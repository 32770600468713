import {useTabStyles} from '../../views/Extensions/ExtensionDetails/utils';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import Loader from '../Loader/Loader';
import {Formik} from 'formik';
import {useEditExtensionCallForwardingFormData} from '../../hooks/useEditExtensionCallForwardingForm';
import CallForwardingForm from '../Forms/Extensions/CallForwarding/CallForwardingForm';
import {
    ExtensionCallForwardingFormType,
    extensionCallForwardingFormValidationSchema,
} from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import {TabInterface} from './Tabs.utils';
import RestrictedMessageProvider, {ProviderTabName} from "../../views/AutoAttendants/Details/RestrictedMessageProvider";

const CallForwardingTab: React.VFC<TabInterface> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,

    }) => {
    const classes = useTabStyles();

    const {initFormData, data} = useEditExtensionCallForwardingFormData();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callForwardingTab
                ?.isLoading || false,
    );

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading, initFormData]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="incoming-list-loader"/>
        </div>
    ) : (
        <RestrictedMessageProvider tabName={ProviderTabName.CallForwarding}>
            <Formik<ExtensionCallForwardingFormType>
                initialValues={initFormData}
                validationSchema={extensionCallForwardingFormValidationSchema}
                onSubmit={handleSubmitForm!}
                enableReinitialize
                validateOnChange={false}
            >
                {() => {

                    return (
                        <>
                            <CallForwardingForm
                                handleDirtyChange={handleDirtyChange}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                                permittedSipProxies={data.permittedSipProxies}
                            />
                        </>
                    )
                }
                }

            </Formik>
        </RestrictedMessageProvider>

    );
};

export default CallForwardingTab;

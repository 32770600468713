import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Bar, Chart } from 'react-chartjs-2';
import { Colors } from '../../styles/Colors';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    mainContainer: {
        height: 240,
        position: 'relative',
        marginTop: -20,
    },
    loadingContainer: {
        position: 'absolute',
        top: 73,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    loadingLabel: {
        backgroundColor: Colors.White,
        width: 110,
        display: 'flex',
        justifyContent: 'center',
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
}));

export type BarChartProps = {
    data: {
        label: string;
        color: string;
        value: number;
        tooltip?: string;
    }[];
    isLoading: boolean;
};

const tooltipPlugin = Chart?.registry?.getPlugin('tooltip');

if (tooltipPlugin) {
    //@ts-ignore
    tooltipPlugin.positioners.middle = (elements) => {
        const model = elements[0]?.element;
        return {
            x: model?.x + (model?.width || 0) / 2 - 30,
            y: model?.y + (model?.height || 0) / 2 - 13,
        };
    };
}

const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = Colors.Text;
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.padding = '4px';

        tooltipEl.style.color = Colors.White;
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context: any, data: any[]) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    if (tooltip.body) {
        const titleLine = data[tooltip.dataPoints[0].dataIndex].tooltip || '';
        const element = document.createElement('div');

        if (titleLine.split(' ').length > 1) {
            const splitted = titleLine.split(' ');

            const boldElement = document.createElement('b');
            boldElement.append(document.createTextNode(splitted[0]));

            element.appendChild(boldElement);
            element.appendChild(
                document.createTextNode(' ' + splitted.slice(1).join(' ')),
            );
        }

        while (tooltipEl?.firstChild) {
            tooltipEl.firstChild.remove();
        }

        tooltipEl.appendChild(element);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.fontSize = '12px';
    tooltipEl.style.lineHeight = '18px';
    tooltipEl.style.padding = '4px 8px';
    tooltipEl.style.zIndex = '5';
};

const BarChart: React.VFC<BarChartProps> = ({ data, isLoading }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const dataset = useMemo(() => {
        return {
            labels: data.map((v) => v.label),
            datasets: [
                {
                    data: data.map((v) => v.value),
                    backgroundColor: data.map((v) => v.color),
                    borderColor: data.map(() => Colors.White),
                },
            ],
        };
    }, [data]);

    return (
        <div className={classes.mainContainer}>
            <div>
                <Bar
                    data-testid="bar-chart"
                    data={dataset}
                    height={240}
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false,
                                //@ts-ignore
                                position: 'middle',
                                external: (context) =>
                                    externalTooltipHandler(context, data),
                            },
                        },
                        //@ts-ignore
                        borderRadius: 4,
                        barPercentage: 0.6,
                        hoverBackgroundColor: Colors.Secondary1,
                        layout: {
                            padding: {
                                left: -20,
                                right: 0,
                                top: 0,
                                bottom: 0,
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    color: Colors.Gray13,
                                },
                            },
                            y: {
                                beginAtZero: true,
                                suggestedMax: isLoading ? 3000 : 5,
                                grid: {
                                    drawBorder: false,
                                },

                                ticks: {
                                    padding: 20,
                                    stepSize:
                                        Math.round(
                                            Math.max.apply(
                                                Math,
                                                dataset.datasets[0].data,
                                            ) /
                                                10 /
                                                5,
                                        ) * 5,
                                    maxTicksLimit: 4,
                                    precision: 0,
                                    color: Colors.Gray13,

                                    callback: (value) => {
                                        if (typeof value === 'string') {
                                            return value;
                                        }
                                        return value > 999
                                            ? (value / 1000).toFixed(1) + 'K'
                                            : value;
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
            <Fade in={isLoading} timeout={1000} appear={isLoading}>
                <div className={classes.loadingContainer}>
                    <div className={classes.loadingLabel}>
                        {t('common:loading')}
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default BarChart;

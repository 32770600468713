import React from 'react';
import { PaymentStatus } from '../../../store/types/Payment';
import { ReactComponent as PassedStatus } from '../../../assets/payments/passed_status.svg';
import { ReactComponent as PendingStatus } from '../../../assets/payments/pending_status.svg';
import { ReactComponent as ErrorStatus } from '../../../assets/payments/error_status.svg';
import { ReactComponent as PassedStatusSmall } from '../../../assets/payments/passed_status_small.svg';
import { ReactComponent as PendingStatusSmall } from '../../../assets/payments/pending_status_small.svg';
import { ReactComponent as ErrorStatusSmall } from '../../../assets/payments/error_status_small.svg';

type PaymentMethodIconProps = {
    status: PaymentStatus;
    type?: 'small' | 'big';
};

const PaymentsStatusesIcons: {
    [key in PaymentStatus]: { big: React.ReactNode; small: React.ReactNode };
} = {
    [PaymentStatus.Completed]: {
        big: <PassedStatus />,
        small: <PassedStatusSmall />,
    },
    [PaymentStatus.Authorized]: {
        big: <PassedStatus />,
        small: <PassedStatusSmall />,
    },
    [PaymentStatus.Pending]: {
        big: <PendingStatus />,
        small: <PendingStatusSmall />,
    },
    [PaymentStatus.Failed]: {
        big: <ErrorStatus />,
        small: <ErrorStatusSmall />,
    },
};

const PaymentStatusIcon: React.VFC<PaymentMethodIconProps> = ({
    status,
    type = 'big',
}) => {
    return <>{PaymentsStatusesIcons[status][type]}</>;
};

export default PaymentStatusIcon;

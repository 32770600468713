import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CustomizedTooltip from '../Tooltip/Tooltip';

import { Colors } from '../../styles/Colors';
import { Badge } from '@material-ui/core';
import EmptyRowContent from '../DataTable/EmptyRowContent';
import classNames from 'classnames';

type ExtensionDIDNumberProps = {
    extNumbers?: string[];
    dataQa?: string;
    dataTestId?: string;
    showFooter?: boolean;
    className?: string;
    copy?: boolean;
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    extNumber: {
        color: Colors.Text,
        fontSize: 16,
        lineHeight: '20px',
    },
    didLabel: {
        fontSize: 16,
        color: Colors.Gray9,
        fontWeight: 400,
        lineHeight: '28px',
    },
    badge: {
        '& > span.MuiBadge-badge': {
            backgroundColor: Colors.Gray4,
            top: '-1px',
            color: Colors.Gray5,
            right: 'unset',
        },
    },
    numbersContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const ExtensionDIDNumber: React.FC<ExtensionDIDNumberProps> = ({
    extNumbers = [],
    dataQa,
    dataTestId = '',
    showFooter = true,
    className,
    copy = true,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const count = extNumbers.length > 0 ? extNumbers.length : 0;
    const elements = extNumbers?.reduce((prev, item) => prev + item + '\n', '');

    return (
        <div
            className={classNames(classes.root, className)}
            data-qa={dataQa}
            data-testid={dataTestId}
        >
            {showFooter && (
                <span className={classes.didLabel}>
                    {t('extensions.didNumber')}
                </span>
            )}
            {count ? (
                <>
                    <div className={classNames(classes.numbersContainer)}>
                        <span className={classes.extNumber}>
                            {extNumbers[0]}{' '}
                        </span>
                        {count > 1 ? (
                            <CustomizedTooltip
                                title={elements}
                                dataQa={'tooltip-did-numbers'}
                                interactive={true}
                                copy={copy}
                            >
                                <Badge
                                    badgeContent={count}
                                    className={classes.badge}
                                    data-qa={'badge-did-numbers'}
                                />
                            </CustomizedTooltip>
                        ) : null}
                    </div>
                </>
            ) : (
                <EmptyRowContent />
            )}
        </div>
    );
};
export default ExtensionDIDNumber;

import { CallQueueFormType } from '../../components/Forms/RingGroups/callQueueFormUtils';
import { CallQueue } from '../../store/types/CallQueue';

export const prepareRingGroupCallQueueAddData = (
    data: Partial<CallQueueFormType>,
): Partial<CallQueue> => ({
    announce_estimated_wait_time: data.announceEstimatedWaitTime ? 'Y' : 'N',
    announce_number_of_callers: data.announceNumberOfCallersInQueue ? 'Y' : 'N',
    announcement_interval: data.intervalBetweenAnnouncements
        ? parseInt(data.intervalBetweenAnnouncements)
        : 10,
    incoming_capacity: data.maximumNumberOfQueuedCallers
        ? parseInt(data.maximumNumberOfQueuedCallers.toString())
        : 1,
    average_duration: data.averageHandleTime
        ? parseInt(data.averageHandleTime)
        : 1,
});

export const prepareRingGroupCallQueueEditData = (
    changedData: Partial<CallQueueFormType>,
): Partial<CallQueue> => {
    const result: Partial<CallQueue> = {};

    if (changedData.announceEstimatedWaitTime !== undefined) {
        result.announce_estimated_wait_time = changedData.announceEstimatedWaitTime
            ? 'Y'
            : 'N';
    }

    if (changedData.announceNumberOfCallersInQueue !== undefined) {
        result.announce_number_of_callers = changedData.announceNumberOfCallersInQueue
            ? 'Y'
            : 'N';
    }

    if (changedData.intervalBetweenAnnouncements !== undefined) {
        result.announcement_interval =
            changedData.intervalBetweenAnnouncements !== undefined
                ? parseInt(changedData.intervalBetweenAnnouncements)
                : 10;
    }

    if (changedData.maximumNumberOfQueuedCallers !== undefined) {
        result.incoming_capacity =
            changedData.maximumNumberOfQueuedCallers !== undefined
                ? parseInt(changedData.maximumNumberOfQueuedCallers.toString())
                : 1;
    }

    if (changedData.averageHandleTime !== undefined) {
        result.average_duration =
            changedData.averageHandleTime !== undefined
                ? parseInt(changedData.averageHandleTime)
                : 1;
    }

    if (changedData.playOnHoldMusicStatus === false) {
        result.moh_action = 'unset';
    }

    return result;
};

import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TwoLineCell from '../../Calls/TwoLineCell';
import { convertToUserFormat } from '../../../utils/dateWithTimezoneConversion';
import React from 'react';
import { DurationFromSec } from '../../../utils/transformers';
import { makeStyles } from '@material-ui/core/styles';

export interface ConferencingTableRowInterface {
    id: string;
    accountId?: string;
    celler: string;
    destination: string;
    description?: string;
    connectTime: string;
    chargedTime: DurationFromSec;
    chargedAmount?: number;
}

export const generateColumns = (
    t: TFunction<string>,
    currency = 'USD',
    userDateTimeFormat?: string,
): Column<ConferencingTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.callerDestination'),
            accessor: 'celler',
            width: 1,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.celler}
                        descriptionText={params.row.original.destination}
                    />
                );
            },
        },
        {
            Header: t<string>('common:description'),
            accessor: 'description',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.description;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'description-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.connectTimeChargedTime'),
            accessor: 'connectTime',
            width: 1,
            Cell: function Cell(params) {
                const connectTime = convertToUserFormat(
                    params.row.original.connectTime || '',
                    userDateTimeFormat,
                );

                const { string, duration } = params.row.original.chargedTime;

                return (
                    <TwoLineCell
                        headerText={connectTime}
                        descriptionText={string}
                        descriptionTextTooltip={t<string>(
                            'tooltips:billing.durationWithTime',
                            { time: duration },
                        )}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }),
            width: 0.5,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(5)': {
            display: ' flex',
            justifyContent: 'end',
        },
    },

    tableContent: {
        '& td': {
            alignItems: 'center !important',
        },
        '& td:nth-child(5)': {
            display: 'flex',
            justifyContent: 'end',
        },
    },
}));

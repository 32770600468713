
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import { SessionIdPayload } from "../../store/actions/payloads";
import { Colors } from '../../styles/Colors';

export type Validate2FADialogProps = {
    isOpen: boolean;
    sessionConfig?: SessionIdPayload;
};

const requiredError = i18n.t<string>('screens:twoFa.incorrectCode');
export const mfaValidationSchema = Yup.object().shape({
    one_time_password: Yup.string()
        .required(requiredError)
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
            height: 419,
            width: 372,
            minWidth: 'unset !important',
            padding: '26px 72px 48px 72px !important',
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 516,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    header1: {
        fontWeight: 400,
        fontSize: 22,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%'
    },
    
    header3: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center',
        color: Colors.Gray5,
        letterSpacing: 0.15,
        lineHeight: '16px',
        maxWidth: 250
    },

    itemsContainer: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        }
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    
    oneTimePassword: {
        width: 401,
        height: 56
    },

    submitButton: {
        width: '100%',
        height: 56,
        color: 'white'
    },

    buttonContainer: {
        marginTop: 44,
        width: '100%',

        '& > :first-child': {
            width: '100%',
        },
    },

    disabledSubmitButton: {
        background: Colors.Gray11
    },

    maxAttemptsReached: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        color: Colors.Error,
        width: 372
    }
}));
import React from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import CustomButton from '../../Button/Button';
import { MailMePayload } from '../../../store/actions/auth/payloads';
import { APIErrorInterface } from '../../../store/types';
import { getErrorsMsg } from './helpers';
import { useStyles } from './ChangePasswordForm.utils';
import PasswordTextField from '../../PasswordTextField/PasswordTextField';

type ChangePasswordProps = {
    changePasswordAction: (payload: MailMePayload) => void;
    validateOnChange: boolean;
    recoveryId?: string;
    dataQa?: string;
    apiErrors?: APIErrorInterface;
};

const ChangePasswordForm: React.FC<ChangePasswordProps> = ({
    changePasswordAction,
    recoveryId,
    validateOnChange = false,
    dataQa,
    apiErrors,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            password: '',
            global: '',
        },
        validateOnChange: validateOnChange,
        onSubmit: (values) => {
            const payload: MailMePayload = {
                id: recoveryId,
                password: values.password,
                custom_url: `${window.location.protocol}//${window.location.host}/sign-in`,
            };

            changePasswordAction(payload);
        },
    });

    const passwordErrorMsg = getErrorsMsg(formik.errors.password, apiErrors);

    const { values, handleChange, setFieldError } = formik;

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            data-qa={dataQa}
        >
            <div className={classes.inputs}>
                <Grid item>
                    <PasswordTextField
                        id="password"
                        dataQa="recovery-password-input"
                        label={t('screens:signIn.newPasswordLabel')}
                        onChange={handleChange}
                        value={values.password.trim()}
                        error={
                            passwordErrorMsg
                                ? t(`errors:loginForm.${passwordErrorMsg}`)
                                : ''
                        }
                        setFieldError={setFieldError}
                    />
                </Grid>
            </div>
            <div className={classes.action}>
                <CustomButton
                    className={!values.password.length ? classes.disabled : ''}
                    dataQa="recovery-submit-button"
                    primary
                    type="submit"
                    disabled={!values.password.length}
                >
                    {t('common:save')}
                </CustomButton>
            </div>
        </form>
    );
};

export default ChangePasswordForm;

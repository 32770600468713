import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
    isDefaultModeSelected,
    isSelectModeAutoValueDisabled,
    removeSecondsFromDateTimeFormat,
    resetSecFromUtcTime,
    SwitchModeFormInterface,
    useStyles,
} from './SwithModelDialog.utils';
import SelectField from '../SelectField/SelectField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import CustomKeyboardDateTimePicker from '../KeyboardDateTimePicker/KeyboardDateTimePicker';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { useSwitchModeDialogData } from '../../hooks/extensions/useSwitchModeDialogData';
import Loader from '../Loader/Loader';
import { YesNo } from '../../store/types/CallScreening';
import { switchModeValidation } from './SwitchMode.utils';

export const TESTING_ATTR_VALUE = 'switch-mode-dialog';

interface SwitchModeDialogInterface {
    isOpen: boolean;
    toggleVisibility: () => void;
    actionAfterSave?: () => void;
}

const SwitchModeDialog: React.VFC<SwitchModeDialogInterface> = ({
    isOpen,
    toggleVisibility,
    actionAfterSave,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const [defaultModeSelected, setDefaultModeSelected] = useState(false);
    const { i_c_group, mode, i_customer, data } = useSwitchModeDialogData();

    useEffect(() => {
        if (isOpen) {
            resetForm();
            dispatch(actions.fetchDataForSwitchMode.request());
        }
    }, [isOpen]);

    const {
        resetForm,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        setFieldError,
    } = useFormik<SwitchModeFormInterface>({
        initialValues: { i_c_group, mode, i_customer },
        enableReinitialize: true,
        validationSchema: switchModeValidation,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (form: SwitchModeFormInterface) => {
            dispatch(
                actions.updateAccountsCallProcessingMode.request({
                    i_c_group:
                        form.i_c_group == -1 ? undefined : form.i_c_group,
                    short_code: form.mode?.short_code,
                    i_customer: form.i_customer,
                    file_id: form.file_id,
                    effective_to: form.effective_to
                        ? resetSecFromUtcTime(
                              form.effective_to,
                              data.timezoneOffset,
                          )
                        : undefined,
                    i_response_message: form.i_response_message,
                    callback: () => {
                        toggleVisibility();
                        actionAfterSave?.();
                    },
                }),
            );
        },
    });

    const actionButtons = () => {
        return [
            <DialogButton
                key="cancel"
                label={t('common:cancel')}
                onClick={() => {
                    toggleVisibility();
                    resetForm();
                }}
            />,
            <DialogButton
                disabled={data.isLoading}
                key="save"
                label={t('common:apply')}
                onClick={handleSubmit}
                primary
            />,
        ];
    };

    useEffect(() => {
        const isDefaultMode = isDefaultModeSelected(
            data.callProcessingOperationModeList,
            values.mode?.short_code,
        );

        setDefaultModeSelected(isDefaultMode);
    }, [values.mode?.short_code]);

    useEffect(() => {
        defaultModeSelected
            ? setFieldValue('file_id', null)
            : setFieldValue('file_id', -1);

        if (!defaultModeSelected && values.mode?.default_timeout) {
            const current = new Date();
            current.setMinutes(
                current.getMinutes() + values.mode?.default_timeout,
            );

            setFieldValue('effective_to', current.toString());
        } else {
            setFieldValue('effective_to', null);
            setFieldError('effective_to', '');
        }
    }, [defaultModeSelected, values.mode]);

    return (
        <DialogContainer
            isOpen={isOpen}
            dialogActionsButtons={actionButtons()}
            contentClass={classes.content}
            header={t('screens:extensions.switchModeDialogHeader')}
            className={classes.dialog}
            dataQa={TESTING_ATTR_VALUE}
            dataTestId={TESTING_ATTR_VALUE}
        >
            {data.isLoading ? (
                <Loader
                    dataQa={'s"witch-mode-data-fetch-loader"'}
                    absolutePosition
                />
            ) : (
                <div>
                    <form className={classes.form}>
                        <Box className={classes.box}>
                            <SelectField
                                label={t('screens:switchModeDialog.groupLabel')}
                                items={data.huntGroupList || []}
                                getOptionLabel={(o) => o.name || ''}
                                getOptionSelected={(o) =>
                                    o.i_c_group == values.i_c_group
                                }
                                onChange={(e, newValue) => {
                                    setFieldValue(
                                        'i_c_group',
                                        newValue.i_c_group,
                                    );
                                }}
                                value={data.huntGroupList?.find(
                                    (o) => o.i_c_group == values.i_c_group,
                                )}
                                disableClearable
                            />
                        </Box>
                        <Box className={classes.box}>
                            <SelectField
                                label={t('screens:switchModeDialog.modelLabel')}
                                items={
                                    data.callProcessingOperationModeList || []
                                }
                                getOptionLabel={(o) => o.name}
                                getOptionSelected={(o) =>
                                    o.short_code == values.mode?.short_code
                                }
                                onChange={(e, newValue) => {
                                    setFieldValue('mode', newValue);
                                }}
                                value={
                                    data.callProcessingOperationModeList?.find(
                                        (o) =>
                                            o.short_code ==
                                            values.mode?.short_code,
                                    ) || null
                                }
                                getOptionDisabled={(o) => o.sticky == YesNo.Yes}
                                icon={
                                    <IconWithTooltip
                                        dataQa="switch-mode-dialog-mode-tooltip"
                                        tooltipText={t(
                                            'tooltips:switchModeDialog.mode',
                                        )}
                                    />
                                }
                                disableAutoSettingValue={isSelectModeAutoValueDisabled(
                                    data.callProcessingOperationModeList,
                                )}
                                disableClearable
                            />
                        </Box>
                        <Box className={classes.box}>
                            <SelectField
                                label={t(
                                    'screens:switchModeDialog.tempRespMessageLabel',
                                )}
                                items={data.responseMessageList || []}
                                getOptionLabel={(o) => o.name}
                                getOptionSelected={(o) =>
                                    o.id == values.file_id
                                }
                                value={
                                    data.responseMessageList?.find(
                                        (o) => o.id == values.file_id,
                                    ) || null
                                }
                                onChange={(e, newValue) => {
                                    setFieldValue('file_id', newValue.id);
                                }}
                                icon={
                                    <IconWithTooltip
                                        dataQa="switch-mode-dialog-temp-response-message-tooltip"
                                        tooltipText={t(
                                            'tooltips:switchModeDialog.tempRespMessage',
                                        )}
                                    />
                                }
                                disabled={defaultModeSelected}
                                disableClearable
                            />
                        </Box>
                        <Box className={classes.box}>
                            <CustomKeyboardDateTimePicker
                                id={'effective_to'}
                                label={t(
                                    'screens:switchModeDialog.switchBackLabel',
                                )}
                                disabled={!values.mode?.default_timeout}
                                allowEmptyDate={true}
                                userDateTimeFormat={removeSecondsFromDateTimeFormat(
                                    data.userDateTimeFormat,
                                )}
                                onChange={(v) =>
                                    setFieldValue('effective_to', v)
                                }
                                value={values.effective_to}
                                minDate={new Date()}
                                helperText={errors.effective_to}
                                setFieldError={setFieldError}
                                disablePast
                            />
                        </Box>
                    </form>
                    {data.isSaving && (
                        <Loader
                            dataQa={'switch-mode-data-saving-loader'}
                            absolutePosition
                        />
                    )}
                </div>
            )}
        </DialogContainer>
    );
};

export default SwitchModeDialog;

import { AddRingGroupForm } from '../../../views/RingGroups/AddRingGroup';
import { ExtensionsListItem } from '../../reducers/extensions/extensions/reducer';
import { RingGroupType } from '../../types/RingGroup';
import { CreateNewRingGroupFormData } from '../../../views/RingGroups/createUtils';
import { EditRingGroupForm } from '../../../views/RingGroups/RingGroupDetails.utils';

export type RingGroupListRequestPayload = {
    page?: number;
    nameFilter?: string;
    groupNumberFilter?: string;
    extensionFilter?: string;
    limit: number;
    offset: number;
};

export type RingGroupListResponsePayload = {
    total: number;
    items: RingGroupType[];
};

export type RemoveRingGroupPayload = {
    i_c_group: number;
    i_c_ext?: number;
    i_customer?: number;
    withRedirectToList?: boolean;
};

export type UploadRingbackToneRequestPayload = {
    file: File;
    name?: string;
    callback?: () => void;
};

export type GroupMembersDetailsSuccessPayload = {
    extensions: ExtensionsListItem[];
    ringGroups: RingGroupType[];
};

export type RingGroupDetailsRequest = {
    i_c_group: number;
    limit?: number;
    offset?: number;
};

export type EditRingGroupRequest = {
    initialValues: EditRingGroupForm;
    changedValues: EditRingGroupForm;
    i_c_group: number;
    redirectTab?: number;
    blockRedirection?: boolean;
};

export type AddRingGroupRequest = {
    form: AddRingGroupForm;
    redirectTab?: number;
};

export type CreateRingGroupRequest = {
    form: CreateNewRingGroupFormData;
    callback?: () => void;
};

export type ChangeCallQueueStatusRequest = {
    i_c_group: number;
    i_c_queue?: number;
    callQueueStatus: boolean;
};

export type ChangeCallQueueStatusResponse = {
    i_c_group: number;
    i_c_queue?: number;
    callQueueStatus: boolean;
};

export type GetHuntGroupListRequest = {
    i_c_ext?: number;
    self_ref?: number;
    name?: string;
    get_main_office_huntgroups?: number;
    i_customer?: number;
    limit?: number;
    offset?: number;
};

export type GetHuntGroupListResponse = {
    huntgroup_list: RingGroupType[];
};

export type GetSipCallsListRequest = {
    callback?: () => void;
};

/*
"trying" - a call is initiated and an outgoing request is sent;
"ringing" - a phone is ringing;
"early" - early media is played;
"connected" - a call is answered/taken from hold and the remote side is connected;
"terminated" - a call is disconnected;
"held" - a call party is connected and is put on hold. This state is returned to the party which is placed on hold;
"holding" - a call party is connected and has put the call on hold. This state is returned to the party who places the call on hold;
"queued" - a caller is placed into a call queue;
"dequeued" - a caller is removed from the call queue;
"transferred" - a call was transferred.
*/
export enum SipCallState {
    Connected = 'connected',
    Trying = 'trying',
    Ringing = 'ringing',
    Holding = 'holding',
    Held = 'held',
    Queued = 'queued',
    Early = 'early',
    Terminated = 'terminated',
    Dequeued = 'dequeued',
    Transferred = 'transferred'
}

export enum SipCallType {
    Outgoing = 'outgoing',
    Incoming = 'incoming',
}

export type SipCallQueueInfo = {
    i_c_queue: number;
    position?: number;
    operators?: number;
};

export type SipCall = {
    tracking_id: string;
    connect_time?: string;
    callee: CalleeOrCaller;
    type: SipCallType;
    caller: CalleeOrCaller;
    call: Call;
    state: SipCallState;
    id?: string;
    start_time?: string;
    queue_info?: SipCallQueueInfo;
    update_time?: string;
    duration?: string;
    durationFormatSec?: string;
}

export type CalleeOrCaller = {
    centrex_id?: string;
    display_id: string;
    extension_id?: string;
    huntgroup_id?: string;
    id: string;
    forwarder_list?: CalleeOrCaller[];
    account_id?: string;
    display_name?: string;
}

export type Call = {
    id: string;
    tag: string;
}

export type GetSipCallsListResponse = {
    calls_list: SipCall[],
    server_time?: string,
};
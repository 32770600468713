import React, {useEffect} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {actions} from "../../store";

interface TabErrorsProps {
    show: boolean,
    tabName: string,
    isSubmitting?:boolean
    tabIndex?:number
}

const TabErrors: React.FC<TabErrorsProps> = ({show, tabName, isSubmitting,tabIndex}) => {
    const {t} = useTranslation();
    const msg = t('errors:tabError', {tabName: tabName});
    const dispatch = useDispatch();


    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {
        if (show && isSubmitting) {
            markTabAsHaveError(true)
            toast(msg, {duration:2500});
        }
    }, [show,isSubmitting]);

    return <></>

}


export default TabErrors;
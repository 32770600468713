import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { EditMyProfileForm } from '../views/MyProfile/utils';
import { useMemo } from 'react';
import dayjs from '../services/customDayJs';
import { convertFormatFromBackendToDayJs } from '../utils/dateWithTimezoneConversion';

export const useEditMyProfileFormData = () => {
    const { timeZonesList, languagesList, configData } = useSelector(
        (state: ReduxState) => state.generic,
    );

    const { customer } = useSelector((state: ReduxState) => state.myProfile);
    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) => state.generic.sessionData?.out_date_time_format || '',
    );
    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const timeInUserFormat = dayjs
        .utc(dayjs(), userDateTimeFormat)
        .utcOffset(timezoneOffset / 60)
        .format(convertFormatFromBackendToDayJs(userDateTimeFormat));

    const initialValues = useMemo((): EditMyProfileForm => {
        return {
            login: customer?.login,
            email: customer?.email,
            language: languagesList?.find(
                (lang) =>
                    lang.iso_639_1 ===
                    (customer?.lang || configData?.Global?.Locale_Language),
            ),
            timeZone:
                timeZonesList.find(
                    (tz) => tz.i_time_zone === customer?.iTimeZone,
                ) ||
                timeZonesList.find(
                    (tz) => tz.time_zone_name === configData?.Global?.Time_Zone,
                ),
            oldPassword: '',
            newPassword: '',
        };
    }, [timeZonesList, languagesList, configData]);

    return {
        initialValues,
        data: {
            timeZonesList,
            languagesList,
            configData,
            customer,
            timeInUserFormat,
        },
    };
};

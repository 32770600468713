import React from 'react';
import { CustomerAgreementCondition } from '../../store/types/CustomerAgreementCondition';
import { useTranslation } from 'react-i18next';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import { useStyles } from './productRowUtils';

type ProductRowProps = {
    product: CustomerAgreementCondition;
};

const ProductRow: React.VFC<ProductRowProps> = ({ product }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const percentValue =
        product.max_offered_quantity === 0
            ? 100
            : (100 * product.used_quantity) / product.max_offered_quantity;

    return (
        <div className={classes.mainContainer}>
            <div className={classes.firstColumn}>
                <OverflowTooltip
                    tooltip={product.product_name}
                    text={product.product_name}
                    copy={false}
                />
            </div>
            <div className={classes.firstColumn}>
                <LinearProgress
                    className={classNames(
                        classes.progressBar,
                        product.max_offered_quantity <= product.used_quantity &&
                            classes.fullProgressBar,
                        product.max_offered_quantity === 0 &&
                            classes.unlimitedProgressBar,
                    )}
                    variant="determinate"
                    value={percentValue > 100 ? 100 : percentValue}
                />
            </div>
            <div className={classes.secondColumn}>
                <span
                    className={classes.usedQuantity}
                    data-testid="product-row-used-quantity"
                    data-qa="product-row-used-quantity"
                >
                    {product.used_quantity}
                </span>{' '}
                <span
                    className={classes.maxQuantity}
                    data-testid="product-row-max-quantity"
                    data-qa="product-row-max-quantity"
                >
                    /{' '}
                    {product.max_offered_quantity === 0
                        ? t('screens:dashboard.unlimited')
                        : product.max_offered_quantity}
                </span>
            </div>
            <div className={classes.thirdColumn}>
                {!product.is_addon && (
                    <div className={classes.mainProductLabel}>
                        <span>{t('screens:dashboard.mainProduct')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductRow;

import {ActionType, createReducer} from "typesafe-actions";
import * as actions from "../../actions/tabs";

export interface TabsReducerType {
    errors: TabErrorInterface[],
    saveForms: boolean
}

export interface TabErrorInterface {
    name: string,
    index: number,
    markAsInvalid: boolean
}

const initState: TabsReducerType = {
    errors: [],
    saveForms: false
}

export type TabActionsType = ActionType<typeof actions>;

const tabsReducer = createReducer<TabsReducerType, TabActionsType>(initState)
    .handleAction(actions.clearErrorInTav, (state) => ({
        ...state,
        errors: []
    }))
    .handleAction(actions.setErrorInTab, (state, action) => {

        let newErrors = [...state.errors];

        if (!newErrors.length) {
            newErrors.push(action.payload)
        } else {
            let updated = false;
            newErrors = newErrors.map(item => {

                if (item.name == action.payload.name) {
                    updated = true;
                    return action.payload
                }
                return item;
            });

            !updated && newErrors.push(action.payload);
        }

        return {
            ...state,
            errors: newErrors
        }
    }).handleAction(actions.saveForms, (state, action) => {

            return {
                ...state,
                saveForms: action.payload.visible
            }
        }
    )
;


export default tabsReducer;
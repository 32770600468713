import { CallHistory } from '../../store/types/CallHistory';
import { convertUtcToUserLocalTime } from '../dateWithTimezoneConversion';

export const prepareCallRecordName = (
    call: CallHistory,
    timezoneOffset: number,
) => {
    const connectTime = call.connect_time
        ? convertUtcToUserLocalTime(
              call.connect_time,
              timezoneOffset,
              'YYYY-MM-DD_HH.mm.ss',
          )
        : '';

    let endValue = call.i_account || '';

    if (call.aux_xdrs) {
        const v: { value: string } | undefined = call.aux_xdrs.find(
            (v) => v.xdr_column_id === 'xcli',
        );

        if (v) {
            endValue = v.value;
        }
    }

    return `${connectTime}_${endValue}`;
};

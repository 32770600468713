import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/menus';
import {
    AutoAttendantMenu,
    AutoAttendantMenuTransition,
} from '../../types/AutoAttendant';

export interface MenusStateType {
    isDataLoading?: boolean;
    isDataSending?: boolean;
    isDataSaving?: boolean;
    menuDetails?: AutoAttendantMenu;
    menuTransitions?: AutoAttendantMenuTransition[];
}

export const initialState: MenusStateType = {};

export type MenusActionsType = ActionType<typeof actions>;

const menusReducer = createReducer<MenusStateType, MenusActionsType>(
    initialState,
)
    .handleAction(actions.getMenuDetails.request, (state) => ({
        ...state,
        isDataLoading: true,
    }))
    .handleAction(actions.getMenuDetails.success, (state, action) => ({
        ...state,
        isDataLoading: false,
        menuDetails: action.payload,
    }))
    .handleAction(actions.getMenuTransitions.success, (state, action) => ({
        ...state,
        menuTransitions: action.payload,
    }))
    .handleAction(actions.editMenu.request, (state) => ({
        ...state,
        isDataSending: true,
    }))
    .handleAction(actions.editMenu.failure, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(actions.removeMenu.request, (state) => ({
        ...state,
        isDataSending: true,
    }))
    .handleAction(actions.createMenu.request, (state) => ({
        ...state,
        isDataSaving: true,
    }))
    .handleAction(actions.createMenu.success, (state) => ({
        ...state,
        isDataSaving: false,
    }))
    .handleAction(actions.createMenu.failure, (state) => ({
        ...state,
        isDataSaving: false,
    }));

export default menusReducer;

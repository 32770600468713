import React from 'react';
import { RowCellProps } from './types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    internalHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '12px',
        letterSpacing: '0.005em',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 6,
    },
    cellContainerWithInternalHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
    }
}));

const RowCellInternalHeader: <T extends Object>(
    p: RowCellProps<T>,
) => React.ReactElement<RowCellProps<T>> = ({
    cell
}) => {

    const classes = useStyles();

    return (
        <>
            {cell.column?.Header && ((cell.column.Header as string)?.length > 0) && (
                <div className={classes.internalHeader}>
                    {cell.column.Header}
                </div>
            )}
            <div className={classes.cellContainerWithInternalHeader}>
                {cell.render('Cell')}
            </div>
        </>
    );
};

export default RowCellInternalHeader;

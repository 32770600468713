import React, {useCallback, useEffect, useState} from 'react';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {ReduxState} from '../../store/types';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../components/Loader/Loader';
import {actions} from '../../store';
import {getTabNumber} from '../../utils/getTabNumber';
import {useEditCallSettingsFormData} from '../../hooks/useEditCallSettingsFormData';
import ServiceCodesForm from '../../components/Forms/CallSettings/ServiceCodesForm';
import {serviceCodesFormValidationSchema} from '../../components/Forms/CallSettings/utils/validation';
import CallBarringForm from '../../components/Forms/CallSettings/CallBarring/CallBarringForm';
import MusicRingingForm from '../../components/Forms/CallSettings/MusicRinging/MusicRingingForm';
import {CallBarringFormType} from '../../components/Forms/CallSettings/CallBarring/utils';
import {MusicRingingFormType} from '../../components/Forms/CallSettings/MusicRinging/utils';
import {useStyles} from './utils/utils';
import {ServiceCodesFormType} from '../../components/Forms/CallSettings/utils/serviceCodesUtils';
import usePageTitle from '../../hooks/usePageTitle';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import GeneralForm from '../../components/Forms/CallSettings/GeneralForm/GeneralForm';
import {DefaultCallerFormType} from '../../components/Forms/CallSettings/GeneralForm/DefaultCallerForm';
import i18next from 'i18next';
import {Permission} from "../../store/types/Permission";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {generateTabs} from "../../utils/generateTabs";
import {useRawPermissions} from "../../hooks/usePermissions";
import CallScreeningForm from "../../components/Forms/CallSettings/CallScreening/CallScreeningForm";
import { CallScreeningFormType } from '../../components/Forms/CallSettings/CallScreening/PoliciesForm.utils';

export type EditCallSettingsForm = CallBarringFormType &
    MusicRingingFormType &
    ServiceCodesFormType &
    DefaultCallerFormType &
    CallScreeningFormType;

const formValidationSchema = serviceCodesFormValidationSchema;

export const tabsNames = [
    i18next.t<string>('common:general'),
    i18next.t<string>('screens:callSettings.serviceCodes'),
    i18next.t<string>('screens:extensions.callBarring'),
    i18next.t<string>('screens:extensions.callScreening'),
    i18next.t<string>('screens:callSettings.musicAndRinging'),
];

const CallSettingsDetails: React.VFC = () => {
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2, 3, 4]));

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();
    const permissions = useRawPermissions();

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.callSettings.isDataLoading,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.callSettings.isDataSending,
    );

    const {initialValues, data} = useEditCallSettingsFormData();

    useEffect(() => {
        dispatch(actions.getCallSettingsDetails.request());
    }, []);

    const onSubmitForm = useCallback(
        (form: EditCallSettingsForm) => {
            dispatch(
                actions.editCallSettings.request({
                    initialValues,
                    changedValues: form,
                    redirectTab: tabIndex,
                }),
            );
        },
        [initialValues, tabIndex],
    );

    if (loading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="call-settings-loader"/>
            </div>
        );
    }

    const {tabNames, tabs} = generateTabs(
        [
            {
                title: tabsNames[0],
                permission:
                Permission.Calls.Settings.General.value,
                tab: <GeneralForm numbers={data.numbers}/>,
            },
            {
                title: tabsNames[1],
                permission:
                Permission.Calls.Settings.ServiceCodes.value,
                tab: (
                    <ServiceCodesForm/>
                ),
            },
            {
                title: tabsNames[2],
                permission:
                Permission.Calls.Settings.CallBarring.value,
                tab: (
                    <CallBarringForm/>
                ),
            },
            {
                title: tabsNames[3],
                permission:
                Permission.Calls.Settings.CallScreening.value,
                tab: (
                    <CallScreeningForm/>
                ),
            },
            {
                title: tabsNames[4],
                permission:
                Permission.Calls.Settings.MusicAndRinging.value,
                tab: (
                    <MusicRingingForm/>
                ),
            },
        ],
        permissions,
    );


    const top = (
        <div className={classes.callSettingsHeader}>
            {t('screens:callSettings.callSettings')}
        </div>
    );

    return (
        <PermissionPlaceholder
            permission={Permission.Calls.Settings.value}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                onSubmit={onSubmitForm}
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={true}
            >
                {({submitForm, dirty, values}) => (
                    <>
                        <DetailsWrapper
                            tabs={tabs}
                            top={top}
                            tabsNames={tabNames}
                            defaultTabIndex={tabIndex}
                            onTabChange={(_, index) => setTabIndex(index)}
                            onSavePress={submitForm}
                            preventIsOpen={dirty}
                            preventSavePress={() => onSubmitForm(values)}
                            showActionButtons
                            hideCancel
                            saveEnable={dirty}
                        >
                            {isFormSaving && (
                                <Loader
                                    dataQa="edit-auto-attendant-sending-loader"
                                    absolutePosition
                                />
                            )}
                        </DetailsWrapper>
                    </>
                )}
            </Formik>
        </PermissionPlaceholder>

    );
};

export default CallSettingsDetails;

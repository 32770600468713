import FiltersWrapper from '../FiltersWrapper/FiltersWrapper';
import CustomKeyboardDateTimePicker from '../KeyboardDateTimePicker/KeyboardDateTimePicker';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import CustomizedButtonGroup from '../ButtonGroup/ButtonGroup';
import {getDefaultDatePreferences} from '../../views/Billing/Transactions/utils/DateUtils';
import {dateFormat, dateFromDateTillValidationSchema} from "../Extensions/CallHistoryDialog.utils";

export interface TransactionFiltersProps {
    initData: TransactionFiltersFormType;
    userDateTimeFormat?: string;
    onClick?: () => void;
    onChange?: (values: TransactionFiltersFormType) => void;
    className?: string;
    timeZoneOffset?: number,
    id?: string
}

export enum TransactionFilterDateModes {
    ThisMonth = 0,
    PreviousMonth = 1,
    Custom = 2
}

export interface TransactionFiltersFormType {
    from: string;
    till: string;
    dateMode: TransactionFilterDateModes;
    i_service?: number;
}

const useStyles = makeStyles(() => ({
    option: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    statusContainer: {
        minWidth: 208,
        maxWidth: 208,
    },

    filter: {
        maxWidth: 208,
    },
}));

const TransactionFilters: React.VFC<TransactionFiltersProps> = (
    {
        initData,
        userDateTimeFormat,
        onClick,
        onChange,
        className,
        timeZoneOffset,
        id
    }) => {
    const {t} = useTranslation();
    const clasess = useStyles();

    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        setFieldError,
        validateForm
    } = useFormik<TransactionFiltersFormType>({
        initialValues: initData,
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: dateFromDateTillValidationSchema(timeZoneOffset || 0, userDateTimeFormat || dateFormat),
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: () => {
            onClick?.();
        },
    });

    useEffect(() => {
        onChange?.(values);
    }, [values]);

    const [fromDate, setFromDate] = useState<string>(initData.from);
    const [toDate, setToDate] = useState<string>(initData.till);

    const fields = [
        <CustomKeyboardDateTimePicker
            label={t('common:fromDate')}
            id={'fromDate'}
            value={fromDate}
            onChange={(v) => setFromDate(v || '')}
            dataQa={'filter-by-from-date'}
            dataTestId={'filter-by-from-Date'}
            userDateTimeFormat={userDateTimeFormat}
            setFieldError={setFieldError}
            key="filter-1"
            helperText={errors.from}
            className={clasess.filter}
            disabled={values.dateMode !== TransactionFilterDateModes.Custom}
            skipPermission
        />,
        <CustomKeyboardDateTimePicker
            label={t('common:toDate')}
            id={'toDate'}
            value={toDate}
            onChange={(v) => setToDate(v || '')}
            dataQa={'filter-by-to-date'}
            dataTestId={'filter-by-to-date'}
            userDateTimeFormat={userDateTimeFormat}
            key="filter-2"
            setFieldError={setFieldError}
            helperText={errors.till}
            className={clasess.filter}
            disabled={values.dateMode !== TransactionFilterDateModes.Custom}
            skipPermission
        />,
    ];

    const dateModeChangedEvent = (val: TransactionFilterDateModes) => {
        if (initData.dateMode !== val) {
            const calc = getDefaultDatePreferences(new Date());
            if (val === TransactionFilterDateModes.ThisMonth) {
                setFromDate(calc.currentMonthStartDate);
                setToDate(calc.currentMonthEndDate);
                setFieldValue('from', calc.currentMonthStartDate);
                setFieldValue('till', calc.currentMonthEndDate);
            } else if (val === TransactionFilterDateModes.PreviousMonth) {
                setFromDate(calc.previousMonthStartDate);
                setToDate(calc.previousMonthEndDate);
                setFieldValue('from', calc.previousMonthStartDate);
                setFieldValue('till', calc.previousMonthEndDate);
            }
            setFieldValue('dateMode', val);
        }
    };

    const dateModeElem = (
        <div style={{height: '36px', marginTop: '6px', padding: '4px', borderRadius: '44px'}} key="date-mode">
            <CustomizedButtonGroup
                items={['screens:billing:thisMonth',
                    'screens:billing:previousMonth',
                    'screens:billing:customDate']}
                onChange={dateModeChangedEvent}
                filterValue={initData.dateMode}
                key="date-mode-internal"
                skipPermission
            >
            </CustomizedButtonGroup>
        </div>
    );

    const searchClicked = function (e?: React.FormEvent<HTMLFormElement> | undefined) {
        if (values.from !== fromDate) {
            setFieldValue('from', fromDate);
        }
        if (values.till !== toDate) {
            setFieldValue('till', toDate);
        }
        validateForm({
            ...values,
            from: fromDate,
            till: toDate
        }).then(res => {
            const hasErrors = res && JSON.stringify(res) !== JSON.stringify({});
            if (!hasErrors) {
                handleSubmit(e);
            }
        });

    }

    return (<div id={id}>
            <FiltersWrapper
                fields={[dateModeElem, ...fields]}
                onSubmit={searchClicked}
                dataQa={'transaction-filters'}
                className={className}
                hideSearchButton={initData.dateMode !== TransactionFilterDateModes.Custom}
            >
            </FiltersWrapper>
        </div>
    );
};

export default TransactionFilters;

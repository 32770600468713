import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';

export type ErrorMessage = {
    login?: string;
    password?: string;
    global?: string;
};
export const validate = (values: any) => {
    const errors: ErrorMessage = {};

    if (!values.login) {
        errors.login = 'emptyInput';
        errors.global = 'emptyLoginOrPassword';
    }

    if (!values.password) {
        errors.password = 'emptyInput';
        errors.global = 'emptyLoginOrPassword';
    } else if (values.password.length < 6) {
        errors.password = 'emptyInput';
        errors.global = 'incorrectLoginOrPassword';
    }

    return errors;
};
export const useStyles = makeStyles(() => ({
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },

    action: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& button': {
            padding: 0,
        },

        '& div': {
            width: '100%',
        },
    },
    submitButton: {
        width: '100%',
        height: 56,
        '& span': {
            color: Colors.White,
            fontSize: 16,
            fontWeight: '700',
        },

        '& .MuiCircularProgress-svg': {
            color: Colors.White + '!important',
        },
    },
    recoveryButtonContainer: {
        '& div': {
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'end'
        },
    },
    recoveryButton: {
        padding: 0,
        width: 'fit-content',
        fontWeight: 'normal',
        marginBottom: 28,

        '& span': {
            color: Colors.Secondary1,
        },
    },
}));

export const initLoginFormData = {
    login: '',
    password: '',
    global: '',
};

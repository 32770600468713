import React from 'react';
import { CPConditionInfo, YesNo } from '../../store/types/CallScreening';
import i18n from '../../services/i18n';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    exclude: {
        color: Colors.Gray5,
        fontSize: 14,
        lineHeight: '20px',
    },
}));

type CpNumbersCellProps = {
    cpNumbers?: CPConditionInfo;
    exclClass?: string;
};

const CpNumbersCell: React.VFC<CpNumbersCellProps> = ({
    cpNumbers,
    exclClass,
}) => {
    const classes = useStyles();
    const filterByNumber = cpNumbers
        ? cpNumbers?.numbers?.map((numberFilter) =>
              numberFilter.negated == YesNo.Yes
                  ? 'Excl.' + numberFilter.number
                  : numberFilter.number,
          )
        : [i18n.t<string>('screens:extensions.any')];

    return (
        <span>
            {filterByNumber?.map((v, index) => {
                const splited = v.split('.');
                if (splited.length > 1) {
                    return (
                        <span key={index}>
                            <span
                                className={classNames(
                                    classes.exclude,
                                    exclClass,
                                )}
                            >
                                {splited[0]}
                            </span>
                            {`. ${splited[1]}`}
                            {filterByNumber.length - 1 !== index && `, `}
                        </span>
                    );
                } else {
                    return (
                        <span key={index}>
                            {v}
                            {filterByNumber.length - 1 !== index && `, `}
                        </span>
                    );
                }
            })}
        </span>
    );
};

export default CpNumbersCell;

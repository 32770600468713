import { RowData } from './types';

export const reorder = <T extends Object>(
    list: RowData<T>[],
    startIndex: number,
    endIndex: number,
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

import { createAction, createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import { ApiFileList } from '../../types/ApiFile';
import { CPConditionInfo } from '../../types/CallScreening';
import { DialingRuleInfo } from '../../types/DialingRule';
import { ServiceFeature } from '../../types/ServiceFeature';
import { GetCallProcessingPolicyListEntity, GetCPConditionListResponsePayload, OperationMode } from '../extensions/payloads';
import * as payloads from './payloads';

export const getCallSettingsDetails = createAsyncAction(
    'GET_CALL_SETTINGS_DETAILS',
    'GET_CALL_SETTINGS_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_DETAILS_FAILED',
)<undefined, undefined, undefined>();

export const editCallSettings = createAsyncAction(
    'EDIT_CALL_SETTINGS_DETAILS',
    'EDIT_CALL_SETTINGS_DETAILS_SUCCESS',
    'EDIT_CALL_SETTINGS_DETAILS_FAILED',
)<payloads.SetCallSettings, undefined, undefined>();

export const getCallSettingsMohDetails = createAsyncAction(
    'GET_CALL_SETTINGS_MOG_DETAILS',
    'GET_CALL_SETTINGS_MOG_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MOG_DETAILS_FAILED',
)<undefined, payloads.CallSettingsMohDetailsResponsePayload, Error>();

export const getMusicRingDetails = createAsyncAction(
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_FAILED',
)<undefined, payloads.MusicRingingResponsePayload, Error>();

export const uploadMusicFile = createAsyncAction(
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_MUSIC_RING_DETAILS_FAILED',
)<
    payloads.UploadMusicFileRequest,
    payloads.MusicRingingResponsePayload,
    Error
>();

export const getDialingRules = createAsyncAction(
    'GET_DIALING_RULES',
    'GET_DIALING_RULES_SUCCESS',
    'GET_DIALING_RULES_FAILED',
)<undefined, DialingRuleInfo[], undefined>();

export const getCustomerServiceFeatures = createAsyncAction(
    'GET_CUSTOMER_SERVICE_FEATURES',
    'GET_CUSTOMER_SERVICE_FEATURES_SUCCESS',
    'GET_CUSTOMER_SERVICE_FEATURES_FAILED',
)<undefined, ServiceFeature[], undefined>();

export const getCustomerInfoCallBarring = createAsyncAction(
    'GET_CUSTOMER_INFO_CALL_BARRING',
    'GET_CUSTOMER_INFO_CALL_BARRING_SUCCESS',
    'GET_CUSTOMER_INFO_CALL_BARRING_FAILED',
)<undefined, payloads.CustomerInfoResponsePayload, undefined>();

export const getCallBarringOptions = createAsyncAction(
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS',
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS_SUCCESS',
    'GET_CALL_BARRING_OPTIONS_CALL_SETTINGS_FAILED',
)<undefined, payloads.CallBarringOptionsResponsePayload, undefined>();

export const getCustomerCallScreeningConditions = createAsyncAction(
    'GET_CUSTOMER_CALL_SCREENING_CONDITIONS',
    'GET_CUSTOMER_CALL_SCREENING_CONDITIONS_SUCCESS',
    'GET_CUSTOMER_CALL_SCREENING_CONDITIONS_FAILED',
)<payloads.CustomerCallScreeningConditionsPayload, GetCPConditionListResponsePayload, undefined>();

export const getCustomerResponseMessage = createAsyncAction(
    'GET_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS',
    'GET_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_SUCCESS',
    'GET_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_FAILED',
)<payloads.CustomerResponseMessagesPayload, ApiFileList, undefined>();

export const updateRuleStatus = createAction(
    'UPDATE_CP_RULE_STATUS',
)<payloads.UpdateRuleStatusPayload, undefined>();

export const addNewPolicy = createAsyncAction(
    'ADD_NEW_CALL_SETTINGS_POLICY',
    'ADD_NEW_CALL_SETTINGS_POLICY_SUCCESS',
    'ADD_NEW_CALL_SETTINGS_POLICY_FAILED',
)<payloads.NewPolicyPayload, GetCallProcessingPolicyListEntity[], APIErrorInterface>();

export const deletePolicy = createAsyncAction(
    'DELETE_CALL_SETTINGS_POLICY',
    'DELETE_CALL_SETTINGS_POLICY_SUCCESS',
    'DELETE_CALL_SETTINGS_POLICY_FAILED',
)<payloads.DeletePolicyPayload, payloads.DeletePolicyPayload, APIErrorInterface>();

export const editPolicy = createAsyncAction(
    'EDIT_CALL_SETTINGS_POLICY',
    'EDIT_CALL_SETTINGS_POLICY_SUCCESS',
    'EDIT_CALL_SETTINGS_POLICY_FAILED',
)<payloads.EditPolicyPayload, GetCallProcessingPolicyListEntity[], APIErrorInterface>();

export const deletePolicyApiErrorStateUpdate = createAction(
    'DELETE_CALL_SETTINGS_POLICY_API_ERROR_STATUS',
)<APIErrorInterface | undefined, undefined>();

export const updateRulesOrder = createAsyncAction(
    'UPDATE_CP_RULES_ORDER',
    'UPDATE_CP_RULES_ORDER_SUCCESS',
    'UPDATE_CP_RULES_ORDER_FAILED',
)<GetCallProcessingPolicyListEntity, GetCallProcessingPolicyListEntity[], undefined>();

export const addMode = createAsyncAction(
    'ADD_NEW_CALL_SETTINGS_MODE',
    'ADD_NEW_CALL_SETTINGS_MODE_SUCCESS',
    'ADD_NEW_CALL_SETTINGS_MODE_FAILED',
)<payloads.AddEditOperationModePayload, OperationMode[], APIErrorInterface>();

export const editMode = createAsyncAction(
    'EDIT_CALL_SETTINGS_MODE',
    'EDIT_NEW_CALL_SETTINGS_MODE_SUCCESS',
    'EDIT_NEW_CALL_SETTINGS_MODE_FAILED',
)<payloads.AddEditOperationModePayload, OperationMode[], APIErrorInterface>();

export const deleteMode = createAsyncAction(
    'DELETE_CALL_SETTINGS_MODE',
    'DELETE_CALL_SETTINGS_MODE_SUCCESS',
    'DELETE_CALL_SETTINGS_MODE_FAILED',
)<payloads.DeleteModePayload, OperationMode[], APIErrorInterface>();

export const addCpCondition = createAsyncAction(
    'ADD_NEW_CP_CONDITION_MODE',
    'ADD_NEW_CP_CONDITION_MODE_SUCCESS',
    'ADD_NEW_CP_CONDITION_MODE_FAILED',
)<payloads.AddCpConditionPayload, CPConditionInfo[], APIErrorInterface>();

export const editCpCondition = createAsyncAction(
    'EDIT_CP_CONDITION_MODE',
    'EDIT_CP_CONDITION_MODE_SUCCESS',
    'EDIT_CP_CONDITION_MODE_FAILED',
)<payloads.AddCpConditionPayload, CPConditionInfo[], APIErrorInterface>();

export const deleteCpCondition = createAsyncAction(
    'DELETE_CP_CONDITION_MODE',
    'DELETE_CP_CONDITION_MODE_SUCCESS',
    'DELETE_CP_CONDITION_MODE_FAILED',
)<payloads.DeleteCpConditionPayload, CPConditionInfo[], APIErrorInterface>();

export const addCustomerResponseMessage = createAsyncAction(
    'ADD_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS',
    'ADD_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_SUCCESS',
    'ADD_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_FAILED',
)<payloads.AddEditCustomerResponseMessagePayload, ApiFileList, APIErrorInterface>();

export const editCustomerResponseMessage = createAsyncAction(
    'EDIT_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS',
    'EDIT_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_SUCCESS',
    'EDIT_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_FAILED',
)<payloads.AddEditCustomerResponseMessagePayload, ApiFileList, APIErrorInterface>();

export const deleteCustomerResponseMessage = createAsyncAction(
    'DELETE_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS',
    'DELETE_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_SUCCESS',
    'DELETE_CUSTOMER_RESPONSE_MESSAGES_CONDITIONS_FAILED',
)<payloads.DeleteCustomerResponseMessagePayload, ApiFileList, APIErrorInterface>();
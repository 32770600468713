import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { UAInfo, UATypeInfo } from '../../store/types/Devices';
import * as Yup from 'yup';
import i18n from '../../services/i18n';

export type DeviceFormProps = {
    model?: UATypeInfo | null;
    mac: string;
    name: string;
    ascii_key?: string;
    inventory_id?: string;
    description?: string;
};

export type NewDeviceDialogProps = {
    isOpen: boolean;
    clickOnClose?: () => void;
};

export const useStyles = makeStyles(() => ({
    error: {
        color: Colors.Error,
        fontSize: 12,
        fontWeight: 400,

        '& svg': {
            display: 'none',
        },
    },

    itemsContainer: {
        padding: '47px 82px 33px 84px',
        display: 'flex',
        flexDirection: 'column',
        width: 472,

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 472,
            marginBottom: 24,
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: 640,
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 560,
            width: 640,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 640,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },
    },

    generateDeviceNameButton: {
        cursor: 'pointer',
        fill: Colors.Gray5,
        marginRight: '5px !important',
        marginTop: '5px !important'
    },

    initMac: {
        '& .MuiInputBase-input.MuiInput-input': {
            color: Colors.LightGraySecondary3,
        },
    },
}));

export const MacAddressPlaceHolder = 'xx:xx:xx:xx:xx:xx';

export const formatMacAddress = (mac: string) => {
    if(!(mac?.length)) {
        return mac;
    }

    mac = mac.toUpperCase();
    const maxLength = MacAddressPlaceHolder.length;
    const format = /([\dA-F]{1,2})/g;
    const separator = ':';
    const onlyValid = [...mac.matchAll(/[\dA-F]/g)].map(e => e[0]).join('');
    const splittedByParts = [...onlyValid.matchAll(format)]
        .map(e => e[0])
        .filter(e => e?.length > 0);
    let formatted = splittedByParts.join(separator);
    
    if(formatted.length > 0 && mac.endsWith(separator) && mac.length === (formatted.length + 1)
        && splittedByParts[splittedByParts.length - 1].length === 2) {
        formatted = formatted + separator;
    }

    if(formatted.length > maxLength) {
        formatted = formatted.substring(0, maxLength);
    }

    return formatted;
};

/**
 * ASCII Key. For Yealink devices, the expected value is a string of 16 Latin1 symbols without whitespaces. 
 * For other devices, the expected value is a string of 16, 32 or 64 characters. 
 * Supported characters are 'a-Z','0-9'. Note that an empty string is accepted as well. 
 * The UI equivalent of this field is the "ASCII/AES key" field on the "Create CPE" panel.
 */
export const formatAsciiAesAddress = (asciiKey: string) => {
    if((asciiKey?.length ?? 0) === 0) {
        return asciiKey;
    }

    const fullMatch = asciiKey.match(/^[-a-zA-Z0-9]{1,64}$/);
    if(!fullMatch) {
        return asciiKey.match(/[-a-zA-Z0-9]{1,64}/g)?.join('') || '';
    }
    
    return asciiKey;
};

export const uaTypeToAddDeviceTransformer = (form: DeviceFormProps) => {
    return {
        ...form,
        i_ua_type: form.model?.i_ua_type,
    } as UAInfo;
}

const requiredError = i18n.t<string>('errors:common.emptyInput');
const macFormatInvalidError = i18n.t<string>('errors:devices.macFormatInvalid', {
    format: MacAddressPlaceHolder
});

export const addDeviceValidationSchema = Yup.object().shape({
    model: Yup.object().when('i_ua', {
        is: (i_ua: number) => {
            return i_ua && i_ua > 0;
        },
        then: Yup.object()
            .nullable()
            .notRequired(),
        otherwise: Yup.object()
            .nullable()
            .required(i18n.t<string>('errors:devices.modelNotSelected')),
    }),
    mac: Yup.string()
        .required(requiredError)
        .min(MacAddressPlaceHolder.length, macFormatInvalidError)
        .max(MacAddressPlaceHolder.length, macFormatInvalidError),
    name: Yup.string()
        .required(requiredError),
    ascii_key: Yup.string().when('model', {
            is: (model: UATypeInfo) => {
                return !model || model?.manufacturer === 'Yealink';
            },
            then: Yup.string()
                .nullable()
                .notRequired()
                .min(16, i18n.t<string>('errors:devices.invalidYealinkAsciiLength'))
                .max(16, i18n.t<string>('errors:devices.invalidYealinkAsciiLength')),
            otherwise: 
                Yup.string().when('ascii_key', {
                    is: (ascii_key: string) => {
                        return ((ascii_key?.length || 0) === 0) 
                            || ascii_key.length === 16
                            || ascii_key.length === 32
                            || ascii_key.length === 64;
                    },
                    then: Yup.string()
                        .nullable()
                        .notRequired(),
                    otherwise: Yup.string()
                        .nullable()
                        .notRequired()
                        .min(2, i18n.t<string>('errors:devices.invalidNotYealinkAsciiLength'))
                        .max(2, i18n.t<string>('errors:devices.invalidNotYealinkAsciiLength'))
                })
        }),
    inventory_id: Yup.string()
        .nullable()
        .notRequired(),
    description: Yup.string()
        .nullable()
        .notRequired()
});

export const getRoute = (route: string, options: object) => {
    let output = route;

    for (const [key, value] of Object.entries(options)) {
        const search = `:${key}`;

        // @ts-ignore
        output = output.replace(search, value);
    }

    return output;
};

export const macAddressLengthFuncForMask = (v: string | undefined) => {
    const d = v?.replace(new RegExp(":", 'g'), "");
    return d?.length || 0;
}

export const macAddressLengthFunc = (v: string | undefined) => {
    const d = v
        ?.replace(new RegExp(":", 'g'), "")
        ?.replace(new RegExp("x", 'g'), "");
    return d?.length || 0;
}
import {makeStyles} from '@material-ui/core/styles';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import {IconButton} from '@material-ui/core';
import {CloudDownload} from '@material-ui/icons';
import React from 'react';
import BillStatus from '../../../components/BillStatus/BillStatus';
import {convertUtcToUserLocalTime} from '../../../utils/dateWithTimezoneConversion';
import EmptyRowContent from '../../../components/DataTable/EmptyRowContent';
import CustomizedTooltip from '../../../components/Tooltip/Tooltip';
import dayjs from './../../../services/customDayJs';

export const useStyles = makeStyles(() => ({
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },

    header: {
        '& th:nth-child(2)': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& th:nth-child(3)': {
            display: 'flex',
            justifyContent: 'end',
            textAlign: 'right'
        },
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& th:nth-child(7)': {
            display: ' flex',
            justifyContent: 'center',
        },
    },

    tableContent: {
        '& td:nth-child(2)': {
            display: ' flex',
            justifyContent: 'center',
        },
        '& td:nth-child(3)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& td:nth-child(4)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& td:nth-child(7)': {
            display: ' flex',
            justifyContent: 'center',
        },
    },
}));

export interface InvoiceTableRow {
    id: number;
    number: number;
    status: string;
    i_status: number;
    period_total: string;
    paid: string;
    boldPeriodTotal: boolean;
    issued_at: string;
    due_date: string;
    period_from: string;
    period_to: string;
    fileExist: boolean;
    currency: string;
}

export function makePeriodText(from: string, to: string, isSameYear: boolean) {
    const fromFormat = 'D MMM';
    const toFormat = 'D MMM, YYYY';
    const mainFormat = 'DD MMM YYYY';

    return isSameYear
        ? dayjs(from).format(fromFormat) + ' - ' + dayjs(to).format(toFormat)
        : dayjs(from).format(mainFormat) + ' - ' + dayjs(to).format(mainFormat);
}

export const generateColumns = (
    t: TFunction<string>,
    currency = 'USD',
    timezoneOffset: number,
    downloadFile: (invoice_number: number, index: number) => void,
    userDateTimeFormat?: string,
): Column<InvoiceTableRow>[] => {
    let from = '';
    let to = '';
    let isSameYear = false;
    let periodText = '';

    return [
        {
            Header: t<string>('screens:billing.invoiceTitle'),
            accessor: 'number',
            width: 100,
            Cell: function Cell(params) {
                return params.row.original.number;
            },
        },
        {
            Header: t<string>('common:status'),
            accessor: 'status',
            Cell: function Cell(params) {
                return (
                    <BillStatus
                        i_invoice_status={params.row.original.i_status}
                        status={params.row.original.status}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:billing.periodTitle', {
                currency: currency,
            }),
            accessor: 'period_total',
            Cell: function Cell(params) {
                const isBold = params.row.original.boldPeriodTotal;
                return (
                    <span style={{fontWeight: isBold ? 'bold' : 'normal'}}>
                        {params.row.original.period_total}
                    </span>
                );
            },
        },
        {
            Header: t<string>('screens:billing.paidTitle', {
                currency: currency,
            }),
            accessor: 'paid',
            Cell: function Cell(params) {
                const numberValue = Number(params.row.original.paid);
                return numberValue ? (
                    params.row.original.paid
                ) : (
                    <EmptyRowContent/>
                );
            },
        },
        {
            Header: t<string>('screens:billing.issuedAtTile'),
            accessor: 'issued_at',
            Cell: function Cell(params) {
                from = convertUtcToUserLocalTime(
                    params.row.original.period_from,
                    timezoneOffset,
                );

                to = convertUtcToUserLocalTime(
                    params.row.original.period_to,
                    timezoneOffset,
                );

                isSameYear = dayjs(params.row.original.period_from).isSame(
                    dayjs(params.row.original.period_to),
                    'year',
                );
                periodText = makePeriodText(from, to, isSameYear);

                return (
                    <CustomizedTooltip
                        title={t<string>('screens:billing.fromTo', {
                            period: periodText,
                        })}
                        copy={false}
                        dataTestId={'issued-at-period-tooltip'}
                    >
                        <span>
                            {convertUtcToUserLocalTime(
                                params.row.original.issued_at,
                                timezoneOffset,
                                userDateTimeFormat,
                            )}
                        </span>
                    </CustomizedTooltip>
                );
            },
        },
        {
            Header: t<string>('screens:billing.dueDateTilte'),
            accessor: 'due_date',
            Cell: function Cell(params) {
                return (
                    <CustomizedTooltip
                        title={t<string>('screens:billing.fromTo', {
                            period: periodText,
                        })}
                        copy={false}
                        dataTestId={'due-date-period-tooltip'}
                    >
                        <span>
                            {convertUtcToUserLocalTime(
                                params.row.original.due_date,
                                timezoneOffset,
                                userDateTimeFormat,
                            )}
                        </span>
                    </CustomizedTooltip>
                );
            },
        },
        {
            Header: t<string>('common:download'),
            accessor: 'fileExist',
            Cell: function Cell(params) {
                const index = params.row.index;
                return params.row.original.fileExist ? (
                    <CustomizedTooltip
                        copy={false}
                        title={t<string>('tooltips:billing.downloadInvoice')}
                    >
                        <IconButton
                            size="small"
                            data-qa="download-icon"
                            data-testid="download-icon"
                            onClick={() =>
                                downloadFile(params.row.original.number, index)
                            }
                        >
                            <CloudDownload/>
                        </IconButton>
                    </CustomizedTooltip>
                ) : (
                    ''
                );
            },
        },
    ];
};

export const keyboardDateTimePickerMaskPropsForDMONYYFormat = {
    mask: '__-___-__ __:__:__',
    maskChar: '_',
    refuse: /[^a-zA-Z0-9]+/gi,
    onKeyDown: (e: any) => {
        if (
            (e.key === '-' || e.key === 'ArrowRight') &&
            /[1-9]_-___-__ __:__:__/.test(e.nativeEvent?.target?.value)
        ) {
            const event = new Event('change', {
                bubbles: true,
            });
            e.nativeEvent.target.value = `0${e.nativeEvent.target.value[0]}-___-__ __:__:__`;
            e.nativeEvent.target.setSelectionRange(2, 2);
            e.nativeEvent.target.dispatchEvent(event);
        }
    },
};

export const keyboardDatePickerMaskPropsForDMONYYFormat = {
    mask: '__-___-__',
    maskChar: '_',
    refuse: /[^a-zA-Z0-9]+/gi,
    onKeyDown: (e: any) => {
        if (
            (e.key === '-' || e.key === 'ArrowRight') &&
            /[1-9]_-___-__/.test(e.nativeEvent?.target?.value)
        ) {
            const event = new Event('change', {
                bubbles: true,
            });
            e.nativeEvent.target.value = `0${e.nativeEvent.target.value[0]}-___-__`;
            e.nativeEvent.target.setSelectionRange(2, 2);
            e.nativeEvent.target.dispatchEvent(event);
        }
    },
};

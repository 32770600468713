import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { ServiceFeature } from '../../../types/ServiceFeature';

export interface ServiceFeaturesReducerState {
    serviceFeatures?: ServiceFeature[];
}

export const initialState: ServiceFeaturesReducerState = {};

export type ServiceFeaturesReducerActions = ActionType<typeof actions>;

export const serviceFeaturesReducer = createReducer<
    ServiceFeaturesReducerState,
    ServiceFeaturesReducerActions
>(initialState).handleAction(
    actions.getServiceFeatures.success,
    (state, action) => ({
        ...state,
        serviceFeatures: action.payload,
    }),
);

export default serviceFeaturesReducer;

import React from 'react';
import DataGrid from '../../DataGrid/DataGrid';

import { PaginationMode } from '../../DataGrid/types';
import { useTranslation } from 'react-i18next';
import { DataServiceEntityTransformer } from '../../../utils/transformers';
import {
    DataServiceTableRowInterface,
    generateColumns,
    useStyles,
    DataServiceInterface,
} from './DataService.utils';
import { CallHistory } from '../../../store/types/CallHistory';

const DataService: React.VFC<DataServiceInterface> = ({
    currency,
    data,
    onPageChange,
    onPageSizeChange,
    initialPageSize,
    loading,
    rowCount,
    userDateTimeFormat,
    unit,
    ratio,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const columns = generateColumns(t, currency, unit, userDateTimeFormat);

    const items: DataServiceTableRowInterface[] = data.map(
        (item: CallHistory) => DataServiceEntityTransformer(item, ratio),
    );

    return (
        <DataGrid<DataServiceTableRowInterface>
            data={items}
            dataQa={'data-service-table'}
            loading={loading}
            columns={columns}
            rowCount={rowCount}
            paginationMode={PaginationMode.Server}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            initialPageSize={initialPageSize}
            classes={{
                header: classes.header,
                tableContainer: classes.tableContent,
            }}
        />
    );
};

export default DataService;

import React, {useEffect} from 'react';
import {useTabStyles} from '../../Extensions/ExtensionDetails/utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import MenuDragForm from '../../../components/Forms/AutoAttendants/MenuDragForm';
import Loader from '../../../components/Loader/Loader';
import {Formik} from 'formik';
import {IvrFlowFormType} from '../../../components/Forms/AutoAttendants/IvrFlowDiagram/IvrFlowDiagram';
import {useAutoAttendantMenuFormTab} from '../../../hooks/useAutoAttendantMenuFormTab';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import TabErrors from "../../../components/TabErrors/TabErrors";

const CallFlowTab: React.VFC<TabInterface> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        isActive,
        tabName
    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.callFlowTab?.isLoading ||
            false,
    );

    const {initValues} = useAutoAttendantMenuFormTab();

    useEffect(() => {
        if (!isDataLoading && initValues && initValues.menus?.length > 0) {
            handleInitDataChange?.(initValues);
            handleSubmitForm?.(initValues);
        }
    }, [isDataLoading, initValues]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-flow-details-loader"/>
        </div>
    ) : (
        <Formik<IvrFlowFormType>
            initialValues={initValues}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={handleSubmitForm!}
        >
            {({errors, isSubmitting}) => {

                return (
                    <>
                        <MenuDragForm
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                        />
                        <TabErrors
                            show={(Object.keys(errors).length && !isActive) || false}
                            tabName={tabName || ''}
                            isSubmitting={isSubmitting}
                        />
                    </>
                )
            }
            }

        </Formik>
    );
};

export default CallFlowTab;

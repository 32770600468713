import axios from 'axios';

import { config } from '../config';
import { toast } from 'react-hot-toast';
import i18n from '../../services/i18n';
import camelCase from 'camelcase';

export const api = axios.create();

export const PAYLOAD_KEY = 'params=';
export const CONTENT_TYPE = 'application/x-www-form-urlencoded';

const CHECK_AUTH_FAILD_CODE = 'Server.Session.check_auth.auth_failed';
const CHECK_CSRF_TOKEN_INVALID_CODE = 'Server.Session.check_auth.csrf_token_mismatch';
const CHECK_CSRF_TOKEN_MISSING_CODE = 'Server.Session.check_auth.csrf_token_missing';
const CHECK_YOU_MUST_CHANGE_PASSWORD = 'Server.Session.alert_You_must_change_password';

let logoutGuard = false;

api.interceptors.request.use(
    (axiosConfig) => ({
        ...axiosConfig,
        baseURL: config?.baseURL as string | undefined,
    }),
    (error) => Promise.reject(error),
);

api.interceptors.response.use(undefined, (error) => {
    const logOut = (delay = 10) => {
        localStorage.removeItem('session_id');
        localStorage.removeItem('user_info');
        localStorage.removeItem('user_email');
        localStorage.removeItem('csrf_token');
        localStorage.removeItem('default_portal_view_configuration');
        localStorage.removeItem('porta_one_menu');
        localStorage.removeItem('user_login');
        setTimeout(() => {
            logoutGuard = false;
            window.location.reload();
        }, delay);
    };

    if (
        error.response?.data.faultcode == CHECK_AUTH_FAILD_CODE &&
        !logoutGuard
    ) {
        logoutGuard = true;
        const camelFaultCode = camelCase(CHECK_AUTH_FAILD_CODE);
        toast(i18n.t<string>('common:' + camelFaultCode));
        logOut();
    }
    if (error.response?.status == 500 &&
        error.response?.data.faultcode == CHECK_CSRF_TOKEN_INVALID_CODE &&
        !logoutGuard
    ) {
        logoutGuard = true;
        const camelFaultCode = camelCase(CHECK_CSRF_TOKEN_INVALID_CODE);
        toast(i18n.t<string>('common:' + camelFaultCode));
        logOut();
    }
    if (error.response?.status == 500 &&
        error.response?.data.faultcode == CHECK_CSRF_TOKEN_MISSING_CODE &&
        !logoutGuard
    ) {
        logoutGuard = true;
        const camelFaultCode = camelCase(CHECK_CSRF_TOKEN_MISSING_CODE);
        toast(i18n.t<string>('common:' + camelFaultCode));
        logOut();
    }
    if (error.response?.status == 500 &&
        error.response?.data.faultcode == CHECK_YOU_MUST_CHANGE_PASSWORD &&
        !logoutGuard
    ) {
        logoutGuard = true;
        if(localStorage.getItem('user_login_page') !== 'true')
        {
            logOut();
        }
    }

    return Promise.reject(error);
});

export function buildPayload(data: any): string {
    return PAYLOAD_KEY + encodeURIComponent(JSON.stringify(data));
}

import React, {useEffect} from 'react';
import {useExtensionFormTab} from '../../../hooks/useExtensionFormTab';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {useTabStyles} from './utils';
import {Formik} from 'formik';
import ExtensionForm from '../../../components/Forms/Extensions/Extension/ExtensionForm';
import {
    ExtensionFormType,
    generateExtensionFormValidationSchema,
} from '../../../components/Forms/Extensions/Extension/ExtensionForm.utils';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import TabErrors from "../../../components/TabErrors/TabErrors";
import { DID_NUMBERS_FETCH_API_ERROR } from '../../../store/sagas/extensions/extensions/saga';
import AccessDenied from '../../../components/PermissionProvider/AccessDenied';

const ExtensionTab: React.VFC<TabInterface & { prefixEnabled?: boolean, callback?:()=>void }> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        prefixEnabled,
        callback
    }) => {
    const classes = useTabStyles();
    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.extensionTab?.isLoading ||
            false,
    );

    const {data, initialValues} = useExtensionFormTab();

    useEffect(() => {
        if (!isDataLoading && initialValues) {
            handleInitDataChange?.(initialValues);
            handleSubmitForm?.(initialValues);
        }
    }, [isDataLoading]);

    if(data?.apiErrors?.faultcode === DID_NUMBERS_FETCH_API_ERROR) {
        return <AccessDenied subPageMode />;
    }

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="extension-details-loader"/>
        </div>
    ) : (
        <Formik<ExtensionFormType>
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={(form) => handleSubmitForm?.(form)}
            validationSchema={generateExtensionFormValidationSchema(
                data.passwordConfig?.Web?.PasswordMinLength || 6,
            )}
        >

            {({}) => {

                return (
                    <>
                        <ExtensionForm
                            didNumbers={data.didNumbers}
                            accountId={data.accountId}
                            isBranchOffice={data.isBranchOffice}
                            timeZones={data.timeZonesList}
                            countries={data.countriesList}
                            webConfig={data.passwordConfig}
                            subdivisionsList={data.subdivisionsList}
                            apiErrors={data.apiErrors}
                            handleSubmitForm={handleSubmitForm}
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                            handleSetIsValidFunc={handleSetIsValidFunc}
                            prefixEnabled={prefixEnabled}
                            callback={callback}
                        />
                    </>

                )
            }

            }


        </Formik>
    );
};

export default ExtensionTab;

import classNames from 'classnames';
import {
    AutoAttendantMenu,
    AutoAttendantMenuTransition,
    MenuAction,
} from '../../store/types/AutoAttendant';
import {Grid} from '@material-ui/core';
import CustomizedCheckbox from '../Checkbox/Checkbox';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import FileSelect from '../FileSelect/FileSelect';
import {actions} from '../../store';
import TextField from '../TextField/TextField';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {SettingsFormType} from '../Forms/AutoAttendantsMenus/SettingsForm.utils';
import {useStyles} from './utils';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {useTranslation} from 'react-i18next';
import {CallQueue} from '../../store/types/CallQueue';
import {
    MultiLevelSelectField,
    MultiLevelSelectItem,
} from '../MultiLevelSelectField/MultiLevelSelectField';
import {usePermissionContext} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';
import { ServiceFeatureName } from '../../store/types/ServiceFeature';

const WhenMenuIsActive = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const DISAStatus = useSelector<ReduxState, boolean>(
        (state) => state.autoAttendants.incomingTab?.enableDisa === 'Y',
    );

    const noActiveTransition = useSelector<ReduxState,
        AutoAttendantMenuTransition | undefined>((state) =>
        state.menus.menuTransitions?.find((v) => v.event === 'Not Active'),
    );

    const callQueues = useSelector<ReduxState, CallQueue[]>(
        (state) => state.callQueues.callQueues || [],
    );

    const menus = useSelector<ReduxState, AutoAttendantMenu[]>(
        (state) => state.autoAttendants.autoAttendantMenus || [],
    );
    
    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
            ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    const permission = usePermissionContext();

    const {
        values,
        errors,
        setFieldValue,
        handleChange,
        setFieldError,
    } = useFormikContext<SettingsFormType>();

    const inactiveMenuActions: MultiLevelSelectItem[] = useMemo(
        () =>
            Object.values(MenuAction).map((v) => ({
                label: v=== MenuAction.TransferToFaxMailbox && unifiedMessagingVoicemailEnabled
                    ? t('enums:inactiveMenuAction.VoicemailEnabled')
                    : t(`enums:inactiveMenuAction.${v}`),
                value: v,
                nestedItems: [],
                disabled: false,
            })),
        [],
    );

    const selectedLabel = () => {
        if (values.inactiveMenuAction === MenuAction.Queue) {
            const queue = callQueues.find(
                (v) => v.i_c_queue === values.queue?.i_c_queue,
            );

            return `${t(`enums:inactiveMenuAction.${MenuAction.Queue}`)} / ${
                queue?.group_name
            }`;
        } else if (values.inactiveMenuAction === MenuAction.Menu) {
            const menu = menus.find((v) => v.i_menu === values.menu?.i_menu);
            return `${t(`enums:inactiveMenuAction.${MenuAction.Menu}`)} / ${
                menu?.name
            }`;
        } else if (values.inactiveMenuAction == undefined) {
            return t(`enums:inactiveMenuAction.Disabled`);
        } else if (values.inactiveMenuAction === MenuAction.TransferToFaxMailbox && unifiedMessagingVoicemailEnabled) {
            return t('enums:inactiveMenuAction.VoicemailEnabled');
        } else {
            return t(`enums:inactiveMenuAction.${values.inactiveMenuAction}`);
        }
    };

    const items: MultiLevelSelectItem[] = useMemo(() => {
        const items = [...inactiveMenuActions];

        const menuIndex = items.findIndex((v) => v.value === MenuAction.Menu);

        items[menuIndex].nestedItems = menus.map((v) => ({
            value: v.i_menu,
            label: v.name,
        }));

        if (!menus.length) {
            items[menuIndex].disabled = true;
        }

        const queueIndex = items.findIndex((v) => v.value === MenuAction.Queue);

        items[queueIndex].nestedItems = callQueues.map((v) => ({
            value: v.i_c_queue || 0,
            label: v.group_name || '',
        }));

        if (!callQueues.length) {
            items[queueIndex].disabled = true;
        }

        const dialExtensionIndex = items.findIndex(
            (v) => v.value === MenuAction.DialExtensionDirectly,
        );

        items[dialExtensionIndex].disabled = true;

        if (!DISAStatus) {
            const disaIndex = items.findIndex(
                (v) => v.value === MenuAction.DISA,
            );
            items[disaIndex].disabled = true;
        }

        return items;
    }, [inactiveMenuActions, DISAStatus]);

    const onChangeInactiveAction = useCallback(
        (parentValue: string | number, childValue?: string | number) => {
            if (parentValue === MenuAction.Queue) {
                const queue = callQueues.find(
                    (v) => v.i_c_queue === childValue,
                );
                setFieldValue('queue', queue);
            } else if (parentValue === MenuAction.Menu) {
                const menu = menus.find((v) => v.i_menu === childValue);
                setFieldValue('menu', menu);
            }
            setFieldValue('inactiveMenuAction', parentValue);
        },
        [setFieldValue, menus, callQueues],
    );

    useEffect(() => {
        !values.inactiveMenuAction && setFieldValue('inactiveMenuAction', MenuAction.DoNothing)

    }, []);


    return (
        <>
            <p className={classes.activeHeader}>
                {t('screens:autoAttendants.whenTheMenuIsInactive')}
            </p>

            <MultiLevelSelectField
                items={items}
                value={selectedLabel()}
                onChange={onChangeInactiveAction}
                className={classNames(
                    classes.selectMaxWidth,
                    classes.inactiveActionSelect,
                )}
                readOnly={permission === PermissionType.ReadOnly}
            />

            {(values.inactiveMenuAction === MenuAction.Transfer ||
                values.inactiveMenuAction ===
                MenuAction.TransferToE164Number) && (
                <TextField
                    label={t(
                        'screens:autoAttendants.transferCallerToPhoneNumberExtension',
                    )}
                    id="transferDestination"
                    setFieldError={setFieldError}
                    value={values.transferDestination}
                    onChange={handleChange}
                    required
                    className={classNames(
                        classes.destinationInput,
                        classes.additionalMarginTop,
                    )}
                    maxLength={15}
                    helperText={errors.transferDestination}
                />
            )}

            {values.inactiveMenuAction === MenuAction.PromptForExtension && (
                <Grid
                    className={classNames(
                        classes.centeredGrid,
                        classes.numberPickerGrid,
                    )}
                >
                    <TextField
                        id="maxDigits"
                        label={t('screens:autoAttendants.maxDigits')}
                        onChange={handleChange}
                        value={values.maxDigits}
                        type="number"
                        iconPosition="end"
                        className={classNames(
                            classes.numberInput,
                            classes.additionalMarginTop,
                        )}
                        dataQa="incoming-form-answering-timeout-select"
                        inputProps={{
                            inputProps: {
                                min: 0,
                            },
                            pattern: '[0-9]*',
                        }}
                    />

                    <IconWithTooltip
                        dataQa="play-before-action-tooltip"
                        tooltipText={t('tooltips:autoAttendants.displayNumber')}
                    />
                </Grid>
            )}

            <div className={classes.separator}/>

            <Grid
                className={classNames(
                    classes.centeredGrid,
                    classes.playBeforeGrid,
                )}
            >
                <CustomizedCheckbox
                    checked={values.playBeforeAction}
                    dataQa="play-before-action-status"
                    onChange={(value) =>
                        setFieldValue('playBeforeAction', value.target.checked)
                    }
                    label={t('screens:autoAttendants.playBeforeAction')}
                />

                <IconWithTooltip
                    dataQa="play-before-action-tooltip"
                    tooltipText={t('tooltips:autoAttendants.playBeforeAction')}
                />
            </Grid>

            {values.playBeforeAction && (
                <FileSelect
                    classes={{textField: classes.playFileInput}}
                    nameValue={values.recordBeforeActionName}
                    onFileChange={(file) => {
                        setFieldValue('recordBeforeActionName', file.name);
                        setFieldValue('recordBeforeActionFile', file);
                    }}
                    file={values.recordBeforeActionFile}
                    getFileOnPlay={() => {
                        if (noActiveTransition) {
                            dispatch(
                                actions.getMenuPromptFile.request({
                                    i_menu_transition:
                                    noActiveTransition.i_menu_transition,
                                    callback: (file) => {
                                        setFieldValue(
                                            'recordBeforeActionFile',
                                            file,
                                        );
                                    },
                                }),
                            );
                        }
                    }}
                />
            )}
        </>
    );
};

export default WhenMenuIsActive;

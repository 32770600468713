import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import { ServiceFeatureName } from '../store/types/ServiceFeature';
import {
    extensionFormDefaultValues,
    ExtensionFormType,
} from '../components/Forms/AutoAttendants/ExtensionForm.utils';
import { errorTransformer } from '../utils/transformers';

export const useExtensionAAFromTab = () => {
    const extension = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.extension,
    );

    const { serviceFeatures, errors } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const initValues: ExtensionFormType = useMemo(() => {
        if (!extension) return extensionFormDefaultValues;

        return {
            emailAddress: extension.account_info?.email || '',
            timezone: extension.account_info?.time_zone_name || '',
            office:
                extension?.customerInfo?.i_office_type !== 1
                    ? extension?.customerInfo?.name || ''
                    : '',
            country: extension.account_info?.country || '',
            address: extension.account_info?.baddr1 || '',
            city: extension.account_info?.city || '',
            postalCode: extension.account_info?.zip || '',
            state: extension.account_info?.state || '',
            extensionName: extension?.name || '',
            extensionNumber: extension?.id || '',
            didNumber: extension?.didNumbers || [],
            displayNumber:
                serviceFeatures
                    ?.find((v) => v.name === ServiceFeatureName.Cli)
                    ?.attributes.find((v) => v.name === 'centrex')?.values[0] ??
                '',
        };
    }, [extension, errors]);

    return {
        initValues,
        data: {
            extension,
            errors: errorTransformer(errors),
        },
    };
};

import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/autoAttendants';
import {
    AutoAttendantListItem,
    AutoAttendantMenu,
} from '../../types/AutoAttendant';
import { APIErrorInterface } from '../../types';
import {
    AddonsValidationResults,
    CustomerAgreementCondition,
} from '../../types/CustomerAgreementCondition';
import { CustomerDIDNumberType } from '../../types/CustomerDIDNumber';
import { CustomerInfoDetails } from '../../types/CustomerInfo';
import { ExtensionsListItem } from '../extensions/extensions/reducer';
import { EmailOption } from '../../types/Voicemail';
import { CallBarringRule } from '../../types/CallBarring';
import { FetchAutoAttendantMenuListPayload } from '../../actions/autoAttendants/payloads';

type AutoAttendantDetailsType = {
    extension?: ExtensionsListItem & {
        delete_enable?: boolean;
        customerInfo?: CustomerInfoDetails;
        product_visible_name?: string;
    };
    callFlowTab?: {
        isLoading?: boolean;
    };
    extensionTab?: {
        isLoading?: boolean;
    };
    callSettingsTab?: {
        isLoading?: boolean;
    };
    planTab?: {
        isLoading?: boolean;
    };
};

export interface AutoAttendantsStateType {
    isFormSending?: boolean;
    isFormDataLoading?: boolean;
    autoAttendantInfo?: ExtensionsListItem & { delete_enable?: boolean };
    autoAttendantMenus?: AutoAttendantMenu[];
    customerDidNumbers?: CustomerDIDNumberType[];
    customerInfo?: CustomerInfoDetails;
    products?: {
        mainProducts: CustomerAgreementCondition[];
        addonsProducts: CustomerAgreementCondition[];
        addonsConflicts?: AddonsValidationResults;
    };
    createAutoAttendantDataIsLoading?: boolean;
    isCreatingNewAutoAttendant?: boolean;
    createNewAutoAttendantsErrors?: APIErrorInterface;
    autoAttendantList?: {
        items: AutoAttendantListItem[];
        disabled: number;
        total: number;
        isLoading: boolean;
    };
    deletingInProgress?: boolean;
    incomingTab?: {
        enableDisa?: 'Y' | 'N';
        DISAPassword: string;
        faxFormat: string;
        emailAddressIncoming: string;
        emailOption: EmailOption;
    };
    callBarringRules?: CallBarringRule[];
    autoAttendantDetails?: AutoAttendantDetailsType;
    apiError?: APIErrorInterface;
}

export enum FetchDataStatus {
    NotStart = 0,
    InProgress = 1,
    Done = 2,
}

export enum FetchMenuStatus {
    NotStart = 0,
    InProgress = 1,
    Done = 2,
}

export const initialState: AutoAttendantsStateType = {
    isFormDataLoading: true,
};

export type AutoAttendantsActionsType = ActionType<typeof actions>;

const autoAttendantsReducer = createReducer<
    AutoAttendantsStateType,
    AutoAttendantsActionsType
>(initialState)
    .handleAction(actions.getAutoAttendantDetails.request, (state) => ({
        ...state,
        isFormDataLoading: true,
        autoAttendantMenus: undefined,
    }))
    .handleAction(actions.getAutoAttendantDetails.success, (state) => ({
        ...state,
        isFormDataLoading: false,
    }))
    .handleAction(actions.getAutoAttendantDetails.failure, (state) => ({
        ...state,
        isFormDataLoading: false,
    }))
    .handleAction(actions.editAutoAttendant.request, (state) => ({
        ...state,
        isFormSending: true,
    }))
    .handleAction(actions.editAutoAttendant.success, (state) => ({
        ...state,
        isFormSending: false,
    }))
    .handleAction(actions.editAutoAttendant.failure, (state) => ({
        ...state,
        isFormSending: false,
    }))
    .handleAction(actions.getAutoAttendantMenus.success, (state, action) => ({
        ...state,
        autoAttendantMenus: action.payload,
    }))
    .handleAction(actions.getAutoAttendantInfo.request, (state) => ({
        ...state,
        apiError: undefined
    }))
    .handleAction(actions.getAutoAttendantInfo.success, (state, action) => ({
        ...state,
        autoAttendantInfo: action.payload,
        apiError: undefined
    }))
    .handleAction(actions.getAutoAttendantInfo.failure, (state, action) => ({
        ...state,
        autoAttendantInfo: undefined,
        apiError: action.payload
    }))
    .handleAction(actions.getCustomerDidNumbers.success, (state, action) => ({
        ...state,
        customerDidNumbers: action.payload,
    }))
    .handleAction(actions.getCustomerInfo.success, (state, action) => ({
        ...state,
        customerInfo: action.payload,
    }))
    .handleAction(actions.createAutoAttendantDetailsData.request, (state) => ({
        ...state,
        createAutoAttendantDataIsLoading: true,
    }))
    .handleAction(actions.createAutoAttendantDetailsData.success, (state) => ({
        ...state,
        createAutoAttendantDataIsLoading: false,
    }))
    .handleAction(actions.createNewAutoAttendant.request, (state) => ({
        ...state,
        isCreatingNewAutoAttendant: true,
    }))
    .handleAction(actions.createNewAutoAttendant.success, (state) => ({
        ...state,
        isCreatingNewAutoAttendant: false,
    }))
    .handleAction(actions.createNewAutoAttendant.failure, (state, action) => ({
        ...state,
        isCreatingNewAutoAttendant: false,
        createNewAutoAttendantsErrors: action.payload,
    }))
    .handleAction(actions.getAutoAttendantsList.request, (state) => ({
        ...state,
        autoAttendantList: {
            items: [],
            disabled: 0,
            total: 0,
            isLoading: true,
        },
        autoAttendants: {
            autoAttendantMenus: null
        }
    }))
    .handleAction(actions.getAutoAttendantsList.success, (state, action) => ({
        ...state,
        autoAttendantList: {
            items: action.payload.items,
            total: action.payload.total,
            disabled: action.payload.disabled,
            isLoading: false,
        },
        autoAttendants: {
            autoAttendantMenus: null
        }
    }))
    .handleAction(actions.getAutoAttendantsList.failure, (state) => ({
        ...state,
        autoAttendantList: {
            items: [],
            total: 0,
            disabled: 0,
            isLoading: false,
        },
        autoAttendants: {
            autoAttendantMenus: null
        }
    }))
    .handleAction(actions.getAutoAttendantMenuList.request, (state, action) => {
        return updateFetchMenuState(state, action.payload, FetchMenuStatus.InProgress);
    })
    .handleAction(actions.getAutoAttendantMenuList.success, (state, action) => {
        return updateFetchMenuState(state, action.payload, FetchMenuStatus.Done);
    })
    .handleAction(actions.getAutoAttendantMenuList.failure, (state, action) => {
        return updateFetchMenuState(state, action.payload, FetchMenuStatus.Done);
    })
    .handleAction(actions.getDISAState.success, (state, action) => ({
        ...state,
        incomingTab: {
            ...state.incomingTab,
            enableDisa: action.payload.enableDisa,
            DISAPassword: action.payload.DISAPassword,
            faxFormat: action.payload.faxFormat,
            emailAddressIncoming: action.payload.emailAddressIncoming,
            emailOption: action.payload.emailOption,
        },
    }))
    .handleAction(actions.deleteCustomerAutoAttendant.request, (state) => ({
        ...state,
        autoAttendantList: {
            ...state.autoAttendantList,
            items: state.autoAttendantList?.items || [],
            total: state.autoAttendantList?.items.length || 0,
            isLoading: false,
            disabled: state.autoAttendantList?.disabled || 0,
        },
        deletingInProgress: true,
    }))
    .handleAction(
        actions.deleteCustomerAutoAttendant.success,
        (state, action) => {
            const updated =
                state.autoAttendantList?.items.filter(
                    (i) => i.extension_id != action.payload.id,
                ) || [];

            return {
                ...state,
                autoAttendantList: {
                    ...state.autoAttendantList,
                    items: updated,
                    total: updated.length,
                    isLoading: false,
                    disabled: state.autoAttendantList?.disabled || 0,
                },
                deletingInProgress: false,
            };
        },
    )
    .handleAction(actions.deleteCustomerAutoAttendant.failure, (state) => ({
        ...state,
        autoAttendantList: {
            ...state.autoAttendantList,
            items: state.autoAttendantList?.items || [],
            total: state.autoAttendantList?.items.length || 0,
            isLoading: false,
            disabled: state.autoAttendantList?.disabled || 0,
        },
        deletingInProgress: true,
    }))
    // @ts-ignore
    .handleAction(actions.setAutoAttendantStatus.success, (state, action) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                extension: {
                    ...state.autoAttendantDetails?.extension,
                    account_info: {
                        ...state.autoAttendantDetails?.extension?.account_info,
                        blocked: action.payload.account_info.blocked
                    }
                }
            }
        };
    })
    .handleAction(actions.getAutoAttendantBasicDetailsData.request, (state) => {
        return {
            ...state,
            isFormDataLoading: true,
            apiError: undefined,
            autoAttendantDetails: {
                extension: undefined,
                extensionTab: {
                    isLoading: true,
                },
                callSettingsTab: {
                    isLoading: true,
                },
                planTab: {
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(
        actions.getAutoAttendantBasicDetailsData.success,
        (state, action) => {
            return {
                ...state,
                isFormDataLoading: false,
                autoAttendantDetails: {
                    ...state.autoAttendantDetails,
                    extension: action.payload,
                },
            };
        },
    )
    .handleAction(actions.getCallFlowTabData.request, (state) => {
        return {
            ...state,

            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                callFlowTab: {
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(actions.getCallFlowTabData.success, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                callFlowTab: {
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(actions.getCallFlowTabData.failure, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                callFlowTab: {
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(actions.getAutoAttendantExtensionTabData.request, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                extensionTab: {
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(actions.getAutoAttendantExtensionTabData.success, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                extensionTab: {
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(
        actions.getAutoAttendantCallSettingsTabData.request,
        (state) => {
            return {
                ...state,
                autoAttendantDetails: {
                    ...state.autoAttendantDetails,
                    callSettingsTab: {
                        isLoading: true,
                    },
                },
            };
        },
    )
    .handleAction(
        actions.getAutoAttendantCallSettingsTabData.success,
        (state) => {
            return {
                ...state,
                autoAttendantDetails: {
                    ...state.autoAttendantDetails,
                    callSettingsTab: {
                        isLoading: false,
                    },
                },
            };
        },
    )
    .handleAction(actions.getAutoAttendantPlanTabData.request, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                planTab: {
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(actions.getAutoAttendantPlanTabData.success, (state) => {
        return {
            ...state,
            autoAttendantDetails: {
                ...state.autoAttendantDetails,
                planTab: {
                    isLoading: false,
                },
            },
        };
    });
function updateFetchMenuState(
    state: AutoAttendantsStateType,
    payload: FetchAutoAttendantMenuListPayload,
    fetchMenuStatus: FetchMenuStatus,
) {
    return {
        ...state,
        autoAttendantList: {
            ...state.autoAttendantList,
            items:
                state.autoAttendantList?.items.map((o) => {
                    if (o.id_pin == payload.id_pin) {
                        return {
                            ...o,
                            menu: {
                                items: payload.items ?? [],
                                fetchMenu: fetchMenuStatus,
                            },
                            account_info: {
                                ...o.account_info,
                                um_domain: payload.um_domain,
                            }
                        };
                    }
                    return o;
                }) || [],
            total: state.autoAttendantList?.total || 0,
            isLoading: state.autoAttendantList?.isLoading || false,
            disabled: state.autoAttendantList?.disabled || 0
        },
    } as AutoAttendantsStateType;
}

export default autoAttendantsReducer;

import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/generic';
import { Country } from '../../types/Country';
import { LocaleLanguages, TimeZone } from '../../types/TimeZone';
import { SessionData } from '../../types/Session';
import { Subdivision } from '../../types/Subdivision';
import { ConfigData } from '../../types/ConfigData';
import { CustomerInfo, CustomerOfficeType, GlobalCurrencyInfo } from '../../types/CustomerInfo';

export interface GenericStateType {
    countriesList: Country[];
    timeZonesList: TimeZone[];
    subdivisionsList: Subdivision[];
    globalCurrency?: GlobalCurrencyInfo;
    sessionData?: SessionData;
    configData?: ConfigData;
    languagesList?: LocaleLanguages[];
    globalCustomerInfo?: CustomerInfo;
    customerSubdivisionsList?: CustomerInfo[];
    customerOfficeType?: CustomerOfficeType;
}

const localSessionData = localStorage.getItem('user_info');

export const initialState: GenericStateType = {
    countriesList: [],
    timeZonesList: [],
    subdivisionsList: [],
    sessionData: localSessionData ? JSON.parse(localSessionData) : undefined,
};

export type GenericActionsType = ActionType<typeof actions>;

const genericReducer = createReducer<GenericStateType, GenericActionsType>(
    initialState,
)
    .handleAction(actions.countriesList.success, (state, action) => ({
        ...state,
        countriesList: action.payload,
    }))
    .handleAction(actions.timeZonesList.success, (state, action) => ({
        ...state,
        timeZonesList: action.payload,
    }))
    .handleAction(actions.getSubdivisionData.success, (state, action) => ({
        ...state,
        subdivisionsList: action.payload,
    }))
    .handleAction(actions.getSessionData.success, (state, action) => ({
        ...state,
        sessionData: action.payload,
    }))
    .handleAction(actions.getConfigData.success, (state, action) => ({
        ...state,
        configData: action.payload,
    }))
    .handleAction(actions.getGlobalCurrencyData.success, (state, action) => ({
        ...state,
        globalCurrency: action.payload,
    }))
    .handleAction(actions.localLanguagesList.success, (state, action) => ({
        ...state,
        languagesList: action.payload,
    }))
    .handleAction(actions.getGlobalCustomerInfo.success, (state, action) => ({
        ...state,
        globalCustomerInfo: action.payload,
        customerOfficeType: 
            state.customerSubdivisionsList?.length === 0
            ? CustomerOfficeType.NoOffices
            : action.payload?.customer_info?.i_office_type || CustomerOfficeType.NoOffices
    }))
    .handleAction(actions.getCustomerSubdivisionsList.success, (state, action) => ({
        ...state,
        customerSubdivisionsList: action.payload,
    }))
    .handleAction(actions.getCustomerSubdivisionsList.failure, (state) => {
        return {
            ...state,
            isFormLoading: false,
        };
    })
    .handleAction(actions.officeTypeUpdated, (state, action) => ({
        ...state,
        customerOfficeType: action.payload
    }));

export default genericReducer;

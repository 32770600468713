import React from 'react';
import CreditAdjustmentsTable from './IndividualTable/CreditAdjustmentsTable';
import { PageChangeEvent } from '../DataGrid/types';
import { CallHistory } from '../../store/types/CallHistory';
import PageUnderConstruction from '../PageConstruction/PageUnderConstruction';
import VoiceCalls from './VoiceCalls/VoiceCalls';
import DataService from './DataService/DataService';
import QuantityBased from './QuantityBased/QuantityBased';
import Subscriptions from './IndividualTable/Subscriptions';
import WiFi from './DataService/WiFi';
import Messaging from './Messaging/Messaging';
import AggregatedHistory from './IndividualTable/AggregatedHistory';
import Taxes from './IndividualTable/Taxes';
import Conferencing from './IndividualTable/Conferencing';
import DidUsage from './DidUsage/DidUsage';
import IPTV from './IPTV/iPTV';
import MeasuredService from './MeasuredService/MeasuredService';
import MobileNetworkProvisioning from './MobileNetworkProvisioning/MobileNetworkProvisioning';
import { makeStyles } from '@material-ui/core/styles';

export interface TransactionTableFactoryInterface {
    iService: number;
    iServiceType?: number;
    data: CallHistory[];
    onPageChange?: (param: PageChangeEvent) => void;
    onPageSizeChange?: (param: PageChangeEvent) => void;
    initialPageSize?: number;
    loading?: boolean;
    rowCount: number;
    currency: string;
    userDateTimeFormat?: string;
    unit: string;
    ratio: number;
}

const useStyles = makeStyles(() => ({
    tableBodyCustom: {
        '& tbody tr td': {
            fontSize: '14px !important',
        },
    },
}));

const TransactionTableFactory: React.VFC<TransactionTableFactoryInterface> = ({
    iServiceType,
    iService,
    currency,
    data,
    rowCount,
    initialPageSize,
    onPageSizeChange,
    onPageChange,
    loading,
    userDateTimeFormat,
    unit,
    ratio,
}) => {
    const classes = useStyles();

    //individual services
    if ([1, 2].includes(iService)) {
        return (
            <CreditAdjustmentsTable
                data={data}
                currency={currency}
                rowCount={rowCount}
                initialPageSize={initialPageSize}
                onPageSizeChange={onPageSizeChange}
                loading={loading}
                onPageChange={onPageChange}
                userDateTimeFormat={userDateTimeFormat}
                className={classes.tableBodyCustom}
                iService={iService}
            />
        );
    }

    if ([4, 11, 12].includes(iService)) {
        switch (iService) {
            case 4:
                return (
                    <Subscriptions
                        data={data}
                        currency={currency}
                        rowCount={rowCount}
                        initialPageSize={initialPageSize}
                        onPageSizeChange={onPageSizeChange}
                        loading={loading}
                        onPageChange={onPageChange}
                        userDateTimeFormat={userDateTimeFormat}
                        className={classes.tableBodyCustom}
                    />
                );
            case 11:
                return (
                    <AggregatedHistory
                        data={data}
                        currency={currency}
                        rowCount={rowCount}
                        initialPageSize={initialPageSize}
                        onPageSizeChange={onPageSizeChange}
                        loading={loading}
                        onPageChange={onPageChange}
                        userDateTimeFormat={userDateTimeFormat}
                        className={classes.tableBodyCustom}
                    />
                );
            case 12:
                return (
                    <Taxes
                        data={data}
                        currency={currency}
                        rowCount={rowCount}
                        initialPageSize={initialPageSize}
                        onPageSizeChange={onPageSizeChange}
                        loading={loading}
                        onPageChange={onPageChange}
                        userDateTimeFormat={userDateTimeFormat}
                        className={classes.tableBodyCustom}
                    />
                );
        }
    }

    if (iServiceType && [8, 9].includes(iServiceType)) {
        return (
            <WiFi
                data={data}
                currency={currency}
                rowCount={rowCount}
                initialPageSize={initialPageSize}
                onPageSizeChange={onPageSizeChange}
                loading={loading}
                onPageChange={onPageChange}
                userDateTimeFormat={userDateTimeFormat}
                className={classes.tableBodyCustom}
            />
        );
    }

    if (iServiceType && [18, 19].includes(iServiceType)) {
        return (
            <MobileNetworkProvisioning
                data={data}
                currency={currency}
                rowCount={rowCount}
                initialPageSize={initialPageSize}
                onPageSizeChange={onPageSizeChange}
                loading={loading}
                onPageChange={onPageChange}
                userDateTimeFormat={userDateTimeFormat}
                ratio={ratio}
                unit={unit}
                className={classes.tableBodyCustom}
            />
        );
    }

    if (iServiceType && [5, 6].includes(iServiceType)) {
        return (
            <DataService
                data={data}
                currency={currency}
                rowCount={rowCount}
                initialPageSize={initialPageSize}
                onPageSizeChange={onPageSizeChange}
                loading={loading}
                onPageChange={onPageChange}
                userDateTimeFormat={userDateTimeFormat}
                ratio={ratio}
                unit={unit}
                className={classes.tableBodyCustom}
            />
        );
    }

    switch (iServiceType) {
        case 3:
            return (
                <VoiceCalls
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    className={classes.tableBodyCustom}
                />
            );
        case 7:
            return (
                <QuantityBased
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    ratio={ratio}
                    unit={unit}
                    className={classes.tableBodyCustom}
                />
            );
        case 10:
            return (
                <Messaging
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    ratio={ratio}
                    className={classes.tableBodyCustom}
                />
            );
        case 14:
            return (
                <Conferencing
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    ratio={ratio}
                    className={classes.tableBodyCustom}
                />
            );
        case 15:
            return (
                <DidUsage
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    className={classes.tableBodyCustom}
                />
            );
        case 16:
            return (
                <IPTV
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    className={classes.tableBodyCustom}
                />
            );
        case 17:
            return (
                <MeasuredService
                    data={data}
                    currency={currency}
                    rowCount={rowCount}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                    loading={loading}
                    onPageChange={onPageChange}
                    userDateTimeFormat={userDateTimeFormat}
                    ratio={ratio}
                    className={classes.tableBodyCustom}
                />
            );
        default:
            return iService || iServiceType ? <PageUnderConstruction /> : <></>;
    }
};

export default TransactionTableFactory;

export interface LoginPayload {
    login: string;
}

export interface PasswordPayload {
    password: string;
}

export interface SessionIdPayload {
    session_id: string;
    access_token: string;
    csrf_token: string;
    mfa_enabled?: number;
    mfa_verified?: number;
}

export interface ChangePasswordPayload {
    new_password: string;
}

export interface MailMePayload {
    login?: string;
    email?: string;
    custom_url?: string;
    id?: string;
    password?: string;
    callback?: () => void;
}

export interface SignInFromAdminPayload {
    value: string;
}

export interface MfaConfig {
    qr_text: string;
    secret: string;
    sessionId: SessionIdPayload;
}

export interface ConfirmMfaConfig {
    one_time_password: string;
}

export interface ConfirmMfaConfigPayload {
    one_time_password: string;
    sessionId: SessionIdPayload;
    onSuccess?: () => void;
}

export type ConfirmMfaConfigResponse = {
    success?: number;
    mfa_attempts?: number;
};

export interface Validate2FaCodePayload {
    one_time_password: string;
    sessionId: SessionIdPayload;
}

export interface GetMfaConfigPayload {
    object: string;
    i_object: number;
}

export enum MfaStatus {
    Enabled = 'Enabled', 
    Disabled = 'Disabled',
    Inherited = 'Inherited'
}

export interface GetMfaConfigResponse {
    effective_mfa_used?: MfaStatus;
    mfa_configured: number;
    mfa_used: MfaStatus;
    i_object: number;
    object: string;
}

export interface SetMfaConfigPayload {
    i_object: number;
    object: string;
    one_time_password?: string;
    use_mfa: MfaStatus;
    mfa_configured?: number;
    onSuccess?: () => void;
}

export interface ResetMfaPayload {
    one_time_password?: string;
    i_object: number;
    object: string;
    onSuccess?: () => void;
}
import moment from "moment";

export type DefaultDatePreferences = {
    currentMonthStartDate: string;
    currentMonthEndDate: string;
    previousMonthStartDate: string;
    previousMonthEndDate: string;
};

export function getDefaultDatePreferences(date: Date): DefaultDatePreferences {

    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    moment.locale(localStorage.getItem('user_language')?.toLowerCase() || 'en');

    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const _currentMonthStartDate = moment(date).format(dateFormat);
    const dateCopy = new Date(date.valueOf());

    date.setMonth(date.getMonth()-1);
    const _previousMonthStartDate = moment(date).format(dateFormat);
    
    date.setMonth(date.getMonth()+2);
    date.setSeconds(-1);
    const _currentMonthEndDate = moment(date).format(dateFormat);
    
    dateCopy.setSeconds(-1);
    const _previousMonthEndDate = moment(dateCopy).format(dateFormat);//error

    return {
        currentMonthStartDate: _currentMonthStartDate,
        currentMonthEndDate: _currentMonthEndDate,
        previousMonthStartDate: _previousMonthStartDate,
        previousMonthEndDate: _previousMonthEndDate
    } as DefaultDatePreferences;
}
import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { ReduxState } from '../../../../store/types';
import { NewIntervalDialogProps, newIntervalValidationSchema, parseTimeRange, PeriodEditModel, stringifyInterval, toShortTimeFormat, toLongTimeFormatForApi, useStyles, cropSeconds } from './NewIntervalDialog.utils';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import Loader from '../../../Loader/Loader';
import { PeriodInfo } from '../../../../store/types/AccountFollowMeSettings';
import TextField from '../../../TextField/TextField';
import { convertArrayOfNumbersToRangesString, convertRangesStringToNumbersArray, daysSelectLongItems, monthsSelectItems, WeekDayDescriptor, yearsSelectItems } from '../../../../utils/extensions/RingScheduleSummary';
import SelectField from '../../../SelectField/SelectField';
import { actions } from '../../../../store';
import { defaultMin24TimeFrom, defaultMax24TimeTo, prepareDefaultRangeDate, convertRangeToStartEndTime } from '../../../TimeRangePicker/TimeRangePicker.utils';
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip';
import TimeRangePicker from '../../../TimeRangePicker/TimeRangePicker';

const NewIntervalDialog: React.FC<NewIntervalDialogProps> = ({
        isOpen,
        editObject,
        cpCondition,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.callSettings.isEditingCpCondition || false);

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const ampm = outTimeFormat?.includes('AM');

    const initialValues: PeriodEditModel = useMemo(
        () => ({
            start_time: cropSeconds(editObject?.start_time) || toShortTimeFormat(defaultMin24TimeFrom),
            end_time: cropSeconds(editObject?.end_time) || toShortTimeFormat(defaultMax24TimeTo),
            monthday_list: editObject?.monthday_list?.length ? convertArrayOfNumbersToRangesString(
                editObject.monthday_list,
            ) : '',
            weekday_list: editObject?.weekday_list || [],
            month_list: editObject?.month_list || [],
            year_list: editObject?.year_list || [],
            ampm: ampm || false
        } as PeriodEditModel),
        [editObject],
    );

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        handleChange,
        setFieldValue,
        errors,
        dirty
    } = useFormik<PeriodEditModel>({
        initialValues,
        onSubmit: (form) => {
            const startTimeRange = parseTimeRange(form.start_time);
            const endTimeRange = parseTimeRange(form.end_time);
            
            const editModel = {
                start_time: toLongTimeFormatForApi(startTimeRange),
                end_time: toLongTimeFormatForApi(endTimeRange),
                monthday_list: convertRangesStringToNumbersArray(form.monthday_list),
                weekday_list: form.weekday_list,
                month_list: form.month_list,
                year_list: form.year_list
            } as PeriodInfo;

            const intervalDescription = stringifyInterval(t, editModel, ampm) || '';

            let array: PeriodInfo[] = cpCondition.time_window?.definition_list || [];
            if(editObject) {
                array = array.map(e => e === editObject ? {
                    ...e,
                    ...editModel
                } : e);
            } else {
                array.push(editModel);
            }

            const postObject =  {
                ...cpCondition,
                time_window: {
                    ...cpCondition.time_window,
                    period: null,
                    description: intervalDescription,
                    definition_list: [...array]
                }
            };

            dispatch(actions.editCpCondition.request({
                object:postObject,
                callback: () => {
                    clickOnClose?.();
                    resetForm();
                }
            }));
        },
        validationSchema: newIntervalValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    const selectedWeekDays = useMemo(() => {
        return daysSelectLongItems.filter(e => values.weekday_list.find(c => c === e.apiValue));
    }, [values.weekday_list, daysSelectLongItems]);
    
    const selectedMonth = useMemo(() => {
        return monthsSelectItems.filter(e => values.month_list.find(c => c === e.apiValue));
    }, [values.month_list, monthsSelectItems]);
    
    const selectedYears = useMemo(() => {
        return yearsSelectItems.filter(e => values.year_list.find(c => c == e.apiValue));
    }, [values.year_list, yearsSelectItems]);
    
    const renderMultiSelectItems = (selected: WeekDayDescriptor[]) => {
        const renderTagsValue = selected?.map((v) => v.name)?.join(', ') || '';
        return (
            <div className={classes.multiSelectFieldOverflowText}>
                <OverflowTooltip
                    text={renderTagsValue}
                    tooltip={renderTagsValue}
                    copy={false}
                />
            </div>
        );
    };

    const renderWeekdays = useCallback(
        renderMultiSelectItems,
        [selectedWeekDays],
    );
    
    const renderMonths = useCallback(
        renderMultiSelectItems,
        [selectedMonth],
    );
    
    const renderYears = useCallback(
        renderMultiSelectItems,
        [selectedYears],
    );

    const defaultTimeValue = useMemo(() => {
        return prepareDefaultRangeDate(
            values.start_time,
            values.end_time,
            ampm,
        );
    }, [values, ampm]);

    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'interval-add-new-modal'}
                header={editObject
                    ? t('screens:callSettings.editIntervalDialogTitle')
                    : t('screens:callSettings.addNewIntervalDialogTitle')
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            (editObject && !dirty) ||
                            isLoading
                        }
                        onClick={handleSubmit}
                        dataTestId={'add-new-interval-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-interval-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <TimeRangePicker
                                label={t('screens:extensions.time')}
                                ampm={ampm}
                                defaultValue={defaultTimeValue}
                                onChange={(value) => {
                                    if(!isOpen) return;

                                    const result = convertRangeToStartEndTime(
                                        value,
                                        ampm,
                                    );
                                    setFieldValue('start_time', result.startTime);
                                    setFieldValue('end_time', result.endTime);
                                    setFieldError('start_time', undefined );
                                    setFieldError('end_time', undefined);
                                }}
                                helperText={
                                    errors?.start_time || errors?.end_time || ''
                                }
                                customClasses={{
                                    popperContainer: ampm ? classes.popperContainerAmPm : classes.popperContainer
                                }}
                            />
                        </Box>

                        <Box className={classes.boxRow}>
                            <TextField
                                id="monthday_list"
                                onChange={handleChange}
                                label={t('screens:callSettings.dayOfTheMonth')}
                                value={values.monthday_list}
                                helperText={
                                    errors.monthday_list && errors.monthday_list.length > 0
                                        ? errors.monthday_list + ''
                                        : ''
                                }
                                setFieldError={setFieldError}
                            />
                        </Box>

                        {!errors?.monthday_list && (
                            <Box className={classes.helperBox}>
                                <span className={classes.helperText}>
                                    {t('screens:extensions.example')}
                                </span>
                            </Box>
                        )}
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                label={t('screens:callSettings.daysOfTheWeek')}
                                className={classes.multiSelectField}
                                classes={{
                                    container: classes.multiSelectContainer,
                                }}
                                onChange={(e, value: WeekDayDescriptor[]) => {
                                    setFieldValue('weekday_list', value.map(e => e.apiValue));
                                }}
                                getOptionLabel={(opt: WeekDayDescriptor) => opt.name}
                                items={daysSelectLongItems}
                                value={selectedWeekDays}
                                multiple
                                dataQa="weekday-input"
                                //@ts-ignore
                                renderTags={renderWeekdays}
                                id='weekday_list'
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                label={t('screens:callSettings.months')}
                                className={classes.multiSelectField}
                                classes={{
                                    container: classes.multiSelectContainer,
                                }}
                                onChange={(e, value: WeekDayDescriptor[]) => {
                                    setFieldValue('month_list', value.map(e => e.apiValue));
                                }}
                                getOptionLabel={(opt: WeekDayDescriptor) => opt.name}
                                items={monthsSelectItems}
                                value={selectedMonth}
                                multiple
                                dataQa="month-input"
                                //@ts-ignore
                                renderTags={renderMonths}
                                id='month_list'
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                label={t('screens:callSettings.years')}
                                className={classes.multiSelectField}
                                classes={{
                                    container: classes.multiSelectContainer,
                                }}
                                onChange={(e, value: WeekDayDescriptor[]) => {
                                    setFieldValue('year_list', value.map(e => e.apiValue));
                                }}
                                getOptionLabel={(opt: WeekDayDescriptor) => opt.name}
                                items={yearsSelectItems}
                                value={selectedYears}
                                multiple
                                dataQa="years-input"
                                //@ts-ignore
                                renderTags={renderYears}
                                id='year_list'
                            />
                        </Box>

                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewIntervalDialog;
export enum CreateSipTrunkFaultCode {
    MaxOfferedQuantity = 'Server.Customer.Maximum offered quantity exceeded',
    ExtHGNumberInUse = 'Server.Customer.Ext_HG_number_already_in_use',
    DuplicateId = 'Server.Account.The ID {trunk_id} cannot be used because an account with the same id already exists',
    MaxQuantityError = "Server.Account.Maximum offered quantity exceeded for product ID {product_id}",
    MaxQuantityErrorPartial = "Server.Account.Maximum offered quantity exceeded for product ID",
    InvalidTrunkId = "Server.Account.The ID {trunk_id} cannot be used because a node with the same"

}

export enum CreateSipTrunkFaultString {
    DuplicateId = 'duplicate_id',
    InvalidId = "id_cannot_be_used__node_with_the_same_id_exists"
}

export enum BillStatus
{
    Inactive = "I",
    Open = "O"
}
import React from 'react';
import { FormLabel } from '@material-ui/core';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    root: {
        fontSize: 16,
        fontWeight: 500,
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        color: Colors.Text,
        marginBottom: 10,
    },

    tooltip: {
        marginLeft: 10,
    },
}));

interface CustomizedFormLabelProps {
    label: string;
    tooltipText?: string;
    dataQa?: string;
}

const CustomizedFormLabel: React.VFC<CustomizedFormLabelProps> = ({
    label,
    tooltipText,
    dataQa,
}) => {
    const classes = useStyles();

    return (
        <FormLabel data-qa={dataQa} className={classes.root}>
            {label}
            {tooltipText && (
                <IconWithTooltip
                    className={classes.tooltip}
                    dataQa={`info-icon-${dataQa}`}
                    tooltipText={tooltipText}
                    copy={false}
                />
            )}
        </FormLabel>
    );
};

export default CustomizedFormLabel;

import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store";
import {GetCallProcessingPolicyListEntity} from "../../store/actions/extensions/payloads";
import { CPRuleInfo } from "../../store/types/CallScreening";
import { ReduxState } from "../../store/types";
import DataGrid from "../DataGrid/DataGrid";
import RowExpander from "../Expander/RowExpander";
import { generateColumns, useStyles } from "./PolicyOption.utils";
import CreationButton from "../CreationButton/CreationButton";
import { usePermissionContext } from "../../hooks/usePermissions";
import { PermissionType } from "../../store/types/Permission";

export interface PolicyOptionProps {
    policy: GetCallProcessingPolicyListEntity,
    isTheLastOne?: boolean,
    onDelete?: () => void,
    onEdit?: () => void,
    onAddRule?: () => void,
    onEditRule?: (rule: CPRuleInfo) => void,
    onDeleteRule?: (rule: CPRuleInfo) => void,
    setFieldValue?: (field: string, value: unknown) => void
}

const PolicyOption: React.VFC<PolicyOptionProps> = ({
    policy,
    isTheLastOne = false,
    onDelete,
    onEdit,
    onAddRule,
    onEditRule,
    onDeleteRule,
    setFieldValue
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const permission = usePermissionContext();

    const { cpConditionsList, customerResponseMessages } = useSelector(
        (state: ReduxState) => state.callSettings,
    );
    
    const { callProcessingOperationModeList } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const onSwitchClick = (value: boolean, object: CPRuleInfo) => {
        setFieldValue?.('enabled', value);
        dispatch(actions.updateRuleStatus({
            object: object,
            newStatus: value ? 'Y' : 'N'
        }));
    };

    const columns = generateColumns(
        t,
        classes,
        permission,
        cpConditionsList,
        callProcessingOperationModeList,
        customerResponseMessages,
        (object: CPRuleInfo) => {
            onEditRule?.(object);
        },
        (object: CPRuleInfo) => {
            onDeleteRule?.(object);
        },
        (value: boolean, object: CPRuleInfo) => {
            onSwitchClick(value, object);
        }
    );

    return (
        <RowExpander
            title={policy.name}
            isOpenedOnInit={false}
            badgeCount={policy.rule_list.length}
            showUnderline={!isTheLastOne}
            onDelete={onDelete}
            onEdit={onEdit}
        >
            <DataGrid<CPRuleInfo>
                classes={{
                    tableContainer: classes.rulesTableContainer,
                }}
                dragColumnStyle={classes.dragColumnStyle}
                dragHeaderStyle={classes.dragColumnStyle}
                columns={columns}
                data={policy.rule_list}
                rowCount={policy.rule_list?.length || 0}
                pageSizeOptions={[policy.rule_list?.length || 0]}
                initialPageSize={Number.MAX_VALUE}
                centeredRows
                hideFooter
                dragEnabled={permission === PermissionType.Visible}
                getItemId={(r, i) => (r.i_cp_policy || 0) + '_' + (r.order_number || 0) + '_' + i}
                onChangeItemPosition={(startIndex, endIndex) => {
                    const isAsc = startIndex > endIndex;
                    let i = startIndex + (isAsc ? -1 : +1);
                    while(i != (endIndex + (isAsc ? -1 : +1))) {
                        policy.rule_list[i].order_number = (policy.rule_list[i].order_number || 0) + (isAsc ? +1 : -1);
                        i = i + (isAsc ? -1 : +1);
                    }
                    policy.rule_list[startIndex].order_number = endIndex + 1;
                    policy.rule_list.sort((a, b) => (a.order_number || 0) - (b.order_number || 0));
                    dispatch(actions.updateRulesOrder.request(policy));
                    setFieldValue?.('order_number', endIndex || 0);
                }}
            />
            <CreationButton
                data-qa="add-rule-button"
                onClick={onAddRule}
                className={classes.addRuleButton}
                title={t('screens:callSettings.addRule')}
            />
        </RowExpander>
    )
}

export default PolicyOption;
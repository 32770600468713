import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import dayjs from '../../services/customDayJs';
import { makeStyles } from '@material-ui/core';
import { actions } from '../../store';
import { Colors } from '../../styles/Colors';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';

dayjs.extend(relativeTime);

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 15,
        marginTop: 10,
        color: Colors.Text,
        flexDirection: 'column',
        width: '100%',
        lineHeight: 1.2
    },
    button: {
        marginLeft: 10,
    },
    timeText: {
        fontStyle: 'italic',
        marginLeft: 10,
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));

const ActiveCallsInfo: React.VFC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [timeAgo, setTimeAgo] = useState('');

    const refreshTime = useSelector<ReduxState, string | undefined>(
        (state) => state.ringgroups.refreshTime,
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (refreshTime) {
                setTimeAgo(dayjs(refreshTime, dateFormat).fromNow());
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    const refreshActiveCalls = useCallback(() => {
        dispatch(actions.getSipCallsList.request({}));
        dispatch(actions.getRingGroupsAccountList.request());
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshActiveCalls();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [refreshTime]);

    return (
        <div className={classes.container}>
            {timeAgo && (
                <>
                    <span className={classes.noWrap}>{t('screens:calls.lastUpdated')}{':'}</span>
                    <span className={classNames(classes.timeText, classes.noWrap)}>{timeAgo}</span>
                </>
            )}
        </div>
    );
};

export default ActiveCallsInfo;

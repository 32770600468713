import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as Copy } from '../../assets/copy-content.svg';
import { createStyles, makeStyles } from '@material-ui/core';
import Snackbar from '../Snackbar/Snackbar';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import CustomizedIconButton from '../IconButton/IconButton';

type TooltipProps = {
    type?: 'normal' | 'large';
    children: React.ReactElement;
    title?: any;
    above?: boolean;
    dataQa?: string;
    interactive?: boolean;
    copy?: boolean;
    className?: string;
    disableHoverListener?: boolean;
    dataTestId?: string;
    classes?: { text?: string; container?: string; tooltipContainer?: string };
};

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            background: Colors.Text,
        },
        tooltipContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '4px 4px',
            fontSize: 12,
            lineHeight: '18px',
            fontWeight: 400,
        },
        tooltipLargeContainer: {
            padding: 16,
            fontSize: 14,
            lineHeight: '21px',
        },
        tooltip: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            width: '100%',
        },
        tooltipTitle: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            whiteSpace: 'pre-wrap',
            maxWidth: '100%',
        },
        copyTitle: {
            maxWidth: 'calc(100% - 30px)',
        },
        copyWrapper: {
            alignSelf: 'flex-start',
            marginTop: '0.5rem',
        },
        copyButton: {
            '& svg': {
                height: 19,
                width: 19,
            },
        },
    }),
);

const AUTO_HIDE_DURATION = 2000;

const CustomizedTooltip: React.FC<TooltipProps> = ({
    children,
    above,
    title,
    dataQa,
    interactive = false,
    copy = true,
    className,
    disableHoverListener = false,
    dataTestId,
    classes: customClasses,
    type = 'normal',
}) => {
    const classes = useStyles();
    const [showSnackbar, setShowSnackBar] = useState(false);
    const { t } = useTranslation();

    const handleOnClick = () => {
        navigator.clipboard.writeText(title);

        setShowSnackBar(true);
    };

    const handleOnCloseSnackbar = () => {
        setShowSnackBar(false);
    };

    if (disableHoverListener) {
        return children;
    }

    return (
        <>
            <Tooltip
                className={className}
                interactive={interactive}
                data-qa={dataQa}
                data-testid={dataTestId}
                disableHoverListener={disableHoverListener}
                classes={{
                    tooltip: classNames(
                        customClasses?.container,
                        classes.container,
                    ),
                }}
                title={
                    <div
                        className={classNames(
                            classes.tooltipContainer,
                            type === 'large' && classes.tooltipLargeContainer,
                            customClasses?.tooltipContainer,
                        )}
                    >
                        <div
                            className={classes.tooltip}
                            data-testid={dataTestId + '-content'}
                        >
                            <span
                                className={classNames(
                                    classes.tooltipTitle,
                                    copy && classes.copyTitle,
                                    customClasses?.text,
                                )}
                            >
                                {title}
                            </span>
                            {copy && (
                                <CustomizedIconButton
                                    className={classes.copyButton}
                                    dataQa="copy-button"
                                    dataTestId="copy-button"
                                    onClick={handleOnClick}
                                    backgroundWithOpacity
                                >
                                    <Copy />
                                </CustomizedIconButton>
                            )}
                        </div>
                    </div>
                }
                PopperProps={{
                    popperOptions: {
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: '0,-8',
                            },
                        },
                    },
                }}
                placement={above ? 'bottom' : 'top'} // this is hack for connection with minus offset
            >
                {children}
            </Tooltip>
            <Snackbar
                isOpen={showSnackbar}
                snackbarMessage={t('common:textIsCopied')}
                onButtonClick={handleOnCloseSnackbar}
                handleClose={handleOnCloseSnackbar}
                actionLabel={t('common:closeSnackbar')}
                dataQa="copy-snackbar"
                autoHideDuration={AUTO_HIDE_DURATION}
            />
        </>
    );
};

export default CustomizedTooltip;

import React from 'react';
import { Link } from 'react-router-dom';

type CustomizedLinkProps = {
    disabled?: boolean;
    to: string;
};

const CustomizedLink: React.FC<CustomizedLinkProps> = ({
    children,
    disabled,
    to,
}) => {
    if (disabled) {
        return <>{children}</>;
    }

    return <Link to={to}>{children}</Link>;
};

export default CustomizedLink;

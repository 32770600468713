import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import React, {useEffect} from 'react';
import Device from '../../../components/Extensions/Device';
import {useTabStyles} from './utils';
import {useExtensionDeviceFormData} from '../../../hooks/useExtensionDeviceFormData';
import {
    SipFormType,
    SipValidationSchema,
} from '../../../components/Extensions/Sip';
import {Formik} from 'formik';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';

interface DeviceTabProps extends TabInterface {
    assignPermission?: string[];
    releasePermission?: string[];
    changePermission?: string[];
}

const DeviceTab: React.VFC<DeviceTabProps> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        assignPermission,
        releasePermission,
        changePermission,
    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.deviceTab?.isLoading ||
            false,
    );

    const {data, initFormData} = useExtensionDeviceFormData();

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="extension-list-loader"/>
        </div>
    ) : (
        <Formik<SipFormType>
            initialValues={initFormData}
            validationSchema={SipValidationSchema(data.passwordLength)}
            onSubmit={handleSubmitForm!}
            enableReinitialize
            validateOnChange={false}
        >
            {() => {

                return (
                    <>
                        <Device
                            sharedLine={data.sharedLine}
                            i_c_ext={data.extension?.i_c_ext as number}
                            register={data.sipStatus}
                            iAccount={data.extension?.i_account as number}
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                            handleSetIsValidFunc={handleSetIsValidFunc}
                            extensionNumber={data.extension?.id}
                            assignPermission={assignPermission}
                            releasePermission={releasePermission}
                            changePermission={changePermission}
                        />
                    </>
                )
            }
            }

        </Formik>
    );
};

export default DeviceTab;

import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../../DataGrid/DataGrid';
import { PaginationMode } from '../../DataGrid/types';
import { creditAdjustmentsEntityTransformer } from '../../../utils/transformers';
import {
    CreditAdjustmentsTableRowInterface,
    generateColumns,
    useStyles,
} from './CreditAdjustmentsTable.utils';
import { TransactionTableInterface } from '../utils';
import classNames from 'classnames';

const CreditAdjustmentsTable: React.VFC<TransactionTableInterface> = ({
    currency,
    data,
    onPageChange,
    onPageSizeChange,
    initialPageSize,
    loading,
    rowCount,
    userDateTimeFormat,
    className,
    iService
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const columns = generateColumns(t, currency, userDateTimeFormat, iService);

    const items: CreditAdjustmentsTableRowInterface[] = data.map(
        creditAdjustmentsEntityTransformer,
    );

    return (
        <DataGrid<CreditAdjustmentsTableRowInterface>
            dataQa={'credit-adjustments-table'}
            data={items}
            loading={loading}
            columns={columns}
            rowCount={rowCount}
            paginationMode={PaginationMode.Server}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            initialPageSize={initialPageSize}
            classes={{
                header: classes.header,
                tableContainer: classNames(classes.tableContent, className),
            }}
        />
    );
};

export default CreditAdjustmentsTable;

import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

export type DialogProps = {
    isOpen: boolean;
    handleClose?: () => void;
    handleSubmit?: () => void;
    title?: string;
    content?: any;
    closeButtonText?: string;
    submitButtonText?: string;
    dataQa: string;
    customClasses?: { container?: string };
    isLoading?: boolean;
    loadingMessage?: string;
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            '& .MuiDialog-paperWidthSm': {
                minWidth: 330,
                '& .MuiDialogContent-root:first-child': {
                    padding: 24,
                },
            },
        },
        loaderWrapper: {
            paddingBottom: 24,
            '& [data-testid="fetch-data-new-menu-loader"]': {
                alignItems: 'flex-start !important',
                paddingTop: 24,
                backgroundColor: 'unset !important',
                height: 40,
            },
        },
        loadingMessage: {
            height: 113,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            color: Colors.Text,
        },
    }),
);

const CustomizedDialog: React.FC<DialogProps> = ({
    isOpen,
    handleClose,
    handleSubmit,
    title,
    content,
    closeButtonText,
    submitButtonText,
    dataQa,
    customClasses,
    isLoading,
    loadingMessage,
}) => {
    const classes = useStyles();

    return (
        <Dialog
            className={classNames(classes.container, customClasses?.container)}
            onClose={handleClose}
            open={isOpen}
            data-qa={dataQa}
        >
            {isLoading ? (
                <div className={classes.loaderWrapper}>
                    <Loader
                        dataQa="fetch-data-new-menu-loader"
                        absolutePosition={true}
                        dataTestId={'fetch-data-new-menu-loader'}
                    />
                    {loadingMessage && (
                        <div className={classes.loadingMessage}>
                            {loadingMessage}
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {title && (
                        <DialogTitle id="customized-dialog-title">
                            {title}
                        </DialogTitle>
                    )}
                    <DialogContent dividers>{content}</DialogContent>
                    <DialogActions>
                        {closeButtonText && (
                            <Button onClick={handleClose}>
                                {closeButtonText}
                            </Button>
                        )}
                        {submitButtonText && (
                            <Button onClick={handleSubmit}>
                                {submitButtonText}
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CustomizedDialog;

import React from 'react';
import { getEdgeCenter, getMarkerEnd, Position } from 'react-flow-renderer';

const bottomLeftCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`;
const leftBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`;
const bottomRightCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`;
const rightBottomCorner = (x: number, y: number, size: number): string =>
    `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`;
const leftTopCorner = (x: number, y: number, size: number): string =>
    `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`;
const topLeftCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`;
const topRightCorner = (x: number, y: number, size: number): string =>
    `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`;
const rightTopCorner = (x: number, y: number, size: number): string =>
    `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`;

export function getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition = Position.Bottom,
    targetX,
    targetY,
    targetPosition = Position.Top,
    borderRadius = 15,
    centerX,
    centerY,
}: any): string {
    const [_centerX, _centerY, offsetX, offsetY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });
    const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX));
    const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY));
    const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY);
    const leftAndRight = [Position.Left, Position.Right];
    const cX = typeof centerX !== 'undefined' ? centerX : _centerX;
    const cY = typeof centerY !== 'undefined' ? centerY : _centerY;

    let firstCornerPath = null;
    let secondCornerPath = null;

    if (sourceX <= targetX) {
        firstCornerPath =
            sourceY <= targetY
                ? bottomLeftCorner(sourceX, cY, cornerSize)
                : topLeftCorner(sourceX, cY, cornerSize);
        secondCornerPath =
            sourceY <= targetY
                ? rightTopCorner(targetX, cY, cornerSize)
                : rightBottomCorner(targetX, cY, cornerSize);
    } else {
        firstCornerPath =
            sourceY < targetY
                ? bottomRightCorner(sourceX, cY, cornerSize)
                : topRightCorner(sourceX, cY, cornerSize);
        secondCornerPath =
            sourceY < targetY
                ? leftTopCorner(targetX, cY, cornerSize)
                : leftBottomCorner(targetX, cY, cornerSize);
    }

    if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
    ) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(cX, sourceY, cornerSize)
                    : rightBottomCorner(cX, sourceY, cornerSize);
            secondCornerPath =
                sourceY <= targetY
                    ? bottomLeftCorner(cX, targetY, cornerSize)
                    : topLeftCorner(cX, targetY, cornerSize);
        } else if (
            sourcePosition === Position.Right &&
            targetPosition === Position.Left
        ) {
            firstCornerPath =
                sourceY <= targetY
                    ? leftTopCorner(cX, sourceY, cornerSize)
                    : leftBottomCorner(cX, sourceY, cornerSize);
            secondCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(cX, targetY, cornerSize)
                    : topRightCorner(cX, targetY, cornerSize);
        }
    } else if (
        leftAndRight.includes(sourcePosition) &&
        !leftAndRight.includes(targetPosition)
    ) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? rightTopCorner(targetX, sourceY, cornerSize)
                    : rightBottomCorner(targetX, sourceY, cornerSize);
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? leftTopCorner(targetX, sourceY, cornerSize)
                    : leftBottomCorner(targetX, sourceY, cornerSize);
        }
        secondCornerPath = '';
    } else if (
        !leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
    ) {
        if (sourceX <= targetX) {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomLeftCorner(sourceX, targetY, cornerSize)
                    : topLeftCorner(sourceX, targetY, cornerSize);
        } else {
            firstCornerPath =
                sourceY <= targetY
                    ? bottomRightCorner(sourceX, targetY, cornerSize)
                    : topRightCorner(sourceX, targetY, cornerSize);
        }
        secondCornerPath = '';
    }

    return `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`;
}

export default function Connector({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    arrowHeadType,
    markerEndId,
}: any) {
    const path = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        borderRadius: 10,
    });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

    return (
        <>
            <path
                style={style}
                className="react-flow__edge-path"
                d={path}
                markerEnd={markerEnd}
            />
        </>
    );
}

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";
import {CallsItem} from "../../../store/reducers/extensions/extensions/reducer";

const useStyles = makeStyles(() => ({
    separator: {
        marginLeft: 8,
        marginRight: 8
    },
    maxCallsDiv: {
        fontSize: 12,
        lineHeight: 'px',
        color: Colors.Black,
        marginLeft: 54
    },
    maxCallsText: {
        fontWeight: 700,
        marginRight: 8
    },
    maxCallsItem: {
        fontWeight: 400,
    }
}));


type MaxCallsComponentProps = {
    calls?: CallsItem[]
}

const MaxCallsComponent: React.VFC<MaxCallsComponentProps> = ({calls}) => {

    const classes = useStyles();
    const filteredCalls = calls?.filter(call => call.value > 0);
    const callComponent = filteredCalls?.length ? filteredCalls.map((item, index) => {

        return (<span className={classes.maxCallsItem}
                      key={item.name}>{item.value} {item.translation} {index != filteredCalls.length - 1 ?
            <span className={classes.separator}>/</span> : ''}</span>)
    }) : (<></>);

    return calls?.length ?
        (
            <div className={classes.maxCallsDiv}>
                <div>
                    <span className={classes.maxCallsText}>Max calls</span>
                    {callComponent}
                </div>
            </div>
        ) : <></>;

}

export default MaxCallsComponent
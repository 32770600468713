import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../styles/Colors';
import { Controls, useZoomPanHelper } from 'react-flow-renderer';
import { Add, Fullscreen, Remove } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    controlButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: 30,
        width: 30,
        backgroundColor: Colors.White,

        '&:hover': {
            backgroundColor: Colors.LightGraySecondary4,
        },
    },
}));

const CustomControls = () => {
    const classes = useStyles();

    const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

    return (
        <Controls showInteractive={false} showFitView={false} showZoom={false}>
            <div className={classes.controlButton} onClick={() => zoomIn()}>
                <Add
                    data-qa="diagram-add-icon"
                    data-testid="diagram-add-icon"
                />
            </div>

            <div className={classes.controlButton} onClick={() => zoomOut()}>
                <Remove
                    data-qa="diagram-remove-icon"
                    data-testid="diagram-remove-icon"
                />
            </div>

            <div
                className={classes.controlButton}
                onClick={() =>
                    fitView({ padding: 0.2, includeHiddenNodes: true })
                }
            >
                <Fullscreen
                    data-qa="diagram-fullscreen-icon"
                    data-testid="diagram-fullscreen-icon"
                />
            </div>
        </Controls>
    );
};

export default CustomControls;

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import {
    RingGroupGeneralFormType,
    ringGroupGeneralFormValidationSchema,
} from '../../components/Forms/RingGroups/generalFormUtils';
import {
    GroupMembersFormType,
    groupMembersValidationSchema,
} from '../../components/Forms/RingGroups/GroupMembersForm';
import {
    CallQueueFormType,
    callQueueValidationSchema,
} from '../../components/Forms/RingGroups/callQueueFormUtils';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,

        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        backgroundColor: Colors.SmokeBackground,
        overflowY: 'auto',
        flex: 1,
        paddingBottom: 70,
    },
    extensionHeader: {
        backgroundColor: Colors.White,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        '& h2': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    header: {
        fontSize: 32,
        fontWeight: 300,
    },
    subheader: {
        fontWeight: 500,
        fontSize: 32,
        marginLeft: 16,
    },
    ternaryHeader: {
        fontWeight: 300,
        fontSize: 32,
        marginLeft: 16,
    },
    headerDetails: {
        display: 'flex',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        '&:first-of-type': {
            marginRight: 33,
        },
        '& .header': {
            color: Colors.Gray9,
            fontSize: 16,
            margin: 0,
        },
        '& .value': {
            color: Colors.Text,
            fontSize: 16,
            fontWeight: 500,
        },
    },
    centeredColumn: {
        '& .header': {
            textAlign: 'center',
        },
        '& .value': {
            textAlign: 'center',
        },
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
}));
export type EditRingGroupForm = RingGroupGeneralFormType &
    GroupMembersFormType &
    CallQueueFormType;

export const formValidationSchema = ringGroupGeneralFormValidationSchema
    .concat(groupMembersValidationSchema)
    .concat(callQueueValidationSchema);

export type RingGroupDetailsProps = {
    id: number;
    forceSaveIsActive?: boolean;
};

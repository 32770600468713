import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import dayjs from '../../../../services/customDayJs';
import { AdditionalElement } from '../../../../utils/transformers';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '17px 25px 12px 0px',
        maxWidth: 965,
        display: 'flex',
    },
    insideItemsContainer: {
        display: 'flex',
    },
    columnItemsContainer: {
        flexDirection: 'column',
        marginTop: 48,
    },
    firstContainer: {
        marginTop: '3px !important',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        padding: 0,
        height: 'max-content',
        overflow: 'hidden',
        '& .MuiFormControl-root': {
            flex: 1,
        },
        '& .MuiInputBase-root': {
            width: 440,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 12,
        marginBottom: 12,
        '& > :first-child': {
            marginRight: '32px !important',
        },

        '& .MuiFormControl-root': {
            maxWidth: 440,
        },
        '& .character-counter': {
            top: 'unset !important',
        },
    },
    headerBox: {
        display: 'flex',
        marginTop: 0,
        padding: 0 + '!important',
        fontWeight: 500,
    },
    rowBoxHeader: {
        marginRight: 12,
        fontSize: 16,
    },
    sectionSpace: {
        marginTop: 7,
    },
    flexContainer: {
        display: 'flex',
    },
    officeContainer: {
        maxWidth: 370,
    },
    shortField: {
        maxWidth: 440,
    },
    additionalDetails: {
        marginTop: 24,
        display: 'grid',
        gridTemplateColumns: '440px 440px',
        columnGap: 32,
        rowGap: 16,
    },

    scrollable: {
        overflowY: 'auto',
        height: 'fit-content',
        flex: 1,
        width: '100%',
        backgroundColor: Colors.SmokeBackground,

        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        '& .MuiBox-root': {
            overflow: 'visible'
        },
    },

    scrollPaddingForNotTabMode: {
        maxHeight: 'calc(100vh - 106px)'
    },

    '@media (max-width: 1276px)': {
        scrollPaddingForNotTabMode: {
            paddingLeft: 24,
            paddingRight: 24,
        }
    },
    '@media (min-width:1277px) and (max-width: 1440px)': {
        scrollPaddingForNotTabMode: {
            paddingLeft: 90,
            paddingRight: 90,
        }
    },
    '@media (min-width: 1441px)': {
        scrollPaddingForNotTabMode: {
            paddingLeft: 96,
            paddingRight: 96,
        }
    },
    companyName: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        '& .MuiTextField-root': {
            width: '100%',
            maxWidth: 912,
        },
        '& .MuiInputBase-root': {
            width: '100%',
        },
    },
    container: {
        maxWidth: 440,
    }
}));

export type CompanyInfoFormType = {
    name: string;
    companyName: string;
    salutation: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    fax: string;
    phone: string;
    country: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    provinceState: string;
    postalCode: string;
    contactPerson: string;
    additionalPhone: string;
    amount?: string;
    additionalDetails: AdditionalElement[];
    dateFormat: string;
};

export const companyInfoFormDefaultValues: CompanyInfoFormType = {
    name: '',
    companyName: '',
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    fax: '',
    phone: '',
    country: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    provinceState: '',
    postalCode: '',
    contactPerson: '',
    additionalPhone: '',
    amount: '',
    additionalDetails: [],
    dateFormat: 'YYYY-MM-DD HH24:MI:SS',
};

const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const defaultDateFormat = 'YYYY-MM-DD';

const invalidEmail = i18n.t<string>('errors:common.invalidEmail');
const invalidDate = i18n.t<string>('errors:common.invalidDate');

const dateTimeFieldValidation = Yup.string()
    .test('test', invalidDate, (value) => {
        if (!value) {
            return true;
        }
        const date = dayjs(value, defaultDateTimeFormat, true);
        return date.isValid();
    })
    .test('testFrom', '', function (value) {
        const properties = JSON.parse(this.parent.properties);
        if (properties.date_from && value) {
            const date = dayjs(value, defaultDateTimeFormat);
            const dateFrom = dayjs(properties.date_from, defaultDateTimeFormat);
            return date >= dateFrom
                ? true
                : this.createError({
                      message: i18n.t<string>('errors:common.dateFrom', {
                          value: properties.date_from,
                      }),
                      path: this.path,
                  });
        }
        return true;
    })
    .test('testTo', '', function (value) {
        const properties = JSON.parse(this.parent.properties);
        if (properties.date_to && value) {
            const date = dayjs(value, defaultDateTimeFormat);
            const dateTo = dayjs(properties.date_to, defaultDateTimeFormat);
            return date <= dateTo
                ? true
                : this.createError({
                      message: i18n.t<string>('errors:common.dateTo', {
                          value: properties.date_to,
                      }),
                      path: this.path,
                  });
        }
        return true;
    });

const dateFieldValidation = Yup.string()
    .test('test', invalidDate, (value) => {
        if (!value) {
            return true;
        }
        const date = dayjs(value, defaultDateFormat, true);
        return date.isValid();
    })
    .test('testFrom', 'from', function (value) {
        const properties = JSON.parse(this.parent.properties);
        if (properties.date_from && value) {
            const date = dayjs(value, defaultDateFormat);
            const dateFrom = dayjs(properties.date_from, defaultDateFormat);
            return date >= dateFrom
                ? true
                : this.createError({
                      message: i18n.t<string>('errors:common.dateFrom', {
                          value: properties.date_from,
                      }),
                      path: this.path,
                  });
        }
        return true;
    })
    .test('testTo', 'to', function (value) {
        const properties = JSON.parse(this.parent.properties);
        if (properties.date_to && value) {
            const date = dayjs(value, defaultDateFormat);
            const dateTo = dayjs(properties.date_to, defaultDateFormat);
            return date <= dateTo
                ? true
                : this.createError({
                      message: i18n.t<string>('errors:common.dateTo', {
                          value: properties.date_to,
                      }),
                      path: this.path,
                  });
        }
        return true;
    });

export const CompanyInfoFormValidationSchema = Yup.object().shape({
    email: Yup.string().email(invalidEmail).notRequired(),
    additionalDetails: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .when('type', {
                    is: (value: string) => value === 'datetime',
                    then: dateTimeFieldValidation,
                })
                .when('type', {
                    is: (value: string) => value === 'date',
                    then: dateFieldValidation,
                })
                .when('type', {
                    is: (value: string) => value === 'text',
                    then: Yup.string().test(
                        'min',
                        'min error',
                        function (value) {
                            try {
                                const properties = JSON.parse(
                                    this.parent.properties,
                                );
                                if (properties.min && value) {
                                    return value.length >= properties.min
                                        ? true
                                        : this.createError({
                                              message: i18n.t<string>(
                                                  'errors:common.minLength',
                                                  {
                                                      value: properties.min,
                                                  },
                                              ),
                                              path: this.path,
                                          });
                                }
                                return true;
                            } catch (err) {
                                return true;
                            }
                        },
                    ),
                })
                .when('type', {
                    is: (value: string) => value === 'number',
                    then: Yup.string().test('minmax', '', function (value) {
                        try {
                            value = value?.replace(',', '.');
                            const properties = JSON.parse(
                                this.parent.properties,
                            );
                            let maxValid = true;
                            let minValid = true;

                            if (properties.min && value) {
                                minValid =
                                    parseFloat(value) >=
                                    parseFloat(properties.min);
                            }
                            if (properties.max && value) {
                                maxValid =
                                    parseFloat(value) <=
                                    parseFloat(properties.max);
                            }

                            if (!minValid) {
                                return this.createError({
                                    message: i18n.t<string>(
                                        'errors:common.greaterValue',
                                        {
                                            value: properties.min,
                                        },
                                    ),
                                    path: this.path,
                                });
                            } else if (!maxValid) {
                                return this.createError({
                                    message: i18n.t<string>(
                                        'errors:common.lessValue',
                                        {
                                            value: properties.max,
                                        },
                                    ),
                                    path: this.path,
                                });
                            }

                            return minValid && maxValid;
                        } catch (err) {
                            return true;
                        }
                    }),
                })
                .when('mandatory', {
                    is: (mandatory: string) => mandatory === 'Y',
                    then: Yup.string().required(
                        i18n.t<string>('errors:common.emptyInput'),
                    ),
                    otherwise: Yup.string().nullable().notRequired(),
                }),
        }),
    ),
    postalCode: Yup.string()
        .min(2, i18n.t('errors:common.minLengthIs', { value: 2 }))
        .max(10)
        .matches(/^[0-9a-zA-Z -]*$/, i18n.t('errors:common.wrongFormat'))
        .required(i18n.t<string>('errors:common.emptyInput')),
});

export const mappedValues = {
    name: 'name',
    companyName: 'companyname',
    salutation: 'salutation',
    firstName: 'firstname',
    middleName: 'midinit',
    lastName: 'lastname',
    email: 'email',
    fax: 'faxnum',
    phone: 'phone1',
    country: 'country',
    city: 'city',
    addressLine1: 'baddr1',
    addressLine2: 'address_line_2',
    provinceState: 'state',
    postalCode: 'zip',
    contactPerson: 'cont2',
    additionalPhone: 'phone2',
    amount: 'iso_4217',
    additionalDetails: 'additionalDetails',
    dateFormat: 'dateFormat',
};

export type MappedValuesType = {
    companyname: string;
    salutation: string;
    firstname: string;
    midinit: string;
    lastname: string;
    email: string;
    faxnum: string;
    phone1: string;
    country: string;
    city: string;
    baddr1: string;
    address_line_2: string;
    state: string;
    zip: string;
    cont2: string;
    phone2: string;
    iso_4217: string;
    out_date_time_format: string | Date;
    additionalDetails: AdditionalElement[];
};

import React from 'react';
import { ReactComponent as ReceiptContainerBig } from '../../../assets/payments/receipt_container_big.svg';
import { useStyles } from './PaymentReceipt.utils';
import Logo from '../../Logo/Logo';
import PaymentInfoBox from '../PaymentInfoBox/PaymentInfoBox';

const PaymentReceipt: React.VFC = ({}) => {
    const classes = useStyles();

    return (
        <div className={classes.outsideContainer}>
            <div className={classes.mainContainer} id="receipt-to-print">
                <ReceiptContainerBig />

                <div className={classes.receiptDataContainer}>
                    <div className={classes.logoContainer}>
                        <Logo />
                    </div>

                    <PaymentInfoBox withoutTooltips />
                </div>
            </div>
        </div>
    );
};

export default PaymentReceipt;

import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../../styles/Colors';
import {CustomerDIDNumberType} from '../../../../store/types/CustomerDIDNumber';
import * as Yup from 'yup';
import {
    EditExtensionForm,
    ChangeDirtyStateDetailsTab,
} from '../../../../views/Extensions/ExtensionDetails/utils';
import i18n from '../../../../services/i18n';
import {TimeZone} from '../../../../store/types/TimeZone';
import {Country} from '../../../../store/types/Country';
import {PasswordRules} from '../../../../store/types/ConfigData';
import {Subdivision} from '../../../../store/types/Subdivision';
import {APIErrorInterface} from '../../../../store/types';
import {
    maxTrunkIdLength,
    nameSipTrunkMaxLengthError
} from "../../../../views/SipTrunks/List/CreateNewSipTrunkDialog.utils";
import {nameMaxLengthError, requiredFieldError} from "../../../PortalUsers/utils";

export const useStyles = makeStyles(() => ({
    visibilityIcon: {
        marginRight: 6,
    },
    itemsContainer: {
        padding: '32px 0px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
        padding: '0px 16px 24px 16px',

        '& > :first-child': {
            marginRight: 32,
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
    },
    groupsContainer: {
        padding: '24px 16px',
        maxWidth: 944,
    },

    columnItemsContainer: {
        flexDirection: 'column',
        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },
    emergencyHeaderContainer: {
        padding: '0px 24px 0px 24px !important',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 16,
        marginTop: -28,
    },
    emergencyHeader: {
        color: Colors.Text,
        fontWeight: 700,
        marginRight: 12,
    },
    includeSwitcher: {
        maxWidth: '944px !important',
    },

    outsideContainer: {
        paddingRight: 0,
    },
    includeContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: -5,
    },
    includeRowContainer: {
        '& > :first-child': {
            flex: 1,
            paddingLeft: 0,
        },
    },
    topContainer: {
        paddingTop: 22,
    },
}));

export type SipTrunkFormType = {
    sipTrunk: string;
    didNumber: string[];
    displayNumber: string;
    emailAddress: string;
    timezone: string;
    office: string;
    country: string;
    address: string;
    city: string;
    postalCode: string;
    state: string;
    servicePassword: string;
};

export const emptyField = i18n.t('errors:common.emptyInput');
export const portalLoginError = i18n.t('errors:extensions.portalLogin');
export const portalLoginLengthError = i18n.t(
    'errors:extensions.portalLoginLength',
);

const postalCodeMinError = i18n.t('errors:extensions.postalCode');
export const invalidCharError = i18n.t('errors:sipTrunks.withoutSpace');

export const generateSipTrunkFormValidationSchema = (
    portalPasswordLength: number,
) =>
    Yup.object().shape(
        {
            sipTrunk: Yup.string()
                .max(maxTrunkIdLength, nameSipTrunkMaxLengthError)
                .matches(/^[a-zA-Z0-9_.-]*$/, invalidCharError)
                .required(emptyField),
            didNumber: Yup.array().notRequired(),
            displayNumber: Yup.string().notRequired().nullable(),
            emailAddress: Yup.string().email().notRequired(),
            timezone: Yup.string().required(emptyField),
            office: Yup.string().notRequired(),
            country: Yup.string().nullable().notRequired(),
            address: Yup.string().notRequired(),
            city: Yup.string().notRequired(),
            postalCode: Yup.string().min(2, postalCodeMinError).notRequired(),
            state: Yup.string().nullable().notRequired(),
            // servicePassword: Yup.string().min(portalPasswordLength, portalLoginLengthError)
            servicePassword: Yup.string()
                .test(
                    'passLength',
                    i18n.t(`screens:portalUsers.portalPassword`, {
                        value:portalPasswordLength,
                    }),
                    function (value) {
                        if (
                            value !== undefined &&
                            portalPasswordLength
                        ) {
                            return (
                                value.length >=
                                Number(
                                    portalPasswordLength
                                )
                            );
                        }

                        return true;
                    },
                ).test(
                    'passChars',
                    i18n.t(`errors:sipTrunks.whiteSpaceAdded`),
                    function (value) {
                        const test = value?.indexOf(' ')
                        return test == undefined || test == -1;
                    }
                )
                .max(32, nameMaxLengthError)
                .required(requiredFieldError),
        }
    );

export const sipTrunkFormDefaultValues: SipTrunkFormType = {
    sipTrunk: '',
    didNumber: [],
    displayNumber: '',
    emailAddress: '',
    timezone: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
    office: '',
    servicePassword: ''
};

export type SipTrunkFormProps = {
    didNumbers?: CustomerDIDNumberType[];
    accountId?: number;
    initFormData?: SipTrunkFormType;
    timeZones?: TimeZone[];
    countries?: Country[];
    webConfig?: PasswordRules;
    subdivisionsList?: Subdivision[];
    apiErrors?: APIErrorInterface;
    handleSubmitForm?: (formData: Partial<EditExtensionForm>) => void;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    prefixEnabled?: boolean;
    callback?: () => void

};

export function generateDisplayNumberList(
    value: string,
    realValue: string,
    prefixEnabled: boolean,
    prefixMark: string,
    simple = true,
): string | { value: string; label: string } {
    let displayValue = value;
    if (prefixEnabled && value != realValue) {
        const indexOfPrefix = value.indexOf(prefixMark);
        displayValue =
            prefixEnabled && indexOfPrefix !== 0
                ? `${prefixMark}${value}`
                : value;
    } else if (`+${value}` == realValue) {
        displayValue = realValue;
    }

    return simple ? displayValue : {label: displayValue, value: displayValue};
}

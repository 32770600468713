import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionContext } from '../../../../../hooks/usePermissions';
import { PermissionType } from '../../../../../store/types/Permission';
import { Colors } from '../../../../../styles/Colors';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';

type NodeActionButtonProps = {
    tooltip?: string;
    onClick?: () => void;
    dataTestId?: string;
    dataQa?: string;
    skipPermission?: boolean;
    className?: string;
};

const useStyles = makeStyles(() => ({
    editButton: {
        width: 40,
        height: 40,
        backgroundColor: Colors.White,
        borderRadius: 20,
        boxShadow: `0px 4px 4px ${Colors.Gray8}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'auto',
        cursor: 'pointer',
        margin: '0 5px',
        '& .MuiSvgIcon-root': {
            '&:hover': {
                fill: Colors.Text,
            },
        },
    },
    editButtonDisabled: {
        '& .MuiSvgIcon-root': {
            fill: Colors.Gray8,
            '&:hover': {
                fill: Colors.Gray8,
            },
        },

        '&:hover': {
            '& .MuiSvgIcon-root': {
                fill: Colors.Gray8,
            },
        },
    },
}));

const NodeActionButton: React.FC<NodeActionButtonProps> = ({
    tooltip,
    onClick,
    dataTestId,
    dataQa,
    children,
    skipPermission,
    className,
}) => {
    const classes = useStyles();
    let permission = usePermissionContext();
    const { t } = useTranslation();

    permission = skipPermission ? PermissionType.Visible : permission;

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <CustomizedTooltip
            title={
                permission !== PermissionType.Visible
                    ? t('common:noPermissionToResource')
                    : tooltip
            }
            copy={false}
        >
            <div
                className={classNames(
                    classes.editButton,
                    className,
                    permission === PermissionType.ReadOnly &&
                        classes.editButtonDisabled,
                )}
                onClick={
                    permission === PermissionType.Visible ? onClick : undefined
                }
                data-testid={dataTestId}
                data-qa={dataQa}
            >
                {children}
            </div>
        </CustomizedTooltip>
    );
};

export default NodeActionButton;

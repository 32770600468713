export const compareObjectsAndReturnDifferencesInValues = <
    T extends { [P in keyof T]: T[P] }
>(
    oldObject: T,
    newObject: T,
) => {
    const difference: Partial<T> = {};

    Object.keys(oldObject).forEach((v) => {
        const prop = v as keyof T;
        if (
            JSON.stringify(oldObject[prop]) !== JSON.stringify(newObject[prop])
        ) {
            difference[prop] = newObject[prop];
        }
    });

    return difference;
};

import SelectField from '../SelectField/SelectField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import { EmailOption } from '../../store/types/Voicemail';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface EmailOptionSelectProps {
    id?: string;
    label?: string;
    onChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
    value?: string | OptionFormat;
    tooltipMsg?: string;
    copyTooltip?: boolean;
    dataQa?: string;
    tooltipDataQa?: string;
    className?: string;
    classes?: {
        helperText?: string;
        container?: string;
        iconContainer?: string;
        option?: string;
    };
    required?: boolean;
    setFieldError?: (field: string, value: string | undefined) => void;
    helperText?: string;
}

const EmailOptionSelect: React.VFC<EmailOptionSelectProps> = ({
    id,
    label,
    onChange,
    value,
    tooltipMsg,
    copyTooltip,
    dataQa,
    tooltipDataQa,
    classes: customClasses = {},
    className,
    required,
    setFieldError,
    helperText,
}) => {
    const { t } = useTranslation();

    const emailOptionSelectList = useMemo(
        () =>
            Object.values(EmailOption).map((v) => ({
                name: t(`enums:emailOption.${v}`),
                value: v,
            })),
        [],
    );

    return (
        <SelectField
            id={id}
            label={label}
            items={emailOptionSelectList}
            value={
                emailOptionSelectList.find(
                    (v) => v.value === value,
                ) || null
            }
            disableClearable
            onChange={onChange}
            dataQa={dataQa}
            icon={
                tooltipMsg ? (
                    <IconWithTooltip
                        dataQa={tooltipDataQa}
                        tooltipText={tooltipMsg}
                        copy={copyTooltip}
                    />
                ) : undefined
            }
            getOptionLabel={(v: { name: string }) => v.name}
            getOptionSelected={(v: { value: EmailOption }) => v.value === value}
            className={className}
            classes={customClasses}
            required={required}
            setFieldError={setFieldError}
            helperText={helperText}
        />
    );
};

export default EmailOptionSelect;

interface OptionFormat {
    name: string;
    value: any;
}

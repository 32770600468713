import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TwoLineCell from '../../Calls/TwoLineCell';
import { convertToUserFormat } from '../../../utils/dateWithTimezoneConversion';
import EmptyRowContent from '../../DataTable/EmptyRowContent';
import React from 'react';

export interface WiFiTableRowInterface {
    id: string;
    accountId?: string;
    description?: string;
    connectTime?: string;
    chargedAmount?: number;
    sessionDuration?: string;
    disconnectTime?: string;
}

export const generateColumns = (
    t: TFunction<string>,
    currency = '',
    userDateTimeFormat?: string,
): Column<WiFiTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:calls.description'),
            width: 2,
            accessor: 'description',
            Cell: function Cell(params) {
                const desc = params.row.original.description;

                return (
                    <OverflowTooltip tooltip={desc} text={desc} copy={false} />
                );
            },
        },
        {
            Header: t<string>('screens:billing.connectTimeSessionDuration'),
            accessor: 'connectTime',
            width: 2,
            Cell: function Cell(params) {
                return params.row.original.connectTime ? (
                    <TwoLineCell
                        headerText={convertToUserFormat(
                            params.row.original.connectTime,
                            userDateTimeFormat,
                        )}
                        descriptionText={params.row.original.sessionDuration?.toString()}
                        descriptionTextTooltip={t<string>(
                            'tooltips:billing.disconnectTime',
                            {
                                time: convertToUserFormat(
                                    params.row.original.disconnectTime!,
                                    userDateTimeFormat,
                                ),
                            },
                        )}
                    />
                ) : (
                    <EmptyRowContent />
                );
            },
        },
        {
            Header: t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }),
            width: 1,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};

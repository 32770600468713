import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { PermissionType } from '../store/types/Permission';
import { createContext, useContext, useMemo } from 'react';
import { getPermission } from '../utils/permissions/getPermission';
import { sections } from '../components/Sidebar/Sidebar.utils';
import { Routes } from '../routes/routes';

export const usePermissions = (...components: string[]) => {
    const permissions = useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );

    return getPermission(permissions, ...components);
};

export const useRawPermissions = () => {
    const permissions = useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );

    return permissions;
};

export const PermissionContext = createContext(PermissionType.Visible);
export const usePermissionContext = () => useContext(PermissionContext);

export const useDefaultScreenBasedOnPermissions = () => {
    const permissions = useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );

    const defaultScreen = useMemo(() => {
        for (const section of Object.keys(sections)) {
            for (const screen of sections[section].sections) {
                if (
                    getPermission(permissions, ...screen.permission) !==
                    PermissionType.Hidden
                ) {
                    return screen.route;
                }
            }
        }

        return Routes.Dashboard;
    }, [permissions]);

    return defaultScreen;
};

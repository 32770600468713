import React from 'react';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { greyColor4, greyColor5 } from '../../styles/Colors';

type BadgeProps = {
    count: number;
    color?: string;
};

const useStyles = makeStyles(() => ({
    root: {
        '& > span.MuiBadge-badge': {
            backgroundColor: greyColor4,
            top: '-2px',
            color: greyColor5,
        },
    },
}));

const CustomBadge: React.FC<BadgeProps> = ({ count }) => {
    const classes = useStyles();

    return <Badge badgeContent={count} className={classes.root} />;
};

export default CustomBadge;

import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../..';
import { PortalPermission } from '../../types/Permission';

export interface PermissionsStateType {
    isLoading?: boolean;
    permissions?: PortalPermission;
}

export const initialState = {
    isLoading: true,
};

export type PermissionsActions = ActionType<typeof actions>;

export const permissionsReducer = createReducer<
    PermissionsStateType,
    PermissionsActions
>(initialState)
    .handleAction(actions.getPortalPermissions.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getPortalPermissions.success, (state, action) => ({
        ...state,
        isLoading: false,
        permissions: action.payload,
    }))
    .handleAction(actions.getPortalPermissions.failure, (state) => ({
        ...state,
        isLoading: false,
    }));

export default permissionsReducer;

import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        backgroundColor: Colors.SmokeBackground,
        position: 'relative'
    },

    scrollable: {
        flex: 1,
        overflowY: 'auto',
        height: 'fit-content',
        maxHeight: '100%'
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: 10,
    },

    deviceHeader: {
        padding: `50px 0px 56px 96px`,
        marginBottom: 32,
        backgroundColor: Colors.White,
    },

    contentDevice: {
        '& .description': {
            width: '100%',
        },
    },

    deviceInfo: {
        maxWidth: 1040,
        padding: 0,
        marginBottom: 32,
    },

    extraBottomPadding: {
        paddingBottom: 52
    },

    devicePorts: {
        maxWidth: 1040,
        display: 'flex',
        marginBottom: 40,
    },

    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '@media (max-width: 1276px)': {
        deviceHeader: {
            paddingLeft: 24,
            paddingRight: 24,
        },
        contentDevice: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        deviceHeader: {
            paddingLeft: 90,
            paddingRight: 90,
        },
        contentDevice: {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },

    '@media (min-width: 1441px)': {
        deviceHeader: {
            paddingLeft: 96,
            paddingRight: 96,
        },
        contentDevice: {
            paddingLeft: 96,
            paddingRight: 96,
        },
    },

    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '365px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset',
        },
    },

    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
}));

export type DeviceDetailsProps = {
    id: number;
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import { Grid } from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiTextField-root': {
            width: 200,
        },

        '& .MuiInputBase-input': {
            padding: '0 16px',
        },

        margin: '0 0 32px 0',
    },
    label: {
        width: 200,
        color: Colors.Gray5,
        marginTop: 15,
    },
}));

type RuleInputInfoProps = {
    label: string;
    id: string;
    tooltipText: string;
    readOnly?: boolean;
    handleChange?: (e: React.ChangeEvent<any>) => void;
    value: string;
    helperText?: string;
    setFieldError?: (field: string, value: string | undefined) => void;
    handleBlur: any;
    maxLength?: number;
    className?: string;
};

const RuleInput: React.VFC<RuleInputInfoProps> = ({
    label,
    id,
    tooltipText,
    readOnly,
    handleChange,
    value,
    helperText,
    setFieldError,
    maxLength,
    className,
    handleBlur,
}) => {
    const classes = useStyles();

    return (
        <Grid className={classNames(classes.container, className)}>
            <label className={classes.label} htmlFor={id}>
                {label}
            </label>

            <TextField
                id={id}
                onChange={handleChange}
                value={value}
                icon={
                    <IconWithTooltip
                        dataQa="extension-name-tooltip"
                        tooltipText={tooltipText}
                    />
                }
                iconPosition="end"
                dataQa="extension-name-input"
                readOnly={readOnly}
                helperText={helperText}
                setFieldError={setFieldError}
                maxLength={maxLength || 5}
                onBlur={handleBlur(id)}
            />
        </Grid>
    );
};

export default RuleInput;

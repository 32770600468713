import React from 'react';

interface BillStatusInterface {
    i_invoice_status: number;
    status: string;
}

enum BillStatuses {
    DontPay = 1,
    Unpaid = 2,
    PartiallyPaid = 3,
    Overdue = 4,
    Paid = 5,
    NoPaymentRequired = 6,
    PreviousBalanceRemaining = 7,
}

const BillStatusesColors: Record<
    number,
    { backgroundColor: string; color: string }
> = {
    [BillStatuses.DontPay]: {
        backgroundColor: '#9FCE7B',
        color: '#FFFFFF',
    },
    [BillStatuses.Unpaid]: {
        backgroundColor: '#F9AA62',
        color: '#FFFFFF',
    },
    [BillStatuses.PartiallyPaid]: {
        backgroundColor: '#FFE0C3',
        color: '#757575',
    },
    [BillStatuses.Overdue]: {
        backgroundColor: '#F5841F',
        color: '#FFFFFF',
    },
    [BillStatuses.Paid]: {
        backgroundColor: '#9FCE7B',
        color: '#FFFFFF',
    },
    [BillStatuses.NoPaymentRequired]: {
        backgroundColor: '#CFE7BD',
        color: '#828282',
    },
    [BillStatuses.PreviousBalanceRemaining]: {
        backgroundColor: '#FFE0C3',
        color: '#757575',
    },
};

const BillStatus: React.VFC<BillStatusInterface> = ({
    i_invoice_status,
    status,
}) => {
    const settings = BillStatusesColors[i_invoice_status] || {
        backgroundColor: '#E0E0E0',
        color: '#757575',
    };

    return (
        <span
            style={{
                ...settings,
                width: 128,
                fontSize: 12,
                fontWeight: 400,
                textAlign: 'center',
                padding: '3px 15px',
            }}
        >
            {status}
        </span>
    );
};

export default BillStatus;

import { makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TwoLineCell from '../../Calls/TwoLineCell';
import { convertToUserFormat } from '../../../utils/dateWithTimezoneConversion';
import EmptyRowContent from '../../DataTable/EmptyRowContent';
import React from 'react';
import { TransactionTableInterface } from '../utils';

export interface DataServiceInterface extends TransactionTableInterface {
    unit: string;
    ratio: number;
}

export interface DataServiceTableRowInterface {
    id: string;
    accountId?: string;
    description?: string;
    connectTime?: string;
    chargedAmount?: number;
    sessionDuration?: string;
    disconnectTime?: string;
    volume?: number;
}

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(4)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& th:nth-child(5)': {
            display: ' flex',
            justifyContent: 'end',
        },
    },

    tableContent: {
        '& td': {
            alignItems: 'center !important',
        },
        '& td:nth-child(4)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(5)': {
            display: 'flex',
            justifyContent: 'end',
        },
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    currency = '',
    unit = '',
    userDateTimeFormat?: string,
): Column<DataServiceTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 0.7,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('screens:calls.description'),
            width: 1,
            accessor: 'description',
            Cell: function Cell(params) {
                const desc = params.row.original.description;

                return (
                    <OverflowTooltip tooltip={desc} text={desc} copy={false} />
                );
            },
        },
        {
            Header: t<string>('screens:billing.connectTimeSessionDuration'),
            accessor: 'connectTime',
            width: 1.6,
            Cell: function Cell(params) {
                return params.row.original.connectTime ? (
                    <TwoLineCell
                        headerText={convertToUserFormat(
                            params.row.original.connectTime,
                            userDateTimeFormat,
                        )}
                        descriptionText={params.row.original.sessionDuration?.toString()}
                        descriptionTextTooltip={t<string>(
                            'tooltips:billing.disconnectTime',
                            {
                                time: convertToUserFormat(
                                    params.row.original.disconnectTime!,
                                    userDateTimeFormat,
                                ),
                            },
                        )}
                    />
                ) : (
                    <EmptyRowContent />
                );
            },
        },
        {
            Header: t<string>('screens:billing.VolumeWithUnit', {
                unit: unit,
            }),
            width: 0.6,
            accessor: 'volume',
            Cell: function Cell(params) {
                return params.row.original.volume
                    ? params.row.original.volume.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
        {
            Header: t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }),
            width: 0.6,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { ReactComponent as WarningIcon } from '../../assets/warning-icon.svg';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    container: {
        padding: '0 10px',
        backgroundColor: Colors.White,
        color: Colors.Primary,
        border: `1px solid ${Colors.Primary}`,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        lineHeight: '16px',
        height: 40,
    },
    icon: {
        marginRight: 10,
    },
}));

type WarningProps = {
    text: string;
    className?: string;
};

const Warning: React.VFC<WarningProps> = ({ text, className }) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            <WarningIcon className={classes.icon} />
            {text}
        </div>
    );
};

export default Warning;

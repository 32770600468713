import { useMemo } from 'react';
import { IncomingFormType } from '../components/Forms/AutoAttendants/Incoming/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import {
    DefaultAnsweringActionFlag,
    ServiceFeatureName,
} from '../store/types/ServiceFeature';
import { EmailOption, VoicemailFileFormat, VoicemailGreeting } from '../store/types/Voicemail';
import { getServiceFeatureValue } from '../utils/extensions/getServiceValue';

export const useIncomingAAFormTab = () => {
    const extension = useSelector(
        (state: ReduxState) =>
            state.autoAttendants.autoAttendantDetails?.extension,
    );
    
    const {voicemailSettings, greetingsFilesNames} = useSelector(
        (state: ReduxState) =>
            state.extensions,
    );
    
    const { incomingTab } = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    const serviceFeatures = extension?.account_info?.service_features || [];

    const unifiedMessaging = serviceFeatures.find(
        (e) => e.name === ServiceFeatureName.UnifiedMessaging,
    );

    const fax_mode =
        unifiedMessaging &&
        unifiedMessaging.attributes.find((e) => e.name === 'fax_only_mode')
            ?.effective_values;

    const initValues: IncomingFormType = useMemo(() => {
        return {
            defaultAnsweringMode:
                getServiceFeatureValue(
                    ServiceFeatureName.DefaultAction,
                    serviceFeatures,
                ) || DefaultAnsweringActionFlag.RingForwardVoicemail,
            defaultAnsweringTimeout:
                getServiceFeatureValue(
                    ServiceFeatureName.DefaultAction,
                    serviceFeatures,
                    'timeout',
                ) || 0,
            emailAddressIncoming: incomingTab?.emailAddressIncoming || '',
            emailOption: incomingTab?.emailOption || voicemailSettings?.ext_email_action || EmailOption.None,
            faxFormat: incomingTab?.faxFormat || '',
            unifiedMessagingLocked: unifiedMessaging?.locked === 1,
            unifiedMessaging: unifiedMessaging?.effective_flag_value === 'Y',
            faxMailboxSwitcher: 
                (unifiedMessaging?.effective_flag_value === 'Y' &&
                    fax_mode?.every((e) => e === 'Y')) || false,
            autoPlayStatus: voicemailSettings?.auto_play === 'yes',
            announceDateStatus: voicemailSettings?.announce_dt === 'yes',
            requirePinStatus: voicemailSettings?.password_ask === 'yes',
            pin: voicemailSettings?.password || '',
            unifiedMessagingEmailAddress: voicemailSettings?.ext_email || '',
            fileFormat:
                voicemailSettings?.ext_email_vm_fmt || VoicemailFileFormat.WAV,
            greetingType:
                voicemailSettings?.greetings || VoicemailGreeting.Standard,
            greetingFileName:
                greetingsFilesNames?.[
                    voicemailSettings?.greetings || VoicemailGreeting.Standard
                ] || '',
            greetingFile: null,
        };
    }, [extension, incomingTab, voicemailSettings, greetingsFilesNames]);

    return { initValues };
};

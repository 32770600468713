import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store';
import { ReduxState } from '../../../store/types';
import { ExternalIframeData } from '../../../store/types/Wallboard';

export type ExternalUrlWidgetProps = {
    data: ExternalIframeData,
    id: string
};

const ExternalUrlWidget: React.VFC<ExternalUrlWidgetProps> = ({
    data,
    id
}) => {

    const refreshWidgetsQueue = useSelector((state: ReduxState) => state.wallboard.refreshWidgetsQueue);
    
    const dispatch = useDispatch();
    
    function refresh() {
        const iframe = document.getElementById('iframe_' + id) as HTMLIFrameElement;
        if(iframe) {
            iframe.src = iframe.src + '';
        }
        dispatch(actions.refreshWidgetCompleted.request(id));
    }

    const refreshRequired = useMemo(() => !!(refreshWidgetsQueue?.find(e => e.id === id)), 
        [refreshWidgetsQueue, id]);

    useEffect(() => {
        if(refreshRequired) {
            refresh();
        }
    }, [refreshRequired]);

    if(!data?.url)
        return (<></>);

    return (
        <iframe 
            src={data.url}
            width="100%"
            height="100%"
            style={{
                border: 0,
                width: '100%',
                height: '100%',
            }}
            allowFullScreen
            loading="lazy"
            referrerPolicy='no-referrer-when-downgrade'
            id={'iframe_' + id}
            data-testid="iframe-widget"
        />
    );
};

export default ExternalUrlWidget;

import React from 'react';
import { PaymentMethod } from '../../store/types/Payment';
import { ReactComponent as Amex } from '../../assets/payments/amex.svg';
import { ReactComponent as Bank } from '../../assets/payments/bank.svg';
import { ReactComponent as DinersClub } from '../../assets/payments/diners_club.svg';
import { ReactComponent as Discover } from '../../assets/payments/discover.svg';
import { ReactComponent as Maestro } from '../../assets/payments/maestro.svg';
import { ReactComponent as Mastercard } from '../../assets/payments/mastercard.svg';
import { ReactComponent as Paypal } from '../../assets/payments/paypal.svg';
import { ReactComponent as Stripe } from '../../assets/payments/stripe.svg';
import { ReactComponent as Visa } from '../../assets/payments/visa.svg';

type PaymentMethodIconProps = {
    method: PaymentMethod;
};

const PaymentsIcons: { [key in PaymentMethod]: React.ReactNode } = {
    [PaymentMethod.AmericanExpress]: <Amex />,
    [PaymentMethod.BankAccount]: <Bank />,
    [PaymentMethod.DinersClub]: <DinersClub />,
    [PaymentMethod.Discover]: <Discover />,
    [PaymentMethod.Maestro]: <Maestro />,
    [PaymentMethod.Mastercard]: <Mastercard />,
    [PaymentMethod.Paypal]: <Paypal />,
    [PaymentMethod.Stripe]: <Stripe />,
    [PaymentMethod.Visa]: <Visa />,
};

const PaymentMethodIcon: React.VFC<PaymentMethodIconProps> = ({ method }) => {
    return <>{PaymentsIcons[method]}</>;
};

export default PaymentMethodIcon;

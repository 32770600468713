import React, {useEffect, useMemo} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {
    CallSettingsFormProps,
    useStyles,
} from './CallSettingsForm.utils';
import SelectField from '../../../SelectField/SelectField';
import {DefaultAnsweringActionFlag} from '../../../../store/types/ServiceFeature';
import TextField from '../../../TextField/TextField';
import Separator from '../../../Separator/Separator';
import UnifiedMessagingForm from "../../Extensions/UnifiedMessaging/UnifiedMessagingForm";
import CallBarringForm from "../../Extensions/CallBarring/CallBarringForm";
import CallRecordingForm from "../../Extensions/CallRecording/CallRecordingForm";
import SipContactForm from "../SipContact/SipContactForm";
import {SipTrunkExtensionCallSettingsFormType} from "../SipContact/SipContactForm.utils";

const CallSettingsForm: React.FC<CallSettingsFormProps> = (
    {
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        dynamicList,
        callRecordingEmail
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
    } = useFormikContext<SipTrunkExtensionCallSettingsFormType>();

    useEffect(() => {
        handleDirtyChange?.('callSettings', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callSettings', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callSettings', () => isValid);
    }, [isValid]);

    const defaultAnsweringModes = useMemo(
        () =>
            Object.values(DefaultAnsweringActionFlag).map((v) => ({
                name: t(`enums:defaultAnsweringActionEnum.${v}`),
                value: v,
            })),
        [],
    );

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.itemsContainer}>
                <div className={classes.rowContainer}>
                    <SelectField
                        label={t('screens:extensions.defaultAnsweringMode')}
                        items={defaultAnsweringModes}
                        value={defaultAnsweringModes.find(
                            (v) => v.value === values.defaultAnsweringMode,
                        )}
                        classes={{
                            container: classes.defaultAnswringModeInput,
                        }}
                        dataQa="incomingform-answering-mode-select"
                        onChange={(_, value) => {
                            setFieldValue('defaultAnsweringMode', value.value);
                        }}
                        getOptionLabel={(v: { name: string; value: string }) =>
                            v.name
                        }
                        getOptionSelected={(v: {
                            name: string;
                            value: string;
                        }) => v.value === values.defaultAnsweringMode}
                        disableClearable
                    />

                    <TextField
                        id="defaultAnsweringTimeout"
                        label={t('screens:extensions.timeout')}
                        onChange={handleChange}
                        value={values.defaultAnsweringTimeout?.toString()}
                        type="number"
                        className={classes.numberInput}
                        dataQa="answering-timeout-select"
                        inputProps={{
                            inputProps: {
                                min: 1,
                                max: 999,
                            },
                            pattern: '[0-9]*',
                            startAdornment: (
                                <span className={classes.minText}>sec</span>
                            ),
                        }}
                        widthFromLiableText
                    />
                </div>
            </Grid>
            <Separator/>
            <SipContactForm dynamicList={dynamicList}/>
            <Separator/>
            <UnifiedMessagingForm/>
            <Separator/>
            <CallBarringForm/>
            <Separator/>
            <CallRecordingForm
                callRecordingEmail={callRecordingEmail}
                customEmailBlockMsg={t('tooltips:sipTrunks.blockedEmail')}
            />
        </div>
    );
};

export default CallSettingsForm;

import React from 'react';
import TextField from '../TextField/TextField';
import CustomKeyboardDateTimePicker from '../KeyboardDateTimePicker/KeyboardDateTimePicker';
import SelectField from '../SelectField/SelectField';
import { useTranslation } from 'react-i18next';
import NumericTextField from '../NumericTextField/NumericTextField';
import { Colors } from '../../styles/Colors';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { AdditionalElement } from '../../utils/transformers';
import { OpenInNew } from '@material-ui/icons';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

type InputParserHelperProps = {
    element: AdditionalElement;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
    values: any;
    setFieldError: (field: string, errorMsg?: string) => void;
    errors: any;
    index: number;
    property: any;
    key: string;
    readOnly?: boolean;
};

type ListElement = {
    id?: number;
    text?: string;
    value?: string;
};

export const useStyles = makeStyles(() => ({
    icon: {
        cursor: 'pointer',
        padding: 3,
        backgroundColor:"transparent",
        color:Colors.PlaceHolder,

        '&:hover': {
            backgroundColor:Colors.Gray14,
            borderRadius:'50%',
            transition: 'background-color 150ms '+Colors.Gray14+' 0ms',
        },

        '&:active': {
            backgroundColor: Colors.Gray8,
            borderRadius:'50%',
            transition: 'background-color 150ms '+Colors.Gray8+' 0ms',
        }
    }
}));

//@ts-ignore
const InputParserHelper: React.FC<InputParserHelperProps> = ({
    element,
    property,
    setFieldValue,
    values,
    setFieldError,
    errors,
    index,
    readOnly,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const onChangeField = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setFieldValue(
            `additionalDetails.[${index}].value`,
            event.target?.value,
        );
    };

    const onChangeDateField = (value?: string | null) => {
        setFieldValue(`additionalDetails.[${index}].value`, value);
    };

    const onChangeSelectField = (
        e: React.ChangeEvent<{}>,
        value?: { value: any },
    ) => {
        setFieldValue(`additionalDetails.[${index}].value`, value?.value);
    };

    const isValidURL = (value: string | undefined) => {
        const urlValidationResult = Yup.string().url().isValidSync(value || '');
        const protocolValidationResult = (value || '').toLocaleLowerCase()
            .startsWith('http'.toLocaleLowerCase());
        return urlValidationResult && protocolValidationResult;
    }

    const visitWebSite = (value: string | undefined) => {
        if(value) {
            window.open(value, '_blank')?.focus();
        }
    };

    switch (element?.type) {
        case 'text':
            return (
                <div key={`${element.name}-${index}-text`}>
                    <TextField
                        id={`additionalDetails.[${index}].value`}
                        label={element.name}
                        onChange={onChangeField}
                        value={element.value}
                        required={element?.mandatory === 'Y'}
                        setFieldError={setFieldError}
                        helperText={errors?.[index]?.value || null}
                        maxLength={
                            element.name === 'Other field'
                                ? 255
                                : property.max
                                ? Number(property.max)
                                : undefined
                        }
                        readOnly={readOnly}
                        icon={
                            isValidURL(element.value)
                            && (<IconWithTooltip
                                    dataQa={"mycompany-open-link-icon" + index}
                                    tooltipText={t(
                                        'tooltips:myCompany.visitWebSite',
                                    )}
                                    copy={false}
                                    IconComponent={OpenInNew}
                                    onClick={() => {
                                        visitWebSite(element.value);
                                    }}
                                    className={classes.icon}
                                />)
                        }
                        iconPosition={'end'}
                        convertToLink={isValidURL(element.value)}
                        onClick={() => {
                            if(isValidURL(element.value)) {
                                visitWebSite(element.value);
                            }
                        }}
                    />
                </div>
            );
        case 'date':
            return (
                <div key={`${element.name}-${index}-date`}>
                    <CustomKeyboardDateTimePicker
                        id={`additionalDetails.[${index}].value`}
                        label={element.name}
                        value={element.value}
                        onChange={onChangeDateField}
                        dataQa={'call-history-filter-from'}
                        dataTestId={'call-history-filter-from'}
                        minDate={
                            property.date_from
                                ? new Date(property?.date_from)
                                : null
                        }
                        maxDate={
                            property.date_to
                                ? new Date(property?.date_to)
                                : null
                        }
                        withHours={false}
                        allowEmptyDate
                        setFieldError={setFieldError}
                        helperText={errors?.[index]?.value || null}
                        required={element?.mandatory === 'Y'}
                        userDateTimeFormat={userDateFormat}
                    />
                </div>
            );
        case 'datetime':
            return (
                <div key={`${element.name}-${index}-datetime`}>
                    <CustomKeyboardDateTimePicker
                        id={`additionalDetails.[${index}].value`}
                        label={element.name}
                        value={element.value}
                        onChange={onChangeDateField}
                        dataQa={'call-history-filter-from'}
                        dataTestId={'call-history-filter-from'}
                        minDate={
                            property.date_from
                                ? new Date(property?.date_from)
                                : null
                        }
                        maxDate={
                            element.name == 'Date range'
                                ? new Date('31-Jul-21 00:00:00')
                                : property.date_to
                                ? new Date(property?.date_to)
                                : null
                        }
                        allowEmptyDate
                        setFieldError={setFieldError}
                        helperText={errors?.[index]?.value || null}
                        required={element?.mandatory === 'Y'}
                        userDateTimeFormat={userDateTimeFormat}
                    />
                </div>
            );
        case 'number':
            const countLabelHeight = () => {
                const sum = element.value?.includes(',') ? 5 : 0;
                const valueLength =
                    element.value?.length +
                    (property.decimal ? property.decimal + 1 : 0);

                const margin =
                    sum +
                    18 +
                    (element.value?.includes(',')
                        ? valueLength - 1
                        : valueLength) *
                        9.3;
                return margin > 400 ? 400 : margin;
            };
            return (
                <>
                    <div key={`${element.name}-${index}-number-monetary`}>
                        <NumericTextField
                            id={`additionalDetails.[${index}].value`}
                            value={element.value}
                            onChange={onChangeField}
                            decimalScale={property.decimal || 0}
                            min={property.min}
                            max={
                                element.name === 'Number with constraints'
                                    ? 10
                                    : property.max
                            }
                            MuiInputProps={{
                                label: element.name,
                                required: element?.mandatory === 'Y',
                                helperText: errors?.[index]?.value || null,
                                iconPosition: 'end',
                                setFieldError,
                                inputProps: {
                                    startAdornment:
                                        property.monetary &&
                                        element?.value !== undefined &&
                                        element?.value !== '' ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: 16,
                                                    left: countLabelHeight(),
                                                    backgroundColor:
                                                        Colors.White,
                                                }}
                                            >
                                                {values.amount}
                                            </span>
                                        ) : undefined,
                                },
                            }}
                        />
                    </div>
                </>
            );

        case 'dropdown':
        case 'list':
            const items =
                property?.list.map((v: ListElement) => ({
                    label: v.text,
                    value: v.value,
                })) || [];

            return (
                <div key={`${element.name}-${index}-list`}>
                    <SelectField
                        id={`additionalDetails.[${index}].value`}
                        onChange={onChangeSelectField}
                        label={element.name}
                        items={items}
                        value={
                            items.find((v: any) => v.value === element.value) ||
                            null
                        }
                        getOptionLabel={(v) => v.label}
                        required={element?.mandatory === 'Y'}
                        setFieldError={setFieldError}
                        helperText={errors?.[index]?.value || null}
                    />
                </div>
            );
    }
};

export default InputParserHelper;

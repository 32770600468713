import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import PasswordExpirationFrom from '../../components/Forms/LoginForm/PasswordExpirationForm';
import React from 'react';
import { ApiChangePasswordPayload } from '../../store/services/auth-api';
import * as actions from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { useTranslation } from 'react-i18next';
import usePageTitle from '../../hooks/usePageTitle';

const PasswordExpiration = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePageTitle();

    const { login, oldPassword } = useSelector(
        (state: ReduxState) => state.auth,
    );

    const dispatchChangePasswordAction = (
        payload: ApiChangePasswordPayload,
    ) => {
        dispatch(actions.passwordChange.request(payload));
    };

    return (
        <AuthWrapper title={t('screens:signIn.changePasswordTitle')}>
            <PasswordExpirationFrom
                onSubmitAction={dispatchChangePasswordAction}
                validateOnChange={false}
                login={login || ''}
                oldPassword={oldPassword || ''}
            />
        </AuthWrapper>
    );
};

export default PasswordExpiration;

import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../../styles/Colors';
import { FlowNode } from '../utils/types';
import { Handle, Position } from 'react-flow-renderer';
import { ReactComponent as AddIcon } from '../../../../../assets/ivr_plus.svg';
import { usePermissionContext } from '../../../../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { PermissionType } from '../../../../../store/types/Permission';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    mainContainer: {
        height: 52,
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        height: 40,
        width: 40,
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: Colors.Secondary12,
        backgroundColor: Colors.White,
        borderWidth: 2,
        borderStyle: 'solid',
        pointerEvents: 'auto',
    },
    insideContainer: {
        height: 34,
        width: 34,
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.Primary,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: Colors.Primary3,
        },
    },
    insideDisabledContainer: {
        backgroundColor: Colors.Gray8,

        '& svg': {
            '& path': {
                fill: `${Colors.Gray10} !important`,
                fillOpacity: 1,
            },
        },

        '&:hover': {
            backgroundColor: Colors.Gray8,
        },
    },
    handler: {
        left: '0px !important',
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
    },
}));

const AddNode = (node: FlowNode) => {
    const classes = useStyles();

    const { data } = node;

    const permission = usePermissionContext();
    const { t } = useTranslation();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <CustomizedTooltip
            title={t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission === PermissionType.Visible}
        >
            <div className={classes.mainContainer}>
                <Handle
                    type="target"
                    position={Position.Left}
                    id="a"
                    className={classes.handler}
                />
                <div
                    className={classes.container}
                    onClick={
                        permission === PermissionType.Visible
                            ? () =>
                                  data?.parentMenu &&
                                  data?.onPressTransitionNode?.({
                                      menu: data?.parentMenu,
                                  })
                            : undefined
                    }
                    data-testid="add-node"
                    data-qa="add-node"
                >
                    <div
                        className={classNames(
                            classes.insideContainer,
                            permission === PermissionType.ReadOnly &&
                                classes.insideDisabledContainer,
                        )}
                    >
                        <AddIcon data-qa="add-icon" data-testid="add-icon" />
                    </div>
                </div>
            </div>
        </CustomizedTooltip>
    );
};

export default memo(AddNode);

import {useSelector} from "react-redux";
import {APIErrorInterface, ReduxState} from "../store/types";
import {ConfigData} from "../store/types/ConfigData";
import {useMemo} from "react";

export const useCreateNewSipTrunk = () => {

    const isDataFetching = useSelector<ReduxState, boolean>(
        (state) => !!state.sipTrunks.createSipTrunkDataIsLoading,
    );
    const isLoading = useSelector<ReduxState, boolean>(
        (state) => !!state.sipTrunks.isCreatingNewSipTrunk,
    );
    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );
    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.sipTrunks.createNewSipTrunkErrors,
    );

    return useMemo(() => {

        return {
            data: {
                isDataFetching, isLoading,
                config, apiErrors
            }
        }
    }, [isDataFetching, isLoading, config, apiErrors])


}
import { Permission, PermissionType, PortalPermission } from '../../store/types/Permission';
import Config from '../../config.json';
import {convertBool} from "../parseBool";

const defaultPermission =
    !convertBool(Config.DEFAULT_PERMISSION)
        ? PermissionType.Hidden
        : PermissionType.Visible;

export const getPermission = (
    permissions: PortalPermission | undefined,
    ...components: string[]
) => {
    let component = permissions;
    let returnValue: PermissionType;

    components.forEach((v) => {
        component = component?.components?.[v];
        if(component?.name == Permission?.MyCompany?.CompanyInfo?.value[0]) {
            const myCompany = component?.components[Permission?.MyCompany?.CompanyInfo?.value[1]];
            if(myCompany) {
                const branchesHidden = Permission?.MyCompany?.CompanyInfo?.Branch?.value[2] && myCompany.components[Permission.MyCompany.CompanyInfo.Branch.value[2]]?.permission === PermissionType.Hidden;
                const mainHidden = Permission?.MyCompany?.CompanyInfo?.Main?.value[2] && myCompany.components[Permission.MyCompany.CompanyInfo.Main.value[2]]?.permission === PermissionType.Hidden;
                if(branchesHidden && mainHidden && myCompany?.permission === PermissionType.Visible) {
                    myCompany.permission = PermissionType.Hidden;
                }
            }
        }
    });

    if(!component?.permission && components.length > 1) {
        const skipLastValue = components.filter((_, indx) => indx !== (components.length - 1));
        returnValue = getPermission(permissions, ...skipLastValue) as PermissionType;
        return returnValue;
    }

    returnValue = (component?.permission || defaultPermission) as PermissionType;
    return returnValue;
};

import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/';
import { CallQueue } from '../../types/CallQueue';

export interface CallQueuesStateType {
    callQueues?: CallQueue[];
    isLoading?: boolean;
}

export const initialState: CallQueuesStateType = {};

export type CallQueueActionsType = ActionType<typeof actions>;

const callQueuesReducer = createReducer<
    CallQueuesStateType,
    CallQueueActionsType
>(initialState)
    .handleAction(actions.getCallQueues.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.getCallQueues.success, (state, action) => {
        return {
            ...state,
            callQueues: action.payload,
            isLoading: false,
        };
    })
    .handleAction(actions.getCallQueues.failure, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    });

export default callQueuesReducer;

import {
    put,
    select,
    takeLatest,
    takeEvery,
    call,
    delay,
} from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';
import * as actions from '../../actions';
import { AccountListRequest, AccountListResponse } from '../../types/Account';
import axios, { AxiosResponse } from 'axios';
import { AutoAttendantListItem } from '../../types/AutoAttendant';
import { ServiceFeatureName } from '../../types/ServiceFeature';
import {
    CustomerDIDNumberType,
    CustomerNumberType,
} from '../../types/CustomerDIDNumber';
import {
    addEnableToDelete,
    makeAutoAttendantItem,
    mapToItem,
} from '../../../utils/transformers';
import {
    getDIDNumbersByAccountID,
    updateAccountDIDNumbers,
} from '../didNumbers/saga';
import { fetchAccountList } from '../generic/saga';
import JSONFormData from '../../../utils/JSONFormData';
import { api } from '../../services/axios';
import { APIErrorInterface, ReduxState } from '../../types';
import { showErrorToast } from '../../../utils/showErrorToast';
import { Account, Customer, DID, Voicemail } from '../../../services/endpoints';
import {
    CreateExtensionFaultCode,
    CustomerExtension,
    ExtensionType,
    UpdateExtensionFaultCode,
} from '../../types/Extension';
import { toast } from 'react-hot-toast';
import i18n from '../../../services/i18n';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import { EditFaxMailboxForm } from '../../../components/Forms/FaxMailboxes/DetailsForm/utils';
import { VoicemailSettings } from '../../types/Voicemail';
import qs from 'qs';
import Config from '../../../config.json';
import { getProducts } from '../dashboard/saga';
import { CustomerAgreementCondition } from '../../types/CustomerAgreementCondition';
import { EditExtensionForm } from '../../../views/Extensions/ExtensionDetails/utils';
import {
    addCustomerExtension,
    deleteExtension,
    fetchExtensionListById,
    getAccountInfo,
    updateExtensionStatusCall,
} from '../extensions/extensions/saga';
import { ExtensionsListItem } from '../../reducers/extensions/extensions/reducer';
import { getExtensionVoicemailSettings } from '../extensions/voicemail/saga';
import { getExtensionProducts } from '../extensions/plan/saga';
import { FaxMailboxesStateType } from '../../reducers/faxMailboxes/reducer';

const filterOnlyFax = (item: AutoAttendantListItem) => {
    let auto_attendant = false;
    let fax_mode = false;
    item.serviceFeatures?.forEach((o) => {
        if (
            o.name == ServiceFeatureName.AutoAttendant &&
            o.effective_flag_value == 'Y'
        ) {
            auto_attendant = true;
        }

        if (
            o.name == ServiceFeatureName.UnifiedMessaging &&
            o.attributes.filter(
                (a) =>
                    a.name == 'fax_only_mode' &&
                    a.effective_values.filter((v) => v == 'Y').length,
            ).length
        ) {
            fax_mode = true;
        }
    });

    return !auto_attendant && fax_mode;
};

export const GET_FAX_MAILBOX_ERROR_NOT_FOUND = 'GET_FAX_MAILBOX_ERROR_NOT_FOUND';

export function* fetchMailboxesList(
    action: ActionType<typeof actions.getFaxMailboxesList.request>,
) {
    const limit = 300;
    const offset = 0;

    try {
        let res: AxiosResponse<AccountListResponse>;
        let params: Partial<AccountListRequest> & { offset: number };

        if (action.payload.id) {
            params = {
                limit: limit,
                offset: offset,
                extension_id: action.payload.id.toString(),
            };

            res = yield fetchAccountList(undefined, params);

            if (res.data.total == 0) {
                params = {
                    limit: limit,
                    offset: offset,
                    id: action.payload.id.toString(),
                };

                res = yield fetchAccountList(undefined, params);
            }
        } else {
            params = {
                limit: limit,
                offset: offset,
            };

            res = yield fetchAccountList(undefined, params);
        }

        const total = res.data.total;
        let accountList = [...res.data.account_list];
        if (res.data.account_list.length < total) {
            for (let i = 0; i < total / limit; i++) {
                params.offset = params.offset + limit;
                const resMore: AxiosResponse<AccountListResponse> = yield fetchAccountList(
                    undefined,
                    params,
                );
                accountList = accountList.concat(resMore.data.account_list);
            }
        }

        const accountMapResult = accountList.map(mapToItem);

        yield call(getProducts);
        const mainProducts: CustomerAgreementCondition[] = yield select(
            (state: ReduxState) => state.dashboard.mainProducts,
        );

        let filteredData = accountMapResult.filter(filterOnlyFax);
        filteredData = addEnableToDelete(filteredData, mainProducts);

        yield put(
            actions.getFaxMailboxesList.success({
                items: filteredData,
                total: filteredData.length,
            }),
        );
    } catch (err: any) {
        if (err.response?.data) {
            yield put(actions.getFaxMailboxesList.failure());
        }
    }
}

export function* fetchDIDNumber(
    action: ActionType<typeof actions.fetchDIDNumber.request>,
) {
    try {
        const numberResponse: AxiosResponse<CustomerNumberType> = yield getDIDNumbersByAccountID(
            action.payload.i_account,
        );

        yield put(
            actions.fetchDIDNumber.success({
                i_account: action.payload.i_account,
                numbers: numberResponse.data.number_list,
            }),
        );
    } catch (e) {
        yield put(
            actions.fetchDIDNumber.failure({
                i_account: action.payload.i_account,
            }),
        );
    }
}

export function* createNewFaxMailbox(
    action: ActionType<typeof actions.createNewFaxMailbox.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const i_c_ext: number = yield addCustomerExtension(
            action.payload.extensionName || '',
            action.payload.extensionNumber,
            undefined,
            action.payload.mainProductId,
        );

        body.setParams({
            i_c_ext,
        });

        const customerInfoResponse: AxiosResponse<{
            extension_info: { i_account: number };
        }> = yield api.post(Customer.GetCustomerExtensionInfo, body);

        body.setParams({
            account_info: {
                i_account: customerInfoResponse.data.extension_info.i_account,
                email: action.payload.extensionEmail,
            },
        });

        yield api.post(Account.UpdateAccount, body);

        if (action.payload.didNumber) {
            body.setParams({
                i_c_ext,
            });

            const customerInfoResponse: AxiosResponse<{
                extension_info: { i_account: number };
            }> = yield api.post(Customer.GetCustomerExtensionInfo, body);

            if (action.payload.didNumber) {
                const customerDidNumbersList: CustomerDIDNumberType[] = yield select(
                    (state: ReduxState) => state.didNumbers.numbers,
                );
                for (const v of action.payload.didNumber) {
                    body.setParams({
                        i_did_number: customerDidNumbersList.find(
                            (w) => w.did_number === v,
                        )?.i_did_number,
                        i_master_account:
                            customerInfoResponse.data.extension_info.i_account,
                    });
                    yield api.post(DID.AssignDIDToAccount, body);
                }
            }
        }

        yield put(actions.createNewFaxMailbox.success());
        toast(i18n.t<string>('screens:faxMailboxes.faxMailboxAdded'));
        location?.replace(`fax-mailboxes/${action.payload.extensionNumber}`);
    } catch (err: any) {
        showErrorToast(
            err.response?.data?.faultstring,
            err.response?.data?.faultcode,
            [
                CreateExtensionFaultCode.DuplicateId,
                CreateExtensionFaultCode.MaxOfferedQuantity,
                CreateExtensionFaultCode.ExtHGNumberInUse,
            ],
        );
        yield put(actions.createNewFaxMailbox.failure(err.response?.data));
    }
}

export function* fetchFaxMailboxesDetails(
    action: ActionType<typeof actions.getFaxMailboxesDetails.request>,
) {
    try {
        let iAccount: number;
        const extension: ExtensionsListItem = yield fetchExtensionListById(
            action.payload.id.toString(),
        );

        if (extension) {
            iAccount = extension.i_account;
        } else {
            const accountList: AxiosResponse<AccountListResponse> = yield fetchAccountList(
                undefined,
                {
                    id: action.payload.id.toString(),
                },
            );

            iAccount = accountList.data.account_list.length
                ? accountList.data.account_list[0].i_account
                : 0;
        }

        if(!iAccount) {
            yield put(actions.getFaxMailboxesDetails.failure({
                faultcode: GET_FAX_MAILBOX_ERROR_NOT_FOUND,
                faultstring: i18n.t<string>('tooltips:faxMailboxes.faxMailboxNotFound', {
                    value: action.payload.id
                })
            } as APIErrorInterface));
            return;
        }

        const numberResponse: AxiosResponse<CustomerNumberType> = yield getDIDNumbersByAccountID(
            iAccount,
        );

        const account: AxiosResponse<{
            account_info: ExtensionType;
        }> = yield getAccountInfo(iAccount);

        const item = makeAutoAttendantItem(
            numberResponse.data.number_list,
            account.data.account_info,
            extension,
        );

        if (
            account.data.account_info.um_domain &&
            account.data.account_info.i_account
        ) {
            yield call(
                getExtensionVoicemailSettings,
                actions.getExtensionVoicemailSettings.request({
                    accountId: account.data.account_info?.id.toString(),
                    domain: account.data.account_info.um_domain,
                }),
            );
        }

        yield call(getExtensionProducts);

        const mainProducts: CustomerAgreementCondition[] = yield select(
            (state: ReduxState) => state.extensions.products?.mainProducts,
        );

        item.delete_enable =
            item.delete_enable &&
            !!mainProducts.find(
                (p: CustomerAgreementCondition) =>
                    p.i_product == item.account_info?.i_product,
            );

        yield put(actions.getFaxMailboxesDetails.success({ item: item }));
    } catch (e) {
        yield put(actions.getFaxMailboxesDetails.failure(undefined));
    }
}

export function* setFaxMailboxStatus(
    action: ActionType<typeof actions.setFaxMailboxesStatus.request>,
) {
    try {
        yield updateExtensionStatusCall(action.payload);
        yield put(actions.setFaxMailboxesStatus.success(action.payload));
        toast(
            i18n.t<string>(
                i18n.t<string>(
                    action.payload.account_info.blocked === 'Y'
                        ? 'screens:faxMailboxes.faxMailboxDisabled'
                        : 'screens:faxMailboxes.faxMailboxEnabled',
                ),
            ),
        );
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
    }
}

export function* editFaxMailbox(
    action: ActionType<typeof actions.editFaxMailbox.request>,
) {
    try {
        const faxesState: FaxMailboxesStateType = yield select((state) => state.faxMailboxes);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editExtensionTab(dataToSave, action);
        yield editExtensionPlan(dataToSave, action);

        toast(i18n.t<string>('screens:faxMailboxes.faxMailboxEdited'));

        const extensionId =
            action.payload.changedValues.extensionNumber ||
            action.payload.initialValues.extensionNumber;
        if (!action.payload.blockRedirection) {
            location?.replace(
                `${extensionId}?${qs.stringify({
                    tab: action.payload.redirectTab,
                })}`,
            );
        } else if((action.payload.changedValues.extensionNumber &&
            action.payload.changedValues.extensionNumber !== action.payload.initialValues.extensionNumber) || 
            (action.payload.initialValues.extensionNumber && action.payload.changedValues.extensionName !== action.payload.initialValues.extensionName)) {
                const extension = faxesState?.faxMailboxesList?.items?.find(e => e.extension_id === action.payload.initialValues.extensionNumber);
                if(extension) {
                    if(action.payload.changedValues.extensionNumber) {
                        extension.extension_id = action.payload.changedValues.extensionNumber;
                    }
                    if(action.payload.changedValues.extensionName) {
                        extension.name = action.payload.changedValues.extensionName;
                    }
                    if(!extension.account_info) {
                        //@ts-ignore
                        extension.account_info = {};
                    }
                    //@ts-ignore
                    if((!extension.account_info.alias_did_number_list || !extension.account_info.alias_did_number_list.length)
                        && (extension.numbers?.length ?? 0) > 0) {
                            const numbers = extension.numbers?.map(e => { return {
                                i_did_number: 1,
                                did_number: e
                            }});
                            //@ts-ignore
                            extension.account_info.alias_did_number_list = numbers;
                    }
                    yield put(
                        actions.getFaxMailboxesList.success({
                            items: faxesState?.faxMailboxesList?.items || [],
                            total: faxesState?.faxMailboxesList?.total || 0
                        })
                    );
                }
        }
    } catch (e: any) {
        showErrorToast(
            e.response?.data?.faultstring,
            e.response?.data?.faultcode,
            [
                UpdateExtensionFaultCode.DuplicateId,
                UpdateExtensionFaultCode.ExtHGNumberInUse,
            ],
        );
        yield put(actions.editFaxMailbox.failure(e.response?.data));
    }
}

export function* editExtensionPlan(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editFaxMailbox.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    if (data.selectedMainProduct !== undefined || data.selectedAddonsProducts) {
        const selectedMainProduct =
            data.selectedMainProduct ||
            action.payload.initialValues.selectedMainProduct;
        const selectedAddonsProduct =
            data.selectedAddonsProducts ||
            action.payload.initialValues.selectedAddonsProducts;

        body.setParams({
            account_info: {
                i_account: action.payload.i_account,
                i_product: selectedMainProduct,
                assigned_addons: selectedAddonsProduct.map((v) => ({
                    i_product: v,
                })),
            },
        });

        yield api.post(Account.UpdateAccount, body);
    }
}

function* editExtensionTab(
    data: Partial<EditFaxMailboxForm>,
    action: ActionType<typeof actions.editFaxMailbox.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const account: Partial<CustomerExtension> = {};
    const settings: Partial<VoicemailSettings> = {};

    if (data.extensionName || data.extensionNumber) {
        if (action.payload.i_c_ext) {
            const body = new JSONFormData(session_id, csrf_token);
            account.i_c_ext = action.payload.i_c_ext;
            account.i_account = action.payload.i_account;
            account.name = data.extensionName;
            account.id = data.extensionNumber;
            body.setParams({ ...account });

            yield api.post(Customer.UpdateCustomerExtension, body);
        } else if (
            data.extensionName &&
            data.extensionNumber &&
            action.payload.i_account
        ) {
            yield addCustomerExtension(
                data.extensionName,
                data.extensionNumber,
                action.payload.i_account,
            );
        }
    }

    if (data.didNumber) {
        const numbers: CustomerDIDNumberType[] = yield select(
            (state: ReduxState) => state.didNumbers.numbers,
        );

        yield updateAccountDIDNumbers(
            action.payload.initialValues.didNumber,
            data.didNumber,
            numbers,
            action.payload.i_account,
        );
    }

    if (data.extensionEmail !== undefined) {
        settings.ext_email = data.extensionEmail;
    }
    if (data.format !== undefined) {
        settings.fax_file = data.format;
    }
    if (data.action !== undefined) {
        settings.ext_email_action = data.action;
    }

    yield updateFaxMailboxVoicemailSettings(
        settings,
        action.payload.id,
        action.payload.domain,
    );

    if (data.extensionEmail !== undefined) {
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            account_info: {
                i_account: action.payload.i_account,
                email: data.extensionEmail,
            },
        });

        yield api.post(Account.UpdateAccount, body);
    }
}

function* updateFaxMailboxVoicemailSettings(
    settings: Partial<VoicemailSettings>,
    id: string,
    domain: string,
) {
    const { session_id, access_token, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.delete('auth_info');
    body.append(
        'auth_info',
        JSON.stringify({
            login: id,
            domain: domain,
        }),
    );

    if (Object.keys(settings).length) {
        body.setParams({
            vm_settings: settings,
        });

        yield axios.post(Voicemail.SetVmSettings, body, {
            baseURL: Config.SIP_API_URL,
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
    }
}

export function* deleteFaxMailbox(
    action: ActionType<typeof actions.deleteCustomerFaxMailbox.request>,
) {
    try {
        yield deleteExtension(
            action.payload.id,
            action.payload.i_customer,
            action.payload.i_c_ext,
        );

        yield put(
            actions.deleteCustomerFaxMailbox.success({
                id: action.payload.id,
            }),
        );
        toast(i18n.t<string>('screens:faxMailboxes.faxMailboxDeleted'));
        action.payload.callback?.();
    } catch (e: any) {
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.deleteCustomerAutoAttendant.failure());
    }
}

export const faxMailboxesSaga = [
    takeLatest(actions.getFaxMailboxesList.request, fetchMailboxesList),
    takeEvery(actions.fetchDIDNumber.request, fetchDIDNumber),
    takeLatest(actions.createNewFaxMailbox.request, createNewFaxMailbox),
    takeLatest(
        actions.getFaxMailboxesDetails.request,
        fetchFaxMailboxesDetails,
    ),
    takeLatest(actions.setFaxMailboxesStatus.request, setFaxMailboxStatus),
    takeLatest(actions.editFaxMailbox.request, editFaxMailbox),
    takeLatest(actions.deleteCustomerFaxMailbox.request, deleteFaxMailbox),
];

import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0 25px 20px 0',
        maxWidth: 965,
        display: 'flex',
        flexDirection: 'column',
    },

    inputs: {
        display: 'flex',
        padding: '22px 16px 22px 16px',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            height: 'auto',
        },

        '& .MuiInputBase-root': {
            width: 440,
            marginTop: 0,
        },
    },

    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiAutocomplete-root': {
            width: 440,
        },

        '& .MuiTextField-root:first-child': {
            marginRight: 37,
            maxWidth: 440,
        },
        '& .MuiFormControl-root': {
            width: 440,
        },
    },

    rowBoxHeaderMargin: {
        marginBottom: 26,
    },

    additionalMargin: {
        maxWidth: 440,
        marginRight: 37,
    },
    paddingTop: {
        paddingTop: 8,
    },

    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
    },
    boldHeader: {
        fontWeight: 700,
    },
    avatarBox: {
        height: '2.5rem',
    },
    avatar: {
        marginRight: 8,
        textTransform: 'uppercase',
        border: 'none',
        boxShadow: 'none',
        '& div': {
            fontSize: '28px !important',
            fontWeight: 600,
            lineHeight: '16px',
            color: Colors.Gray6,
        },
        '& img': {
            zIndex: 1,
        },
    },
    avatarWrapper: {
        height: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    changeAvatar: {
        color: Colors.Secondary1,
        fontSize: 16,
        fontWeight: 700,
        marginRight: 10,
    },
    avatarLink: {
        height: '100%',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
}));

export const myProfileSettingsValidationSchema = Yup.object().shape({
    email: Yup.string().email(i18n.t<string>('errors:extensions.emailAddress')),
});

export const myProfileSettingsValidationReqFileName: string[] = [
    'email'
];

import React from 'react';
import { RatioTransactionTableInterface } from '../utils';
import { messagingEntityTransformer } from '../../../utils/transformers';
import {
    generateColumns,
    MessagingTableRowInterface,
    useStyles,
} from './Messaging.utils';
import { useTranslation } from 'react-i18next';
import DataGrid from '../../DataGrid/DataGrid';
import { PaginationMode } from '../../DataGrid/types';
import classNames from 'classnames';

const Messaging: React.VFC<RatioTransactionTableInterface> = ({
    currency,
    data,
    onPageChange,
    onPageSizeChange,
    initialPageSize,
    loading,
    rowCount,
    userDateTimeFormat,
    ratio,
    className,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const columns = generateColumns(t, currency, userDateTimeFormat);

    const items: MessagingTableRowInterface[] = data.map((item) =>
        messagingEntityTransformer(item, ratio),
    );

    return (
        <DataGrid<MessagingTableRowInterface>
            data={items}
            dataQa={'messaging-table'}
            loading={loading}
            columns={columns}
            rowCount={rowCount}
            paginationMode={PaginationMode.Server}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            initialPageSize={initialPageSize}
            classes={{
                header: classes.header,
                tableContainer: classNames(classes.tableContent, className),
            }}
        />
    );
};

export default Messaging;

import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    extNumber: {
        marginRight: 9,
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        color: Colors.Text,
    },
    didLabel: {
        fontSize: 16,
        color: Colors.Gray9,
        height: 20,
    },
}));

type AutoAttendantNameProps = {
    name?: string;
    dataQa?: string;
};

const AutoAttendantName: React.VFC<AutoAttendantNameProps> = ({
    name,
    dataQa,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root} data-qa={dataQa}>
            <span className={classes.didLabel}>
                {t('screens:autoAttendants.autoAttendant')}
            </span>
            <div>
                <span className={classes.extNumber}>{name}</span>
            </div>
        </div>
    );
};

export default AutoAttendantName;

import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import DialogContainer, {
    DialogButton,
} from '../../../../AlertDialog/DialogContainer';
import IntervalSelect from '../../../../IntervalSelect/IntervalSelect';
import classNames from 'classnames';
import {
    ForwardingRuleDialogProps,
    useStyles,
} from './ForwardingRuleDialog.utils';
import FollowRuleForm from '../FollowRuleForm';
import {
    followRuleValidationSchema,
    followRuleValidationSchemaWithoutSIP,
} from '../FollowRuleForm.utils';
import {
    CallForwardingRule,
    callForwardingRuleEmptyRule,
} from '../CallForwardingForm.utils';
import CustomizedTextField from '../../../../TextField/TextField';
import { ForwardModeFlag } from '../../../../../store/types/ServiceFeature';

const ForwardingRuleDialog: React.VFC<ForwardingRuleDialogProps> = ({
    isOpen,
    toggleVisibility,
    ruleToEdit,
    permittedSipProxies,
    mode,
    onAddRule,
    onEditRule,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const initialValues = useMemo(() => {
        return ruleToEdit || callForwardingRuleEmptyRule;
    }, [ruleToEdit]);

    const onSubmit = (
        form: CallForwardingRule,
        helpers: FormikHelpers<CallForwardingRule>,
    ) => {
        if (ruleToEdit) {
            onEditRule?.(form);
        } else {
            onAddRule?.(form);
        }

        setTimeout(() => {
            helpers.resetForm();
        }, 300);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={
                mode === ForwardModeFlag.AdvancedForwarding
                    ? followRuleValidationSchema
                    : followRuleValidationSchemaWithoutSIP
            }
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
        >
            {({
                submitForm,
                resetForm,
                dirty,
                setFieldError,
                setFieldValue,
                values,
                errors,
                handleChange,
            }) => (
                <DialogContainer
                    isOpen={isOpen}
                    dataQa="ring-schedule-group-dialog"
                    header={
                        ruleToEdit
                            ? t('screens:extensions.editForwardingRule')
                            : t('screens:extensions.addNewForwardingRule')
                    }
                    className={classes.modalContainer}
                    disableEnforceFocus
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() => {
                                toggleVisibility?.();
                                setTimeout(() => {
                                    resetForm();
                                }, 300);
                            }}
                        />,
                        <DialogButton
                            key="save"
                            label={
                                ruleToEdit
                                    ? t('common:apply')
                                    : t('common:save')
                            }
                            onClick={submitForm}
                            disabled={
                                !dirty ||
                                !values.description ||
                                (mode === ForwardModeFlag.FollowMe &&
                                    !values.number) ||
                                (mode === ForwardModeFlag.AdvancedForwarding &&
                                    !values.number &&
                                    !values.sipProxy)
                            }
                            primary
                        />,
                    ]}
                >
                    <FollowRuleForm
                        setFieldError={setFieldError}
                        setFieldValue={(field, value) => {
                            setFieldValue(field, value);
                        }}
                        rule={values}
                        permittedSipProxies={permittedSipProxies}
                        errors={errors}
                        mode={mode}
                    />

                    <div className={classes.separator} />

                    <IntervalSelect defaultIntervalType={'new'} />

                    <div className={classes.separator} />

                    <CustomizedTextField
                        className={classNames(classes.descriptionInput)}
                        id="description"
                        label={t('screens:extensions.description')}
                        onChange={handleChange}
                        value={values.description}
                        dataQa="forward-to-input"
                        helperText={errors.description}
                        setFieldError={setFieldError}
                        required
                        maxLength={32}
                    />
                </DialogContainer>
            )}
        </Formik>
    );
};

export default ForwardingRuleDialog;

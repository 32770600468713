export interface PricingType {
    customer_costs?: {
        activation: number;
        periodic: number;
        free_of_charge: string;
        iso_4217: string;
    };
}
export interface CustomerDIDNumberType {
    area_name: string | null;
    country_name: string | null;
    description: string;
    did_number: string;
    extension_id?: string;
    extension_name?: string;
    group_name?: string;
    i_account?: number;
    i_customer: number;
    i_did_number: number;
    i_master_account?: number;
    is_provisioned: number;
    master_account_id?: string;
    pricing?: PricingType;
}

export interface CustomerNumberType {
    number_list: CustomerDIDNumberType[];
    total: number;
    inUse?: number
}

export interface StatusInterface {
    success: number;
}

export type AssignDIDToAccountResponse = StatusInterface;
export type CancelDIDAssignmentResponse = StatusInterface;

export enum AssignDIDStatus {
    Fail = 0,
    Ok = 1,
}

export enum CancelDIDStatus {
    Fail = 0,
    Ok = 1,
}

export interface AssignDIDToAccountRequest {
    i_did_number: number;
    i_master_account: number;
}

export interface CancelDIDAssignmentRequest {
    dont_release_to_pool?: number;
    i_customer: number;
    i_did_number: number;
    callback?: () => void;
}

export interface ReassignDIDNumberPayload {
    assignTo: AssignDIDToAccountRequest;
    previousAssigned?: CancelDIDAssignmentRequest;
    callback?: () => void;
    disableToasts?: boolean;
}

export interface DIDInventoryNumber {
    number: number;
}

export interface DIDCountry {
    name: string;
}

export interface DIDArea {
    name: string;
}

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';

export type PromptsFormType = {
    introPromptStatus: boolean;
    introPromptFileName: string;
    introPromptFile: File | null;
    menuPromptStatus: boolean;
    menuPromptFileName: string;
    menuPromptFile: File | null;
    onTimeoutPromptStatus: boolean;
    onTimeoutPromptFileName: string;
    onTimeoutPromptFile: File | null;
    onUnavailableSelectionPromptStatus: boolean;
    onUnavailableSelectionPromptFileName: string;
    onUnavailableSelectionPromptFile: File | null;
};

export const promptsFormValidationSchema = Yup.object().shape({
    introPromptStatus: Yup.boolean().required(),
    menuPromptStatus: Yup.boolean().required(),
    onTimeoutPromptStatus: Yup.boolean().required(),
    onUnavailableSelectionPromptStatus: Yup.boolean().required(),
});

export const promptsFormDefaultValues: PromptsFormType = {
    introPromptStatus: false,
    introPromptFileName: '',
    introPromptFile: null,
    menuPromptStatus: false,
    menuPromptFileName: '',
    menuPromptFile: null,
    onTimeoutPromptStatus: false,
    onTimeoutPromptFileName: '',
    onTimeoutPromptFile: null,
    onUnavailableSelectionPromptStatus: false,
    onUnavailableSelectionPromptFileName: '',
    onUnavailableSelectionPromptFile: null,
};

export type PromptFormProps = {};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '5px 25px 17px 25px',
        maxWidth: 700,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiBox-root': {
            paddingLeft: 0,
        },
    },
    columnItemsContainer: {
        flexDirection: 'column',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },
    subSectionSpace: {
        marginTop: 18,
    },
    numberInput: {
        width: 135,
        marginRight: 20,
    },
    inactiveActionSelect: {
        marginTop: 10,
        '& .MuiInputBase-input': {
            padding: '5px 10px !important',
        },
    },
    selectMaxWidth: {
        width: 475,
        maxWidth: 475,
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    playBeforeGrid: {
        margin: '10px 0',
    },
    uploadMargin: {
        marginLeft: 24,
        marginRight: 10,
    },
    selectMargin: {
        marginTop: 20,
    },
    switchLabel: {
        fontWeight: 'bold',
        color: Colors.Gray7,
        marginRight: 14,
    },
    numberPickerGrid: {
        marginTop: 10,
        '& .MuiFormControl-root': {
            flex: 'unset',
        },
    },
    fileGrid: {
        marginBottom: 20,
    },

    file: {
        paddingLeft: 0,
        '& .MuiGrid-root': {
            maxWidth: 426,
            marginBottom: 6,
        },
        '& .MuiButtonBase-root': {
            width: 90,
        },
    },
}));

import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import Loader from '../../Loader/Loader';
import PaymentStatusIcon from '../PaymentStatusIcon/PaymentStatusIcon';
import { PaymentStatus } from '../../../store/types/Payment';

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 425,
            height: 320,
            backgroundColor: Colors.SmokeBackground,
            padding: '0px 72px 0px 72px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    errorText: {
        color: Colors.Gray5,
        width: 290,
        fontSize: 13,
        lineHeight: '18px',
        paddingBottom: 40,
    },
}));

export type ProcessingDialogProps = {
    isOpen: boolean;
    isError?: boolean;
    toggleVisibility?: () => void;
};

const ProcessingDialog: React.VFC<ProcessingDialogProps> = ({
    isOpen,
    isError,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="processing-dialog"
            header={isError ? t('common:error') : t('common:processing')}
            className={classes.modalContainer}
            disableEnforceFocus
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    disabled={!isError}
                    onClick={toggleVisibility}
                    primary
                />,
            ]}
        >
            {!isError ? (
                <Loader dataQa="edit-payment-method-loader" />
            ) : (
                <>
                    <PaymentStatusIcon status={PaymentStatus.Failed} />
                    <span className={classes.errorText}>
                        {t('screens:billing.checkProvidedCard')}
                    </span>
                </>
            )}
        </DialogContainer>
    );
};

export default ProcessingDialog;

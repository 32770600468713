import { makeStyles } from '@material-ui/core';
import { ForwardModeFlag } from '../../../../../store/types/ServiceFeature';
import { Colors } from '../../../../../styles/Colors';
import { CallForwardingRule } from '../CallForwardingForm.utils';

export interface ForwardingRuleDialogProps {
    onSave?: (form: CallForwardingRule) => void;
    isOpen: boolean;
    toggleVisibility?: () => void;
    ruleToEdit?: CallForwardingRule;
    permittedSipProxies: string[];
    mode: ForwardModeFlag;
    onEditRule?: (rule: CallForwardingRule) => void;
    onAddRule?: (rule: CallForwardingRule) => void;
}

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 900,
            height: 'calc(100vh - 80px)',
            backgroundColor: Colors.SmokeBackground,
            padding: '5px 56px 34px 56px',
        },
    },
    descriptionInput: {
        width: 264,
    },
    separator: {
        height: 1,
        width: 809,
        backgroundColor: Colors.Border,
        margin: '24px 0',
    },
}));

import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';
import {useTranslation} from 'react-i18next';
import EntryModal from './EntryModal';
import {PhoneBookListItem} from '../../store/reducers/phoneBook/reducer';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import CallRemoveBottomBox from '../../components/Calls/CallRemoveBottomBox';
import {PhoneBookListItemExtended} from './EntryModal';
import DataGrid from '../../components/DataGrid/DataGrid';
import {useStyles, generateColumns} from './utils';
import Header from '../../components/ListViewHeader/Header';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import {usePermissions} from '../../hooks/usePermissions';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';

export const PhoneBookList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    usePageTitle();

    const deletePhoneBookPermission = usePermissions(
        ...Permission.CloudPBX.PhoneBook.DeletePhoneBookEntry.value,
    );

    const [entryModalVisible, setEntryModalVisible] = useState<boolean>(false);
    const [removeDialogVisible, setRemoveDialogVisible] = useState<boolean>(
        false,
    );
    const [
        editedRow,
        setEditedRow,
    ] = useState<PhoneBookListItemExtended | null>(null);
    const [rowToDelete, setRowToDelete] = useState<PhoneBookListItem | null>(
        null,
    );

    const [selectedItems, setSelectedItems] = useState<PhoneBookListItem[]>([]);

    const {phonesBookList, isLoading} = useSelector(
        (state: ReduxState) => state.phoneBook,
    );
    const {t} = useTranslation();

    const fetchData = () => {
        dispatch(actions.getPhoneBookList.request({i_customer: 1}));
        dispatch(actions.getMaxAbbreviated.request());
    };

    const setAddEntryModalVisible = (row: PhoneBookListItemExtended | null) => {
        setEditedRow(row);
        setEntryModalVisible(true);
    };

    const closeRemoveModal = () => {
        setRemoveDialogVisible(false);
    };

    const deletePhoneBooks = () => {
        const itemsToDelete: number[] = [];
        selectedItems.forEach((element) => {
            itemsToDelete.push(element.id);
        });
        dispatch(
            actions.deletePhoneBook.request({
                itemsToDelete
            }),
        );

        if (removeDialogVisible) {
            setSelectedItems([]);
            setRemoveDialogVisible(!removeDialogVisible);
        } else {
            setSelectedItems([]);
        }
    };

    const deleteSinglePhoneBook = (itemToDelete: PhoneBookListItem) => {
        const itemsToDelete: number[] = [itemToDelete?.id];
        dispatch(
            actions.deletePhoneBook.request({
                itemsToDelete
            }),
        );

        setRemoveDialogVisible(!removeDialogVisible);
        setEditedRow(null);
    };

    const removeEntry = (itemToDelete: PhoneBookListItem) => {
        setRowToDelete(itemToDelete);
        setRemoveDialogVisible(true);
    };

    const columns = useMemo(
        () => generateColumns(t, setAddEntryModalVisible, removeEntry, classes),
        [],
    );

    useEffect(() => {
        fetchData();
    }, []);

    const onChangeItemsSelection = useCallback(
        (items) => setSelectedItems(items),
        [],
    );

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.PhoneBook.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.header}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.PhoneBook
                                    .AddNewPhoneBookEntry.value
                            }
                        >
                            <Header
                                title={t('screens:phoneBook.phoneBook')}
                                totalNumber={phonesBookList.items.length || 0}
                                showDetails={false}
                                dataQa={'phone-book-button'}
                                buttonText={t('screens:phoneBook.addEntry')}
                                ofText={t('screens:autoAttendants.disabled')}
                                buttonOnClick={() =>
                                    setAddEntryModalVisible(null)
                                }
                                buttonDataQa={'add-new-phone-book-button'}
                                plusIcon={true}
                            />
                        </PermissionProvider>
                    </div>

                    <DataGrid<PhoneBookListItemExtended>
                        columns={columns}
                        data={phonesBookList?.items}
                        rowCount={phonesBookList.total}
                        loading={isLoading}
                        onChangeItemsSelection={onChangeItemsSelection}
                        centeredRows
                        multiSelectEnabled={
                            deletePhoneBookPermission === PermissionType.Visible
                        }
                        hidePagination
                        hideFooter={phonesBookList?.items.length > 0}
                        narrowRows
                        classes={{
                            tableContainer:
                                deletePhoneBookPermission ===
                                PermissionType.Visible
                                    ? classes.tableContainerWithMultiSelect
                                    : classes.tableContainer,
                        }}
                        customRowHeight={55}
                    />
                    <EntryModal
                        isOpen={entryModalVisible}
                        toggleVisibility={() =>
                            setEntryModalVisible(!entryModalVisible)
                        }
                        editedRow={editedRow}
                    />

                    <AlertDialog
                        isOpen={removeDialogVisible}
                        dataQa={'delete-phone-book-modal'}
                        hideHeader={true}
                        className={classes.containerDialog}
                        description={
                            selectedItems.length > 1 ? (
                                t(
                                    'screens:phoneBook.deletePhoneBooksDescription',
                                    {
                                        value: selectedItems.length,
                                    },
                                )
                            ) : (
                                t(
                                    'screens:phoneBook.deletePhoneBookDescription',
                                )
                            )
                        }
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={closeRemoveModal}
                            />,
                            <DialogButton
                                key="delete"
                                label={t('common:delete')}
                                className={classes.primaryModalButton}
                                onClick={
                                    selectedItems.length === 0 && rowToDelete
                                        ? () =>
                                            deleteSinglePhoneBook(rowToDelete)
                                        : () => deletePhoneBooks()
                                }
                            />,
                        ]}
                    />

                    <CallRemoveBottomBox
                        numberOfItems={selectedItems.length}
                        onDelete={() => setRemoveDialogVisible(true)}
                    />
                </div>
            </div>
        </PermissionPlaceholder>
    );
};

import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { RingGroupType } from '../../../types/RingGroup';

export interface AssignedRingGroupsReducerState {
    extensionRingGroups?: RingGroupType[];
    allRingGroups?: RingGroupType[];
}

export const initialState: AssignedRingGroupsReducerState = {};

export type AssignedRingGroupsReducerActions = ActionType<typeof actions>;

export const assignedRingGroupsReducer = createReducer<
    AssignedRingGroupsReducerState,
    AssignedRingGroupsReducerActions
>(initialState)
    .handleAction(actions.getExtensionRingGroups.success, (state, action) => {
        return {
            ...state,
            ...action.payload,
        };
    })
    .handleAction(actions.resetExtensionRingGroups, (state) => {
        return {
            ...state,
            extensionRingGroups: undefined,
        };
    });

export default assignedRingGroupsReducer;

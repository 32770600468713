import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { VoicemailGreeting, VoicemailSettings } from '../../../types/Voicemail';

export interface VoicemailReducerState {
    voicemailSettings?: VoicemailSettings;
    greetingsFilesNames?: { [key in VoicemailGreeting]?: string };
}

export const initialState: VoicemailReducerState = {
    greetingsFilesNames: {
        name: 'name.au',
        personal: 'personal.au',
        extended: 'extended.au',
        standard: 'standard.au',
    },
};

export type VoicemailReducerActions = ActionType<typeof actions>;

export const voicemailReducer = createReducer<
    VoicemailReducerState,
    VoicemailReducerActions
>(initialState)
    .handleAction(
        actions.getExtensionVoicemailSettings.success,
        (state, action) => ({
            ...state,
            voicemailSettings: action.payload,
        }),
    )
    .handleAction(actions.getGreetingsFilesNames.success, (state, action) => ({
        ...state,
        greetingsFilesNames: action.payload,
    }));

export default voicemailReducer;

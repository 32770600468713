import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundImage } from '../../assets/not-found.svg';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomizedButton from '../../components/Button/Button';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SmokeBackground,
    },
    title: {
        fontSize: 32,
        fontWeight: 500,
        color: Colors.Text,
    },
    description: {
        fontWeight: 400,
        color: Colors.Gray5,
    },
    button: {
        marginTop: 30,
        marginBottom: 60,
        '& .MuiButton-label': {
            color: Colors.White,
            padding: '0 25px',
        },
    },
    link: {
        textDecoration: 'none !important',
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <NotFoundImage />
            <h2 className={classes.title}>
                {t('screens:notFound.pageNotFound')}
            </h2>
            <span className={classes.description}>
                {t('screens:notFound.pageNotExist')}
            </span>
            <Link to="/dashboard" className={classes.link}>
                <CustomizedButton className={classes.button} primary>
                    {t('screens:notFound.goToDashboard')}
                </CustomizedButton>
            </Link>
        </div>
    );
};

export default NotFound;

import {ApiFile} from "../../../../store/types/ApiFile";
import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../../styles/Colors";
import {TFunction} from "react-i18next";
import {GetCallProcessingPolicyListEntity} from "../../../../store/actions/extensions/payloads";
import {Column} from "react-table";
import StickyCell from "./StickyCell";
import {Permission} from "../../../../store/types/Permission";
import CustomizedIconButton from "../../../IconButton/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import React from "react";

export type ResponseMessagesTabProps = {
    onDelete: (mode: ApiFile) => void
};

export const useStyles = makeStyles(() => ({
    tabsContainer: {
        backgroundColor: Colors.White,
        border: 'none',
        width: 'calc(100% - 64px)',
        paddingLeft: 24,
        paddingRight: 32,
        overflowX: 'hidden',

        '& table': {
            width: 760,

            '& .MuiTableBody-root .MuiTableCell-root': {
                height: 57
            },
        }
    },
    temporaryIcon: {
        color: Colors.Error,
        border: `1px solid ${Colors.Error}`,
        padding: '3px 8px',
        marginLeft: 16
    },
    addApiFileButton: {
        marginTop: 16,
        marginLeft: 32,
        marginBottom: 22
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    policies: GetCallProcessingPolicyListEntity[],
    onEdit?: (object: ApiFile) => void,
    onDelete?: (object: ApiFile) => void,
): Column<ApiFile>[] => {

    const isFileInUse = (mode: ApiFile) => {
        if (!policies?.length) return false;
        for (const policy of policies) {
            if (!policy?.rule_list?.length) continue;
            for (const rule of policy.rule_list) {
                const messageId = rule.i_response_message || 0;
                if (messageId === mode.id) {
                    return true;
                }
            }
        }
        return false;
    };

    return [
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            width: 2,
            Cell: function Cell(params) {
                const name = params.row.original.name;
                const isTemporary = params.row.original.transitional === 'Y';
                return (
                    <StickyCell
                        text={name}
                        stickyText={t('screens:callSettings.temporary')}
                        isSticky={isTemporary}
                        customClasses={{
                            stickyText: classes.temporaryIcon
                        }}
                        onClick={() => {
                            onEdit?.(params.row.original);
                        }}
                        permissions={Permission.Calls.Settings.CallScreening.value}
                    />
                );
            },
        },
        {
            Header: '',
            accessor: 'id',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (
                    <>
                        <CustomizedIconButton
                            onClick={() => {
                                onEdit?.(original);
                            }}
                            dataTestId="edit-file-item-button"
                            dataQa="edit-file-item-button"
                            tooltipText={t<string>('common:edit')}
                        >
                            <Edit htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                        {!isFileInUse(original) && (
                            <CustomizedIconButton
                                onClick={() => {
                                    onDelete?.(original);
                                }}
                                dataTestId="remove-file-item-button"
                                dataQa="remove-file-item-button"
                            >
                                <Delete htmlColor={Colors.ListIconColor}/>
                            </CustomizedIconButton>
                        )}
                    </>);
            },
        }
    ];
}
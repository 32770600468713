import { GetCustomerDIDNumbersRequest } from '../actions/didNumbers/payloads';
import { api } from './axios';
import {
    AssignDIDToAccountRequest,
    CancelDIDAssignmentRequest,
} from '../types/CustomerDIDNumber';
import { DID } from '../../services/endpoints';
import JSONFormData from '../../utils/JSONFormData';

export const didNumberApi = {
    getCustomerNumbers: async (
        sessionId: string,
        csrf_token: string,
        params: GetCustomerDIDNumbersRequest,
    ) => {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams(params);

        return api.post(DID.GetCustomerNumbers, body);
    },

    assignNumberToAccount: async (
        sessionId: string,
        csrf_token: string,
        params: AssignDIDToAccountRequest,
    ) => {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams(params);
        return api.post(DID.AssignDIDToAccount, body);
    },

    cancelDIDAssignment: async (
        sessionId: string,
        csrf_token: string,
        params: CancelDIDAssignmentRequest,
    ) => {
        const body = new JSONFormData(sessionId, csrf_token);
        body.setParams(params);
        return api.post(DID.CancelDIDAssignment, body);
    },
};

export default didNumberApi;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import BarLoader from '../../components/BarLoader/BarLoader';

const useStyles = makeStyles(() => ({
    mainContainer: {
        padding: '11px 5px',
        display: 'flex',
        alignItems: 'center',
    },
    firstLine: {
        flex: 2,
        paddingRight: 17,
    },
    secondLine: {
        width: 150,
        paddingRight: 17,
    },
    thirdLine: {
        flex: 2.4,
    },
    separator: {
        borderBottom: `1px solid ${Colors.Border}`,
    },
}));

const ProductLoadingPlaceholderItem: React.VFC = () => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.firstLine}>
                <BarLoader />
            </div>
            <div className={classes.firstLine}>
                <BarLoader />
            </div>
            <div className={classes.secondLine}>
                <BarLoader />
            </div>
            <div className={classes.thirdLine} />
        </div>
    );
};

type ProductLoadingPlaceholderProps = {
    isVisible: boolean;
};

const ProductLoadingPlaceholder: React.VFC<ProductLoadingPlaceholderProps> = ({
    isVisible,
}) => {
    const classes = useStyles();

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <ProductLoadingPlaceholderItem />
            <div
                className={classes.separator}
                data-testid="products-loading-placeholder"
                data-qa="products-loading-placeholder"
            />
            <ProductLoadingPlaceholderItem />
        </>
    );
};

export default ProductLoadingPlaceholder;

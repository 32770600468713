import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { MohInfo } from '../../../types/AccountMoh';

export interface MohsReducerState {
    mohDetails?: {
        isEnabled: boolean;
        items: MohInfo[];
        selectedValueId: number;
    };
    isLoading?: boolean;
}

export const initialState: MohsReducerState = {};

export type MohsReducerActions = ActionType<typeof actions>;

export const mohsReducer = createReducer<MohsReducerState, MohsReducerActions>(
    initialState,
)
    .handleAction(actions.getExtensionMohDetails.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getExtensionMohDetails.success, (state, action) => ({
        ...state,
        mohDetails: {
            ...state.mohDetails,
            ...action.payload,
        },
        isLoading: false,
    }));

export default mohsReducer;

export type ServiceFeature = {
    name: ServiceFeatureName;
    attributes: ServiceFeatureAtributes[];
    effective_flag_value: string;
    flag_value: string;
    locked?: number;
};

export type ServiceFeatureAtributes = {
    name: string;
    values: any[];
    effective_values: any[];
};

export enum ServiceFeatureAtributesName {
    PBX = 'pbx_management',
}

export enum ServiceFeatureName {
    DefaultAction = 'default_action',
    ForwardMode = 'forward_mode',
    Cli = 'cli',
    MusicOnHold = 'music_on_hold',
    CallProcessing = 'call_processing',
    CallBarring = 'call_barring',
    CallWaiting = 'call_wait_limit',
    AutoAttendant = 'auto_attendant',
    CallRecording = 'call_recording',
    UnifiedMessaging = 'unified_messaging',
    ConfEnabled = 'conf_enabled',
    DistinctiveRingVPN = 'distinctive_ring_vpn',
    CallParking = 'call_parking',
    GroupPicking = 'group_pickup',
    Paging = 'paging',
    VoiceDialing = 'voice_dialing',
    CallSupervision = 'call_supervision',
    VoiceAuthentication = 'voice_authentication',
    VoiceFup = 'voice_fup',
    SipStaticContact = 'sip_static_contact',
    LineSharing = 'line_sharing'
}

export interface ScreeningModeFeature extends ServiceFeature {
    name: ServiceFeatureName.CallProcessing;
    effective_flag_value: ScreeningModeFlag;
}

export enum ScreeningModeFlag {
    DefinedByProduct = '~',
    NoForwarding = 'N',
    FollowMe = 'Y',
}

export interface DefaultActionFeature extends ServiceFeature {
    name: ServiceFeatureName.DefaultAction;
    effective_flag_value: DefaultAnsweringActionFlag;
}

export enum DefaultAnsweringActionFlag {
    RingForwardVoicemail = '7',
    RingThenForward = '3',
    RingThenVoicemail = '5',
    ForwardThenVoicemail = '6',
    RingOnly = '1',
    ForwardOnly = '2',
    VoicemailOnly = '4',
    Reject = '0',
}

export interface ForwardModeFeature extends ServiceFeature {
    name: ServiceFeatureName.ForwardMode;
    effective_flag_value: ForwardModeFlag;
}

export enum ForwardModeFlag {
    DefinedByProduct = '~',
    NoForwarding = 'N',
    FollowMe = 'Y',
    AdvancedForwarding = 'F',
    ForwardToSIPUri = 'U',
    SimpleForwarding = 'C',
}

export interface MusicOnHoldFeature extends ServiceFeature {
    name: ServiceFeatureName.ForwardMode;
    flag_value: MusicOnHoldFlag;
}

export enum MusicOnHoldFlag {
    Enabled = 'Y',
    Disabled = 'N',
}

export interface CallBarringFeature extends ServiceFeature {
    name: ServiceFeatureName.CallBarring;
    effective_flag_value: 'Y' | 'N';
}

export enum CallWaitingFlag {
    Enabled = 'Y',
    Disabled = 'N',
}

export interface CallWaitingFeature extends ServiceFeature {
    name: ServiceFeatureName.CallWaiting;
    effective_flag_value: CallWaitingFlag;
}

import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FiltersWrapper from '../../../components/FiltersWrapper/FiltersWrapper';
import SelectField from '../../../components/SelectField/SelectField';
import CustomKeyboardDateTimePicker from '../../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import { useFormik } from 'formik';
import {
    InvoiceFiltersProps,
    InvoiceFiltersType,
    statusOptions,
    useStyles,
} from './InvoiceFilters.utils';
import NumericTextField from '../../../components/NumericTextField/NumericTextField';

const InvoiceFilters: React.VFC<InvoiceFiltersProps> = ({
    initData,
    userDateTimeFormat,
    onClick,
    onChange,
}): ReactElement => {
    const { t } = useTranslation();
    const clasess = useStyles();

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
    } = useFormik<InvoiceFiltersType>({
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: () => {
            onClick?.();
        },
    });

    useEffect(() => {
        onChange?.(values);
    }, [values]);

    const fields = [
        <NumericTextField
            id={'invoiceNumber'}
            label={t('screens:billing.invoiceNumber')}
            className={clasess.numberFilter}
            value={values.invoiceNumber}
            onChange={handleChange}
            dataQa={'filter-by-invoice-number'}
            key="filter-1"
            skipPermission
        />,
        <SelectField
            id={'statusFilter'}
            label={t('common:status')}
            className={clasess.statusFilter}
            classes={{
                container: clasess.statusContainer,
                option: clasess.option,
            }}
            getOptionLabel={(o) => o.name}
            items={statusOptions}
            dataQa={'filter-by-status'}
            onChange={(e, value) => {
                setFieldValue('statusFilter', value);
            }}
            value={
                !!values.statusFilter ? values.statusFilter : statusOptions[0]
            }
            disableClearable
            key="filter-2"
            skipPermission
        />,
        <CustomKeyboardDateTimePicker
            label={t('screens:billing.afterFilter')}
            className={clasess.dateTimeFilter}
            id={'afterFilter'}
            value={values.afterFilter}
            onChange={(v) => setFieldValue('afterFilter', v)}
            dataQa={'filter-by-after'}
            dataTestId={'filter-by-after'}
            userDateTimeFormat={userDateTimeFormat}
            key="filter-3"
            allowEmptyDate
            skipPermission
        />,
        <CustomKeyboardDateTimePicker
            label={t('screens:billing.beforeFilter')}
            className={clasess.dateTimeFilter}
            id={'beforeFilter'}
            value={values.beforeFilter}
            onChange={(v) => setFieldValue('beforeFilter', v)}
            dataQa={'filter-by-before'}
            dataTestId={'filter-by-before'}
            userDateTimeFormat={userDateTimeFormat}
            key="filter-4"
            allowEmptyDate
            skipPermission
        />,
        <CustomKeyboardDateTimePicker
            label={t('screens:billing.coveringFilter')}
            className={clasess.dateTimeFilter}
            id={'coveringFilter'}
            value={values.coveringFilter}
            onChange={(v) => setFieldValue('coveringFilter', v)}
            dataQa={'filter-by-covering'}
            dataTestId={'filter-by-covering'}
            userDateTimeFormat={userDateTimeFormat}
            key="filter-5"
            allowEmptyDate
            skipPermission
        />,
    ];

    return (
        <FiltersWrapper
            fields={fields}
            onSubmit={handleSubmit}
            dataQa={'invoice-filters'}
        />
    );
};
export default InvoiceFilters;

import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import {
    CustomerBillingType,
    GlobalCurrencyInfo,
} from '../../../../store/types/CustomerInfo';
import {
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import NumericTextField from '../../../NumericTextField/NumericTextField';
import { MakePaymentFormType } from '../MakePaymentDialog/MakePaymentDialog.utils';
import { useStyles } from './PaymentAmountForm.utils';
import classNames from 'classnames';
import PaymentTaxes from './PaymentTaxes';

const PaymentAmountForm = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const currentMethod = useMemo(
        () =>
            ownerPaymentsMethods?.find(
                (v) => v.i_payment_method === paymentMethod?.i_payment_method,
            ),
        [ownerPaymentsMethods],
    );

    const { customerInfo } = useSelector((state: ReduxState) => state.billing);

    const balance =
        customerInfo?.i_balance_control_type === CustomerBillingType.Postpaid
            ? customerInfo?.balance
            : -1 * (customerInfo?.balance || 0);

    const {
        setFieldError,
        setFieldValue,
        errors,
        handleChange,
        values,
    } = useFormikContext<MakePaymentFormType>();

    const taxesVisible =
        customerInfo?.i_balance_control_type === 2 &&
        customerInfo?.estimate_taxes === 1 &&
        customerInfo?.inclusive_taxation === 'N';

    return (
        <div className={classes.mainContainer}>
            <div className={classes.amountContainer}>
                <NumericTextField
                    id="amount"
                    label={`${t('screens:billing.amount')}, ${
                        currencyInfo?.iso_4217
                    }`}
                    required
                    value={values.amount}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    className={classes.amountInput}
                    thousandSeparator=","
                    decimalScale={2}
                    min={currentMethod?.min_allowed_payment}
                    max={9999999999.99}
                    helperText={errors.amount}
                    arrows
                />

                <span className={classes.headerText}>{`${t(
                    'screens:billing.currentBalance',
                )}, ${currencyInfo?.iso_4217}`}</span>

                <span
                    className={classNames(
                        classes.valueText,
                        classes.balanceValueText,
                    )}
                >
                    {balance?.toFixed(2)}
                </span>
            </div>

            {taxesVisible && <PaymentTaxes taxesVisible/>}
        </div>
    );
};

export default PaymentAmountForm;

import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import React, {useEffect} from 'react';
import PlanForm from '../../../components/Forms/Extensions/PlanForm';
import {useExtensionPlanFormData} from '../../../hooks/useExtensionPlanFormData';
import {useTabStyles} from './utils';
import {MainProductType} from '../../../components/Extensions/MainProductSelectFiled/utils';
import {Formik} from 'formik';
import {
    extensionPlanFormDefaultValues,
    ExtensionPlanFormType,
    extensionPlanFormValidationSchema,
} from '../../../components/Forms/Extensions/PlanForm.utils';
import {TabInterface} from "../../../components/Tabs/Tabs.utils";

interface PlanTabInterface extends TabInterface {
    productType: MainProductType;
}

const PlanTab: React.VFC<PlanTabInterface> = (
    {
        handleSubmitForm,
        productType,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        isActive,
        tabName
    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.planTab?.isLoading || false,
    );

    const {data, initFormData} = useExtensionPlanFormData();

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="extension-list-loader"/>
        </div>
    ) : (
        <Formik<ExtensionPlanFormType>
            initialValues={initFormData ?? extensionPlanFormDefaultValues}
            enableReinitialize={true}
            validationSchema={extensionPlanFormValidationSchema}
            validateOnChange={false}
            onSubmit={handleSubmitForm!}
        >{() => {

            return (
                <>
                    <PlanForm
                        i_account={data.i_account}
                        productType={productType}
                        handleDirtyChange={handleDirtyChange}
                        handleSetSubmitFunc={handleSetSubmitFunc}
                        handleSetIsValidFunc={handleSetIsValidFunc}
                    />
                </>
            )
        }

        }

        </Formik>
    );
};

export default PlanTab;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { actionsFormDefaultValues } from '../components/Forms/AutoAttendantsMenus/ActionsForm.utils';
import { promptsFormDefaultValues } from '../components/Forms/AutoAttendantsMenus/PromptForm.utils';
import { settingsFormDefaultValues } from '../components/Forms/AutoAttendantsMenus/SettingsForm.utils';
import { ReduxState } from '../store/types';
import { MenuAction, UserInput } from '../store/types/AutoAttendant';
import { parsePeriodString, periodStringToArray } from '../utils/extensions/CallScreeningTimeWindowToPeriod';
import { EditAutoAttendantsMenuForm } from '../views/AutoAttendants/AutoAttendantsMenuDetails';
import {intervalFormInitialValues, IntervalStatus} from "../components/IntervalSelect/IntervalSelect.utils";
import {Interval} from "../store/types/RingGroup";
import { v4 as uuidv4 } from 'uuid';

export const defaultValues = {
    ...settingsFormDefaultValues,
    intervals: intervalFormInitialValues,
    ...promptsFormDefaultValues,
    ...actionsFormDefaultValues,
};

export const useEditAutoAttendantsMenuFormData = () => {
    const { autoAttendantInfo, autoAttendantMenus } = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    const { callQueues } = useSelector((state: ReduxState) => state.callQueues);

    const { menuDetails, menuTransitions } = useSelector(
        (state: ReduxState) => state.menus,
    );

    const initialValues = useMemo((): EditAutoAttendantsMenuForm => {
        if (
            !autoAttendantInfo?.account_info ||
            !menuDetails ||
            !menuTransitions
        ) {
            return defaultValues;
        }

        const intervals = periodStringToArray({
            period: menuDetails.period,
            description: menuDetails.period_desc,
        });

        const nonActiveTransition = menuTransitions.find(
            (v) => v.event === 'Not Active',
        );

        const mappedIntervals: Interval[] = [];
        if(intervals) {
            for(const i of intervals) {
                const parsed = parsePeriodString(i.intervals);
                for(const p of parsed) {
                    p.id = uuidv4();
                    mappedIntervals.push(p);
                }
            }
        }

        return {
            menuId: menuDetails.i_menu,
            name: menuDetails.name,
            inactiveMenuAction: intervals[0].description === 'Always' ?
                MenuAction.DoNothing : nonActiveTransition?.action || MenuAction.DoNothing,
            playBeforeAction: nonActiveTransition?.play_prompt === 'Y',
            recordBeforeActionName: nonActiveTransition?.prompt_set
                ? 'prompt.au'
                : '',
            recordBeforeActionFile: null,
            allowCallersToDialKnownNumber:
                menuDetails.direct_dial_enabled === 'Y',
            callersToDialKnownNumberTimeout: menuDetails.next_digit_timeout,
            errorsCount: menuDetails.replay_menu_times || 0,
            intervals: {
                activity:
                    intervals[0].description === 'Always'
                        ? IntervalStatus.Always
                        : IntervalStatus.OnlyFollowingTimeInterval,
                    intervals: intervals[0].description === 'Always' ? [] : mappedIntervals
            },
            menu: autoAttendantMenus?.find(
                (v) => v.i_menu === nonActiveTransition?.target_i_menu,
            ),
            queue: callQueues?.find(
                (v) => v.i_c_queue === nonActiveTransition?.target_i_queue,
            ),
            transferDestination: nonActiveTransition?.destination || '',
            maxDigits: nonActiveTransition?.max_size?.toString() || '4',
            introPromptStatus: menuDetails.msg_intro_type === 'custom',
            introPromptFileName:
                menuDetails.msg_intro_set === 1 ? 'intro.au' : '',
            introPromptFile: null,
            menuPromptStatus: menuDetails.msg_menu_type === 'custom',
            menuPromptFileName: menuDetails.msg_menu_set === 1 ? 'menu.au' : '',
            menuPromptFile: null,
            onTimeoutPromptStatus: menuDetails.msg_timeout_type === 'custom',
            onTimeoutPromptFileName:
                menuDetails.msg_timeout_set === 1 ? 'timeout.au' : '',
            onTimeoutPromptFile: null,
            onUnavailableSelectionPromptStatus:
                menuDetails.msg_disabled_type === 'custom',
            onUnavailableSelectionPromptFileName:
                menuDetails.msg_disabled_set === 1 ? 'disabled.au' : '',
            onUnavailableSelectionPromptFile: null,
            actions: menuTransitions
                .filter((v) => v.event !== UserInput.NotActive)
                .map((v) => ({
                    userInput: v.event,
                    action: v.action,
                    timeout: menuDetails.first_digit_timeout?.toString() || '0',
                    directoryIntroPromptStatus: v.play_prompt === 'Y',
                    directoryInputPromptFileName:
                        v.prompt_set === 1 ? 'prompt.au' : '',
                    announceExtensionNumbers: v.announce_ext_numbers === 'Y',
                    playBeforeActionStatus: v.play_prompt === 'Y',
                    playBeforeActionFileName:
                        v.prompt_set === 1 ? 'prompt.au' : '',
                    transferCallerToPhoneNumberExtension: v.destination || '',
                    maxDigits: v.max_size?.toString() || '1',
                    queue: callQueues?.find(
                        (w) => w.i_c_queue === v.target_i_queue,
                    ),
                    menu: autoAttendantMenus?.find(
                        (w) => w.i_menu === v.target_i_menu,
                    ),
                    transitionId: v.i_menu_transition
                })),
            deleteEnabled: menuDetails?.deleteEnabled
        };
    }, [autoAttendantInfo, menuDetails, callQueues, menuTransitions]);

    return {
        initialValues,
        data: {
            autoAttendantInfo,
            menuDetails,
        },
    };
};

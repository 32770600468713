import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { OperationMode } from '../../store/actions/extensions/payloads';
import { CallProcessingModeNameMapping } from '../Calls/ModeCell';
import { YesNo } from '../../store/types/CallScreening';
import { convertUserLocalTimeToUtc } from '../../utils/dateWithTimezoneConversion';

export const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            width: 640,
            minHeight: 500,
        },
    },
    content: {
        backgroundColor: Colors.SmokeBackground,
    },
    form: {
        padding: '10px 54px',
        '& > div:last-child': {
            marginBottom: 0,
        },
    },

    box: {
        display: 'flex',
        marginBottom: 24,
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
}));

export interface SwitchModeFormInterface {
    i_c_group?: number;
    mode?: OperationMode;
    file_id?: number;
    i_customer?: number;
    effective_to?: string;
    i_response_message?: number | null;
}

export const isSelectModeAutoValueDisabled = (modeList?: OperationMode[]) => {
    let anyEnabled = false;

    modeList?.forEach((o) => {
        if (o.sticky == YesNo.No) {
            anyEnabled = true;
        }
    });

    return !anyEnabled;
};

export const resetSecFromUtcTime = (time: string, offset: number) => {
    return convertUserLocalTimeToUtc(time, offset).slice(0, -2) + '00';
};

export const isDefaultModeSelected = (
    modeList?: OperationMode[],
    shortCode?: number,
) => {
    return !!modeList?.find(
        (o) =>
            o.short_code == shortCode &&
            o.name == CallProcessingModeNameMapping.Normal,
    );
};

export const removeSecondsFromDateTimeFormat = (format: string) =>
    format.replace(/:ss/gi, '');

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    content: {
        padding: '10px 60px 20px 60px !important',
        height: 620,
        width: 890,
        backgroundColor: Colors.SmokeBackground,
    },
    tabs: {
        '& .MuiAppBar-root': {
            padding: 0,
            backgroundColor: 'transparent',
        },

        '& .MuiBox-root': {
            padding: 0,
        },
        '& #wrapped-tabpanel-0': {
            margin: '0 -15px',
        },
        '& #wrapped-tabpanel-1': {
            margin: '0 -15px',
        },
    },
    disabledButton: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
}));

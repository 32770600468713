import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '19px 24px 0 24px',
        display: 'flex',
        flexDirection: 'column',
    },
    sectionMargin: {
        marginTop: 16,
    },

    primaryActionButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    saveButtonDisabled: {
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}!important`,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 520,
            minHeight: 520,
            padding: '28px 60px',
            background: Colors.SmokeBackground,
        },
    },
    warningModal: {
        '& .MuiDialogContent-root': {
            minHeight: 40,
            padding: 23,
            width: 594,
            display: 'flex',
            alignItems: 'center',
        },
    },
    warningWrapper: {
        '& svg': {
            height: 20,
            width: 22,
            '& path': {
                fill: Colors.Gray5,
            },
        },
    },
    otherErrorLabel: {
        fontSize: 16,
        lineHeight: '19px',
        fontWeight: 400,
        marginLeft: 18,
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    option: {
        height: '48px !important',
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    fieldMargin: {
        marginBottom: 24,
    },
    didNumber: {
        marginTop: 12,
    },
    didNumbers: {
        marginTop: 12,
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: 25,
            height: 'auto',
            '& .MuiChip-root': {
                height: '22px',
                backgroundColor: Colors.LightGraySecondary4,
                color: Colors.Gray9,
                '& .MuiSvgIcon-root': {
                    height: 16,
                },
            },
        },
        '& .MuiInput-input': {
            padding: '0 !important',
        },
        '& .MuiInputBase-root': {
            paddingBottom: 5,
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'unset',
            bottom: 14,
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        margin: '3px 0',
    },
    daysMonthsSelect: {
        display: 'flex',
        maxWidth: 197,

        '& .MuiInputBase-root': {
            paddingBottom: 5,
            height: 'auto',
            paddingLeft: 10,
        },
    },
    selectContainer: {
        minWidth: 'unset',
        flex: 1,

        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
            padding: '0!important',
            flexGrow: 'unset',
            minWidth: 'unset',
        },
    },
    timeRangeContainer: {
        maxWidth: 215,
        marginRight: 10,
    },
    menusSection: {
        marginTop: 25,
    },
    afterHoursSection: {
        marginTop: 5,
    },
    rangesSection: {
        marginBottom: 15,
        alignItems: 'flex-start',
    },
}));

import React from 'react';
import { Colors } from '../../styles/Colors';
export const EMPTY_ROW_CHARS = '—';

const EmptyRowContent = () => {
    return (
        <span
            style={{ color: Colors.Gray3 }}
            data-qa={'empty-row'}
            data-testid={'empty-row'}
        >
            {EMPTY_ROW_CHARS}
        </span>
    );
};

export default EmptyRowContent;

import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import {
    useStyles,
    MusicRingingFormProps,
    MusicRingingFormType,
} from './utils';
import OnHoldMusic from '../../../OnHoldMusic/OnHoldMusic';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { ReduxState } from '../../../../store/types';

const MusicRingingForm: React.FC<MusicRingingFormProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { mohDetails } = useSelector(
        (state: ReduxState) => state.callSettings,
    );

    const { values, setFieldValue } = useFormikContext<MusicRingingFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    return (
        <>
            <OnHoldMusic
                setValue={setValue}
                value={values.onHoldMusicSwitcher}
                withTooltip={false}
                mohItems={mohDetails?.items}
                selectedMohFile={values.selectedMohFile}
                classes={{ selectContainer: classes.selectedMohContainer, switcher: classes.switcherContainer }}
            />
            <div className={classes.inputs}>
                <Grid item className={classes.itemsContainer}>
                    <Box className={classes.rowBox}>
                        <SwitchWithLabel
                            label={t('screens:callSettings.extToExt')}
                            setValue={setValue}
                            value={values.extToExtSwitcher}
                            field={'extToExtSwitcher'}
                            id={'ext-to-ext-switcher-switch'}
                            className={classes.switcher}
                        />
                        <IconWithTooltip
                            dataQa="ext-to-ext-tooltip"
                            tooltipText={t('tooltips:callSettings.extToExt')}
                            copy={false}
                        />
                    </Box>
                </Grid>
            </div>
        </>
    );
};

export default MusicRingingForm;

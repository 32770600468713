import React from "react";
import {makeStyles} from "@material-ui/core/styles";

interface ExtendedMessageProps {
    title: string,
    messages: string[]
}

const useStyles = makeStyles(() => (
    {
        list: {
            marginTop: 0,
            paddingLeft: 22,
            '& li:first-letter': {
                textTransform: "capitalize"
            }
        }
    }
))

const ExtendedMessage: React.FC<ExtendedMessageProps> = ({title, messages}) => {
    const singleRender = messages.map((error, index) => <li key={index}>{error}</li>)
    const classes = useStyles();
    const colon = messages.length ? ':' : '';

    return (
        <>
            <div>{title}{colon}</div>
            {messages.length && (
                <ul className={classes.list}>
                    {singleRender}
                </ul>
            )}
        </>
    )
}

export default ExtendedMessage
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { actions } from '../../store';
import { useTranslation } from 'react-i18next';
import { convertUserLocalTimeToUtc } from '../../utils/dateWithTimezoneConversion';
import { CallHistory } from '../../store/types/CallHistory';
import CustomKeyboardDateTimePicker from '../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { useFormik } from 'formik';
import dayjs from '../../services/customDayJs';
import CallRecordInformationDialog from '../../components/Calls/CallRecordInformationDialog';
import CustomizedDialog from '../../components/Dialog/Dialog';
import CallRemoveBottomBox from '../../components/Calls/CallRemoveBottomBox';
import { prepareCallRecordName } from '../../utils/calls/prepareCallRecordName';
import DataGrid from '../../components/DataGrid/DataGrid';
import { PaginationMode } from '../../components/DataGrid/types';
import Header from '../../components/ListViewHeader/Header';
import {
    CallRecordingsFormProps,
    dateFormat,
    useStyles,
    generateColumns,
} from './utils';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission} from "../../store/types/Permission";
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import {dateFromDateTillValidationSchema} from "../../components/Extensions/CallHistoryDialog.utils";

export const CallRecordingsList: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    usePageTitle();

    const [itemsToRemove, setItemsToRemove] = useState<CallHistory[]>([]);
    const [selectedItems, setSelectedItems] = useState<CallHistory[]>([]);
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );
    const dispatch = useDispatch();

    const [callRecordInformation, setCallRecordInformation] = useState<
        CallHistory | undefined
    >(undefined);
    const [
        callRecordInformationInList,
        setCallRecordInformationInList,
    ] = useState<CallHistory | undefined>(undefined);

    const { items, total, allTotal, isRemovingGoingOn } = useSelector<
        ReduxState,
        {
            items: CallHistory[];
            total: number;
            allTotal: number;
            isRemovingGoingOn?: boolean;
        }
    >((state) => state.calls.callHistory);

    const customerCurrency = useSelector<ReduxState, string>(
        (state) => state.calls.customerCurrency || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.callHistory.isLoading,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const initialValues = useMemo(
        () => ({
            from: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .subtract(1, 'month')
                .format(dateFormat),
            till: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
            cli: '',
            cld: '',
            unsuccessfulCalls: false,
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        setFieldError,
    } = useFormik<CallRecordingsFormProps>({
        initialValues,
        onSubmit: (form) => {
            getCallHistory(form, false);
        },
        validationSchema: dateFromDateTillValidationSchema(timezoneOffset,userDateTimeFormat),
        enableReinitialize: true,
        validateOnChange: false,
    });

    useEffect(() => {
        getCallHistory(values, true);
    }, []);

    const getCallHistory = (
        formData: CallRecordingsFormProps,
        withInitCalls?: boolean,
        limit?: number,
        offset?: number,
    ) => {
        dispatch(
            actions.getCallHistory.request({
                limit: limit || 10,
                offset: offset || 0,
                ...formData,
                cli: formData.cli ? `${formData.cli}%` : undefined,
                cld: formData.cld ? `${formData.cld}%` : undefined,
                from: convertUserLocalTimeToUtc(formData.from, timezoneOffset),
                till: convertUserLocalTimeToUtc(formData.till, timezoneOffset),
                withInitCalls,
                callRecordingsOnly: true,
            }),
        );
    };

    const toogleTooltipVisibility = (index: number) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = false;
        setIsDownloadingGoingOn(newDownloadings);
    };

    const downloadFile = (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = true;
        setIsDownloadingGoingOn(newDownloadings);
        toast(t<string>('tooltips:ringGroups.downloadWillStartShortly'));
        dispatch(
            actions.getExtensionCallHistoryFile.request({
                i_xdr,
                call_recording_id,
                fileName,
                callback: () => toogleTooltipVisibility(index),
            }),
        );
    };

    const removeItems = (items: CallHistory[]) => {
        dispatch(
            actions.removeCallRecordings.request({
                items,
                getDataRequestPayload: {
                    limit: 10,
                    offset: 0,
                    ...values,
                    cli: values.cli ? `${values.cli}%` : undefined,
                    cld: values.cld ? `${values.cld}%` : undefined,
                    from: convertUserLocalTimeToUtc(
                        values.from,
                        timezoneOffset,
                    ),
                    till: convertUserLocalTimeToUtc(
                        values.till,
                        timezoneOffset,
                    ),
                    callRecordingsOnly: true,
                },
            }),
        );
        setItemsToRemove([]);
        setCallRecordInformation(undefined);
    };

    const toggleCallRecordInformationInList = (item: CallHistory) =>
        setCallRecordInformationInList(item);

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                timezoneOffset,
                downloadFile,
                setCallRecordInformation,
                (items) => {
                    setItemsToRemove(items);
                },
                userDateTimeFormat,
                isDownloadingGoingOn,
                !!callRecordInformation,
                toggleCallRecordInformationInList,
            ),
        [userDateTimeFormat, isDownloadingGoingOn],
    );

    const onChangeItemsSelection = useCallback(
        (items) => setSelectedItems(items as CallHistory[]),
        [],
    );
    const handleSubmitRemove = () => {
        removeItems(itemsToRemove);
    };

    return (
        <PermissionPlaceholder permission={Permission.Calls.Recordings.value}>
            <PermissionProvider permission={Permission.Calls.Recordings.value}>
                <div className={classes.mainContainer}>
                    <div className={classes.scrollable}>
                        <div>
                            <Header
                                title={t('screens:calls.callRecordings')}
                                totalNumber={allTotal || 0}
                                dataQa={'device-list-view-header'}
                                showDetails={false}
                            />
                            <form
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                data-testid="call-history-form"
                            >
                                <Grid item className={classes.filtersContainer}>
                                    <Grid item className={classes.itemsContainer}>
                                        <Grid item className={classes.inputsContainer}>
                                            <CustomKeyboardDateTimePicker
                                                id="from"
                                                label={t('common:fromDate')}
                                                value={values.from}
                                                onChange={(v) =>
                                                    setFieldValue('from', v)
                                                }
                                                dataQa={'call-history-filter-from'}
                                                dataTestId={'call-history-filter-from'}
                                                userDateTimeFormat={userDateTimeFormat}
                                                handleSubmit={handleSubmit}
                                                helperText={errors.from}
                                                setFieldError={setFieldError}
                                                skipPermission
                                            />
                                            <CustomKeyboardDateTimePicker
                                                id="till"
                                                label={t('common:toDate')}
                                                value={values.till}
                                                onChange={(v) =>
                                                    setFieldValue('till', v)
                                                }
                                                dataQa={'call-history-filter-till'}
                                                dataTestId={'call-history-filter-till'}
                                                userDateTimeFormat={userDateTimeFormat}
                                                handleSubmit={handleSubmit}
                                                helperText={errors.till}
                                                setFieldError={setFieldError}
                                                skipPermission
                                            />
                                            <TextField
                                                id="cli"
                                                label={t('screens:calls.caller')}
                                                onChange={handleChange}
                                                value={values.cli}
                                                dataQa={
                                                    'call-history-filter-calling-number'
                                                }
                                                handleSubmit={handleSubmit}
                                                className={classes.cropLabelTextInFilter}
                                                skipPermission
                                            />
                                            <TextField
                                                id="cld"
                                                label={t('screens:calls.destination')}
                                                onChange={handleChange}
                                                value={values.cld}
                                                dataQa={
                                                    'call-history-filter-called-number'
                                                }
                                                handleSubmit={handleSubmit}
                                                className={classes.cropLabelTextInFilter}
                                                skipPermission
                                            />
                                        </Grid>
                                        <Button
                                            primary
                                            accent
                                            dataQa="call-history-search"
                                            className={classes.button}
                                            onClick={() => handleSubmit()}
                                            skipPermission
                                        >
                                            {t('common:search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>

                            <DataGrid<CallHistory>
                                columns={columns}
                                data={items}
                                rowCount={total}
                                loading={isLoading}
                                onPageChange={(v) =>
                                    getCallHistory(
                                        values,
                                        false,
                                        v.pageSize,
                                        v.pageSize * v.page,
                                    )
                                }
                                onPageSizeChange={(v) =>
                                    getCallHistory(values, false, v.pageSize, 0)
                                }
                                paginationMode={PaginationMode.Server}
                                centeredRows
                                narrowRows
                                multiSelectEnabled
                                onChangeItemsSelection={onChangeItemsSelection}
                                initialPageSize={10}
                                getItemId={(v) => v.i_xdr!}
                                classes={{
                                    tableContainer: classes.table,
                                }}
                            />

                            <CallRecordInformationDialog
                                isOpen={!!callRecordInformation}
                                toggleVisibility={() =>
                                    setCallRecordInformation(undefined)
                                }
                                callHistory={callRecordInformation}
                                customerCurrency={customerCurrency}
                                timezoneOffset={timezoneOffset}
                                onDownloadClick={() =>
                                    callRecordInformation?.i_xdr &&
                                    downloadFile(
                                        callRecordInformation.i_xdr,
                                        callRecordInformation.cr_download_ids?.[0] ||
                                        '',
                                        prepareCallRecordName(
                                            callRecordInformation,
                                            timezoneOffset,
                                        ),
                                        0,
                                    )
                                }
                                onDeleteClick={() => {
                                    callRecordInformation?.i_xdr &&
                                    setItemsToRemove([callRecordInformation]);
                                }}
                                isDownloadingGoingOn={isDownloadingGoingOn}
                                userDateTimeFormat={userDateTimeFormat}
                                deletePermission={Permission.Calls.Recordings.CallDetailRecord.DeleteCallRecording.value}
                                downloadPermission={Permission.Calls.Recordings.CallDetailRecord.DownloadCallRecording.value}
                            />

                            <CustomizedDialog
                                isOpen={!!itemsToRemove.length || !!isRemovingGoingOn}
                                handleClose={() => setItemsToRemove([])}
                                handleSubmit={handleSubmitRemove}
                                customClasses={{
                                    container: classNames(
                                        itemsToRemove.length === 1 &&
                                        classes.containerDelete,
                                    ),
                                }}
                                content={
                                    !!itemsToRemove.length && (
                                        <span className={classes.deleteConfirmation}>
                                    {itemsToRemove.length === 1
                                        ? `${t(
                                            'screens:calls.deleteCallHistory',
                                            {
                                                name: prepareCallRecordName(
                                                    itemsToRemove[0],
                                                    timezoneOffset,
                                                ),
                                            },
                                        )}`
                                        : t(
                                            'screens:calls.deleteSelectedRecordsQuestion',
                                            {
                                                value: itemsToRemove.length,
                                            },
                                        )}
                                </span>
                                    )
                                }
                                closeButtonText={t('common:cancel')}
                                submitButtonText={t('common:delete')}
                                dataQa="remove-call-records-dialog"
                                isLoading={isRemovingGoingOn}
                                loadingMessage={t('screens:calls.deletingRecord')}
                            />
                        </div>

                        <CallRemoveBottomBox
                            numberOfItems={selectedItems.length}
                            onDelete={() => setItemsToRemove([...selectedItems])}
                            customClasses={{
                                buttonsContainer: classes.buttonsContainer,
                            }}
                        />
                    </div>
                </div>
            </PermissionProvider>

        </PermissionPlaceholder>

    );
};

import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: 'fit-content',
        verticalAlign: 'middle',
        margin: 8,
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    textContainer: {
        width: 'fit-content',
        height: 'fit-content',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 16,
        cursor: 'pointer'
    },
    colorMark: {
        width: 15,
        height: 15,
        borderRadius: 4,
        marginTop: 3,
        marginLeft: 8
    }
}));

interface ColorMarkCellProps {
    className?: string;
    textClassName?: string;
    text: string;
    color: string;
    onClick?: () => void;
}

const ColorMarkCell: React.VFC<ColorMarkCellProps> = ({
    className,
    textClassName,
    text,
    color,
    onClick
}) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.colorMark} style={{backgroundColor: color}} data-testid={'color-data-id'}>
            </div>
            <div className={classNames(classes.textContainer, textClassName)} data-testid={'text-data-id'}
                onMouseDown={onClick}>
                {text}
            </div>
        </div>
    );
};

export default ColorMarkCell;

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import TextField from '../TextField/TextField';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import Loader from '../Loader/Loader';
import { APIErrorInterface, ReduxState } from '../../store/types';
import AssignDevice from './AssignDevice';
import { UAInfo, UAPortConfiguration } from '../../store/types/Devices';
import MainProductSelectField from './MainProductSelectFiled/MainProductSelectField';
import { MainProductType } from './MainProductSelectFiled/utils';
import { DialogInterface } from './types/dialog';
import CreateNewExtensionErrorDialog from './CreateNewExtensionErrorDialog';
import { CreateExtensionFaultCode } from '../../store/types/Extension';

const requiredFieldError = i18n.t('errors:common.emptyInput');
const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');
const nameMaxLengthError = i18n.t('errors:extensions.max32Length');

const CreateNewExtensionValidationSchema = Yup.object().shape({
    extensionNumber: Yup.string()
        .max(5, numberMaxLengthError)
        .matches(/^[0-9]*$/, numberDigitsError)
        .required(requiredFieldError),
    extensionName: Yup.string().max(32, nameMaxLengthError).notRequired(),
    mainProductId: Yup.number().required(requiredFieldError),
    device: Yup.object().nullable().notRequired(),
    port: Yup.object().when('device', {
        is: (value: unknown) => !!value,
        then: Yup.object().nullable().required(requiredFieldError),
    }),
});

interface CreateNewExtensionFormData {
    extensionNumber: string;
    extensionName?: string;
    mainProductId?: number;
    device?: UAInfo | null;
    port?: UAPortConfiguration | null;
}

const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '18px 24px 18px 24px',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            marginBottom: 24,
        },
    },

    itemsContainerExpander: {
        padding: '0px 24px 0px 6px',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            marginBottom: 24,
        },

        '& .MuiGrid-root': {
            marginLeft: 18,
            paddingTop: 16,

            '& > div:last-child .MuiFormControl-root ': {
                marginBottom: '4px',
            },
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 520,
            padding: '28px 60px',
            backgroundColor: Colors.SmokeBackground,
        },
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    tooltipIcon: {
        marginLeft: 10,
    },

    containerTitle: {
        lineHeight: '28px',
        fontWeight: 700,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        padding: '0px 24px',
    },
}));

const CreateNewExtensionDialog: React.VFC<DialogInterface> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fetchData, setFetchData] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const isDataFetching = useSelector<ReduxState, boolean>(
        (state) => !!state.extensions.createExtensionDataIsLoading,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => !!state.extensions.isCreatingNewExtension,
    );

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.extensions.createNewExtensionErrors,
    );

    useEffect(() => {
        if (isOpen) {
            dispatch(actions.clearErrorsCreateNewExtensionForm());
            setFetchData(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (apiErrors == undefined) {
            setErrors({});
        } else if (
            apiErrors?.faultcode === CreateExtensionFaultCode.DuplicateId
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:extensions.extensionNumberExists'),
            );
        } else if (
            apiErrors?.faultcode === CreateExtensionFaultCode.ExtHGNumberInUse
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:extensions.numberInUse'),
            );
        } else if (
            apiErrors?.faultcode?.includes(
                CreateExtensionFaultCode.MaxOfferedQuantity,
            )
        ) {
            setFieldError(
                'mainProductId',
                t('errors:extensions.maximumProviderLimit'),
            );
        }
    }, [apiErrors]);

    useEffect(() => {
        if (isDataFetching) {
            setFetchData(false);
        }
    }, [isDataFetching]);

    const initialValues = useMemo(
        () => ({
            extensionNumber: '',
            extensionId: '',
            mainProductId: undefined,
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
        setErrors,
    } = useFormik<CreateNewExtensionFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(
                actions.createNewExtension.request({
                    ...form,
                    errorCallback: handleErrorShow,
                }),
            );
        },
        enableReinitialize: true,
        validationSchema: CreateNewExtensionValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const onDeviceChange = useCallback((v) => {
        setFieldValue('device', v);
        setFieldError('port', undefined);
    }, []);

    const handleErrorShow = () => {
        toggleVisibility?.();
        resetForm();
        setShowErrorDialog(true);
    };

    const errorDialog = useMemo(
        () => (
            <CreateNewExtensionErrorDialog
                isOpen={showErrorDialog}
                toggleVisibility={() => setShowErrorDialog(false)}
            />
        ),
        [showErrorDialog],
    );

    const isDisabledSave = !values.extensionNumber || !values.mainProductId;
    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                dataQa="sip-dialog"
                header={t('screens:extensions.createNewExtension')}
                headerClass={classes.header}
                className={classes.modalContainer}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            toggleVisibility?.();
                            resetForm();
                        }}
                    />,
                    <DialogButton
                        disabled={isDisabledSave}
                        key="save"
                        label={t('common:save')}
                        onClick={handleSubmit}
                        primary
                    />,
                ]}
            >
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="create-extension-form"
                >
                    <Grid item className={classes.itemsContainer}>
                        <TextField
                            id="extensionNumber"
                            label={t('screens:extensions.extensionNumber')}
                            onChange={handleChange}
                            value={values.extensionNumber}
                            setFieldError={setFieldError}
                            icon={
                                <IconWithTooltip
                                    dataQa="create-extension-number-tooltip"
                                    tooltipText={t(
                                        'tooltips:extensions.extensionNumber',
                                    )}
                                />
                            }
                            iconPosition="end"
                            dataQa="create-extension-number-input"
                            helperText={errors?.extensionNumber}
                            required
                            maxLength={5}
                        />

                        <TextField
                            id="extensionName"
                            label={t('screens:extensions.extensionName')}
                            onChange={handleChange}
                            value={values.extensionName}
                            setFieldError={setFieldError}
                            icon={
                                <IconWithTooltip
                                    dataQa="create-extension-name-tooltip"
                                    tooltipText={t(
                                        'tooltips:extensions.extensionName',
                                    )}
                                />
                            }
                            iconPosition="end"
                            dataQa="create-extension-name-input"
                            helperText={errors?.extensionName}
                            maxLength={32}
                        />

                        <MainProductSelectField
                            fetchData={fetchData}
                            mainProductId={values.mainProductId}
                            setFieldError={setFieldError}
                            onChange={(_, value) => {
                                setFieldValue('mainProductId', value.i_product);
                            }}
                            errorMsg={errors?.mainProductId}
                            productType={MainProductType.Extensions}
                            customApiErrorMessage={t(
                                'errors:extensions.maximumProviderLimit',
                            )}
                        />
                    </Grid>
                    <div className={classes.containerTitle}>
                        {t('screens:devices.assign')}
                        <IconWithTooltip
                            dataQa="assign-device-tooltip"
                            tooltipText={t(
                                'tooltips:extensions.addDeviceLater',
                            )}
                            className={classes.tooltipIcon}
                        />
                    </div>
                    <Grid item className={classes.itemsContainerExpander}>
                        <AssignDevice
                            refresh={isOpen}
                            onDeviceChange={onDeviceChange}
                            onPortChange={(v) => setFieldValue('port', v)}
                            device={values.device}
                            port={values.port}
                            errors={errors}
                        />
                    </Grid>

                    {isLoading ||
                        (isDataFetching && (
                            <Loader
                                dataQa="create-new-extension-loader"
                                absolutePosition
                            />
                        ))}
                </form>
            </DialogContainer>
            {errorDialog}
        </>
    );
};

export default CreateNewExtensionDialog;

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 24,
        paddingTop: 28
    },

    welcome: {
        fontWeight: 400,
        fontSize: 22,
        color: Colors.Gray5,
        height: 44,
        justifyContent: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },

    logo: {
        display: 'flex',
        width: '100%',
        height: 'fit-content',
        marginTop: 8,
        marginBottom: 9,
    },
}));

import classNames from 'classnames';
import React from 'react';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import { useStyles } from './PaymentStatusDialog.utils';

type ReceiptInfoLineProps = {
    leftText: string;
    rightText?: string;
    rightSecondaryText?: string;
    boldLeftText?: boolean;
    withTooltip?: boolean;
};

const ReceiptInfoLine: React.VFC<ReceiptInfoLineProps> = ({
    leftText,
    rightText,
    rightSecondaryText,
    boldLeftText,
    withTooltip,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.receiptRow}>
            <span
                className={classNames(
                    classes.leftReceiptText,
                    boldLeftText && classes.boldLeftText,
                )}
            >
                {leftText}
            </span>

            <span
                className={classNames(
                    classes.rightContainer,
                    withTooltip && classes.rightContainerWithTooltip,
                )}
            >
                {withTooltip ? (
                    <OverflowTooltip
                        text={rightText}
                        tooltip={rightText}
                        copy={false}
                        classes={{ text: classes.rightReceiptText }}
                    />
                ) : (
                    <span className={classes.rightReceiptText}>
                        {rightText}
                    </span>
                )}
                {!!rightSecondaryText && (
                    <span className={classes.rightSecondaryReceiptText}>
                        {rightSecondaryText}
                    </span>
                )}
            </span>
        </div>
    );
};

export default ReceiptInfoLine;

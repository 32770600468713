import React from 'react';
import { Fade, makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { CustomerAgreementCondition } from '../../store/types/CustomerAgreementCondition';
import { useTranslation } from 'react-i18next';
import ProductRow from './ProductRow';
import EmptyGridPlaceholder from '../../components/DataGrid/EmptyGridPlaceholder';
import ProductLoadingPlaceholder from './ProductLoadingPlaceholder';

const useStyles = makeStyles(() => ({
    mainContainer: {
        borderRadius: 4,
        border: `1px solid ${Colors.Border}`,
        padding: 22,
        marginBottom: 24,
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    label: {
        color: Colors.Text2,
        fontWeight: 500,
        fontSize: 20,
    },
}));

export type ProductsSectionProps = {
    products?: CustomerAgreementCondition[];
    addonProducts?: CustomerAgreementCondition[];
    isLoading: boolean;
};

const ProductsSection: React.VFC<ProductsSectionProps> = ({
    products = [],
    isLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.labelContainer}>
                <span className={classes.label}>
                    {t('screens:dashboard.licensing')}
                </span>
            </div>

            <Fade in={!isLoading} timeout={2000} appear={false}>
                <div>
                    {products?.map((v) => (
                        <ProductRow key={v.i_product} product={v} />
                    ))}
                </div>
            </Fade>

            <EmptyGridPlaceholder
                message={t('screens:dashboard.noProductsAssignedToCompany')}
                isVisible={products?.length === 0 && !isLoading}
            />

            <ProductLoadingPlaceholder isVisible={isLoading} />
        </div>
    );
};

export default ProductsSection;

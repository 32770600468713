import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

type StatusIconProps = {
    state: 0 | 1 | 2 | number;
    dataQa?: string;
    style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'inline-flex',
        marginRight: 9,
        width: 10,
        height: 10,
        borderRadius: 25,
    },
    statusOff: {
        backgroundColor: Colors.Primary,
    },
    statusOn: {
        backgroundColor: Colors.Support,
    },
    statusDisabled: {
        backgroundColor: Colors.Gray9,
    },
    statusHold: {
        backgroundColor: Colors.Gray8,
    },
    statusInactive: {
        backgroundColor: Colors.Error,
    },
}));

export enum Statuses {
    Off = 0,
    On = 1,
    Disabled = 2,
    Hold = 3,
    Inactive=4
}

const StatusIcon: React.FC<StatusIconProps> = ({
    state = 0,
    dataQa,
    style,
}) => {
    const classes = useStyles();

    const styles = classes.root;
    let iconColorStyle = '';

    switch (state) {
        case Statuses.Off:
            iconColorStyle = classes.statusOff;
            break;
        case Statuses.On:
            iconColorStyle = classes.statusOn;
            break;
        case Statuses.Disabled:
            iconColorStyle = classes.statusDisabled;
            break;
        case Statuses.Hold:
            iconColorStyle = classes.statusHold;
            break;
        case Statuses.Inactive:
            iconColorStyle = classes.statusInactive;
            break;
    }

    return (
        <span
            data-qa={dataQa}
            style={style}
            className={styles + ' ' + iconColorStyle}
            data-testid={'status-icon'}
        />
    );
};

export default StatusIcon;

import React from 'react';
import TransactionsList from './TransactionsList';

const TransactionTab = () => {
    return (
        <TransactionsList />
    );
};

export default TransactionTab;

import RecoveryPasswordEmailForm from '../../components/Forms/LoginForm/RecoveryPasswordEmailForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import { MailMePayload } from '../../store/actions/auth/payloads';
import * as actions from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import { Routes } from '../../routes/routes';
import { ReduxState } from '../../store/types';
import usePageTitle from '../../hooks/usePageTitle';

const PasswordRecovery = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();
    const [passedLogin, setPassedLogin] = useState('');
    const [snackbarMessage] = useState('');

    const { login } = useSelector((state: ReduxState) => state.auth);

    const dispatchRecoveryPassword = (payload: MailMePayload) => {
        dispatch(actions.sendRecoveryPasswordEmail.request(payload));
    };

    const handleOnClickOnLoginRecovery = (login?: string) => {
        dispatch(actions.setEnteredLogin({ login: login || '' }));
        history.push(Routes.SignIn);
    };

    const handleOnCloseSnackbar = () => {
        dispatch(actions.loginClearErrors());
    };

    useEffect(() => {
        login && setPassedLogin(login);
    }, [login]);

    return (
        <AuthWrapper
            title={t('screens:signIn.recoveryPasswordTitle')}
            message={snackbarMessage}
            onSnackbarClose={handleOnCloseSnackbar}
        >
            <RecoveryPasswordEmailForm
                onEmailMeAction={dispatchRecoveryPassword}
                onClickLoginAction={handleOnClickOnLoginRecovery}
                validateOnChange={false}
                passedLogin={passedLogin}
            />
        </AuthWrapper>
    );
};

export default PasswordRecovery;

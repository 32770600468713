import qs from 'qs';

export const getTabNumber = (tabsNumbers?: number[]): number => {
    const tabQueryParam = qs.parse(location.search, { ignoreQueryPrefix: true })
        .tab;

    let defaultTab = 0;

    if (
        tabQueryParam &&
        typeof tabQueryParam === 'string' &&
        tabsNumbers?.includes(parseInt(tabQueryParam))
    ) {
        defaultTab = parseInt(tabQueryParam);
    }

    return defaultTab;
};

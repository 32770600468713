import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import StatusIcon from './StatusIcon';
import {ReactComponent as RefreshIcon} from '../../assets/refresh.svg';
import {IconButton, makeStyles} from '@material-ui/core';
import {Colors, errorColor} from '../../styles/Colors';
import classNames from 'classnames';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import {BillStatus} from "../../store/types/SipTrunk";

const YES = 'Y';
const NO = 'N';

type StatusItemProps = {
    sip_status: number;
    blocked: 'Y' | 'N';
    device?: string;
    fetchData?: () => void;
    statusOnCustomMessage?: string;
    statusOffCustomMessage?: string;
    hideRefresh?: boolean;
    billStatus?: BillStatus
};

const useStyles = makeStyles(() => ({
    outsideContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 130,
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    statusText: {
        fontSize: 16,
        fontWeight: 500,
    },
    deviceText: {
        fontSize: 12,
        color: Colors.Gray9,
    },

    statusOff: {
        color: Colors.Primary,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 130,
    },
    statusOn: {
        color: Colors.Support,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 130,

    },
    statusDisabled: {
        color: Colors.Gray9,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 130,

    },
    statusInactive:{
        color: Colors.Error,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 130,
    },
    refresh: {
        marginRight: -40,
    },
    placeholderIcon: {
        width: 34,
        height: 34,
        marginRight: -40,
    },
}));

const StatusItem: React.FC<StatusItemProps> = (
    {
        sip_status,
        blocked,
        fetchData,
        device,
        statusOnCustomMessage,
        statusOffCustomMessage,
        hideRefresh = false,
        billStatus
    }) => {
    const {t} = useTranslation();

    const [isHovered, setIsHovered] = useState(false);

    const classes = useStyles();

    let status = '';
    let state: 0 | 1 | 2 | 3 | 4 = 0;

    let colorClass = '';

    if (billStatus && billStatus == BillStatus.Inactive &&
        (blocked == NO || sip_status == 0 || sip_status == 1)) {
        status = statusOnCustomMessage
            ? statusOnCustomMessage
            : t('screens:sipTrunks.inactive');
        state = 4;
        colorClass = classes.statusInactive;
    } else if (sip_status === 1 && blocked === NO) {
        status = statusOnCustomMessage
            ? statusOnCustomMessage
            : t('screens:extensions.sipStatusRegister');
        state = 1;
        colorClass = classes.statusOn;
    } else if (sip_status === 0 && blocked === NO) {
        state = 0;
        status = statusOffCustomMessage
            ? statusOffCustomMessage
            : t('screens:extensions.sipStatusUnregister');
        colorClass = classes.statusOff;
    } else if (blocked === YES) {
        state = 2;
        status = t('screens:extensions.disabled');
        colorClass = classes.statusDisabled;
    }

    return (
        <div
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.outsideContainer}
        >
            <div className={classes.container}>
                <div className={classes.statusContainer}>
                    <span
                        className={classNames(classes.statusText, colorClass)}
                        data-qa="status-text"
                    >
                        <StatusIcon
                            style={{display: 'inline-block'}}
                            state={state}
                        />
                        <OverflowTooltip text={status} tooltip={status} copy={false}/>
                    </span>
                </div>
                {device && (
                    <OverflowTooltip
                        text={device}
                        tooltip={device}
                        classes={{text: classes.deviceText}}
                    />
                )}
            </div>
            {!hideRefresh && isHovered ? (
                <IconButton
                    className={classes.refresh}
                    size="small"
                    onClick={fetchData}
                >
                    <RefreshIcon/>
                </IconButton>
            ) : (
                <div className={classes.placeholderIcon}/>
            )}
        </div>
    );
};

export default StatusItem;

import { CallForwardingRule } from './CallForwardingForm.utils';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import { ForwardModeFlag } from '../../../../store/types/ServiceFeature';
import {intervalFormValidationSchema} from "../../../IntervalSelect/IntervalSelect.utils";

export type FollowRuleFormProps = {
    setFieldError: (field: string, value: any) => void;
    setFieldValue: (field: string, value: any) => void;
    rule: CallForwardingRule;
    permittedSipProxies: string[];
    errors: any;
    mode: ForwardModeFlag;
};

const emptyField = i18n.t('errors:common.emptyInput');
const cldAndSipProxyEmpty = i18n.t('errors:extensions.cldAndSipProxyEmpty');
export const forwardToError = i18n.t('errors:extensions.forwardTo');

export const followRuleValidationSchema = Yup.object().shape({
    weight: Yup.string().notRequired(),
    description: Yup.string().required(emptyField),
    number: Yup.string().when('sipProxy', {
        is: (value: unknown) => !!value,
        then: Yup.string()
            .matches(/^[a-dA-D0-9*#]*[a-dA-D0-9*#!]$/, forwardToError)
            .notRequired(),
        otherwise: Yup.string()
            .matches(/^[a-dA-D0-9*#]*[a-dA-D0-9*#!]$/, forwardToError)
            .required(cldAndSipProxyEmpty),
    }),
    intervals: intervalFormValidationSchema.notRequired(),
    ringFor: Yup.string().required(),
    sipProxy: Yup.string().notRequired(),
    keepOriginalCld: Yup.boolean().required(),
});

export const followRuleValidationSchemaWithoutSIP = followRuleValidationSchema.shape(
    {
        number: Yup.string()
            .matches(/^[a-dA-D0-9*#]*[a-dA-D0-9*#!]$/, forwardToError)
            .required(emptyField),
    },
);

export const numberNotReq = followRuleValidationSchema.shape(
    {
        number: Yup.string().nullable().notRequired(),
    },
);


export const followRuleWithoutDescriptionSchema = (schema: any) => {
    return schema.shape({
        description: Yup.string().notRequired(),
    });
};

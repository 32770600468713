import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    AddPaymentMethodDialogProps,
    useStyles,
} from './AddPaymentMethodDialog.utils';
import {
    convertPaymentMethodToFormData,
    isEmailRequiredForSave,
    paymentMethodFormDefaultValues,
    PaymentMethodFormType,
    paymentMethodValidationSchemaHelper,
} from '../PaymentMethodForm/PaymentMethodForm.utils';
import DialogContainer, {
    DialogButton,
} from '../../../AlertDialog/DialogContainer';
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm';
import { PaymentMethodType } from '../../../../store/types/Billing';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import {
    OwnerPaymentMethod,
    PaymentMethod,
} from '../../../../store/types/Payment';
import { CustomerInfoDetails } from '../../../../store/types/CustomerInfo';
import { Subdivision } from '../../../../store/types/Subdivision';
import { actions } from '../../../../store';
import Loader from '../../../Loader/Loader';

const AddPaymentMethodDialog: React.VFC<AddPaymentMethodDialogProps> = ({
    isOpen,
    toggleVisibility,
    paymentMethodToEdit,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.billing.customerInfo);

    const isDefaultPaymentFormLoading = useSelector<
        ReduxState,
        boolean | undefined
    >((state) => state.billing.isDefaultPaymentFormLoading);

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>(
        (state) => state.generic.subdivisionsList,
    );

    const paymentsMethods = useMemo(() => {
        const paymentMethods = [];

        if (
            ownerPaymentsMethods?.find(
                (v) => v.payment_method === PaymentMethod.Echeck,
            )
        ) {
            paymentMethods.push(PaymentMethodType.BankAccount);
        }

        if (
            ownerPaymentsMethods?.find(
                (v) => v.payment_method !== PaymentMethod.Echeck,
            )
        ) {
            paymentMethods.push(PaymentMethodType.CreditCard);
        }
        return paymentMethods;
    }, [ownerPaymentsMethods]);

    const initialValues = useMemo(() => {
        return {
            ...paymentMethodFormDefaultValues,
            paymentType: paymentsMethods?.includes(PaymentMethodType.CreditCard)
                ? PaymentMethodType.CreditCard
                : PaymentMethodType.BankAccount,
            address: customerInfo?.baddr1 || '',
            state:
                subdivisionsList.find((v) => v.name === customerInfo?.state)
                    ?.i_country_subdivision || null,
            country: customerInfo?.country || '',
            city: customerInfo?.city || '',
            postalCode: customerInfo?.zip || '',
            billingPhone: customerInfo?.phone1 || '',
            ...(paymentMethodToEdit
                ? convertPaymentMethodToFormData(paymentMethodToEdit)
                : {}),
        };
    }, [paymentMethodToEdit, ownerPaymentsMethods, subdivisionsList]);

    const isPaymentMethodAvaiableToSave = (
        isDirty: boolean,
        values: PaymentMethodFormType,
    ) => {
        if (
            !isDirty ||
            !values.address ||
            !values.country ||
            !values.city ||
            !values.billingPhone ||
            !values.postalCode
        ) {
            return false;
        }

        if (
            isEmailRequiredForSave(
                values,
                ownerPaymentsMethods,
                customerInfo,
            ) &&
            !values.email
        ) {
            return false;
        }

        if (values.paymentType === PaymentMethodType.CreditCard) {
            return (
                !!values.cardholderName &&
                !!values.cardNumber &&
                !!values.expirationDate &&
                !!values.cvv
            );
        } else {
            return (
                !!values.accountName &&
                !!values.accountNumber &&
                !!values.bankRoutingNumber
            );
        }
    };

    const onSubmit = (form: PaymentMethodFormType) => {
        dispatch(actions.setDefaultPaymentMethod.request(form));
    };

    return (
        <Formik<PaymentMethodFormType>
            initialValues={initialValues}
            validationSchema={paymentMethodValidationSchemaHelper(
                ownerPaymentsMethods || [],
                customerInfo,
                true
            )}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
        >
            {({ submitForm, resetForm, dirty, values }) => (
                <DialogContainer
                    isOpen={isOpen}
                    dataQa="ring-schedule-group-dialog"
                    header={
                        paymentMethodToEdit
                            ? t('screens:billing.editPaymentMethod')
                            : t('screens:billing.addPaymentMethod')
                    }
                    className={classes.modalContainer}
                    disableEnforceFocus
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() => {
                                toggleVisibility?.();
                                setTimeout(() => {
                                    resetForm();
                                }, 300);
                            }}
                        />,
                        <DialogButton
                            key="save"
                            label={t('common:save')}
                            onClick={submitForm}
                            disabled={
                                !isPaymentMethodAvaiableToSave(dirty, values)
                            }
                            primary
                        />,
                    ]}
                >
                    <PaymentMethodForm
                        paymentMethodsTypes={paymentsMethods}
                        withEmailWhenIsNecessary
                    />

                    {isDefaultPaymentFormLoading && (
                        <Loader
                            dataQa="edit-payment-method-loader"
                            absolutePosition
                        />
                    )}
                </DialogContainer>
            )}
        </Formik>
    );
};

export default AddPaymentMethodDialog;

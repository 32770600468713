import { AxiosResponse } from 'axios';
import dayjs from '../../../../services/customDayJs'
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Account, Customer, Products } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import { showErrorToast } from '../../../../utils/showErrorToast';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import { api } from '../../../services/axios';
import {
    AddonsValidationResults,
    CustomerAgreementCondition,
} from '../../../types/CustomerAgreementCondition';
import { ExtensionType } from '../../../types/Extension';
import { ReduxState } from '../../../types';
import { getAccountInfo } from '../extensions/saga';

export function* getExtensionProducts(i_account?: number) {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            with_usage: '1',
        });

        const res: AxiosResponse<{
            agreement_condition_list: CustomerAgreementCondition[];
        }> = yield api.post(Customer.GetCustomerAgreementInfo, body);

        let extraProduct: CustomerAgreementCondition | undefined;
        const extraAddons: CustomerAgreementCondition[] = [];

        const addons = res.data.agreement_condition_list.filter(
            (v) => v.is_addon === 1,
        );

        if (i_account) {
            const accountInfoRes: AxiosResponse<{
                account_info: ExtensionType;
            }> = yield getAccountInfo(i_account);

            const { account_info } = accountInfoRes.data;
            if (account_info.product_name && account_info.i_product) {
                extraProduct = {
                    product_name: account_info.product_name,
                    i_product: account_info.i_product,
                    is_addon: 1,
                    max_offered_quantity: 0,
                    used_quantity: 1,
                };
            }

            if (account_info.assigned_addons) {
                const temp: CustomerAgreementCondition[] = account_info.assigned_addons.map(
                    (a) => {
                        return {
                            i_product: a.i_product,
                            is_addon: 1,
                            max_offered_quantity: 0,
                            product_name: a.name || a.end_user_name,
                            used_quantity: 1,
                        };
                    },
                );

                temp.forEach((item) => {
                    if (!addons.find((a) => a.i_product == item.i_product)) {
                        extraAddons.push(item);
                    }
                });
            }
        }

        const products = res.data.agreement_condition_list.filter(
            (v) => v.is_addon === 0,
        );

        const mainProducts =
            extraProduct &&
            !products.find((p) => p.i_product == extraProduct?.i_product)
                ? [...products, extraProduct]
                : products;

        const addonsProducts = extraAddons.length
            ? [...addons, ...extraAddons]
            : addons;

        yield put(
            actions.getExtensionProducts.success({
                mainProducts: mainProducts,
                addonsProducts: addonsProducts,
            }),
        );
    } catch (err) {
        actions.getExtensionProducts.failure();
    }
}

export function* editExtensionPlan(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);

    if (data.selectedMainProduct !== undefined || data.selectedAddonsProducts) {
        const selectedMainProduct =
            data.selectedMainProduct ||
            action.payload.initialValues.selectedMainProduct;
        const selectedAddonsProduct =
            data.selectedAddonsProducts ||
            action.payload.initialValues.selectedAddonsProducts;

        body.setParams({
            account_info: {
                i_account: action.payload.accountId,
                i_product: selectedMainProduct,
                assigned_addons: selectedAddonsProduct.map((v) => ({
                    i_product: v,
                })),
            },
        });

        yield api.post(Account.UpdateAccount, body);
    }
}

export function* validateAddonsProducts(
    action: ActionType<typeof actions.validateAddonsProducts.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const addonsProducts: CustomerAgreementCondition[] = yield select(
            (state) => state.extensions.products.addonsProducts,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const dateNow = dayjs.utc().format('YYYY-MM-DD HH:mm:ss');

        body.setParams({
            i_product: action.payload.mainProductId,
            selected_products: addonsProducts.map((v) => ({
                i_product: v.i_product,
                effective_from: dateNow,
                effective_to: null,
            })),
        });

        const response: AxiosResponse<{
            addons_combination_validation_result: AddonsValidationResults;
        }> = yield api.post(Products.ValidateAddonsCombination, body);

        yield put(
            actions.validateAddonsProducts.success(
                response.data.addons_combination_validation_result,
            ),
        );
    } catch (err: any) {
        showErrorToast(err.response?.data?.faultstring);
        yield put(actions.validateAddonsProducts.failure());
    }
}

import { ServiceFeature } from './ServiceFeature';
import {BillStatus} from "./SipTrunk";

export interface ExtensionType {
    auto_attendant?: string;
    fax_only_mode?: string;
    value?: number;
    alias_did_number_list?: { i_did_number: number; did_number: string }[];
    account_expired?: number;
    account_inactive?: number;
    activation_date?: Date;
    address_line_2?: string;
    assigned_addons?: {
        i_product: number;
        name: string;
        end_user_name: string;
    }[];
    aux_fields?: unknown[];
    baddr1: string;
    balance?: number;
    batch_name?: string;
    bcc?: string;
    bill_status?: BillStatus;
    billing_model?: number;
    blocked: 'Y' | 'N';
    city: string;
    companyname?: string;
    cont1?: string;
    cont2?: string;
    control_number?: number;
    country: string;
    credit_exceed?: string;
    credit_limit?: number;
    cust_bill_suspension_delayed?: number;
    customer_bill_status?: string;
    customer_bill_suspended?: number;
    customer_blocked?: string;
    customer_credit_exceed?: string;
    customer_name?: string;
    customer_status?: string;
    ecommerce_enabled?: string;
    email: string;
    expiration_date?: Date;
    extension_id: string;
    extension_name: string;
    faxnum?: string;
    first_usage?: Date;
    first_usage_time?: Date;
    firstname?: string;
    h323_password: string;
    has_custom_fields?: number;
    i_account: number;
    i_account_balance_control_type?: number;
    i_account_role?: number;
    i_acl?: number;
    i_batch?: number;
    i_credit_card?: number;
    i_customer: number;
    i_customer_site?: number;
    i_did_number?: number;
    did_number?: string;
    i_distributor?: number;
    i_lang?: string;
    i_master_account?: number;
    i_parent?: number;
    i_product?: number;
    i_role?: number;
    i_subscriber?: number;
    i_time_zone?: number;
    i_vd_plan?: number;
    id: string;
    idle_days?: number;
    in_date_format?: string;
    in_time_format?: string;
    inactivity_expire_time?: number;
    included_services?: unknown[];
    is_active?: number;
    iso_4217?: string;
    iso_639_1?: string;
    issue_date?: Date;
    last_recharge?: Date;
    last_usage?: Date;
    lastname?: string;
    life_time?: number;
    login: string | null;
    login_to_um_is_available?: number;
    mac?: string;
    management_number?: string;
    master_account_id?: string;
    master_billing_model?: number;
    midinit?: string;
    note?: string;
    notepad?: string;
    opening_balance?: number;
    out_date_format?: string;
    out_date_time_format?: string;
    out_time_format?: string;
    password: string | null;
    password_lifetime?: number;
    password_timestamp?: Date;
    phone1?: string;
    phone2?: string;
    product_name?: string;
    product_visible_name?: string;
    realm?: string;
    redirect_number: string;
    salutation?: string;
    service_features?: ServiceFeature[];
    service_flags?: string;
    sip_agent?: string;
    sip_contact?: string;
    sip_status?: number;
    site_name?: string;
    state: string;
    status?: string;
    subscriber_email?: string;
    time_zone_name: string;
    timer?: Date;
    ua_profile_id?: number;
    um_domain?: string;
    um_h323_id?: string;
    um_ip?: string;
    um_url?: string;
    um_url_valid?: number;
    voucher_info?: any[];
    zero_balance?: number;
    zero_balance_date?: Date;
    zip: string;
    i_prompt: number | null;
    displayNumber?: string;
    label?: string;
    call_processing_mode_info?: CallProcessingModeInfo;
    unified_messaging?:string,
    fax_only?:string,
    max_calls_in?:number,
    max_calls_out?:number,
    max_calls_fwd?:number,
    max_calls?:number
}

export type CustomerExtension = {
    account_id?: string;
    bill_status?: string;
    cont1?: string;
    firstname?: string;
    group_name?: string;
    hunt_keep_original_cli?: string;
    hunt_sequence?: string;
    i_account?: number | string;
    i_c_ext?: number;
    i_c_group?: number;
    i_customer?: number;
    i_customer_of_account?: number;
    i_prompt?: number;
    id?: string;
    ip_phone_name?: string;
    lastname?: string;
    midinit?: string;
    name?: string;
    primary_group_id?: number;
    primary_group_name?: string;
    primary_i_c_group?: number;
    prompt_error?: string;
    prompt_name?: string;
    prompt_action?: string;
    prompt_status?: string;
    published: 'Y' | 'N';
    type?: string;
};

export interface CallProcessingModeInfo {
    short_code: number;
    i_response_message: string | null;
    sticky: string;
    name: string;
    effective_to: string | null;
}

export interface DeleteCustomerExtension {
    id: string;
    i_customer: number;
    i_c_ext?: number;
    callback?: () => void;
}

export enum CreateExtensionFaultCode {
    MaxOfferedQuantity = 'Server.Customer.Maximum offered quantity exceeded',
    ExtHGNumberInUse = 'Server.Customer.Ext_HG_number_already_in_use',
    ExtHGNameInUse = 'Server.Customer.HG_name_already_in_use',
    DuplicateId = 'Server.Customer.duplicate_id',
}

export enum PasswordFaultCode {
    InvalidChars = 'Client.Account.invalid_h323_password_value'
}

export enum UpdateExtensionFaultCode {
    ExtHGNumberInUse = 'Client.Customer.Ext_HG_number_already_in_use',
    DuplicateId = 'Client.Customer.duplicate_id',
    DuplicateLogin = 'Server.Account.duplicate_login',
    AccountUpdatedFailed = 'Server.Account.account_update.failed',
}

export enum UpdateExtensionFaultString {
    IllegalChar = "The password must contain at least one symbol from the following groups",
    Forbidden = "Password contains forbidden characters"
}
import React from 'react';
import { makeStyles, TablePagination } from '@material-ui/core';
import { PaginationProps } from './types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    pagination: {
        marginTop: 30,
        color: Colors.Gray,
        height: 66,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

        '& .MuiTablePagination-select': {
            backgroundColor: Colors.White,
            borderBottom: `1px solid ${Colors.Gray}`,
            textAlign: 'left',
            textAlignLast: 'unset',
            color: Colors.Gray,
            fontSize: 16,
            verticalAlign: 'middle',
        },
        '& .MuiTablePagination-selectRoot': {
            '& .MuiInputBase-input': {
                padding: '12px !important',
            },
        },
        '& .MuiInputBase-root': {
            width: 82,
        },
    },
}));

const Pagination: React.VFC<PaginationProps> = ({
    className,
    rowCount,
    page,
    pageSize,
    pageSizeOptions,
    onPageChange,
    onPageSizeChange,
    isVisible = true,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!isVisible) {
        return null;
    }

    return (
        <TablePagination
            className={classNames(classes.pagination, className)}
            data-qa={'drag-drop-pagination'}
            data-testid={'drag-drop-pagination'}
            component="div"
            count={rowCount}
            page={page}
            rowsPerPageOptions={pageSizeOptions}
            onChangePage={onPageChange}
            onChangeRowsPerPage={onPageSizeChange}
            rowsPerPage={pageSize}
            labelRowsPerPage={
                <span>{t('screens:ringGroups.itemsPerPage')}:</span>
            }
        />
    );
};

export default Pagination;

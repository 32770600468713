import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/';
import {
    InvoiceList,
    TransactionList,
} from '../../types/Billing';
import { CustomerInfoDetails } from '../../types/CustomerInfo';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentDetails,
    PaymentMethodInfo,
} from '../../types/Payment';
import { CallHistory } from '../../types/CallHistory';

export interface BillingStateType {
    isLoading?: boolean;
    customerInfo?: CustomerInfoDetails;
    invoicesTab?: {
        invoiceList?: InvoiceList;
        isLoading?: boolean;
    };
    transactionsTab?: {
        transactionList?: TransactionList;
        isLoading?: boolean;
        dataIsLoading?: boolean;
        transactionDetails?: {
            xdr_list?: CallHistory[];
            total?: number;
            isLoading?: boolean;
        };
    };
    ownerPaymentsMethods?: OwnerPaymentMethod[];
    customerPaymentMethod?: PaymentMethodInfo;
    autoPaymentInfo?: AutoPaymentInfo;
    isFormLoading?: boolean;
    isDefaultPaymentFormLoading?: boolean;
    isMakeTransactionLoading?: boolean;
    paymentSuccess?:boolean
    estimatedTaxesValue?: number;
    paymentDetails?: PaymentDetails;
    isPaymentProcessing?: boolean;
    isPaymentHasError?: boolean;
}

export const initialState: BillingStateType = {};

export type BillingActionsType = ActionType<typeof actions>;

const billingReducer = createReducer<BillingStateType, BillingActionsType>(
    initialState,
)
    .handleAction(actions.getInvoicesListAction.request, (state) => {
        return {
            ...state,
            invoicesTab: {
                ...state.invoicesTab,
                isLoading: true,
            },
        };
    })
    .handleAction(actions.getInvoicesListAction.success, (state, action) => {
        return {
            ...state,
            invoicesTab: {
                ...state.invoicesTab,
                invoiceList: action.payload,
                isLoading: false,
            },
        };
    })
    .handleAction(actions.getInvoicesListAction.failure, (state) => {
        return {
            ...state,
            invoicesTab: {
                ...state.invoicesTab,
                isLoading: false,
            },
        };
    })
    .handleAction(actions.getBasicBillingInfo.request, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    })
    .handleAction(actions.getBasicBillingInfo.success, (state, action) => {
        return {
            ...state,
            isLoading: false,
            customerInfo: action.payload,
        };
    })
    .handleAction(actions.getBasicBillingInfo.failure, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
    .handleAction(actions.getTransactionList.request, (state) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                isLoading: true,
            },
        };
    })
    .handleAction(actions.getTransactionList.success, (state, action) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                isLoading: false,
                transactionList: action.payload,
            },
        };
    })
    .handleAction(actions.getTransactionList.failure, (state) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                isLoading: false,
            },
        };
    })
    .handleAction(actions.getPaymentData.success, (state, action) => {
        return {
            ...state,
            ...action.payload,
        };
    })
    .handleAction(actions.getTransactionDetails.request, (state) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                transactionDetails: {
                    ...state.transactionsTab?.transactionDetails,
                    isLoading: true,
                },
            },
        };
    })
    .handleAction(actions.getTransactionDetails.success, (state, action) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                transactionDetails: {
                    ...action.payload,
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(actions.getTransactionDetails.failure, (state) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                transactionDetails: {
                    ...state.transactionsTab?.transactionDetails,
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(actions.transactionsHistoryRefresh, (state) => {
        return {
            ...state,
            transactionsTab: {
                ...state.transactionsTab,
                transactionDetails: {
                    xdr_list: [],
                    total: 0,
                    isLoading: false,
                },
            },
        };
    })
    .handleAction(actions.editAutoPayments.request, (state) => {
        return {
            ...state,
            isFormLoading: true,
        };
    })
    .handleAction(actions.editAutoPayments.failure, (state) => {
        return {
            ...state,
            isFormLoading: false,
        };
    })
    .handleAction(actions.setDefaultPaymentMethod.request, (state) => {
        return {
            ...state,
            isDefaultPaymentFormLoading: true,
        };
    })
    .handleAction(actions.setDefaultPaymentMethod.failure, (state) => {
        return {
            ...state,
            isDefaultPaymentFormLoading: false,
        };
    })
    .handleAction(actions.removeDefaultPaymentMethod.request, (state) => {
        return {
            ...state,
            isFormLoading: true,
        };
    })
    .handleAction(actions.removeDefaultPaymentMethod.failure, (state) => {
        return {
            ...state,
            isFormLoading: false,
        };
    })
    .handleAction(actions.estimateTaxes.request, (state) => ({
        ...state,
        isMakeTransactionLoading: true,
    }))
    .handleAction(actions.estimateTaxes.success, (state, action) => ({
        ...state,
        isMakeTransactionLoading: false,
        estimatedTaxesValue: action.payload,
    }))
    .handleAction(actions.estimateTaxes.failure, (state) => ({
        ...state,
        isMakeTransactionLoading: false,
    }))
    .handleAction(actions.clearEstimatedTaxes, (state) => ({
        ...state,
        estimatedTaxesValue: undefined,
    }))
    .handleAction(actions.makePayment.request, (state) => ({
        ...state,
        isMakeTransactionLoading: true,
        paymentSuccess:false
    }))
    .handleAction(actions.makePayment.success, (state,action) => ({
        ...state,
        isMakeTransactionLoading: false,
        paymentSuccess:action.payload.paymentSuccess
    }))
    .handleAction(actions.makePayment.failure, (state) => ({
        ...state,
        isMakeTransactionLoading: false,
        paymentSuccess:false
    }))
    .handleAction(actions.getPaymentDetails.success, (state, action) => ({
        ...state,
        paymentDetails: action.payload,
    }))
    .handleAction(actions.setPaymentProcessing, (state, action) => ({
        ...state,
        isPaymentProcessing: action.payload,
    }))
    .handleAction(actions.setPaymentError, (state, action) => ({
        ...state,
        isPaymentHasError: action.payload,
    }));

export default billingReducer;

import i18n from '../../services/i18n';
import { AddonsValidationResults } from '../../store/types/CustomerAgreementCondition';

export const getConflictedAddons = (
    selectedAddons: number[],
    conflicts: AddonsValidationResults,
) => {
    const conflictedIds: number[] = [
        ...conflicts.disallowed_products.map((v) => v.i_product),
    ];

    conflicts.service_features_conflicts.forEach((v) => {
        const ids = v.conflicting_addons.map((v) => v.i_product);
        if (ids.find((v) => selectedAddons.includes(v))) {
            conflictedIds.push(
                ...ids.filter((v) => !selectedAddons.includes(v)),
            );
        }
    });

    conflicts.subscription_conflicts.forEach((v) => {
        const ids = v.addons.map((v) => v.i_product);
        if (ids.find((v) => selectedAddons.includes(v))) {
            conflictedIds.push(
                ...ids.filter((v) => !selectedAddons.includes(v)),
            );
        }
    });

    conflicts.vdp_conflicts.forEach((v) => {
        const ids = v.conflicting_vdps.map((v) => v.i_product);
        if (ids.find((v) => selectedAddons.includes(v))) {
            conflictedIds.push(
                ...ids.filter((v) => !selectedAddons.includes(v)),
            );
        }
    });

    return conflictedIds;
};

export const getConflictedAddonsWithAddon = (
    i_product: number,
    conflicts: AddonsValidationResults,
) => {
    const conflictedAddonsNames: string[] = [];

    if (conflicts.disallowed_products.find((v) => v.i_product === i_product)) {
        conflictedAddonsNames.push(
            i18n.t<string>('screens:extensions.theMainProduct'),
        );
    }

    conflicts.service_features_conflicts.forEach((v) => {
        const ids = v.conflicting_addons.map((v) => v.i_product);
        if (ids.includes(i_product)) {
            conflictedAddonsNames.push(
                ...v.conflicting_addons
                    .filter((v) => v.i_product !== i_product)
                    .map((v) => v.product_name),
            );
        }
    });

    conflicts.subscription_conflicts.forEach((v) => {
        const ids = v.addons.map((v) => v.i_product);
        if (ids.includes(i_product)) {
            conflictedAddonsNames.push(
                ...v.addons
                    .filter((v) => v.i_product !== i_product)
                    .map((v) => v.product_name),
            );
        }
    });

    conflicts.vdp_conflicts.forEach((v) => {
        const ids = v.conflicting_vdps.map((v) => v.i_product);
        if (ids.includes(i_product)) {
            conflictedAddonsNames.push(
                ...v.conflicting_vdps
                    .filter((v) => v.i_product !== i_product)
                    .map((v) => v.product_name),
            );
        }
    });

    return [...new Set(conflictedAddonsNames)];
};

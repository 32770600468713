import {ListItem, makeStyles} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Colors} from '../../styles/Colors';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

export type CustomSidebarItemProps = {
    active?: boolean;
    title: string;
    url: string;
    forcePermission?: PermissionType
};

const useStyles = makeStyles(() => ({
    activeItem: {
        color: `${Colors.WhiteWithOpacity} !important`,
        backgroundColor: Colors.Secondary6,
        borderRadius: 4,
    },
    disableItem: {
        color: `${Colors.Gray} !important`,
        backgroundColor: 'transparent',
        borderRadius: 4,

        '&:hover':{
            backgroundColor: 'transparent !important',
            cursor: 'not-allowed !important'
        }
    },
    item: {
        cursor: 'pointer',
        width: 'unset',
        paddingLeft: 40,
        marginLeft: 15,
        marginRight: 15,
        '&:hover': {
            backgroundColor: Colors.Secondary6,
        },
    },
    separator: {
        border: `1px solid ${Colors.White}`,
        opacity: 0.3,
        marginLeft: 24,
        marginRight: 29,
        marginBottom: 10,
        marginTop: 10,
    },
}));

const CustomSidebarItem: React.FC<CustomSidebarItemProps> = (
    {
        active,
        title,
        url,
        forcePermission
    }) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const permission = usePermissionContext();
    let validPermission = forcePermission ?? permission;

    if (forcePermission && forcePermission === PermissionType.Hidden) {
        validPermission = PermissionType.Hidden;
    }

    if (forcePermission == undefined && permission == PermissionType.Hidden) {
        validPermission = PermissionType.Hidden;
    }

    if (validPermission === PermissionType.Hidden) {
        return null
    }

    const item = (
        <ListItem
            className={classNames(
                active && classes.activeItem,
                validPermission !== PermissionType.Visible && classes.disableItem,
                classes.item,
            )}
            onClick={validPermission == PermissionType.Visible ? () => window.location.replace(url) : () => null}
        >
            {t(`enums:customSidebarLinks.${title}`, title)}
        </ListItem>
    )

    return (
        <>
            <div className={classes.separator}/>
            {validPermission === PermissionType.ReadOnly ? item : (
                <a href={url}>
                    {item}
                </a>
            )}

        </>
    );
};

export default CustomSidebarItem;

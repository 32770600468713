import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        maxWidth: 850,
    },
    header: {
        fontWeight: 500,
        color: Colors.Text,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 40,
    },
    insideText: {
        marginLeft: 16,
        marginRight: 26,
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    textFieldMargin: {
        marginTop: -15,
        width: 200,
    },
    disabledText: {
        color: Colors.Gray9,
    },
    frozenWarning: {
        marginBottom: 35,
        maxWidth: 708,
    },
}));

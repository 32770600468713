import {makeStyles} from "@material-ui/core";
import {Colors} from "../../styles/Colors";
import {DispatchType, GroupMemberType, RingGroupMemberFormProps} from "./groupDialogUtils";

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },
    saveButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: Colors.White,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            padding: '54px 82px 72px 82px',
            width: 476,
            height: 600,
            backgroundColor: Colors.SmokeBackground,
        },
    },
    numberInput: {
        flex: 1,

        '& .Mui-disabled': {
            opacity: 0.5,
        },
    },
    numberLabel: {
        marginLeft: -80,
        marginTop: 18,
    },
    middleOption: {
        margin: '0 50px',
    },
    groupSelect: {
        marginBottom: 16,
    },
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray5,
        marginBlockEnd: '5px',
    },
    inputMargin: {
        marginTop: '20px !important',
    },
    radioContainer: {
        background: Colors.White,
        border: `1px solid ${Colors.Border}`,
        margin: '0 16px 0 0',
        height: 195,
        borderRadius: 4,
        padding: '18px 25px 18px 25px',
        maxWidth: 171,
        '&:last-of-type': {
            marginRight: 0,
        },
    },
    radioDescription: {
        fontSize: 14,
    },
    selectedRadio: {
        background: Colors.Secondary10,
        border: `2px solid ${Colors.Secondary1}`,
    },
    column: {
        flexDirection: 'column',
    },
    marginBottom: {
        marginBottom: 34,
    },
    ringing: {
        marginLeft: -8,
        marginBottom: 0,
    },
    descriptionRadio: {
        width: 182,
    },
    descriptionRadioDisabled: {
        width: 182,
        color: Colors.Gray
    },
    minText: {
        position: 'absolute',
        marginTop: 16,
        marginLeft: 50,
        top: 10.5
    },
    saveButtonDisabled: {
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}!important`,
        },
    },
}));

export const formInitialValues: RingGroupMemberFormProps = {
    groupMemberType: GroupMemberType.Extension,
    ignoreFollowMeVoiceMail: true,
    setAsPrimary: false,
    ringGroup: undefined,
    extension: undefined,
    dispatchType: DispatchType.PermanentlyEnabled,
    customWaitsFor: 15,
    customThenRingsFor: 30,
    ringing: true,
    supervisor: false,
    member: undefined,
    allowGroupPaging: false
};

export interface CreateNewSipTrunkFormData {
    trunkId: string;
    password?: string;
    mainProductId?: number;
    didNumber?: string[];
}
import * as React from 'react';
import {useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { Validate2FADialogProps, mfaValidationSchema, useStyles } from './Validate2FADialog.utils';
import classNames from 'classnames';
import { ConfirmMfaConfig } from '../../store/actions/payloads';
import DialogContainer from '../../components/AlertDialog/DialogContainer';
import CustomizedTextField from '../../components/TextField/TextField';
import Logo from '../../components/Logo/Logo';
import CustomizedButton from '../../components/Button/Button';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import Loader from '../../components/Loader/Loader';
import { INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED } from '../../store/sagas/auth/saga';

const Validate2FADialog: React.FC<Validate2FADialogProps> = ({
        isOpen,
        sessionConfig,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mfaVerifyError, verifying2FaCode } = useSelector(
        (state: ReduxState) => state.auth,
    );
    
    const initialValues: ConfirmMfaConfig = useMemo(
        () => ({
            one_time_password: ''
        } as ConfirmMfaConfig),
        [],
    );

    const {
        values,
        handleSubmit,
        setFieldError,
        handleChange,
        errors,
        setFieldValue
    } = useFormik<ConfirmMfaConfig>({
        initialValues,
        onSubmit: (form) => {
            dispatch(actions.validate2FaCode.request({
                ...form,
                //@ts-ignore
                sessionId: sessionConfig
            }));
        },
        validationSchema: mfaValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    useMemo(() => {
        if(mfaVerifyError?.faultstring) {
            setFieldValue('one_time_password', '');
            setFieldError('one_time_password', mfaVerifyError.faultstring);
        }
    }, [mfaVerifyError]);

    const maxAttemptsReached = useMemo(() => {
        const reached = mfaVerifyError?.faultcode === INVALID_VERIFICATION_CODE_MAX_ATTEMPTS_REACHED;
        if(reached) {
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
        return reached;
    }, [mfaVerifyError]);
    
    return (
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'login-enable-2fa'}
                hideHeader
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="login-enable-2fa-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classNames(classes.boxRow, classes.logoContainer)}>
                            <Logo />
                        </Box>

                        <Box className={classes.boxRow} style={{marginTop: 17}}>
                            <div className={classes.header1}>{t('screens:twoFa.twoFactorAuth')}</div>
                        </Box>

                        <Box className={classes.boxRow} style={{marginTop: 56}}>
                            {maxAttemptsReached ? (
                                <div className={classes.maxAttemptsReached}>{t('screens:twoFa.maxAttemptsReached')}</div>
                            ) : (
                                <CustomizedTextField
                                    autoFocus
                                    id="one_time_password"
                                    label={t('screens:twoFa.enterCode')}
                                    value={values.one_time_password}
                                    required
                                    setFieldError={setFieldError}
                                    helperText={
                                        errors.one_time_password && errors.one_time_password.length > 0
                                            ? errors.one_time_password
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleChange(e);
                                        if(e.target.value.length >= 6) {
                                            setTimeout(() => {
                                                handleSubmit();
                                            }, 100);
                                        }
                                    }}
                                    maxLength={6}
                                    dataQa="one_time_password-field"
                                    className={classes.oneTimePassword}
                                    skipPermission
                                />)}
                        </Box>
                        
                        <Box className={classNames(classes.boxRow, classes.buttonContainer)}>
                            <CustomizedButton
                                dataQa="submit-button"
                                skipPermission
                                onClick={() => {handleSubmit();}}
                                primary
                                className={classNames(classes.submitButton, verifying2FaCode && classes.disabledSubmitButton)}
                                disabled={verifying2FaCode || maxAttemptsReached || (values.one_time_password.length !== 6)}
                            >
                                {(verifying2FaCode || maxAttemptsReached) ? (
                                    <Loader dataQa={'verify-in-progress'} absolutePosition={true}/>
                                ) : (
                                    t('screens:twoFa.submit')
                                )}
                            </CustomizedButton>
                        </Box>
                        
                        <Box className={classes.boxRow} style={{marginTop: 28, justifyContent: 'center'}}>
                            <div className={classes.header3}>{t('screens:twoFa.ifYouLost')}</div>
                        </Box>
                    </Grid>
                </form>
            </DialogContainer>
    );
};

export default Validate2FADialog;
import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContainer, { DialogContainerProps } from './DialogContainer';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';

export interface AlertDialogPropsType extends DialogContainerProps {
    description?: string | React.ReactNode;
}

const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            padding: 24,
            lineHeight: '20px',
            fontSize: 18,
            fontWeight: 700,
        },
    },
    content: {
        '& .MuiDialogContent-root': {
            backgroundColor: Colors.SmokeBackground,
            padding: 24,
        },
    },
}));

const AlertDialog: React.FC<AlertDialogPropsType> = (props) => {
    const classes = useStyles();
    const { description, className } = props;
    return (
        <DialogContainer
            {...props}
            className={classNames(classes.content, classes.header, className)}
        >
            <DialogContentText>{description}</DialogContentText>
        </DialogContainer>
    );
};

export default AlertDialog;

import React from 'react';

import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import CustomButton from '../../Button/Button';
import Loader from "../../Loader/Loader";
import PasswordTextField from "../../PasswordTextField/PasswordTextField";
import * as Yup from "yup";
import {PasswordExpirationPros, useStyles} from "./ChangePasswordExpirationForm.utils";

const ChangePasswordExpirationForm: React.FC<PasswordExpirationPros> = (
    {
        onSubmitAction,
        login,
        oldPassword,
        apiErrorsExist,
        inProgress
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const formik = useFormik({
        initialValues: {
            login: login,
            newPassword: '',
            oldPassword: oldPassword,
        },
        validateOnChange: false,
        validationSchema: () => {
            return Yup.object().shape({
                newPassword: Yup.string().required().test(
                    'newPassDiffThenNewPass',
                    t("screens:signIn.newSameAsOldError"),
                    (item, context) => {
                        return context.parent.newPassword !== context.parent.oldPassword
                    }
                ),
                login: Yup.string().required(),
                oldPassword: Yup.string().required()
            })
        },
        onSubmit: (values) => {
            const payload = {
                login: values.login,
                password: values.oldPassword,
                new_password: values.newPassword,
            };

            onSubmitAction(payload);
        },
    });

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            data-testid={'password-expiration-form'}
        >
            <div className={classes.inputs}>
                <Grid item>
                    <PasswordTextField
                        id={"newPassword"}
                        dataQa={'change-password-filed'}
                        label={t('screens:signIn.newPasswordLabel')}
                        isRequired={true}
                        onChange={formik.handleChange}
                        value={formik.values.newPassword.trim()}
                        setFieldError={formik.setFieldError}
                        maxLength={32}
                        autoComplete={'off'}
                        error={apiErrorsExist ?
                            t('screens:signIn.changePasswordCommonError') :
                            formik.errors.newPassword ?? ''
                        }
                    />
                </Grid>
            </div>
            <div className={classes.action}>
                <CustomButton
                    dataQa="signin-submit-button"
                    primary
                    type="submit"
                    className={classes.submitButton}
                    containerClassName={classes.submitButtonContainer}
                    disabled={!formik.dirty}
                >
                    {inProgress ? (
                        <Loader dataQa={'sign-in-progress'} absolutePosition={true}/>
                    ) : (
                        t('screens:signIn.loginButton')
                    )}
                </CustomButton>
            </div>
        </form>
    );
};

export default ChangePasswordExpirationForm;

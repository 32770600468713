import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CallingPartyDisplay,
    TransportProtocol,
} from '../../../../store/types/AccountFollowMeSettings';
import { ForwardModeFlag } from '../../../../store/types/ServiceFeature';
import Checkbox from '../../../Checkbox/Checkbox';
import SelectField from '../../../SelectField/SelectField';
import CustomizedTextField from '../../../TextField/TextField';
import { useStyles } from './CallForwardingForm.utils';
import { FollowRuleFormProps } from './FollowRuleForm.utils';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';

const FollowRuleForm: React.VFC<FollowRuleFormProps> = ({
    setFieldValue,
    setFieldError,
    rule,
    errors,
    permittedSipProxies,
    mode,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const callingPartyDisplayOptions = Object.values(CallingPartyDisplay).map(
        (v) => ({
            name: t(`enums:callerPartyDisplay.${v}`),
            value: v,
        }),
    );

    return (
        <div className={classes.ruleContainer}>
            <div className={classes.row}>
                <CustomizedTextField
                    className={classNames(
                        classes.phoneNumberInput,
                        classes.controlMargin,
                    )}
                    id="number"
                    label={t('screens:extensions.phoneNumber')}
                    onChange={(e) => setFieldValue('number', e.target.value)}
                    value={rule?.number}
                    dataQa="forward-to-input"
                    helperText={errors?.number}
                    setFieldError={setFieldError}
                    required={!rule?.sipProxy}
                    maxLength={64}
                    fixedLengthCounter
                />

                {(mode === ForwardModeFlag.ForwardToSIPUri ||
                    mode === ForwardModeFlag.AdvancedForwarding) && (
                    <>
                        <div className={classes.sipContainer}>
                            <SelectField
                                label={t('screens:extensions.sipProxy')}
                                items={permittedSipProxies ?? ['']}
                                value={'@ ' + (rule?.sipProxy ?? '')}
                                dataQa="sip-forwarding-proxy-select"
                                onChange={(_, value) => {
                                    setFieldValue('sipProxy', value);
                                    setFieldError('sipUriNumber', undefined);

                                    if (!value) {
                                        setFieldValue(
                                            'transportProtocol',
                                            TransportProtocol.UDP,
                                        );
                                    }
                                }}
                                className={classes.sipProxyInput}
                                classes={{
                                    container: classNames(
                                        classes.sipProxyInput,
                                        classes.controlMargin,
                                    ),
                                }}
                                disableAutoSettingValue
                                disableClearable
                                required={!rule?.number}
                                getOptionSelected={(item, value) =>
                                    (value?.includes?.(item) && item !== '') ||
                                    (item === '' && value === '@ ')
                                }
                            />
                            {!rule?.sipProxy && (
                                <span className={classes.sipHint}>
                                    {t('screens:extensions.sipProxyHint')}
                                </span>
                            )}
                        </div>

                        <SelectField
                            dataQa="transport-protocol-select"
                            label={t(
                                'screens:advancedSettings.transportProtocol',
                            )}
                            items={Object.values(TransportProtocol)}
                            value={rule?.transportProtocol}
                            onChange={(_, value) =>
                                setFieldValue('transportProtocol', value)
                            }
                            className={classes.transportProtocolSelect}
                            classes={{
                                container: classNames(
                                    classes.transportProtocolSelect,
                                    classes.controlMargin,
                                ),
                            }}
                            disabled={!rule?.sipProxy}
                            disableClearable
                        />
                    </>
                )}

                <CustomizedTextField
                    id="ringFor"
                    label={t('screens:extensions.timeout')}
                    onChange={(e) => setFieldValue('ringFor', e.target.value)}
                    value={rule?.ringFor}
                    type="number"
                    icon={<span className={classes.numberLabel}>sec</span>}
                    iconPosition="end"
                    className={classes.timeoutInput}
                    dataQa="follow-me-timeout-input"
                    inputProps={{
                        inputProps: {
                            min: 1,
                            max: 999,
                        },
                        pattern: '[0-9]*',
                    }}
                />
            </div>

            <div className={classes.secondRow}>
                <SelectField
                    dataQa="calling-party-display-select"
                    label={t('screens:extensions.callingPartyDisplay')}
                    items={callingPartyDisplayOptions}
                    value={callingPartyDisplayOptions.find(
                        (v) => v.value === rule?.callingPartyDisplay,
                    )}
                    getOptionLabel={(v: { name: string; value: string }) =>
                        v.name
                    }
                    getOptionSelected={(v: { name: string; value: string }) =>
                        v.value === rule.callingPartyDisplay
                    }
                    onChange={(_, value) =>
                        setFieldValue('callingPartyDisplay', value.value)
                    }
                    className={classes.callingPartySelect}
                    classes={{ container: classes.callingPartySelect }}
                    disableClearable
                />
            </div>

            {mode !== ForwardModeFlag.FollowMe && (
                <div className={classes.horizontalAlign}>
                    <Checkbox
                        dataQa="keep-original-checkbox"
                        checked={rule?.keepOriginalCld}
                        onChange={(e) =>
                            setFieldValue('keepOriginalCld', e.target.checked)
                        }
                        label={t('screens:advancedSettings.keepOriginalCld')}
                    />

                    <IconWithTooltip
                        dataQa={'keep-orginal-tooltip'}
                        tooltipText={t('tooltips:extensions.forwardingCall')}
                        copy={false}
                    />
                </div>
            )}
        </div>
    );
};

export default FollowRuleForm;

import { Badge } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { getRingScheduleSummary } from '../../../../utils/extensions/RingScheduleSummary';
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import { useStyles } from './CallForwardingForm.utils';
import {IntervalFormProps, IntervalStatus} from "../../../IntervalSelect/IntervalSelect.utils";

type IntervalCellProps = {
    intervals?: IntervalFormProps;
};

const IntervalCell: React.VFC<IntervalCellProps> = ({ intervals }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    if (intervals?.activity === IntervalStatus.Always) {
        return (
            <span className={classes.intervalText}>
                {t('screens:ringSchedule.always')}
            </span>
        );
    }

    let value = [''];
    if (intervals) {
        value = getRingScheduleSummary(
            intervals,
            outTimeFormat?.includes('AM'),
        ).split(' or ');
    }

    return (
        <>
            <OverflowTooltip
                classes={{
                    text: classes.intervalText,
                }}
                tooltip={value[0]}
                text={<span>{value[0]}</span>}
                copy={false}
            />
            {value.length > 1 && (
                <CustomizedTooltip
                    title={value.join(' or ')}
                    dataQa={'tooltip-menu-list'}
                    interactive={true}
                    copy={false}
                >
                    <Badge
                        badgeContent={value.length}
                        className={classes.badge}
                        data-qa="badge-intervals"
                        data-testid="badge-intervals"
                    />
                </CustomizedTooltip>
            )}
        </>
    );
};

export default IntervalCell;

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
const requiredFieldError = i18n.t('errors:common.emptyInput');
export const duplication = i18n.t('errors:ringGroups.duplication');

export const initialValues = {
    musicFile: '',
    fileName: '',
    file: null,
};

export type MusicUpdateFormProps = {
    musicFile: string;
    fileName: string;
    file: File | null;
};

export type UploadFileProps = {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    onSave?: (file: File, name: string, callback: () => void) => void;
    items: { name: string; value: string | number | null }[];
};

export const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        padding: '47px 76px 55px 76px',
        marginTop: 11,
        maxWidth: 876,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            '& div': {
                '& div': {
                    height: 'unset',
                    marginBottom: 0,
                },
            },
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
        marginBottom: 0,
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 20,

        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            color: Colors.Text,
            fontSize: 14,
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        paddingTop: 30,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        fontSize: 14,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
        '& div': {
            maxWidth: 522,
        },
    },
    columnItemsContainer: {
        flexDirection: 'column',

        '& .MuiFormControl-root:first-of-type': {
            marginRight: '15px !important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        maxWidth: 964,
        '& .MuiFormControl-root': {
            flex: 1,
        },
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
            width: 640,
        },
        '& .MuiGrid-item': {
            margin: 0,
        },
    },
    file: {
        minHeight: 56,
        height: 'unset',
        width: '100% !important',

        '& .MuiFormHelperText-root.Mui-error': {
            color: Colors.Text,
            borderColor: `${Colors.Error}!important`,
            '& span': {
                marginLeft: 12,
                color: `${Colors.Error} !important`,
            },
        },
    },
    fileRow: {
        display: 'flex',
        alignItems: 'flex-start',
        marginRight: '0 !important',
        marginBottom: 21,
    },
    fileBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 358,
        height: 56,
        flex: 1,
    },
    uploadMargin: {
        marginLeft: 24,
        marginTop: 8,
        borderRadius: 4,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    descriptionFormats: {
        fontSize: 12,
        marginLeft: 16,
        marginTop: 8,
        color: Colors.Gray5,
    },
    actionButtonDisabled: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
    saveButtonDisabled: {
        width: 96,
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: Colors.Gray10 + '!important',
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    saveButton: {
        width: 96,
        background: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: Colors.White,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    option: {
        height: 47,
    },
    switcher: {
        marginBottom: '0 !important',
        height: 'unset !important',
        maxWidth: 185,
    },
}));

export const UploadFileValidationSchema = Yup.object().shape({
    file: Yup.mixed().required(requiredFieldError),
    fileName: Yup.string()
        .required(requiredFieldError)
        .test('duplication', duplication, function (value, x) {
            const result = this?.options?.context?.items.find(
                (i: any) => i.name !== 'Upload new file' && i.name === value,
            );
            return !result;
        }),
});

import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import CustomizedTextField, {
    CustomTextFieldProps,
} from '../TextField/TextField';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

type TextFieldWithMaskProps = {
    name?: string;
    id?: string;
    value?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    placeholder?: string;
    decimalScale?: number;
    required?: boolean;
    helperText?: string;
    iconPosition?: string;
    MuiInputProps?: Partial<CustomTextFieldProps>;
    min?: number;
    max?: number;
    label?: string;
    thousandSeparator?: string;
    setFieldError?: (field: string, value: any) => void;
    setFieldValue?: (field: string, value: any) => void;
    disabled?: boolean;
    dataQa?: string;
    icon?: React.ReactNode;
    maxLength?: number;
    type?: string;
    arrows?: boolean;
    skipPermission?:boolean;
};

export const useStyles = makeStyles(() => ({
    arrow: {
        height: 10,
        width: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            fill: Colors.Gray5,
            height: 18,
        },
        '&:hover': {
            backgroundColor: Colors.Gray4,
        },
    },
    arrowContainer: {
        position: 'absolute',
        right: 22,
        top: 20,
    },
}));

const TextFieldMiddleware = (
    params: any & { muiInputProps: Partial<CustomTextFieldProps> },
) => {
    const { muiInputProps, ...otherParams } = params;
    return <CustomizedTextField {...otherParams} {...muiInputProps} />;
};

const NumericTextField: React.FC<TextFieldWithMaskProps> = ({
    name,
    id,
    value,
    onChange,
    className,
    placeholder,
    decimalScale,
    MuiInputProps,
    min,
    max,
    label,
    thousandSeparator,
    required,
    setFieldError,
    setFieldValue,
    disabled,
    helperText,
    dataQa,
    icon,
    maxLength,
    type,
    arrows,
    skipPermission
}) => {
    const classes = useStyles();
    let permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    permission = skipPermission ? PermissionType.Visible : permission;

    const withValueLimit = ({ floatValue }: NumberFormatValues) => {
        let isValid = true;

        if (max && floatValue) {
            isValid = floatValue <= max;
        }
        return isValid;
    };

    const changeValueInAmount = useCallback(
        (diff) => {
            const v = parseFloat(value?.replace(/,/g, ''));

            if (v + diff > 0) {
                !!id && setFieldValue?.(id, (v + diff).toString());
            }
        },
        [value],
    );

    return (
        <div className={classNames(className)} data-qa={dataQa}>
            <FormControl>
                <NumberFormat
                    value={value}
                    onChange={(e) => {
                        onChange?.(e);
                        !!id && setFieldError?.(id, undefined);
                    }}
                    name={name}
                    id={id}
                    customInput={TextFieldMiddleware}
                    placeholder={placeholder}
                    decimalSeparator="."
                    thousandSeparator={thousandSeparator}
                    decimalScale={decimalScale}
                    allowNegative={false}
                    muiInputProps={MuiInputProps}
                    isNumericString={true}
                    isAllowed={withValueLimit}
                    fixedDecimalScale={value !== ''}
                    label={label}
                    required={required}
                    min={min}
                    max={max}
                    disabled={disabled}
                    readOnly={!disabled && (permission === PermissionType.ReadOnly)}
                    helperText={helperText}
                    icon={icon}
                    iconPosition="end"
                    maxLength={maxLength}
                    //@ts-ignore
                    type={type}
                    skipPermission
                />

                {arrows && (
                    <div className={classes.arrowContainer}>
                        <div
                            className={classes.arrow}
                            onClick={() => changeValueInAmount(1)}
                        >
                            <ArrowDropUp />
                        </div>
                        <div
                            className={classes.arrow}
                            onClick={() => changeValueInAmount(-1)}
                        >
                            <ArrowDropDown />
                        </div>
                    </div>
                )}
            </FormControl>
        </div>
    );
};

export default NumericTextField;

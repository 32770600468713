import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {UserInput} from '../../../store/types/AutoAttendant';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Colors} from '../../../styles/Colors';
import {MenuActionFormType} from '../../Forms/AutoAttendantsMenus/ActionsForm.utils';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import {getTransitionMenuLabel} from '../../../utils/autoAttendants/getTransitionMenuLabel';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {availableListForDialExtensionDirectly} from '../MenuActionDialog';
import { usePermissionContext } from '../../../hooks/usePermissions';
import { PermissionType } from '../../../store/types/Permission';
import { ServiceFeatureName } from '../../../store/types/ServiceFeature';

const useStyles = makeStyles(() => ({
    rowContainer: {
        display: 'flex',
    },
    titleContainer: {
        width: 150,
    },
    roundInput: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 40,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 44,
        marginBottom: 4,
        marginRight: 4,
        color: Colors.Text,
        fontWeight: 500,
        cursor: 'pointer',
        fontSize: '16px',
        '&:hover': {
            opacity: 0.6,
        },

        '& span': {
            lineHeight: 'initial',
            textAlign: 'center'
        }
    },
    rectangleInput: {
        width: 118,
    },
    selected: {
        color: Colors.White,
        background: Colors.Secondary1,
        opacity: '1!important',
    },
    disabled: {
        opacity: 0.5,
    },
}));

type DialingButtonProps = {
    selectedValue: UserInput | null;
    value: UserInput;
    onChange: (value: UserInput) => void;
    wide?: boolean;
    menuActions: MenuActionFormType[];
    isDialExtensionDirectly?: boolean;
};

const DialingButton: React.VFC<DialingButtonProps> = (
    {
        value,
        selectedValue,
        onChange,
        wide,
        menuActions,
        isDialExtensionDirectly,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const permission = usePermissionContext();

    const email = useSelector<ReduxState, string>(
        (state) =>
            state.autoAttendants?.incomingTab?.emailAddressIncoming || '',
    );

    const usedAction = useMemo(
        () =>
            menuActions?.find(
                (v) => v.userInput === value && v.userInput !== selectedValue,
            ),
        [menuActions, value],
    );

    const disabledNonDigits = useMemo(
        () =>
            isDialExtensionDirectly &&
            !availableListForDialExtensionDirectly.includes(value),
        [isDialExtensionDirectly, value],
    );

    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
            ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    const {title, description} = getTransitionMenuLabel(unifiedMessagingVoicemailEnabled, usedAction, email);

    const onClick = useCallback(() => {
        if(permission === PermissionType.ReadOnly) {
            return;
        }
        !usedAction && !disabledNonDigits && onChange?.(value);
    }, [disabledNonDigits, usedAction, onChange]);

    return (
        <CustomizedTooltip
            copy={false}
            title={
                permission === PermissionType.ReadOnly
                    ? t('common:noPermissionToResource')
                    : t(`screens:autoAttendants.userInputUsedFor`, {
                    destination: `${title} ${description}`,
            })}
            disableHoverListener={!usedAction && permission !== PermissionType.ReadOnly}
            data-qa={`dial-pad-${t(`enums:userInput.${value}`)}-tooltip`}
        >
            <div
                className={classNames(
                    classes.roundInput,
                    wide && classes.rectangleInput,
                    (!!usedAction || permission === PermissionType.ReadOnly) && classes.disabled,
                    disabledNonDigits && classes.disabled,
                    selectedValue === value && classes.selected,
                )}
                onClick={onClick}
                data-qa={`dial-pad-${t(`enums:userInput.${value}`)}`}
            >
                {
                    <span>{t(`enums:userInput.${value}`)}</span>
                }
            </div>
        </CustomizedTooltip>
    );
};

export default DialingButton;

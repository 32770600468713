import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputParserHelper from './InputParserHelper';
import { AdditionalElement } from '../../utils/transformers';
type InputParserProps = {
    inputValues: AdditionalElement[];
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void;
    values: any;
    setFieldError: (field: string, errorMsg?: string) => void;
    errors: any;
    readOnly?: boolean;
};

const useStyles = makeStyles(() => ({
    additionalDetails: {
        marginTop: 24,
        display: 'grid',
        gridTemplateColumns: '440px 440px',
        columnGap: 32,
        rowGap: 24,
        '& div': {
            width: '100%',
            '& .MuiFormControl-root': {
                '& .MuiInputBase-root': {
                    '& .MuiInputBase-input': {
                        minWidth: '85%',
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        display: 'flex',
                        justifyContent: 'flex-end',
                    },
                },
            },
        },

        '& .character-counter': {
            top: 'unset !important',
        },
    },
}));
const InputsParser: React.FC<InputParserProps> = React.memo(
    ({ inputValues = [], setFieldValue, values, setFieldError, errors, readOnly = false }) => {
        const classes = useStyles();

        return (
            <div className={classes.additionalDetails} key={'input-wrapper'}>
                {inputValues.map((element, index) => {
                    const property = element.properties
                        ? JSON.parse(element.properties)
                        : {};
                    return (
                        <InputParserHelper
                            element={element}
                            property={property}
                            setFieldValue={setFieldValue}
                            values={values}
                            setFieldError={setFieldError}
                            errors={errors}
                            index={index}
                            key={`${index}-input-parser-helper`}
                            readOnly={readOnly}
                        />
                    );
                })}
            </div>
        );
    },
);

export default InputsParser;

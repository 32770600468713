export const getInitials = (name: string, maxLength = 3) => {
    const chars = [...name.trim()];

    if (name.length <= maxLength) return name;

    const initials: string[] = [];

    for (const [idx, char] of chars.entries()) {
        if (
            char.toLowerCase() !== char ||
            !chars[idx - 1] ||
            /\s/.test(chars[idx - 1])
        ) {
            initials.push(char);

            if (initials.length === maxLength) break;
        }
    }

    return initials.join('');
};

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    mainContainer: {
        padding: '12px 18px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        maxWidth: 280,
        '&:hover': {
            backgroundColor: Colors.SmokeBackground,
            //maxWidth: 0,
            //width: 'fit-content'
        },
        borderRadius: 5,
    },
    containerSpan: {
        justifyContent: 'left',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        color: Colors.Secondary1,
        fontWeight: 500,
        fontSize: 16,
        marginLeft: 16,
    },
    disabled: {
        '& svg': {
            '& path': {
                fill: `${Colors.Gray9}!important`,
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    readOnlyTitle: {
        color: `${Colors.Gray9}!important`,
    },
}));

export type PanelButtonProps = {
    title: string;
    icon?: React.ReactNode;
    onPress?: () => void;
};

const PanelButton: React.VFC<PanelButtonProps> = ({ title, icon, onPress }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    const isReadOnly = permission === PermissionType.ReadOnly;

    return (
        <CustomizedTooltip
            disableHoverListener={!isReadOnly}
            title={t('common:noPermissionToResource')}
            copy={false}
        >
            <div
                className={classNames(
                    classes.mainContainer,
                    isReadOnly && classes.disabled,
                )}
                onClick={isReadOnly ? undefined : onPress}
            >
                <div>{icon}</div>
                <div className={classNames(
                        classes.containerSpan,
                        classes.title,
                        isReadOnly && classes.readOnlyTitle,
                    )}
                >
                    {title}
                </div>
            </div>
        </CustomizedTooltip>
    );
};

export default PanelButton;

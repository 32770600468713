import dagre from 'dagre';
import { isNode } from 'react-flow-renderer';
import { FlowElements } from './types';

const nodeWidth = 300;
const nodeHeight = 52;

export const getLayoutedElements = (
    elements: FlowElements,
    direction = 'LR',
) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({ rankdir: direction, align: 'UL', marginy: 100 });

    const children: { id: string; width: number; height: number }[] = [];
    const edges: { id: string; sources: string[]; targets: string[] }[] = [];

    elements.forEach((el) => {
        if (isNode(el)) {
            const customNodeWidth = el.type === 'hoursNode' ? 100 : nodeWidth;

            dagreGraph.setNode(el.id, {
                width: customNodeWidth,
                height: nodeHeight,
            });

            children.push({
                id: el.id,
                width: nodeWidth,
                height: nodeHeight,
            });
        } else {
            dagreGraph.setEdge(el.source, el.target);
            edges.push({
                id: el.id,
                sources: [el.source],
                targets: [el.target],
            });
        }
    });

    dagre.layout(dagreGraph);

    return elements.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);

            let customNodeWidth = el.type === 'hoursNode' ? 70 : nodeWidth;
            customNodeWidth =
                el.type === 'menuNode' && !el.data?.transition
                    ? 150
                    : customNodeWidth;
            customNodeWidth = el.type === 'startNode' ? 20 : customNodeWidth;

            if (nodeWithPosition && nodeWithPosition.x && nodeWithPosition.y) {
                el.position = {
                    x: nodeWithPosition.x - customNodeWidth / 2,
                    y: nodeWithPosition.y,
                };
            }
        }

        return el;
    });
};

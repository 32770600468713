import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import DateTimeCell from '../../../components/Calls/DateTimeCell';
import CallerInfo from '../../../components/Calls/CallerInfo';

const additionalMargin = '5%';

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 1000,
            backgroundColor: Colors.SmokeBackground,
            padding: '32px 32px 22px 32px',
            overflowX: 'hidden'
        },

        '& .MuiDialogActions-root': {
            padding: '8px 32px',
        },

        '& .MuiTypography-h6': {
            fontWeight: 700,
        },
    },

    button: {
        marginLeft: 1,
        '& .MuiButton-label': {
            color: Colors.White,
        },
        marginTop: 10,
    },

    table: {
        marginTop: 30,

        '& tbody tr.MuiTableRow-root': {
            borderBottom: `1px solid ${Colors.Gray4}`,
        },

        '& tr th.MuiTableCell-root.MuiTableCell-head:last-child': {
            textAlign: 'right',
        },

        '& thead tr th.MuiTableCell-root': {
            padding: '17px 16px !important',
        },

        '& tr.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:first-child': {
            paddingLeft: additionalMargin,
        },
        '& tr.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:last-child': {
            paddingRight: additionalMargin,
        },

        '& thead tr.MuiTableRow-root th.MuiTableCell-root.MuiTableCell-head:first-child': {
            paddingLeft: `${additionalMargin} !important`,
        },

        '& thead tr.MuiTableRow-root th.MuiTableCell-root.MuiTableCell-head:last-child': {
            paddingRight: `${additionalMargin} !important`,
        },

        '& .MuiTableHead-root': {
            '& .MuiTableRow-root': {
                minHeight: 64,
            },
        },

        '& .MuiTableFooter-root': {
            '& .MuiTablePagination-root': {
                maxHeight: 64,
            },
        },
    },
    pagination: {
        color: Colors.Gray,
    },

    dataGrid: {},

    itemsContainer: {
        padding: '4px 18px 25px 0',
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiFormControl-root': {
            marginRight: 15,
        },
    },

    chargeValueToRight: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
    },
    centeredRecordingValue: {
        paddingLeft: '0!important',
        marginLeft: 20,
    },
    headerCell: {
        fontSize: 16,
    },
    timePicker: {
        '& .MuiFormLabel-root': {
            marginLeft: 16,
        },
    },
}));

export interface FaxTransmissionListItem {
    id: number;
    connect_time: string;
    CLI: string;
    CLD: string;
    charged_amount: number;
}

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    timezoneOffset: number,
    customerCurrency: string,
    userDateTimeFormat?: string,
): Column<FaxTransmissionListItem>[] => {
    return [
        {
            accessor: 'connect_time',
            Header: t<string>('screens:calls.connectTime'),
            width: 0.9,
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
        },
        {
            accessor: 'CLI',
            Header: t<string>('screens:calls.sender'),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <CallerInfo
                        caller={params.row.original.CLI!}
                        headerClassName={classes.headerCell}
                    />
                );
            },
        },
        {
            accessor: 'CLD',
            Header: t<string>('screens:calls.recipient'),
            width: 1,
            Cell: function Cell(params) {
                return (
                    <CallerInfo
                        caller={params.row.original.CLD!}
                        headerClassName={classes.headerCell}
                    />
                );
            },
        },
        {
            accessor: 'charged_amount',
            Header:
                t('screens:calls.charge') +
                (customerCurrency ? `, ${customerCurrency}` : ''),
            width: 0.6,

            Cell: function Cell(params) {
                return (
                    <div className={classes.chargeValueToRight}>
                        <span>
                            {params.row.original.charged_amount?.toFixed(2)}
                        </span>
                    </div>
                );
            },
        },
    ];
};

import { UAPortConfiguration } from '../../store/types/Devices';
import React from 'react';
import EmptyRowContent from '../../components/DataTable/EmptyRowContent';

type AssignedExtensionsCellProps = {
    portConfig: UAPortConfiguration[];
};

export const dataTestId = 'assigned-extensions';
const AssignedExtensionsCell: React.FC<AssignedExtensionsCellProps> = ({
    portConfig,
}) => {
    const ext = portConfig
        .filter((c: UAPortConfiguration) => c.extension_id != undefined)
        .map((c: UAPortConfiguration) => c.extension_id);

    if (!ext.length) {
        return <EmptyRowContent />;
    }

    const extString = ext.toString().replace(/,/g, ', ');
    return <span data-testid={dataTestId}>{extString}</span>;
};

export default AssignedExtensionsCell;

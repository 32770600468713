import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import { Cancel } from '@material-ui/icons';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next/';

type ChipProps = {
    label: string;
    handleDelete?: (label: string) => void;
    dataQa?: string;
    skipPermission?: boolean;
    customClasses?: {
        root?: string,
        chip?: string,
    }
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& > *': {
                marginRight: 6,
                marginLeft: 5,
            },

            '& .MuiChip-root': {
                height: 22,
                backgroundColor: Colors.LightGraySecondary4,
                '& .MuiChip-label': {
                    paddingLeft: 5,
                    color: Colors.Text,
                    fontWeight: 400,
                },
                '& .MuiSvgIcon-root': {
                    marginLeft: 5,
                    '& path': {
                        fill: Colors.Gray9,
                    },
                },
                '& .MuiSvgIcon-root:hover': {
                    '& path': {
                        fill: Colors.Gray10,
                    },
                },
                '& .MuiChip-deleteIcon': {
                    marginRight: 5,
                    height: 16,
                    width: 16,
                }
            },
        },

        disabled: {
            '& .MuiChip-root': {
                height: 22,
                backgroundColor: Colors.SignInRootBackground,
                '& .MuiChip-label': {
                    color: Colors.Gray5,
                },
            },
        },

        deleteIcon: {
            marginRight: 5,
            height: 16,
            width: 16
        }
    }),
);

const CustomizedChip: React.FC<ChipProps> = ({
    label,
    handleDelete,
    dataQa,
    skipPermission,
    customClasses
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const permission = skipPermission ? PermissionType.Visible : usePermissionContext();

    const internalDelete = () => {
        if(permission === PermissionType.Visible && handleDelete) {
            handleDelete(label);
        }
    };

    return (
        <div
            className={classNames(
                classes.root,
                !handleDelete && classes.disabled,
                customClasses?.root
            )}
        >
            <Chip
                size="small"
                label={label}
                onDelete={internalDelete}
                className={customClasses?.chip}
                deleteIcon={
                    <CustomizedTooltip
                        copy={false}
                        title={t('common:noPermissionToResource')}
                        disableHoverListener={permission === PermissionType.Visible}
                        data-qa={`chip-disabled-tooltip`}
                    >
                        <Cancel htmlColor={Colors.Gray5} className={classes.deleteIcon} onClick={internalDelete} />
                    </CustomizedTooltip>
                }
                data-qa={dataQa}
            />
        </div>
    );
};

export default CustomizedChip;

import { Grid, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Colors } from '../../styles/Colors';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SoundPlayer from '../SoundPlayer/SoundPlayer';

export type FileSelectProps = {
    onDelete?: () => void;
    onFileChange?: (file: File) => void;
    nameValue?: string;
    file?: File | null;
    getFileOnPlay?: () => void;
    classes?: { textField?: string };
    icon?: boolean;
    readOnly?: boolean;
    customLabel?: string;
    required?: boolean;
    helperText?: string;
};

const useStyles = makeStyles(() => ({
    selectMaxWidth: {
        width: 475,
        maxWidth: 475,
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    playBeforeGrid: {
        margin: '10px 0',
    },
    uploadMargin: {
        marginLeft: 24,
        marginRight: 10,
        width: 90,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    fileGrid: {
        marginBottom: 20,
    },
}));

const FileSelect: React.VFC<FileSelectProps> = ({
    onDelete,
    onFileChange,
    nameValue,
    file,
    getFileOnPlay,
    classes: customClasses,
    icon,
    customLabel,
    required,
    helperText,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const fileRef = useRef<HTMLInputElement>(null);

    return (
        <Grid className={classNames(classes.centeredGrid, classes.fileGrid)}>
            <input
                type="file"
                id="recordedFile"
                accept={'audio/*'}
                ref={fileRef}
                data-testid="file-upload"
                style={{ display: 'none' }}
                onChange={(event) => {
                    event?.stopPropagation();
                    event?.preventDefault();
                    if (event?.target?.files?.[0]) {
                        onFileChange?.(event?.target?.files[0]);
                    }
                }}
            />

            <TextField
                id="file"
                label={customLabel ?? t('screens:extensions.file')}
                value={nameValue}
                icon={
                    icon && (
                        <SoundPlayer
                            file={file}
                            getFileOnPlay={getFileOnPlay}
                            fileName={nameValue}
                            disabled
                        />
                    )
                }
                iconPosition="end"
                dataQa="extension-recorded-name-input"
                readOnly
                className={classNames(
                    classes.selectMaxWidth,
                    customClasses?.textField,
                )}
                required={required}
                helperText={helperText}
            />

            <Button
                dataQa="upload-button"
                primary
                accent
                className={classes.uploadMargin}
                onClick={() => fileRef.current?.click()}
            >
                {t('screens:extensions.upload')}
            </Button>

            {onDelete && (
                <IconButton
                    onClick={onDelete}
                    data-qa="delete-file-button"
                    data-testid="delete-file-button"
                >
                    <Delete />
                </IconButton>
            )}
        </Grid>
    );
};

export default FileSelect;

import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import EmptyRowContent from '../DataTable/EmptyRowContent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

interface NameEmailCellInterface {
    name?: string;
    email?: string;
}

const useStyle = makeStyles(() => ({
    root: { display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    email: {
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 400,
        color: Colors.Gray5,
        display: 'block',
    },
}));

export const QA_ATR_VAL = 'name-email-cell-container';

const NameEmailCell: React.VFC<NameEmailCellInterface> = ({ name, email }) => {
    const classes = useStyle();

    if (name || email) {
        return (
            <div
                className={classes.root}
                data-qa={QA_ATR_VAL}
                data-testid={QA_ATR_VAL}
            >
                <div>
                    <OverflowTooltip tooltip={name} text={name} dataQa={"name"}/>
                </div>
                <div>
                    <OverflowTooltip
                        tooltip={email}
                        text={email}
                        classes={{ text: classes.email }}
                        dataQa={"email"}
                    />
                </div>
            </div>
        );
    }

    return <EmptyRowContent />;
};

export default NameEmailCell;

import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import {
    AddonsValidationResults,
    CustomerAgreementCondition,
} from '../../../types/CustomerAgreementCondition';

export interface PlanReducerState {
    products?: {
        mainProducts: CustomerAgreementCondition[];
        addonsProducts: CustomerAgreementCondition[];
        addonsConflicts?: AddonsValidationResults;
    };
}

export const initialState: PlanReducerState = {};

export type PlanReducerActions = ActionType<typeof actions>;

export const planReducer = createReducer<PlanReducerState, PlanReducerActions>(
    initialState,
)
    .handleAction(actions.getExtensionProducts.success, (state, action) => ({
        ...state,
        products: {
            addonsConflicts: undefined,
            ...action.payload,
        },
    }))
    .handleAction(actions.validateAddonsProducts.success, (state, action) => ({
        ...state,
        products: {
            mainProducts: state.products?.mainProducts || [],
            addonsProducts: state.products?.addonsProducts || [],
            addonsConflicts: action.payload,
        },
    }));

export default planReducer;

import * as Yup from 'yup';
import { ExtensionsListItem } from '../../store/reducers/extensions/extensions/reducer';
import { RingGroupType } from '../../store/types/RingGroup';
import i18n from 'i18next';

const max999 = i18n.t('errors:ringGroups.max999');

export const ringGroupMemberFormValidationSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    groupMemberType: Yup.string().required(),
    ignoreFollowMeVoiceMail: Yup.bool().required(),
    setAsPrimary: Yup.bool().required(),
    member: Yup.object().required(),
    dispatchType: Yup.number().required(),
    customWaitsFor: Yup.number().max(999, max999).notRequired(),
    customThenRingsFor: Yup.number().max(999, max999).notRequired(),
});

export interface RingGroupMemberDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    ringGroups?: RingGroupType[];
    extensions?: ExtensionsListItem[];
    onSave?: (form: RingGroupMemberFormProps) => void;
    objectToEdit?: RingGroupMemberFormProps;
    currentMembers?: RingGroupMemberFormProps[];
    i_c_group?: number;
}

export enum GroupMemberType {
    Extension = '0',
    RingGroup = '1',
}

export enum DispatchType {
    PermanentlyEnabled = 0,
    Custom = 1,
    Disabled = 2,
}

export type RingGroupMemberFormProps = {
    id?: string;
    groupMemberType: GroupMemberType;
    ignoreFollowMeVoiceMail: boolean;
    setAsPrimary: boolean;
    ringGroup?: Partial<RingGroupType>;
    extension?: Partial<ExtensionsListItem>;
    dispatchType?: DispatchType;
    customWaitsFor?: number | string;
    customThenRingsFor?: number | string;
    supervisionAllowed?: 'Y' | 'N';
    ringing?: boolean;
    supervisor?: boolean;
    allowGroupPaging?: boolean;
    member?: Partial<RingGroupType> | Partial<ExtensionsListItem>;
};

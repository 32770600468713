import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../store/types/ServiceFeature';

export const getServiceFeatureValue = (
    serviceName: ServiceFeatureName,
    serviceFeatures?: ServiceFeature[],
    attributeName?: string,
) => {
    const value = serviceFeatures?.find((v) => v.name === serviceName);

    if (attributeName) {
        return value?.attributes.find((v) => v.name === attributeName)
            ?.effective_values[0];
    }

    return value?.effective_flag_value;
};

export const getServiceFeatureLockedStatus = (
    serviceName: ServiceFeatureName,
    serviceFeatures?: ServiceFeature[],
) => {
    return serviceFeatures?.find((v) => v.name === serviceName)?.locked === 1;
};

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    header: {
        fontWeight: 500,
        color: Colors.Text,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    noMethodLabel: {
        marginTop: 22,
        whiteSpace: 'nowrap',
        width: 'fit-content',
        maxWidth: 'unset',
        paddingRight: 28
    },
    paymentMethodContainer: {
        display: 'flex',
        marginTop: 22,
        alignItems: 'center',
    },
    methodInsidecontainer: {
        width: 500,
    },
    secondIcon: {
        marginLeft: 20,
    },
    removeDialogContainer: {
        '& .MuiDialogContent-root': {
            width: 240,
            minWidth: 240,
            backgroundColor: Colors.White,
        },
    },
}));

import { YesNo } from './CallScreening';

export interface GetProviderInfoRequest {
    did_provider_info: CustomerDIDProviderIdent;
}

export interface CustomerDIDProviderIdent {
    customer_batch?: number;
    i_customer?: number;
    reseller_batch?: number;
    i_did_provider?: number;
}

export interface GetProviderInfoResponse {
    provider_info: DIDProviderInfo;
}

export interface DIDProviderInfo {
    i_did_provider: number;
    is_numbers_list_available: number;
    name: string;
    provider_currency: string;
}

export interface GetDIDCountriesListRequest {
    did_provider_info: CustomerDIDProviderIdent;
    for_idle_numbers_only: YesNo;
}

export interface GetDIDCountriesListResponse {
    countries_list: DIDCountryInfo[];
}

export interface DIDCountryInfo {
    id: number;
    name: string;
    prefix?: string;
    req_params: DIDRequestParameters;
}

export interface DIDRequestParameters {
    area_name?: string | null;
    area_prefix?: string;
    city_id?: number;
    city_prefix?: string;
    country_id?: number;
    country_iso?: string | null;
    country_name?: string;
    country_prefix?: string;
    i_did_provider?: number;
    number?: string;
}

export interface GetDIDAreasListRequest {
    did_provider_info: CustomerDIDProviderIdent;
    for_idle_numbers_only: YesNo;
    req_params?: DIDRequestParameters;
    req_params_list?: DIDRequestParameters[];
}

export interface GetDIDAreasListResponse {
    areas_list: DIDAreaInfo[];
}

export interface DIDAreaInfo {
    id: number;
    name: string;
    prefix: string;
    pricing: DIDPricing;
    req_params: DIDRequestParameters;
}

export interface DIDPricing {
    admin_costs?: DIDCostsInfo;
    customer_costs: DIDCostsInfo;
    reseller_costs?: DIDCostsInfo;
}

export enum DIDPricingTypeEnum {
    Admin = 'admin_costs',
    Customer = 'customer_costs',
    Reseller = 'reseller_costs',
}

export interface DIDCostsInfo {
    activation: number;
    free_of_charge: string;
    iso_4217: string;
    periodic: number;
}

export enum DIDCostsInfoEnum {
    Activation = 'activation',
    Periodic = 'periodic',
}

export interface GetDIDNumbersListRequest {
    did_provider_info: CustomerDIDProviderIdent;
    idle_only?: string;
    limit?: number;
    offset?: number;
    req_params?: DIDRequestParameters;
    req_params_list?: DIDRequestParameters[];
}

export interface GetDIDNumbersListResponse {
    numbers_list: DIDNumberListInfo[] | null;
}

export interface DIDNumberListInfo {
    id: number;
    number: string;
    pricing: DIDPricing | null;
    req_params: DIDRequestParameters | null;
}

export interface DIDInventoryDetailsSuccessPayload {
    provider: GetProviderInfoResponse;
    countries: DIDCountryInfo[];
    numbers: DIDNumberListInfo[] | null;
}

export interface ProvisionDIDRequest {
    did_provider_info?: CustomerDIDProviderIdent;
    req_params: DIDRequestParameters;
    assignTo?: {
        i_did_number?: number;
        i_master_account: number;
    };
}

export interface ProvisionDIDResponse {
    did_order_info: DIDNumberOrderInfo;
}

export interface DIDNumberOrderInfo {
    area_name: string;
    country_name: string;
    did_number: string;
    did_status: string;
    fault_code: string;
    fault_string: string;
    i_did_number: number;
    is_success: number;
    order_id: string;
    pricing: DIDPricing;
}

export interface GetDidAreasRequestPayload {
    country: DIDCountryInfo;
}

export interface GetDidNumbersRequestPayload {
    area: DIDAreaInfo;
}

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: 580,
        width: 700,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'red',
    },
    receiptRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 275,
        margin: '3px 0',
    },
    leftReceiptText: {
        color: Colors.Gray5,
        fontSize: 13,

        '&:first-letter': {
            textTransform: 'capitalize',
        },
    },
    boldLeftText: {
        fontWeight: 500,
        color: Colors.Text,
    },
    rightReceiptText: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.Text,
    },
    rightSecondaryReceiptText: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.Gray5,
        marginLeft: 5,
    },
    rightContainer: {
        display: 'flex',
        maxWidth: 140,
    },
    receiptDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 195,
    },
    separator: {
        border: `1.2px dashed ${Colors.Border}`,
        margin: '17px 0',
        width: '100%',
    },
    logoContainer: {
        marginTop: 25,
        marginBottom: 50,
    },
}));

import React from 'react';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { AssignedExtension } from '../../store/types/RingGroup';
import { YesNo } from '../../store/types/CallScreening';
import classNames from 'classnames';
import { SipCall, SipCallState, SipCallType } from '../../store/actions/ringgroups/payloads';
import { APIErrorInterface } from '../../store/types';
import { useStyles } from './RingGroupsCallsCell.utils';
import PeopleIcon from '../../assets/PeopleIcon';

export interface RingGroupsCallsCellProps {
    assignedExtensions?: AssignedExtension[];
    sipCalls?: SipCall[];
    sipCallsApiError?: APIErrorInterface;
};

export const RingGroupsCallsCell: React.FC<RingGroupsCallsCellProps> = ({
    assignedExtensions,
    sipCalls,
    sipCallsApiError
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    /*
    -Total of all type:account
    -Available of all type:account and hunt_active:Y
    -Logged out of alltype:account and hunt_active:N
    -No Wrapup state for now
    -On call - this can be calculated using CallControl/get_sip_calls_list: 
        get the total of calls with unique tracking_id where extension_id matching id from assigned_extensions
        and state:connected or (state:trying or state:ringing and type:outgoing)
    -On hold - this can be calculated using CallControl/get_sip_calls_list: 
        get the total of calls with unique tracking_id where extension_id matches id from assigned_extensions
        and (state:holding or state:held)
    */

    const extensions = assignedExtensions?.filter(
        (v: AssignedExtension) => v.type === 'Account'
    );
    const total = extensions?.length || 0;
    const available = extensions?.filter(
        (v: AssignedExtension) => v.hunt_active === YesNo.Yes
    )?.length || 0;
    const loggedOut = extensions?.filter(
        (v: AssignedExtension) => v.hunt_active === YesNo.No
    )?.length || 0;

    const extIds = assignedExtensions?.map(e => e.id || '') || [];
    const callsByExtensionIds = sipCalls?.filter(e => 
        (e.callee?.extension_id && extIds.findIndex(c => c === e.callee.extension_id) !== -1) || 
        (e.caller?.extension_id && extIds.findIndex(c => c === e.caller.extension_id) !== -1)
    ) || [];

    const onCall = callsByExtensionIds.filter(e => e.state === SipCallState.Connected
        || ((e.state === SipCallState.Trying || e.state === SipCallState.Ringing)
            && e.type === SipCallType.Outgoing))
        ?.map(e => e.tracking_id);
    const onCallCount = [...new Set(onCall)].length;
        
    const onHold = callsByExtensionIds.filter(e => e.state === SipCallState.Holding || e.state === SipCallState.Held)
        ?.map(e => e.tracking_id);
    const onHoldCount = [...new Set(onHold)].length;
    
    const availableColor = available == 0 ? Colors.Primary2
        : total == available ? Colors.Support : Colors.Gray;
        
    const availableColorInTooltip = available == 0 ? Colors.Primary2
        : total == available 
        ? Colors.Support : undefined

    const badgeTooltipContent = (
        <div className={classes.badgeContainer}>
            <div className={classes.badgeRow}>
                <span className={classes.badgeTitle}>{t('screens:ringGroups.groupMembers')}</span>
            </div>
            <div className={classNames(classes.badgeRow, classes.headerSeparator)}>
            </div>
            <div className={classNames(classes.badgeRow, classes.firstTableRow)}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.total')}</div>
                <div className={classes.rightColumn}>{total}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn} style={{color: availableColorInTooltip}}>{t('screens:ringGroups.availableInBadge')}</div>
                <div className={classes.rightColumn} style={{color: availableColorInTooltip}}>{available}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.loggedOut')}</div>
                <div className={classes.rightColumn}>{loggedOut}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.onCall')}</div>
                <div className={classes.rightColumn}>{sipCallsApiError ? ' - ' : onCallCount}</div>
            </div>
            <div className={classes.badgeRow}>
                <div className={classes.leftColumn}>{t('screens:ringGroups.onHold')}</div>
                <div className={classes.rightColumn}>{sipCallsApiError ? ' - ' : onHoldCount}</div>
            </div>
        </div>
    );

    return (
        <Tooltip
            title={badgeTooltipContent}
            classes={{
                tooltip: classes.badgeContainerGlobal
            }}
        >
            <div className={classes.mainContainer}>
                <div className={classes.iconContainer}>
                    <div className={classes.iconSubContainer}>
                        <PeopleIcon />
                    </div>
                </div>
                <div className={classes.labelsContainer}>
                    <div className={classes.totalsLabel}>{total} {t('common:total')}</div>
                        <div className={classes.availableLabel} style={{color: availableColor}}>
                            {available} {t('screens:ringGroups.available')}
                        </div>
                </div>
            </div>
        </Tooltip>
    );
}

export default RingGroupsCallsCell;

import React from 'react';
import CustomizedButton from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    plusIcon: {
        '& svg.MuiSvgIcon-root path': {
            fill: Colors.White + '!important',
            fillOpacity: 1,
        },
    },
    icon: {
        fill: Colors.Error,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.6,
            marginBlockStart: 0,
            fontWeight: 400,
        },
    },
    button: {
        marginTop: -14,
    },
}));

type HeaderType = {
    title: string;
    buttonText?: string;
    buttonDataQa?: string;
    totalNumber?: number;
    ofNumber?: number;
    dataQa?: string;
    buttonOnClick?: () => void;
    ofText?: string;
    showDetails?: boolean;
    plusIcon?: boolean;
    customClass?: string;
    hideTotal?: boolean;
    children?: React.ReactNode;
};

const Header: React.FC<HeaderType> = ({
    title,
    buttonText,
    buttonOnClick,
    totalNumber = 0,
    ofNumber = 0,
    dataQa,
    ofText,
    showDetails = true,
    buttonDataQa = 'header-button',
    plusIcon = false,
    customClass,
    hideTotal = false,
    children
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const secText = ofText ?? t('common:active');
    
    const capitalizeFirstLetter = (value: string) => {
        const l = i18n.language.toLocaleLowerCase();
        if(l === "de") {
            return value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();
        } else {
            return value?.toLocaleLowerCase();
        }
    }
    
    const PlusIcon = (
        <Add
            className={classes.plusIcon}
            htmlColor={Colors.White}
            style={{ height: 22, width: 22, marginRight: 6, marginLeft: -3 }}
        />
    );

    return (
        <div
            className={classNames(classes.header, customClass)}
            data-qa={dataQa}
            data-testid={'list-view-header'}
        >
            <div className={classes.titleContainer}>
                <h2>{title}</h2>
                {!hideTotal && (
                    <p data-qa={'header-summary-text'}>
                        {`${totalNumber} ${capitalizeFirstLetter(t('common:total'))}`}

                        {showDetails && ` / ${ofNumber} ${capitalizeFirstLetter(secText || '')}`}
                    </p>
                )}
            </div>
            {buttonText && (
                <CustomizedButton
                    dataQa={buttonDataQa}
                    primary
                    type="submit"
                    onClick={buttonOnClick}
                    className={classes.button}
                >
                    {plusIcon && PlusIcon}
                    {buttonText}
                </CustomizedButton>
            )}
            {children}
        </div>
    );
};

export default Header;

import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../../actions/callSettings';
import { MohInfo } from '../../types/AccountMoh';
import { DialingRuleInfo } from '../../types/DialingRule';
import { ServiceFeature } from '../../types/ServiceFeature';
import { CallBarringRule } from '../../types/CallBarring';
import { CustomerInfoDetails } from '../../types/CustomerInfo';
import { GetCPConditionListResponsePayload } from '../../actions/extensions/payloads';
import { ApiFileList } from '../../types/ApiFile';

export interface CallSettingsStateType {
    isDataLoading?: boolean;
    isDataSending?: boolean;
    mohDetails?: {
        items: MohInfo[];
    };
    onHoldMusicStatus?: boolean;
    extToExtStatus?: boolean;
    dialingRules?: DialingRuleInfo[];
    customerServiceFeatures?: ServiceFeature[];
    callBarringStatus?: boolean;
    callBarring?: ServiceFeature;
    callBarringItems?: CallBarringRule[];
    customerInfo?: CustomerInfoDetails;
    cpConditionsList?: GetCPConditionListResponsePayload;
    customerResponseMessages?: ApiFileList;

    isCreatingCpCondition?: boolean;
    isEditingCpCondition?: boolean;

    isCreatingResponseMessage?: boolean;
    isEditingResponseMessage?: boolean;
}

export const initialState: CallSettingsStateType = {};

export type CallSettingsActionsType = ActionType<typeof actions>;

const callSettingsReducer = createReducer<
    CallSettingsStateType,
    CallSettingsActionsType
>(initialState)
    .handleAction(actions.getCallSettingsDetails.request, (state) => ({
        ...state,
        isDataLoading: true,
    }))
    .handleAction(actions.getCallSettingsDetails.success, (state) => ({
        ...state,
        isDataLoading: false,
    }))
    .handleAction(actions.editCallSettings.request, (state) => ({
        ...state,
        isDataSending: true,
    }))
    .handleAction(actions.editCallSettings.success, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(actions.editCallSettings.failure, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(actions.getDialingRules.success, (state, action) => ({
        ...state,
        dialingRules: action.payload,
    }))
    .handleAction(
        actions.getCustomerServiceFeatures.success,
        (state, action) => ({
            ...state,
            customerServiceFeatures: action.payload,
        }),
    )
    .handleAction(
        actions.getCustomerInfoCallBarring.success,
        (state, action) => ({
            ...state,
            isDataSending: false,
            callBarring: action.payload.callBarring,
            customerInfo: action.payload.customerInfo,
        }),
    )
    .handleAction(actions.getCallBarringOptions.success, (state, action) => ({
        ...state,
        isDataSending: false,
        callBarringItems: action.payload.callBarringOptions,
    }))
    .handleAction(actions.getCallBarringOptions.failure, (state) => ({
        ...state,
        isDataSending: false,
    }))
    .handleAction(
        actions.getCallSettingsMohDetails.success,
        (state, action) => ({
            ...state,
            mohDetails: {
                ...state.mohDetails,
                ...action.payload,
            },
        }),
    )
    .handleAction(actions.getMusicRingDetails.success, (state, action) => ({
        ...state,
        onHoldMusicStatus: action.payload.onHoldMusicStatus,
        extToExtStatus: action.payload.extToExtStatus,
    }))
    .handleAction(actions.getCustomerCallScreeningConditions.request, (state) => ({
        ...state,
        isDataLoading: true,
    }))
    .handleAction(actions.getCustomerCallScreeningConditions.success, (state, action) => ({
        ...state,
        cpConditionsList: action.payload
    }))
    .handleAction(actions.getCustomerCallScreeningConditions.failure, (state) => ({
        ...state,
    }))
    .handleAction(actions.getCustomerResponseMessage.request, (state) => ({
        ...state,
    }))
    .handleAction(actions.getCustomerResponseMessage.success, (state, action) => ({
        ...state,
        customerResponseMessages: action.payload
    }))
    .handleAction(actions.addCpCondition.request, (state) => ({
        ...state,
        isCreatingCpCondition: true,
    }))
    .handleAction(actions.addCpCondition.success, (state, action) => ({
        ...state,
        cpConditionsList: {
            ...state.cpConditionsList,
            cp_condition_list: action.payload,
            total: action.payload.length
        },
        isCreatingCpCondition: false,
    }))
    .handleAction(actions.addCpCondition.failure, (state) => ({
        ...state,
        isCreatingCpCondition: false,
    }))
    .handleAction(actions.editCpCondition.request, (state) => ({
        ...state,
        isEditingCpCondition: true,
    }))
    .handleAction(actions.editCpCondition.success, (state, action) => ({
        ...state,
        cpConditionsList: {
            ...state.cpConditionsList,
            cp_condition_list: action.payload,
            total: action.payload.length
        },
        isEditingCpCondition: false,
    }))
    .handleAction(actions.editCpCondition.failure, (state) => ({
        ...state,
        isEditingCpCondition: false,
    }))
    .handleAction(actions.deleteCpCondition.success, (state, action) => ({
        ...state,
        cpConditionsList: {
            ...state.cpConditionsList,
            cp_condition_list: action.payload,
            total: action.payload.length
        }
    }))
    .handleAction(actions.addCustomerResponseMessage.request, (state) => ({
        ...state,
        isCreatingResponseMessage: true,
    }))
    .handleAction(actions.addCustomerResponseMessage.success, (state, action) => ({
        ...state,
        customerResponseMessages: action.payload,
        isCreatingResponseMessage: false
    }))
    .handleAction(actions.addCustomerResponseMessage.failure, (state) => ({
        ...state,
        isCreatingResponseMessage: false,
    }))
    .handleAction(actions.editCustomerResponseMessage.request, (state) => ({
        ...state,
        isEditingResponseMessage: true,
    }))
    .handleAction(actions.editCustomerResponseMessage.success, (state, action) => ({
        ...state,
        customerResponseMessages: action.payload,
        isEditingResponseMessage: false
    }))
    .handleAction(actions.editCustomerResponseMessage.failure, (state) => ({
        ...state,
        isEditingResponseMessage: false,
    }))
    .handleAction(actions.deleteCustomerResponseMessage.success, (state, action) => ({
        ...state,
        customerResponseMessages: action.payload
    }));
    
export default callSettingsReducer;

import React, { useEffect } from 'react';
import {
    CallSettingsFormProps,
    CallSettingsFormType
} from './CallSettingsForm.utils';
import IncomingForm from '../Incoming/IncomingForm';
import CallBarringForm from '../../Extensions/CallBarring/CallBarringForm';
import CallRecordingForm from '../../Extensions/CallRecording/CallRecordingForm';
import { useFormikContext } from 'formik';
import DisaForm from '../DisaForm';
import Separator from '../../../Separator/Separator';

const CallSettingsForm: React.VFC<CallSettingsFormProps> = ({
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    callRecordingEmail
}) => {
    const {
        handleSubmit,
        isValid,
        dirty,
    } = useFormikContext<CallSettingsFormType>();

    useEffect(() => {
        handleDirtyChange?.('callSettings', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callSettings', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callSettings', () => isValid);
    }, [isValid]);

    return (
        <>
            <IncomingForm />
            <Separator />
            <CallBarringForm />
            <Separator />
            <CallRecordingForm callRecordingEmail={callRecordingEmail}/>
            <Separator />
            <DisaForm />
        </>
    );
};

export default CallSettingsForm;

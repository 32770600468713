import CustomizedTooltip from "../Tooltip/Tooltip";
import Checkbox from "../Checkbox/Checkbox";
import IconWithTooltip from "../Tooltip/IconWithTooltip";
import {greyColor3, greyColor6} from "../../styles/Colors";
import React, {ChangeEvent} from "react";
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {RingGroupType} from "../../store/types/RingGroup";

type AllowGroupPaggingProps = {
    readOnly: boolean,
    allowGroupPaging?: boolean,
    type: 'ringGroup' | 'extensions' | null,
    ringGroup?: Partial<RingGroupType>,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const AllowGroupPagging: React.VFC<AllowGroupPaggingProps> = (
    {
        readOnly,
        allowGroupPaging,
        type,
        ringGroup,
        onChange
    }) => {

    const {t} = useTranslation();
    return (
        <Box display="flex" alignItems="center">
            <CustomizedTooltip
                title={t('common:noPermissionToResource')}
                copy={false}
                disableHoverListener={!readOnly}
            >
                <div>
                    <Checkbox
                        dataQa="allow-group-paging"
                        checked={allowGroupPaging}
                        onChange={onChange}
                        label={t(
                            'screens:addToRingGroup.allowGroupPaging',
                        )}
                        disabled={
                            type === 'ringGroup' ||
                            readOnly
                        }
                    />
                </div>
            </CustomizedTooltip>

            <IconWithTooltip
                dataQa="allow-group-paging-tooltip"
                tooltipText={t(
                    'tooltips:ringGroups.allowGroupPaging',
                )}
                iconColor={
                    ringGroup !== undefined
                        ? greyColor6
                        : greyColor3
                }
            />
        </Box>
    )
}

export default AllowGroupPagging;
import { MenuActionFormType } from '../../components/Forms/AutoAttendantsMenus/ActionsForm.utils';
import i18n from '../../services/i18n';
import { MenuAction, UserInput } from '../../store/types/AutoAttendant';

export const getTransitionMenuLabel = (
    unifiedMessagingVoicemailEnabled: boolean,
    transition?: MenuActionFormType,
    sendToEmail?: string,
) => {
    if (!transition) {
        return { title: '', description: '' };
    }

    let title: string | undefined = '';
    let description: string | undefined = '';

    const timeoutTime =
        transition.userInput === UserInput.NoInput && transition.timeout
            ? parseInt(transition.timeout, 10)
            : 0;

    switch (transition.action) {
        case MenuAction.Transfer:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecTransferTo', {
                        sec: timeoutTime,
                    })
                : i18n.t('screens:autoAttendants.transferTo');
            description = transition.transferCallerToPhoneNumberExtension;
            break;
        case MenuAction.TransferToE164Number:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecTransferTo', {
                        sec: timeoutTime,
                    })
                : i18n.t('screens:autoAttendants.transferToE164');
            description = '+' + transition.transferCallerToPhoneNumberExtension;
            break;
        case MenuAction.TransferToFaxMailbox:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecTransferTo', {
                        sec: timeoutTime,
                    })
                : i18n.t('screens:autoAttendants.transferTo');
            description = (unifiedMessagingVoicemailEnabled && transition.userInput !== UserInput.Fax) 
                ? i18n.t('tooltips:autoAttendants.voicemail')
                : (sendToEmail || '-');
            break;
        case MenuAction.DISA:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecActivate', {
                        sec: timeoutTime,
                    })
                : i18n.t('screens:autoAttendants.activate');
            description = 'DISA';
            break;
        case MenuAction.Queue:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecGoToCallQueue', {
                        sec: timeoutTime,
                    })
                : (title = i18n.t(
                        `enums:inactiveMenuAction.${transition.action}`,
                    ));
            description = transition.queue?.group_name;
            break;
        case MenuAction.Menu:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSecGoToMenu', {
                        sec: timeoutTime,
                    })
                : (title = i18n.t('enums:inactiveMenuAction.Menu'));
            description = transition.menu?.name;
            break;
        default:
            title = timeoutTime
                ? i18n.t('screens:autoAttendants.inSec', {
                        sec: timeoutTime,
                    })
                : '';
            description = i18n.t(
                `enums:inactiveMenuAction.${transition.action}`,
            );
            break;
    }

    return { title, description };
};

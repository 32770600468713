import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import {
    settingsFormDefaultValues,
    SettingsFormType,
} from '../../Forms/AutoAttendantsMenus/SettingsForm.utils';
import { ActionsFormType } from '../../Forms/AutoAttendantsMenus/ActionsForm.utils';
import {intervalFormInitialValues, IntervalFormProps} from "../../IntervalSelect/IntervalSelect.utils";

export const initialValues: AddNewMenuForm = {
    intervals: intervalFormInitialValues,
    ...settingsFormDefaultValues,
    callersToDialKnownNumberTimeout: 5,
    actions: [],
    errorsCount: 3,
};

export type AddNewMenuForm = {
    name?: string;
} & { intervals: IntervalFormProps } & SettingsFormType &
    ActionsFormType;

export const useStyles = makeStyles(() => ({
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },
    },
    itemsContainer: {
        padding: '0 16px!important',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogContainer: {
        '& .MuiDialogContent-root': {
            padding: '26px 60px!important',
            backgroundColor: Colors.SmokeBackground,
            height: 506,
            width: 974,
        },
    },
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
        margin: '5px 0',
    },
    intervalsContainer: {
        marginTop: 30,
        marginBottom: 24,
    },
}));

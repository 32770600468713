import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './SwitchMode.utils';

import { ReactComponent as HandIcon } from './../../assets/open_hand.svg';
import Button from '../Button/Button';
import SwitchModeDialog from './SwitchModeDialog';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import classNames from 'classnames';

interface SwitchModeInterface {
    actionAfterSave?: () => void;
}

const SwitchMode: React.VFC<SwitchModeInterface> = ({ actionAfterSave }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const permission = usePermissionContext();

    const [showDialog, setShowDialog] = useState(false);

    const handleDialogVisibility = () => {
        setShowDialog((prevState) => !prevState);
    };

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <>
            <CustomizedTooltip
                title={
                    permission === PermissionType.ReadOnly
                        ? t('common:noPermissionToResource')
                        : t('tooltips:extensions.switchButtonTooltip')
                }
                copy={false}
            >
                <div style={{ display: 'flex' }}>
                    <Button
                        icon={<HandIcon />}
                        className={classNames(classes.button, 
                            permission === PermissionType.ReadOnly ? classes.disabledButton : null)}
                        onClick={handleDialogVisibility}
                        disabled={permission === PermissionType.ReadOnly}
                    >
                        {t('screens:extensions:switchModeButtonText')}
                    </Button>
                </div>
            </CustomizedTooltip>

            <SwitchModeDialog
                isOpen={showDialog}
                toggleVisibility={handleDialogVisibility}
                actionAfterSave={actionAfterSave}
            />
        </>
    );
};

export default SwitchMode;

import { MohInfo } from '../../types/AccountMoh';
import { EditCallSettingsForm } from '../../../views/CallSettings/CallSettingsDetails';
import { ServiceFeature } from '../../types/ServiceFeature';
import { CallBarringRule } from '../../types/CallBarring';
import { CustomerInfoDetails } from '../../types/CustomerInfo';
import { CPConditionInfo, CPRuleInfo, YesNo } from '../../types/CallScreening';
import { NewPolicyProps } from '../../../components/Forms/CallSettings/CallScreening/NewPolicyDialog.utils';
import { GetCallProcessingPolicyListEntity, OperationMode } from '../extensions/payloads';

export interface SetCallSettings {
    changedValues: EditCallSettingsForm;
    initialValues: EditCallSettingsForm;
    redirectTab?: number;
}

export type CallSettingsMohDetailsResponsePayload = {
    items: MohInfo[];
};

export type MusicRingingResponsePayload = {
    onHoldMusicStatus?: boolean;
    extToExtStatus?: boolean;
};

export type UploadMusicFileRequest = {
    name: string;
    file: File;
    callback?: () => void;
    errorCallback?: () => void;
};

export type CustomerInfoResponsePayload = {
    callBarring?: ServiceFeature;
    customerInfo?: CustomerInfoDetails;
};

export type CallBarringOptionsResponsePayload = {
    callBarringOptions?: CallBarringRule[];
};

export type CustomerCallScreeningConditionsPayload = {
    get_total?: number;
    i_customer: number;
};

export type CustomerResponseMessagesPayload = {
    handler: string;
    owner_type: string;
    i_owner: number;
}

export type UpdateRuleStatusPayload = {
    newStatus: 'Y' | 'N';
    object: CPRuleInfo;
}

export type NewPolicyPayload = {
    object: NewPolicyProps;
    callback?: () => void;
};

export type DeletePolicyPayload = {
    i_cp_policy: number;
    callback?: () => void;
};

export type EditPolicyPayload = {
    object?: GetCallProcessingPolicyListEntity;
    successToastText?: string;
    callback?: () => void;
};

export enum DeletePolicyFaultCodes {
    PolicyIsInUse = 'Server.Customer.delete_call_processing_policy.not_deleted'
}

export type AddEditOperationModePayload = {
    object: OperationMode;
    callback?: () => void;
};

export type DeleteModePayload = {
    i_operation_mode: number;
    callback?: () => void;
};

export type AddCpConditionPayload = {
    object: CPConditionInfo;
    callback?: () => void;
}

export type DeleteCpConditionPayload = {
    i_cp_condition: number;
    callback?: () => void;
}

export type DeleteCustomerResponseMessagePayload = {
    id: number;
    callback?: () => void;
};

export interface ApiFileCreateEditPayload {
    audio_profile?: string;
    callback?: string;
    callback_params?: string;
    codec_list?: string[];
    file_name: string;
    handler?: string;
    i_object?: number;
    object?: string;
    storage_info?: number;
    transitional: YesNo;
    file?: File;
    fileDisplayName?: string;
    fileSize?: number;
}

export type AddEditCustomerResponseMessagePayload = {
    object: ApiFileCreateEditPayload;
    isDirty?:boolean;
    callback?: () => void;
}
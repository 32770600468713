import DialogContainer, {
    DialogButton,
} from '../../../components/AlertDialog/DialogContainer';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaxTransmissionListItem, generateColumns, useStyles } from './utils';
import { Grid } from '@material-ui/core';
import CustomKeyboardDateTimePicker from '../../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';
import dayjs from '../../../services/customDayJs';
import { useFormik } from 'formik';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { PaginationMode } from '../../../components/DataGrid/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import { actions } from '../../../store';
import { convertUserLocalTimeToUtc } from '../../../utils/dateWithTimezoneConversion';
import { CallHistory } from '../../../store/types/CallHistory';
import { callRecordingsFiltersValidationSchema } from '../../CallRecordings/utils';

export type TransmissionHistoryDialogProps = {
    isOpen: boolean;
    toggleVisibility?: () => void;
    accountId?: number;
    extensionName?: string;
};

export type FaxHistoryTransmissionFormProps = {
    from: string;
    till: string;
    cli: string;
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const TransmissionHistoryDialog: React.VFC<TransmissionHistoryDialogProps> = ({
    isOpen,
    toggleVisibility,
    accountId,
    extensionName,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const customerCurrency = useSelector<ReduxState, string>(
        (state) => state.calls.customerCurrency || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const { items, total } = useSelector<
        ReduxState,
        { items: CallHistory[]; total: number }
    >((state) => state.calls.extensionCallHistory);

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.extensionCallHistory.isLoading,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const initialValues = useMemo(
        () => ({
            from: dayjs().subtract(1, 'month').format(dateFormat),
            till: dayjs().format(dateFormat),
            cli: '',
        }),
        [],
    );

    const getTransmissionHistory = (
        form: FaxHistoryTransmissionFormProps,
        withInitCalls?: boolean,
        limit?: number,
        offset?: number,
    ) => {
        if (accountId) {
            dispatch(
                actions.getExtensionCallHistory.request({
                    i_account: accountId,
                    limit: limit || 3,
                    offset: offset || 0,
                    ...form,
                    cli: form.cli ? `${form.cli}%` : undefined,
                    from: convertUserLocalTimeToUtc(form.from, timezoneOffset),
                    till: convertUserLocalTimeToUtc(form.till, timezoneOffset),
                    withInitCalls,
                }),
            );
        }
    };

    const closeDialog = () => {
        toggleVisibility?.();
        resetForm();
    };

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
        errors,
        setFieldError,
    } = useFormik<FaxHistoryTransmissionFormProps>({
        initialValues,
        onSubmit: (form) => {
            getTransmissionHistory(form, false);
        },
        validationSchema: callRecordingsFiltersValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    const itemsTransform: FaxTransmissionListItem[] = items.map((o, index) => ({
        id: index,
        connect_time: o.connect_time ?? '',
        CLI: o.CLI ?? '',
        CLD: o.CLD ?? '',
        charged_amount: o.charged_amount ?? 0,
    }));

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                timezoneOffset,
                customerCurrency,
                userDateTimeFormat,
            ),
        [userDateTimeFormat, customerCurrency],
    );

    useEffect(() => {
        getTransmissionHistory(values, true);
    }, [accountId]);

    return (
        <DialogContainer
            dataQa={'transmission-dialog'}
            dataTestId={'transmission-dialog'}
            className={classes.modalContainer}
            header={t('screens:faxMailboxes.historyHeader', {
                value: extensionName,
            })}
            isOpen={isOpen}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:close')}
                    onClick={closeDialog}
                    dataQa={'close-transmission-dialog-button'}
                />,
            ]}
        >
            <form>
                <Grid item className={classes.itemsContainer}>
                    <CustomKeyboardDateTimePicker
                        id="from"
                        label={t('common:fromDate')}
                        value={values.from}
                        onChange={(v) => setFieldValue('from', v)}
                        dataQa={'transmission-history-filter-from'}
                        dataTestId={'transmission-history-filter-from'}
                        userDateTimeFormat={userDateTimeFormat}
                        className={classes.timePicker}
                        helperText={errors.from}
                        setFieldError={setFieldError}
                    />
                    <CustomKeyboardDateTimePicker
                        id="till"
                        label={t('common:toDate')}
                        value={values.till}
                        onChange={(v) => setFieldValue('till', v)}
                        dataQa={'transmission-history-filter-till'}
                        dataTestId={'transmission-history-filter-till'}
                        userDateTimeFormat={userDateTimeFormat}
                        className={classes.timePicker}
                        helperText={errors.till}
                        setFieldError={setFieldError}
                    />
                    <TextField
                        id="cli"
                        label={t('common:sender')}
                        onChange={handleChange}
                        value={values.cli}
                        dataQa={'transmission-history-filter-calling-sender'}
                    />
                    <Button
                        primary
                        accent
                        dataQa="transmission-history-search"
                        className={classes.button}
                        onClick={() => handleSubmit()}
                    >
                        {t('common:search')}
                    </Button>
                </Grid>
            </form>
            <DataGrid
                className={classes.table}
                classes={{ pagination: classes.pagination }}
                columns={columns}
                data={itemsTransform}
                rowCount={total}
                loading={isLoading}
                onPageChange={(v) =>
                    getTransmissionHistory(
                        values,
                        false,
                        v.pageSize,
                        v.pageSize * v.page,
                    )
                }
                onPageSizeChange={(v) =>
                    getTransmissionHistory(values, false, v.pageSize, 0)
                }
                paginationMode={PaginationMode.Server}
                centeredRows
                pageSizeOptions={[3, 5, 10, 50]}
                initialPageSize={3}
                narrowRows
            />
        </DialogContainer>
    );
};

export default TransmissionHistoryDialog;

import React, {useMemo, useState} from 'react';
import FormLabel from '../../../FormLabel/FormLabel';
import {useTranslation} from 'react-i18next';
import SelectField from '../../../SelectField/SelectField';
import {makeStyles} from '@material-ui/core/styles';
import {CustomerDIDNumberType} from '../../../../store/types/CustomerDIDNumber';
import {useFormikContext} from 'formik';
import {generateDisplayNumberList} from '../../Extensions/Extension/ExtensionForm.utils';

export interface DefaultCallerFormType {
    defaultCompanyNumber: string;
}

export const defaultCallerFormDefaultValues = {
    defaultCompanyNumber: '',
};

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 10,
        maxWidth: 392,
    },
}));

interface DefaultCallerFormProps {
    numbers: CustomerDIDNumberType[];
    prefixEnabled?: boolean;
}

const DefaultCallerForm: React.VFC<DefaultCallerFormProps> = ({
    numbers,
    prefixEnabled,
}) => {
    const { t } = useTranslation();
    const value = { label: t('common:notSet'), value: '' };
    const classes = useStyles();

    const [numberList, setNumberList] = useState<
        { label: string; value: string }[]
    >([
        value,
        ...numbers.map((number) => ({
            label: number.did_number,
            value: number.did_number,
        })),
    ]);

    const {
        values,
        setFieldValue,
        initialValues,
    } = useFormikContext<DefaultCallerFormType>();

    useMemo(() => {
        let newList = [...numberList];
        if (
            initialValues.defaultCompanyNumber.length &&
            !numberList.find(
                (val) => val.value == initialValues.defaultCompanyNumber,
            )
        ) {
            newList.push({
                value: initialValues.defaultCompanyNumber,
                label: initialValues.defaultCompanyNumber,
            });
        }

        // @ts-ignore
        newList = newList.map((item) => {
            if (item.label == t('common:notSet')) return item;
            return generateDisplayNumberList(
                item.value,
                values.defaultCompanyNumber,
                prefixEnabled || false,
                '+',
                false,
            );
        });

        const output = [
            ...new Map(newList.map((item) => [item.value, item])).values(),
        ];

        setNumberList(output);
    }, []);

    return (
        <>
            <FormLabel
                label={t('screens:callSettings.outbandLabel')}
                tooltipText={t('tooltips:callSettings.defaultCeller')}
                dataQa={'outbound'}
            />
            <SelectField
                dataQa={'outband-display-number'}
                dataTestId={'outband-display-number'}
                id={'outband-display-number'}
                items={numberList}
                value={
                    numberList?.find(
                        (v) => v.value == values.defaultCompanyNumber,
                    ) || ''
                }
                getOptionSelected={(o) =>
                    o.value == values.defaultCompanyNumber
                }
                onChange={(e, value) => {
                    const newValue = value !== null ? value.value : '';
                    setFieldValue('defaultCompanyNumber', newValue);
                }}
                getOptionLabel={(o) => {
                    return o.label || t('common:notSet');
                }}
                label={t('screens:callSettings:mainCompanyName')}
                className={classes.select}
            />
        </>
    );
};

export default DefaultCallerForm;

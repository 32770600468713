import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    numberInput: {
        width: 135,
        marginRight: 20,
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    numberPickerGrid: {
        '& .MuiFormControl-root': {
            flex: 'unset !important',
        },
    },
    activeHeader: {
        marginTop: 0,
        marginBottom: 16,
    },
}));

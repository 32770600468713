import React, { useRef, useEffect, useState } from 'react';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

type OverflowTooltipProps = {
    tooltip: any;
    text: string | React.ReactNode;
    above?: boolean;
    classes?: { text?: string; titleText?: string };
    dataQa?: string;
    copy?: boolean;
    onClickText?: () => void;
};
const useStyles = makeStyles(() => ({
    text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flex: 1,
    },
}));

const OverflowTooltip: React.VFC<OverflowTooltipProps> = ({
    tooltip,
    text,
    above,
    classes: customClasses,
    dataQa,
    copy,
    onClickText,
}) => {
    const classes = useStyles();
    const [hoverStatus, setHover] = useState(false);

    const textElementRef = useRef<HTMLInputElement | null>(null);

    const compareSize = () => {
        if (textElementRef.current) {
            const compare =
                textElementRef.current.scrollWidth >
                    textElementRef.current.clientWidth ||
                textElementRef.current.scrollHeight >
                    textElementRef.current.clientHeight;
            setHover(compare);
        }
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);

        return () => {
            window.removeEventListener('resize', compareSize);
        };
    }, []);

    return (
        <CustomizedTooltip
            title={tooltip}
            interactive
            disableHoverListener={!hoverStatus}
            above={above}
            data-qa={dataQa}
            copy={copy}
            classes={{ text: customClasses?.titleText }}
        >
            <div
                ref={textElementRef}
                className={classNames(customClasses?.text, classes.text)}
                onClick={onClickText}
                data-qa={`${dataQa}-value`}
            >
                {text}
            </div>
        </CustomizedTooltip>
    );
};

export default OverflowTooltip;

import React from 'react';
import { useSelector } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import Config from '../../config.json';
import { ReduxState } from '../../store/types';

const ZendeskWrapper: React.VFC = () => {
    if (!Config.ZENDESK_KEY) {
        return null;
    }

    const email = useSelector<ReduxState, string | undefined>(
        (state) => state.myProfile.customer?.email,
    );

    const isLoading = useSelector<ReduxState, boolean | undefined>(
        (state) => state.myProfile.isLoading,
    );

    const setting = {
        webWidget: {
            offset: { vertical: '15px', horizontal: '10px' },
        },
        contactForm: {
            fields: [
                {
                    id: 'email',
                    prefill: { '*': email || '' },
                },
            ],
        },
    };

    if (isLoading !== false) {
        return null;
    }

    return (
        <Zendesk
            key={email}
            defer
            zendeskKey={Config.ZENDESK_KEY}
            {...setting}
            onLoaded={() => ZendeskAPI('webWidget', 'setLocale', 'en-US')}
        />
    );
};

export default ZendeskWrapper;

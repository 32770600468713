import React from "react";
import AccessDenied from "../../../components/PermissionProvider/AccessDenied";
import {useSelector} from "react-redux";
import {APIErrorInterface, ReduxState} from "../../../store/types";
import {
    ACCOUNT_CALL_DIALING_RULE_ACCESS_DENIED,
    ACCOUNT_CALL_PROCESSING_LIST_ACCESS_DENIED,
    ACCOUNT_CALL_PROCESSING_RULES_ACCESS_DENIED,
    ACCOUNT_FOLLOW_ME_ACCESS_DENIED,
    ACCOUNT_PERMITTED_SIP_PROXIES_ACCESS_DENIED
} from "../../../store/sagas/autoAttendants/saga";

type RestrictedMessageProviderProps = {
    forceShowRestricted?: boolean;
    tabName?: ProviderTabName
};

export enum ProviderTabName {
    CallScreening = 'CallScreeningTab',
    CallForwarding = 'CallForwardingTab',
    CallSettings = 'CallSettings'
}

const RestrictedMessageProvider: React.FC<RestrictedMessageProviderProps> = (
    {
        forceShowRestricted = false,
        tabName,
        children
    }) => {

    const extensionsApiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state: ReduxState) => state.extensions?.fetchApiError
    );

    const sipInfoErrors = useSelector(
        (state: ReduxState) => state.extensions?.apiErrorFollowMeState
            || state.extensions?.sipInfoErrors,
    );

    const fetchDialingRulesApiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state: ReduxState) => state.extensions?.fetchDialingRulesApiError
    );

    let showRestricted = false;

    switch (tabName) {
        case ProviderTabName.CallScreening:
            if (extensionsApiError?.faultcode === ACCOUNT_CALL_PROCESSING_RULES_ACCESS_DENIED ||
                extensionsApiError?.faultcode === ACCOUNT_CALL_PROCESSING_LIST_ACCESS_DENIED) {
                showRestricted = true;
            }
            break;
        case ProviderTabName.CallForwarding:
            if (sipInfoErrors?.faultcode == ACCOUNT_FOLLOW_ME_ACCESS_DENIED ||
                sipInfoErrors?.faultcode == ACCOUNT_PERMITTED_SIP_PROXIES_ACCESS_DENIED) {
                showRestricted = true;
            }
            break;
        case ProviderTabName.CallSettings:
            if (fetchDialingRulesApiError?.faultcode == ACCOUNT_CALL_DIALING_RULE_ACCESS_DENIED ||
                fetchDialingRulesApiError?.faultcode == ACCOUNT_CALL_DIALING_RULE_ACCESS_DENIED) {
                showRestricted = true;
            }
            break;
        default:
            showRestricted = false;
            break;
    }

    return forceShowRestricted || showRestricted ? <AccessDenied subPageMode/>
        : <>{children}</>;
}

export default RestrictedMessageProvider;
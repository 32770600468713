import { Interval } from './RingGroup';
import { GetCallProcessingPolicyListEntity } from '../actions/extensions/payloads';
import { TransformBitMask } from '../../utils/extensions/translateBitMak';
import { PeriodInfo } from './AccountFollowMeSettings';
import {IntervalStatus} from "../../components/IntervalSelect/IntervalSelect.utils";
import { ForwardModeFlag } from './ServiceFeature';
import { CallForwardingRule } from '../../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';

export enum YesNo {
    Yes = 'Y',
    No = 'N',
}

export enum CPConditionInfoType {
    To = 'ToNumber',
    From = 'FromNumber',
    TimeWindow = 'TimeWindow',
}

export const CPConditionInfoTypeMapping: {
    [key in CPConditionInfoType]: keyof CPRuleInfo;
} = {
    [CPConditionInfoType.TimeWindow]: 'time_window_i_cp_condition',
    [CPConditionInfoType.To]: 'to_number_i_cp_condition',
    [CPConditionInfoType.From]: 'from_number_i_cp_condition',
};

export interface CPConditionNumberInfo {
    negated?: YesNo;
    number: string;
    onnet?: YesNo;
}

export interface CPConditionTimeWindowInfo {
    period: string | null;
    description?: string;
    definition_list?: PeriodInfo[];
}

export interface CPConditionInfo {
    i_account?: number;
    i_cp_condition?: number | null;
    is_used?: YesNo;
    name?: string;
    numbers?: CPConditionNumberInfo[];
    time_window?: CPConditionTimeWindowInfo;
    type?: CPConditionInfoType;
    value?: string;
    i_customer?: number;
}

export enum CPRuleInfoAction {
    Reject = 0,
    RingOnly = 1,
    ForwardOnly = 2,
    RingTheForward = 3,
    VoiceEmailOnly = 4,
    RingThenVoicemail = 5,
    ForwardThenVoicemail = 6,
    RingForwardVoicemail = 7,
}

export interface CPRuleInfo {
    action?: CPRuleInfoAction;
    from_number_i_cp_condition?: number | null;
    i_account?: number;
    i_cp_rule?: number | null;
    is_active?: 'Y' | 'N';
    order_number?: number;
    time_window_i_cp_condition?: number | null;
    to_number_i_cp_condition?: number | null;
    operation_mode_bitmask?: number;
    response_message_name?: string;
    i_response_message?: number | null;
    i_cp_policy?: number;
    enabled?: 'Y' | 'N';
    i_cpp_rule?: number;
    operation_mode?: number;
}

export interface CPRuleInfoDetails extends CPRuleInfo {
    id?: string;
    ToNumber?: CPConditionInfo;
    FromNumber?: CPConditionInfo;
    TimeWindow?: CPConditionInfo;
    policyDetails?: GetCallProcessingPolicyListEntity;
    showOnlyPolicy?: boolean;
    modeDecode?: TransformBitMask;
}

export type TimeWindowIntervalsDetails = {
    description: string;
    intervals: string;
};

export enum TimePeriod {
    Always = 'always',
}

export type TimeWindowIntervals = {
    timeFilterIndex: number;
    status: IntervalStatus;
    intervals: TimeWindowIntervalsDetails[];
    newIntervals: Interval[];
};

export type ChangeForwardingModeStateItem = {
    flag: ForwardModeFlag;
    callForwardingRules: CallForwardingRule[];
};

export type ChangeForwardingModeState = {
    stateValue: ChangeForwardingModeStateItem[];
};
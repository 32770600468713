import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import TwoLineCell from '../../Calls/TwoLineCell';
import EmptyRowContent from '../../DataTable/EmptyRowContent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateTimeCell from '../../Calls/DateTimeCell';

export const useStyles = makeStyles(() => ({
    header: {
        '& th:nth-child(5)': {
            display: ' flex',
            justifyContent: 'end',
        },
        '& th:nth-child(6)': {
            display: ' flex',
            justifyContent: 'end',
        },
    },

    tableContent: {
        '& td': {
            alignItems: 'center !important',
        },
        '& td:nth-child(5)': {
            display: 'flex',
            justifyContent: 'end',
        },
        '& td:nth-child(6)': {
            display: 'flex',
            justifyContent: 'end',
        },
    },
}));

export interface MessagingTableRowInterface {
    id: string;
    accountId?: string;
    to?: string;
    description?: string;
    date?: string;
    quantity: number;
    chargedAmount?: number;
    country?: string;
}

export const generateColumns = (
    t: TFunction<string>,
    currency = 'USD',
    userDateTimeFormat?: string,
): Column<MessagingTableRowInterface>[] => {
    return [
        {
            Header: t<string>('screens:billing.accountID'),
            accessor: 'accountId',
            width: 1,
            Cell: function Cell(params) {
                const text = params.row.original.accountId;

                return (
                    <OverflowTooltip
                        tooltip={text}
                        text={text}
                        dataQa={'account-id-text'}
                        copy={false}
                    />
                );
            },
        },
        {
            Header: t<string>('common:to'),
            accessor: 'to',
            width: 1,
            Cell: function Cell(params) {
                return (
                    <TwoLineCell
                        headerText={params.row.original.to || ''}
                        descriptionText={params.row.original.country}
                    />
                );
            },
        },
        {
            Header: t<string>('common:description'),
            accessor: 'description',
            width: 1,
            Cell: function Cell(params) {
                const content = params.row.original.description;

                return (
                    <OverflowTooltip
                        copy={false}
                        text={content}
                        tooltip={content}
                    />
                );
            },
        },
        {
            Header: t<string>('common:date'),
            accessor: 'date',
            width: 1,
            Cell: function Cell(params) {
                return params.row.original.date ? (
                    <DateTimeCell
                        time={params.row.original.date as string}
                        customDateFormat={userDateTimeFormat}
                    />
                ) : (
                    <EmptyRowContent />
                );
            },
        },
        {
            Header: t<string>('common:quantity'),
            width: 0.5,
            accessor: 'quantity',
            Cell: function Cell(params) {
                return params.row.original.quantity;
            },
        },
        {
            Header: t<string>('screens:billing.chargedWithCurrency', {
                currency: currency,
            }),
            width: 0.5,
            accessor: 'chargedAmount',
            Cell: function Cell(params) {
                return params.row.original.chargedAmount
                    ? params.row.original.chargedAmount.toFixed(2)
                    : Number(0).toFixed(2);
            },
        },
    ];
};

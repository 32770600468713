import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import { Add } from '@material-ui/icons';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import { PermissionType } from '../../../store/types/Permission';
import { usePermissionContext } from '../../../hooks/usePermissions';
import classNames from 'classnames';

type AddToGroupButtonProps = {
    onClick?: () => void;
};

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: Colors.Secondary5,
        height: 60,
        borderRadius: 4,
        transition: 'box-shadow 0.2s, color 0.2s, background-color 0.2s',
        margin: 8,
        color: Colors.Secondary1,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        width: 'calc(100% * (1/4) - 16px)',
        cursor: 'pointer',

        '& .MuiSvgIcon-root': {
            fill: Colors.Secondary1,
        },

        '&:hover': {
            boxShadow: `0px 4px 8px ${Colors.Gray8}`,
            color: Colors.White,
            backgroundColor: Colors.Secondary1,

            '& .MuiSvgIcon-root': {
                fill: Colors.White,
            },
        },
    },
    addIcon: {
        marginLeft: 21,
        marginRight: 19,
    },
    disabled: {
        opacity: 0.4,

        '&:hover': {
            boxShadow: 'none',
            color: Colors.Secondary1,
            backgroundColor: Colors.Secondary5,

            '& .MuiSvgIcon-root': {
                fill: Colors.Secondary1,
            },
        },
    },
}));

const AddToGroupButton: React.FC<AddToGroupButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const permission = usePermissionContext();

    const internalOnClick = () => {
        if(permission === PermissionType.Visible && onClick) {
            onClick();
        }
    };

    return (
        <CustomizedTooltip
            title={t('common:noPermissionToResource')}
            interactive={false}
            copy={false}
            disableHoverListener={permission === PermissionType.Visible}
        >
            <Grid
                item
                className={classNames(
                    classes.root,
                    permission !== PermissionType.Visible && classes.disabled,
                )}
                data-qa="add-to-new-group"
                onClick={internalOnClick}
            >
                <Add className={classes.addIcon} />
                {t('screens:extensions.addToNewGroup')}
            </Grid>
        </CustomizedTooltip>
    );
};

export default AddToGroupButton;

import { createAsyncAction } from 'typesafe-actions';
import {
    DIDAreaInfo,
    DIDInventoryDetailsSuccessPayload,
    DIDNumberListInfo,
    GetDidAreasRequestPayload,
    GetDidNumbersRequestPayload,
    ProvisionDIDRequest,
} from '../../types/DIDProvider';

export const getDIDCountry = createAsyncAction(
    'GET_DID_COUNTRY',
    'GET_DID_COUNTRY_SUCCESS',
    'GET_DID_COUNTRY_FAILED',
)<undefined, undefined, undefined>();

export const getDIDProviderInfo = createAsyncAction(
    'GET_DID_PROVIDER_INFO',
    'GET_DID_PROVIDER_INFO_SUCCESS',
    'GET_DID_PROVIDER_INFO_FAILED',
)<undefined, undefined, undefined>();

export const getDIDInventoryDetails = createAsyncAction(
    'GET_DID_DETAILS_INFO',
    'GET_DID_DETAILS_INFO_SUCCESS',
    'GET_DID_DETAILS_INFO_FAILED',
)<undefined, DIDInventoryDetailsSuccessPayload, undefined>();

export const getDIDAreas = createAsyncAction(
    'GET_DID_AREAS',
    'GET_DID_AREAS_SUCCESS',
    'GET_DID_AREAS_FAILED',
)<GetDidAreasRequestPayload, DIDAreaInfo[], undefined>();

export const getDidProviderNumbers = createAsyncAction(
    'GET_DID_PROVIDERS_NUMBERS',
    'GET_DID_PROVIDERS_NUMBERS_SUCCESS',
    'GET_DID_PROVIDERS_NUMBERS_FAILED',
)<GetDidNumbersRequestPayload, DIDNumberListInfo[], undefined>();

export const postProvisionNumber = createAsyncAction(
    'ADD_PROVISION_NUMBER',
    'ADD_PROVISION_NUMBER_SUCCESS',
    'ADD_PROVISION_NUMBER_FAILED',
)<ProvisionDIDRequest, undefined, undefined>();

import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import TextField from '../TextField/TextField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './utils';
import { useFormikContext } from 'formik';
import { ActionsFormType } from '../Forms/AutoAttendantsMenus/ActionsForm.utils';
import { SettingsFormType } from '../Forms/AutoAttendantsMenus/SettingsForm.utils';

const DisconnectCall = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { values, handleChange } = useFormikContext<
        SettingsFormType & ActionsFormType
    >();

    return (
        <>
            <p className={classes.activeHeader}>
                {t('screens:autoAttendants.disconnectTheCallAfterInputErrors')}
            </p>
            <Grid
                className={classNames(
                    classes.centeredGrid,
                    classes.numberPickerGrid,
                )}
            >
                <TextField
                    id="errorsCount"
                    label={t('screens:autoAttendants.errorCount')}
                    onChange={handleChange}
                    value={values.errorsCount}
                    type="number"
                    iconPosition="end"
                    className={classes.numberInput}
                    dataQa="incomingform-answering-timeout-select"
                    inputProps={{
                        inputProps: {
                            min: 1,
                            max: 100,
                        },
                        pattern: '[0-9]*',
                    }}
                    widthFromLiableText
                />

                <IconWithTooltip
                    dataQa="play-before-action-tooltip"
                    tooltipText={t('tooltips:autoAttendants.errorCount')}
                />
            </Grid>
        </>
    );
};

export default DisconnectCall;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ModeCell {
    mode?: string;
}

export enum CallProcessingModeName {
    Normal = 'Normal (Default)',
}

export enum CallProcessingModeNameMapping {
    Normal = 'Default',
}

const useStyles = makeStyles(() => ({
    mode: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 400,
        color: Colors.Gray9,
    },
    other: {
        color: Colors.Primary,
    },
}));

const ModeCellComponent: React.VFC<ModeCell> = ({ mode }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const modeText =
        mode == CallProcessingModeName.Normal
            ? t('screens:extensions.defaultMode')
            : mode;

    return (
        <span
            className={classNames(
                classes.mode,
                mode != CallProcessingModeName.Normal && classes.other,
            )}
        >
            {modeText}
        </span>
    );
};

export default ModeCellComponent;

import React, {useEffect, useMemo, useState} from 'react';
import Box from '@material-ui/core/Box';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import TextField from '../TextField/TextField';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import {CancelDIDAssignmentRequest} from '../../store/types/CustomerDIDNumber';
import Radio from '../Radio/Radio';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../store/actions';
import {ReduxState} from '../../store/types';
import Loader from '../Loader/Loader';
import {isAssigned, mapToName} from '../../views/DidNumbers/helpers';
import {useFormik} from 'formik';
import {ExtensionType} from '../../store/types/Extension';
import {
    AccountListRequest,
    AccountListResponse,
} from '../../store/types/Account';
import JSONFormData from '../../utils/JSONFormData';
import {AxiosResponse} from 'axios';
import {api} from '../../store/services/axios';
import AsyncSelectField from '../AsyncSelectField/AsyncSelectField';
import {Account} from '../../services/endpoints';
import {
    AssignDIDNumberFormProps,
    AssignDIDNumberDialogProp,
    useStyles,
} from './AssignDIDNumberDialog.utils';
import CustomizedTooltip from '../Tooltip/Tooltip';
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import {Permission} from "../../store/types/Permission";

const AssignDIDNumberDialog: React.FC<AssignDIDNumberDialogProp> = (
    {
        item,
        isOpen,
        clickOnClose,
    }) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();
    const [reassignMode, setReassignMode] = useState(false);
    const [inputValue, onInputChangeRaw] = useState<string>('');
    const {accountsToAssign, assigned} = useSelector((state: ReduxState) => state.didNumbers,);
    const {session_id, csrf_token} = useSelector((state: ReduxState) => state.auth);

    const addLabel = (item: ExtensionType): ExtensionType => {
        return {...item, label: mapToName(item, t), value: item.i_account};
    };

    const fetchAssignedAccount = () => {
        if (item?.master_account_id) {
            dispatch(
                actions.getAccountByIAccount.request({
                    master_account_id: item.master_account_id,
                }),
            );
        }
    };

    useEffect(() => {
        if (accountsToAssign.reassigned) {
            setTimeout(() => {
                location.reload();
            }, 2000);
        }
    }, [accountsToAssign.reassigned]);

    const initialValues: AssignDIDNumberFormProps = useMemo(
        () => ({
            reassignMode,
            item: item,
            assignToAccount:
                item?.master_account_id && assigned
                    ? addLabel(assigned)
                    : undefined,
        }),
        [reassignMode, item, accountsToAssign, assigned],
    );

    useEffect(() => {
        if (item) {
            setReassignMode(isAssigned(item));
            fetchAssignedAccount();
        }
    }, [item]);

    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        dirty,
    } = useFormik<AssignDIDNumberFormProps>({
        initialValues,
        onSubmit: () => {
            let previouslyAssignedTo:
                | CancelDIDAssignmentRequest
                | undefined = undefined;
            if (item) {
                previouslyAssignedTo = isAssigned(item)
                    ? {
                        i_did_number: item?.i_did_number,
                        i_customer: item?.i_customer,
                        dont_release_to_pool: 1,
                    }
                    : undefined;
            }

            if (values.assignToAccount && item?.i_did_number) {
                const {i_account} = values.assignToAccount;

                if (values.assignToAccount.i_account != item.i_master_account) {
                    dispatch(
                        actions.assignDIDNumberToAccount.request({
                            assignTo: {
                                i_did_number: item.i_did_number,
                                i_master_account: i_account,
                            },
                            previousAssigned: previouslyAssignedTo,
                            callback: () => {
                                clickOnClose && clickOnClose();
                            },
                        }),
                    );
                }
            } else if (
                item?.i_did_number &&
                reassignMode &&
                previouslyAssignedTo
            ) {
                dispatch(
                    actions.cancelDIDNumberAssignet.request({
                        ...previouslyAssignedTo,
                        callback: () => {
                            clickOnClose && clickOnClose();
                        },
                    }),
                );
            }
        },
        enableReinitialize: true,
    });

    const onInputChange = (newInputValue: string) => {
        onInputChangeRaw(newInputValue);
    };

    const loadOptions = async (search: string, prevOptions: unknown[]) => {
        const limit = 30;

        const params: Partial<AccountListRequest> = {
            id: search ? `%${search}%` : undefined,
            i_customer: item?.i_customer,
            free_of_extensions: 0,
            get_not_closed_accounts: 1,
            get_only_real_accounts: 1,
            get_total: 1,
            limit: 30,
            offset: prevOptions.length,
        };
        const body = new JSONFormData(session_id || '', csrf_token || '');
        body.setParams(params);

        const res: AxiosResponse<AccountListResponse> = await api.post(
            Account.GetAccountList,
            body,
        );
        const items = res.data.account_list.map(addLabel);

        return {
            options: items,
            hasMore: res.data.total > prevOptions.length + limit,
        };
    };

    const renderCountryArea = () => {

        const countryName = values.item?.country_name ? values.item?.country_name : null;
        const areaName = values.item?.area_name ? values.item?.area_name : null;

        if (
            (countryName && areaName && countryName?.length + areaName?.length > 27) ||
            (countryName && countryName.length > 27)
        ) {
            return (
                <CustomizedTooltip
                    title={
                        countryName && areaName
                            ? `${countryName} / ${areaName}`
                            : countryName || ''
                    }
                    copy={false}
                >
                    <div>
                        <TextField
                            value={
                                countryName &&
                                areaName
                                    ? `${countryName} / ${areaName}`
                                    : countryName || '—'
                            }
                            label={t('screens:didNumbers.countryArea')}
                            dataQa={'did-country-input'}
                            readOnly
                        />
                    </div>
                </CustomizedTooltip>
            );
        }
        return (
            <TextField
                value={
                    countryName && areaName
                        ? `${countryName} / ${areaName}`
                        : countryName || '—'
                }
                label={t('screens:didNumbers.countryArea')}
                dataQa={'did-country-input'}
                readOnly
            />
        );
    };

    return (
        <PermissionProvider permission={Permission.Inventory.DIDNumbers.DIDDetails.value}>

            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'did-numbers-edit-modal'}
                header={t('screens:didNumbers.editDIDNumberHeaderText')}
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                        dataQa={'did-edit-modal-cancel'}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            (values.reassignMode && !values.assignToAccount)
                        }
                        onClick={handleSubmit}
                        dataQa={'did-numbers-edit-modal-save'}
                        primary
                    />,
                ]}
            >
                {accountsToAssign.isLoading ? (
                    <Loader dataQa={'wait-for-account-to-assign'}/>
                ) : (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        data-testid="assign-did-number-form"
                    >
                        <Grid className={classes.itemsContainer}>
                            <Box className={classes.twoItems}>
                                <TextField
                                    value={values.item?.did_number}
                                    readOnly={!!values.item?.did_number}
                                    dataQa={'did-number-input'}
                                    label={t(
                                        'screens:didNumbers.editDIDNumberText',
                                    )}
                                />
                                {renderCountryArea()}
                            </Box>
                        </Grid>
                        <Grid className={classes.itemsContainer}>
                            <Box className={classes.marginBottom}>
                                <Radio
                                    label={t('screens:didNumbers.assigned')}
                                    dataQa={'did-reassign-radio'}
                                    checked={values.reassignMode}
                                    onChange={() => {
                                        setFieldValue('reassignMode', true);
                                    }}
                                />
                                <Radio
                                    label={t(
                                        'screens:didNumbers.editDIDUnassignedText',
                                    )}
                                    dataQa={'did-unassigned-radio'}
                                    checked={!values.reassignMode}
                                    onChange={() => {
                                        setFieldValue('reassignMode', false);
                                        setFieldValue('assignToAccount', undefined);
                                    }}
                                />
                            </Box>
                            {values.reassignMode && (
                                <AsyncSelectField
                                    title={t('screens:didNumbers.assignTo')}
                                    loadOptions={loadOptions}
                                    onInputChange={onInputChange}
                                    value={values.assignToAccount}
                                    onChange={(value) => {
                                        setFieldValue('assignToAccount', value);
                                    }}
                                    cacheUniqs={[inputValue]}
                                    maxMenuHeight={140}
                                    dataQa={'select-extension-to-assing'}
                                    defaultValue={{
                                        label: t(
                                            'screens:didNumbers.noneUnassigned',
                                        ),
                                        value: null,
                                    }}
                                />
                            )}
                        </Grid>
                    </form>
                )}
            </DialogContainer>
        </PermissionProvider>

    );
};

export default AssignDIDNumberDialog;

import {ActiveCall} from "../../store/reducers/extensions/extensions/reducer";
import React from "react";
import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    callsRow: {
        display:"flex",
        fontSize: 14
    },

    callsCount: {
        fontWeight: "bold",
        marginRight: 5
    }
}));

interface ActiveCallsInterface
{
    data: ActiveCall[]
}

const ActiveCalls:React.VFC<ActiveCallsInterface> = ({data}) => {

    const classes = useStyles();

    const output = data.map((item,index) => {
        return (
            <div key={'row-'+index} className={classes.callsRow}>
                <div className={classes.callsCount}>{item.count}</div>
                <div>{item.name}</div>
            </div>
        )
    })

    return <div>{output}</div>
}

export default ActiveCalls;
import React from 'react';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { greyColor3 } from '../../styles/Colors';
import Switch from '../Switch/Switch';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    didLabel: {
        fontSize: 16,
        color: greyColor3,
    },
}));

type ExtensionStatusProps = {
    blocked?: boolean;
    dataQa?: string;
    dataTestId?: string;
    onSwitchClick?: () => void;
    customClasses?: { status?: string };
};

const ExtensionStatus: React.FC<ExtensionStatusProps> = ({
    blocked = false,
    dataQa,
    dataTestId = '',
    onSwitchClick,
    customClasses,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const statusText = !blocked
        ? t('extensions.enabled')
        : t('extensions.disabled');

    return (
        <div className={classes.root} data-qa={dataQa} data-testid={dataTestId}>
            {onSwitchClick ? (
                <Switch
                    checked={!blocked}
                    onChange={onSwitchClick}
                    dataQa={'extension-details-status-switcher'}
                    dataTestId={'extension-details-status-switcher'}
                />
            ) : (
                <span className={classes.didLabel}>
                    {t('extensions.status')}
                </span>
            )}
            <span className={classNames(customClasses?.status)}>
                {statusText}
            </span>
        </div>
    );
};

export default ExtensionStatus;

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomizedTooltip from '../Tooltip/Tooltip';
import {
    errorColor,
    lightGraySecondary,
    supportColor,
    secondaryColor2,
} from '../../styles/Colors';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';

const MAX_GROUP_MEMBERS_ITEMS_DISPLAYED = 5;

export type MembersListProps = {
    extensions: any[];
    ringGroups: any[];
};

const useStyles = makeStyles(() => ({
    ringGroupMembersContainer: {
        overflow: 'hidden',
    },
    memberDot: {
        height: 9,
        width: 9,
        borderRadius: '50%',
        marginRight: 5,
        marginTop: 5,
    },
    memberRedDot: {
        backgroundColor: errorColor,
    },
    memberGreenDot: {
        backgroundColor: supportColor,
    },
    emptyText: {
        color: lightGraySecondary,
    },
    listContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipListContainer: {
        width: 134,
    },
    rightListMargin: {
        marginRight: 10,
    },
    listItem: {
        display: 'flex',
    },
    itemsHeader: {
        fontSize: 12,
        lineHeight: '18px',
        color: lightGraySecondary,
        whiteSpace: 'nowrap',
    },
    itemTitle: {
        fontSize: 14,
        lineHeight: '21px',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    itemAndMore: {
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: secondaryColor2,
        },
    },
    listItemWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    tooltipContainer: {
        padding: '14px 19px !important',
    },
}));

const MembersList: React.VFC<MembersListProps> = ({
    extensions,
    ringGroups,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!extensions.length && !ringGroups.length) {
        return (
            <OverflowTooltip
                text={t('screens:ringGroups.emptyRingGroup')}
                tooltip={t('screens:ringGroups.emptyRingGroup')}
                copy={false}
                classes={{ text: classes.emptyText }}
            />
        );
    }

    const renderGroup = (
        list: any[],
        startSliceNumber?: number,
        endSliceNumber?: number,
    ) => {
        return list.slice(startSliceNumber, endSliceNumber).map((item) => {
            const name = `${item.id} ${item.name ? ` - ${item.name}` : ''}`;

            return (
                <div
                    key={'linkedItem' + item.id}
                    className={classes.listItem}
                    data-qa="group-member-item"
                >
                    <CustomizedTooltip
                        interactive={true}
                        className={classes.listItemWrapper}
                        dataQa="group-member-dot"
                        title={
                            item.hunt_active === 'Y' && item.hunt_expire !== 0
                                ? t(
                                      'screens:ringGroups.availableRingGroupMember',
                                  )
                                : t(
                                      'screens:ringGroups.unavailableRingGroupMember',
                                  )
                        }
                        copy={false}
                        type="large"
                    >
                        <div
                            className={classNames(
                                classes.memberDot,
                                item.hunt_active === 'Y' &&
                                    item.hunt_expire !== 0
                                    ? classes.memberGreenDot
                                    : classes.memberRedDot,
                            )}
                        />
                    </CustomizedTooltip>
                    <OverflowTooltip
                        text={name}
                        tooltip={name}
                        copy={false}
                        classes={{ text: classes.itemTitle }}
                        dataQa="group-member-item-title"
                    />
                </div>
            );
        });
    };

    return (
        <div className={classes.ringGroupMembersContainer}>
            {!!ringGroups.length && (
                <div
                    className={classes.listContainer}
                    data-qa="group-member-ring-groups"
                >
                    <OverflowTooltip
                        text={t('screens:ringGroups.includedRingGroups')}
                        tooltip={t('screens:ringGroups.includedRingGroups')}
                        copy={false}
                        classes={{ text: classes.itemsHeader }}
                    />
                    {renderGroup(
                        ringGroups,
                        0,
                        MAX_GROUP_MEMBERS_ITEMS_DISPLAYED,
                    )}
                </div>
            )}
            {!!extensions.length &&
                MAX_GROUP_MEMBERS_ITEMS_DISPLAYED - ringGroups.length > 0 && (
                    <div
                        className={classes.listContainer}
                        data-qa="group-member-extensions"
                    >
                        <OverflowTooltip
                            text={t('screens:ringGroups.includedExtensions')}
                            tooltip={t('screens:ringGroups.includedExtensions')}
                            copy={false}
                            classes={{ text: classes.itemsHeader }}
                        />
                        {renderGroup(
                            extensions,
                            0,
                            MAX_GROUP_MEMBERS_ITEMS_DISPLAYED -
                                ringGroups.length,
                        )}
                    </div>
                )}
            {ringGroups.length +
                extensions.length -
                MAX_GROUP_MEMBERS_ITEMS_DISPLAYED >
                0 && (
                <CustomizedTooltip
                    interactive={true}
                    dataQa=""
                    classes={{
                        tooltipContainer: classes.tooltipContainer,
                    }}
                    copy={false}
                    title={
                        <div className={classes.listItem}>
                            {!!ringGroups.length &&
                                ringGroups.length >
                                    MAX_GROUP_MEMBERS_ITEMS_DISPLAYED && (
                                    <div
                                        className={classNames(
                                            classes.listContainer,
                                            classes.tooltipListContainer,
                                            !!extensions.length &&
                                                classes.rightListMargin,
                                        )}
                                    >
                                        <span className={classes.itemsHeader}>
                                            {t(
                                                'screens:ringGroups.includedRingGroups',
                                            )}
                                        </span>
                                        {renderGroup(
                                            ringGroups,
                                            MAX_GROUP_MEMBERS_ITEMS_DISPLAYED,
                                        )}
                                    </div>
                                )}
                            {!!extensions.length && (
                                <div
                                    className={classNames(
                                        classes.listContainer,
                                        classes.tooltipListContainer,
                                    )}
                                >
                                    <span className={classes.itemsHeader}>
                                        {t(
                                            'screens:ringGroups.includedExtensions',
                                        )}
                                    </span>
                                    {renderGroup(
                                        extensions,
                                        MAX_GROUP_MEMBERS_ITEMS_DISPLAYED -
                                            ringGroups.length,
                                    )}
                                </div>
                            )}
                        </div>
                    }
                >
                    <span className={classes.itemAndMore}>
                        {t('screens:ringGroups.andXMore', {
                            count:
                                ringGroups.length +
                                extensions.length -
                                MAX_GROUP_MEMBERS_ITEMS_DISPLAYED,
                        })}
                    </span>
                </CustomizedTooltip>
            )}
        </div>
    );
};

export default MembersList;

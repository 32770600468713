import { createAsyncAction } from 'typesafe-actions';
import { UpdateServicePasswordPayload } from '../extensions/payloads';
import { ExtensionType } from '../../types/Extension';
import { APIError } from '../../types';

export const postServicePassword = createAsyncAction(
    'POST_SERVICE_PASSWORD',
    'POST_SERVICE_PASSWORD_SUCCESSES',
    'POST_SERVICE_PASSWORD_FAILED',
)<
    UpdateServicePasswordPayload,
    { i_account: number; account_inf: Partial<ExtensionType> },
    APIError
>();

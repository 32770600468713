import React from "react";
import {ReactComponent as Warning} from '../../../src/assets/warning.svg';
import {makeStyles} from "@material-ui/core/styles";
import {Fade} from "@material-ui/core";


interface WarningTabLabelProps {
    label?: string,
    showWarning?: boolean
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",

        '& svg': {
            marginLeft: '-20px',
            position: 'absolute'
        }
    }
}));

const WarningTabLabel: React.VFC<WarningTabLabelProps> = ({label, showWarning}) => {
    const classes = useStyles();

    return (<span className={showWarning ? classes.root : undefined}>
        {showWarning && (<Fade in={true} timeout={500} appear={true}><Warning/></Fade>)}
        {label}
    </span>)
}

export default WarningTabLabel;
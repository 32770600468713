import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodInfo } from '../../../store/types/Payment';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import PaymentMethodIcon from '../PaymentMethodIcon';
import { useStyles } from './PaymentMethodDetails.utils';

type PaymentMethodDetailsProps = {
    method: PaymentMethodInfo;
};

const PaymentMethodDetails: React.VFC<PaymentMethodDetailsProps> = ({
    method,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <PaymentMethodIcon method={method.payment_method} />

            {method.number && (
                <div className={classes.columnContainer}>
                    <span className={classes.title}>
                        <span className={classes.header}>
                            {method.payment_method}
                        </span>
                        {' •••• '}
                        {method.number.substring(method.number.length - 4)}
                    </span>
                    <span className={classes.secondaryText}>
                        {t('screens:billing.expires')} {method.exp_month}/
                        {method.exp_year.substring(2)}
                    </span>
                </div>
            )}

            {!method.number && (
                <>
                    <div className={classes.columnContainer}>
                        <span className={classes.title}>
                            {t('screens:billing.bankAccount')}
                        </span>
                        <OverflowTooltip
                            tooltip={method.name}
                            text={method.name}
                            copy={false}
                            classes={{ text: classes.header }}
                        />
                    </div>
                    <div
                        className={classNames(
                            classes.columnContainer,
                            classes.secondColumn,
                        )}
                    >
                        <span className={classes.secondaryText}>
                            {t('screens:billing.accountNumber')}
                            {' •••• '}
                            {[...method.account_number]
                                .reverse()
                                .join('')
                                .substring(0, 3)}
                        </span>
                        <span className={classes.secondaryText}>
                            {t('screens:billing.bankRoutingNumber')}
                            {' •••• '}
                            {[...method.account_number]
                                .reverse()
                                .join('')
                                .substring(0, 3)}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default PaymentMethodDetails;

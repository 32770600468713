import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IvrFlowDiagram from './IvrFlowDiagram/IvrFlowDiagram';
import { EditAutoAttendantForm } from '../../../views/AutoAttendants/Details/AutoAttendantsDetails';
import { Colors } from '../../../styles/Colors';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        height: 'calc(100%)',
        position: 'relative',
        backgroundColor: Colors.SmokeBackground,

        '& .react-flow__controls': {
            left: 'unset',
            right: 60,
            bottom: 30,
        },
    },
}));

type MenuDragFromProps = {
    handleSubmitForm?: (formData: Partial<EditAutoAttendantForm>) => void;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
};

const MenuDragForm: React.VFC<MenuDragFromProps> = ({
    handleDirtyChange,
    handleSetSubmitFunc,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <IvrFlowDiagram
                handleDirtyChange={handleDirtyChange}
                handleSetSubmitFunc={handleSetSubmitFunc}
            />
        </div>
    );
};

export default MenuDragForm;

import React, {useEffect, useMemo, useState} from 'react';
import ExtensionTitle from '../../../components/Extensions/ExtensionTitle';
import ExtensionDIDNumber from '../../../components/Extensions/ExtensionDIDNumber';
import ExtensionStatus from '../../../components/Extensions/ExtensionStatus';
import {useStyles} from './utils';
import history from '../../../history';
import {Routes} from '../../../routes/routes';
import {getTabNumber} from '../../../utils/getTabNumber';
import {useTranslation} from 'react-i18next';
import DetailsForm from '../../../components/Forms/FaxMailboxes/DetailsForm/DetailsForm';
import {Formik} from 'formik';
import {
    EditFaxMailboxForm,
    ExtensionFaxMailboxValidationSchema,
} from '../../../components/Forms/FaxMailboxes/DetailsForm/utils';
import {useEditFaxMailboxesFormData} from '../../../hooks/useEditFaxMailboxesFormData';
import {actions} from '../../../store';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {YesNo} from '../../../store/types/CallScreening';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import PlanForm from '../../../components/Forms/Extensions/PlanForm';
import {extensionPlanFormValidationSchema} from '../../../components/Forms/Extensions/PlanForm.utils';
import usePageTitle from '../../../hooks/usePageTitle';
import DetailsWrapper from '../../../components/DetailsWraper/DetailsWrapper';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import {generateTabs} from '../../../utils/generateTabs';
import {useRawPermissions} from '../../../hooks/usePermissions';
import {MainProductType} from '../../../components/Extensions/MainProductSelectFiled/utils';
import {toast} from 'react-hot-toast';
import i18n from '../../../services/i18n';
import NotFound from '../../NotFound/NotFound';
import {GET_FAX_MAILBOX_ERROR_NOT_FOUND} from '../../../store/sagas/faxMailboxes/saga';
import ErrorNotification from "../../../components/TabErrors/ErrorNotification";

type FaxMailboxesDetailsProps = {
    id: string;
    forceEnableSave?: boolean;
};

const formValidationSchema = ExtensionFaxMailboxValidationSchema.concat(
    extensionPlanFormValidationSchema,
);

const FaxMailboxDetails: React.VFC<FaxMailboxesDetailsProps> = (
    {
        id,
        forceEnableSave,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const permissions = useRawPermissions();

    const backToList = () => {
        history.push(Routes.FaxMailboxes);
    };

    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
    }>();

    const [wasDeleted, setWasDeleted] = useState(false)

    const {isLoadingDetails, deletingInProgress, apiError} = useSelector(
        (state: ReduxState) => state.faxMailboxes,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.faxMailboxes.isFormSending,
    );

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const {initialValues, data} = useEditFaxMailboxesFormData(id);

    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1]));

    const status = data.extensionInfo?.account_info?.blocked;

    useEffect(() => {
        dispatch(actions.getFaxMailboxesDetails.request({id: id}));
    }, []);

    const isApiError = useMemo(() => {
        return apiError?.faultcode === GET_FAX_MAILBOX_ERROR_NOT_FOUND;
    }, [apiError]);

    if (isApiError) {
        return (
            <NotFound/>
        );
    }

    if (isLoadingDetails) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="auto-attendant-loader"/>
            </div>
        );
    }

    const handleStatusChange = () => {
        if (data.extensionInfo?.i_account) {
            dispatch(
                actions.setFaxMailboxesStatus.request({
                    account_info: {
                        i_account: data.extensionInfo?.i_account.toString(),
                        blocked: status == YesNo.Yes ? YesNo.No : YesNo.Yes,
                    },
                }),
            );
        }
    };

    const onSubmitFrom = (values: EditFaxMailboxForm) => {
        onSave(values, false);
    };

    const onSave = (values: EditFaxMailboxForm, blockRedirection: boolean) => {

        if (
            data.extensionInfo?.i_account &&
            data.extensionInfo.account_info?.um_domain
        ) {
            dispatch(
                actions.editFaxMailbox.request({
                    i_c_ext: data.extensionInfo?.i_c_ext,
                    i_account: data.extensionInfo?.i_account,
                    initialValues,
                    changedValues: values,
                    redirectTab: tabIndex,
                    domain: data.extensionInfo.account_info?.um_domain,
                    id: data.extensionInfo.id,
                    i_product: data.extensionInfo.account_info.i_product,
                    blockRedirection: blockRedirection,
                }),
            );
        } else {
            toast(i18n.t<string>('common:cantSaveChanges'));
        }
    };

    const closeRemoveDialog = () =>
        setRemoveModal({
            ...removeModal,
            isOpen: false,
        });

    const deleteExtension = () => {
        if (removeModal?.id && removeModal?.i_customer) {
            dispatch(
                actions.deleteCustomerFaxMailbox.request({
                    id: removeModal.id,
                    i_customer: removeModal.i_customer,
                    i_c_ext: data.extensionInfo?.i_c_ext,
                    callback: () => {
                        history.push(Routes.FaxMailboxes);
                    },
                }),
            );

            setRemoveModal({...removeModal, isOpen: false});
            setWasDeleted(true);
        }
    };

    const showRemoveExtensionModal = (
        id: string,
        i_customer: number,
        name?: string,
    ) => {
        setRemoveModal({
            isOpen: true,
            id,
            name: name,
            i_customer: i_customer,
        });
    };

    const {tabs, tabNames} = generateTabs(
        [
            {
                tab: (
                    <DetailsForm
                        i_account={data.extensionInfo?.i_account}
                        primaryAccountDidNumber={
                            data.extensionInfo?.account_info?.did_number
                        }
                        key={'tab1'}
                        tabIndex={0}
                        tabName={t('screens:extensions.extension')}
                        callback={() => dispatch(actions.saveForms({visible: true}))}
                    />
                ),
                title: t('screens:extensions.extension'),
                permission:
                Permission.CloudPBX.FaxMailboxes.FaxMailboxDetails.Extension
                    .value,
            },
            {
                tab: (
                    <PlanForm
                        i_account={data.extensionInfo?.i_account}
                        key={'tab2'}
                        productType={MainProductType.FaxMailboxes}
                        tabIndex={1}
                        tabName={t('screens:extensions.plan')}
                    />
                ),
                title: t('screens:extensions.plan'),
                permission:
                Permission.CloudPBX.FaxMailboxes.FaxMailboxDetails.Plan
                    .value,
            },
        ],
        permissions,
    );

    const onTabChange = (_: React.ChangeEvent<{}>, index: any) => {
        dispatch(actions.saveForms({visible: false}))
        setTabIndex(index);
    }

    const top = (
        <div className={classes.extensionHeader}>
            <div className={classes.extensionTop}>
                <PermissionProvider
                    permission={
                        Permission.CloudPBX.FaxMailboxes.FaxMailboxDetails.value
                    }
                >
                    <ExtensionTitle
                        headerText="Fax mailbox"
                        headerName={data.extensionInfo?.extension_id?.toString()}
                        subTitle={
                            data.extensionInfo?.name ||
                            t('screens:autoAttendants.unnamed')
                        }
                        isBlocked={status == YesNo.Yes}
                        onChevronClick={backToList}
                        dataQa={'extension-details-title'}
                        customClasses={{
                            header: classes.header,
                        }}
                    />
                    <div className={classes.extensionStatus}>
                        {data.extensionInfo?.numbers?.length ? (
                            <ExtensionDIDNumber
                                extNumbers={data.extensionInfo?.numbers}
                                dataQa={'extension-details-did-numbers'}
                            />
                        ) : (
                            ''
                        )}
                        <ExtensionStatus
                            onSwitchClick={handleStatusChange}
                            blocked={
                                data.extensionInfo?.account_info?.blocked ==
                                YesNo.Yes
                            }
                            dataQa={'extension-details-status'}
                            customClasses={{
                                status: classes.status,
                            }}
                        />
                    </div>
                </PermissionProvider>
            </div>
        </div>
    );

    return (
        <Formik<EditFaxMailboxForm>
            initialValues={initialValues}
            onSubmit={onSubmitFrom}
            enableReinitialize
            validateOnChange={false}
            validationSchema={formValidationSchema}
        >
            {({submitForm, dirty, values, isValid}) => (
                <>
                    <DetailsWrapper
                        tabs={tabs}
                        tabsNames={tabNames}
                        top={top}
                        defaultTabIndex={tabIndex}
                        onTabChange={onTabChange}
                        onSavePress={() => {
                            submitForm().then(() => dispatch(actions.saveForms({visible: true})))
                        }}
                        onBackPress={backToList}
                        onDeletePress={() => {
                            if (
                                data.extensionInfo?.id &&
                                data.extensionInfo?.i_customer
                            ) {
                                showRemoveExtensionModal(
                                    data.extensionInfo?.id,
                                    data.extensionInfo?.i_customer,
                                    data.extensionInfo?.name ||
                                    t('screens:autoAttendants.unnamed'),
                                );
                            }
                        }}
                        deleteText={
                            data.extensionInfo?.delete_enable
                                ? t('screens:faxMailboxes.deleteFaxMailbox')
                                : undefined
                        }
                        showActionButtons
                        preventIsOpen={dirty && !wasDeleted}
                        preventSavePress={() => onSave(values, true)}
                        saveEnable={forceEnableSave || dirty}
                        deleteEnable
                        deletePermission={
                            Permission.CloudPBX.FaxMailboxes.DeleteFaxMailbox
                                .value
                        }
                    >
                        {isFormSaving || deletingInProgress ? (
                            <Loader
                                dataQa="edit-auto-attendant-sending-loader"
                                absolutePosition
                            />
                        ) : null}
                        <AlertDialog
                            isOpen={!!removeModal?.isOpen}
                            dataQa={'delete-fax-mailbox-modal'}
                            contentClass="medium-width-modal-delete"
                            hideHeader={true}
                            description={t('screens:faxMailboxes.deleteMsg', {
                                name: removeModal?.name,
                            })}
                            className={classes.deleteDialogButtons}
                            dialogActionsButtons={[
                                <DialogButton
                                    key="cancel"
                                    label={t('common:cancel')}
                                    onClick={closeRemoveDialog}
                                    dataTestId={
                                        'fax-mailbox-dialog-cancel-button'
                                    }
                                />,
                                <DialogButton
                                    key="delete"
                                    label={t('common:delete')}
                                    onClick={deleteExtension}
                                    dataTestId={
                                        'fax-mailbox-dialog-delete-button'
                                    }
                                />,
                            ]}
                        />

                        {isFormSaving || deletingInProgress ? (
                            <Loader
                                dataQa="edit-auto-attendant-sending-loader"
                                absolutePosition
                            />
                        ) : undefined}
                        <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={saveClicked}/>
                    </DetailsWrapper>
                </>
            )}
        </Formik>
    );
};

export default FaxMailboxDetails;

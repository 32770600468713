import React, { useMemo } from 'react';
import { FaxFormat } from '../Forms/AutoAttendants/Incoming/utils';
import SelectField from '../SelectField/SelectField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';

interface FaxFormatSelectProps {
    label?: string;
    id?: string;
    onChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
    value?: string | OptionFormat;
    tooltipMsg?: string;
    copyTooltip?: boolean;
    dataQa?: string;
    tooltipDataQa?: string;
    className?: string;
    classes?: {
        helperText?: string;
        container?: string;
        iconContainer?: string;
        option?: string;
    };
    required?: boolean;
    setFieldError?: (field: string, value: string | undefined) => void;
    helperText?: string;
}

const FaxFormatSelect: React.VFC<FaxFormatSelectProps> = ({
    id,
    label,
    onChange,
    value,
    tooltipMsg,
    copyTooltip,
    dataQa,
    tooltipDataQa,
    classes: customClasses = {},
    className,
    required = false,
    setFieldError,
    helperText,
}) => {
    const { t } = useTranslation();

    const faxFormatOptions = useMemo(
        () =>
            Object.values(FaxFormat).map((v) => ({
                name: t(`enums:incoming.${v}`),
                value: v,
            })),
        [],
    );
    return (
        <SelectField
            id={id}
            label={label}
            onChange={onChange}
            items={faxFormatOptions}
            value={
                faxFormatOptions.find((v) => v.value == value || v == value) ||
                null
            }
            dataQa={dataQa}
            className={className}
            classes={customClasses}
            icon={
                tooltipMsg ? (
                    <IconWithTooltip
                        dataQa={tooltipDataQa}
                        tooltipText={tooltipMsg}
                        copy={copyTooltip}
                    />
                ) : undefined
            }
            getOptionLabel={(v: { name: string }) => v.name}
            getOptionSelected={(v: { value: FaxFormat }) =>
                v.value === value || v == value
            }
            helperText={helperText}
            setFieldError={setFieldError}
            required={required}
            disableClearable
        />
    );
};

export default FaxFormatSelect;

interface OptionFormat {
    name: string;
    value: any;
}

import { AxiosResponse } from 'axios';
import { api } from './axios';

import {
    GetCPConditionListRequestPayload,
    GetCPRuleListRequest,
} from '../actions/extensions/payloads';
import { AccountListRequest } from '../types/Account';
import { Account, Customer, DID } from '../../services/endpoints';
import JSONFormData from '../../utils/JSONFormData';

export async function postGetAccountList(
    sessionId: string,
    csrfToken: string,
    filters: AccountListRequest,
): Promise<AxiosResponse> {
    const body = new JSONFormData(sessionId, csrfToken);
    body.setParams(filters);

    return api.post(Account.GetAccountList, body);
}

export async function postGetCustomerNumbers(
    sessionId: string,
    csrfToken: string,
    iAccount: number,
): Promise<AxiosResponse> {
    const body = new JSONFormData(sessionId, csrfToken);
    body.setParams({i_account: iAccount});

    return api.post(DID.GetCustomerNumbers, body);
}

export async function postGetExtensionsList(
    sessionId: string,
    csrfToken: string,
    filters?: {},
): Promise<AxiosResponse> {
    const body = new JSONFormData(sessionId, csrfToken);
    body.setParams({ ...filters, get_main_office_extensions: 1 });

    return api.post(Customer.GetExtensionsList, body);
}

export async function postGetAccountInfo(sessionId: string, csrfToken: string, iAccount: number) {
    const body = new JSONFormData(sessionId, csrfToken);
    body.setParams({ detailed_info: '1', i_account: iAccount });

    return api.post(Account.GetAccountInfo, body);
}

export type accountInfoPayload = {
    i_account?: number;
    detailed_info?: number;
    get_service_features?: string[];
    id?:string,
    limit_alias_did_number_list?:number
};

export const SERVICE_FEATURE_CALL_PROCESSING = 'call_processing';

export const extensionsApi = {
    getAccountInfo: async (sessionId: string, csrfToken: string, params: accountInfoPayload) => {
        const body = new JSONFormData(sessionId, csrfToken);
        body.setParams(params);

        return api.post(Account.GetAccountInfo, body);
    },
    getCallScreeningRules: async (
        sessionId: string,
        csrfToken: string,
        params: GetCPRuleListRequest,
    ) => {
        const body = new JSONFormData(sessionId, csrfToken);
        body.setParams(params);

        return api.post(Account.GetCpRuleList, body);
    },
    getCallScreeningConditions: async (
        sessionId: string,
        csrfToken: string,
        params: GetCPConditionListRequestPayload,
    ) => {
        const body = new JSONFormData(sessionId, csrfToken);
        body.setParams(params);

        return api.post(Account.GetCpConditionList, body);
    },
};

export default extensionsApi;

import React from 'react';
import AutoPayments from './AutoPayments/AutoPayments';
import DefaultPaymentMethod from './DefaultPaymentMethod/DefaultPaymentMethod';
import { useStyles } from './PaymentMethod.utils';

const PaymentMethod = () => {
    const classes = useStyles();

    return (
        <div>
            <DefaultPaymentMethod />
            <div className={classes.separator} />
            <AutoPayments />
        </div>
    );
};

export default PaymentMethod;

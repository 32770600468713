import React, {useState} from "react";
import Tabs from "../../../Tabs/Tabs";
import {getTabNumber} from "../../../../utils/getTabNumber";
import i18next from "i18next";
import {generateTabs} from "../../../../utils/generateTabs";
import {Permission} from "../../../../store/types/Permission";
import {useRawPermissions} from "../../../../hooks/usePermissions";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../../../styles/Colors";
import PolicyModesTab from "./PolicyModesTab";
import TimeFiltersTab from "./TimeFiltersTab";
import ResponseMessagesTab from "./ResponseMessagesTab";
import AlertDialog from "../../../AlertDialog/AlertDialog";
import { DialogButton } from "../../../AlertDialog/DialogContainer";
import {useTranslation} from "react-i18next";
import { useDispatch } from "react-redux";
import { actions } from "../../../../store";
import { TimeFiltersPageFilterType } from "./NewTimeFilterDialog.utils";
import { OperationMode } from "../../../../store/actions/payloads";
import { CPConditionInfo } from "../../../../store/types/CallScreening";
import { ApiFile } from "../../../../store/types/ApiFile";
import { PeriodInfo } from "../../../../store/types/AccountFollowMeSettings";

export const tabsNames = [
    i18next.t<string>('screens:callSettings.modes'),
    i18next.t<string>('screens:callSettings.timeFilters'),
    i18next.t<string>('screens:callSettings.cellNumbersFrom'),
    i18next.t<string>('screens:callSettings.calledNumbersTo'),
    i18next.t<string>('screens:callSettings.responseMessages')
];

enum DialogType {
    mode = 'mode',
    timeFilter = 'timeFilter',
    callNumberFrom = 'callNumberFrom',
    callNumberTo = 'callNumberTo',
    responseMessage = 'responseMessage',
    interval = 'interval'
};

const useStyles = makeStyles(() => ({
    tabs: {
        '& header': {
            paddingLeft: 32,
            paddingRight: 32,
            zIndex: 5,
        },
        
        '& #wrapped-tabpanel-0': {
            padding: '0 !important',

            '& .MuiBox-root': {
                padding: '0 !important',

                '& .MuiTableCell-root': {
                    paddingLeft: 8
                }
            }
        },

        '& #wrapped-tabpanel-1': {
            padding: '0 !important',

            '& .MuiBox-root': {
                padding: '0 !important',
            }
        },
        
        '& #wrapped-tabpanel-2': {
            padding: '0 !important',

            '& .MuiBox-root': {
                padding: '0 !important',
            }
        },
        
        '& #wrapped-tabpanel-3': {
            padding: '0 !important',

            '& .MuiBox-root': {
                padding: '0 !important',
            }
        },

        '& #wrapped-tabpanel-4': {
            padding: '0 !important',

            '& .MuiBox-root': {
                padding: '0 !important',
            }
        },
    },
    tabsContainer:{
        backgroundColor: Colors.White,
        
        '& #wrapped-tab-0': {
            paddingLeft: 35.5,
            paddingRight: 35.5
        }
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '280px !important',
            width: '280px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: '232px !important',
        },
    },
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
}));

const CallScreeningsTabs = () => {
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2, 3, 4]));
    const permissions = useRawPermissions();
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        type?: DialogType;
        name?: string;
        id?: number;
        interval?: PeriodInfo;
        cpCondition?: CPConditionInfo;
    }>({
        isOpen: false,
    });
    
    const deleteDialogQuestion = () => {
        const defaultNameValue = '';
        switch(deleteDialog.type) {
            case DialogType.mode:
                return t('screens:callSettings.deleteMode', {
                    name: deleteDialog.name || defaultNameValue,
                });
            case DialogType.timeFilter:
                return t('screens:callSettings.deleteTimeFilter', {
                    name: deleteDialog.name || defaultNameValue,
                });
            case DialogType.callNumberFrom:
                return t('screens:callSettings.deleteCallNumberFrom', {
                    name: deleteDialog.name || defaultNameValue,
                });
            case DialogType.callNumberTo:
                return t('screens:callSettings.deleteCallNumberTo', {
                    name: deleteDialog.name || defaultNameValue,
                });
            case DialogType.responseMessage:
                return t('screens:callSettings.deleteResponseMessage', {
                    name: deleteDialog.name || defaultNameValue,
                });
            case DialogType.interval:
                return t('screens:callSettings.deleteInterval');
        }
        return defaultNameValue;
    };
    
    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            type: undefined,
            name: undefined,
            id: undefined,
            interval: undefined,
            cpCondition: undefined
        });
    };
    
    const handleDeleteAction = () => {
        switch(deleteDialog.type) {
            case DialogType.mode:
                dispatch(actions.deleteMode.request({
                    i_operation_mode: deleteDialog.id || 0,
                    callback: () => {
                        closeDeleteDialog();
                    },
                }));
            break;
            case DialogType.timeFilter:
            case DialogType.callNumberFrom:
            case DialogType.callNumberTo:
                dispatch(actions.deleteCpCondition.request({
                    i_cp_condition: deleteDialog.id || 0,
                    callback: () => {
                        closeDeleteDialog();
                    },
                }));
                break;
            case DialogType.responseMessage:
                dispatch(actions.deleteCustomerResponseMessage.request({
                    id: deleteDialog.id || 0,
                    callback: () => {
                        closeDeleteDialog();
                    },
                }));
                break;
            case DialogType.interval:
                dispatch(actions.editCpCondition.request({
                    object: {
                        ...deleteDialog.cpCondition,
                        time_window: {
                            period: deleteDialog.cpCondition?.time_window?.period || '',
                            definition_list: deleteDialog.cpCondition?.time_window?.definition_list
                                ?.filter(e => e !== deleteDialog.interval),
                        }
                    },
                    callback: () => {
                        closeDeleteDialog();
                    }
                }));
                break;
        }
    };

    const onDeleteModeHandler = (mode: OperationMode) => {
        setDeleteDialog({
            isOpen: true,
            type: DialogType.mode,
            name: mode.name,
            id: mode.i_operation_mode
        });
    };

    const onDeleteConditionBaseHandler = (object: CPConditionInfo, type: DialogType) => {
        setDeleteDialog({
            isOpen: true,
            type: type,
            name: object.name,
            id: object.i_cp_condition || 0
        });
    };

    const onDeleteTimeWindowHandler = (object: CPConditionInfo) => {
        onDeleteConditionBaseHandler(object, DialogType.timeFilter);
    };

    const onDeleteCallNumberFromHandler = (object: CPConditionInfo) => {
        onDeleteConditionBaseHandler(object, DialogType.callNumberFrom);
    };

    const onDeleteCallNumberToHandler = (object: CPConditionInfo) => {
        onDeleteConditionBaseHandler(object, DialogType.callNumberTo);
    };

    const onDeleteResponseMessageHandler = (object: ApiFile) => {
        setDeleteDialog({
            isOpen: true,
            type: DialogType.responseMessage,
            name: object.name,
            id: object.id || 0
        });
    };
    
    const onDeleteIntervalHandler = (object: PeriodInfo, cpCondition: CPConditionInfo) => {
        setDeleteDialog({
            isOpen: true,
            type: DialogType.interval,
            name: object.start_time,
            id: 0,
            interval: object,
            cpCondition: cpCondition
        });
    };

    const {tabNames, tabs} = generateTabs(
        [
            {
                title: tabsNames[0],
                permission: Permission.Calls.Settings.CallScreening.value,
                tab: (
                    <PolicyModesTab onDelete={onDeleteModeHandler} />),
            },
            {
                title: tabsNames[1],
                permission: Permission.Calls.Settings.CallScreening.value,
                tab: (<TimeFiltersTab
                        pageFilterType={TimeFiltersPageFilterType.TimeWindow}
                        onDelete={onDeleteTimeWindowHandler}
                        onDeleteInterval={onDeleteIntervalHandler}
                    />
                ),
            },
            {
                title: tabsNames[2],
                permission: Permission.Calls.Settings.CallScreening.value,
                tab: (<TimeFiltersTab pageFilterType={TimeFiltersPageFilterType.FromNumber} onDelete={onDeleteCallNumberFromHandler} />),
            },
            {
                title: tabsNames[3],
                permission: Permission.Calls.Settings.CallScreening.value,
                tab: (<TimeFiltersTab pageFilterType={TimeFiltersPageFilterType.ToNumber} onDelete={onDeleteCallNumberToHandler} />),
            },
            {
                title: tabsNames[4],
                permission: Permission.Calls.Settings.CallScreening.value,
                tab: (<ResponseMessagesTab onDelete={onDeleteResponseMessageHandler} />),
            }
        ],
        permissions,
    );

    return (<>
            <div className={classes.tabsContainer}>
                <Tabs
                    tabsNames={tabNames || []}
                    defaultTabIndex={tabIndex}
                    onChange={(_, index) => setTabIndex(index)}
                    className={classes.tabs}
                >
                    {tabs}
                </Tabs>
            </div>
            <AlertDialog
                className={classes.deleteDialog}
                contentClass={classes.contentDialog}
                isOpen={deleteDialog.isOpen}
                dataQa={'delete-item-dialog'}
                hideHeader
                description={<>{deleteDialogQuestion()}</>}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={closeDeleteDialog}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                            onClick={handleDeleteAction}
                    />,
                ]}
            />
        </>
    )
}

export default CallScreeningsTabs;
import React from 'react';
import { Colors } from '../../styles/Colors';
import { makeStyles } from '@material-ui/core/styles';
import PolicyComponent from './PolicyComponent';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import { useTranslation } from 'react-i18next';

interface CallScreeningActionInterface {
    responseMessage?: string;
    policy?: string;
    action?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        overflow: 'hidden',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },

    message: {
        display: 'grid',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
        color: Colors.Gray5,
    },
}));

const CallScreeningAction: React.VFC<CallScreeningActionInterface> = ({
    action,
    responseMessage,
    policy,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            {' '}
            <div className={classes.container}>
                <div className={classes.message}>
                    {!policy && (
                        <OverflowTooltip
                            tooltip={t('tooltips:callScreening.policyDetails', {
                                details: responseMessage,
                            })}
                            text={responseMessage}
                            copy={false}
                        />
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    <PolicyComponent name={policy} />
                </div>
                <div>{!policy && <b>{action}</b>}</div>
            </div>
        </>
    );
};

export default CallScreeningAction;

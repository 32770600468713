import React, { useEffect, useState } from 'react';
import CustomButton from '../../../components/Button/Button';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18n from '../../../services/i18n';
import TextField from '../../../components/TextField/TextField';
import SelectField from '../../../components/SelectField/SelectField';
import { Grid } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    filtersContainer: {
        marginTop: 40,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1040,
    },
    itemsContainer: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    inputsContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        '& .MuiFormControl-root': {
            marginRight: 18,
        },
    },
    button: {
        minWidth: 96,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    marginL: {
        marginLeft: 16,
    },
    filterItem: {
        maxWidth: 220,
        width: '25%',
    },
}));

type DevicesFiltersProps = {
    onSubmit?: () => void;
    handleChange?: (
        name?: string,
        email?: string,
        did?: string,
        status?: string,
    ) => void;
};

const statuses = [
    { value: '', label: i18n.t<string>('screens:devices.anyStatus') },
    { value: 'Y', label: i18n.t<string>('screens:faxMailboxes.disabled') },
    {
        value: 'N',
        label: i18n.t<string>('screens:faxMailboxes.enabled'),
    },
];

const Filters: React.FC<DevicesFiltersProps> = ({ onSubmit, handleChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [nameFilter, setNameFilter] = useState('');
    const [emailFilter, setEmailFilter] = useState('');
    const [didNumberFilter, setDidNumberFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState<
        | {
              label?: string;
              value?: string;
          }
        | undefined
    >({
        value: '',
        label: i18n.t<string>('screens:devices.anyStatus'),
    });

    useEffect(() => {
        if (handleChange != undefined) {
            handleChange(
                nameFilter,
                emailFilter,
                didNumberFilter,
                statusFilter ? statusFilter.value : undefined,
            );
        }
    }, [nameFilter, emailFilter, didNumberFilter, statusFilter]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit?.();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid item className={classes.filtersContainer}>
                <Grid item className={classes.itemsContainer}>
                    <Grid item className={classes.inputsContainer}>
                        <TextField
                            id="name"
                            label={t('common:name')}
                            value={nameFilter}
                            onChange={(e) => setNameFilter(e.target.value)}
                            dataQa={'filter-by-name'}
                            className={classes.filterItem}
                        />

                        <TextField
                            id={'email-filter-text-field'}
                            label={t('common:emailAddress')}
                            value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)}
                            dataQa={'filter-by-email'}
                            className={classes.filterItem}
                        />
                        <TextField
                            id="did-number"
                            label={t('screens:extensions.didNumber')}
                            value={didNumberFilter}
                            onChange={(e) => setDidNumberFilter(e.target.value)}
                            dataQa={'filter-by-did-number'}
                            className={classes.filterItem}
                        />
                        <SelectField
                            onChange={(e, value) =>
                                setStatusFilter(
                                    statuses.find((e) => e.label === value),
                                )
                            }
                            label={t('common:status')}
                            items={statuses.map((e) => e.label)}
                            value={statusFilter?.label}
                            dataQa="role-portal-user"
                            disableClearable
                            classes={{ container: classes.filterItem }}
                        />
                    </Grid>

                    <CustomButton
                        dataQa="search-button"
                        primary
                        accent
                        type="submit"
                        className={classes.marginL}
                    >
                        {t('common:search')}
                    </CustomButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default Filters;

import React from 'react';
import { Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

type BaseRouteProps = {
    path: string;
    component?: any;
    exact?: boolean;
    render?: any;
};

const BaseRoute: React.FC<BaseRouteProps> = ({
    component: Component,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Route
            {...props}
            render={(renderProps) => (
                <>
                    <Component {...renderProps} />
                </>
            )}
        />
    );
};

export default BaseRoute;

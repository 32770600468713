import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { RowCellProps } from './types';
import classNames from 'classnames';
import RowCellInternalHeader from './RowCellInternalHeader';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
    },
    containerColumnFlex: {
        flexDirection: 'column'
    },
    internalHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '12px',
        letterSpacing: '0.005em',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 6,
    },
    cellContainerWithInternalHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        width: '100%',
    }
}));

const RowCell: <T extends Object>(
    p: RowCellProps<T>,
) => React.ReactElement<RowCellProps<T>> = ({
    cell,
    onCellHover,
    rowIndex,
    colIndex,
    internalHeader = false
}) => {
    const classes = useStyles();

    const cellProps = cell.getCellProps();

    return (
        <TableCell
            className={classNames(classes.container, internalHeader && classes.containerColumnFlex)}
            {...cellProps}
            style={{
                ...cellProps.style,
                maxWidth: cell.column.maxWidth,
                minWidth: cell.column.minWidth,
            }}
            onMouseOver={() => onCellHover?.(cell)}
            data-rowindex={rowIndex}
            aria-colindex={colIndex}
        >
            {internalHeader ? (
                <RowCellInternalHeader cell={cell} />
            ) : (
                cell.render('Cell')
            )}
        </TableCell>
    );
};

export default RowCell;

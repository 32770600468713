import { put, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import { api } from '../../services/axios';
import JSONFormData from '../../../utils/JSONFormData';
import { ReduxState } from '../../types';
import { AxiosResponse } from 'axios';
import { CallQueue } from '../../types/CallQueue';
import { Customer } from '../../../services/endpoints';

export function* getCallQueues() {
    try {
        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        const response: AxiosResponse<{
            callqueue_list: CallQueue[];
        }> = yield api.post(Customer.GetCallQueueList, body);

        yield put(actions.getCallQueues.success(response.data.callqueue_list));
    } catch (err) {
        yield put(actions.getCallQueues.failure());
    }
}

export const callQueuesSaga = [
    takeLatest(actions.getCallQueues.request, getCallQueues),
];

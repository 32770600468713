import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {Handle, Position} from 'react-flow-renderer';
import {useTranslation} from 'react-i18next';
import {UserInput} from '../../../../../store/types/AutoAttendant';
import {Colors} from '../../../../../styles/Colors';
import {getRingScheduleSummary} from '../../../../../utils/extensions/RingScheduleSummary';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';
import {FlowNode} from '../utils/types';
import TransitionSubNode from './TransitionSubNode';
import {IntervalStatus} from "../../../../IntervalSelect/IntervalSelect.utils";

//@ts-ignore
const useStyles = makeStyles(() => ({
    outsideContainer: {
        height: 52,
    },
    mainContainer: {
        height: 40,
        width: 40,
    },
    container: {
        marginTop: 0,
        pointerEvents: 'auto',
    },
    handler: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        right: 0,
        top: 25,
        pointerEvents: 'none!important',
    },
    topHandler: {
        top: 26,
    },
    leftHandlerWithoutTransition: {
        left: 22,
        top: 26,
    },
    leftHandler: {
        left: 22,
        top: 26,
    },
    bottomHandler: {
        top: 19,
    },
    separator: {
        height: 6,
    },
    offHours: {
        position: 'absolute',
        fontSize: 12,
        fontStyle: 'italic',
        width: 53,
        height: 63,
        backgroundColor: Colors.SmokeBackground,
        top: -10,
        left: 60,
        padding: 5,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: "center"
    },
    onHours: {
        position: 'absolute',
        fontSize: 12,
        fontStyle: 'italic',
        width: "max-content",
        color: Colors.Support,
        backgroundColor: Colors.SmokeBackground,
        fontWeight: 700,
        top: 65,
        left: -12,
        padding: 5,
    },
    menuName: {
        color: Colors.Gray3,
        marginBottom: 5,
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const HoursNode = (node: FlowNode) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {data} = node;

    if (!data?.transition) {
        return null;
    }

    const isNotActiveTransition =
        (data?.transition.userInput === UserInput.NotActive && data?.menu?.intervals.activity === IntervalStatus.OnlyFollowingTimeInterval) ||
        (!!data?.transition?.menu?.menuTransitions?.find(item => item.userInput === UserInput.NotActive) && data?.menu?.intervals.activity === IntervalStatus.OnlyFollowingTimeInterval) ||
        (!!data.menu?.actions.find(item => item.userInput === UserInput.NotActive && data.menu?.intervals.activity == IntervalStatus.OnlyFollowingTimeInterval));

    let transitionToTransfer = undefined;


    if (isNotActiveTransition) {
        transitionToTransfer = data?.transition;
    }

    const scheduleSummary = getRingScheduleSummary(
        {
            activity: IntervalStatus.OnlyFollowingTimeInterval,
            intervals: data.menu?.intervals.intervals || [],
        },
        false
    );

    // @ts-ignore
    return (
        <div className={classes.outsideContainer}>
            <Handle
                type="target"
                position={Position.Left}
                id="a"
                className={classNames(
                    classes.handler,
                    isNotActiveTransition
                        ? classes.leftHandler
                        : classes.leftHandlerWithoutTransition,
                )}
            />

            <div className={classes.separator}/>

            <div className={classes.mainContainer}>
                {(isNotActiveTransition) && (
                    <CustomizedTooltip
                        copy={false}
                        title={
                            <div className={classes.menuContainer}>
                                <span className={classes.menuName}>
                                    {data?.menu?.name}
                                </span>

                                <span>{scheduleSummary}</span>
                            </div>
                        }
                    >
                        <div>
                            <TransitionSubNode
                                transition={ {...transitionToTransfer, userInput:UserInput.NotActive}}
                                className={classes.container}
                            />
                        </div>
                    </CustomizedTooltip>
                )}
            </div>

            <div className={classes.separator}/>

            <Handle
                type="source"
                position={Position.Bottom}
                id="b"
                className={classNames(classes.handler, classes.bottomHandler)}
            />

            <Handle
                type="source"
                position={Position.Top}
                id="c"
                className={classNames(classes.handler, classes.topHandler)}
            />

            {(isNotActiveTransition) && (
                <div className={classes.offHours}>
                    {t('screens:autoAttendants:offHours')}
                </div>
            )}

            {(isNotActiveTransition) && (
                <div className={classes.onHours}>
                    {t('screens:autoAttendants:onHours')}
                </div>
            )}
        </div>
    );
};

export default HoursNode;

import { makeStyles } from "@material-ui/core";
import { GetCallProcessingPolicyListEntity } from "../../../../store/actions/extensions/payloads";
import { Colors } from "../../../../styles/Colors";

export const useStyles = makeStyles(() => ({
    addPolicyContainer: {
        paddingLeft: 8,
        paddingTop: 8,
        paddingBottom: 16,
        '& label': {
            color: Colors.Gray7,
            fontWeight: 700,
            paddingBottom: 10,
            '& svg': {
                width: 20
            }
        },
        '& > *': {
            width: 'fit-content'
        }
    },
    addPolicyButton: {
        fontWeight: 500,
        width: "auto",
        paddingLeft: 10,
        paddingRight:16,
    },
    addIcon: {
        marginLeft: 0,
        marginRight: 6,
    },

    policiesListContainer: {
        width: '100%',
        height: 'fit-content'
    },
    deleteDialog: {
        '& .MuiPaper-root': {
            maxWidth: '280px !important',
            width: '280px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: '232px !important',
        },
    },
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-all'
    },
}));

export type CallScreeningFormType = {
    policies: GetCallProcessingPolicyListEntity[];
}

export const callScreeningFormDefaultValues: CallScreeningFormType = {
    policies: []
};
import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Box, Grid} from '@material-ui/core';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import classNames from 'classnames';
import {DynamicListEntity, SipContactFormType, SipContactFormTypeProps, useStyles} from "./SipContactForm.utils";
import CustomizedRadio from "../../../Radio/Radio";
import CustomizedTextField from "../../../TextField/TextField";
import SelectField from "../../../SelectField/SelectField";
import {TransportProtocol} from "../../../../store/types/AccountFollowMeSettings";
import IconWithTooltip from "../../../Tooltip/IconWithTooltip";

const SipContactForm: React.FC<SipContactFormTypeProps> = (
    {
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        customClass,
        dynamicList
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        dirty,
        errors,
        setFieldError
    } = useFormikContext<SipContactFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    useEffect(() => {
        handleDirtyChange?.('callBarring', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callBarring', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callBarring', () => isValid);
    }, [isValid]);

    useEffect(()=>{
        if(values.sipContactStatus && !values.staticAddress  && !values.dynamicAddress)
        {
            setFieldValue('staticAddress', true);
            setFieldValue('transportProtocol', TransportProtocol.UDP);
        }

        if(!values.sipContactStatus)
        {
            setFieldValue('staticAddress', false);
            setFieldValue('dynamicAddress', false);
            setFieldValue('transportProtocol', '');
        }

    },[values.sipContactStatus])

    const renderOption = (item:DynamicListEntity) => {
        return (<div className={classes.optionContainer}>
            <span className={classes.optionText}>{item.name}</span>
            {item.status && <span className={classes.optionStatus}>{item.status}</span>}
        </div>)
    }

    return (
        <div className={classNames(classes.inputs, customClass?.container)}>
            <Grid item>
                <Box className={classes.rowBox}>
                    <SwitchWithLabel
                        className={classes.switchContainer}
                        label={t('screens:sipTrunks.sipContact')}
                        setValue={setValue}
                        value={values.sipContactStatus}
                        field={'sipContactStatus'}
                        id={'sip-contact-status-switch'}
                        disabled={values.sipContactLocked}
                    />
                    <IconWithTooltip
                        dataQa="sip-contact-tooltip"
                        tooltipText={t(
                            'tooltips:sipTrunks.sipContact',
                        )}
                        className={classes.switchContainer}
                    />
                </Box>
            </Grid>
            {values.sipContactStatus && (
                <Grid item className={classes.itemsContainer}>
                    <Box className={classes.rowBox}>
                        <div className={classes.row}>
                            <CustomizedRadio
                                value={values.staticAddress}
                                className={classes.radioContainer}
                                checked={values.staticAddress == true}
                                label={t('screens:sipTrunks.staticAddress')}
                                dataQa={`sip-contact-static-address`}
                                onChange={(val) => {
                                    setFieldValue('staticAddress', true);
                                    setFieldValue('dynamicAddress', false);
                                }}
                            />
                        </div>
                        <div className={classes.row}>
                            <CustomizedRadio
                                value={values.dynamicAddress}
                                className={classes.radioContainer}
                                checked={values.dynamicAddress == true}
                                label={t('screens:sipTrunks.dynamicAddress')}
                                dataQa={`sip-contact-dynamic-address`}
                                onChange={() => {
                                    setFieldValue('dynamicAddress', true);
                                    setFieldValue('staticAddress', false);
                                }}
                            />
                        </div>
                    </Box>
                    {values.staticAddress && (
                        <div className={classes.row}>
                            <CustomizedTextField
                                className={classNames(
                                    classes.phoneNumberInput,
                                    classes.controlMargin,
                                )}
                                id="sipCldNumber"
                                label={t('screens:sipTrunks.cldNumberLabel')}
                                onChange={(e) => setFieldValue('sipCldNumber', e.target.value)}
                                value={values?.sipCldNumber}
                                dataQa="cld-number-input"
                                helperText={errors?.sipCldNumber}
                                setFieldError={setFieldError}
                                maxLength={64}
                                fixedLengthCounter
                            />
                            <CustomizedTextField
                                className={classNames(
                                    classes.controlMargin,
                                    classes.hostInput
                                )}
                                id="host"
                                label={t('screens:sipTrunks.host')}
                                onChange={(e) => setFieldValue('host', e.target.value)}
                                value={values?.host}
                                dataQa="host-input"
                                helperText={errors?.host}
                                setFieldError={setFieldError}
                                maxLength={255}
                                required
                                fixedLengthCounter
                            />
                            <CustomizedTextField
                                className={classNames(
                                    classes.controlMargin,
                                    classes.portInput,
                                )}
                                id="port"
                                label={t('screens:sipTrunks.port')}
                                onChange={(e) => setFieldValue('port', e.target.value.replace(/\D/g, ''))}
                                value={values?.port}
                                dataQa="port-input"
                                helperText={errors?.port}
                                setFieldError={setFieldError}
                                maxLength={15}
                                fixedLengthCounter
                            />
                            <SelectField
                                dataQa="transport-protocol-select"
                                label={t(
                                    'screens:advancedSettings.transportProtocol',
                                )}
                                items={Object.values(TransportProtocol)}
                                value={values.transportProtocol}
                                onChange={(_, value) =>
                                    setFieldValue('transportProtocol', value)
                                }
                                className={classes.transportProtocolSelect}
                                classes={{
                                    container: classNames(
                                        classes.transportProtocolSelect,
                                        classes.controlMargin,
                                    ),
                                }}
                                disableClearable
                            />
                        </div>

                    )}
                    {values.dynamicAddress && (
                        <div className={classes.row}>
                            <SelectField
                                label={t('screens:sipTrunks.dynamicListLabel')}
                                items={dynamicList ?? []}
                                value={values.dynamicAddressEntity}
                                dataQa="sip-forwarding-proxy-select"
                                onChange={(_, value) => {
                                    setFieldValue('dynamicAddressEntity', value);
                                }}
                                disableAutoSettingValue
                                disableClearable
                                getOptionSelected={(item: DynamicListEntity) => item.iAccount == values.dynamicAddressEntity?.iAccount}
                                getOptionLabel={(v: { name: string; value: string }) => v.name}
                                className={classes.selectFiled}
                                required
                                renderOption={renderOption}
                                helperText={errors.dynamicAddressEntity}
                            />
                        </div>
                    )}
                </Grid>
            )}
        </div>
    );
};

export default SipContactForm;

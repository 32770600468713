import React from 'react';
import history from '../../history';
import {makeStyles} from '@material-ui/core/styles';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import TextButton from '../../components/TextButton/TextButton';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";

type DeviceModelProps = {
    name: string;
    type: string;
    moveTo?: string;
};

export const dataTestId = 'device-mod-cell';

const useStyle = makeStyles(() => ({
    root: {
        fontWeight: 400,
        lineHeight: '24px',

        '& span:first-child': {
            fontSize: 16,
        },
        '& span:last-child': {
            fontSize: 14,
        },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    disabled: {
        fontSize:'16px !important'
    }
}));

const DeviceModeCell: React.FC<DeviceModelProps> = ({name, type, moveTo}) => {
    const classes = useStyle();
    const permission = usePermissionContext();
    const handleOnClick = () => {
        moveTo && history.push(moveTo);
    };

    const content = (
        <div>
            {permission == PermissionType.Hidden ?
                (
                    <PermissionLinkCell text={name} onClick={handleOnClick} className={classes.disabled}/>
                ) :
                (
                    <>
                        <TextButton onClick={handleOnClick} role={'application'}>
                            {name}
                        </TextButton>
                        <br/>
                    </>
                )
            }

            <span>{type}</span>
        </div>
    );

    const tooltipContent = (
        <>
            <span>{name}</span>
            <span>{type}</span>
        </>
    );
    return (
        <div className={classes.root} data-testid={dataTestId}>
            <OverflowTooltip
                tooltip={tooltipContent}
                text={content}
                copy={false}
            />
        </div>
    );
};

export default DeviceModeCell;

import React from 'react';
import CustomizedTooltip from '../Tooltip/Tooltip';
import StatusIcon from '../Extensions/StatusIcon';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

interface DeviceStatusCellInterface {
    status: number;
}

const useStyle = makeStyles(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const DeviceStatusTableCell: React.FC<DeviceStatusCellInterface> = ({
    status,
}) => {
    const { t } = useTranslation();
    const classes = useStyle();

    return (
        <CustomizedTooltip
            title={t(
                `enums:deviceStatus.${status}`,
                t(`enums:deviceStatus.unknown`),
            )}
            copy={false}
        >
            <div className={classes.root}>
                <StatusIcon state={status} />
            </div>
        </CustomizedTooltip>
    );
};

export default DeviceStatusTableCell;

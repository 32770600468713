import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { ExtensionsListItem, SelectExtensionListItem } from '../../store/reducers/extensions/extensions/reducer';
import { ExtensionType } from '../../store/types/Extension';
import { ServiceFeatureName } from '../../store/types/ServiceFeature';
import { AxiosResponse } from 'axios';
import { Account, Customer } from '../../services/endpoints';
import { api } from '../../store/services/axios';
import { AccountListResponse } from '../../store/types/Account';
import { YesNo } from '../../store/types/CallScreening';
import { GetAssignedCpePortsListResponse } from '../../store/types/Devices';
import JSONFormData from '../../utils/JSONFormData';

export type EditDialogProps = {
    cancelClick?: () => void;
    i_ua?: number;
    extension_id?: string;
    account_id?: string;
    isOpen: boolean;
    dataQa?: string;
    header?: string;
    className?: string;
    dataTestId?: string;
    line?: number;
    extension_name?: string;
    toggleModal?: () => void;
};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: 18,
        '& .MuiInputBase-root': {
            height: 59,
            marginTop: 0,
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 10px) scale(0.75)',
        },
        '& .MuiInputLabel-root': {
            marginLeft: 10,
        },
    },

    select: {
        width: '100%',
    },

    boxRow: {
        display: 'flex',
    },

    disabledButton: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
}));

export const mapToLabel = (item: ExtensionType): string => {
    const label = item.extension_id;
    return item.extension_name ? label + ' - ' + item.extension_name : label;
};

export const mapToLabelId = (item: ExtensionsListItem): string => {
    const label = item.id;
    return item.name ? label + ' - ' + item.name : label;
};

export const mapToSelectExtensionListItem = (item: ExtensionType, i_ua?: number): SelectExtensionListItem => {
    return {
        label: mapToLabel(item),
        value: item.id, 
        ip_phone_name: mapToLabel(item),
        i_ua: i_ua,
        id: item.extension_id,
        account_id: item.i_account
    };
};

export const mapListItemToSelectExtensionListItem = (item: ExtensionsListItem): SelectExtensionListItem => {
    return {
        label: mapToLabelId(item),
        value: item.id, 
        ip_phone_name: mapToLabelId(item),
        i_c_ext: item.id,
        id: item.id,
        account_id: item.i_account
    };
};

export const loadOptionsFromBackend = async (search: string, prevOptions: unknown[], totalOffset: number,
    session_id: string, csrf_token: string, i_customer: number, i_ua: number) => {
    const limit = 20;

    const params = {
        extension_id: search ? `%${search}%` : undefined,
        has_extension: 1,
        get_only_real_accounts: 1,
        get_not_closed_accounts: 1,
        get_status: 1,
        get_total: 1,
        limit: limit,
        offset: totalOffset,
        get_service_features: [
            ServiceFeatureName.LineSharing
        ]
    };

    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(params);
    
    const res: AxiosResponse<AccountListResponse> = await api.post(Account.GetAccountList, body);

    const body2 = new JSONFormData(session_id, csrf_token);
    body2.setParams({
        i_customer: i_customer
    });
    
    const resAvailable: AxiosResponse<GetAssignedCpePortsListResponse> = await api.post(
        Customer.GetAssignedCpePortsList,
        body2
    );

    const items: SelectExtensionListItem[] = [];

    for(const ac of res.data.account_list) {
        const lineSharingEnabled = ac?.service_features
            ?.find((e) => e.name === ServiceFeatureName.LineSharing)
            ?.effective_flag_value === YesNo.Yes;

        if(lineSharingEnabled) {
            items.push(mapToSelectExtensionListItem(ac, i_ua));
        } else {
            const foundAsAssigned = resAvailable.data.assigned_cpe_ports_list.find(e => e.i_account === ac.i_account);
            if(!foundAsAssigned) {
                items.push(mapToSelectExtensionListItem(ac, i_ua));
            }
        }
    }

    return {
        options: items,
        hasMore: res.data.total > prevOptions.length + limit,
        additional: {
            totalOffset: totalOffset + limit
        }
    };
};

export const getAssignedDevice = async(session_id: string, csrf_token: string, 
        i_customer: number, i_ua: number, account_id: string, port: number) => {

    const body2 = new JSONFormData(session_id, csrf_token);
    body2.setParams({
        i_customer: i_customer
    });
    
    const resAvailable: AxiosResponse<GetAssignedCpePortsListResponse> = await api.post(
        Customer.GetAssignedCpePortsList,
        body2
    );

    const itm = resAvailable.data.assigned_cpe_ports_list.find(e => e.id === account_id && e.i_ua === i_ua && e.port === port);

    return itm?.i_ua_link ?? 0;
}
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/ListViewHeader/Header';
import DevicesFilters from './DevicesFilters';
import { ReduxState } from '../../store/types';
import * as actions from '../../store/actions';
import { DeviceFilters, UAInfo, UAListFileFormat, UAUsage } from '../../store/types/Devices';
import { PaginationMode } from '../../components/DataGrid/types';
import DataGrid from '../../components/DataGrid/DataGrid';
import { addId, generateColumns, useStyles } from './DevicesList.utils';
import { macPayloadFormatter } from '../../utils/macAdressFormater';
import usePageTitle from '../../hooks/usePageTitle';
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import {Permission, PermissionType} from "../../store/types/Permission";
import NewDeviceDialog from './NewDeviceDialog';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import toast from 'react-hot-toast';
import dayjs from '../../services/customDayJs';
import UploadDeviceFileDialog from './UploadDeviceFileDialog';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { DialogButton } from '../../components/AlertDialog/DialogContainer';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import { usePermissions } from '../../hooks/usePermissions';
import classNames from 'classnames';

const DevicesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState<DeviceFilters>({});
    usePageTitle();

    const [showAddNewDeviceDialog, setShowAddNewDeviceDialog] = useState(false);
    const handleAddNewDeviceClick = () => {
        setShowAddNewDeviceDialog((prev) => !prev);
    };

    const [menuItemRef, setMenuItemRef] = React.useState<null | HTMLElement>(null);
    const open = Boolean(menuItemRef);

    const handleMenuOpenClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuItemRef(event.currentTarget);
    };

    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        i_ua?: number;
        name?: string;
    }>({
        isOpen: false
    });

    const handleDeleteAction = () => {
        dispatch(actions.deleteUA.request({
            i_ua: deleteDialog.i_ua || 0,
            callback: () => {
                closeDeleteDialog();
                fetchData({pageSize:pageSize,page:page});
                toast(t<string>('screens:devices.deviceDeleted'));
            }
        }));
    };

    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            i_ua: undefined,
            name: undefined
        });
    };

    const handleImportFromFile = () => {
        setIsUploadingModal(true);
        handleMenuClose();
    };

    const handleDownloadCsv = () => {
        setIsDeviceListDownloading(true);
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setMenuItemRef(null);
    };

    const [isDeviceListDownloading, setIsDeviceListDownloading] = useState<boolean>(false);

    useEffect(() => {
        if (isDeviceListDownloading) {
            toast(t<string>('screens:devices.downloadWillStartShortly'));
            dispatch(
                actions.getDeviceListCsvFile.request({
                    ...filters,
                    file_format: UAListFileFormat.CSV,
                    with_available_ports: 1,
                    with_busy_ports: 1,
                    get_total: 1,
                    offset: 0,
                    fileName: 'devices-' + dayjs().unix(),
                    callback: () => setIsDeviceListDownloading(false)
                }),
            );
        }
    }, [isDeviceListDownloading]);

    const [isUploadingModal, setIsUploadingModal] = useState<boolean>(false);

    const handleFiltersChange = (
        extension_id?: string,
        name?: string,
        mac?: string,
        inventory_id?: string,
        usage?: UAUsage | string,
    ) => {
        setFilters({
            extension_id: extension_id?.length
                ? `%${extension_id}%`
                : undefined,
            name: name?.length ? `%${name}%` : undefined,
            mac: mac?.length ? macPayloadFormatter(mac) : undefined,
            inventory_id: inventory_id?.length
                ? `%${inventory_id}%`
                : undefined,
            usage: usage ? usage : undefined,
        });
    };

    const handleSubmit = () => {
        fetchData({ pageSize: pageSize, page: 0 });
    };

    const {
        items = [],
        isLoading = false,
        total = 0,
        active = 0,
        allTotal = 0,
        createdUAId,
    } = useSelector((state: ReduxState) => state.devices);

    const fetchData = (params?: { pageSize: number; page: number }) => {
        dispatch(
            actions.devicesList.request({
                ...filters,
                limit: params?.pageSize || pageSize,
                offset: params ? params.page * params.pageSize : 0,
                with_available_ports: 1,
                with_busy_ports: 1,
                get_total: 1,
            }),
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(!showAddNewDeviceDialog && createdUAId) {
            fetchData();
        }
    }, [showAddNewDeviceDialog, createdUAId]);

    const handleDelete = (obj: UAInfo) => {
        setDeleteDialog({isOpen: true, i_ua: obj.i_ua, name: obj.name});
    };

    const columns = useMemo(() => generateColumns(t, handleDelete), []);

    const addPermission = usePermissions(...Permission.Inventory.Devices.AddNewDevice.value);
    const downloadPermission = usePermissions(...Permission.Inventory.Devices.DownloadDevices.value);

    const hideMoreOptionsButton = addPermission === PermissionType.Hidden
        && downloadPermission === PermissionType.Hidden;

    return (
        <PermissionPlaceholder permission={Permission.Inventory.Devices.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.content}>
                        <PermissionProvider
                            permission={Permission.Inventory.Devices.AddNewDevice.value}
                        >
                        <Header
                            title={t('screens:devices.listTitle')}
                            totalNumber={allTotal || 0}
                            ofNumber={active}
                            dataQa={'device-list-view-header'}
                            customClass={classes.header}
                            plusIcon={true}
                            buttonText={t('common:addNewButton')}
                            buttonOnClick={handleAddNewDeviceClick}
                        >
                            {!hideMoreOptionsButton && (
                            <div>
                                <CustomizedTooltip
                                    title={t('tooltips:devices.moreActions')}
                                    copy={false}
                                >
                                    <IconButton
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup
                                        data-testid="mevices-header-dropdownmenu"
                                        data-qa="mevices-header-dropdownmenu"
                                        className={classes.moreOptionsButton}
                                        onClick={handleMenuOpenClick}
                                    >
                                        <MoreVert htmlColor={Colors.Gray5} />
                                    </IconButton>
                                </CustomizedTooltip>
                                <Menu
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button'
                                    }}
                                    anchorEl={menuItemRef}
                                    getContentAnchorEl={null}
                                    open={open}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 96,
                                            width: 224,
                                            marginTop: 4
                                        },
                                    }}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                >
                                    <MenuItem key="import-from-file" onClick={handleImportFromFile}
                                        className={classNames(classes.dropdownMenuItem,
                                            addPermission === PermissionType.Hidden && classes.hidden
                                        )}
                                        disabled={addPermission === PermissionType.ReadOnly}
                                    >
                                        {t('screens:devices.importDevicesFromFile')}
                                    </MenuItem>
                                    <MenuItem key="upload-csv" onClick={handleDownloadCsv}
                                        className={classNames(classes.dropdownMenuItem,
                                            downloadPermission === PermissionType.Hidden && classes.hidden
                                        )}
                                        disabled={downloadPermission === PermissionType.ReadOnly}
                                    >
                                        {t('screens:devices.downloadDeviceListCsv')}
                                    </MenuItem>
                                </Menu>
                            </div>)}
                        </Header>
                        </PermissionProvider>

                        <NewDeviceDialog
                            isOpen={showAddNewDeviceDialog}
                            clickOnClose={handleAddNewDeviceClick}
                        />

                        <UploadDeviceFileDialog
                            isModalOpen={isUploadingModal}
                            setIsModalOpen={(v) => {
                                setIsUploadingModal(v);
                            }}
                            afterUpload={fetchData}
                        />

                        <DevicesFilters
                            handleChange={handleFiltersChange}
                            onSubmit={handleSubmit}
                        />

                        <DataGrid<UAInfo>
                            columns={columns}
                            data={items.map(addId)}
                            className={classes.table}
                            rowCount={total}
                            loading={isLoading}
                            onPageChange={(params) => {
                                fetchData({
                                    pageSize: params.pageSize,
                                    page: params.page,
                                });
                                setPage(params.page);
                            }}
                            onPageSizeChange={(params) => {
                                fetchData({ pageSize: params.pageSize, page: 0 });
                                setPageSize(params.pageSize);
                            }}
                            paginationMode={PaginationMode.Server}
                            centeredRows
                            narrowRows
                            initialPageSize={10}
                            forceFirstPage={false}
                        />
                    </div>
                </div>

                <AlertDialog
                    className={classes.deleteDialog}
                    contentClass={classes.contentDialog}
                    isOpen={deleteDialog.isOpen}
                    dataQa={'delete-rule-dialog'}
                    hideHeader
                    description={t('screens:devices.deleteRule', {
                        name: deleteDialog.name || '',
                    })}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeDeleteDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={handleDeleteAction}
                        />,
                    ]}
                />
            </div>
        </PermissionPlaceholder>
    );
};

export default DevicesList;

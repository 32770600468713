enum MacAdressSepecialChars {
    EmptyPlace = '%%',
    Seperator = ':',
}

export const macPayloadFormatter = (input: string) => {
    const characterSet = input.toLowerCase().replace(/x/g, '%').split(':');
    let output = '';
    let rangeExist = false;

    for (let i = 0; i < characterSet.length; i++) {
        if (characterSet[i] != MacAdressSepecialChars.EmptyPlace) {
            output += characterSet[i] + MacAdressSepecialChars.Seperator;
            rangeExist = false;
        } else if (
            characterSet[i] == MacAdressSepecialChars.EmptyPlace &&
            !rangeExist
        ) {
            output += '%:';
            rangeExist = true;
        } else {
            continue;
        }
    }

    return output.substring(0, output.length - 1);
};

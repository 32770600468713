import React from 'react';

import classNames from 'classnames';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import { Grid } from '@material-ui/core';
import { DisaFormType, useStyles } from './DisaForm.utils';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import NumericTextField from '../../NumericTextField/NumericTextField';

const DisaForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        values,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
    } = useFormikContext<DisaFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    return (
        <Grid
            item
            className={classNames(
                classes.contentContainer,
                !values.DISASwitcher && classes.collapsedContainer,
            )}
        >
            <SwitchWithLabel
                setValue={setValue}
                value={values.DISASwitcher}
                field={'DISASwitcher'}
                label={t('screens:incoming.DISA')}
                id={'DISA'}
            />

            {values.DISASwitcher && (
                <div className={classes.row}>
                    <NumericTextField
                        label={t('screens:incoming.DISAPassword')}
                        onChange={handleChange}
                        value={values.DISAPassword}
                        dataQa="incoming-DISAPassword"
                        className={classes.DISAPassword}
                        id={'DISAPassword'}
                        required
                        icon={
                            <IconWithTooltip
                                dataQa="incoming-DISAPassword-name-tooltip"
                                tooltipText={t('tooltips:incomings.DISA')}
                                copy={false}
                            />
                        }
                        iconPosition={'end'}
                        helperText={errors.DISAPassword}
                        setFieldError={setFieldError}
                        maxLength={32}
                    />
                </div>
            )}
        </Grid>
    );
};

export default DisaForm;

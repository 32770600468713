import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    amountContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    mainContainer: {
        marginBottom: 24,
    },
    taxContainer: {
        marginTop: 27,
    },
    taxValuesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 337,
    },
    headerText: {
        color: Colors.Gray5,
    },
    valueText: {
        color: Colors.Text,
        fontWeight: 700,
    },
    balanceValueText: {
        marginLeft: 16,
    },
    amountInput: {
        '& .MuiFormControl-root': {
            width: 337,
        },
        '& .MuiInputBase-input': {
            fontWeight: 700,
        },
        '& input[type="number"]::-webkit-inner-spin-button': {
            opacity: 1,
        },
        '& input[type="number"]::-webkit-outside-spin-button': {
            opacity: 1,
        },
        marginRight: 27,
    },
}));

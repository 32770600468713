import React from 'react';
import i18n from 'i18next';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import { makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import dayjs from '../../services/customDayJs';
import CallerInfo from '../../components/Calls/CallerInfo';
import DateTimeCell from '../../components/Calls/DateTimeCell';
import CustomizedIconButton from '../../components/IconButton/IconButton';
import { prepareCallRecordName } from '../../utils/calls/prepareCallRecordName';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px'
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
    },
    filtersHidden: {
        width: 50,
        overflow: 'hidden',
        marginBottom: -92,
    },
    pressableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },
    filtersContainer: {
        marginBottom: 16,
        backgroundColor: Colors.LightGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
        backgroundColor: Colors.LightGray,
        height: '60px',
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        flex: 1,
        width: '214px',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        width: 1002,
        marginTop: 9,

        '& tr th.MuiTableCell-root.MuiTableCell-head:nth-child(3)': {
            paddingLeft: 35,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-start',
        height: 56,
        marginTop: 24,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            padding: '36px 32px 30px 32px',
        },
    },
    recordingColumn: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: 70,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    chargeUSD: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: 120,
    },
    container: {
        background: Colors.Text,
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    button: {
        marginTop: 10,
    },
}));

export const generateColumns = (
    t: TFunction<string>,
    timezoneOffset: number,
    classes: ReturnType<typeof useStyles>,
    downloadFile?: (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => void,
    globalCurrencyName?: string,
    isDownloadingGoingOn?: boolean[],
    userDateTimeFormat?: string,
    downloadPermission?: string[],
): Column<CallHistoryType>[] => {
    return [
        {
            Header: t<string>('screens:calls.connectTime'),
            accessor: 'connect_time',
            Cell: function Cell(params) {
                return (
                    <DateTimeCell
                        timezoneOffset={timezoneOffset}
                        time={params.row.original.connect_time as string}
                        customDateFormat={userDateTimeFormat}
                    />
                );
            },
            width: 1.25,
        },
        {
            Header: t<string>('screens:calls.caller'),
            accessor: 'CLI',
            Cell: function Cell(params) {
                return <CallerInfo caller={params.row.original.CLI!} />;
            },
            width: 1.75,
        },
        {
            Header: t<string>('screens:calls.duration'),
            accessor: 'charged_quantity',
            Cell: function Cell(params) {
                return (
                    <span>
                        {new Date(params.row.original.charged_quantity! * 1000)
                            .toISOString()
                            .substr(11, 8)}
                    </span>
                );
            },
            width: 1,
        },
        {
            Header: `${t<string>('screens:calls.charge')}, ${
                globalCurrencyName || ''
            }`,
            accessor: 'charged_amount',
            Cell: function Cell(params) {
                return (
                    <span className={classes.chargeUSD}>
                        {' '}
                        {params.row.original.charged_amount?.toFixed(2)}
                    </span>
                );
            },
            width: 1,
        },
        {
            Header: t<string>('screens:calls.recording'),
            Cell: function Cell(params: any) {
                const number = params.row.original.i_xdr as number;
                const downloadId = params.row.original?.cr_download_ids?.[0];
                const index = params.row.index;

                return (
                    <div className={classes.recordingColumn}>
                        {downloadId && (
                            <PermissionProvider
                                permission={downloadPermission || []} skipReadOnly
                            >
                                <CustomizedIconButton
                                    onClick={() =>
                                        downloadFile &&
                                        downloadFile(
                                            number,
                                            downloadId,
                                            prepareCallRecordName(
                                                params.row.original,
                                                timezoneOffset,
                                            ),
                                            index,
                                        )
                                    }
                                    dataTestId="download-icon"
                                    disabled={isDownloadingGoingOn?.[index]}
                                    tooltipText={
                                        isDownloadingGoingOn?.[index]
                                            ? ''
                                            : t(
                                                    'tooltips:ringGroups.downloadRecording',
                                                )
                                    }
                                >
                                    <CloudDownload />
                                </CustomizedIconButton>
                            </PermissionProvider>
                        )}
                    </div>
                );
            },
            width: 0.75,
        },
    ];
};

export type CallHistoryType = {
    connectTime: Date | string;
    caller: string;
    duration: string;
    charge: string;
    connect_time?: string;
    CLI?: string;
    charged_amount?: number;
    charged_quantity?: number;
};

export type CallHistoryFormProps = {
    from: string;
    till: string;
    caller: string;
};
export const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const requiredFieldError = i18n.t('errors:ringGroups.emptyInput');
const wrongFormatError = i18n.t('errors:ringGroups.wrongFormat');
const dateTest = Yup.string()
    .required(requiredFieldError)
    .test('is-valid-date', wrongFormatError, (v) =>
        dayjs(new Date(v?.replace(/-/g, '/') || '')).isValid(),
    );

export const callHistoryFiltersValidationSchema = Yup.object().shape({
    from: dateTest.nullable(),
    till: dateTest.nullable(),
});

import React from 'react';
import {MenuItem, MenuItemName} from '../../store/types/AutoAttendant';
import {makeStyles} from '@material-ui/core/styles';
import {Colors, greyColor4, greyColor5} from '../../styles/Colors';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import {Badge} from '@material-ui/core';
import TimeFilterCell from '../../components/Calls/TimeFilterCell';
import i18next from "i18next";

const useStyle = makeStyles(() => ({
    root: {
        fontSize: 12,
        color: Colors.Gray9,
        display: 'flex',
        alignItems: 'center',
    },
    badge: {
        paddingLeft: 4,
        '& > span.MuiBadge-badge': {
            backgroundColor: greyColor4,
            color: greyColor5,
        },
    },

    tRoot: {
        fontSize: 12,
        color: Colors.Gray3,
    },
    tContent: {
        fontSize: 16,
    },

    tooltip: {
        '& .MuiBadge-badge': {
            display: 'flex',
        },
    },

    tooltipText: {
        overflowWrap: 'anywhere',
    },
    container: {
        maxWidth: 370,
        width: 370,
    },

    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
        marginBottom: 5,
    },
    description: {
        marginRight: 10,
        color: Colors.Gray5,
    },
}));

type TooltipContentProps = {
    element: MenuItem;
};

export const TRANSFORM_DATA_TEST_KEY = 'span-desc-';
export const transformDescription = (desc?: string) => {
    if (desc == undefined) {
        return desc;
    }

    let newDesc: string | JSX.Element[] | undefined = desc;

    if (desc.includes(' or')) {
        const descArr = desc
            .split(' or')
            .filter((item) => item.length)
            .map((item) => item.trim());

        newDesc = descArr.map((item, index) => (
            <span
                key={index}
                style={{display: 'inline-block'}}
                data-testid={TRANSFORM_DATA_TEST_KEY + index}
            >
                {item}
            </span>
        ));
    }

    return newDesc;
};

export const TooltipContent: React.VFC<TooltipContentProps> = ({element}) => {
    const classes = useStyle();
    const period = translateAlways(element.period);
    const periodString = typeof period === 'string' ? period: '';

    return (
        <div className={classes.menuItem}>
            <span className={classes.tRoot}>{element.name}</span>
            <TimeFilterCell
                period={periodString}
                allowShowAny={false}
            />
        </div>
    );
};

type MenuCellProps = {
    menuItems: MenuItem[];
    above?: boolean;
};

export const translateAlways = (value: string | JSX.Element[] | undefined ):string | JSX.Element[] | undefined => {
    return  value && typeof value === 'string' && value.indexOf('Always') > -1 ?
        value.replace('Always', i18next.t<string>('common:always')) :value
}

const MenuCell: React.VFC<MenuCellProps> = ({menuItems, above}) => {
    const classes = useStyle();
    const menuLength = menuItems.length;
    const root = menuItems.find((o) => {
        return o.name == MenuItemName.Root;
    });

    const rootDesc: string | JSX.Element[] | undefined = transformDescription(
        root?.period_desc,
    );

    const desc = translateAlways(rootDesc);

    const elements = menuItems
        .sort((a) => {
            if (a.name == MenuItemName.Root) {
                return -1;
            }
            return 1;
        })
        .map((o, i) => i < 8 && <TooltipContent key={o.i_menu} element={o}/>);

    return (
        <div className={classes.root}>
            <span className={classes.description}>
                {desc ? desc : ''}
            </span>
            {menuLength > 1 ? (
                <CustomizedTooltip
                    className={classes.tooltip}
                    title={elements}
                    dataQa={'tooltip-menu-list'}
                    interactive={true}
                    classes={{
                        container: classes.container,
                        text: classes.tooltipText,
                    }}
                    copy={false}
                    above={above}
                >
                    <Badge
                        badgeContent={menuLength}
                        className={classes.badge}
                        data-qa={'badge-did-numbers'}
                    />
                </CustomizedTooltip>
            ) : null}
        </div>
    );
};

export default MenuCell;

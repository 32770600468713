import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Colors } from '../../styles/Colors';
import { ButtonGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomButton from '../Button/Button';

type ButtonGroupProps = {
    onChange: (newValue: number) => void;
    items: string[];
    filterValue: number;
    skipPermission?: boolean;
};

const useStyles = makeStyles(() => ({
    buttonGroupBase: {
        background: Colors.White,
        height: '36px',
        border: '4px solid '+Colors.White,
        width: 'fit-content',
        borderRadius: '28px !important',
        whiteSpace: 'nowrap'
    },
    buttonBasic: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        height: '36px',
        border: 'none',
        width: 'fit-content',
        textTransform: 'none',
        borderRadius: '28px !important',
        boxShadow: 'none',
        marginLeft: '0 !important'
    },
    active: {
        background: Colors.Secondary1,
        '& .MuiButton-label': {
            color: `${Colors.Background}!important`,
            fontSize: '15px !important',
            fontWeight: 400
        },
        '&:hover': {
            background: Colors.Secondary1,
            opacity: 0.88,
            boxShadow: 'none'
        },
        '& .MuiTouchRipple-root': {
            color: `${Colors.Gray10}!important`,
        },
    },
    inactive: {
        background: Colors.Background,
        '& .MuiButton-label': {
            color: `${Colors.Secondary1}!important`,
            fontSize: '15px !important',
            fontWeight: 400
        },
        '&:hover': {
            background: Colors.Secondary1,
            boxShadow: 'none',
            '& .MuiButton-label': {
                color: `${Colors.Background}!important`,
            }
        },
        '& .MuiTouchRipple-root': {
            color: `${Colors.Gray10}!important`,
        },
    }
}));

const CustomizedButtonGroup: React.FC<ButtonGroupProps> = ({
    onChange,
    items,
    filterValue,
    skipPermission = false
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleButtonClick = (id: number) => {
        onChange(id);
    };

    items = items || [];

    return (
        <ButtonGroup className={classes.buttonGroupBase}>
            {items.map(function(name, index) {
                return <CustomButton
                key={index}
                dataTestId={index === filterValue ? 'active' : 'inactive'}
                className={classNames(classes.buttonBasic,
                    index === filterValue ? classes.active : classes.inactive
                )}
                onClick={() => {
                    handleButtonClick(index);
                }}
                primary
                accent
                skipPermission={skipPermission || false}
            >
                {t(name)}
            </CustomButton>
            })}
        </ButtonGroup>
    );
};

export default CustomizedButtonGroup;

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '19px 24px 40px 24px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            marginTop: 24,
        },
    },
    marginTop0: {
        marginTop: '0 !important',
    },

    sectionPadding: {
        paddingBottom: 0,
    },
    sectionMargin: {
        paddingTop: 0,
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            backgroundColor: Colors.SmokeBackground,
            maxWidth: 640,
            width: 520,
            height: 437,
            padding: '28px 60px',
            minHeight: 400,
        },

        '& .MuiTypography-root': {
            fontWeight: 700,
        },
    },

    didNumberMargin: {
        marginBottom: '0 !important',
    },

    primaryActionButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },

    actionButtonDisabled: {
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },

    header: {
        fontSize: 18,
        fontWeight: 700,
    },
    warningModal: {
        '& .MuiDialogContent-root': {
            minHeight: 40,
            height: 40,
            padding: 23,
            width: 594,
            display: 'flex',
            alignItems: 'center',
        },
    },

    warningWrapper: {
        '& svg': {
            height: 20,
            width: 22,
            '& path': {
                fill: Colors.Gray5,
            },
        },
    },
    otherErrorLabel: {
        fontSize: 16,
        lineHeight: '19px',
        fontWeight: 400,
        marginLeft: 18,
    },
}));

export interface CreateNewFaxMailboxFormData {
    extensionNumber: string;
    extensionName: string;
    mainProductId: number | undefined;
    extensionEmail?: string;
    didNumber?: string[];
}

import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import { DefaultAnsweringActionFlag } from '../../../../store/types/ServiceFeature';
import { useStyles, IncomingFormProps, IncomingFormType } from './utils';
import EmailOptionSelect from '../../../EmailOptionSelect/EmailOptionSelect';
import FaxFormatSelect from '../../../FaxFormatSelect/FaxFormatSelect';
import CustomizedRadio from '../../../Radio/Radio';
import OnHoldMusic from '../../../OnHoldMusic/OnHoldMusic';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import { VoicemailFileFormat, VoicemailGreeting } from '../../../../store/types/Voicemail';
import CustomizedTooltip from '../../../Tooltip/Tooltip';

const IncomingForm: React.FC<IncomingFormProps> = ({
    handleSetIsValidFunc,
    handleSetSubmitFunc,
    handleDirtyChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        values,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
        dirty,
        handleSubmit,
        isValid,
    } = useFormikContext<IncomingFormType>();

    useEffect(() => {
        handleDirtyChange?.('incoming', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('incoming', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('incoming', () => isValid);
    }, [isValid]);

    const defaultAnsweringModes = useMemo(
        () =>
            Object.values(DefaultAnsweringActionFlag).map((v) => ({
                name: t(`enums:defaultAnsweringActionAutoAttendantEnum.${v}`),
                value: v,
            })),
        [],
    );

    const greetingSelectList = useMemo(
        () =>
            Object.values(VoicemailGreeting).map((v) => ({
                name: t(`enums:voicemailGreeting.${v}`),
                value: v,
            })),
        [],
    );
    
    const greetingFiles = useMemo(
        () =>
            values.greetingFileName
                ? [{ id: 1, name: values.greetingFileName }]
                : undefined,
        [values.greetingType, values.greetingFileName],
    );
    
    const onSaveMoh = useCallback(
        (file, name, callback) => {
            setFieldValue('greetingFile', file);
            setFieldValue('greetingFileName', name);
            callback?.();
        },
        [setFieldValue],
    );
    
    const fileFormatsSelectList = useMemo(
        () =>
            Object.values(VoicemailFileFormat).map((v) => ({
                name: v.toUpperCase(),
                value: v,
            })),
        [],
    );

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    return (
        <div className={classes.inputs}>
            <Grid item className={classes.contentContainer}>
                <div className={classes.singleRow}>
                    <SelectField
                        label={t('screens:incoming.defaultAnsweringMode')}
                        items={defaultAnsweringModes}
                        value={defaultAnsweringModes.find(
                            (v) => v.value === values.defaultAnsweringMode,
                        )}
                        dataQa="incomingform-answering-mode-select"
                        onChange={(_, value) => {
                            setFieldValue('defaultAnsweringMode', value?.value);
                        }}
                        getOptionLabel={(v: { name: string; value: string }) =>
                            v.name
                        }
                        getOptionSelected={(v: {
                            name: string;
                            value: string;
                        }) => v.value === values.defaultAnsweringMode}
                        classes={{ container: classes.customContainer }}
                        disableClearable
                    />

                    <TextField
                        id="defaultAnsweringTimeout"
                        label={t('screens:incoming.timeout')}
                        onChange={handleChange}
                        value={values.defaultAnsweringTimeout.toString()}
                        type="number"
                        className={classes.numberInput}
                        dataQa="incoming-timeout"
                        inputProps={{
                            inputProps: {
                                min: 1,
                                max: 999,
                            },
                            pattern: '[0-9]*',
                            startAdornment: (
                                <span className={classes.minText}>sec</span>
                            ),
                        }}
                        widthFromLiableText
                    />
                </div>
            </Grid>
            <div className={classes.separator} style={{ marginBottom: 12 }} />
            <Grid item className={classes.faxContentContainer}>
                <SwitchWithLabel
                    label={t('screens:extensions.unifiedMessaging')}
                    setValue={setValue}
                    value={values.unifiedMessaging}
                    field={'unifiedMessaging'}
                    id={'unifiedMessaging'}
                    disabled={values.unifiedMessagingLocked}
                    dataQa={'status-switch-unifiedMessaging'}
                />
                {values.unifiedMessaging && (<>
                    <CustomizedTooltip
                        title={t('tooltips:billing.disabledFeature')}
                        copy={false}
                        disableHoverListener={!values.unifiedMessagingLocked}
                    >
                        <div className={classes.faxRadioButtonsRow}>
                            <CustomizedRadio
                                value={values.faxMailboxSwitcher}
                                checked={!values.faxMailboxSwitcher}
                                label={t('screens:extensions.voicemail')}
                                dataQa={`voicemail-radio`}
                                onChange={() => {
                                    setFieldValue('faxMailboxSwitcher', !values.faxMailboxSwitcher);
                                }}
                                disabled={values.unifiedMessagingLocked}
                            />
                            <CustomizedRadio
                                value={values.faxMailboxSwitcher}
                                className={classes.radioContainer}
                                checked={values.faxMailboxSwitcher}
                                label={t('screens:autoAttendants.faxMailbox')}
                                dataQa={`fax-mailbox-radio`}
                                onChange={() => {
                                    setFieldValue('faxMailboxSwitcher', !values.faxMailboxSwitcher);
                                }}
                                disabled={values.unifiedMessagingLocked}
                            />
                        </div>
                    </CustomizedTooltip>
                    {values.faxMailboxSwitcher ? (
                        <div className={classes.row} style={{marginBottom: 0}}>
                            <TextField
                                label={t(
                                    'screens:incoming.emailAddressIncoming',
                                )}
                                onChange={handleChange}
                                id={'emailAddressIncoming'}
                                value={values.emailAddressIncoming}
                                dataQa="incoming-emailAddressIncoming"
                                className={classes.emailAddressIncoming}
                                icon={
                                    <IconWithTooltip
                                        dataQa="incoming-emailAddressIncoming-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:incomings.emailAddressIncoming',
                                        )}
                                        copy={false}
                                    />
                                }
                                iconPosition={'end'}
                                helperText={
                                    errors.emailAddressIncoming &&
                                    t('errors:extensions.emailAddress')
                                }
                                setFieldError={setFieldError}
                                maxLength={128}
                            />

                            <EmailOptionSelect
                                value={values.emailOption}
                                label={t('screens:incoming.emailOption')}
                                copyTooltip={false}
                                tooltipMsg={t('tooltips:incomings.emailOption')}
                                dataQa="incoming-emailOption"
                                tooltipDataQa="incoming-emailOption-name-tooltip"
                                className={classes.emailOption}
                                classes={{ container: classes.emailOption }}
                                onChange={(_, value) => {
                                    setFieldValue(
                                        'emailOption',
                                        value.value,
                                        false,
                                    );
                                }}
                            />

                            <FaxFormatSelect
                                label={t('screens:autoAttendants.fileFormat')}
                                onChange={(e, value) =>
                                    setFieldValue('faxFormat', value.value)
                                }
                                value={values.faxFormat}
                                dataQa="incoming-faxFormat"
                                className={classes.faxFormat}
                                classes={{ container: classes.faxFormat }}
                                tooltipMsg={t('tooltips:incomings.faxFormat')}
                                copyTooltip={false}
                                tooltipDataQa="incoming-faxFormat-name-tooltip"
                            />
                        </div>
                    ) : (<>
                        <div className={classes.row} style={{width: 'fit-content', paddingTop: 9, marginBottom: 16}}>
                            <SelectField
                                label={t('screens:extensions.greetingType')}
                                items={greetingSelectList}
                                value={greetingSelectList.find(
                                    (v) => v.value === values.greetingType,
                                )}
                                dataQa="greeting-type-select"
                                onChange={(_, value) => {
                                    setFieldValue('greetingType', value?.value);
                                }}
                                getOptionLabel={(v: { name: string; value: string }) =>
                                    v.name
                                }
                                getOptionSelected={(v: {
                                    name: string;
                                    value: string;
                                }) => v.value === values.greetingType}
                                classes={{ container: classes.greetingContainer }}
                                disableClearable
                            />

                            <OnHoldMusic
                                id="greetingFileName"
                                fileSelectId="greetingFileName"
                                setValue={setFieldValue}
                                value={true}
                                hideSwitcher
                                disableSelectHide
                                customSelectLabel={t(
                                    'screens:extensions.greetingRecord',
                                )}
                                mohItems={greetingFiles}
                                selectedMohFile={values.greetingFileName || ''}
                                onSave={onSaveMoh}
                                classes={{
                                    container: classes.greetingFileContainer,
                                    selectContainer: classes.greetingFileContainer,
                                    outsideContainer:
                                        classes.greetingOutsideContainer,
                                }}
                                withoutUpload={
                                    values.greetingType ===
                                    VoicemailGreeting.Standard
                                }
                                customIcon={
                                    <IconWithTooltip
                                        dataQa="greeting-record-tooltip"
                                        tooltipText={t(
                                            `enums:voicemailGreetingDetails.${values.greetingType}`,
                                        )}
                                        copy={false}
                                        className={classes.greetingRecordTooltip}
                                    />
                                }
                            />
                        </div>

                        <div className={classes.separator} style={{ width: 440, marginLeft: 38 }} />

                        <div className={classes.row} style={{marginBottom: 0}}>
                            <TextField
                                id="unifiedMessagingEmailAddress"
                                label={t('screens:extensions.emailAddress')}
                                dataQa="email-address-input"
                                onChange={handleChange}
                                value={values.unifiedMessagingEmailAddress}
                                icon={
                                    <>
                                        <IconWithTooltip
                                            dataQa="email-address-tooltip"
                                            tooltipText={t(
                                                'tooltips:extensions.voicemailEmailAddress',
                                            )}
                                        />
                                    </>
                                }
                                iconPosition="end"
                                maxLength={128}
                                setFieldError={setFieldError}
                                helperText={errors.unifiedMessagingEmailAddress}
                                className={classes.emailAddressIncoming}
                            />

                            <EmailOptionSelect
                                label={t('screens:extensions.emailOption')}
                                onChange={(_, value) => {
                                    setFieldValue('emailOption', value.value);
                                }}
                                dataQa="email-option-input"
                                tooltipDataQa="email-option-tooltip"
                                tooltipMsg={t(
                                    'tooltips:extensions.emailOption',
                                )}
                                copyTooltip={false}
                                value={values.emailOption}
                                className={classes.emailOption}
                            />
                            <SelectField
                                label={t('screens:autoAttendants.fileFormat')}
                                items={fileFormatsSelectList}
                                value={fileFormatsSelectList.find(
                                    (v) => v.value === values.fileFormat,
                                )}
                                onChange={(_, value) => {
                                    setFieldValue(
                                        'fileFormat',
                                        value.value,
                                        false,
                                    );
                                }}
                                disableClearable
                                dataQa="file-format-input"
                                icon={
                                    <IconWithTooltip
                                        dataQa="file-format-tooltip"
                                        tooltipText={t(
                                            'tooltips:extensions.fileFormat',
                                        )}
                                    />
                                }
                                getOptionLabel={(v: { name: string }) => v.name}
                                getOptionSelected={(v: {
                                    value: VoicemailFileFormat;
                                }) => v.value === values.fileFormat}
                                className={classes.faxFormat}
                            />
                        </div>

                        <div className={classes.separator}  style={{ width: 440, marginLeft: 38, marginTop: 24, marginBottom: 8 }} />

                        <div className={classes.rowCheckBox}>
                            <CustomizedCheckbox
                                checked={values.requirePinStatus}
                                label={t('screens:extensions.requirePin')}
                                dataQa="requirePinStatus"
                                onChange={() =>
                                    setFieldValue(
                                        'requirePinStatus',
                                        !values.requirePinStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.requirePin',
                                )}
                                type="small"
                            />
                        </div>

                        {values.requirePinStatus && (
                            <TextField
                                id="pin"
                                label={t('screens:extensions.pin')}
                                onChange={handleChange}
                                value={values.pin}
                                dataQa="pin-input"
                                className={classes.pinInput}
                                maxLength={5}
                                inputProps={{
                                    pattern: '[0-9]*',
                                }}
                                required
                                setFieldError={setFieldError}
                                helperText={errors?.pin}
                            />
                        )}

                        <div className={classes.rowCheckBox}>
                            <CustomizedCheckbox
                                checked={values.autoPlayStatus}
                                label={t('screens:extensions.autoPlayMessages')}
                                dataQa={`autoPlayStatus`}
                                onChange={() =>
                                    setFieldValue(
                                        'autoPlayStatus',
                                        !values.autoPlayStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t('tooltips:extensions.autoPlay')}
                                type="small"
                            />
                        </div>

                        <div className={classes.rowCheckBox}>
                            <CustomizedCheckbox
                                checked={values.announceDateStatus}
                                label={t('screens:extensions.announceDateTime')}
                                dataQa={`announceDateStatus`}
                                onChange={() =>
                                    setFieldValue(
                                        'announceDateStatus',
                                        !values.announceDateStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.announceDateTime',
                                )}
                                type="small"
                            />
                        </div>
                    </>)}
                </>)}
            </Grid>
        </div>
    );
};

export default IncomingForm;

import React, {useEffect, useMemo, useState} from 'react';
import Header from '../../../components/ListViewHeader/Header';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {actions} from '../../../store';
import DataGrid from '../../../components/DataGrid/DataGrid';
import {PaginationMode} from '../../../components/DataGrid/types';
import Filters from './Filters';
import {FaxMailboxesListType} from '../../../store/reducers/faxMailboxes/reducer';
import AddNewFaxMailboxesDialog from '../CreateNewDialog/AddNewFaxMailboxesDialog';
import {filterData, generateColumns, useStyles} from './utils';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import TransmissionHistoryDialog from '../TransmissionHistoryDialog/TransmissionHistoryDialog';
import usePageTitle from '../../../hooks/usePageTitle';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {Permission} from '../../../store/types/Permission';
import PermissionPlaceholder from "../../../components/PermissionProvider/PermissionPlaceholder";

const FaxMailboxesList = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const source = useSelector(
        (state: ReduxState) => state.faxMailboxes.faxMailboxesList,
    );
    const {deletingInProgress} = useSelector(
        (state: ReduxState) => state.faxMailboxes,
    );
    const [data, setData] = useState<FaxMailboxesListType>({
        items: [],
        total: 0,
        isLoading: false
    });

    const [isOpenAddNew, setIsOpenNew] = useState(false);
    const [filters, setFilters] = useState<{
        name?: string;
        email?: string;
        did?: string;
        status?: string;
    }>({
        name: '',
        email: '',
        did: '',
        status: '',
    });

    const [historyModal, setHistoryModal] = useState<{
        isOpen: boolean;
        i_account?: number;
        extensionName?: string;
    }>();

    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
    }>();

    const fetchData = () => {
        dispatch(actions.getFaxMailboxesList.request({}));
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (source) {
            handleSubmitSearch();
        }
    }, [source?.items]);

    useEffect(() => {
        data.items.forEach((e) => {
            e.fetchDIDNumbers === undefined && fetchDIDNumber(e.i_account);
        });
    }, [data.items]);

    const handleChangeFilters = (
        name?: string,
        email?: string,
        did?: string,
        status?: string,
    ) => {
        setFilters({name, email, did, status});
    };

    const handleSubmitSearch = () => {
        if (!source) {
            return null;
        }

        const items = filterData(filters, [...source.items]);

        setData((prevState) => ({
            ...prevState,
            items: items,
            total: items.length
        }));
    };

    const handleAddNewClick = () => setIsOpenNew((prevState) => !prevState);

    const fetchDIDNumber = (i_account: number) => {
        dispatch(
            actions.fetchDIDNumber.request({
                i_account: i_account,
            }),
        );
    };

    const deleteExtension = () => {
        if (removeModal?.id && removeModal?.i_customer) {
            dispatch(
                actions.deleteCustomerFaxMailbox.request({
                    id: removeModal.id,
                    i_customer: removeModal.i_customer,
                    callback: () => {
                        fetchData();
                    }
                }),
            );

            setRemoveModal({...removeModal, isOpen: false});
        }
    };

    const showRemoveExtensionModal = (
        id: string,
        i_customer: number,
        name: string,
    ) => {
        setRemoveModal({
            isOpen: true,
            id,
            name: name,
            i_customer: i_customer,
        });
    };

    const hideHistoryModal = () => {
        setHistoryModal({
            isOpen: false,
            i_account: undefined,
            extensionName: undefined,
        });
    };

    const showHistoryModal = (i_account: number, extensionName?: string) => {
        setHistoryModal({
            isOpen: true,
            i_account,
            extensionName,
        });
    };

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                fetchDIDNumber,
                classes,
                showRemoveExtensionModal,
                showHistoryModal,
            ),
        [],
    );

    const closeRemoveDialog = () =>
        setRemoveModal({
            ...removeModal,
            isOpen: false,
        });

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.FaxMailboxes.value}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.FaxMailboxes.AddNewFaxMailbox.value
                        }
                    >
                        <Header
                            title={t('screens:faxMailboxes.listTitle')}
                            totalNumber={data?.total || 0}
                            dataQa={'fax-mailboxes-list-view-header'}
                            buttonText={t('common:addNewButton')}
                            plusIcon={true}
                            buttonOnClick={handleAddNewClick}
                            showDetails={false}
                        />
                    </PermissionProvider>

                    <Filters
                        handleChange={handleChangeFilters}
                        onSubmit={handleSubmitSearch}
                    />
                    <DataGrid
                        className={classes.table}
                        columns={columns}
                        data={data?.items || []}
                        rowCount={data?.total || 0}
                        loading={source?.isLoading || deletingInProgress || false}
                        paginationMode={PaginationMode.Client}
                        initialPageSize={10}
                        centeredRows
                        classes={{tableContainer: classes.tableContainer}}
                    />
                    <AddNewFaxMailboxesDialog
                        isOpen={isOpenAddNew}
                        toggleVisibility={() => setIsOpenNew(!isOpenAddNew)}
                    />
                    <TransmissionHistoryDialog
                        isOpen={!!historyModal?.isOpen}
                        accountId={historyModal?.i_account}
                        extensionName={historyModal?.extensionName}
                        toggleVisibility={hideHistoryModal}
                    />
                    <AlertDialog
                        isOpen={!!removeModal?.isOpen}
                        dataQa={'delete-fax-mailbox-modal'}
                        contentClass="medium-width-modal-delete"
                        hideHeader={true}
                        description={t('screens:faxMailboxes.deleteMsg', {
                            name: removeModal?.name,
                        })}
                        className={classes.deleteDialogButtons}
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={closeRemoveDialog}
                            />,
                            <DialogButton
                                key="delete"
                                label={t('common:delete')}
                                onClick={deleteExtension}
                            />,
                        ]}
                    />
                </div>
            </div>
        </PermissionPlaceholder>
    );
};

export default FaxMailboxesList;

import { Service } from '../../components/Extensions/ServiceIcon';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../store/types/ServiceFeature';
import { CallProcessingModeName } from '../../components/Calls/ModeCell';

export type AttributesService = {
    name: string;
    values: any[];
    effective_values: any[];
};

export const getServiceValue = (
    name: ServiceFeatureName,
    serviceFeatures: ServiceFeature[],
) => {
    return (
        serviceFeatures.find((v) => v.name === name)?.effective_flag_value ===
        'Y'
    );
};

export const getFaxEnabledValue = (fax: AttributesService | undefined) => {
    if (fax) {
        return fax.effective_values[0] !== undefined
            ? fax.effective_values[0]
            : fax.values[0];
    }
};

export const checkAttributeEnabled = (
    service: ServiceFeature,
    name: string,
) => {
    return !!service?.attributes.find(
        (att) =>
            att.name == name &&
            att.values.includes('Y') &&
            att.effective_values.includes('Y'),
    );
};

export const selectProperCallProcessingService = (
    mode?: string,
    serviceFeature?: ServiceFeature,
) => {
    const pbxEnabled = serviceFeature
        ? checkAttributeEnabled(serviceFeature, 'pbx_management')
        : false;

    if (!!mode && mode != CallProcessingModeName.Normal && !pbxEnabled) {
        return Service.CallProcessingOther;
    } else if (pbxEnabled && mode == CallProcessingModeName.Normal) {
        return Service.CallProcessingPbxEnabled;
    } else if (pbxEnabled && mode != CallProcessingModeName.Normal) {
        return Service.CallProcessingPbxEnabledOther;
    } else {
        return Service.CallProcessing;
    }
};

export const convertServiceFeaturesToIconsFeatures = (
    serviceFeatures: ServiceFeature[],
    serviceToSkip?: Service,
    mode?: string,
) => {
    const fax = serviceFeatures
        .find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
        ?.attributes.find((v) => v.name === 'fax_only_mode');

    const callProcessingValue = serviceFeatures.find(
        (v) =>
            v.name === ServiceFeatureName.CallProcessing &&
            v.effective_flag_value === 'Y',
    );

    const output = [
        getServiceValue(ServiceFeatureName.CallBarring, serviceFeatures)
            ? Service.CallBarring
            : undefined,
        callProcessingValue
            ? selectProperCallProcessingService(mode, callProcessingValue)
            : undefined,
        getServiceValue(ServiceFeatureName.CallRecording, serviceFeatures)
            ? Service.CallRecording
            : undefined,
        getServiceValue(ServiceFeatureName.ConfEnabled, serviceFeatures)
            ? Service.ConfEnabled
            : undefined,
        getServiceValue(ServiceFeatureName.UnifiedMessaging, serviceFeatures) &&
        getFaxEnabledValue(fax) === 'Y'
            ? Service.UnifiedMessagingFaxOnly
            : undefined,
        getServiceValue(ServiceFeatureName.UnifiedMessaging, serviceFeatures) &&
        getFaxEnabledValue(fax) !== 'Y'
            ? Service.UnifiedMessaging
            : undefined,
    ];

    return serviceToSkip
        ? output.map((v) => (v === serviceToSkip ? undefined : v))
        : output;
};

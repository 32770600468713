import React from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { HeaderRowProps } from './types';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
    },
    emptyCell: {
        width: 90,
    },

    orderCell: {
        width: 60,
    },
}));

const HeaderRow: <T extends Object>(
    p: HeaderRowProps<T>,
) => React.ReactElement<HeaderRowProps<T>> = ({
    columns,
    isVisible = true,
    dragEnabled,
    dragHeader,
    dragHeaderStyle,
}) => {
    const classes = useStyles();

    if (!isVisible) {
        return <></>;
    }

    return (
        <TableRow className={classes.container}>
            {dragEnabled && !dragHeader && (
                <td
                    className={classNames(classes.emptyCell, dragHeaderStyle)}
                />
            )}
            {dragEnabled && dragHeader && (
                <TableCell
                    className={classNames(classes.orderCell, dragHeaderStyle)}
                >
                    {dragHeader}
                </TableCell>
            )}
            {columns.map((column, id) => {
                const cellProps = column.getHeaderProps();
                return (
                    <TableCell
                        //@ts-ignore
                        key={id}
                        {...cellProps}
                        style={{
                            ...cellProps.style,
                            maxWidth: column.maxWidth,
                            minWidth: column.minWidth,
                            alignSelf: 'center',
                        }}
                    >
                        {column.render('Header')}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default HeaderRow;

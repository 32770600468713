import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    expandMenusContainer: {
        position: 'absolute',
        top: 30,
        right: 60,
        zIndex: 100,
    },
    expandMenusLabel: {
        color: Colors.Gray7,
        fontWeight: 'bold',
    },
}));

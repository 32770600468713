import {DialogButton} from '../AlertDialog/DialogContainer';
import AlertDialog from '../AlertDialog/AlertDialog';
import React from 'react';
import {DialogInterface} from './types/dialog';
import {useTranslation} from 'react-i18next';
import {Warning} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    dialogContainer: {
        '& .MuiPaper-root': {
            width: 640,
        },
    },

    content: {
        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
        },

        '& svg > path': {
            fill: Colors.Gray5,
        },

        '& svg': {
            marginRight: 17,
        },
    },
}));

const CreateNewExtensionErrorDialog: React.FC<DialogInterface> = (
    {
        isOpen,
        toggleVisibility,
        customHeader,
        customDesc
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const handleClick = () => {
        toggleVisibility?.();
    };

    const description = (
        <>
            <Warning/>
            {customDesc ?? t('screens:extensions.errorCreateNewExtension')}
        </>
    );

    return (
        <AlertDialog
            isOpen={isOpen}
            header={customHeader ?? t('screens:extensions.createNewExtension')}
            description={description}
            contentClass={classes.content}
            className={classes.dialogContainer}
            dialogActionsButtons={
                <DialogButton
                    key="cancel"
                    label={t('common:close')}
                    onClick={handleClick}
                    primary
                />
            }
        />
    );
};

export default CreateNewExtensionErrorDialog;

import { createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import {
    AssignDIDToAccountResponse,
    CancelDIDAssignmentRequest,
    CancelDIDAssignmentResponse,
    CustomerDIDNumberType,
    CustomerNumberType,
    ReassignDIDNumberPayload,
} from '../../types/CustomerDIDNumber';
import {
    DeleteDIDNumberPayload,
    GetCustomerDIDNumbersRequest,
    FetchAccountInformationPayload,
    FetchAccountInformationSuccessPayload,
    GetFilteredDidNumbersPayload,
} from './payloads';
import { AccountListResponse } from '../../types/Account';
import { ExtensionType } from '../../types/Extension';

export const didNumbers = createAsyncAction(
    'GET_DID_NUMBERS',
    'GET_DID_NUMBERS_SUCCESSED',
    'GET_DID_NUMBERS_FAILED',
)<
    GetCustomerDIDNumbersRequest | null | undefined,
    CustomerNumberType,
    APIErrorInterface
>();

export const didNumberWithServices = createAsyncAction(
    'GET_DID_NUMBERS_SERVICES',
    'GET_DID_NUMBERS_SERVICES_SUCCESSED',
    'GET_DID_NUMBERS_SERVICES_FAILED',
)<
    GetCustomerDIDNumbersRequest | null | undefined,
    CustomerNumberType,
    APIErrorInterface
>();

export const didAccountsToAssign = createAsyncAction(
    'GET_ACCOUNTS_TO_ASSIGN',
    'GET_ACCOUNTS_TO_ASSIGN_SUCCESS',
    'GET_ACCOUNTS_TO_ASSIGN_FAILED',
)<{ i_customer: number }, AccountListResponse, undefined>();

export const assignDIDNumberToAccount = createAsyncAction(
    'POST_ASSIGN_DID_NUMBER',
    'POST_ASSIGN_DID_NUMBER_SUCCESS',
    'POST_ASSIGN_DID_NUMBER_FAILED',
)<ReassignDIDNumberPayload, AssignDIDToAccountResponse, undefined>();

export const cancelDIDNumberAssignet = createAsyncAction(
    'CANCEL_DID_ASSIGNET',
    'CANCEL_DID_ASSIGNET_SUCCESS',
    'CANCEL_DID_ASSIGNET_FAILED',
)<CancelDIDAssignmentRequest, CancelDIDAssignmentResponse, undefined>();

export const getAccountByIAccount = createAsyncAction(
    'GET_ACCOUNT_BY_I_ACCOUNT',
    'GET_ACCOUNT_BY_I_ACCOUNT_SUCCESS',
    'GET_ACCOUNT_BY_I_ACCOUNT_FAILED',
)<
    { master_account_id: string },
    { assignedTo: ExtensionType | undefined },
    undefined
>();

export const didNumbersForSelect = createAsyncAction(
    'GET_DID_NUMBERS_FOR_SELECT',
    'GET_DID_NUMBERS_FOR_SELECT_SUCCESS',
    'GET_DID_NUMBERS_FOR_SELECT_FAILED',
)<undefined, { numbers: CustomerDIDNumberType[] }, APIErrorInterface | undefined>();

export const deleteDIDNumber = createAsyncAction(
    'DELETE_DID_NUMBER',
    'DELETE_DID_NUMBER_SUCCESS',
    'DELETE_DID_NUMBER_FAILED',
)<DeleteDIDNumberPayload, undefined, undefined>();

export const fetchAccountInformation = createAsyncAction(
    'FETCH_ACCOUNT_INFORMATION',
    'FETCH_ACCOUNT_INFORMATION_SUCCESS',
    'FETCH_ACCOUNT_INFORMATION_FAILED',
)<
    FetchAccountInformationPayload,
    FetchAccountInformationSuccessPayload,
    undefined
>();

export const getFilteredDidNumbers = createAsyncAction(
    'GET_FILTERED_DID_NUMBERS',
    'GET_FILTERED_DID_NUMBERS_SUCCESS',
    'GET_FILTERED_DID_NUMBERS_FAILED',
)<GetFilteredDidNumbersPayload, CustomerNumberType, undefined>();

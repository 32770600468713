import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs/Tabs';
import { Add, ChevronLeft } from '@material-ui/icons';
import history from '../../history';
import GeneralForm, {
    ringGroupGeneralFormDefaultValues,
} from '../../components/Forms/RingGroups/GeneralForm';
import {
    RingGroupGeneralFormType,
    ringGroupGeneralFormValidationSchema,
} from '../../components/Forms/RingGroups/generalFormUtils';
import { ReduxState } from '../../store/types';
import { ApiFile } from '../../store/types/ApiFile';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import FormActions from '../../components/Forms/FormActions';
import Loader from '../../components/Loader/Loader';
import CustomButton from '../../components/Button/Button';
import GroupMembersForm, {
    groupMembersDefaultValues,
    GroupMembersFormType,
    groupMembersValidationSchema,
} from '../../components/Forms/RingGroups/GroupMembersForm';
import {
    CallQueueFormType,
    callQueueValidationSchema,
} from '../../components/Forms/RingGroups/callQueueFormUtils';

import CallQueueForm, {
    callQueueFormDefaultValues,
} from '../../components/Forms/RingGroups/CallQueueForm';
import usePageTitle from '../../hooks/usePageTitle';

const PlusIcon = (
    <Add
        htmlColor={Colors.White}
        style={{ height: 22, width: 22, marginRight: 10 }}
    />
);

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,

        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
    },
    extensionHeader: {
        padding: '30px 90px 10px 90px',
        backgroundColor: Colors.Gray2,
        display: 'flex',
        alignItems: 'center',
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '30px 90px 30px 90px',
        borderTop: `1px ${Colors.Border} solid`,
        backgroundColor: Colors.White,
        margin: 0,
    },

    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    header: {
        fontWeight: 500,
        fontSize: 32,
    },
}));

export type AddRingGroupForm = RingGroupGeneralFormType &
    GroupMembersFormType &
    CallQueueFormType;

const formValidationSchema = ringGroupGeneralFormValidationSchema
    .concat(groupMembersValidationSchema)
    .concat(callQueueValidationSchema);

const defaultValues = {
    ...ringGroupGeneralFormDefaultValues,
    ...groupMembersDefaultValues,
    ...callQueueFormDefaultValues,
};

const AddRingGroup: React.VFC = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const [groupMemberDialogVisible, setGroupMemberDialogVisible] = useState(
        false,
    );

    const isFormSaving = useSelector<ReduxState, boolean>(
        (state) => !!state.ringgroups.isFormSending,
    );

    const ringbackTonesList = useSelector<ReduxState, ApiFile[]>(
        (state) => state.ringgroups.ringbackTonesList || [],
    );

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.ringgroups.isFormLoading,
    );

    useEffect(() => {
        dispatch(actions.prepareDataForAddNewRingGroup.request());
    }, []);

    const onSubmitForm = useCallback(
        (form: AddRingGroupForm) => {
            dispatch(
                actions.addRingGroup.request({ form, redirectTab: tabIndex }),
            );
        },
        [tabIndex],
    );

    const onBackPress = useCallback(() => {
        history.push('/ring-groups');
    }, []);

    if (loading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="add-ring-group-loader" />
            </div>
        );
    }

    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={formValidationSchema}
            onSubmit={onSubmitForm}
            enableReinitialize
            validateOnChange={false}
        >
            {({ submitForm }) => (
                <>
                    <div className={classes.root}>
                        <form className={classes.formContainer}>
                            <div className={classes.scrollable}>
                                <div className={classes.extensionHeader}>
                                    <IconButton
                                        style={{ marginRight: 10 }}
                                        onClick={onBackPress}
                                    >
                                        <ChevronLeft htmlColor={Colors.Text} />
                                    </IconButton>
                                    <h2 className={classes.header}>
                                        {t('screens:ringGroups.newRingGroup')}
                                    </h2>
                                </div>

                                <Tabs
                                    tabsNames={[
                                        t('common:general'),
                                        t('screens:ringGroups.members'),
                                        t('screens:ringGroups.callQueue'),
                                    ]}
                                    onChange={(_, index) => setTabIndex(index)}
                                    CustomRightSideComponent={
                                        tabIndex === 1 ? (
                                            <CustomButton
                                                primary
                                                accent
                                                dataQa="followme-add-rule-button"
                                                className={classes.addButton}
                                                onClick={() =>
                                                    setGroupMemberDialogVisible(
                                                        !groupMemberDialogVisible,
                                                    )
                                                }
                                            >
                                                {PlusIcon}
                                                {t(
                                                    'screens:ringGroups.addGroupMember',
                                                )}
                                            </CustomButton>
                                        ) : undefined
                                    }
                                >
                                    <GeneralForm
                                        ringbackTonesList={ringbackTonesList}
                                    />
                                    <GroupMembersForm
                                        groupMemberDialogVisible={
                                            groupMemberDialogVisible
                                        }
                                        toggleMemberDialogVisible={() =>
                                            setGroupMemberDialogVisible(
                                                !groupMemberDialogVisible,
                                            )
                                        }
                                    />
                                    <CallQueueForm />
                                </Tabs>
                            </div>
                            <FormActions
                                isSaveActive
                                onCancel={onBackPress}
                                onSave={submitForm}
                                className={classes.bottomBar}
                            />
                        </form>

                        {isFormSaving && (
                            <Loader
                                dataQa="edit-ring-group-sending-loader"
                                absolutePosition
                            />
                        )}
                    </div>
                </>
            )}
        </Formik>
    );
};

export default AddRingGroup;

import React, {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import TextField from '../TextField/TextField';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import {greyColor3, greyColor6} from '../../styles/Colors';
import classNames from 'classnames';
import {RingGroupType} from '../../store/types/RingGroup';
import Checkbox from '../Checkbox/Checkbox';
import Radio from '../Radio/Radio';
import SelectField from '../SelectField/SelectField';
import SwitchWithLabel from '../SwitchWithLabel/SwitchWithLabel';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import {omit} from 'lodash';
import {
    DispatchType,
    GroupMemberType,
    RingGroupMemberDialogProps,
    RingGroupMemberFormProps,
    ringGroupMemberFormValidationSchema,
} from './groupDialogUtils';
import {usePermissions} from '../../hooks/usePermissions';
import {Permission, PermissionType} from '../../store/types/Permission';
import CustomizedTooltip from '../Tooltip/Tooltip';
import AllowGroupPagging from "./AllowGroupPagging";
import {useSelector} from 'react-redux';
import {CustomerOfficeType} from '../../store/types/CustomerInfo';
import {ReduxState} from '../../store/types';
import {formInitialValues, useStyles} from "./GroupMemberDialog.utils";

const GroupMemberDialog: React.VFC<RingGroupMemberDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        ringGroups = [],
        extensions = [],
        onSave,
        objectToEdit,
        i_c_group,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [type, setType] = useState<'ringGroup' | 'extensions' | null>(null);
    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const permission = usePermissions(...Permission.CloudPBX.RingGroups.RingGroupDetails.Members.MembersSettings.value);
    const permissionBase = usePermissions(...Permission.CloudPBX.RingGroups.RingGroupDetails.Members.value);
    const isBranchOffice = permission === PermissionType.Visible && PermissionType.Visible && i_office_type === CustomerOfficeType.Branch;
    let readOnly = false

    if (isBranchOffice ||
        (objectToEdit && permission === PermissionType.ReadOnly) ||
        (!objectToEdit && permissionBase === PermissionType.ReadOnly)) {

        readOnly = true
    }

    const initialValues = useMemo(() => {
        const type = objectToEdit?.extension ? 'extension' : 'ringGroup';

        return objectToEdit
            ? {
                ...objectToEdit,
                ringing:
                    objectToEdit.dispatchType === DispatchType.Custom ||
                    objectToEdit.dispatchType ===
                    DispatchType.PermanentlyEnabled,
                supervisor: objectToEdit.supervisionAllowed === 'Y',
                member: objectToEdit[type],
                allowGroupPaging: objectToEdit.allowGroupPaging || false,
                dispatchType: objectToEdit.dispatchType
            }
            : formInitialValues;
    }, [objectToEdit]);

    useEffect(() => {
        setType(
            objectToEdit?.extension
                ? 'extensions'
                : objectToEdit?.ringGroup
                    ? 'ringGroup'
                    : null,
        );
    }, [objectToEdit]);

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        setFieldError,
        resetForm,
        dirty,
    } = useFormik<RingGroupMemberFormProps>({
        initialValues,
        onSubmit: (form) => {
            const preparedForm = omit(form, ['supervisor']);
            type === 'extensions'
                ? (preparedForm.extension = form.member)
                : //@ts-ignore
                (preparedForm.ringGroup = form.member);
            preparedForm.id = form?.member?.id;
            preparedForm.supervisionAllowed = form.supervisor ? 'Y' : 'N';
            preparedForm.allowGroupPaging = form.allowGroupPaging;
            preparedForm.groupMemberType =
                type === 'extensions'
                    ? GroupMemberType.Extension
                    : GroupMemberType.RingGroup;
            preparedForm.dispatchType = form.ringing
                ? preparedForm.dispatchType
                : DispatchType.Disabled;
            onSave?.(preparedForm);
            resetForm();
        },
        validationSchema: ringGroupMemberFormValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    const isPrimaryGroupDisabled = useMemo(() => {
        const extension = extensions.find(
            (v) => v.i_c_ext === values.member?.i_c_ext,
        );

        if (extension && extension.primary_i_c_group) {
            return extension.primary_i_c_group !== i_c_group;
        }

        return false;
    }, [values.member, i_c_group, extensions]);

    const members = useMemo(() => {
        const preparedExtension = extensions.map((e) => {
            return {id: e.id, name: e.name, group: 'extensions'};
        });
        const preparedRingGroup = ringGroups.map((e) => {
            return {id: e.id, name: e.name, group: 'ringGroup'};
        });

        return [
            {
                id: t(
                    `enums:groupMemberPlural.${GroupMemberType.Extension}`,
                ).toUpperCase(),
                value: t(
                    `enums:groupMemberPlural.${GroupMemberType.Extension}`,
                ).toUpperCase(),
                disabled: true,
            },
            ...preparedExtension,
        ].concat(
            {
                id: t(
                    `enums:groupMemberPlural.${GroupMemberType.RingGroup}`,
                ).toUpperCase(),
                value: t(
                    `enums:groupMemberPlural.${GroupMemberType.RingGroup}`,
                ).toUpperCase(),
                disabled: true,
            },
            ...preparedRingGroup,
        );
    }, [ringGroups, extensions]);

    const onChangeGroupMember = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _: any,
        value: { id: string; value: string; group: string },
    ) => {
        const newMember =
            value.group === 'extensions'
                ? extensions.find((e) => e.id === value.id)
                : ringGroups.find((e) => e.id === value.id);

        setFieldValue('member', newMember);
        setFieldError('member', undefined);
        setType(value.group === 'extensions' ? 'extensions' : 'ringGroup');

        if (value.group === 'ringGroup') {
            setFieldValue('setAsPrimary', false);
            setFieldValue('supervisor', false);
        }
    };

    const toggleModalVisiblity = () => {
        toggleVisibility?.();
        setTimeout(resetForm, 200);
        setTimeout(setType, 200, null);
    };

    useEffect(() => {
        if (values.ringing && values.dispatchType === DispatchType.Disabled) {
            setFieldValue('dispatchType', DispatchType.PermanentlyEnabled)
        }
    }, [values.ringing]);

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="add-ring-group-member"
            header={
                !!objectToEdit
                    ? t('screens:ringGroups.editGroupMember')
                    : t('screens:ringGroups.addNewGroupMember')
            }
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={toggleModalVisiblity}
                />,
                <DialogButton
                    key="save"
                    label={t('common:save')}
                    primary
                    onClick={handleSubmit}
                    disabled={!dirty}
                />,
            ]}
        >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="add-group-member-form"
            >
                <Grid item className={classes.itemsContainer}>
                    <CustomizedTooltip
                        title={t('common:noPermissionToResource')}
                        copy={false}
                        disableHoverListener={!readOnly}
                    >
                        <div style={{width: '100%', display: 'flex'}}>
                            <SelectField
                                label={t('screens:ringGroups.member')}
                                items={members}
                                value={
                                    members.find((v) => v.id === values?.member?.id) ||
                                    ''
                                }
                                dataQa="member-select"
                                dataTestId="member-select"
                                onChange={onChangeGroupMember}
                                getOptionLabel={(v: RingGroupType) =>
                                    v.id ? v.id + (v.name ? ' - ' + v.name : '') : ''
                                }
                                getOptionSelected={(v: RingGroupType) =>
                                    v.id === values?.member?.id
                                }
                                required
                                className={classes.groupSelect}
                                helperText={
                                    errors.member ? t('errors:common.emptyInput') : ''
                                }
                                getOptionDisabled={(v) => v?.disabled}
                                id="member"
                                disableClearable
                                readOnly={readOnly}
                                disabled={readOnly}
                            />
                        </div>
                    </CustomizedTooltip>
                    <Box flexDirection="column" display="flex">
                        <Grid>
                            <Box display="flex" alignItems="center">
                                <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={!readOnly}
                                >
                                    <div>
                                        <Checkbox
                                            dataQa="ignore-follow-me-voicemail"
                                            checked={values.ignoreFollowMeVoiceMail}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'ignoreFollowMeVoiceMail',
                                                    e.target.checked,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.ignoreFollowMeVoicemail',
                                            )}
                                            disabled={readOnly}
                                        />
                                    </div>
                                </CustomizedTooltip>
                                <IconWithTooltip
                                    dataQa="ignoreFollowMeVoicemail-tooltip"
                                    tooltipText={t(
                                        'tooltips:ringGroups.ignoreFollowMeVoicemail',
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid>
                            <Box display="flex" alignItems="center">
                                <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={!readOnly}
                                >
                                    <div>
                                        <Checkbox
                                            dataQa="set-group-as-primary"
                                            checked={values.setAsPrimary}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'setAsPrimary',
                                                    e.target.checked,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.setGroupAsPrimary',
                                            )}
                                            disabled={
                                                type === 'ringGroup' ||
                                                isPrimaryGroupDisabled ||
                                                readOnly
                                            }
                                        />
                                    </div>
                                </CustomizedTooltip>

                                <IconWithTooltip
                                    dataQa="primaryGroup-tooltip"
                                    tooltipText={t(
                                        'tooltips:ringGroups.primaryGroup',
                                    )}
                                    iconColor={
                                        values.ringGroup !== undefined
                                            ? greyColor6
                                            : greyColor3
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid>
                            <AllowGroupPagging
                                readOnly={readOnly}
                                allowGroupPaging={values.allowGroupPaging}
                                type={type}
                                ringGroup={values.ringGroup}
                                onChange={(e) =>
                                    setFieldValue(
                                        'allowGroupPaging',
                                        e.target.checked,
                                        false,
                                    )}/>
                        </Grid>
                        <Grid>
                            <Box
                                className={classes.marginBottom}
                                display="flex"
                                alignItems="center"
                            >
                                <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={!readOnly}
                                >
                                    <div>
                                        <Checkbox
                                            dataQa="supervisor"
                                            checked={values.supervisor}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'supervisor',
                                                    e.target.checked,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.supervisor',
                                            )}
                                            disabled={type === 'ringGroup' || readOnly}
                                        />
                                    </div>
                                </CustomizedTooltip>

                                <IconWithTooltip
                                    dataQa="supervisor-tooltip"
                                    tooltipText={t(
                                        'tooltips:ringGroups.supervisor',
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
                <SwitchWithLabel
                    label={t('screens:ringGroups.ringing')}
                    setValue={setFieldValue}
                    value={values.ringing || false}
                    field={'ringing'}
                    id={'ringing'}
                    className={classes.ringing}
                    icon={
                        <IconWithTooltip
                            dataQa="ringing-tooltip"
                            tooltipText={t('tooltips:ringGroups.ringing')}
                        />
                    }
                    disabled={readOnly}
                    customDisabledTooltip={t('common:noPermissionToResource')}
                />
                {values.ringing && (
                    <CustomizedTooltip
                        title={t('common:noPermissionToResource')}
                        copy={false}
                        disableHoverListener={!readOnly}
                    >
                        <Box display="flex">
                            <Box
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.radioContainer,
                                    values.dispatchType ===
                                    DispatchType.PermanentlyEnabled &&
                                    classes.selectedRadio,
                                )}
                                flex={1}
                            >
                                <Grid>
                                    <Grid>
                                        <Radio
                                            dataQa="dispatch-type-permamently-enabled"
                                            checked={
                                                values.dispatchType ===
                                                DispatchType.PermanentlyEnabled
                                            }
                                            name="dispatchType"
                                            value={values.dispatchType}
                                            onChange={() =>
                                                setFieldValue(
                                                    'dispatchType',
                                                    DispatchType.PermanentlyEnabled,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.permanently',
                                            )}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <p className={readOnly ? classes.descriptionRadioDisabled : classes.descriptionRadio}>
                                        {t(
                                            'screens:addToRingGroup.permanentlyEnabledDescription',
                                        )}
                                    </p>
                                </Grid>
                            </Box>

                            <Box
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.radioContainer,
                                    values.dispatchType === DispatchType.Custom &&
                                    classes.selectedRadio,
                                )}
                                flex={1}
                            >
                                <Box>
                                    <Grid>
                                        <Radio
                                            dataQa="dispatch-type-custom"
                                            checked={
                                                values.dispatchType ===
                                                DispatchType.Custom
                                            }
                                            name="dispatchType"
                                            value={values.dispatchType}
                                            onChange={() =>
                                                setFieldValue(
                                                    'dispatchType',
                                                    DispatchType.Custom,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.custom',
                                            )}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                </Box>

                                <TextField
                                    id="customWaitsFor"
                                    label={t('screens:addToRingGroup.waitsFor')}
                                    onChange={handleChange}
                                    value={values.customWaitsFor?.toString()}
                                    type="number"
                                    iconPosition="end"
                                    className={classNames(
                                        classes.numberInput,
                                        classes.inputMargin,
                                    )}
                                    dataQa="custom-wait-for-input"
                                    disabled={values.dispatchType !== 1 || readOnly}
                                    readOnly={values.dispatchType !== 1 || readOnly}
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <span className={classes.minText}>
                                            {t('screens:extensions.sec')}
                                        </span>
                                        ),
                                    }}
                                    helperText={errors.customWaitsFor}
                                />

                                <TextField
                                    id="customThenRingsFor"
                                    label={t('screens:addToRingGroup.thenRingFor')}
                                    onChange={handleChange}
                                    value={values.customThenRingsFor?.toString()}
                                    type="number"
                                    className={classNames(
                                        classes.numberInput,
                                        classes.inputMargin,
                                    )}
                                    dataQa="custom-then-rings-for-input"
                                    disabled={values.dispatchType !== 1 || readOnly}
                                    readOnly={values.dispatchType !== 1 || readOnly}
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <span className={classes.minText}>
                                            {t('screens:extensions.sec')}
                                        </span>
                                        ),
                                    }}
                                    helperText={errors.customThenRingsFor}
                                />
                            </Box>
                        </Box>
                    </CustomizedTooltip>
                )}
            </form>
        </DialogContainer>
    );
};

export default GroupMemberDialog;

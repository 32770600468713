import { FetchMenuStatus } from '../reducers/autoAttendants/reducer';
import { MenuActionFormType } from '../../components/Forms/AutoAttendantsMenus/ActionsForm.utils';
import { ExtensionsListItem } from '../reducers/extensions/extensions/reducer';

export interface AutoAttendantMenu {
    msg_timeout_set: number;
    i_menu: number;
    msg_menu_type: 'standard' | 'custom';
    first_digit_timeout: number | null;
    msg_intro_set: number;
    direct_dial_enabled: 'N' | 'Y';
    next_digit_timeout: number;
    msg_timeout_type: 'standard' | 'custom';
    msg_intro_type: 'standard' | 'custom';
    period: string;
    msg_disabled_type: 'standard' | 'custom';
    name: string;
    msg_disabled_set: number;
    replay_menu_times: number;
    period_desc: string;
    msg_menu_set: number;
    menuTransitions?: MenuActionFormType[];
    deleteEnabled?: boolean;
}

export interface AutoAttendantMenuTransition {
    target_i_menu: number;
    destination: string;
    announce_ext_numbers: 'N' | 'Y';
    event: UserInput;
    play_prompt: 'N' | 'Y';
    prompt_set: number;
    i_menu_transition: number;
    target_i_queue: number;
    action: MenuAction;
    max_size?: number;
}

export interface AutoAttendantListItem extends ExtensionsListItem {
    extension_name?: string;
    extension_id?: string;
    menu?: {
        items: MenuItem[];
        fetchMenu: FetchMenuStatus;
    };
    id_pin: string;
    i_product?: number;
    delete_enable?: boolean;
}

export interface MenuItem {
    i_menu: number;
    name: string;
    period: string;
    period_desc: string;
}

export enum MenuItemName {
    Root = 'ROOT',
}

export enum MenuAction {
    DoNothing = 'Disabled',
    DialByNameDirectory = 'Directory',
    Transfer = 'Transfer',
    TransferToE164Number = 'TransferE164',
    TransferToFaxMailbox = 'Voicemail',
    PromptForExtension = 'Extension',
    DialExtensionDirectly = 'DirectDial',
    DisconnectCall = 'DisconnectCall',
    Menu = 'Menu',
    Queue = 'Queue',
    DISA = 'DISA',
}

export enum UserInput {
    Digit1 = '1',
    Digit2 = '2',
    Digit3 = '3',
    Digit4 = '4',
    Digit5 = '5',
    Digit6 = '6',
    Digit7 = '7',
    Digit8 = '8',
    Digit9 = '9',
    Star = '*',
    Digit0 = '0',
    Hash = '#',
    Fax = 'f',
    NoInput = 'Timeout',
    NotActive = 'Not Active',
}

export const userInputsWeights = {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '*': 10,
    '0': 11,
    '#': 12,
    f: 13,
    'Voicemail': 14,
    Timeout: 15,
    'Not Active': 0,
};

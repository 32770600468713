import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import { extensionPlanFormDefaultValues } from '../components/Forms/Extensions/PlanForm.utils';

export const useSipTrunkPlanFormData = () => {
    const extension = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );

    const { products } = useSelector((state: ReduxState) => state.extensions);

    const initFormData = useMemo(() => {
        if (!extension) {
            return extensionPlanFormDefaultValues;
        }
        return {
            selectedMainProduct:
                products?.mainProducts.find(
                    (v) =>
                        v.product_name === extension.account_info?.product_name,
                )?.i_product || -1,
            selectedAddonsProducts:
                extension.account_info?.assigned_addons?.map(
                    (v) => v.i_product,
                ) || [],
        };
    }, [extension, products]);

    return {
        initFormData: initFormData,
        data: {
            i_account: extension?.i_account,
        },
    };
};

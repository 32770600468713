import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        width: '100%',
        position: 'relative',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        background: Colors.SmokeBackground,
    },

    extensionHeader: {
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        background: Colors.White,
    },

    extensionTop: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSwitch-root': {
            marginTop: -5,
            marginBottom: -8,
        },
        '& .did-number-wrapper': {
            marginTop: -5,
        },
    },

    extensionStatus: {
        display: 'flex',
        fontSize: 22,
        weight: 400,

        '& > div': {
            marginRight: 40,
            paddingRight: '1.5rem',
            '& > div': {
                fontWeight: 500,
            },
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },

    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '24px 90px 24px 90px',
        borderTop: `1px rgba(0, 0, 0, 0.12) solid`,
        backgroundColor: Colors.White,
        margin: 0,
    },

    deleteDialogButtons: {
        '& button': {
            minWidth: 90,
        },

        '& .MuiDialogContent-root': {
            paddingTop: 26,
            paddingBottom: 26,
            minWidth: 'unset !important',

            '& p': {
                marginBottom: 0,
            },
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        '& .header-name': {
            fontSize: 32,
            fontWeight: 500,
            color: Colors.Text,
            marginRight: 0,
        },
        '& .header-subtitle': {
            fontWeight: 300,
            fontSize: 32,
            color: Colors.Text,
            opacity: 1,
        },
    },
    status: {
        fontSize: 16,
        fontWeight: 500,
    },
}));

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        width: '100%',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        backgroundColor: Colors.SmokeBackground,
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: Colors.White,
        alignItems: 'center',
        '& h3': {
            fontSize: 22,
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            color: Colors.Gray5,
            margin: 0,
        },
    },
    statusWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '& > :last-child': {
            color: Colors.Text,
        },
    },
    extensionHeader: {
        display: 'flex',
        alignItems: 'center',
        '& label': {
            marginRight: 18,
            fontWeight: 300,
            fontSize: 32,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },
    subTitle: {
        color: Colors.Gray5,
        fontSize: 22,
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        padding: '25px 90px 25px 90px',
        borderTop: `1px rgba(0, 0, 0, 0.12) solid`,
        backgroundColor: Colors.White,
        margin: '0 !important',
    },

    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    status: {
        fontWeight: 500,
    },
    dialog: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
        },
    },
}));

export type EditPortalUserDetailsProps = {
    id: number;
};

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../../services/i18n';

export type ExtensionFormType = {
    extensionNumber: string;
    extensionName: string;
    didNumber: string[];
    emailAddress: string;
    timezone: string;
    office: string;
    country: string;
    address: string;
    city: string;
    postalCode: string;
    state: string;
};
const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const requiredFieldError = i18n.t('errors:common.emptyInput');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');

export const extensionFormValidationSchema = Yup.object().shape({
    extensionNumber: Yup.string()
        .max(5, numberMaxLengthError)
        .matches(/^[0-9]*$/, numberDigitsError)
        .required(requiredFieldError),
    extensionName: Yup.string().required(requiredFieldError),
    didNumber: Yup.array().notRequired(),
    emailAddress: Yup.string().email().notRequired(),
    timezone: Yup.string().required(),
    office: Yup.string().notRequired(),
    country: Yup.string().nullable().notRequired(),
    address: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    postalCode: Yup.string()
        .min(2, i18n.t('errors:extensions.postalCode'))
        .notRequired(),
    state: Yup.string().nullable().notRequired(),
});
export const extensionFormDefaultValues: ExtensionFormType = {
    extensionNumber: '',
    extensionName: '',
    didNumber: [],
    emailAddress: '',
    timezone: '',
    office: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
};
export const useStyles = makeStyles(() => ({
    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
        marginTop: 10,
        marginBottom: 32,
    },

    itemsContainer: {
        padding: '0px 16px 22px 16px',

        display: 'flex',
        '& > :first-child': {
            marginRight: 32,
        },
    },

    section: {
        borderBottom: `1px solid ${Colors.Border}`,
        marginBottom: 8,
    },

    singleItem: {
        display: 'flex',
        width: 472,
        '& > :first-child': {
            marginRight: 'unset',
            minWidth: '100%',
        },
    },

    columnItemsContainer: {
        flexDirection: 'column',
    },

    inputs: {
        display: 'flex',
        padding: '22px 0px',
        width: 944,
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 10,
        fontSize: 16,
        lineHeight: '24px',
        color: Colors.Text,
    },
    boldHeader: {
        fontWeight: 700,
    },
    musicRowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 11,

        '& .MuiButton-label': {
            color: Colors.White,
        },

        '& .MuiFormControl-root': {
            flex: 1,
            marginRight: 14,
        },
    },
    sectionSpace: {
        marginTop: 48,
    },

    subSectionSpace: {
        marginTop: 18,
    },
    iconColors: {
        '& .MuiSvgIcon-root': {
            '& path': {
                fill: `${Colors.PlaceHolder} !important`,
                fillOpacity: 1,
            },
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },
    insideItemsContainer: {
        display: 'flex',
        '& > :last-child': {
            marginLeft: 32,
        },
    },
}));

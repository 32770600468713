import { APIErrorInterface } from '../../../store/types';

export const getErrorsMsg = function (
    formError?: string,
    apiErrors?: APIErrorInterface | null,
): string {
    let msg = '';
    if (formError) {
        msg = formError;
    } else if (apiErrors) {
        msg = 'emptyInput';
    }
    return msg;
};

import { makeStyles } from '@material-ui/core';
import i18n from 'i18next';
import { CallerID, RingStrategy } from '../../store/types/RingGroup';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            flex: 1,
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            padding: '54px 84px 36px 84px',
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    sectionMargin: {
        marginTop: 16,
    },
    visibilityIcon: {
        marginRight: 6,
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            minWidth: 600,
            width: 1080,
            padding: '28px 60px',
        },
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    option: {
        height: '48px !important',
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        marginTop: 24,
    },
    callRecording: {
        marginTop: 42,
        height: 20,
        marginLeft: -10,
        '& div': {
            marginBottom: 0,
        },
    },
    headerBox: {
        display: 'flex',
        marginTop: 0,
        padding: 0 + '!important',
        fontWeight: 500,
    },
    rowBoxHeader: {
        marginRight: 12,
        fontSize: 16,
    },
    textField: {
        maxWidth: 440,
        height: 56,
        margin: '16px 38px 16px 24px',
        '& span': {
            marginLeft: 8,
            marginBottom: 8,
        },
    },
    ipElementWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    addNewIP: {
        width: 99,
        whiteSpace: 'nowrap',
    },
}));

export type CreateNewRingGroupFormData = {
    name: string;
    number: string;
    callerId: CallerID;
    ringStrategy: RingStrategy;
    callRecording: boolean;
    callPickupAllowed: boolean;
};

const emptyInputText = i18n.t<string>('errors:common.emptyInput');

export const ringGroupFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(emptyInputText)
        .max(32, i18n.t<string>('errors:ringGroups.numberMaxLength32')),
    number: Yup.string()
        .matches(/[0-9]+/, i18n.t<string>('errors:ringGroups.numberOnlyDigits'))
        .max(5, i18n.t<string>('errors:ringGroups.numberMaxLength5'))
        .required(emptyInputText),
    callerId: Yup.string().required(),
    ringStrategy: Yup.string().required(),
});

import { CallBarringRule } from '../../../../store/types/CallBarring';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../styles/Colors';
import * as Yup from 'yup';

export type ExtensionCallBarringFormType = {
    callBarringStatus: boolean;
    callBarringItems: CallBarringRule[];
    callBarringLocked: boolean;
};
export const extensionCallBarringFormValidationSchema = Yup.object().shape({
    callBarringStatus: Yup.boolean().required(),
    callBarringItems: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required(),
                status: Yup.boolean().notRequired(),
            }),
        )
        .notRequired(),
});
export const extensionCallBarringFormDefaultValues: ExtensionCallBarringFormType = {
    callBarringStatus: false,
    callBarringItems: [],
    callBarringLocked: false,
};
export type CallBarringFormProps = {
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    customClass?: { container?: string };
};
export const useStyles = makeStyles(() => ({
    itemsContainer: {
        maxWidth: 550,
        display: 'flex',
        flexDirection: 'column',
        '& > :first-child': {
            marginRight: 90,
        },
        padding: '0px 16px 0px 16px',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
    },
    rowBoxHeader: {
        marginRight: 20,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Gray7,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        marginLeft: 20,
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
    },
    itemsElementsContainer: {
        padding: '0 15px',
        paddingBottom: 30,
    },
    noData: {
        color: Colors.LightGraySecondary2,
        display: 'flex',
        paddingLeft: 40,
        fontSize: 14,
        paddingBottom: 30,
    },
    switchContainer: {
        marginBottom: 0,
    },
}));

import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import CustomButton from '../../Button/Button';
import { MailMePayload } from '../../../store/actions/auth/payloads';
import { useStyles, validate } from './RecoveryPasswordEmailForm.utils';
import history from './../../../history';
import { Routes } from '../../../routes/routes';
import classNames from 'classnames';

type LoginFormProps = {
    onEmailMeAction: (payload: MailMePayload) => void;
    onClickLoginAction: (login?: string) => void;
    validateOnChange: boolean;
    passedLogin?: string;
};

const RecoveryPasswordEmailForm: React.FC<LoginFormProps> = ({
    onEmailMeAction,
    onClickLoginAction,
    validateOnChange = false,
    passedLogin = '',
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [formDisabled, setFormDisabled] = useState(false);

    const formik = useFormik({
        initialValues: {
            login: passedLogin,
            email: '',
            global: '',
        },
        validate,
        validateOnChange: validateOnChange,
        enableReinitialize: true,
        onSubmit: (values) => {
            const payload: MailMePayload = {
                login: values.login,
                email: values.email,
                custom_url: `${window.location.protocol}//${window.location.host}/reset-password`,
                callback: () => history.push(Routes.SignIn),
            };

            onEmailMeAction(payload);
        },
    });

    const { values } = formik;

    useEffect(() => {
        const { login, email } = values;
        const allSet = !!(login.length && email.length);
        setFormDisabled(!allSet);
    }, [values.login, values.email]);

    const handleBackLoginClick = () => {
        onClickLoginAction(values.login);
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            data-testid={'recovery-password-form'}
        >
            <div className={classes.inputs}>
                <Grid item>
                    <TextField
                        id="login"
                        dataQa="login-user-input"
                        label={t('screens:signIn.loginLabel')}
                        onChange={formik.handleChange}
                        value={formik.values.login.trim()}
                        helperText={
                            formik.errors.login
                                ? t(`errors:loginForm.${formik.errors.login}`)
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="email"
                        dataQa="email-password-input"
                        label={t('screens:signIn.emailLabels')}
                        onChange={formik.handleChange}
                        value={formik.values.email.trim()}
                        helperText={
                            formik.errors.email
                                ? t(`errors:loginForm.${formik.errors.email}`)
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                    />
                </Grid>
            </div>
            <div className={classes.action}>
                <CustomButton
                    dataQa="recovery-submit-button"
                    dataTestId="recovery-submit-button"
                    primary
                    type="submit"
                    disabled={formDisabled}
                    className={classNames(
                        classes.recoveryButton,
                        formDisabled ? classes.disabled : '',
                    )}
                >
                    {t('screens:signIn.resetButton')}
                </CustomButton>
                <CustomButton
                    dataQa="login-screen-button"
                    onClick={handleBackLoginClick}
                    type="button"
                    className={classes.backButton}
                >
                    {t('screens:signIn.backToLogin')}
                </CustomButton>
            </div>
        </form>
    );
};

export default RecoveryPasswordEmailForm;

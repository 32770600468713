import CustomizedCheckbox, { CheckboxProps } from './Checkbox';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import React from 'react';

interface CheckboxWithIconInterface extends CheckboxProps {
    tooltipText?: string;
    containerStyle: string;
}

const CheckboxWithIcon: React.VFC<CheckboxWithIconInterface> = (props) => {
    return (
        <div
            className={props.containerStyle}
            data-testid={'checkbox-with-icon-compnent'}
        >
            <CustomizedCheckbox {...props} />
            <IconWithTooltip
                dataQa="require-pin-tooltip"
                tooltipText={props.tooltipText || ''}
                type="small"
            />
        </div>
    );
};

export default CheckboxWithIcon;

import { AxiosResponse } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PortalView } from '../../../services/endpoints';
import JSONFormData from '../../../utils/JSONFormData';
import { mapPermissionsToObject } from '../../../utils/permissions/mapPermissionsToObject';
import * as actions from '../../actions';
import { api } from '../../services/axios';
import { ReduxState } from '../../types';
import {
    PermissionType,
    PortalComponentInfo,
    PortalViewInfo,
} from '../../types/Permission';

export function* getPortalPermissions() {
    try {
        if(localStorage.getItem('default_portal_view_configuration')) {
            const permissionId = +(localStorage.getItem('default_portal_view_configuration') || '0');
            yield getPortalPermissionByConfigurationId(permissionId);
            return;
        }

        const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({ portal_url: `%${window.location.host}%` });

        const portalsListResponse: AxiosResponse<{
            portal_view_configuration_list: PortalViewInfo[];
        }> = yield api.post(PortalView.GetPortalViewConfigurationList, body);

        if (portalsListResponse.data.portal_view_configuration_list[0]) {
            const permissionId = portalsListResponse.data.portal_view_configuration_list[0]
            .i_portal_view_configuration;
            yield getPortalPermissionByConfigurationId(permissionId);
        } else {
            yield put(
                actions.getPortalPermissions.success({
                    name: '',
                    permission: PermissionType.Visible,
                    components: {},
                }),
            );
        }
    } catch (err) {
        yield put(actions.getPortalPermissions.failure());
    }
}

export function* getPortalPermissionByConfigurationId(id: number) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_portal_view_configuration: id
    });

    const portalPermissionsResponse: AxiosResponse<{
        portal_view_configuration_info: {
            component_info: PortalComponentInfo;
        };
    }> = yield api.post(
        PortalView.GetPortalViewConfigurationInfo,
        body,
    );

    yield put(
        actions.getPortalPermissions.success(
            mapPermissionsToObject(
                portalPermissionsResponse.data
                    .portal_view_configuration_info.component_info,
            ),
        ),
    );
}

export const permissionsSaga = [
    takeLatest(actions.getPortalPermissions.request, getPortalPermissions),
];

import React from 'react';
import classNames from 'classnames';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import { Grid, makeStyles } from '@material-ui/core';
import CustomizedTooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        flex: 1,
        overflow: 'hidden',
    },
    header: { flex: 'unset' },
    description: {
        fontSize: 13,
        opacity: 0.6,
        flex: 'unset',
        overflow: 'hidden',
    },
    textContainer: {
        flex: 'unset',
    },
}));

interface TwoLineCellProps {
    className?: string;
    headerClassName?: string;
    headerText: string;
    descriptionText?: string;
    descriptionTextTooltip?: string;
}

const TwoLineCell: React.VFC<TwoLineCellProps> = ({
    className,
    headerText,
    headerClassName,
    descriptionText,
    descriptionTextTooltip,
}) => {
    const classes = useStyles();

    return (
        <Grid className={classNames(classes.container, className)}>
            <div className={classes.textContainer}>
                <OverflowTooltip
                    copy={false}
                    text={headerText}
                    tooltip={headerText}
                    dataQa="header-text"
                    classes={{
                        text: classNames(classes.header, headerClassName),
                    }}
                />
            </div>

            {descriptionText && !descriptionTextTooltip && (
                <div className={classes.textContainer}>
                    <OverflowTooltip
                        copy={false}
                        text={descriptionText}
                        tooltip={descriptionText}
                        dataQa="description-text"
                        classes={{ text: classes.description }}
                    />
                </div>
            )}

            {descriptionTextTooltip && descriptionText && (
                <CustomizedTooltip title={descriptionTextTooltip} copy={false}>
                    <div className={classes.description}>{descriptionText}</div>
                </CustomizedTooltip>
            )}
        </Grid>
    );
};

export default TwoLineCell;

import { makeStyles } from '@material-ui/core';
import { PaymentMethodInfo } from '../../../../store/types/Payment';
import { Colors } from '../../../../styles/Colors';
import { PaymentMethodFormType } from '../PaymentMethodForm/PaymentMethodForm.utils';

export interface AddPaymentMethodDialogProps {
    onSave?: (form: PaymentMethodFormType) => void;
    isOpen: boolean;
    toggleVisibility?: () => void;
    paymentMethodToEdit?: PaymentMethodInfo;
}

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 686,
            backgroundColor: Colors.SmokeBackground,
            padding: '42px 72px 27px 72px',
        },
    },
}));

import {makeStyles} from '@material-ui/core';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        width: 'fit-content',
        height: 'fit-content',
        overflow: 'hidden',
        display: 'inline-flex'
    },
    iconContainer: {
        width: 'fit-content',
        height: '100%',
        verticalAlign: 'middle'
    },
    iconSubContainer: {
        marginTop: 6
    },
    labelsContainer: {
        marginLeft: 12,
        width: 'fit-content',
        height: 'fit-content',
    },
    totalsLabel: {
        width: 'fit-content',
        color: Colors.Gray5,
        fontWeight: 700,
        fontSize: 12,
        whiteSpace: 'nowrap'
    },
    availableLabel: {
        width: 'fit-content',
        fontWeight: 700,
        fontSize: 12,
        whiteSpace: 'nowrap'
    },
    badgeContainerGlobal: {
        background: Colors.Text,
        padding: 0,
        borderRadius: 4
    },
    badgeContainer: {
        padding: '18px 24px',
        background: Colors.Text,
        whiteSpace: 'nowrap',
        borderRadius: 4
    },
    badgeTitle: {
        fontWeight: 700,
        fontSize: 14,
        color: '#D8D8D8'
    },
    headerSeparator: {
        marginTop: 7,
        width: '100%',
        borderTop: '1px ' + Colors.WhiteWithOpacity + ' solid',
        height: '1px !important'
    },
    badgeRow: {
        width: '100%',
        height: 24,
        justifyContent: 'space-between',
        display: 'flex'
    },
    leftColumn: {
        fontWeight: 400,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'left',
        width: 'fit-content'
    },
    rightColumn: {
        fontWeight: 400,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'right',
        width: 'fit-content'
    },
    firstTableRow: {
        paddingTop: 7,
        marginTop: 7
    }
}));
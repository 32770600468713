import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import CustomizedButton from '../Button/Button';
import { Delete } from '@material-ui/icons';
import classNames from 'classnames';

interface CallRemoveBottomBoxProps {
    numberOfItems: number;
    onDelete?: () => void;
    customClasses?: { buttonsContainer: string };
}

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
        padding: '19px 40px 19px 40px',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 8,
        boxShadow: `0px 4px 4px ${Colors.Gray8}`,
    },
    deleteButton: {
        '& .MuiButton-label': {
            color: Colors.Error + '!important',
        },

        '& svg': {
            '& path': {
                fill: Colors.Error,
                fillOpacity: 1,
            },
        },
    },

    buttonsContainer: {
        display: 'flex',
        padding: '10px 0',
        position: 'absolute',
        bottom: 0,
        width: 'calc(100% - 194px)',
        maxWidth: 1042,
        margin: 0,
    },
}));

const CallRemoveBottomBox: React.VFC<CallRemoveBottomBoxProps> = ({
    numberOfItems,
    onDelete,
    customClasses,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!numberOfItems) {
        return null;
    }

    return (
        <div
            className={classNames(
                classes.buttonsContainer,
                customClasses?.buttonsContainer,
            )}
        >
            <Grid className={classes.container}>
                {`${numberOfItems} ${
                    numberOfItems === 1
                        ? t('common:itemSelected')
                        : t('common:itemsSelected')
                }`}
                <CustomizedButton
                    dataQa="delete-all-button"
                    onClick={onDelete}
                    className={classes.deleteButton}
                    icon={<Delete color="error" htmlColor={Colors.Error} />}
                >
                    {t('common:delete')}
                </CustomizedButton>
            </Grid>
        </div>
    );
};

export default CallRemoveBottomBox;

import React from 'react';
import { getInitials } from './formaters';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import { makeStyles } from '@material-ui/core/styles';

export type UserData = {
    name: string;
    emailMd5?: string;
    size?: number;
    classes?: string;
};

const useStyles = makeStyles({
    parent: {
        backgroundColor: Colors.AvatarBackground,
        position: 'relative',
        width: 40,
        height: 40,
        borderRadius: '50%',
        border: '1px solid ' + Colors.WhiteWithTinyOpacity,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.15)',
    },
    swatch: {
        fontSize: 26,
        position: 'absolute',
        fontFamily: 'sans-serif',
        userSelect: 'none',
    },
    img: {
        width: 40,
        height: 40,
        top: 0,
        left: 0,
        borderRadius: '50%',
    },
    placeholder: {
        width: 40,
        height: 40,
        boxShadow: '5px 5px 10px transparent',
        border: '1px solid transparent',
    },
});

export const Avatar: React.VFC<UserData> = ({
    emailMd5,
    name,
    size = 40,
    classes,
}) => {
    const url =
        emailMd5 !== undefined
            ? `https://www.gravatar.com/avatar/${emailMd5}?s=${Math.max(
                  size,
                  250,
              ).toString()}&r=g&d=blank`
            : `https://www.gravatar.com/avatar/blank?s=${Math.max(
                size,
                250,
            ).toString()}&r=g&d=blank`;

    const initials = getInitials(name, 1);
    const c = useStyles();

    if (!url) {
        return <div className={classNames(c.placeholder, classes)} />;
    }

    setTimeout(() => {
        const element = document.getElementById('avatar_img');
        if(element) {
            element.setAttribute('alt', name + "’s avatar");
        }
    }, 1000);

    return (
        <div className={classNames([c.parent, classes])}>
            <>
                <div
                    aria-hidden="true"
                    className={c.swatch}
                    data-testid={'avatar-char'}
                >
                    {initials}
                </div>
                
                <img className={c.img} src={url} id="avatar_img" />
            </>
        </div>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    outsideContainer: {
        position: 'absolute',
        opacity: 0,
        pointerEvents: 'none',
    },
    mainContainer: {
        height: 580,
        width: 800,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    receiptDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 80,
        width: 370,
    },
    logoContainer: {
        marginTop: 20,
        marginBottom: 45,
        height: 60,

        '& svg': {
            height: 60,
        },

        '& img': {
            height: 60,
        },
    },
}));

export type PaymentReceiptProps = {
    date: string;
    method: string;
    transactionNumber: string;
    total?: string;
    paymentTotal?: string;
    tax?: string;
};

import CustomizedTooltip from '../Tooltip/Tooltip';
import { Badge } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { CPConditionTimeWindowInfo } from '../../store/types/CallScreening';
import { parsePeriodString, timeWindowFromTimeWindowIntervals } from '../../utils/extensions/CallScreeningTimeWindowToPeriod';
import { IntervalStatus } from '../IntervalSelect/IntervalSelect.utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    wraper: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        maxWidth: '95%',
    },

    badge: {
        '& > span.MuiBadge-badge': {
            backgroundColor: Colors.Gray4,
            color: Colors.Gray5,
        },
    },

    badgeTooltip: {
        minWidth: 15,
    },
}));

type TimeFilterCellProps = {
    showOnlyPolicy?: boolean;
    period: string;
    allowShowAny: boolean;
};

const TimeFilterCell: React.VFC<TimeFilterCellProps> = ({
    period,
    showOnlyPolicy,
    allowShowAny,
}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const intervals = parsePeriodString(period ?? '');
    const timeFilters: CPConditionTimeWindowInfo[] = [];

    for(const interval of intervals) {
        const val =  timeWindowFromTimeWindowIntervals({
            activity: IntervalStatus.OnlyFollowingTimeInterval,
            intervals: [interval]
        });
        timeFilters.push(val);
    }

    const badgeTooltipContent =
    timeFilters.length > 1
            ? timeFilters.map((item, key) => (
                <span key={key}>{item.description}</span>
            ))
            : '';

    const text = timeFilters.length >= 1
        ? timeFilters[0].description
        : (allowShowAny ? t('common:always', 'Any') : '') 

    return !showOnlyPolicy ? (
        timeFilters.length > 1 ? (
            <div className={classes.wraper}>
                <span>{text}</span>
                <CustomizedTooltip
                    title={badgeTooltipContent}
                    dataQa={'tooltip-time-filters-list'}
                    interactive={true}
                    copy={false}
                    className={classes?.badgeTooltip}
                    above
                >
                    <Badge
                        badgeContent={
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {timeFilters.length}
                            </span>
                        }
                        className={classes?.badge}
                        data-qa={'time-filters-counter'}
                    />
                </CustomizedTooltip>
            </div>
        ) : (
            <>{text}</>
        )
    ) : null;
};

export default TimeFilterCell;

import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Handle, Position } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IncomingCall } from '../../../../../assets/incoming_call.svg';
import { Colors } from '../../../../../styles/Colors';

const useStyles = makeStyles(() => ({
    container: {
        height: 48,
        width: 175,
        borderRadius: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White,
        border: `2px solid ${Colors.Gray8}`,
    },
    titleText: {
        fontSize: 14,
        color: Colors.Gray5,
        marginLeft: 15,
        fontWeight: 500,
    },
    sourceHandler: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        right: 0,
    },
}));

const StartNode = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <IncomingCall />
            <span className={classes.titleText}>
                {t('screens:autoAttendants.incomingCall')}
            </span>

            <Handle
                className={classes.sourceHandler}
                type="source"
                position={Position.Right}
                id="b"
            />
        </div>
    );
};

export default memo(StartNode);

import React, { useEffect, useMemo, useState } from 'react';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { Disable2FADialogProps, useStyles } from './Disable2FADialog.utils';
import CustomizedTextField from '../TextField/TextField';
import { MfaStatus } from '../../store/actions/auth/payloads';
import classNames from 'classnames';

const Disable2FADialog: React.VFC<Disable2FADialogProps> = ({
    onClose,
    isOpen,
    i_object,
    object,
    mfaError
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [mainValue, setMainValue] = useState<string>('');
    const [fieldError, setFieldError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(mfaError) {
            const leftAttempts = (mfaError?.mfa_attempts || 0);
            if(leftAttempts > 0) {
                const faultString = t(leftAttempts === 1
                    ? 'screens:twoFa.incorrectCodeAttemptCount'
                    : 'screens:twoFa.incorrectCodeAttemptsCount', {
                    value: leftAttempts?.toString()
                });
                setFieldError(faultString);
                setMainValue('');
            } else if(leftAttempts === 0) {
                setTimeout(() => {
                    dispatch(actions.removeAuthDataAndReload({reload: true}));
                }, 5000);
            }
        } else {
            setFieldError(undefined);
        }
    }, [mfaError]);

    const isMaxAttemptsReached = useMemo(() => {
        return !!mfaError && ((mfaError?.mfa_attempts || 0) === 0);
    }, [mfaError]);

    return (
        <DialogContainer
            isOpen={isOpen}
            header={t('screens:twoFa.disable2FADialogTitle')}
            headerClass={classes.header}
            dialogActionsButtons={[
                <DialogButton
                key="cancel"
                    label={t('common:cancel')}
                    onClick={() => {
                        dispatch(actions.cleanupMfaConfig());
                        onClose?.(false); 
                    }}
                />,
                <DialogButton
                    key="save"
                    label={t('screens:twoFa.disableButton')}
                    onClick={() => {
                        dispatch(actions.updateMfaUsed.request({
                            use_mfa: MfaStatus.Disabled,
                            i_object: i_object,
                            object: object,
                            one_time_password: mainValue,
                            onSuccess: () => {
                                setMainValue('');
                                onClose?.(true);
                            }
                        }));
                    }}
                    disabled={mainValue.length !== 6 || isMaxAttemptsReached}
                    className={classNames(classes.primaryButton, (mainValue.length !== 6) ? classes.disabledButton : null)}
                />,
            ]}
            className={classes.root}
            dataQa="dialog-disable-2fa"
            dataTestId="dialog-disable-2fa-id"
        >
            <Grid container className={classes.itemsContainer}>
                {isMaxAttemptsReached ? (
                    <div className={classes.maxAttemptsReached}>{t('screens:twoFa.maxAttemptsReached')}</div>)
                : (
                    <CustomizedTextField
                        autoFocus
                        id="one_time_password"
                        label={t('screens:twoFa.enterCode')}
                        value={mainValue}
                        required
                        helperText={
                            fieldError && fieldError.length > 0
                                ? fieldError
                                : ''
                        }
                        onChange={(e) => {
                            setMainValue(e.target.value);
                            if(e.target.value.length >= 6) {
                                setTimeout(() => {
                                    dispatch(actions.updateMfaUsed.request({
                                        use_mfa: MfaStatus.Disabled,
                                        i_object: i_object,
                                        object: object,
                                        one_time_password: e.target.value,
                                        onSuccess: () => {
                                            setMainValue('');
                                            onClose?.(true);
                                        }
                                    }));
                                }, 100);
                            }
                        }}
                        maxLength={6}
                        dataQa="one_time_password-field"
                        skipPermission
                    />)}
            </Grid>
        </DialogContainer>
    );
};
export default Disable2FADialog;

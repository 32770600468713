import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import TextField from '../TextField/TextField';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import classNames from 'classnames';
import { MenuActionFormType } from '../Forms/AutoAttendantsMenus/ActionsForm.utils';
import {
    AutoAttendantListItem,
    AutoAttendantMenu,
    MenuAction,
    UserInput,
} from '../../store/types/AutoAttendant';
import CustomizedCheckbox from '../Checkbox/Checkbox';
import FileSelect from '../FileSelect/FileSelect';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { CallQueue } from '../../store/types/CallQueue';
import i18n from '../../services/i18n';
import DialingPad from './utils/DialingPad';
import {
    MultiLevelSelectField,
    MultiLevelSelectItem,
} from '../MultiLevelSelectField/MultiLevelSelectField';
import { Delete } from '@material-ui/icons';
import CustomizedButton from '../Button/Button';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useParams } from 'react-router';
import { ServiceFeatureName } from '../../store/types/ServiceFeature';

const requiredError = i18n.t<string>('errors:common.emptyInput');
const transferDestinationValidation = i18n.t(
    'errors:extensions.transferDestination',
);
const number = i18n.t('errors:extensions.e164Number');
const MenuActionValidationSchema = Yup.object().shape({
    playBeforeActionStatus: Yup.boolean().required(),
    playBeforeActionFileName: Yup.string().notRequired(),
    menu: Yup.object().when('action', {
        is: (value: unknown) => value === MenuAction.Menu,
        then: Yup.object().required(requiredError),
        otherwise: Yup.object(),
    }),
    queue: Yup.object().when('action', {
        is: (value: unknown) => value === MenuAction.Queue,
        then: Yup.object().required(requiredError),
        otherwise: Yup.object(),
    }),
    transferCallerToPhoneNumberExtension: Yup.string()
        .when('action', {
            is: (value: unknown) =>
                value === MenuAction.Transfer ||
                value === MenuAction.TransferToE164Number,
            then: Yup.string().required(requiredError),
            otherwise: Yup.string(),
        })
        .when('action', {
            is: (value: unknown) => value === MenuAction.TransferToE164Number,
            then: Yup.string()
                .matches(/^[A-Da-d0-9*#]+$/, i18n.t<string>(number))
                .min(7, transferDestinationValidation)
                .required(requiredError),
            otherwise: Yup.string(),
        }),

    userInput: Yup.string().required(requiredError),
    action: Yup.string().required(requiredError),
});

const initialValuesMenuActionForm: MenuActionFormType = {
    timeout: '0',
    announceExtensionNumbers: false,
    playBeforeActionStatus: false,
    playBeforeActionFileName: '',
    playBeforeActionFile: null,
    transferCallerToPhoneNumberExtension: '',
    maxDigits: '4',
    action: MenuAction.DoNothing,
};

export interface MenuActionDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    menuActions: MenuActionFormType[];
    initialValue?: MenuActionFormType;
    onSave?: (form: MenuActionFormType) => void;
    isDialExtensionDirectlyBlocked?: boolean;
    deleteAction?: (action: MenuActionFormType) => void;
}

const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0 38px 18px 28px',
        display: 'flex',
        flexDirection: 'column',
    },

    sectionMargin: {
        marginTop: 16
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            width: 520,
            height: 450,
            padding: '26px 60px 40px 60px',
        },

        '& .MuiDialogActions-root': {
            justifyContent: 'space-between',
            paddingLeft: 50,
        },
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    option: {
        height: '48px !important',
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    didNumbers: {
        height: 'auto',
        minHeight: 60,

        '& .MuiInputBase-root': {
            paddingBottom: 5,
            minHeight: 60,
            height: 'auto',
            paddingTop: 25,
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'unset',
            bottom: 14,
        },
    },
    didNumbersIconContainer: {
        alignItems: 'flex-end',
        paddingBottom: 16,
    },
    fieldMargin: {
        marginBottom: 25,
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    playBeforeGrid: {
        margin: '15px 0',
    },
    numberPickerGrid: {
        marginTop: 15,
        '& .MuiFormControl-root': {
            flex: 'unset',
        },
    },
    numberInput: {
        width: 135,
        marginRight: 20,
        whiteSpace: 'nowrap'
    },
    selectMargin: {
        marginTop: 15,
        width: '100%'
    },
    removeButton: {
        color: Colors.Gray5,
        display: 'flex',
        alignItems: 'center',

        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
    },
    saveButtonDisabled: {
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}!important`,
        },
    },
    fileWrapper: {
        width: 424,
    },
    saveButton: {
        marginLeft: 16,
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const availableListForDialExtensionDirectly = [
    UserInput.Digit0,
    UserInput.Digit1,
    UserInput.Digit2,
    UserInput.Digit3,
    UserInput.Digit4,
    UserInput.Digit5,
    UserInput.Digit6,
    UserInput.Digit7,
    UserInput.Digit8,
    UserInput.Digit9,
];

const faxActions = [
    MenuAction.DoNothing,
    MenuAction.DisconnectCall,
    MenuAction.TransferToFaxMailbox,
];

const MenuActionDialog: React.VFC<MenuActionDialogProps> = ({
    isOpen,
    toggleVisibility,
    menuActions,
    initialValue,
    onSave,
    isDialExtensionDirectlyBlocked,
    deleteAction,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const destinationRef = useRef<HTMLInputElement>(null);
    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
    } = useFormik<MenuActionFormType>({
        initialValues: initialValue || initialValuesMenuActionForm,
        onSubmit: (form) => {
            resetForm();
            onSave?.(form);
        },
        validationSchema: MenuActionValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
    });

    const voicemailSettings = useSelector((state: ReduxState) => state.extensions?.voicemailSettings);
    const voiceMaileSettingsValue = voicemailSettings?.ext_email !== undefined ? voicemailSettings?.ext_email : '';

    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
            ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    const permission = usePermissionContext();

    const menuActionsList: MultiLevelSelectItem[] = useMemo(
        () =>
            Object.values(MenuAction).map((v) => ({
                label: unifiedMessagingVoicemailEnabled && v === MenuAction.TransferToFaxMailbox && values.userInput !== UserInput.Fax
                    ? t('enums:inactiveMenuAction.VoicemailEnabled')
                    : v === MenuAction.TransferToFaxMailbox
                        ? t('enums:inactiveMenuAction.Voicemail') + (" " + voiceMaileSettingsValue)
                        : t(`enums:inactiveMenuAction.${v}`),
                value: v,
                nestedItems: [],
                disabled: false,
            })),
        [unifiedMessagingVoicemailEnabled, voicemailSettings, values?.userInput],
    );

    const menuFaxAction: MultiLevelSelectItem[] = useMemo(
        () =>
            faxActions.map((v) => ({
                label: unifiedMessagingVoicemailEnabled && v === MenuAction.TransferToFaxMailbox && values.userInput !== UserInput.Fax
                    ? t('enums:inactiveMenuAction.VoicemailEnabled')
                    : v === MenuAction.TransferToFaxMailbox
                        ? t('enums:inactiveMenuAction.Voicemail') + (" " + voiceMaileSettingsValue)
                        : t(`enums:inactiveMenuAction.${v}`),
                value: v,
                nestedItems: [],
                disabled: false,
            })),
        [faxActions, unifiedMessagingVoicemailEnabled, voicemailSettings, values?.userInput],
    );

    const menus = useSelector<ReduxState, AutoAttendantMenu[]>(
        (state) => state.autoAttendants.autoAttendantMenus || [],
    );

    const callQueues = useSelector<ReduxState, CallQueue[]>(
        (state) => state.callQueues?.callQueues || [],
    );

    const DISAStatus = useSelector<ReduxState, boolean>(
        (state) => state.autoAttendants.incomingTab?.enableDisa === 'Y',
    );

    const saveDisabled = !values.action || !values.userInput || permission === PermissionType.ReadOnly;

    const autoAttendantList = useSelector<ReduxState, AutoAttendantListItem[] | undefined>(
        (state) => state.autoAttendants?.autoAttendantList?.items
    );
    
    // @ts-ignore
    const id = useParams()?.id || '';
    const autoattendantMenusFoundInState =  useMemo(() => {
        return autoAttendantList?.filter((e: AutoAttendantListItem) => e.extension_id == id);
    }, [autoAttendantList]);

    const onChangeUserInput = useCallback(
        (value: UserInput) => {
            setFieldValue('userInput', value);
            setFieldValue('transitionId', undefined);

            if (
                value === UserInput.Fax &&
                values.action &&
                !faxActions.includes(values.action)
            ) {
                setFieldValue('action', MenuAction.DoNothing);
            }
        },
        [values.action],
    );

    const selectedLabel = () => {
        if (values.action === MenuAction.Queue) {
            const queue = callQueues.find(
                (v) => v.i_c_queue === values.queue?.i_c_queue,
            );

            return `${t(`enums:inactiveMenuAction.${MenuAction.Queue}`)} / ${
                queue?.group_name
            }`;
        } else if (values.action === MenuAction.Menu) {
            const menu = menus.find((v) => v.i_menu === values.menu?.i_menu);
            return `${t(`enums:inactiveMenuAction.${MenuAction.Menu}`)} / ${
                menu?.name
            }`;
        } else if (values.action === MenuAction.TransferToFaxMailbox) {
            return unifiedMessagingVoicemailEnabled && values.userInput !== UserInput.Fax
                ? t('enums:inactiveMenuAction.VoicemailEnabled')
                : t('enums:inactiveMenuAction.Voicemail') + (" " + voiceMaileSettingsValue);
        } else {
            return t(`enums:inactiveMenuAction.${values.action}`);
        }
    };

    const items: MultiLevelSelectItem[] = useMemo(() => {
        const items = [...menuActionsList];

        const menuIndex = items.findIndex((v) => v.value === MenuAction.Menu);

        items[menuIndex].nestedItems = menus.map((v) => ({
            value: v.i_menu,
            label: v.name,
        }));

        items[menuIndex].disabled = !menus.length;

        const queueIndex = items.findIndex((v) => v.value === MenuAction.Queue);

        items[queueIndex].nestedItems = callQueues.map((v) => ({
            value: v.i_c_queue || 0,
            label: v.group_name || '',
        }));

        if (!callQueues.length) {
            items[queueIndex].disabled = true;
        }

        if (
            !!isDialExtensionDirectlyBlocked ||
            (values.userInput !== undefined &&
                !availableListForDialExtensionDirectly.includes(
                    values.userInput,
                ))
        ) {
            const dialExtensionIndex = items.findIndex(
                (v) => v.value === MenuAction.DialExtensionDirectly,
            );

            items[dialExtensionIndex].disabled = true;
        }

        if (!DISAStatus) {
            const disaIndex = items.findIndex(
                (v) => v.value === MenuAction.DISA,
            );
            items[disaIndex].disabled = true;
        }

        return items;
    }, [
        menuActionsList,
        DISAStatus,
        isDialExtensionDirectlyBlocked,
        values.userInput,
        availableListForDialExtensionDirectly,
        menus,
        callQueues,
        autoattendantMenusFoundInState,
    ]);

    const onChangeAction = useCallback(
        (parentValue: string | number, childValue?: string | number) => {

            if (parentValue === MenuAction.Queue) {
                const queue = callQueues.find(
                    (v) => v.i_c_queue === childValue,
                );
                setFieldValue('queue', queue);
            } else if (parentValue === MenuAction.Menu) {
                const menu = menus.find((v) => v.i_menu === childValue);
                setFieldValue('menu', menu);
            }
            setFieldValue('action', parentValue);
        },
        [setFieldValue, menus, callQueues],
    );

    useEffect(() => {
        if (destinationRef) {
            destinationRef?.current?.focus();
        }
    }, [values.action]);

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="sip-dialog"
            header={
                permission === PermissionType.ReadOnly ? 
                    t('screens:autoAttendants.menuActionDetails')
                : (initialValue
                    ? t('screens:autoAttendants.editActionMenu')
                    : t('screens:autoAttendants.addNewAction'))
            }
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogLeftSideActionsButtons={[
                initialValue ? (
                    <div key={'delete-left'}>
                        <CustomizedButton
                            dataQa="add-new-menu-button"
                            onClick={() =>
                                deleteAction && deleteAction(initialValue)
                            }
                            ternary
                        >
                            <Delete />
                            {t('screens:autoAttendants.deleteAction')}
                        </CustomizedButton>
                    </div>
                ) : null,
            ]}
            dialogActionsButtons={[
                <div key="right">
                    <DialogButton
                        label={t('common:cancel')}
                        onClick={() => {
                            toggleVisibility?.();
                            resetForm();
                        }}
                    />
                    <DialogButton
                        label={t('common:save')}
                        onClick={handleSubmit}
                        disabled={saveDisabled}
                        primary
                        className={classes.saveButton}
                    />
                </div>,
            ]}
        >
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="action-form"
            >
                <DialingPad
                    id="userInput"
                    value={values.userInput || null}
                    setFieldError={setFieldError}
                    onChange={onChangeUserInput}
                    menuActions={menuActions}
                    isDialExtensionDirectly={
                        values.action === MenuAction.DialExtensionDirectly
                    }
                />

                <Grid item className={classes.itemsContainer}>
                    {values.userInput === UserInput.NoInput && (
                        <CustomizedTooltip
                            title={t('common:noPermissionToResource')}
                            copy={false}
                            disableHoverListener={permission === PermissionType.Visible}
                        >
                            <Grid
                                className={classNames(
                                    classes.centeredGrid,
                                    classes.numberPickerGrid,
                                )}
                            >
                                <TextField
                                    id="timeout"
                                    label={t('screens:extensions.timeout')}
                                    onChange={handleChange}
                                    value={values.timeout}
                                    type="number"
                                    iconPosition="end"
                                    className={classes.numberInput}
                                    dataQa="timeout-input"
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                    }}
                                />

                                <IconWithTooltip
                                    dataQa="timeout-tooltip"
                                    tooltipText={t(
                                        'tooltips:autoAttendants.timeout',
                                    )}
                                />
                            </Grid>
                        </CustomizedTooltip>
                    )}

                    <CustomizedTooltip
                        title={t('common:noPermissionToResource')}
                        copy={false}
                        disableHoverListener={permission === PermissionType.Visible}
                    >
                        <div style={{width: '100%', height: 'fit-content'}}>
                            <MultiLevelSelectField
                                label={t('common:action')}
                                items={
                                    values.userInput === UserInput.Fax
                                        ? menuFaxAction
                                        : items
                                }
                                value={selectedLabel()}
                                onChange={onChangeAction}
                                readOnly={permission === PermissionType.ReadOnly}
                                required
                            />
                        </div>
                    </CustomizedTooltip>

                    {values.action === MenuAction.Transfer && (
                        <CustomizedTooltip
                            title={t('common:noPermissionToResource')}
                            copy={false}
                            disableHoverListener={permission === PermissionType.Visible}
                        >
                            <div style={{width: '100%', height: 'fit-content'}}>
                                <TextField
                                    label={t(
                                        'screens:autoAttendants.transferCallerToPhoneNumberExtension',
                                    )}
                                    id="transferCallerToPhoneNumberExtension"
                                    setFieldError={setFieldError}
                                    value={values.transferCallerToPhoneNumberExtension}
                                    onChange={handleChange}
                                    required
                                    className={classes.selectMargin}
                                    maxLength={32}
                                    inputRef={destinationRef}
                                    helperText={
                                        errors.transferCallerToPhoneNumberExtension
                                    }
                                />
                            </div>
                        </CustomizedTooltip>
                    )}

                    {values.action === MenuAction.TransferToE164Number && (
                        <CustomizedTooltip
                            title={t('common:noPermissionToResource')}
                            copy={false}
                            disableHoverListener={permission === PermissionType.Visible}
                        >
                            <div style={{width: '100%', height: 'fit-content'}}>
                                <TextField
                                    label={t(
                                        'screens:autoAttendants.transferCallerToPhoneNumberExtension',
                                    )}
                                    id="transferCallerToPhoneNumberExtension"
                                    setFieldError={setFieldError}
                                    value={values.transferCallerToPhoneNumberExtension}
                                    onChange={handleChange}
                                    required
                                    className={classes.selectMargin}
                                    inputProps={{
                                        pattern: '[0-9]*',
                                    }}
                                    maxLength={15}
                                    helperText={
                                        errors.transferCallerToPhoneNumberExtension
                                    }
                                    inputRef={destinationRef}
                                />
                            </div>
                        </CustomizedTooltip>
                    )}

                    {(values.action === MenuAction.PromptForExtension ||
                        values.action === MenuAction.DialExtensionDirectly) && (
                        <Grid
                            className={classNames(
                                classes.centeredGrid,
                                classes.numberPickerGrid,
                            )}
                        >
                            <TextField
                                id="maxDigits"
                                label={t('screens:autoAttendants.maxDigits')}
                                onChange={handleChange}
                                value={values.maxDigits}
                                type="number"
                                iconPosition="end"
                                className={classes.numberInput}
                                dataQa="max-digits-input"
                                inputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 32,
                                    },
                                    pattern: '[0-9]*',
                                }}
                            />

                            <IconWithTooltip
                                dataQa="max-digits-tooltip"
                                tooltipText={t(
                                    'tooltips:autoAttendants.maxDigits',
                                )}
                            />
                        </Grid>
                    )}

                    {values.action === MenuAction.DialByNameDirectory && (
                        <>
                            <Grid
                                className={classNames(
                                    classes.centeredGrid,
                                    classes.playBeforeGrid,
                                )}
                            >
                                <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={permission === PermissionType.Visible}
                                >
                                    <div style={{width: 'fit-content', height: 'fit-content'}}>
                                    <CustomizedCheckbox
                                        checked={values.playBeforeActionStatus}
                                        dataQa="play-before-action-status"
                                        onChange={(value) =>
                                            setFieldValue(
                                                'playBeforeActionStatus',
                                                value.target.checked,
                                            )
                                        }
                                        label={t(
                                            'screens:autoAttendants.directoryIntroPrompt',
                                        )}
                                    />
                                    </div>
                                </CustomizedTooltip>

                                <IconWithTooltip
                                    dataQa="play-before-action-tooltip"
                                    tooltipText={t(
                                        'tooltips:autoAttendants.playBeforeAction',
                                    )}
                                />
                            </Grid>

                            {values.playBeforeActionStatus && (
                                <div className={classes.fileWrapper}>
                                    <FileSelect
                                        nameValue={
                                            values.playBeforeActionFileName
                                        }
                                        onFileChange={(file) => {
                                            setFieldValue(
                                                'playBeforeActionFileName',
                                                file.name,
                                            );
                                            setFieldValue(
                                                'playBeforeActionFile',
                                                file,
                                            );
                                        }}
                                        file={values.playBeforeActionFile}
                                    />
                                </div>
                            )}

                            <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={permission === PermissionType.Visible}
                                >
                                <div style={{width: 'fit-content', height: 'fit-content'}}>
                                    <CustomizedCheckbox
                                        checked={values.announceExtensionNumbers}
                                        dataQa="play-before-action-status"
                                        onChange={(value) =>
                                            setFieldValue(
                                                'announceExtensionNumbers',
                                                value.target.checked,
                                            )
                                        }
                                        label={t(
                                            'screens:autoAttendants.announceExtensionNumbers',
                                        )}
                                    />
                                </div>
                            </CustomizedTooltip>
                        </>
                    )}
                    {values.action !== MenuAction.DialByNameDirectory && (
                        <>
                            <Grid
                                className={classNames(
                                    classes.centeredGrid,
                                    classes.playBeforeGrid,
                                )}
                            >
                                <CustomizedTooltip
                                    title={t('common:noPermissionToResource')}
                                    copy={false}
                                    disableHoverListener={permission === PermissionType.Visible}
                                >
                                    <div style={{width: 'fit-content', height: 'fit-content'}}>
                                        <CustomizedCheckbox
                                            checked={values.playBeforeActionStatus}
                                            dataQa="play-before-action-status"
                                            onChange={(value) =>
                                                setFieldValue(
                                                    'playBeforeActionStatus',
                                                    value.target.checked,
                                                )
                                            }
                                            label={t(
                                                'screens:autoAttendants.playBeforeAction',
                                            )}
                                        />
                                    </div>
                                </CustomizedTooltip>

                                <IconWithTooltip
                                    dataQa="play-before-action-tooltip"
                                    tooltipText={t(
                                        'tooltips:autoAttendants.playBeforeAction',
                                    )}
                                />
                            </Grid>

                            {values.playBeforeActionStatus && (
                                <div className={classes.fileWrapper}>
                                    <FileSelect
                                        nameValue={
                                            values.playBeforeActionFileName
                                        }
                                        onFileChange={(file) => {
                                            setFieldValue(
                                                'playBeforeActionFileName',
                                                file.name,
                                            );
                                            setFieldValue(
                                                'playBeforeActionFile',
                                                file,
                                            );
                                        }}
                                        file={values.playBeforeActionFile}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Grid>
            </form>
        </DialogContainer>
    );
};

export default MenuActionDialog;

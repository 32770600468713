import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import React, {useEffect, useState} from 'react';
import {ApiChangePasswordPayload} from '../../store/services/auth-api';
import * as actions from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {useTranslation} from 'react-i18next';
import usePageTitle from '../../hooks/usePageTitle';
import ChangePasswordExpirationForm from "../../components/Forms/LoginForm/ChangePasswordExpirationForm";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import history from "../../history";
import {Routes} from "../../routes/routes";
import {formatMsg} from "../../utils/formatMsg";
import ExtendedMessage from "./ExtendenMessage";

const useStyles = makeStyles(() => ({
    description: {
        fontSize: 16,
        color: Colors.Gray5,

        '& p': {
            marginTop: 0
        }
    }
}));

const multipleError = 'The password must contain at least one symbol from the following groups:'

const ChangePassword = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const classes = useStyles();
    usePageTitle();

    const [customMessage, setCustomMessage] = useState<string | React.ReactNode | undefined>(undefined)

    const {login, oldPassword, errors, inProgress} = useSelector(
        (state: ReduxState) => state.auth,
    );

    const dispatchChangePasswordAction = (
        payload: ApiChangePasswordPayload,
    ) => {
        dispatch(actions.changeExpiredPassword.request({
            ...payload,
            onSuccess: () => history.push(Routes.Default)
        }));
    };

    useEffect(() => {
        if (errors) {
            if (errors?.faultstring.includes(multipleError)) {
                const formatted = formatMsg(errors.faultstring, ':', '\n')
                setCustomMessage(<ExtendedMessage title={formatted.title} messages={formatted.errors}/>)
            } else {
                setCustomMessage(errors.faultstring)
            }
        }
    }, [errors])


    return (
        <AuthWrapper title={t('screens:signIn.passwordChangeTitle')} message={customMessage}>
            <div className={classes.description}>
                <p>{t('screens:signIn.changePasswordSubTitle')}</p>
            </div>
            <ChangePasswordExpirationForm
                onSubmitAction={dispatchChangePasswordAction}
                validateOnChange={false}
                login={login || ''}
                oldPassword={oldPassword || ''}
                apiErrorsExist={!!errors}
                inProgress={inProgress}
            />
        </AuthWrapper>
    );
};

export default ChangePassword;

import { ListItem, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { Colors } from '../../styles/Colors';

export type SidebarItemProps = {
    active?: boolean;
    title: string;
    onClick?: () => void;
};
const useStyles = makeStyles(() => ({
    activeItem: {
        color: `${Colors.WhiteWithOpacity} !important`,
        backgroundColor: Colors.Secondary6,
        borderRadius: 4,
    },
    item: {
        '&:hover': {
            backgroundColor: Colors.Secondary6,
        },
    },
}));

const SidebarItem: React.FC<SidebarItemProps> = ({
    active,
    onClick,
    title,
}) => {
    const permission = usePermissionContext();
    const classes = useStyles();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <ListItem
            className={classNames(active && classes.activeItem, classes.item)}
            onClick={onClick}
        >
            {title}
        </ListItem>
    );
};

export default SidebarItem;

import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../..';
import assignedRingGroupsReducer, {
    AssignedRingGroupsReducerState,
} from './assignedRingGroups/reducer';
import callBarringReducer, {
    CallBarringReducerState,
} from './callBarring/reducer';
import callForwadingReducer, {
    CallForwardingReducerState,
} from './callForwarding/reducer';
import callScreeningReducer, {
    CallScreeningReducerState,
} from './callScreening/reducer';
import deviceReducer, { DeviceReducerState } from './device/reducer';
import extensionsReducer, {
    ExtensionsState,
    initialState as extensionReducerState,
} from './extensions/reducer';
import mohsReducer, { MohsReducerState } from './mohs/reducer';
import planReducer, { PlanReducerState } from './plan/reducer';
import serviceFeaturesReducer, {
    ServiceFeaturesReducerState,
} from './serviceFeatures/reducer';
import sipReducer, { SipReducerState } from './sip/reducer';
import extensionTabsReducer, {
    ExtensionTabReducerState,
    initialState as extensionTabsInitialState,
} from './tabs/reducer';
import voicemailReducer, {
    VoicemailReducerState,
    initialState as voicemailInitialState,
} from './voicemail/reducer';

export type MainExtensionsReducerState = AssignedRingGroupsReducerState &
    CallBarringReducerState &
    CallForwardingReducerState &
    CallScreeningReducerState &
    DeviceReducerState &
    ExtensionsState &
    MohsReducerState &
    PlanReducerState &
    ServiceFeaturesReducerState &
    SipReducerState &
    ExtensionTabReducerState &
    VoicemailReducerState;

export type MainExtensionsReducerActions = ActionType<typeof actions>;

export const initialState = {
    ...extensionReducerState,
    ...extensionTabsInitialState,
    ...voicemailInitialState,
};

export const mainExtensionsReducer = createReducer<
    MainExtensionsReducerState,
    MainExtensionsReducerActions
>(
    initialState,
    //@ts-ignore
    {
        ...extensionsReducer?.handlers,
        ...assignedRingGroupsReducer?.handlers,
        ...callBarringReducer?.handlers,
        ...callForwadingReducer?.handlers,
        ...callScreeningReducer?.handlers,
        ...deviceReducer?.handlers,
        ...mohsReducer?.handlers,
        ...planReducer?.handlers,
        ...serviceFeaturesReducer?.handlers,
        ...sipReducer?.handlers,
        ...extensionTabsReducer?.handlers,
        ...voicemailReducer?.handlers,
    },
);

export default mainExtensionsReducer;

import MaskedInput from 'react-text-mask';
import React from 'react';

const MacMaskForFiltersWithoutMask = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
                ':',
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
                ':',
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
                ':',
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
                ':',
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
                ':',
                /[A-Fa-f0-9]|x/,
                /[A-Fa-f0-9]|x/,
            ]}
            placeholderChar={'x'}
            keepCharPositions
        />
    );
};

export default MacMaskForFiltersWithoutMask;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ChevronLeft } from '../../assets/chevron left.svg';
import classNames from 'classnames';
import Switch from '../Switch/Switch';
import { IconButton } from '@material-ui/core';
import { Colors } from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        lineHeight: 'initial',
        alignItems: 'center',
        '& > span': {
            marginRight: 9,
        },
        '& h2': {
            margin: 0,
            fontSize: 32,
            color: Colors.Text,
            fontWeight: 300,
        },
    },

    arrow: {
        cursor: 'pointer',
    },
    subTitle: {
        opacity: 0.6,
        fontSize: 22,
        margin: '0 16px',
    },

    headerName: {
        marginLeft: 16,
        marginRight: 16,
        color: Colors.Gray5,
        fontSize: 22,
        fontWeight: 400,
    },
    header: {},
}));

type ExtensionTitleProps = {
    headerText: string;
    headerName?: string;
    onChevronClick?: () => void;
    onSwitchClick?: (event: any) => void;
    isBlocked?: boolean;
    dataQa?: string;
    dataTestId?: string;
    subTitle?: string;
    customClasses?: { header?: string };
};

const ExtensionTitle: React.FC<ExtensionTitleProps> = ({
    headerText,
    headerName,
    onChevronClick,
    onSwitchClick,
    isBlocked = false,
    dataQa,
    dataTestId,
    subTitle,
    customClasses,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root} data-qa={dataQa} data-testid={dataTestId}>
            <h2 className={classNames(classes.header, customClasses?.header)}>
                <IconButton className={classes.arrow} onClick={onChevronClick}>
                    <ChevronLeft />
                </IconButton>
                {headerText}
                {headerName && (
                    <span className={`header-name ${classes.headerName}`}>
                        {headerName}
                    </span>
                )}
                {subTitle && (
                    <span className={`header-subtitle ${classes.subTitle}`}>
                        {subTitle}
                    </span>
                )}
                {onSwitchClick && (
                    <Switch
                        checked={!isBlocked}
                        onChange={onSwitchClick}
                        dataQa={'extension-details-status-switcher'}
                        dataTestId={'extension-details-status-switcher'}
                    />
                )}
            </h2>
        </div>
    );
};

export default ExtensionTitle;

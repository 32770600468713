import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import RuleInput from './utils/RuleInput';
import SelectField from '../../SelectField/SelectField';
import classNames from 'classnames';
import { getRuleError } from './utils/validation';
import { ServiceCodesFormType, useStyles } from './utils/serviceCodesUtils';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../../store/types/ServiceFeature';

const ServiceCodesForm: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const serviceFeatures = useSelector<
        ReduxState,
        ServiceFeature[] | undefined
    >((state) => state.callSettings.customerServiceFeatures);

    const disabledFeature =
        serviceFeatures?.find((v) => v.name === ServiceFeatureName.VoiceDialing)
            ?.effective_flag_value === 'N';

    const {
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        setFieldError,
    } = useFormikContext<ServiceCodesFormType>();

    const onChangeRule = useCallback(
        (_, value) => {
            const item = values.rules.find(
                (v) => v.i_dialing_rule === value.i_dialing_rule,
            );
            setFieldValue('selectedRuleId', item?.i_dialing_rule);
        },
        [values.rules],
    );

    if (values.rules.length === 0 || disabledFeature) {
        return (
            <Grid item className={classes.itemsContainer}>
                <div className={classes.noData}>{t('common:noData')}</div>
            </Grid>
        );
    }

    const index = values.rules.findIndex(
        (v) => v.i_dialing_rule == values.selectedRuleId,
    );
    const selectedRuleIndex = index === -1 ? 0 : index;
    const isRestrictedSelecting =
        values.rules[selectedRuleIndex].restricted === 'Y';
    const isRestrictedRule =
        !values.rules[selectedRuleIndex].owner_type || isRestrictedSelecting;

    return (
        <div>
            <Grid item className={classes.itemsContainer}>
                {values.rules.length > 1 && !isRestrictedSelecting && (
                    <>
                        <span
                            className={classNames(
                                classes.header,
                                classes.dialingRulesHeader,
                            )}
                        >
                            {t('screens:callSettings.dialingRules')}
                        </span>
                        <SelectField
                            items={values.rules}
                            value={values.rules[selectedRuleIndex]}
                            onChange={onChangeRule}
                            getOptionLabel={(option) =>
                                `${
                                    option.owner_type === 'Customer'
                                        ? t('screens:callSettings.customRule')
                                        : option.name
                                } (${
                                    !option.owner_type
                                        ? t('common:readOnly')
                                        : t('common:editable')
                                })`
                            }
                            getOptionSelected={(v) =>
                                v.i_dialing_rule ===
                                values.rules[selectedRuleIndex].i_dialing_rule
                            }
                            disableClearable
                            dataTestId="dialing-rule-select"
                            dataQa="dialing-rule-select"
                            className={classes.ruleSelect}
                        />
                    </>
                )}

                <>
                    <span
                        className={classNames(
                            classes.header,
                            !isRestrictedSelecting && classes.generalHeader,
                        )}
                    >
                        {t('screens:callSettings.general')}
                    </span>

                    <Grid className={classes.rulesContainer}>
                        <RuleInput
                            label={t('screens:callSettings.transferCall')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.dial_out`}
                            tooltipText={t(
                                'tooltips:callSettings.transferCall',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.dial_out || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'dial_out',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />

                        <RuleInput
                            label={t('screens:callSettings.voicemail')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.voicemail`}
                            tooltipText={t('tooltips:callSettings.voicemail')}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.voicemail || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'voicemail',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />

                        <RuleInput
                            label={t('screens:callSettings.bypassDialPlan')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.bypass_dial_plan`}
                            tooltipText={t(
                                'tooltips:callSettings.bypassDialPlan',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.bypass_dial_plan || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'bypass_dial_plan',
                            )}
                            setFieldError={setFieldError}
                            maxLength={6}
                            handleBlur={handleBlur}
                        />
                    </Grid>

                    <Grid className={classes.switchContainer}>
                        <SwitchWithLabel
                            id="callParking"
                            field="callParkingStatus"
                            label={t('screens:callSettings.callParking')}
                            className={classes.switch}
                            setValue={(field, value) =>
                                setFieldValue(field, value)
                            }
                            value={values.callParkingStatus}
                        />
                    </Grid>

                    {values.callParkingStatus && (
                        <Grid className={classes.rulesContainer}>
                            <RuleInput
                                label={t('screens:callSettings.park')}
                                id={`rules.[${selectedRuleIndex}].dial_codes.call_park`}
                                tooltipText={t('tooltips:callSettings.park')}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.call_park || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'call_park',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />

                            <RuleInput
                                label={t('screens:callSettings.release')}
                                id={`rules.[${selectedRuleIndex}].dial_codes.call_release`}
                                tooltipText={t('tooltips:callSettings.release')}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.call_release || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'call_release',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />
                        </Grid>
                    )}

                    <Grid className={classes.switchContainer}>
                        <SwitchWithLabel
                            id="pagingIntercom"
                            field="pagingIntercomStatus"
                            label={t('screens:callSettings.pagingIntercom')}
                            className={classes.switch}
                            setValue={(field, value) =>
                                setFieldValue(field, value)
                            }
                            value={values.pagingIntercomStatus}
                        />
                    </Grid>

                    {values.pagingIntercomStatus && (
                        <Grid className={classes.rulesContainer}>
                            <RuleInput
                                label={t('screens:callSettings.pagingPrefix')}
                                id={`rules.[${selectedRuleIndex}].dial_codes.paging_prefix`}
                                tooltipText={t(
                                    'tooltips:callSettings.pagingPrefix',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.paging_prefix || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'paging_prefix',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />
                        </Grid>
                    )}

                    <Grid className={classes.switchContainer}>
                        <SwitchWithLabel
                            id="groupPickup"
                            field="groupPickupStatus"
                            label={t('screens:callSettings.groupPickup')}
                            className={classes.switch}
                            setValue={(field, value) =>
                                setFieldValue(field, value)
                            }
                            value={values.groupPickupStatus}
                        />
                    </Grid>

                    {values.groupPickupStatus && (
                        <Grid className={classes.rulesContainer}>
                            <RuleInput
                                label={t(
                                    'screens:callSettings.groupPickupPrefix',
                                )}
                                id={`rules.[${selectedRuleIndex}].dial_codes.group_pickup`}
                                tooltipText={t(
                                    'tooltips:callSettings.groupPickupPrefix',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.group_pickup || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'group_pickup',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />
                        </Grid>
                    )}

                    <span
                        className={classNames(
                            classes.header,
                            !values.groupPickupStatus &&
                                classes.headerWithMargin,
                        )}
                    >
                        {t('screens:callSettings.privateCall')}
                    </span>

                    <Grid className={classes.rulesContainer}>
                        <RuleInput
                            label={t('screens:callSettings.hideCli')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.clir_hide`}
                            tooltipText={t('tooltips:callSettings.hideCli')}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.clir_hide || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'clir_hide',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />

                        <RuleInput
                            label={t('screens:callSettings.showCli')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.clir_show`}
                            tooltipText={t('tooltips:callSettings.showCli')}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.clir_show || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'clir_show',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />
                    </Grid>

                    <span className={classes.header}>
                        {t('screens:callSettings.callRecording')}
                    </span>

                    <Grid className={classNames(classes.rulesContainer)}>
                        <Grid className={classes.rowInsideContainer}>
                            <RuleInput
                                label={t('screens:callSettings.startRecording')}
                                id={`rules.[${selectedRuleIndex}].dial_codes.recording_on`}
                                tooltipText={t(
                                    'tooltips:callSettings.startRecording',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.recording_on || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'recording_on',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />

                            <RuleInput
                                label={t(
                                    'screens:callSettings.startRecordingDtmf',
                                )}
                                id={`rules.[${selectedRuleIndex}].dial_codes.recording_on_dtmf`}
                                tooltipText={t(
                                    'tooltips:callSettings.startRecordingDtmf',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.recording_on_dtmf || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'recording_on_dtmf',
                                )}
                                setFieldError={setFieldError}
                                className={classes.space}
                                handleBlur={handleBlur}
                            />
                        </Grid>

                        <Grid className={classes.rowInsideContainer}>
                            <RuleInput
                                label={t('screens:callSettings.stopRecording')}
                                id={`rules.[${selectedRuleIndex}].dial_codes.recording_off`}
                                tooltipText={t(
                                    'tooltips:callSettings.stopRecording',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.recording_off || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'recording_off',
                                )}
                                setFieldError={setFieldError}
                                handleBlur={handleBlur}
                            />

                            <RuleInput
                                label={t(
                                    'screens:callSettings.stopRecordingDTMF',
                                )}
                                id={`rules.[${selectedRuleIndex}].dial_codes.recording_off_dtmf`}
                                tooltipText={t(
                                    'tooltips:callSettings.stopRecordingDTMF',
                                )}
                                readOnly={isRestrictedRule}
                                handleChange={handleChange}
                                value={
                                    values.rules[selectedRuleIndex].dial_codes
                                        ?.recording_off_dtmf || ''
                                }
                                helperText={getRuleError(
                                    errors,
                                    selectedRuleIndex,
                                    'recording_off_dtmf',
                                )}
                                setFieldError={setFieldError}
                                className={classes.space}
                                handleBlur={handleBlur}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.switchContainer}>
                        <SwitchWithLabel
                            id="callSupervision"
                            field="callSupervisionStatus"
                            label={t('screens:callSettings.callSupervision')}
                            className={classes.switch}
                            setValue={(field, value) =>
                                setFieldValue(field, value)
                            }
                            value={values.callSupervisionStatus}
                        />
                    </Grid>

                    {values.callSupervisionStatus && (
                        <Grid className={classNames(classes.rulesContainer)}>
                            <Grid className={classes.rowInsideContainer}>
                                <RuleInput
                                    label={t('screens:callSettings.spyMode')}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.spy`}
                                    tooltipText={t(
                                        'tooltips:callSettings.spyMode',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.spy || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'spy',
                                    )}
                                    setFieldError={setFieldError}
                                    handleBlur={handleBlur}
                                />

                                <RuleInput
                                    label={t(
                                        'screens:callSettings.spyModeDtmf',
                                    )}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.spy_dtmf`}
                                    tooltipText={t(
                                        'tooltips:callSettings.spyModeDtmf',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.spy_dtmf || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'spy_dtmf',
                                    )}
                                    setFieldError={setFieldError}
                                    className={classes.space}
                                    handleBlur={handleBlur}
                                />
                            </Grid>

                            <Grid className={classes.rowInsideContainer}>
                                <RuleInput
                                    label={t(
                                        'screens:callSettings.whisperMode',
                                    )}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.whisper`}
                                    tooltipText={t(
                                        'tooltips:callSettings.whisperMode',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.whisper || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'whisper',
                                    )}
                                    setFieldError={setFieldError}
                                    handleBlur={handleBlur}
                                />

                                <RuleInput
                                    label={t(
                                        'screens:callSettings.whisperModeDtmf',
                                    )}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.whisper_dtmf`}
                                    tooltipText={t(
                                        'tooltips:callSettings.whisperModeDtmf',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.whisper_dtmf || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'whisper_dtmf',
                                    )}
                                    setFieldError={setFieldError}
                                    className={classes.space}
                                    handleBlur={handleBlur}
                                />
                            </Grid>

                            <Grid className={classes.rowInsideContainer}>
                                <RuleInput
                                    label={t(
                                        'screens:callSettings.bargeInMode',
                                    )}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.barge_in`}
                                    tooltipText={t(
                                        'tooltips:callSettings.bargeInMode',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.barge_in || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'barge_in',
                                    )}
                                    setFieldError={setFieldError}
                                    handleBlur={handleBlur}
                                />
                                <RuleInput
                                    label={t(
                                        'screens:callSettings.bargeInModeDTMF',
                                    )}
                                    id={`rules.[${selectedRuleIndex}].dial_codes.barge_in_dtmf`}
                                    tooltipText={t(
                                        'tooltips:callSettings.bargeInModeDTMF',
                                    )}
                                    readOnly={isRestrictedRule}
                                    handleChange={handleChange}
                                    value={
                                        values.rules[selectedRuleIndex]
                                            .dial_codes?.barge_in_dtmf || ''
                                    }
                                    helperText={getRuleError(
                                        errors,
                                        selectedRuleIndex,
                                        'barge_in_dtmf',
                                    )}
                                    setFieldError={setFieldError}
                                    className={classes.space}
                                    handleBlur={handleBlur}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <span
                        className={classNames(
                            classes.header,
                            !values.callSupervisionStatus &&
                                classes.headerWithMargin,
                        )}
                    >
                        {t('screens:callSettings.callScreening')}
                    </span>

                    <Grid className={classes.rulesContainer}>
                        <RuleInput
                            label={t(
                                'screens:callSettings.individualManagment',
                            )}
                            id={`rules.[${selectedRuleIndex}].dial_codes.cs_individual_management`}
                            tooltipText={t(
                                'tooltips:callSettings.individualManagment',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.cs_individual_management || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'cs_individual_management',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />

                        <RuleInput
                            label={t('screens:callSettings.cloudPbxManagment')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.cs_bulk_management`}
                            tooltipText={t(
                                'tooltips:callSettings.cloudPBXManagment',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.cs_bulk_management || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'cs_bulk_management',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />
                    </Grid>

                    <span className={classes.header}>
                        {t('screens:callSettings.ringGroupLoginLogout')}
                    </span>

                    <Grid className={classes.rulesContainer}>
                        <RuleInput
                            label={t('screens:callSettings.loginToRingGroup')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.huntgroup_login`}
                            tooltipText={t(
                                'tooltips:callSettings.ringGroupLogin',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.huntgroup_login || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'huntgroup_login',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />

                        <RuleInput
                            label={t('screens:callSettings.logoutOfRingGroup')}
                            id={`rules.[${selectedRuleIndex}].dial_codes.huntgroup_logout`}
                            tooltipText={t(
                                'tooltips:callSettings.ringGroupLogout',
                            )}
                            readOnly={isRestrictedRule}
                            handleChange={handleChange}
                            value={
                                values.rules[selectedRuleIndex].dial_codes
                                    ?.huntgroup_logout || ''
                            }
                            helperText={getRuleError(
                                errors,
                                selectedRuleIndex,
                                'huntgroup_logout',
                            )}
                            setFieldError={setFieldError}
                            handleBlur={handleBlur}
                        />
                    </Grid>
                </>
            </Grid>
        </div>
    );
};

export default ServiceCodesForm;

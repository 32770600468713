import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import {
    ServiceFeatureAtributesName,
    ServiceFeatureName,
} from '../store/types/ServiceFeature';
import { CallScreeningFormType } from '../components/Forms/Extensions/CallScreening/CallScreeningForm.utils';
import {
    checkAttributeEnabled,
    selectProperCallProcessingService,
} from '../utils/extensions/convertServiceFeaturesToIconsFeatures';
import {
    CPConditionInfoType,
    CPConditionInfoTypeMapping,
    CPRuleInfo,
    CPRuleInfoDetails,
} from '../store/types/CallScreening';
import {
    CallScreeningConditions,
    CallScreeningRules,
} from '../store/reducers/extensions/callScreening/reducer';
import {
    GetCallProcessingPolicyListEntity,
    OperationMode,
} from '../store/actions/extensions/payloads';
import { translateBitMak } from '../utils/extensions/translateBitMak';
import { v4 as uuidv4 } from 'uuid';

export const useSipTrunkCallScreeningFormData = () => {
    const {
        serviceFeatures,
        callScreeningRules,
        callScreeningConditions,
        callProcessingPolicyList,
        callProcessingOperationModeList,
    } = useSelector((state: ReduxState) => state.extensions);

    const extensionDetails = useSelector((state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk);

    const callProcessingValue = serviceFeatures?.find(
        (v) =>
            v.name === ServiceFeatureName.CallProcessing &&
            v.effective_flag_value === 'Y',
    );

    const serviceValue = callProcessingValue
        ? selectProperCallProcessingService(
              extensionDetails?.callProcessingModeInfo?.name,
              callProcessingValue,
          )
        : undefined;

    const initFormData: CallScreeningFormType = useMemo(() => {
        const rules = addModesTranslation(
            addPolicy(
                addDetails(callScreeningRules, callScreeningConditions),
                callProcessingPolicyList,
            ),
            callProcessingOperationModeList,
        );

        const notUsedPolicy = callProcessingPolicyList
            ?.filter(
                (policy) =>
                    !rules?.find(
                        (rule) => rule.i_cp_policy == policy.i_cp_policy,
                    ),
            )
            .map((rule, key) => ({ ...rule, id: key, active: false }));

        const serviceCallProcessing = serviceFeatures?.find(
            (e) => e.name === ServiceFeatureName.CallProcessing,
        );

        return {
            callScreeningLocked: serviceCallProcessing?.locked === 1,
            callScreening: !!callProcessingValue,
            pbxEnabled: serviceCallProcessing
                ? checkAttributeEnabled(
                      serviceCallProcessing,
                      ServiceFeatureAtributesName.PBX,
                  )
                : false,
            rules: rules,
            notSelectedPolicy: notUsedPolicy,
        };
    }, [
        serviceFeatures,
        callScreeningRules,
        callScreeningConditions,
        callProcessingPolicyList,
        callProcessingOperationModeList,
    ]);

    return {
        initFormData,
        data: {
            serviceValue,
            mode: extensionDetails?.callProcessingModeInfo?.name,
            policy: callProcessingPolicyList,
        },
    };
};

function addDetails(
    callScreeningRules?: CallScreeningRules,
    callScreeningConditions?: CallScreeningConditions,
): CPRuleInfoDetails[] | undefined {
    return callScreeningRules?.items?.map((cpRule) => {
        const output: any = {};

        Object.values(CPConditionInfoType).forEach((enumValue: string) => {
            output[enumValue] = callScreeningConditions?.items?.find(
                (details) =>
                    details.type == enumValue &&
                    details.i_cp_condition ==
                        cpRule[CPConditionInfoTypeMapping[enumValue]],
            );
        });

        return {
            id: uuidv4(),
            ...cpRule,
            ...output,
        };
    });
}

function addModesTranslation(
    items?: CPRuleInfo[] | CPRuleInfoDetails[],
    modes?: OperationMode[],
) {
    // @ts-ignore
    return items?.map((rule: CPRuleInfo | CPRuleInfoDetails) => {
        if (!rule.operation_mode_bitmask || !modes) {
            return items;
        }

        const translation =
            rule.operation_mode_bitmask &&
            translateBitMak(rule.operation_mode_bitmask, modes);

        return {
            ...rule,
            modeDecode: translation,
        };
    }) as CPRuleInfoDetails[];
}

function addPolicy(
    items?: CPRuleInfo[],
    policyList?: GetCallProcessingPolicyListEntity[],
) {
    return items?.map((rule) => {
        const policy = policyList?.find(
            (policy) => policy.i_cp_policy == rule.i_cp_policy,
        );

        const showOnlyPolicy = !!isOnlyPolicySet(policy, rule);

        return {
            ...rule,
            policyDetails: policy,
            showOnlyPolicy,
        } as CPRuleInfoDetails;
    });
}
enum BitMaskCode {
    Any = 1023,
}
function isOnlyPolicySet(
    policy?: GetCallProcessingPolicyListEntity,
    rule?: CPRuleInfo,
) {
    return (
        policy &&
        !rule?.time_window_i_cp_condition &&
        !rule?.to_number_i_cp_condition &&
        !rule?.from_number_i_cp_condition &&
        rule?.operation_mode_bitmask == BitMaskCode.Any
    );
}

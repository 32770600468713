import * as React from 'react';
import {useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { ReduxState } from '../../../../store/types';
import { NewResponseMessagesDialogProps, newFileValidationSchema, useStyles } from './NewResponseMessagesDialog.utils';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import CustomizedTextField from '../../../TextField/TextField';
import Loader from '../../../Loader/Loader';
import { actions } from '../../../../store';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import { YesNo } from '../../../../store/types/CallScreening';
import { ApiFileCreateEditPayload } from '../../../../store/actions/callSettings/payloads';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';

const NewResponseMessagesDialog: React.FC<NewResponseMessagesDialogProps> = ({
        isOpen,
        editObject,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const files = useSelector(
        (state: ReduxState) => state.callSettings?.customerResponseMessages?.file_list || [],
    );
    
    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.callSettings.isCreatingResponseMessage || state.callSettings.isEditingResponseMessage || false);
    
    const audioConfig = useSelector<ReduxState, number>(
        (state) =>
            state.generic?.configData?.AudioFiles?.ResponseMessageKeepDays || 0
    );
    
    const crmFile = React.useRef<HTMLInputElement>(null);

    const initialValues: ApiFileCreateEditPayload = useMemo(
        () => ({
            file_name: editObject?.name || '',
            i_object: editObject?.id,
            transitional: editObject?.transitional || YesNo.No,
            file: undefined,
            fileDisplayName: files.find(file => file.id == editObject?.id)?.name || '',
            fileSize: 0
        } as ApiFileCreateEditPayload),
        [editObject],
    );

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        setFieldValue,
        handleChange,
        errors,
        dirty
    } = useFormik<ApiFileCreateEditPayload>({
        initialValues,
        onSubmit: (form) => {
            if(editObject) {
                dispatch(actions.editCustomerResponseMessage.request({
                    object: {
                        ...editObject,
                        ...form
                    },
                    isDirty: initialValues.file_name !== values.file_name || initialValues.transitional !== values.transitional,
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            } else {
                dispatch(actions.addCustomerResponseMessage.request({
                    object: form,
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            }
        },
        validationSchema: newFileValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    const isNameNotUnique: boolean = useMemo(() => {
        const found = files?.find(e => e.name?.toLocaleUpperCase()?.trim() === values.file_name?.toLocaleUpperCase()?.trim());
        let notUnique = (found !== undefined);
        
        if(notUnique && editObject !== undefined && found !== undefined) {
            if(found.id === editObject.id) {
                notUnique = false;
            }
        }

        if(notUnique && errors.file_name === undefined) {
            setFieldError('file_name', t('errors:common.thisNameIsAlreadyUsed'));
        } else if(!notUnique && errors.file_name !== undefined) {
            setFieldError('file_name', undefined);
        }

        return notUnique;
    }, [files, values.file_name, errors]);

    const onFileUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event?.stopPropagation();
        event?.preventDefault();
        if (event?.target?.files) {
            setFieldValue(
                'fileDisplayName',
                event.target.files[0]?.name,
            );
            setFieldValue(
                'fileSize',
                event.target.files[0]?.size,
            );
            setFieldValue(
                'file',
                event.target.files[0],
            );
            setFieldError('fileDisplayName', undefined);
        }
    };
    
    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'files-add-new-modal'}
                header={editObject
                    ? t('screens:callSettings.editResponseMessageDialogTitle')
                    : t('screens:callSettings.addNewResponseMessagesDialogTitle')
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            !(values.file_name?.length) ||
                            isLoading ||
                            isNameNotUnique
                        }
                        onClick={() => {
                            handleSubmit();
                        }}
                        dataTestId={'add-new-response-message-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-response-message-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="file_name"
                                label={t('screens:callSettings.policyName')}
                                value={values.file_name}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.file_name && errors.file_name.length > 0
                                        ? errors.file_name
                                        : ''
                                }
                                onChange={handleChange}
                                maxLength={64}
                                dataQa="name-field"
                            />
                        </Box>


                            <Box className={classes.boxRow}>
                                <input
                                    type="file"
                                    id="file"
                                    accept={'audio/*'}
                                    ref={crmFile}
                                    style={{ display: 'none' }}
                                    data-testid="crm-file-upload"
                                    onChange={onFileUploadHandler}
                                />
                                
                                <TextField
                                    id="fileDisplayName"
                                    label={t('common:file')}
                                    dataQa="fileDisplayName-name-input"
                                    warningIcon={false}
                                    className={classes.fileDisplayName}
                                    value={values.fileDisplayName}
                                    readOnly
                                    helperText={errors?.fileDisplayName ? errors.fileDisplayName : ''}
                                />
                                
                                <Button
                                    dataQa="browse-button"
                                    primary
                                    accent
                                    className={classes.browseButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        crmFile.current?.click();
                                    }}
                                    containerClassName={classes.browserButtonContainer}
                                >
                                    {t('screens:devices.browse')}
                                </Button>
                            </Box>

                            <Box className={classes.helperBox}>
                                {!errors?.fileDisplayName && (
                                    <span className={classes.descriptionFormats}>
                                        {t('common:supportedFileFormats')} mp3, wav, ogg, au {t('screens:callSettings.maxFileSize')}
                                    </span>
                                )}
                            </Box>

                        <Box className={classes.boxRow}>
                            <CustomizedCheckbox
                                checked={values.transitional === YesNo.Yes}
                                dataQa="play-before-action-status"
                                onChange={(value) =>
                                    setFieldValue(
                                        'transitional',
                                            value.target.checked ? YesNo.Yes : YesNo.No,
                                        )
                                    }
                                label={t(
                                    'screens:callSettings.temporaryPattern', {
                                        value: audioConfig
                                    }
                                )}
                            />
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition text={t('screens:devices.uploading')} />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewResponseMessagesDialog;
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReceiptInfoLine from '../PaymentStatusDialog/ReceiptInfoLine';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import {
    CustomerInfoDetails,
    GlobalCurrencyInfo,
} from '../../../store/types/CustomerInfo';
import { useStyles } from './PaymentInfoBox.utils';
import {
    convertFormatFromBackendToDayJs,
    convertToUserFormat,
    convertUserLocalTimeToUtc,
} from '../../../utils/dateWithTimezoneConversion';
import { PaymentStatus } from '../../../store/types/Payment';

type PaymentInfoBoxProps = {
    withoutTooltips?: boolean;
};

const PaymentInfoBox: React.VFC<PaymentInfoBoxProps> = ({
    withoutTooltips,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.billing.customerInfo);

    const { paymentDetails } = useSelector(
        (state: ReduxState) => state.billing,
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const outDateFormat = useSelector<ReduxState, string | undefined>(
        (state) => state.generic.sessionData?.out_date_time_format,
    );

    if (!paymentDetails) {
        return null;
    }

    return (
        <>
            <ReceiptInfoLine
                leftText={t('common:date')}
                rightText={convertToUserFormat(
                    convertUserLocalTimeToUtc(
                        paymentDetails.timestamp,
                        timezoneOffset,
                    ),
                    convertFormatFromBackendToDayJs(outDateFormat || ''),
                )}
            />
            <ReceiptInfoLine
                leftText={t('common:method')}
                rightText={paymentDetails.payment_method_info.replace(
                    /(x)+/,
                    ' •••• ',
                )}
            />

            {paymentDetails.x_transaction_id && (
                <ReceiptInfoLine
                    leftText={t('screens:billing.transactionNumber')}
                    rightText={paymentDetails.x_transaction_id}
                    withTooltip={!withoutTooltips}
                />
            )}

            {customerInfo?.companyname && (
                <ReceiptInfoLine
                    leftText={t('common:account')}
                    rightText={customerInfo?.companyname}
                    withTooltip={!withoutTooltips}
                />
            )}

            <div className={classes.separator} />

            {paymentDetails.status !== PaymentStatus.Failed && (
                <>
                    <ReceiptInfoLine
                        leftText={t('screens:billing.paymentTotal')}
                        rightText={paymentDetails.paymentAmount}
                        rightSecondaryText={currencyInfo?.iso_4217}
                    />
                    <ReceiptInfoLine
                        leftText={t('common:tax')}
                        rightText={paymentDetails.taxAmount}
                        rightSecondaryText={currencyInfo?.iso_4217}
                    />
                    <ReceiptInfoLine
                        leftText={t('screens:myCompany.total')}
                        rightText={paymentDetails.amount.toFixed(2).toString()}
                        rightSecondaryText={currencyInfo?.iso_4217}
                        boldLeftText
                    />
                </>
            )}
        </>
    );
};

export default PaymentInfoBox;

import { SipCall } from "../actions/ringgroups/payloads";
import { CallHistory } from "./CallHistory";
import { ExtensionType } from "./Extension";
import { RingGroupType } from "./RingGroup";

export enum WidgetDataType {
    externalIframe = 'iframe',
    extensionPresense = 'extensions',
    callVolumes = 'callvolumes'
}

export enum WidgetPresantationType {
    list = 'list',
    doughnut = 'doughnut'
}

export enum WidgetAutoRefreshPreset {
    sec30 = 30,
    sec60 = 60,
    hour1 = 3600
}

export enum WidgetExtensionPresenseFilter {
    all = 'all',
    selected_extensions = 'selected_extensions',
    selected_ring_groups = 'selected_ring_groups',
}

export interface BaseWallboardWidgetPositionProps {
    x: number;
    y: number;
}

export interface BaseWallboardWidgetSizeProps {
    w: number;
    h: number;
}

export interface BaseWallboardWidgetViewProps extends BaseWallboardWidgetPositionProps, BaseWallboardWidgetSizeProps {
}

export interface BaseWallboardWidget {
    id: string;
    title: string;
    dataType: WidgetDataType;
    presentation: WidgetPresantationType;
    autoRefreshTime: number;
    layout: BaseWallboardWidgetViewProps;
    extensions?: string[];
    //The data object specific for each the widget type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
}

export interface ExternalIframeData {
    url: string;
}

export interface WidgetExtensionPresenseData {
    show: WidgetExtensionPresenseFilter;
    ringGroup: string;
    extensions?: string[];
    timeRange?:WidgetCallVolumesTimeRange
}

export enum WidgetCallVolumesTimeRange {
    min60 = 60,
    min180 = 180,
    min300 = 300,
    min420 = 420,
    min1440 = 1440,
    min4320 = 4320,
    min7200 = 7200,
    min10080 = 10080,
}

export interface WidgetCallVolumesData {
    show: WidgetExtensionPresenseFilter;
    timeRange: WidgetCallVolumesTimeRange;
    ringGroup: string;
    extensions?: string[];
    numberOfCalls: boolean;
}

export interface RefreshWidgetTime {
    id: string;
    time: string;
}

export interface RefreshWallboardTriggerPayload {
    id: string | undefined;
    refreshImmidiately: boolean;
}

export interface RefreshWallboardTriggerResponse {
    refreshWidgetsQueue: RefreshWidgetQueueItem[];
    refreshWidgetsTime?: RefreshWidgetTime[];
}

export interface WidgetCallHistory extends CallHistory {
    callDuration: string;
    startedMinutesAgo: number;
    durationInSec: number;
}
export interface CallVolumesWidgetData {
    callHistoryItems: WidgetCallHistory[];
    extensionsList: ExtensionType[];
    ringGroupsList: RingGroupType[];
}

export interface ExtensionPresenceWidgetData {
    sipCallsList: SipCall[];
    extensionsList: ExtensionType[];
    ringGroupsList: RingGroupType[];
}

export interface RefreshWidgetQueueItem {
    id: string;
    dataHasLoaded: boolean;
    initLoadStart: string;
    data: CallVolumesWidgetData | ExtensionPresenceWidgetData | {};
}

export interface UpdateWallboardsRequest {
    data: BaseWallboardWidget[];
    onSuccess?: () => void;
}

export enum DefaultAutoRefreshPresetForDataType {
    'iframe' = WidgetAutoRefreshPreset.sec30,
    'extensions' = WidgetAutoRefreshPreset.sec30,
    'callvolumes' = WidgetAutoRefreshPreset.hour1,
}
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';

import { actions } from '../../index';
import {
    getConfigData,
    getLocalLanguagesList,
    getSessionData,
    getTimeZonesList,
} from '../generic/saga';

import JSONFormData from '../../../utils/JSONFormData';
import { api } from '../../services/axios';
import { Customer } from '../../../services/endpoints';
import { AxiosResponse } from 'axios';
import {
    MyProfile,
    CustomerIndividualInfoResponse,
} from '../../actions/myProfile/payloads';
import { CustomerInfo } from '../../types/CustomerInfo';
import {
    makeMyProfileFromCustomerInfoDetails,
    makeMyProfileByCustomerIndividualInfo,
} from '../../../utils/transformers';
import { showErrorToast } from '../../../utils/showErrorToast';
import { ActionType } from 'typesafe-actions';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import { EditMyProfileForm } from '../../../views/MyProfile/utils';
import qs from 'qs';
import { ReduxState } from '../../types';
import i18n from '../../../services/i18n';
import { toast } from 'react-hot-toast';

export function* getMyProfileDetails() {
    try {
        yield call(getSessionData);
        yield call(getCustomerInfoByLogin);
        yield call(getTimeZonesList);
        yield call(getConfigData);
        yield call(getCustomerInfoByLogin);
        yield call(getLocalLanguagesList);
        yield call(getConfigData);

        yield put(actions.getMyProfileDetails.success());
    } catch (e) {
        //@ts-ignore
        showErrorToast(e);
        yield put(actions.getMyProfileDetails.failure());
    }
}

export function* getCustomerInfoByLogin() {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const { user } = yield select((state) => state.generic.sessionData);

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ login: user });

        const customerIndividualInfo: AxiosResponse<CustomerIndividualInfoResponse> = yield api.post(
            Customer.GetCustomerIndividualInfo,
            body,
        );

        let customerDetails: MyProfile | undefined;
        if (customerIndividualInfo.data.customer_individual_info) {
            customerDetails = makeMyProfileByCustomerIndividualInfo(
                customerIndividualInfo.data.customer_individual_info,
            );
        } else {
            body.setParams({get_main_office_info: 1});
            const customerInfo: AxiosResponse<CustomerInfo> = yield api.post(
                Customer.GetCustomerInfo,
                body,
            );

            customerDetails = makeMyProfileFromCustomerInfoDetails(
                customerInfo.data.customer_info,
            );
        }

        yield put(
            actions.getMyProfileCustomerInfoByLogin.success(customerDetails),
        );
    } catch (e) {
        yield put(actions.removeAuthDataAndReload({reload: true}));
        //@ts-ignore
        showErrorToast(e?.response?.data?.faultstring);
        yield put(actions.getMyProfileCustomerInfoByLogin.failure());
    }
}

export function* editMyProfile(
    action: ActionType<typeof actions.editMyProfile.request>,
) {
    try {
        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        yield editMyProfileSettings(dataToSave, action);
        yield editMyProfilePassword(dataToSave);

        yield put(actions.editMyProfile.success());

        if (dataToSave.email !== undefined) {
            localStorage.setItem('user_email', dataToSave.email);
        }
        yield delay(1000);
        location?.replace(
            `?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (e) {
        //@ts-ignore
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.editMyProfile.failure());
    }
}

const defaultPasswordError = {
    response: {
        data: {
            faultstring: i18n.t<string>(
                'screens:myCompany.passwordUpdatedFailed',
            ),
        },
    },
};

function* editMyProfilePassword(data: Partial<EditMyProfileForm>) {
    if (!data.oldPassword) return false;

    const payload = {
        old_password: data?.oldPassword || '',
        new_password: data?.newPassword || '',
        confirm_password: data?.confirmPassword || '',
    };

    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    try {
        body.setParams(payload);
        const response: AxiosResponse<{
            success: number;
            errors?: { [key: string]: string };
        }> = yield api.post(Customer.ChangePassword, body);

        if (response.data.success !== 1) {
            throw response.data;
        }

        toast(i18n.t<string>('screens:extensions.passwordChanged'));
        yield delay(2000);

        yield put(actions.changePassword.success());
    } catch (e) {
        //@ts-ignore
        if (e.errors) {
            //@ts-ignore
            yield put(actions.changePassword.failure(e.errors));
            throw defaultPasswordError;
        } else {
            throw e;
        }
    }
}

function* editMyProfileSettings(
    data: Partial<EditMyProfileForm>,
    action: ActionType<typeof actions.editMyProfile.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const payload = {
        i_customer: action.payload.i_customer,
        login: data.login,
        email: data.email,
    };

    if (action.payload.i_individual == -1) {
        const payloadCustomerInfo: any = {
            i_ui_time_zone: undefined,
            i_lang: undefined,
        };

        if (data.timeZone !== undefined) {
            payloadCustomerInfo.i_ui_time_zone =
                data.timeZone?.i_time_zone ?? null;
        }

        if (data.language !== undefined) {
            payloadCustomerInfo.i_lang = data.language?.iso_639_1 ?? null;
        }

        body.setParams({
            customer_info: {
                ...payload,
                ...payloadCustomerInfo,
            },
        });

        yield api.post(Customer.UpdateCustomer, body);
    } else {
        const payloadCustomerInfo: any = {
            i_ui_time_zone: undefined,
            i_lang: undefined,
        };

        if (data.timeZone !== undefined) {
            payloadCustomerInfo.i_time_zone =
                data.timeZone?.i_time_zone ?? null;
        }

        if (data.language !== undefined) {
            payloadCustomerInfo.lang = data.language?.iso_639_1 ?? null;
        }

        body.setParams({
            customer_individual_info: {
                ...payload,
                i_individual: action.payload.i_individual,
                ...payloadCustomerInfo,
            },
        });

        yield api.post(Customer.UpdateCustomerIndividual, body);
    }

    yield call(getSessionData);
}

export const myProfileSaga = [
    takeLatest(actions.getMyProfileDetails.request, getMyProfileDetails),
    takeLatest(
        actions.getMyProfileCustomerInfoByLogin.request,
        getCustomerInfoByLogin,
    ),
    takeLatest(actions.editMyProfile.request, editMyProfile),
];

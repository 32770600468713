import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    numberInput: {
        width: 135,
        marginRight: 20,
    },
    inactiveActionSelect: {
        marginTop: 10,
        '& .MuiInputBase-root': {
            '& .MuiInputBase-input': {
                padding: '10px 15px !important',
            },
        },
    },
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray7,
        marginBlockEnd: '0',
    },
    selectMaxWidth: {
        width: 475,
        maxWidth: 475,
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    playBeforeGrid: {
        margin: '10px 0',
        marginTop: 15,
    },

    numberPickerGrid: {
        marginTop: 10,
        '& .MuiFormControl-root': {
            flex: 'unset',
        },
    },

    destinationInput: {
        width: 475,
    },

    additionalMarginTop: {
        marginTop: 10,
    },

    separator: {
        height: 1,
        width: 944,
        backgroundColor: Colors.Border,
        marginLeft: -16,
        marginTop: 30,
    },
    playFileInput: {
        maxWidth: 294,
        width: 294,
    },
    itemsContainer: {
        paddingBottom: '17px',
        display: 'flex',
        flexDirection: 'column',
        borderBottom: `1px solid ${Colors.Border}`,
    },
}));

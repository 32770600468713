import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    widgetMainContainer: {
        width: '100%',
        height: '100%',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    chartMainContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    chartContainer: {
        position: 'relative',
        width: '100%',
    },
    chartContainerEmptyData: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
    },
    chart: {
        position: 'absolute',
        top: 0,
    },
    chartPlaceholder: {
        height: 70,
        borderRadius: '50%',
        border: `transparent solid 20px`,
    },
    chartEmptyBackground: {
        borderColor: '#D9D9D9',
        aspectRatio: '1 !important',
    },
    chartLoadingBackground: {
        borderColor: 'transparent',
    },
    sumValue: {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: 'calc(50% - 15px)',
    },
    sumText: {
        fontSize: 26,
        zIndex: -1
    },
    loadingLabel: {
        backgroundColor: 'transparent',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        top: '50%',
        color: Colors.Gray5,
        size: 14,
        fontWeight: 400,
    },
    legendContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        width: 'calc(100% - 16px)',
        justifyContent: 'center',
        minHeight: 82,
    },
    legendRow: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center'
    },
    legendSubRow: {
        display: 'flex', 
        flexDirection: 'column', 
        width: 'fit-content', 
        justifyContent: 'flex-start'
    },
    secondColumn: {
        marginLeft: 32
    },
    lastItem: {
        '& > :first-child': {
            marginTop: 0,
        }
    },
    overflowStyleForListWidgetType: {
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    listViewMainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
    },
    listViewMainContainerNoData: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    listViewItem: {
        display: 'flex',
        flexDirection: 'row',
        width: 293,
        height: 57,
        marginLeft: 24
    },
    listViewIconContainer: {
        marginRight: 14,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 20,
        marginTop: 8.5
    },
    listViewIconText: {
        height: 'fit-content',
        width: '100%',
        fontWeight: 700,
        fontSize: 14,
        color: Colors.Text,
        marginTop: 'calc(50% - 14px)',
    },
    listViewRighContainer: {
        width: 'calc(100% - 54px)',
        height: 49,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
    },
    listViewRighHeader: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        lineHeight: '19px'
    },
    listViewRighDetails: {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '16px'
    },
    statusDetails: {
        fontSize: 14,
        color: Colors.Gray5,
        lineHeight: '16px'
    },
    callDuration: {
        marginLeft: 10,
        lineHeight: '16px',
        fontWeight: 400,
        fontSize: 14,
        color: Colors.Gray5
    },
    listViewIconContainerUnregistered: {
        background: Colors.Secondary11,
        opacity: 0.6,
    },
    listViewRighHeaderUnregistered: {
        color: Colors.Text2,
        opacity: 0.6,
    },
    statusDetailsUnregistered: {
        color: Colors.Gray5,
        fontWeight: 400,
    },
    listViewIconContaineridle: {
        background: 'rgba(117, 185, 67, 0.2)'
    },
    listViewRighHeaderIdle: {
        color: Colors.Text2,
    },
    statusDetailsIdle: {
        color: Colors.Support,
        fontWeight: 700
    },
    listViewIconContainerOnCall: {
        background: 'rgba(196, 2, 2, 0.12)'
    },
    listViewRighHeaderOnCall: {
        color: Colors.Text2
    },
    statusDetailsOnCall: {
        fontWeight: 700,
        color: '#C40202',
    },
    listViewIconContainerOnHold: {
        background: 'rgba(245, 132, 31, 0.2)',
    },
    listViewRighHeaderOnHold: {
        color: Colors.Text2,
    },
    statusDetailsOnHold: {
        fontWeight: 700,
        color: Colors.Primary2
    },
    listViewIconContainerRinging: {
        background: 'rgba(117, 185, 67, 0.2)'
    },
    listViewRighHeaderRinging: {
        color: Colors.Text2
    },
    statusDetailsRinging: {
        color: Colors.Support,
        fontWeight: 700
    },
    listViewIconContainerAvailable: {
        background: 'rgba(117, 185, 67, 0.2)'
    },
    listViewRighHeaderAvailable: {
        color: Colors.Text2
    },
    statusDetailsAvailable: {
        color: Colors.Support,
        fontWeight: 700
    },
    listViewIconContainerLoggedOut: {
        background: 'rgba(245, 132, 31, 0.2)',
    },
    listViewRighHeaderLoggedOut: {
        color: Colors.Text2,
    },
    statusDetailsLoggedOut: {
        fontWeight: 700,
        color: Colors.Primary2
    }
}));
import * as React from 'react';
import {useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { ReduxState } from '../../../../store/types/store';
import { NewPolicyDialogProps, NewPolicyProps, newPolicyValidationSchema, useStyles } from './NewPolicyDialog.utils';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import CustomizedTextField from '../../../TextField/TextField';
import Loader from '../../../Loader/Loader';
import { actions } from '../../../../store';
import { GetCallProcessingPolicyListEntity } from '../../../../store/actions/extensions/payloads';

const NewPolicyDialog: React.FC<NewPolicyDialogProps> = ({
        isOpen,
        editObject,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );
    
    const policies = useSelector<ReduxState, GetCallProcessingPolicyListEntity[]>((state) => 
        state.extensions.callProcessingPolicyList || []);

    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.extensions.isCreatingPolicy || state.extensions.isEditingPolicy || false);
    
    const initialValues: NewPolicyProps = useMemo(
        () => ({
            name: editObject?.name || '',
            i_customer: i_customer,
        } as NewPolicyProps),
        [editObject, i_customer],
    );

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        handleChange,
        errors,
        dirty
    } = useFormik<NewPolicyProps>({
        initialValues,
        onSubmit: (form) => {
            if(editObject) {
                dispatch(actions.editPolicy.request({
                    object: {
                        ...editObject,
                        ...form
                    },
                    successToastText: t('screens:callSettings.editPolicySuccess'),
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            } else {
                dispatch(actions.addNewPolicy.request({
                    object: form,
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            }
        },
        validationSchema: newPolicyValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    const isNameNotUnique: boolean = useMemo(() => {
        const found = policies?.find(e => e.name?.toLocaleUpperCase()?.trim() === values.name?.toLocaleUpperCase()?.trim());
        let notUnique = (found !== undefined);
        if(notUnique && editObject !== undefined && found !== undefined) {
            if(found.i_cp_policy === editObject.i_cp_policy) {
                notUnique = false;
            }
        }
        if(notUnique && errors.name === undefined) {
            setFieldError('name', t('errors:common.thisNameIsAlreadyUsed'));
        } else if(!notUnique && errors.name !== undefined) {
            setFieldError('name', undefined);
        }
        return notUnique;
    }, [policies, values.name, errors]);
    
    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'policies-add-new-modal'}
                header={editObject
                    ? t('screens:callSettings.editPolicyDialogTitle')
                    : t('screens:callSettings.addNewPolicyDialogTitle')
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            !(values.name?.length) ||
                            isLoading ||
                            isNameNotUnique
                        }
                        onClick={handleSubmit}
                        dataTestId={'add-new-policy-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-policy-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:callSettings.policyName')}
                                value={values.name}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                onChange={handleChange}
                                maxLength={255}
                                dataQa="name-field"
                            />
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewPolicyDialog;
import {
    TransactionFiltersFormType,
    TransactionFiltersProps,
} from '../TransactionFilters/TransactionFilters';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            paddingTop: 19,
            width: 1066,
            backgroundColor: Colors.SmokeBackground,
            overflowX: 'hidden',
        },
    },

    '@media(max-width: 1599px)': {
        modalContainer: {
            '& #transaction-filters-modal-container p.MuiFormHelperText-root': {
                fontSize: '10px'
            }
        }
    },

    '@media(min-width: 1600px)': {
        modalContainer: {
            '& #transaction-filters-modal-container div.MuiFormControl-root': {
                maxWidth: 300
            }
        }
    },
    filters: {
        paddingBottom: 25,
    },
    downloadButton: {
        maxWidth: 181,
        '& .MuiButton-label': {
            color: Colors.Secondary1,
        },

        '& svg > path': {
            fill: Colors.Secondary1,
        },
        '&:hover': {
            backgroundColor: Colors.Secondary6,
        },
        fontWeight: 700,
        borderRadius: '4px',
        padding: '6px 12px',
        textTransform: 'none',
        fontSize: 16,
        height: 36,
    },
}));

export interface TransactionDialogProps extends TransactionFiltersProps {
    serviceName: string;
    isOpen: boolean;
    toggleVisibility: () => void;
    i_service?: number;
    i_service_type?: number;
    dataQa?: string;
    index?: number;
    currency: string;
    initialPageSize: number;
    unit?: string;
    ratio?: number;
    onClickCsvButton: (
        filters: TransactionFiltersFormType,
        timezoneOffset: number,
    ) => void;
    timezoneOffset?: number;
}

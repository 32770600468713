import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ChevronLeft} from '@material-ui/icons';
import CustomizedIconButton from '../../../components/IconButton/IconButton';
import StatusItem from '../../../components/Extensions/StatusItem';
import ExtensionDIDNumber from '../../../components/Extensions/ExtensionDIDNumber';
import Switch from '../../../components/Switch/Switch';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {actions} from '../../../store';
import {YesNo} from '../../../store/types/CallScreening';
import {Colors} from '../../../styles/Colors';
import history from '../../../history';
import {Routes} from '../../../routes/routes';
import classNames from 'classnames';
import OverflowTooltip from '../../../components/OverflowTooltip/OverflowTooltip';
import {useSipTrunkDetailsForm} from "../../../hooks/useSipTrunkDetailsForm";
import MaxCallsComponent from "./MaxCallsComponent";

const SipTrunkDetailsHeader: React.VFC = () => {
    const classes = useStyles();

    const {
        name,
        extension_id,
        is_blocked,
        sip_status,
        sip_agent,
        didNumbers,
        i_account,
        calls,
        bill_status
    } = useSipTrunkDetailsForm();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleChangeStatusClick = useCallback(() => {
        if (i_account && is_blocked !== undefined) {
            dispatch(
                actions.setSipTrunkStatus.request({
                    account_info: {
                        i_account: i_account.toString(),
                        blocked: is_blocked ? YesNo.No : YesNo.Yes,
                    },
                }),
            );
        }
    }, [is_blocked, i_account]);

    const handleBackClick = useCallback(() => {
        history.push(Routes.SipTrunks);
    }, []);

    return (
        <div className={classes.header}>
            <div className={classes.top}>
                <div
                    className={classes.leftContainer}
                    data-testid="sip-trunk-details-title"
                    data-qa="sip-trunk-details-title"
                >
                    <CustomizedIconButton
                        className={classes.backButton}
                        onClick={handleBackClick}
                        skipPermission
                    >
                        <ChevronLeft/>
                    </CustomizedIconButton>
                    {t('screens:sipTrunks.detailsHeader')}

                    <span className={classes.extensionId}>{extension_id}</span>

                    {name && (
                        <OverflowTooltip
                            tooltip={name}
                            text={name}
                            copy={false}
                            classes={{text: classes.nameText}}
                        />
                    )}
                </div>

                <div className={classes.extensionRightContainer}>
                    <StatusItem
                        blocked={is_blocked ? 'Y' : 'N'}
                        sip_status={sip_status || 0}
                        device={sip_agent}
                        hideRefresh
                        billStatus={bill_status}
                    />

                    {!!didNumbers?.length && (
                        <ExtensionDIDNumber
                            extNumbers={didNumbers}
                            dataQa={'sip-details-did-numbers'}
                            dataTestId={'sip-details-did-numbers'}
                            className={classes.didNumbers}
                        />
                    )}

                    <div
                        className={classNames(
                            classes.statusSwitcher,
                            didNumbers?.length === 1 &&
                            classes.onlyOneDidNumber,
                        )}
                    >
                        <Switch
                            checked={!is_blocked}
                            onChange={handleChangeStatusClick}
                            dataQa={'sip-details-status-switcher'}
                            dataTestId={'sip-details-status-switcher'}
                            className={classes.switch}
                        />
                        <span
                            data-qa={'sip-trunk-details-status'}
                            data-testid={'sip-details-status'}
                        >
                            {!is_blocked
                                ? t('extensions.enabled')
                                : t('extensions.disabled')}
                        </span>
                    </div>
                </div>
            </div>
            <MaxCallsComponent calls={calls}/>
        </div>
    );
};

export default SipTrunkDetailsHeader;

const useStyles = makeStyles(() => ({
    separator: {
        marginLeft: 8,
        marginRight: 8
    },
    maxCallsDiv: {
        fontSize: 12,
        lineHeight: 'px',
        color: Colors.Black,
        marginLeft: 54
    },
    maxCallsText: {
        fontWeight: 700,
        marginRight: 8
    },
    maxCallsItem: {
        fontWeight: 400,
    },
    header: {
        backgroundColor: Colors.White,
        display: 'flex',
        flexDirection: 'column',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.Text,
        fontSize: 32,
        lineHeight: '36px',
        fontWeight: 300,
        maxWidth: 'calc(100% - 410px)',
        marginRight: 30,
    },
    extensionRightContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    extensionId: {
        fontWeight: 500,
        margin: '0 16px',
    },
    backButton: {
        marginRight: 10,
    },
    statusSwitcher: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 33,
        fontWeight: 500,
        color: Colors.Text,
    },
    switch: {
        marginBottom: -10,
    },
    didNumbers: {
        margin: '0 33px',
    },
    onlyOneDidNumber: {
        marginLeft: 0,
    },
    nameText: {
        height: 38,
    }
}));

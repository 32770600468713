import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import BarChart, { BarChartProps } from '../Charts/BarChart';
import { useTranslation } from 'react-i18next';
import AnimatedNumberText from '../AnimatedNumber/AnimatedNumber';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';

const useStyles = makeStyles(() => ({
    mainContainer: {
        borderRadius: 4,
        border: `1px solid ${Colors.Border}`,
        padding: 22,
        flex: 1,
        marginBottom: 24
    },
    labelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    label: {
        color: Colors.Text2,
        fontWeight: 500,
        fontSize: 20,
    },
    subLabel: {
        color: Colors.Gray12,
        fontWeight: 500,
        fontSize: 14,
        marginLeft: 48,
    },
    valueContainer: {
        marginLeft: 30,
        display: 'inline',
        color: Colors.Gray3,
    },
    rightContainer: {
        display: 'flex',
    },
}));

export type CallsSectionProps = {
    callsNow?: number;
    callsToday?: number;
    callsWeek?: number;
} & BarChartProps;

const CallsSection: React.VFC<CallsSectionProps> = ({
    data,
    isLoading,
    callsNow,
    callsToday,
    callsWeek,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.labelContainer}>
                <span className={classes.label}>
                    {t('screens:dashboard.calls')}
                </span>
                <div className={classes.rightContainer}>
                    <span className={classes.subLabel}>
                        {t('screens:dashboard.now')}
                    </span>

                    <div className={classes.valueContainer}>
                        {callsNow !== undefined && !isLoading ? (
                            <AnimatedNumberText
                                value={callsNow}
                                dataTestId="calls-now"
                                dataQa="calls-now"
                            />
                        ) : (
                            <span>—</span>
                        )}
                    </div>

                    <span className={classes.subLabel}>
                        {t('screens:dashboard.today')}
                    </span>

                    <div className={classes.valueContainer}>
                        {callsToday !== undefined && !isLoading ? (
                            <AnimatedNumberText
                                value={callsToday}
                                dataTestId="calls-today"
                                dataQa="calls-today"
                            />
                        ) : (
                            <span>—</span>
                        )}
                    </div>

                    <span className={classes.subLabel}>
                        {t('screens:dashboard.week')}
                    </span>

                    <div className={classes.valueContainer}>
                        {callsWeek !== undefined && !isLoading ? (
                            <AnimatedNumberText
                                value={callsWeek}
                                dataTestId="calls-week"
                                dataQa="calls-week"
                            />
                        ) : (
                            <span>—</span>
                        )}
                    </div>
                </div>
            </div>
            <BarChart data={data} isLoading={isLoading} />
        </div>
    );
};

export default CallsSection;

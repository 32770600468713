import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../store/types/Payment';
import { AutoPaymentsForm } from '../views/Billing/PaymentMethod/PaymentMethod.utils';

export const useBillingFormData = () => {
    const autoPaymentInfo = useSelector<
        ReduxState,
        AutoPaymentInfo | undefined
    >((state) => state.billing.autoPaymentInfo);

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const { customerInfo } = useSelector((state: ReduxState) => state.billing);

    const currentMethod = useMemo(
        () =>
            ownerPaymentsMethods?.find(
                (v) => v.i_payment_method === paymentMethod?.i_payment_method,
            ),
        [ownerPaymentsMethods],
    );

    const initialValues = useMemo((): AutoPaymentsForm => {
        return {
            autoPayInvoice: customerInfo?.bp_charge_cc === 'Y',
            autoPayWithValues:
                autoPaymentInfo?.pay_amount !== undefined &&
                autoPaymentInfo?.frozen === 'N',
            amount: autoPaymentInfo?.pay_amount?.toString() || '',
            balance: autoPaymentInfo?.balance_threshold?.toString() || '',
        };
    }, [autoPaymentInfo, customerInfo]);

    return {
        initialValues,
        data: {
            autoPaymentInfo,
            currentMethod,
        },
    };
};

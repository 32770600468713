import React from 'react';
import { TransactionTableInterface } from '../utils';
import DataGrid from '../../DataGrid/DataGrid';
import { PaginationMode } from '../../DataGrid/types';
import {
    iPTVTableRowInterface,
    generateColumns,
    useStyles,
} from './iPTV.utils';
import { iPTVEntityTransformer } from '../../../utils/transformers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const IPTV: React.VFC<TransactionTableInterface> = ({
    currency,
    data,
    onPageChange,
    onPageSizeChange,
    initialPageSize,
    loading,
    rowCount,
    userDateTimeFormat,
    className,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const columns = generateColumns(t, currency, userDateTimeFormat);

    const items: iPTVTableRowInterface[] = data.map(iPTVEntityTransformer);

    return (
        <DataGrid<iPTVTableRowInterface>
            data={items}
            dataQa={'iPTV-table'}
            loading={loading}
            columns={columns}
            rowCount={rowCount}
            paginationMode={PaginationMode.Server}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            initialPageSize={initialPageSize}
            classes={{
                header: classes.header,
                tableContainer: classNames(classes.tableContent, className),
            }}
        />
    );
};

export default IPTV;

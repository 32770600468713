import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { ChangeDirtyStateDetailsTab } from '../../views/Extensions/ExtensionDetails/utils';
import { AssignedPortInfo, UATypeInfo } from '../../store/types/Devices';
import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import classNames from 'classnames';
import CustomizedIconButton from '../IconButton/IconButton';
import { Delete, Edit } from '@material-ui/icons';
import PermissionProvider from '../PermissionProvider/PermissionProvider';

export const containerWidth = 978;
export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: 16,
        width: containerWidth,
        display: 'flex',
        flexDirection: 'column',
    },

    releaseButton: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 110,
        '& .MuiButton-label': {
            fontSize: 16,
            paddingLeft: 0,
            paddingRight: 0,
            color: Colors.Gray5 + '!important',
        },

        '& svg': {
            '& path': {
                fill: Colors.Gray5,
                fillOpacity: 1,
            },
        },
    },

    changeButton: {
        width: 109,
        height: 40,
        marginLeft: 30,
    },

    assignDeviceButtonContainer: {
        '& > :first-child': {
            width: 'fit-content'
        },
    },

    assignButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
        },
        margin: '16px 0 31px 8px',
        width: 'auto',
        height: 40,
        paddingLeft: 13,
    },

    actionButtons: {
        marginTop: 39,
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 8,
    },
    sipInfo: {
        width: containerWidth,
    },
    titleContainer: {
        display: 'flex',

        '& h5': {
            marginLeft: 3,
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 17,
            marginTop: 0,
        },
    },
    iconContainer: {
        marginLeft: 16,
        marginTop: 2
    },
    tableContainer: {
        width: '100%',
        marginTop: 0,
        background: 'transparent',
        border: 'none',
        overflowX: 'hidden',

        '& table': {
            background: 'transparent',
            border: 'none',
            overflowX: 'hidden',

            '& thead': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    '& th': {
                        paddingBottom: 4,
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: '12px',
                        color: 'rgba(0, 0, 0, 0.54)',
                    }
                }
            },

            '& tbody': {
                background: 'transparent',
                border: 'none',

                '& tr': {
                    background: 'transparent',
                    border: 'none',

                    '& td': {
                        paddingTop: 14,
                        paddingBottom: 14
                    }
                }
            }
        }
    },
    releaseContainer: {
        '& .MuiDialogContent-root': {
            width: 233,
            minWidth: 'unset',
            padding: 24,
            paddingBottom: 27,
            background: Colors.White,
        },
    },

    marginTop: {
        marginTop: 7,
    },
    field: {
        '& .MuiInputBase-root ': {
            maxWidth: 440,
        },
    },
    container: {
        '& .MuiGrid-root': {
            maxWidth: 440,
            margin: '16px',
            padding: 0,
        },
    },
    valueSpan: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Text,
        maxHeight: '44px !important',
    },
}));

export interface DeviceProps {
    sharedLine: boolean;
    iAccount: number;
    register: boolean;
    i_c_ext: number;
    handleDirtyChange?: ChangeDirtyStateDetailsTab;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    extensionNumber?: string;
    assignPermission?: string[];
    releasePermission?: string[];
    changePermission?: string[];
}

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    typesList: UATypeInfo[],
    releasePermission?: string[],
    changePermission?: string[],
    onEdit?: (object: AssignedPortInfo) => void,
    onDelete?: (object: AssignedPortInfo) => void
): Column<AssignedPortInfo>[] => {

    const drawValueSpan = (text?: string, customClass?: string) => {
        return (
            <div style={{overflow: 'hidden'}} data-testid={'assigned-ports-list-value-span'}>
                <OverflowTooltip
                    tooltip={text}
                    text={text}
                    classes={{text: classNames(classes.valueSpan, customClass)}}
                    copy={false}
                />
            </div>
        );
    }

    const columns: Column<AssignedPortInfo>[] = [
        {
            Header: t<string>('screens:devices.deviceName'),
            accessor: 'device_name',
            width: 2,
            Cell: function Cell(params) {
                const obj = (params.row.original as AssignedPortInfo);
                const name = obj?.ua_info?.name ?? obj?.device_name;
                return drawValueSpan(name);
            },
        },
        {
            Header: t<string>('screens:devices.macAddress'),
            accessor: 'mac',
            width: 1.5,
            Cell: function Cell(params) {
                const name = (params.row.original as AssignedPortInfo).mac;
                return drawValueSpan(name);
            },
        },
        {
            Header: t<string>('screens:devices.model'),
            accessor: 'i_ua_type',
            width: 2,
            Cell: function Cell(params) {
                const name = (params.row.original as AssignedPortInfo).device_name;
                return drawValueSpan(name);
            },
        },
        {
            Header: t<string>('screens:devices.port'),
            accessor: 'port',
            width: 1,
            Cell: function Cell(params) {
                const port = (params.row.original as AssignedPortInfo).port;
                return drawValueSpan(port + '.');
            },
        },
        {
            Header: '',
            accessor: 'is_multiservice',
            width: 1,
            Cell: function Cell(params) {
                const original = params.row.original;
                return (<>
                    <PermissionProvider permission={changePermission || []}>
                        <CustomizedIconButton
                            onClick={() => {
                                onEdit?.(original);
                            }}
                            dataTestId="edit-item-button"
                            tooltipText={t<string>('common:edit')}
                        >
                            <Edit htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    </PermissionProvider>
                    <PermissionProvider permission={releasePermission || []}>
                        <CustomizedIconButton
                            onClick={() => {
                                onDelete?.(original);
                            }}
                            dataTestId="remove-item-button"
                            tooltipText={t<string>('screens:devices.release')}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    </PermissionProvider>
                </>);
            },
        }
    ];

    return columns;
};
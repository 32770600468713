import { Menu, MenuItem, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import CustomizedTextField from '../TextField/TextField';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import NestedMenuItem from './NestedMenuItem';
import { Colors } from '../../styles/Colors';
import CustomizedIconButton from '../IconButton/IconButton';
import { ArrowDropDown } from '@material-ui/icons';

export type MultiLevelSelectItem = {
    value: string | number;
    label: string;
    nestedItems?: MultiLevelSelectItem[];
    disabled?: boolean;
};

export type MultiLevelSelectFieldProps = {
    onChange?: (value: string | number, parentValue?: string | number) => void;
    items?: MultiLevelSelectItem[];
    value?: string;
    label?: string;
    className?: string;
    required?: boolean;
    readOnly?: boolean;
};

const useStyles = makeStyles(() => ({
    input: {
        pointerEvents: 'none',
        flex: 1,
        position: 'relative',
        display: 'flex',
    },
    container: {
        display: 'flex',
        marginTop: 15,
        '&:hover .MuiInputBase-root:before': {
            borderBottom: `2px solid ${Colors.Text}`,
        },
    },
    containerFocused: {
        '& .MuiInputBase-root:before': {
            borderBottom: `2px solid ${Colors.Primary}!important`,
        },
        '& .MuiInputLabel-root': {
            transform: 'translate(0, 13px) scale(0.75)',
            color: Colors.Primary,
        },
    },
    menu: {
        '& .MuiButtonBase-root': {
            height: 47,
            fontSize: 16,
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    dropdownIcon: {
        position: 'absolute',
        right: 0,
        top: 6,
    },
    rotatedIcon: {
        transform: 'rotate(180deg)',
    },
}));

export const MultiLevelSelectField: React.VFC<MultiLevelSelectFieldProps> = ({
    onChange,
    items,
    value,
    label,
    className,
    required,
    readOnly = false
}) => {
    const classes = useStyles();

    const [menuPosition, setMenuPosition] = useState<any>(null);
    const [menuWidth, setMenuWidth] = useState<any>(0);
    const inputRef = useRef<typeof Typography>(null);

    const handleRightClick = (event: any) => {
        if (menuPosition) {
            return;
        }

        event.preventDefault();
        
        if(readOnly) {
            return;
        }

        //@ts-ignore
        const params = inputRef?.current?.getBoundingClientRect();

        setMenuPosition({
            top: params?.top + 62,
            left: params?.left,
        });
        setMenuWidth(params?.width);
    };

    const handleItemClick = (
        parentValue: string | number,
        childValue?: string | number,
    ) => {
        onChange?.(parentValue, childValue);
        setMenuPosition(null);
    };

    return (
        <div
            onClick={handleRightClick}
            className={classNames(
                classes.container,
                className,
                !!menuPosition && classes.containerFocused,
            )}
        >
            <div className={classes.input}>
                <CustomizedTextField
                    id="multilevel-dropdown"
                    inputRef={inputRef}
                    label={label}
                    value={value || ''}
                    className={classes.input}
                    required={required}
                    readOnly={readOnly}
                />

                <CustomizedIconButton
                    className={classNames(
                        classes.dropdownIcon,
                        !!menuPosition && classes.rotatedIcon,
                    )}
                >
                    <ArrowDropDown htmlColor={Colors.Gray5} />
                </CustomizedIconButton>
            </div>

            <Menu
                open={!!menuPosition}
                onClose={() => {
                    setMenuPosition(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
                className={classes.menu}
                elevation={1}
                PaperProps={{
                    style: {
                        width: menuWidth,
                    },
                }}
                data-testid="multidropdown-menu"
            >
                {items?.map((v, index) => {
                    if (v.nestedItems?.length) {
                        return (
                            <NestedMenuItem
                                label={v.label}
                                parentMenuOpen={!!menuPosition}
                                key={`item_${index}`}
                                MenuProps={{
                                    className: classes.menu,
                                    //@ts-ignore
                                    'data-testid': 'multidropdown-nested-menu',
                                }}
                            >
                                {v.nestedItems?.map((w, wIndex) => (
                                    <MenuItem
                                        key={`item_${index}_${wIndex}`}
                                        onClick={() =>
                                            handleItemClick(v.value, w.value)
                                        }
                                        disabled={v.disabled}
                                    >
                                        {w.label}
                                    </MenuItem>
                                ))}
                            </NestedMenuItem>
                        );
                    } else {
                        return (
                            <MenuItem
                                onClick={() => handleItemClick(v.value)}
                                key={`item_${index}`}
                                disabled={v.disabled}
                            >
                                {v.label}
                            </MenuItem>
                        );
                    }
                })}
            </Menu>
        </div>
    );
};

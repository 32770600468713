import React, {useState} from "react";
import {useSelector} from "react-redux";
import {ReduxState} from "../../../../store/types";
import DataGrid from "../../../DataGrid/DataGrid";
import {useTranslation} from 'react-i18next';
import CreationButton from "../../../CreationButton/CreationButton";
import {ApiFile} from "../../../../store/types/ApiFile";
import {NewResponseMessagesDialogProps} from "./NewResponseMessagesDialog.utils";
import NewResponseMessagesDialog from "./NewResponseMessagesDialog";
import {generateColumns, ResponseMessagesTabProps, useStyles} from "./ResponseMessagesTab.utils";

const ResponseMessagesTab: React.FC<ResponseMessagesTabProps> = ({
    onDelete
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const policies = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingPolicyList || [],
    );
    
    const files = useSelector(
        (state: ReduxState) => state.callSettings?.customerResponseMessages?.file_list || [],
    );

    const [showAddEditFileDialog, setShowAddEditFileDialog] = useState<NewResponseMessagesDialogProps>({
        isOpen: false
    });

    const columns = generateColumns(
        t,
        classes,
        policies,
        (object: ApiFile) => {
            setShowAddEditFileDialog({
                isOpen: true,
                editObject: object,
                clickOnClose: () => {
                    setShowAddEditFileDialog({
                        isOpen: false,
                        editObject: undefined,
                    });
                }
            });
        },
        onDelete
    );

    return (
        <>
            <DataGrid<ApiFile>
                classes={{
                    tableContainer: classes.tabsContainer,
                }}
                columns={columns}
                data={files}
                rowCount={files?.length || 0}
                pageSizeOptions={[files?.length || 0]}
                initialPageSize={Number.MAX_VALUE}
                centeredRows
                hideFooter
                getItemId={(r) => r.id}
            />
            <CreationButton
                data-qa="add-api-file-button"
                onClick={() => {
                    setShowAddEditFileDialog({
                        isOpen: true,
                        editObject: undefined,
                        clickOnClose: () => {
                            setShowAddEditFileDialog({
                                isOpen: false,
                                editObject: undefined,
                            });
                        }
                    });
                }}
                className={classes.addApiFileButton}
                title={t('screens:callSettings.addResponseMessage')}
            />
            <NewResponseMessagesDialog
                {...showAddEditFileDialog}
            />
        </>
    )
}

export default ResponseMessagesTab;
import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    MakePaymentDialogProps,
    MakePaymentFormType,
    newPaymentValidationSchemaHelper,
    useStyles,
} from './MakePaymentDialog.utils';
import {
    convertCardValidatorTypeToPaymentType,
    paymentMethodFormDefaultValues,
    getCardType
} from '../PaymentMethodForm/PaymentMethodForm.utils';
import DialogContainer, {
    DialogButton,
} from '../../../AlertDialog/DialogContainer';
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm';
import { PaymentMethodType } from '../../../../store/types/Billing';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';
import {
    OwnerPaymentMethod,
    PaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import {
    CustomerInfoDetails,
    GlobalCurrencyInfo,
} from '../../../../store/types/CustomerInfo';
import { Subdivision } from '../../../../store/types/Subdivision';
import { actions } from '../../../../store';
import Loader from '../../../Loader/Loader';
import PaymentAmountForm from '../PaymentAmountForm/PaymentAmountForm';
import PaymentMethodDetails from '../../../Payments/PaymentMethodDetails/PaymentMethodDetails';
import CreationButton from '../../../CreationButton/CreationButton';
import CustomizedTextField from '../../../TextField/TextField';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import { BillingStateType } from '../../../../store/reducers/billing/reducer';
import _ from 'lodash';
import CardValidator from 'card-validator';
import CustomizedTooltip from '../../../Tooltip/Tooltip';

const MakePaymentDialog: React.VFC<MakePaymentDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const customerInfo = useSelector<
        ReduxState,
        CustomerInfoDetails | undefined
    >((state) => state.billing.customerInfo);

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);

    const { isMakeTransactionLoading } = useSelector<
        ReduxState,
        BillingStateType
    >((state) => state.billing);

    const currency = useSelector<ReduxState, GlobalCurrencyInfo | undefined>(
        (state) => state.generic.globalCurrency,
    );

    const subdivisionsList = useSelector<ReduxState, Subdivision[]>(
        (state) => state.generic.subdivisionsList,
    );

    const paymentsMethods = useMemo(() => {
        const paymentMethods = [];

        if (
            ownerPaymentsMethods?.find(
                (v) => v.payment_method === PaymentMethod.Echeck,
            )
        ) {
            paymentMethods.push(PaymentMethodType.BankAccount);
        }

        if (
            ownerPaymentsMethods?.find(
                (v) => v.payment_method !== PaymentMethod.Echeck,
            )
        ) {
            paymentMethods.push(PaymentMethodType.CreditCard);
        }
        return paymentMethods;
    }, [ownerPaymentsMethods]);

    const currentMethod = useMemo(
        () =>
            ownerPaymentsMethods?.find(
                (v) => v.i_payment_method === paymentMethod?.i_payment_method,
            ),
        [ownerPaymentsMethods],
    );

    const initialValues = useMemo(() => {
        return {
            ...paymentMethodFormDefaultValues,
            paymentType: paymentsMethods?.includes(PaymentMethodType.CreditCard)
                ? PaymentMethodType.CreditCard
                : PaymentMethodType.BankAccount,
            address: customerInfo?.baddr1 || '',
            state:
                subdivisionsList.find((v) => v.name === customerInfo?.state)
                    ?.i_country_subdivision || null,
            country: customerInfo?.country || '',
            city: customerInfo?.city || '',
            postalCode: customerInfo?.zip || '',
            billingPhone: customerInfo?.phone1 || '',
            amount: currentMethod?.min_allowed_payment.toString() || '0',
            isDefaultPaymentMethod: !!currentMethod,
            setAsDefaultPaymentMethod: false,
        };
    }, [ownerPaymentsMethods, subdivisionsList]);

    const getCurrentOwnerPaymentMethod = (
        type: PaymentMethodType,
        number: string,
    ) => {
        if (type === PaymentMethodType.BankAccount) {
            return ownerPaymentsMethods?.find(
                (v) => v.payment_method === PaymentMethod.BankAccount,
            );
        } else {
            const validatorCardType = getCardType(number);
            if (validatorCardType) {
                const cardType = convertCardValidatorTypeToPaymentType(
                    validatorCardType
                );

                return ownerPaymentsMethods?.find(
                    (v) => v.payment_method === cardType,
                );
            }

            return undefined;
        }
    };

    const isSelectedMethodBlockedAsSaveDefault = (
        type: PaymentMethodType,
        number: string,
    ) => {
        return (
            getCurrentOwnerPaymentMethod(type, number)?.remote_cc_storage ===
                'Y' && !customerInfo?.email
        );
    };

    const isPaymentMethodAvailableToSave = (
        isDirty: boolean,
        values: MakePaymentFormType,
    ) => {
        if (values.isDefaultPaymentMethod) {
            if (paymentMethod?.csc_required === 1 && !values.cvv) {
                return false;
            }
        } else {
            if (
                !values.address ||
                !values.country ||
                !values.city ||
                !values.billingPhone ||
                !values.postalCode
            ) {
                return false;
            }

            if (values.paymentType === PaymentMethodType.CreditCard) {
                return (
                    !!values.cardholderName &&
                    !!values.cardNumber &&
                    !!values.expirationDate &&
                    !!values.cvv
                );
            } else {
                return (
                    !!values.accountName &&
                    !!values.accountNumber &&
                    !!values.bankRoutingNumber
                );
            }
        }

        return true;
    };

    const onSubmit = (form: MakePaymentFormType) => {
        dispatch(actions.makePayment.request(form));
    };

    return (
        <Formik<MakePaymentFormType>
            initialValues={initialValues}
            validate={(values) => {
                const schema = newPaymentValidationSchemaHelper(
                    values.isDefaultPaymentMethod,
                    ownerPaymentsMethods || [],
                    paymentMethod,
                    currency?.iso_4217,
                );

                try {
                    schema.validateSync(values, {
                        abortEarly: false,
                        context: values,
                    });
                } catch (error: any) {
                    return error.inner.reduce(
                        (errors: any, currentError: any) => {
                            errors = _.set(
                                errors,
                                currentError.path,
                                currentError.message,
                            );
                            return errors;
                        },
                        {},
                    );
                }

                return {};
            }}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
        >
            {({
                submitForm,
                resetForm,
                dirty,
                values,
                setFieldError,
                setFieldValue,
                handleChange,
                errors,
                setErrors,
            }) => {
                const disabledSaveDefault = isSelectedMethodBlockedAsSaveDefault(
                    values.paymentType,
                    values.cardNumber,
                );

                return (
                    <DialogContainer
                        isOpen={isOpen}
                        dataQa="make-payment-dialog"
                        header={t('screens:billing.makeAPayment')}
                        className={classes.modalContainer}
                        disableEnforceFocus
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={() => {
                                    toggleVisibility?.();
                                    setTimeout(() => {
                                        resetForm();
                                    }, 300);
                                }}
                            />,
                            <DialogButton
                                key="save"
                                label={t('screens:billing.pay')}
                                onClick={submitForm}
                                disabled={
                                    !isPaymentMethodAvailableToSave(
                                        dirty,
                                        values,
                                    )
                                }
                                primary
                            />,
                        ]}
                    >
                        <PaymentAmountForm />

                        {(values.isDefaultPaymentMethod ||
                            paymentsMethods.length === 2) && (
                            <div className={classes.sectionHeader}>
                                {t('screens:billing.payWith')}
                            </div>
                        )}

                        {paymentMethod && values.isDefaultPaymentMethod ? (
                            <div className={classes.defaultPaymentContainer}>
                                <div
                                    className={
                                        classes.defaultPaymentInsideContainer
                                    }
                                >
                                    <PaymentMethodDetails
                                        method={paymentMethod}
                                    />

                                    {paymentMethod.csc_required === 1 && (
                                        <CustomizedTextField
                                            id="cvv"
                                            label={t('screens:billing.cvv')}
                                            value={values.cvv}
                                            helperText={errors.cvv}
                                            setFieldError={setFieldError}
                                            onChange={handleChange}
                                            required
                                            iconPosition="end"
                                            icon={
                                                <IconWithTooltip
                                                    dataQa="cvv-tooltip"
                                                    tooltipText={t(
                                                        'tooltips:billing.cvv',
                                                    )}
                                                />
                                            }
                                            maxLength={4}
                                            className={classes.cvvTextfield}
                                        />
                                    )}
                                </div>
                                <CreationButton
                                    title={t('screens:billing.useOtherMethod')}
                                    className={classes.otherMethodButton}
                                    onClick={() => {
                                        setErrors({});
                                        setFieldValue(
                                            'isDefaultPaymentMethod',
                                            false,
                                        );
                                    }}
                                    withPlus={false}
                                />
                            </div>
                        ) : (
                            <div className={classes.payForm}>
                                <div>
                                    <PaymentMethodForm
                                        paymentMethodsTypes={paymentsMethods}
                                    />
                                </div>

                                <CustomizedTooltip
                                    title={t(
                                        'tooltips:billing.saveAsDefaultPaymentMethodDisabled',
                                    )}
                                    disableHoverListener={!disabledSaveDefault}
                                    copy={false}
                                >
                                    <div className={classes.checkboxContainer}>
                                        <CustomizedCheckbox
                                            dataQa="set-as-default-method"
                                            label={t(
                                                'screens:billing.saveAsDefaultPaymentMethod',
                                            )}
                                            checked={
                                                values.setAsDefaultPaymentMethod
                                            }
                                            onChange={() =>
                                                setFieldValue(
                                                    'setAsDefaultPaymentMethod',
                                                    !values.setAsDefaultPaymentMethod,
                                                )
                                            }
                                            disabled={disabledSaveDefault}
                                        />
                                        <IconWithTooltip
                                            tooltipText={t(
                                                'tooltips:billing.saveAsDefaultPaymentMethod',
                                            )}
                                        />
                                    </div>
                                </CustomizedTooltip>

                                {paymentMethod && (
                                    <CreationButton
                                        title={t(
                                            'screens:billing.useDefaultMethod',
                                        )}
                                        className={classes.defaultMethodButton}
                                        onClick={() => {
                                            setErrors({});
                                            setFieldValue(
                                                'isDefaultPaymentMethod',
                                                true,
                                            );
                                        }}
                                        withPlus={false}
                                    />
                                )}
                            </div>
                        )}

                        {isMakeTransactionLoading && (
                            <Loader
                                dataQa="edit-payment-method-loader"
                                absolutePosition
                            />
                        )}
                    </DialogContainer>
                );
            }}
        </Formik>
    );
};

export default MakePaymentDialog;

import { makeStyles } from '@material-ui/core';
import { PaymentMethodType } from '../../../../store/types/Billing';
import {
    OwnerPaymentMethod,
    PaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import { Colors } from '../../../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import CardValidator from 'card-validator';
import { CustomerInfoDetails } from '../../../../store/types/CustomerInfo';

export type PaymentMethodFormProps = {
    paymentMethodsTypes: PaymentMethodType[];
    withEmailWhenIsNecessary?: boolean;
};

export type PaymentMethodFormType = {
    paymentType: PaymentMethodType;
    cardholderName: string;
    cardNumber: string;
    expirationDate: string;
    cvv: string;
    bankRoutingNumber: string;
    accountNumber: string;
    accountName: string;
    address: string;
    state: number | null;
    country: string;
    city: string;
    postalCode: string;
    billingPhone: string;
    email: string;
};

export const paymentMethodFormDefaultValues: PaymentMethodFormType = {
    paymentType: PaymentMethodType.CreditCard,
    cardholderName: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    bankRoutingNumber: '',
    accountNumber: '',
    accountName: '',
    address: '',
    state: null,
    country: '',
    city: '',
    postalCode: '',
    billingPhone: '',
    email: '',
};

const requiredField = i18n.t('errors:common.emptyInput');

export const paymentMethodValidationSchemaHelper = (
    ownerPaymentsMethods: OwnerPaymentMethod[],
    customerInfo?: CustomerInfoDetails,
    isEmailRequired?: boolean,
) =>
    Yup.object().shape({
        cardholderName: Yup.string().when('paymentType', {
            is: PaymentMethodType.CreditCard,
            then: Yup.string()
                .min(2, i18n.t('errors:common.minLengthIs', { value: 2 }))
                .max(41)
                .required(requiredField),
        }),
        cardNumber: Yup.string().when('paymentType', {
            is: PaymentMethodType.CreditCard,
            then: Yup.string().test(
                'credit-card',
                '',
                function (value?: string) {
                    if (!value) {
                        return this.createError({
                            path: this.path,
                            message: requiredField,
                        });
                    }

                    if (!validateCardNo(value)) {
                        return this.createError({
                            path: this.path,
                            message: i18n.t('errors:billing.invalidCardNumber'),
                        });
                    }

                    const cardTypeValue = getCardType(value);
                    if (cardTypeValue) {
                        const cardType = convertCardValidatorTypeToPaymentType(
                            cardTypeValue
                        );

                        if (
                            !ownerPaymentsMethods.find(
                                (v) => v.payment_method === cardType,
                            )
                        ) {
                            return this.createError({
                                path: this.path,
                                message: i18n.t(
                                    'errors:billing.paymentMethodIsNotSupported',
                                    { value: cardTypeValue },
                                ),
                            });
                        }
                    }

                    return true;
                },
            ),
        }),
        email: Yup.string()
            .email()
            .test('emailrequired', '', function (value?: string) {
                let cardType: PaymentMethod | undefined = PaymentMethod.BankAccount;

                if (this.parent.paymentType === PaymentMethodType.CreditCard) {
                    const cardTypeValue = getCardType(value) || '';
                    cardType = convertCardValidatorTypeToPaymentType(
                        cardTypeValue
                    );
                }

                const method = ownerPaymentsMethods.find(
                    (v) => v.payment_method === cardType,
                );

                if (
                    isEmailRequired &&
                    method?.remote_cc_storage === 'Y' &&
                    !customerInfo?.email &&
                    !value
                ) {
                    return this.createError({
                        path: this.path,
                        message: requiredField,
                    });
                }

                return true;
            }),
        expirationDate: Yup.string().when('paymentType', {
            is: PaymentMethodType.CreditCard,
            then: Yup.string()
                .test('expiration-date', '', function (value?: string) {
                    if (!value) {
                        return this.createError({
                            path: this.path,
                            message: requiredField,
                        });
                    }

                    const validate = CardValidator.expirationDate(value);

                    if (!/[0-9][0-9] \/ [0-9][0-9]/.test(value)) {
                        return this.createError({
                            path: this.path,
                            message: i18n.t('errors:common.wrongFormat'),
                        });
                    }

                    if (!validate.isValid) {
                        return this.createError({
                            path: this.path,
                            message: i18n.t('errors:common.invalidDate2'),
                        });
                    }

                    return true;
                })
                .required(requiredField),
        }),
        cvv: Yup.string().when('paymentType', {
            is: PaymentMethodType.CreditCard,
            then: Yup.string()
                .min(3, i18n.t('errors:common.minLengthIs', { value: 3 }))
                .max(4)
                .required(requiredField),
        }),
        bankRoutingNumber: Yup.string().when('paymentType', {
            is: PaymentMethodType.BankAccount,
            then: Yup.string().max(9).required(requiredField),
        }),
        accountNumber: Yup.string().when('paymentType', {
            is: PaymentMethodType.BankAccount,
            then: Yup.string()
                .min(3, i18n.t('errors:common.minLengthIs', { value: 3 }))
                .max(41)
                .required(requiredField),
        }),
        accountName: Yup.string().when('paymentType', {
            is: PaymentMethodType.BankAccount,
            then: Yup.string()
                .min(2, i18n.t('errors:common.minLengthIs', { value: 3 }))
                .max(41)
                .required(requiredField),
        }),
        address: Yup.string().max(41).required(requiredField),
        state: Yup.number().nullable(),
        country: Yup.string().required(requiredField),
        city: Yup.string().max(50).required(requiredField),
        postalCode: Yup.string()
            .min(2, i18n.t('errors:common.minLengthIs', { value: 2 }))
            .max(10)
            .matches(/^[0-9a-zA-Z -]*$/, i18n.t('errors:common.wrongFormat'))
            .required(requiredField),
        billingPhone: Yup.string()
            .min(4, i18n.t('errors:common.minLengthIs', { value: 4 }))
            .max(16)
            .matches(/^\+?[0-9]*$/, i18n.t('errors:common.wrongFormat'))
            .required(requiredField),
    });

export const useStyles = makeStyles(() => ({
    methodTypesContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    methodTypeBox: {
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        borderRadius: 4,
        width: 224,
        padding: '5px 1px 5px 22px',

        '& .MuiFormControlLabel-label': {
            fontWeight: 'bold',
        },

        '&:first-of-type': {
            marginRight: 16,
        },
    },
    methodTypeBoxSelected: {
        backgroundColor: Colors.SmokeBackground,
        padding: '4px 0px 4px 21px',
        border: `2px solid ${Colors.Secondary1}`,
    },
    sectionHeader: {
        color: Colors.Text,
        fontWeight: 500,
        marginTop: 27,
        marginBottom: 20,
    },
    billingAddressHeader: {
        marginTop: 0,
    },
    formItems: {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            marginBottom: 24,
            width: 330,
        },
    },

    formRow: {
        display: 'flex',

        '& .MuiFormControl-root:first-of-type': {
            marginRight: 24,
        },
    },
    creditCardInput: {
        '& svg': {
            width: 35,
        },
    },
    cardInfoContainer: {
        display: 'flex',

        '& .MuiFormControl-root': {
            width: 170,

            '&:first-of-type': {
                marginRight: 24,
            },
        },
    },
    cardNumberContainer: {
        '& .MuiFormControl-root': {
            width: 512,
            marginBottom: 0,
        },
        '& .MuiTextField-root': {
            marginBottom: 24,
        },
    },
    sectionHeaderMargin: {
        marginTop: 0,
    },
    countrySelect: {
        marginRight: 30,
    },
    input: {
        width: 330,
        minWidth: 330,
        '& .MuiInputBase-root': {
            width: 330,
            minWidth: 330,
        }
    },
    disabledMaskedLabel: {
        '& .MuiInputBase-input': {
            background: Colors.SmokeBackground
        },
        '& .MuiInputBase-root': {
            background: Colors.SmokeBackground
        }
    }
}));

export const convertCardValidatorTypeToPaymentType = (type: string | null) => {
    if(!type) {
        return undefined;
    }
    const c = CARD_TYPES.find(e => e.displayName === type);
    if(!c) {
        return undefined;
    }
    return c.type;
};

export const convertPaymentMethodToFormData = (
    paymentMethod: PaymentMethodInfo,
) => {
    return {
        paymentType:
            paymentMethod.payment_method === PaymentMethod.BankAccount
                ? PaymentMethodType.BankAccount
                : PaymentMethodType.CreditCard,
        cardholderName:
            paymentMethod.payment_method !== PaymentMethod.BankAccount
                ? paymentMethod.name
                : '',
        cardNumber: '',
        expirationDate: paymentMethod.exp_month
            ? `${paymentMethod.exp_month} / ${paymentMethod.exp_year.substring(
                  2,
              )}`
            : '',
        cvv: '',
        bankRoutingNumber: paymentMethod.bank_number || '',
        accountNumber: '',
        accountName:
            paymentMethod.payment_method === PaymentMethod.BankAccount
                ? paymentMethod.name
                : '',
        address: paymentMethod.address || '',
        state: paymentMethod.i_country_subdivision || null,
        country: paymentMethod.iso_3166_1_a2 || '',
        city: paymentMethod.city || '',
        postalCode: paymentMethod.zip || '',
        billingPhone: paymentMethod.phone_number || '',
    };
};

export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g;
export const CARD_TYPES = [
    {
        displayName: 'Visa',
        type: PaymentMethod.Visa,
        format: DEFAULT_CARD_FORMAT,
        startPattern: /^4/,
        gaps: [4, 8, 12],
        lengths: [16, 18, 19],
        code: {
            name: 'CVV',
            length: 3,
        },
    },
    {
        displayName: 'Mastercard',
        type: PaymentMethod.Mastercard,
        format: DEFAULT_CARD_FORMAT,
        startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
        gaps: [4, 8, 12],
        lengths: [16],
        code: {
            name: 'CVC',
            length: 3,
        },
    },
    {
        displayName: 'American Express',
        type: PaymentMethod.AmericanExpress,
        format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
        startPattern: /^3[47]/,
        gaps: [4, 10],
        lengths: [15],
        code: {
            name: 'CID',
            length: 4,
        },
    },
    {
        displayName: 'Diners Club',
        type: PaymentMethod.DinersClub,
        format: DEFAULT_CARD_FORMAT,
        startPattern: /^(36|38|30[0-5])/,
        gaps: [4, 10],
        lengths: [14, 16, 19],
        code: {
            name: 'CVV',
            length: 3,
        },
    },
    {
        displayName: 'Discover',
        type: PaymentMethod.Discover,
        format: DEFAULT_CARD_FORMAT,
        startPattern: /^(6011|65|64[4-9]|622)/,
        gaps: [4, 8, 12],
        lengths: [16, 19],
        code: {
            name: 'CID',
            length: 3,
        },
    },
    {
        displayName: 'Maestro',
        type: PaymentMethod.Maestro,
        format: DEFAULT_CARD_FORMAT,
        startPattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
        gaps: [4, 8, 12],
        lengths: [12, 13, 14, 15, 16, 17, 18, 19],
        code: {
            name: 'CVC',
            length: 3,
        },
    },
];
export const formatCardNumber = (cardNumber: string, type?: string) => {
    const cardType = CARD_TYPES.find((v) => v.type === type);

    const format = cardType?.format || DEFAULT_CARD_FORMAT;
    if (format && format.global) {
        return (cardNumber.match(format) || []).join(' ');
    }

    if (format) {
        const execResult = format.exec(cardNumber.split(' ').join(''));
        if (execResult) {
            return execResult
                .splice(1, 3)
                .filter((x) => x)
                .join(' ');
            1;
        }
    }

    return cardNumber;
};

export const isEmailRequiredForSave = (
    values: PaymentMethodFormType,
    ownerPaymentsMethods?: OwnerPaymentMethod[],
    customerInfo?: CustomerInfoDetails,
) => {
    let cardType: PaymentMethod | undefined = PaymentMethod.BankAccount;

    if (values.paymentType === PaymentMethodType.CreditCard) {
        const validatorCardType = getCardType(values.cardNumber);
        cardType = validatorCardType
            ? convertCardValidatorTypeToPaymentType(validatorCardType)
            : undefined;
    }

    const cc_required = ownerPaymentsMethods?.find(
        (v) => v.payment_method === cardType,
    )?.remote_cc_storage;

    return cc_required === 'Y' && !customerInfo?.email;
};

export const validateCardNo = (value: string) => {
    let sum = 0;
    let shouldDouble = false;
    for (let i = value.length - 1; i >= 0; i--) {
        const ch = value.charAt(i);
        if(ch === " ") {
            continue;
        }

        let digit = parseInt(ch, 0);

        if (shouldDouble) {
            if ((digit *= 2) > 9) digit -= 9;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
}

export const getCardType = (value: string | null | undefined) => {
    const noSpaceValue = value?.replace(new RegExp(" ", 'g'), "") || "";
    for(const type of CARD_TYPES) {
        const regex = new RegExp(type.startPattern);
        if(regex.test(noSpaceValue)) {
            return type.displayName;
        }
    }
    return undefined;
}
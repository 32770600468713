import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    activeHeader: {
        fontWeight: 'bold',
        color: Colors.Gray7,
        marginBlockEnd: '0',
        marginRight: 14,
    },
    numberLabel: {
        marginLeft: -75,
        marginTop: 16,

        '& .Mui-disabled': {
            '& .MuiInputAdornment-root': {
                color: Colors.Gray,
            },
        },
    },
    numberInput: {
        width: 135,
        marginRight: 20,
        '& .MuiInputBase-root': {
            '& .MuiInputBase-inputAdornedEnd': {
                paddingRight: 8,
            },
        },
    },
    centeredGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    numberPickerGrid: {
        marginTop: 10,
        '& .MuiFormControl-root': {
            flex: 'unset !important',
        },
    },

    switchContainer: {
        marginLeft: -8,
        marginTop: 10,
    },
}));

import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import { ReduxState } from '../../../../store/types';
import PermissionProvider from '../../../PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../../AlertDialog/DialogContainer';
import { Permission } from '../../../../store/types/Permission';
import CustomizedTextField from '../../../TextField/TextField';
import Loader from '../../../Loader/Loader';
import { actions } from '../../../../store';
import { OperationMode } from '../../../../store/actions/extensions/payloads';
import { NewOperationModeDialogProps, newOperationModeValidationSchema, useStyles } from './NewOperationModeDialog.utils';
import { CallScreeningMode } from '../../../../utils/extensions/translateBitMak';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import TextField from '../../../TextField/TextField';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import { YesNo } from '../../../../store/types/CallScreening';
import classNames from 'classnames';
import SelectField from '../../../SelectField/SelectField';

const NewOperationModeDialog: React.FC<NewOperationModeDialogProps> = ({
        isOpen,
        editObject,
        clickOnClose,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const isLoading: boolean = useSelector((state: ReduxState) => 
        state.extensions.isCreatingPolicyMode || state.extensions.isEditingPolicyMode || false);
    
    const i_customer = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.i_customer,
    );
    
    const modes = useSelector(
        (state: ReduxState) => state.extensions?.callProcessingOperationModeList || [],
    );

    const availableDtmfCodes = useMemo(() => {
        const notUse = editObject?.short_code || 0;
        const returnArray = [];
        for(let i=1; i<=9; i++) {
            const m = modes.find(e => e.short_code === i);
            if(!m || i === notUse) {
                returnArray.push(i);
            }
        }
        
        return returnArray;
    }, [editObject, modes]);
    
    const allDtmfCodes = useMemo(() => {
        const returnArray = [];
        for(let i=1; i<=9; i++) {
            returnArray.push(i);
        }
        return returnArray;
    }, [modes]);

    const defaultDtmfCodeAtDropdown = useMemo(() => {
        return (availableDtmfCodes.length > 0 
            ? availableDtmfCodes[0]
            : 1);
    }, [availableDtmfCodes]);

    const initialValues: OperationMode = useMemo(
        () => ({
            short_code: editObject?.short_code || defaultDtmfCodeAtDropdown,
            sticky: editObject?.sticky || YesNo.No,
            name: editObject?.name || '',
            default_timeout: editObject?.default_timeout || undefined,
            i_customer: i_customer,
            i_operation_mode: editObject?.i_operation_mode || undefined
        } as OperationMode),
        [editObject, i_customer, defaultDtmfCodeAtDropdown],
    );

    const getDtmfCodeOptionValue = useCallback((value: number) => {
        const m = modes.find(e => e.short_code === value); 
        const isCurrentForEdit = (editObject?.short_code || 0) === value;
        if(m && !isCurrentForEdit) {
            return value + ' - ' + m.name;
        } 
        return value + '';
    }, [modes, editObject]);

    const {
        values,
        handleSubmit,
        resetForm,
        setFieldError,
        handleChange,
        errors,
        dirty,
        setFieldValue
    } = useFormik<OperationMode>({
        initialValues,
        onSubmit: (form) => {
            if(editObject) {
                dispatch(actions.editMode.request({
                    object: {
                        ...editObject,
                        ...form,
                        default_timeout: form.default_timeout || null
                    },
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            } else {
                dispatch(actions.addMode.request({
                    object: form,
                    callback: () => {
                        clickOnClose?.();
                        resetForm();
                    }
                }));
            }
        },
        validationSchema: newOperationModeValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true
    });
    
    const isNameNotUnique: boolean = useMemo(() => {
        const found = modes?.find(e => e.name?.toLocaleUpperCase()?.trim() === values.name?.toLocaleUpperCase()?.trim());
        let notUnique = (found !== undefined
            || values.name?.toLocaleUpperCase()?.trim() === CallScreeningMode.Deafault.toLocaleUpperCase()?.trim()
        );
        if(notUnique && editObject !== undefined && found !== undefined) {
            if(found.i_operation_mode === editObject.i_operation_mode) {
                notUnique = false;
            }
        }
        if(notUnique && errors.name === undefined) {
            setFieldError('name', t('errors:common.thisNameIsAlreadyUsed'));
        } else if(!notUnique && errors.name !== undefined) {
            setFieldError('name', undefined);
        }
        return notUnique;
    }, [modes, values.name, errors]);
    
    return (
        <PermissionProvider permission={Permission.Calls.Settings.CallScreening.value}>
            <DialogContainer
                className={classes.modalContainer}
                isOpen={isOpen}
                dataQa={'policies-add-new-mode-modal'}
                header={editObject
                    ? t('screens:callSettings.editPolicyModeDialogTitle')
                    : t('screens:callSettings.addNewPolicyModeDialogTitle')
                }
                headerClass={classes.header}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            resetForm();
                            clickOnClose?.();
                        }}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        disabled={
                            !dirty ||
                            !(values.name?.length) ||
                            !(values.short_code) ||
                            isLoading ||
                            isNameNotUnique
                        }
                        onClick={handleSubmit}
                        dataTestId={'add-new-mode-save-button'}
                        primary
                    />,
                ]}
            >
                <form
                    className={classes.form}
                    autoComplete="off"
                    data-testid="add-new-mode-form"
                >
                    <Grid className={classes.itemsContainer}>
                        <Box className={classes.boxRow}>
                            <CustomizedTextField
                                id="name"
                                label={t('screens:callSettings.modeName')}
                                value={values.name}
                                required
                                setFieldError={setFieldError}
                                helperText={
                                    errors.name && errors.name.length > 0
                                        ? errors.name
                                        : ''
                                }
                                onChange={handleChange}
                                maxLength={255}
                                dataQa="name-field"
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <SelectField
                                id="short_code"
                                label={t('screens:callSettings.dtmfCode')}
                                getOptionLabel={getDtmfCodeOptionValue}
                                onChange={(_, option: number) => {
                                    if(option) {
                                        setFieldValue('short_code', option);
                                    }
                                }}
                                items={allDtmfCodes}
                                defaultValue={defaultDtmfCodeAtDropdown}
                                value={values.short_code}
                                helperText={
                                    errors.short_code && errors.short_code.length > 0
                                        ? errors.short_code
                                        : ''
                                }
                                setFieldError={setFieldError}
                                disableClearable
                                required
                                dataTestId={'short-code-field'}
                                getOptionDisabled={(option: number) => {
                                    return !availableDtmfCodes.find(e => e === option);
                                }}
                            />
                        </Box>
                        
                        <Box className={classes.boxRow}>
                            <TextField
                                id="default_timeout"
                                label={t('screens:callSettings.expiresAfterMinutes')}
                                onChange={(e) => {
                                    const value = (e.target.value + '');
                                    const decimalValue = value.length > 0 ? parseInt(value) : 0;
                                    if(decimalValue > 0)
                                    {
                                        setFieldValue('default_timeout', decimalValue);
                                    } else {
                                        setFieldValue('default_timeout', undefined);
                                    }
                                }}
                                icon={
                                    <IconWithTooltip
                                        dataQa="modes-tooltip"
                                        tooltipText={t('tooltips:callSettings.expiresAfter')}
                                    />
                                }
                                value={values.default_timeout ? (values.default_timeout + '') : '' }
                                type="number"
                                iconPosition="end"
                                dataQa="default_timeout-field"
                                inputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 99999,
                                    },
                                    pattern: '[0-9]*'
                                }}
                                className={classes.numberField}
                            />
                        </Box>
                        
                        <Box className={classNames(classes.boxRow, classes.checkBox)}>
                            <CustomizedCheckbox
                                checked={values.sticky === YesNo.Yes}
                                dataQa="play-before-action-status"
                                onChange={(value) =>
                                    setFieldValue(
                                        'sticky',
                                            value.target.checked ? YesNo.Yes : YesNo.No,
                                        )
                                    }
                                label={t(
                                    'screens:callSettings.stickyMode',
                                )}
                            />
                            <div className={classes.iconTooltip}>
                                <IconWithTooltip
                                    dataQa="sticky-tooltip"
                                    tooltipText={t('tooltips:callSettings.stickyMode')}
                                />
                            </div>
                        </Box>
                    </Grid>
                </form>
                {isOpen && isLoading && (
                    <Loader dataQa={'wait-for-data'} absolutePosition />
                )}
            </DialogContainer>
        </PermissionProvider>
    );
};

export default NewOperationModeDialog;
import {applyMiddleware, combineReducers, createStore} from 'redux';
// @ts-ignore
import {promiseMiddleware} from '@adobe/redux-saga-promise';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';

//Reducers
import auth from './reducers/auth/reducer';
import extensions from './reducers/extensions/reducer';
import ringgroups from './reducers/ringgroups/reducer';
import generic from './reducers/generic/reducer';
import didNumbers from './reducers/didNumbers/reducer';
import calls from './reducers/calls/reducer';
import dashboard from './reducers/dashboard/reducer';
import didProvider from './reducers/didProvider/reducer';
import company from './reducers/company/reducer';
import phoneBook from './reducers/phoneBook/reducer';
import devices from './reducers/devices/reducer';
import autoAttendants from './reducers/autoAttendants/reducer';
import menus from './reducers/menus/reducer';
import faxMailboxes from './reducers/faxMailboxes/reducer';
import callQueues from './reducers/callQueues/reducer';
import callSettings from './reducers/callSettings/reducer';
import myProfile from './reducers/myProfile/reducer';
import billing from './reducers/billing/reducer';
import permissions from './reducers/permissions/reducer';
import wallboard from './reducers/wallboard/reducer';
import sipTrunks from './reducers/sipTrunks/reducer';
import tabs from './reducers/tabs/reducer';

//Sagas
import {authSaga} from './sagas/auth/saga';
import {extensionSaga} from './sagas/extensions/saga';
import {ringGroupsSaga} from './sagas/ringgroups/saga';
import {genericSaga} from './sagas/generic/saga';
import {passwordServiceSaga} from './sagas/sipStatus/saga';
import {didNumbersSaga} from './sagas/didNumbers/saga';
import {callsSaga} from './sagas/calls/saga';
import {companySaga} from './sagas/company/saga';
import {dashboardSaga} from './sagas/dashboard/saga';
import {phoneBookSaga} from './sagas/phoneBook/saga';
import {devicesSaga} from './sagas/devices/saga';
import {didInventorySaga} from './sagas/didProvider/saga';
import {autoAttendantsSaga} from './sagas/autoAttendants/saga';
import {menusSaga} from './sagas/menus/saga';
import {faxMailboxesSaga} from './sagas/faxMailboxes/saga';
import {callQueuesSaga} from './sagas/callQueues/saga';
import {callSettingsSaga} from './sagas/callSettings/saga';
import {billingSaga} from './sagas/billing/saga';
import {permissionsSaga} from './sagas/permissions/saga';
import {wallboardSaga} from './sagas/wallboard/saga';
import {sipTrunksSaga} from './sagas/sipTrunks/saga';

//Tools
import {composeWithDevTools} from 'redux-devtools-extension';
import {myProfileSaga} from './sagas/myProfile/saga';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    // || (() => null), <- this is necessary for testing
    combineReducers({
        auth: auth || (() => null),
        extensions: extensions || (() => null),
        ringgroups: ringgroups || (() => null),
        generic: generic || (() => null),
        didNumbers: didNumbers || (() => null),
        calls: calls || (() => null),
        didProvider: didProvider || (() => null),
        company: company || (() => null),
        dashboard: dashboard || (() => null),
        phoneBook: phoneBook || (() => null),
        devices: devices || (() => null),
        autoAttendants: autoAttendants || (() => null),
        menus: menus || (() => null),
        faxMailboxes: faxMailboxes || (() => null),
        callQueues: callQueues || (() => null),
        callSettings: callSettings || (() => null),
        myProfile: myProfile || (() => null),
        billing: billing || (() => null),
        permissions: permissions || (() => null),
        wallboard: wallboard || (() => null),
        sipTrunks: sipTrunks || (() => null),
        tabs: tabs || (() => null)
    }),
    composeWithDevTools(applyMiddleware(promiseMiddleware, sagaMiddleware)),
);

function* saga() {
    yield all([
        ...authSaga,
        ...extensionSaga,
        ...ringGroupsSaga,
        ...passwordServiceSaga,
        ...genericSaga,
        ...(didNumbersSaga || []),
        ...callsSaga,
        ...didInventorySaga,
        ...companySaga,
        ...dashboardSaga,
        ...wallboardSaga,
        ...phoneBookSaga,
        ...devicesSaga,
        ...autoAttendantsSaga,
        ...menusSaga,
        ...faxMailboxesSaga,
        ...callQueuesSaga,
        ...sipTrunksSaga,
        ...(myProfileSaga || []),
        ...(callSettingsSaga || []),
        ...(billingSaga || []),
        ...(permissionsSaga || []),
    ]);
}

sagaMiddleware.run(saga);

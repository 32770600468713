import React, {useEffect, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {actions} from '../../../store';
import {useTranslation} from 'react-i18next';
import {convertUserLocalTimeToUtc} from '../../../utils/dateWithTimezoneConversion';
import {CallHistory} from '../../../store/types/CallHistory';
import CustomKeyboardDateTimePicker from '../../../components/KeyboardDateTimePicker/KeyboardDateTimePicker';
import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';
import {useFormik} from 'formik';
import dayjs from '../../../services/customDayJs';
import CallRecordInformationDialog from '../../../components/Calls/CallRecordInformationDialog';
import {PaginationMode} from '../../../components/DataGrid/types';
import DataGrid from '../../../components/DataGrid/DataGrid';
import {prepareCallRecordName} from '../../../utils/calls/prepareCallRecordName';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import IconWithTooltip from '../../../components/Tooltip/IconWithTooltip';
import Checkbox from '../../../components/Checkbox/Checkbox';
import {
    useStyles,
    CallHistoryFormProps,
    dateFormat,
    callHistoryFiltersValidationSchema,
    generateColumns,
} from './RecentCalls.utils';
import toast from 'react-hot-toast';
import {useRecentCalls} from "../../../hooks/useRecentCalls";
import { Permission } from '../../../store/types/Permission';

export const RecentCalls: React.VFC = () => {
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );
    const classes = useStyles();
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [callRecordInformation, setCallRecordInformation] = useState<CallHistory | undefined>(undefined);

    const {
        items,
        total,
        timezoneOffset,
        userDateTimeFormat,
        isLoading,
        customerCurrency,
        decimalDigits
    } = useRecentCalls();

    const toogleTooltipVisibility = (index: number) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = false;
        setIsDownloadingGoingOn(newDownloading);
    };

    const initialValues = useMemo(
        () => ({
            from: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .subtract(1, 'month')
                .format(dateFormat),
            till: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
            cli: '',
            cld: '',
            unsuccessfulCalls: false,
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        setFieldError,
    } = useFormik<CallHistoryFormProps>({
        initialValues,
        onSubmit: (form) => {
            getCallHistory(form, false);
        },
        validationSchema: callHistoryFiltersValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    useEffect(() => {
        getCallHistory(values, true);
    }, []);

    const getCallHistory = (
        formData: CallHistoryFormProps,
        withInitCalls?: boolean,
        limit?: number,
        offset?: number,
    ) => {
        dispatch(
            actions.getCallHistory.request({
                limit: limit || 10,
                offset: offset || 0,
                ...formData,
                cli: formData.cli ? `${formData.cli}%` : undefined,
                cld: formData.cld ? `${formData.cld}%` : undefined,
                from: convertUserLocalTimeToUtc(formData.from, timezoneOffset),
                till: convertUserLocalTimeToUtc(formData.till, timezoneOffset),
                withInitCalls,
            }),
        );
    };

    const downloadFile = (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => {
        const newDownloadings = [...isDownloadingGoingOn];
        newDownloadings[index] = true;
        setIsDownloadingGoingOn(newDownloadings);
        toast(t<string>('tooltips:ringGroups.downloadWillStartShortly'));
        dispatch(
            actions.getExtensionCallHistoryFile.request({
                i_xdr,
                call_recording_id,
                fileName,
                callback: () => toogleTooltipVisibility(index),
            }),
        );
    };

    const removeItems = (items: CallHistory[]) => {
        dispatch(
            actions.removeCallRecordings.request({
                items,
                getDataRequestPayload: {
                    limit: 10,
                    offset: 0,
                    ...values,
                    cli: values.cli ? `${values.cli}%` : undefined,
                    cld: values.cld ? `${values.cld}%` : undefined,
                    from: convertUserLocalTimeToUtc(
                        values.from,
                        timezoneOffset,
                    ),
                    till: convertUserLocalTimeToUtc(
                        values.till,
                        timezoneOffset,
                    ),
                },
            }),
        );
        setCallRecordInformation(undefined);
    };

    const deleteCallRecording = () => {
        callRecordInformation && removeItems([callRecordInformation]);
        setIsRemoveModalOpen(false);
    };
    const columns = generateColumns(
        t,
        classes,
        timezoneOffset,
        customerCurrency,
        decimalDigits,
        downloadFile,
        setCallRecordInformation,
        userDateTimeFormat,
        isDownloadingGoingOn,
    );
    return (
        <>
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="call-history-form"
            >
                <Grid item className={classes.filtersContainer}>
                    <Grid item className={classes.itemsContainer}>
                        <Grid item className={classes.inputsContainer}>
                            <CustomKeyboardDateTimePicker
                                id="from"
                                label={t('common:fromDate')}
                                value={values.from}
                                onChange={(v) => setFieldValue('from', v)}
                                dataQa={'call-history-filter-from'}
                                dataTestId={'call-history-filter-from'}
                                handleSubmit={handleSubmit}
                                userDateTimeFormat={userDateTimeFormat}
                                helperText={errors.from}
                                setFieldError={setFieldError}
                                skipPermission
                            />
                            <CustomKeyboardDateTimePicker
                                id="till"
                                label={t('common:toDate')}
                                value={values.till}
                                onChange={(v) => setFieldValue('till', v)}
                                dataQa={'call-history-filter-till'}
                                dataTestId={'call-history-filter-till'}
                                handleSubmit={handleSubmit}
                                userDateTimeFormat={userDateTimeFormat}
                                helperText={errors.till}
                                setFieldError={setFieldError}
                                skipPermission
                            />
                            <TextField
                                id="cli"
                                label={t('screens:calls.caller')}
                                onChange={handleChange}
                                value={values.cli}
                                dataQa={'call-history-filter-calling-number'}
                                handleSubmit={handleSubmit}
                                skipPermission
                            />
                            <TextField
                                id="cld"
                                label={t('screens:calls.destination')}
                                onChange={handleChange}
                                value={values.cld}
                                dataQa={'call-history-filter-called-number'}
                                handleSubmit={handleSubmit}
                                skipPermission
                            />
                        </Grid>
                        <Button
                            primary
                            accent
                            dataQa="call-history-search"
                            className={classes.button}
                            onClick={() => handleSubmit()}
                            skipPermission
                        >
                            {t('common:search')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                    <div className={classNames(classes.checkbox)}>
                        <Checkbox
                            checked={values.unsuccessfulCalls}
                            dataQa="unsuccessful-calls-switch"
                            dataTestId="unsuccessful-calls-switch"
                            onChange={(value) =>
                                setFieldValue(
                                    'unsuccessfulCalls',
                                    value.target.checked,
                                )
                            }
                            label={t('screens:calls.showUnsuccessfulCalls')}
                            skipPermission
                        />
                        <IconWithTooltip
                            dataQa="unsuccessful-tooltip"
                            tooltipText={t(
                                'tooltips:callSettings.showUnsuccessfulAttempts',
                            )}
                        />
                    </div>
                </Grid>
            </form>

            <DataGrid<CallHistory>
                columns={columns}
                data={items.map((v, index) => ({...v, id: index}))}
                rowCount={total}
                loading={isLoading}
                onPageChange={(v) =>
                    getCallHistory(
                        values,
                        false,
                        v.pageSize,
                        v.pageSize * v.page,
                    )
                }
                onPageSizeChange={(v) =>
                    getCallHistory(values, false, v.pageSize, 0)
                }
                paginationMode={PaginationMode.Server}
                centeredRows
                narrowRows
                customRowHeight={63}
                initialPageSize={10}
                classes={{
                    tableContainer: classes.tableContainer,
                }}
            />

            <CallRecordInformationDialog
                isOpen={!!callRecordInformation}
                toggleVisibility={() => setCallRecordInformation(undefined)}
                callHistory={callRecordInformation}
                customerCurrency={customerCurrency}
                timezoneOffset={timezoneOffset}
                onDeleteClick={() => {
                    setIsRemoveModalOpen(true);
                }}
                onDownloadClick={() =>
                    callRecordInformation?.i_xdr &&
                    downloadFile(
                        callRecordInformation.i_xdr,
                        callRecordInformation.cr_download_ids?.[0] || '',
                        prepareCallRecordName(
                            callRecordInformation,
                            timezoneOffset,
                        ),
                        0,
                    )
                }
                userDateTimeFormat={userDateTimeFormat}
                isDownloadingGoingOn={isDownloadingGoingOn}
                deletePermission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.DeleteCallRecording.value}
                downloadPermission={Permission.Calls.Activity.RecentCalls.CallDetailRecord.DownloadCallRecording.value}
            />
            <AlertDialog
                isOpen={isRemoveModalOpen}
                dataQa="remove-call-history"
                contentClass="medium-width-modal-delete"
                hideHeader={true}
                className={classes.container}
                description={
                    <span style={{whiteSpace: 'pre-wrap'}}>
                        {t('screens:calls.deleteCallHistory', {
                            name:
                                callRecordInformation &&
                                prepareCallRecordName(
                                    callRecordInformation,
                                    timezoneOffset,
                                ),
                        })}
                    </span>
                }
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIsRemoveModalOpen(false)}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                        className={classes.primaryModalButton}
                        onClick={deleteCallRecording}
                    />,
                ]}
            />
        </>
    );
};

const ENTITY_TYPE_CUSTOMER = 'Customer';
const ENTITY_TYPE_ACCOUNT = 'Account';

const TEST_PAYMENT_AMOUNT = 0.5;

const ON_DO_PAYMENT_RESULT_CB = (result) => {
    if (result.faultstring) {
        alert('Error: ' + result.faultstring);
    } else if (result.redirect_url) {
        window.location = result.redirect_url;
    } else if (result.success) {
        alert('Success: your balance topped up');
    }
};

const ON_VOID_PAYMENT_RESULT_CB = (result) => {
    if (result.faultstring) {
        alert('Error: ' + result.faultstring);
    } else if (result.redirect_url) {
        window.location = result.redirect_url;
    } else if (result.success) {
        alert('Success: the transaction was voided');
    }
};

const ON_FINILIZE_TRANSACTION_RESULT_CB = (result) => {
    if (result.faultstring) {
        alert('Error: ' + result.faultstring);
    } else if (result.pending) {
        alert(
            'Pending: the transaction is pending. Please check the status later',
        );
    } else if (result.success) {
        alert('Success: your balance topped up');
    }
};

const ON_AUTH_CC_RESULT_CB = (result) => {
    if (result.faultstring) {
        alert('Error: ' + result.faultstring);
    } else if (result.pending) {
        alert(
            'Pending: the authorization transaction is pending. Please check the status later',
        );
    } else if (result.success) {
        alert('Success: the credit card was authorized');
    }
};

const getCookie = (name) => {
    let matches = document.cookie.match(
        new RegExp(
            '(?:^|; )' +
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                '=([^;]*)',
        ),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * PortaBilling library that provides interface for making payments from WEB portals
 * @packageDocumentation
 * @module PBPaymentAPI
 */
class PBPaymentAPI {
    url;
    entityType;

    /**
     * @param args              Object. Mandatory. The contructor arguments
     * @param args.url          String. Mandatory. The url PortaBilling API is available at
     * @param args.entityType   String. Mandatory. The entity type. Possible values: ENTITY_TYPE_CUSTOMER, ENTITY_TYPE_ACCOUNT
     * @param args.sessionId    String. The session identifier
     */
    constructor(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (typeof args !== 'object') {
            throw new Error('args must be an object');
        }

        if (!args.url) {
            throw new Error('args.url is mandatory');
        }

        if (!args.entityType) {
            throw new Error('args.entityType is mandatory');
        }

        if (
            args.entityType !== ENTITY_TYPE_CUSTOMER &&
            args.entityType !== ENTITY_TYPE_ACCOUNT
        ) {
            throw new Error(
                'args.entityType must be either Customer or Account',
            );
        }

        this.url = args.url;
        this.entityType = args.entityType;
        this.sessionId = args.sessionId;
        this.token = args.token;

        const params = new URLSearchParams(window.location.search);
        var iPaymentTransaction = params.get('tr_id'); // tr_id - it's predefined option that is used to hold the transaction identifier

        if (iPaymentTransaction) {
            this.finalizePayment({ iPaymentTransaction });
        }
    }

    /**
     * Function that sends request to PortaBilling server
     * @param methodName String. Mandatory. The API method name (includes service and method). For instance: Customer/make_transaction
     * @param params     Object. The method params
     * @retval           Promise
     */
    sendRequest(methodName, params) {
        if (!methodName) {
            throw new Error('methodName is mandatory');
        }
        if (!params) {
            throw new Error('params is mandatory');
        }

        const headers = {};
        const body = new FormData();

        if (this.sessionId) {
            const auth_info = {
                session_id: this.sessionId,
                csrf_token: this.token,
            };

            body.set('auth_info', JSON.stringify(auth_info));
        }

        body.set('params', JSON.stringify(params));

        const url = [this.url, methodName].join('/');

        const csrfToken = getCookie('CSRF-Token');
        if (csrfToken) {
            headers['CSRF-Token'] = csrfToken;
        }

        return fetch(url, {
            method: 'post',
            mode: 'cors',
            credentials: 'include',
            body,
            headers,
        }).then(
            (response) => response.json(),
            (error) => {
                return {
                    faultcode: 'internal_error',
                    faultstring: error,
                };
            },
        );
    }

    /**
     * Function that sends payment request to PortaBilling server
     * @param args                  Object. Mandatory. The payment method
     * @param args.amount           Number. Mandatory. The amount to charge customer on
     * @param args.saveCard         Boolean. Flag shows whether to save credit card on successful payment
     * @param args.iCustomer        Number. The customer unique identifier (if payment is done by non-customer for customer)
     * @param args.iAccount         Number. The account unique identifier (if payment is done by non-account for account)
     * @param args.resultCallback   Function. The callback called on method executed. Can be used to analize the result of the payment
     * @param args.cardInfo         Object. The credit card information to use for payment. If not specified - use stored credit card
     * @param args.returnUrl        String. The url to return user to after completing 3D Secure authorization
     * @retval                      Promise
     */
    doPayment(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (!args.amount || typeof args.amount !== 'number') {
            throw new Error('args.amount is mandatory');
        }

        const params = {
            action: 'E-Commerce Payment',
            amount: args.amount,
            card_info: args.cardInfo,
            save_card: args.saveCard ? 'Y' : 'N',
            return_url: args.returnUrl,
        };

        if (args.iCustomer && this.entityType === ENTITY_TYPE_CUSTOMER) {
            params.i_customer = args.iCustomer;
        }

        if (args.iAccount && this.entityType === ENTITY_TYPE_ACCOUNT) {
            params.i_account = args.iAccount;
        }

        const resultCallback = args.resultCallback;

        return this.sendRequest(
            [this.entityType, 'make_transaction'].join('/'),
            params,
        )
            .then(
                (response) => this._onDoPaymentCallback(response),
                (error) => this._onDoPaymentCallback(error),
            )
            .then((result) => result);
    }

    _onDoPaymentCallback(response) {
        if (!response) {
            return {
                faultstring: 'No response was received',
            };
        }

        if (response.faultstring) {
            return response;
        }

        if (response.redirect_url) {
            const { redirect_url } = response;
            return {
                redirect_url: response.redirect_url,
            };
        }

        if (response.i_payment_transaction) {
            return {
                i_payment_transaction: response.i_payment_transaction,
            };
        }

        return {
            faultstring: 'unknown error',
        };
    }

    /**
     * Function that sends request to PortaBilling system in order to void transaction
     * @param args                      Object. Mandatory. The payment method
     * @param args.amount               Number. Mandatory. The amount to charge customer on
     * @param args.iPaymentTransaction  Number. The unique transaction identifier (Either this or transactionID must be specified)
     * @param args.transactionID        Number. The unique external transaction identifier (Either this or iPaymentTransaction must be specified)
     * @param args.iCustomer            Number. The customer unique identifier (if payment is done by non-customer for customer)
     * @param args.iAccount             Number. The account unique identifier (if payment is done by non-account for account)
     * @param args.resultCallback       Function. The callback to execute on after transaction is voided
     * @param args.returnUrl            String. The url to return user to after completing 3D Secure authorization
     * @retval                          Promise
     */
    voidPayment(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (!args.amount || typeof args.amount !== 'number') {
            throw new Error('args.amount is mandatory');
        }

        if (!args.iPaymentTransaction && !args.transactionID) {
            throw new Error(
                'At least one of the following arguments must be specified: args.iPaymentTransaction or args.transactionID',
            );
        }

        const params = {
            action: 'void',
            i_payment_transaction: args.iPaymentTransaction,
            transaction_id: args.transactionID,
            amount: args.amount,
            return_url: args.returnUrl,
        };

        if (args.iCustomer && this.entityType === ENTITY_TYPE_CUSTOMER) {
            params.i_customer = args.iCustomer;
        }

        if (args.iAccount && this.entityType === ENTITY_TYPE_ACCOUNT) {
            params.i_account = args.iAccount;
        }

        const resultCallback = args.resultCallback || ON_VOID_PAYMENT_RESULT_CB;

        return this.sendRequest(
            [this.entityType, 'make_transaction'].join('/'),
            params,
        ).then(
            (response) => this._onVoidPaymentCallback(response),
            (error) => this._onVoidPaymentCallback(error),
        );
    }

    /**
     * Function that sends request to PortaBilling system in order to authorize credit card
     * @param args                 Object. Mandatory. The payment method
     * @param args.cardInfo        Object. Mandatory. The credit card information to use for payment. If not specified - use stored credit card
     * @param args.saveCard        Boolean. Flag shows whether to save credit card on successful payment
     * @param args.iCustomer       Number. The customer unique identifier (if payment is done by non-customer for customer)
     * @param args.iAccount        Number. The account unique identifier (if payment is done by non-account for account)
     * @param args.resultCallback  Function. The callback to execute on after transaction is voided
     * @param args.returnUrl       String. The url to return user to after completing 3D Secure authorization
     * @param args.amount          Number. Optional. Amount to make payment. For card authorization in should be the minimal amount for specific payment method.
     * @retval                     Promise
     */
    authorizeCreditCard(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (!args.cardInfo) {
            throw new Error('args.cardInfo is mandatory');
        }

        const params = {
            action: 'Authorization Only',
            amount: args.amount || TEST_PAYMENT_AMOUNT,
            card_info: args.cardInfo,
            save_card: args.saveCard ? 'Y' : 'N',
            return_url: args.returnUrl,
        };

        if (args.iCustomer && this.entityType === ENTITY_TYPE_CUSTOMER) {
            params.i_customer = args.iCustomer;
        }

        if (args.iAccount && this.entityType === ENTITY_TYPE_ACCOUNT) {
            params.i_account = args.iAccount;
        }

        const resultCallback = args.resultCallback || ON_AUTH_CC_RESULT_CB;

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;

        return this.sendRequest(
            [this.entityType, 'make_transaction'].join('/'),
            params,
        )
            .then(
                (response) => this._onAuthorizeCreditCardCallback(response),
                (error) => this._onAuthorizeCreditCardCallback(error),
            )
            .then(function (result) {
                if (result.redirect_url) {
                    return result;
                }

                if (result.faultstring || !result.iPaymentTransaction) {
                    return resultCallback(result);
                }

                self.getTransactionInfo({
                    iPaymentTransaction: result.iPaymentTransaction,
                }).then(function (result) {
                    if (result?.transaction?.status == 'AUTHORIZED') {
                        result = result.transaction;
                        return self.voidPayment({
                            //iPaymentTransaction: result.i_payment_transaction,
                            transactionID: result.x_transaction_id,
                            amount: result.amount,
                            resultCallback: self._onAuthorizeCreditCardCallback,
                        });
                    } else if (result.redirect_url) {
                        return result;
                    } else {
                        return resultCallback(
                            self._onAuthorizeCreditCardCallback({
                                faultstring:
                                    'Can not authorize the credit card',
                            }),
                        );
                    }
                });
            });
    }

    _onAuthorizeCreditCardCallback(response) {
        if (!response) {
            return {
                faultstring: 'No response was received',
            };
        }

        if (response.faultstring) {
            return {
                faultstring: response.faultstring,
            };
        }

        if (response.redirect_url) {
            const { redirect_url } = response;
            return {
                redirect_url: response.redirect_url,
            };
        }

        if (response.i_payment_transaction) {
            return {
                success: 1,
                iPaymentTransaction: response.i_payment_transaction,
            };
        }

        if (response.success) {
            return response;
        }

        return {
            faultstring: 'unknown error',
        };
    }

    _onVoidPaymentCallback(response) {
        if (!response) {
            return {
                faultstring: 'No response was received',
            };
        }

        if (response.faultstring) {
            return {
                faultstring: response.faultstring,
            };
        }

        if (response.redirect_url) {
            const { redirect_url } = response;
            return {
                redirect_url: response.redirect_url,
            };
        }

        if (response.i_payment_transaction) {
            return {
                success: 1,
            };
        }

        return {
            faultstring: 'unknown error',
        };
    }

    /**
     * Function that sends request to PortaBilling system in order to update transaction based on response from payment gateway
     * @param args                      Object. Mandatory. The payment method
     * @param args.iPaymentTransaction  Number. Mandatory. The unique transaction identifier
     * @param args.resultCallback       Function. The callback to execute on after transaction status is updated
     * @retval                          Promise
     */
    finalizePayment(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (!args.iPaymentTransaction) {
            throw new Error('args.iPaymentTransaction is mandatory');
        }

        const params = {
            i_payment_transaction: args.iPaymentTransaction,
        };

        return this.sendRequest('Payment/finalize_transaction', params)
            .then((result) => {
                return result.faultcode
                    ? result
                    : this.getTransactionInfo(args);
            })
            .then((result) => this._onFinilizeTransaction(result));
    }

    _onFinilizeTransaction(response) {
        if (!response) {
            return {
                faultstring: 'No response was received',
            };
        }

        if (response.faultstring) {
            return {
                faultstring: response.faultstring,
            };
        }

        if (!response.transaction) {
            return {
                faultstring: 'Transaction not found',
            };
        }

        const { transaction } = response;

        if (transaction.status === 'STARTED') {
            return {
                pending: 1,
            };
        }

        if (transaction.status === 'COMPLETED') {
            return {
                success: 1,
            };
        }

        return {
            faultstring: transaction.result_code || 'Unknown error',
        };
    }

    /**
     * Function that sends request to PortaBilling system in order to get the transaction information
     * @param args                      Object. Mandatory. The payment method
     * @param args.iPaymentTransaction  Number. Mandatory. The unique transaction identifier
     * @retval                          Promise
     */
    getTransactionInfo(args) {
        if (!args) {
            throw new Error('args is mandatory');
        }

        if (!args.iPaymentTransaction) {
            throw new Error('args.iPaymentTransaction is mandatory');
        }

        const params = {
            i_payment_transaction: args.iPaymentTransaction,
        };

        return this.sendRequest(
            'Payment/get_payment_transaction_by_id',
            params,
        );
    }
}

export default PBPaymentAPI;

export { ENTITY_TYPE_CUSTOMER, ENTITY_TYPE_ACCOUNT };

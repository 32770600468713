import {makeStyles} from '@material-ui/core';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        width: 'fit-content',
        height: 'fit-content',
        overflow: 'hidden',
        display: 'inline-flex'
    },
    iconContainer: {
        width: 'fit-content',
        height: '100%',
        verticalAlign: 'middle'
    },
    iconSubContainer: {
        width: 20,
        height: 20,
        verticalAlign: 'middle'
    },
    labelsContainer: {
        marginLeft: 12,
        width: 'fit-content',
        height: 'fit-content',
    },
    queuedLabel: {
        width: 'fit-content',
        color: Colors.Gray5,
        fontWeight: 700,
        fontSize: 12,
        whiteSpace: 'nowrap'
    },
    activeLabel: {
        width: 'fit-content',
        color: Colors.Gray9,
        fontWeight: 700,
        fontSize: 12,
        whiteSpace: 'nowrap'
    },
    marginCallIcon: {
        marginTop: 7
    },
    emptyCallsContainer: {
        fontWeight: 700,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        color: Colors.Gray5,
        opacity: 0.5,
        marginLeft: 18
    }
}));
import { OperationMode } from '../../store/actions/extensions/payloads';
import i18n from '../../services/i18n';

export type TransformBitMask = {
    bits: string;
    translation: string;
    index: number[];
    modes: string[];
};

export enum BitMask {
    Any = '1111111111',
}

export enum CallScreeningMode {
    Deafault = 'Normal (Default)',
}

const anyMode = i18n.t<string>('screens:extensions.any');

export function translateBitMak(mask: number, operationModes: OperationMode[]) {
    const output: TransformBitMask = {
        bits: (mask >>> 0).toString(2),
        translation: '',
        index: [],
        modes: [],
    };

    if (output.bits == BitMask.Any) {
        return {
            bits: (mask >>> 0).toString(2),
            translation: anyMode,
            index: [],
            modes: [anyMode],
        };
    }

    const bitStringLength = output.bits.length;

    for (let i = 0; i < bitStringLength; i++) {
        if (output.bits[i] === '1') {
            output.index.push(bitStringLength - 1 - i);
        }
    }

    output.index = output.index.sort(function (a, b) {
        return a - b;
    });

    output.modes = output.index.map(
        (shortCode) =>
            operationModes.find((mode) => mode.short_code == shortCode)?.name ||
            shortCode.toString(),
    );
    output.modes = output.modes.map((item) =>
        item == CallScreeningMode.Deafault
            ? i18n.t<string>('enums:callScreeningMode.Default')
            : item,
    );
    output.translation = output.modes.join(',').replace(/,/g, ', ');

    return output;
}

const replaceAt = (str: string, index: number, replacement: string) => {
    return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}

export function toBitMask(selectedModes: string[], operationModes: OperationMode[]) {
    const valueAny = (1 << BitMask.Any.length) - 1;

    if(!selectedModes || selectedModes.length === 0 || !operationModes || operationModes.length === 0) {
        return valueAny;
    }

    if(selectedModes.length === 1 && selectedModes[0] === anyMode) {
        return valueAny;
    }

    let str = "".padStart(BitMask.Any.length, '0');
    
    for(let name of selectedModes.filter(e => e !== anyMode)) {
        if(name === anyMode) continue;
        if(name === i18n.t<string>('enums:callScreeningMode.Default')) {
            name = CallScreeningMode.Deafault;
        }
        const mode = operationModes.find(e => e.name === name);
        if(!mode) continue;
        str = replaceAt(str, str.length - mode.short_code - 1, '1');
    }

    return parseInt(str, 2);
}
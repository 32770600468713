import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {useMemo} from 'react';
import {EXTENSION_NOT_FOUND_BY_ID_API_ERROR} from "../store/sagas/extensions/extensions/saga";

export const useSipTrunkMain = () => {
    const extension = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.sipTrunk,
    );
    const apiError = useSelector(
        (state: ReduxState) => state.sipTrunks.sipTrunkDetails?.apiErrors,
    );

    const {
        isFormSending,
        isFormDataLoading,
    } = useSelector((state: ReduxState) => state.sipTrunks?.sipTrunkDetails || {
        isFormSending: false,
        isFormDataLoading: false
    });


    return useMemo(() => {
        return {
            notFound: apiError?.faultcode === EXTENSION_NOT_FOUND_BY_ID_API_ERROR,
            sipTrunk: extension,
            isFormSending,
            isFormDataLoading
        };
    }, [extension,apiError,isFormSending,isFormDataLoading]);
};

import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {Close} from '@material-ui/icons';
import {Colors} from '../../styles/Colors';
import CustomizedIconButton from '../IconButton/IconButton';

type SnackbarProps = {
    isOpen: boolean;
    snackbarMessage: string | React.ReactNode;
    handleClose: () => void;
    onButtonClick?: () => void;
    actionLabel?: string;
    dataQa?: string;
    autoHideDuration?: number;
};

const useStyles = makeStyles(() =>
    createStyles({
        message: {
            '& > div': {
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                whiteSpace: 'pre',
            },

            '& .MuiSnackbarContent-message': {
                marginTop: 2,
            },
        },
    }),
);

const CustomizedSnackbar: React.FC<SnackbarProps> = (
    {
        isOpen,
        snackbarMessage,
        handleClose,
        onButtonClick,
        actionLabel,
        dataQa,
        autoHideDuration,
    }) => {
    const classes = useStyles();

    const action = (
        <CustomizedIconButton
            dataQa="close-snackbar-button"
            dataTestId="close-snackbar-button"
            onClick={onButtonClick}
            backgroundWithOpacity
        >
            <Close htmlColor={Colors.White}/>
        </CustomizedIconButton>
    );

    return (
        <div data-qa={dataQa}>
            <Snackbar
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.message}
                onClose={handleClose}
                data-qa={dataQa}
                data-testid={dataQa}
                autoHideDuration={autoHideDuration}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    action={actionLabel ? action : null}
                />
            </Snackbar>
        </div>
    );
};

export default CustomizedSnackbar;

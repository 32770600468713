import React, { useMemo } from 'react';
import { CallHistoryAuxXdr } from '../../store/types/CallHistory';
import { ReactComponent as Fair } from '../../assets/call_quality_fair.svg';
import { ReactComponent as Good } from '../../assets/call_quality_good.svg';
import { ReactComponent as Poor } from '../../assets/call_quality_poor.svg';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

export enum CallQualityEnum {
    good = 'good',
    fair = 'fair',
    poor = 'poor',
}

interface CallQualityProps {
    auxXdrs?: CallHistoryAuxXdr[];
}

const CallQuality: React.VFC<CallQualityProps> = ({
    auxXdrs,
}) => {
    const { t } = useTranslation();
    
    const quality = auxXdrs?.find(e => e.xdr_column_id === 'quality')?.value as CallQualityEnum;

    if(!quality) {
        return (<></>);
    }

    const icon = useMemo(() => {
        switch(quality) {
            case CallQualityEnum.fair:
                return (
                    <Fair data-testid="icon_fair" />
                );
                
            case CallQualityEnum.good:
                return (
                    <Good data-testid="icon_good" />
                );
            
            case CallQualityEnum.poor:
                return (
                    <Poor data-testid="icon_poor" />
                );

            default:
                return (<></>);
        }
    }, [quality]);

    const tooltipKey = useMemo(() => {
        switch(quality) {
            case CallQualityEnum.fair:
                return t('tooltips:calls.callQualityFair');
                
            case CallQualityEnum.good:
                return t('tooltips:calls.callQualityGood');
            
            case CallQualityEnum.poor:
                return t('tooltips:calls.callQualityPoor');

            default:
                return '';
        }
    }, [quality]);
    
    return (
        <CustomizedTooltip
            title={tooltipKey}
            copy={false}
        >
            {icon}
        </CustomizedTooltip>
    );
};

export default CallQuality;

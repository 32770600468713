import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import React, {useEffect} from 'react';
import PlanForm from '../../../components/Forms/Extensions/PlanForm';
import {MainProductType} from '../../../components/Extensions/MainProductSelectFiled/utils';
import {Formik} from 'formik';
import {
    extensionPlanFormDefaultValues,
    ExtensionPlanFormType,
    extensionPlanFormValidationSchema,
} from '../../../components/Forms/Extensions/PlanForm.utils';
import {useTabStyles} from '../../Extensions/ExtensionDetails/utils';
import {useAAPlanFormData} from '../../../hooks/useAAPlanFormData';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import TabErrors from "../../../components/TabErrors/TabErrors";

interface PlanTabInterface extends TabInterface {
    productType: MainProductType;
}

const PlanTab: React.VFC<PlanTabInterface> = (
        {
            handleSubmitForm,
            productType,
            handleInitDataChange,
            handleDirtyChange,
            handleSetSubmitFunc,
            handleSetIsValidFunc
        }
    ) => {
        const classes = useTabStyles();

        const isDataLoading = useSelector(
            (state: ReduxState) =>
                state.autoAttendants.autoAttendantDetails?.planTab?.isLoading ||
                false,
        );

        const {data, initFormData} = useAAPlanFormData();

        useEffect(() => {
            if (!isDataLoading && initFormData) {
                handleInitDataChange?.(initFormData);
                handleSubmitForm?.(initFormData);
            }
        }, [isDataLoading, initFormData]);

        return isDataLoading ? (
            <div className={classes.loader}>
                <Loader dataQa="plan-list-loader"/>
            </div>
        ) : (
            <Formik<ExtensionPlanFormType>
                initialValues={initFormData ?? extensionPlanFormDefaultValues}
                enableReinitialize={true}
                validationSchema={extensionPlanFormValidationSchema}
                validateOnChange={false}
                onSubmit={handleSubmitForm!}
            >

                {() => {
                    return (
                        <>
                            <PlanForm
                                i_account={data.i_account}
                                productType={productType}
                                handleDirtyChange={handleDirtyChange}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                            />

                        </>
                    )
                }
                }

            </Formik>
        );
    }
;

export default PlanTab;

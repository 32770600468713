import { makeStyles } from '@material-ui/core';
import i18n from '../../../../services/i18n';
import {
    OwnerPaymentMethod,
    PaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import { Colors } from '../../../../styles/Colors';
import {
    convertCardValidatorTypeToPaymentType,
    PaymentMethodFormType,
    paymentMethodValidationSchemaHelper,
    getCardType
} from '../PaymentMethodForm/PaymentMethodForm.utils';
import * as Yup from 'yup';
import { PaymentMethodType } from '../../../../store/types/Billing';

export interface MakePaymentFormType extends PaymentMethodFormType {
    amount: string;
    isDefaultPaymentMethod: boolean;
    setAsDefaultPaymentMethod: boolean;
}

export interface MakePaymentDialogProps {
    onSave?: (form: MakePaymentFormType) => void;
    isOpen: boolean;
    toggleVisibility?: () => void;
}

const requiredField = i18n.t('errors:common.emptyInput');

export const newPaymentValidationSchemaHelper = (
    isDefaultPayment: boolean,
    ownerPaymentsMethods: OwnerPaymentMethod[],
    paymentMethod?: PaymentMethodInfo,
    currency?: string,
) => {
    const currentMethod = ownerPaymentsMethods?.find(
        (v) => v.i_payment_method === paymentMethod?.i_payment_method,
    );

    const amountValidation = Yup.string().test(
        'amount',
        '',
        function (value?: string) {
            let minValue = 0;

            if (!this.parent.isDefaultPaymentMethod) {
                if (this.parent.paymentType === PaymentMethodType.CreditCard) {
                    const validatorCardType = getCardType(this.parent.cardNumber + '');
                    if (validatorCardType) {
                        const cardType = convertCardValidatorTypeToPaymentType(
                            validatorCardType
                        );

                        const method = ownerPaymentsMethods.find(
                            (v) => v.payment_method === cardType,
                        );

                        if (method) {
                            minValue = method.min_allowed_payment;
                        }
                    }
                } else {
                    const method = ownerPaymentsMethods.find(
                        (v) => v.payment_method === PaymentMethod.Echeck,
                    );

                    if (method) {
                        minValue = method.min_allowed_payment;
                    }
                }
            } else {
                minValue = currentMethod?.min_allowed_payment || 0;
            }

            const currentValue = parseFloat(value?.replace(/,/g, '') || '0');

            if (minValue && currentValue < minValue) {
                return this.createError({
                    path: this.path,
                    message: i18n.t('errors:billing.minAmount', {
                        value: minValue,
                        currency,
                    }),
                });
            }

            return true;
        },
    );

    if (isDefaultPayment) {
        return Yup.object().shape({
            amount: amountValidation,
            cvv:
                paymentMethod?.csc_required === 1
                    ? Yup.string()
                          .min(
                              3,
                              i18n.t('errors:common.minLengthIs', { value: 3 }),
                          )
                          .max(4)
                          .required(requiredField)
                    : Yup.string(),
        });
    }

    return Yup.object()
        .shape({
            amount: amountValidation,
        })
        .concat(
            paymentMethodValidationSchemaHelper(ownerPaymentsMethods || []),
        );
};

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 700,
            backgroundColor: Colors.SmokeBackground,
            padding: '42px 72px 27px 72px',
        },
    },
    sectionHeader: {
        color: Colors.Text,
        fontWeight: 500,
        paddingBottom: 8,
    },
    otherMethodButton: {
        justifyContent: 'center',
        maxWidth: 'unset',
        paddingLeft: 16,
        paddingRight: 16,
        fontWeight: 700,
        marginTop: 30,
    },
    defaultPaymentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    cvvTextfield: {
        marginLeft: 38,
        width: 180,
        '& .MuiFormControl-root': {
            width: 180,
        },
    },
    defaultPaymentInsideContainer: {
        display: 'flex',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },
    payForm: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    defaultMethodButton: {
        justifyContent: 'center',
        maxWidth: 'unset',
        paddingLeft: 16,
        paddingRight: 16,
        fontWeight: 700,
        marginTop: 10,
    },
}));

import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {Colors, primaryColor} from '../../styles/Colors';
import classNames from 'classnames';
import TabContext from '@material-ui/lab/TabContext';
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import WarningTabLabel from "./WarningTabLabel";
import {actions} from "../../store";

type TabPanelProps = {
    value: number;
    index: number;
    subTabMode?: boolean;
};

const TabPanel: React.FC<TabPanelProps> = ({children, value, index, subTabMode}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            style={{
                visibility: value === index ? 'visible' : 'hidden',
                overflowX: subTabMode ? 'hidden' : 'unset',
                overflowY: subTabMode ? 'hidden' : 'unset',
                height: subTabMode ? 'fit-content' : 'unset'
            }}>
            {subTabMode ? (
                    <Box p={3} width="100%" height="fit-content" paddingLeft="0px !important" paddingTop="0px !important"
                         paddingRight={subTabMode ? '0px !important' : 'inherit'}>
                        {children}</Box>
                )
                : (
                    <Box p={3}>{children}</Box>
                )}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`
    };
}

const useStyles = makeStyles(() => ({
    error: {
        color: Colors.Error + '!important',
        opacity: 1 + '!important'
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',

        '& .MuiTab-root': {
            minWidth: 'unset',
            paddingLeft: 20,
            paddingRight: 20,
        },
        '& .MuiTabs-fixed': {},
        '& .MuiTabs-indicator': {
            backgroundColor: primaryColor,
            height: 3,
            borderRadius: '3px 3px 0px 0px',
        },
        '& .Mui-selected': {
            color: primaryColor + '!important',
            opacity: 1 + ' !important',
        },
        '& .MuiTab-textColorInherit': {
            color: 'black',
            opacity: 0.38,
            fontWeight: 'bold',
        },
    },

    tabs: {},
    tabsContainer: {
        zIndex: 999,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 90px 0 90px',
        backgroundColor: Colors.White,
        borderBottom: `1px solid ${Colors.Border}`,
        '& .MuiTabs-root': {
            '& .MuiTabs-scroller': {
                '& .MuiTabs-flexContainer': {
                    '& button': {
                        textTransform: 'none',
                    },
                },
            },
        }
    },
    minPaddingSubTabMode: {
        paddingLeft: '32px !important',
        height: '60px !important',
        paddingRight: '13px !important',
        '& .MuiTabs-root': {
            '& .MuiTabs-scroller': {
                '& .MuiTabs-flexContainer': {
                    height: '60px !important'
                },
            },
        }
    },

    '@media (max-width: 1276px)': {
        root: {
            '& .MuiBox-root': {
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 80
            },
        }
    },

    '@media (min-width:1277px) and (max-width: 1440px)': {
        root: {
            '& .MuiBox-root': {
                paddingLeft: 90,
                paddingRight: 90,
                paddingBottom: 80
            },
        }
    },

    '@media (min-width: 1441px)': {
        root: {
            '& .MuiBox-root': {
                paddingLeft: 96,
                //here !
                paddingRight: 96,
                paddingBottom: 80
            },
        }
    },
}));

export type TabsProps = {
    tabsNames: (string | undefined)[];
    children: (JSX.Element | undefined | boolean)[];
    dataQa?: string;
    CustomRightSideComponent?: React.ReactNode;
    className?: string;
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
    defaultTabIndex?: number;
    subTabMode?: boolean;
};

const TabsComponent: React.FC<TabsProps> = (
    {
        tabsNames,
        children,
        dataQa,
        CustomRightSideComponent,
        className,
        onChange,
        defaultTabIndex,
        subTabMode
    }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(defaultTabIndex || 0);
    const errors = useSelector((state: ReduxState) => state?.tabs?.errors || []);

    useEffect(() => {
        dispatch(actions.clearErrorInTav())
    }, []);


    return (
        <TabContext value={value.toString()}>
            <div className={classNames(classes.root, className)}>
                <AppBar
                    position="static"
                    elevation={0}
                    className={classNames(classes.tabsContainer, subTabMode ? classes.minPaddingSubTabMode : '')}
                >
                    <Tabs
                        value={value}
                        onChange={(_, newValue) => {
                            setValue(newValue);
                            onChange?.(_, newValue);
                        }}
                        data-qa={dataQa}
                        data-testid={'tabs-bar'}
                    >
                        {tabsNames
                            .filter((v) => !!v)
                            .map((tabName, index) => {
                                    const showError = errors.find(item => item.name == tabName && item.markAsInvalid);
                                    return (
                                        <Tab
                                            key={'TabItem' + index}
                                            value={index}
                                            label={
                                                <WarningTabLabel
                                                    label={tabName || ''}
                                                    showWarning={showError != undefined && showError.index !== value}
                                                />
                                            }
                                            {...a11yProps(index)}
                                            className={showError != undefined && showError.index !== value ? classes.error : undefined}
                                        />
                                    )
                                }
                            )}
                    </Tabs>
                    {CustomRightSideComponent}
                </AppBar>

                {children
                    ?.filter((v) => !!v)
                    .map((child, index) => (
                        <TabPanel
                            value={value}
                            key={'TabPanel' + index}
                            index={index}
                            subTabMode={subTabMode}
                        >
                            {child}
                        </TabPanel>
                    ))}
            </div>
        </TabContext>
    );
};

export default TabsComponent;

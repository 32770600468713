import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import CustomButton from '../../components/Button/Button';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UAUsage } from '../../store/types/Devices';
import i18n from '../../services/i18n';
import TextFieldWithMask from '../../components/TextFieldWithMAsk/TextFieldWithMask';
import TextField from '../../components/TextField/TextField';
import SelectField from '../../components/SelectField/SelectField';
import MacMaskForFilters from '../../components/TextFieldWithMAsk/Masks/MacMaskForFilters';
import MacMaskForFiltersWithoutMask from '../../components/TextFieldWithMAsk/Masks/MacMaskForFiltersWithoutMask';

const useStyles = makeStyles(() => ({
    filters: {
        display: 'flex',
        alignItems: 'center',
    },

    filtersContainer: {
        flex: 1,
        paddingTop: 17,
        paddingBottom: 17,
        marginBottom: 12,
        marginTop: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
    },

    search: {
        width: 96,
        height: 40,
    },

    status: {
        maxWidth: 144 + 'px !important',
        minWidth: 144 + 'px !important',
    },

    unsetMinWidth: {
        minWidth: 'unset',
        width: 144,
        maxWidth: 144,
    },

    filterSeparation: {
        marginRight: 16,
        flex: 1,
        maxWidth: '180px',
    },

    initMac: {
        '& .MuiInputBase-input.MuiInput-input': {
            color: Colors.LightGraySecondary3,
        },
    },
}));

type DevicesFiltersProps = {
    onSubmit?: () => void;
    handleChange?: (
        extension_id?: string,
        name?: string,
        mac?: string,
        inventory_id?: string,
        usage?: UAUsage | string,
    ) => void;
};

const statuses = [
    { value: '', label: i18n.t<string>('screens:devices.anyStatus') },
    {
        value: UAUsage.NotUsed,
        label: i18n.t<string>('screens:devices.notUsedStatus'),
    },
    {
        value: UAUsage.CompletelyUsedAllPorts,
        label: i18n.t<string>('screens:devices.completelyUsedStatus'),
    },
    {
        value: UAUsage.Used,
        label: i18n.t<string>('screens:devices.used'),
    },
];

const DevicesFilters: React.FC<DevicesFiltersProps> = ({
    onSubmit,
    handleChange,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const onFocusInitMac = 'xx:xx:xx:xx:xx:xx';
    const [deviceFilter, setDeviceFilter] = useState('');
    const [extensionFilter, setExtensionFilter] = useState('');
    const [macAddressFilter, setMacAddressFilter] = useState('');
    const [inventoryIdFilter, setInventoryIdFilter] = useState('');
    const [macAddressFocusOn, setMackAddressFocusOn] = useState(false);
    const [statusFilter, setStatusFilter] = useState<{
        label?: string;
        value?: string;
    }>(statuses[0]);

    useEffect(() => {
        if (handleChange != undefined) {
            handleChange(
                extensionFilter,
                deviceFilter,
                macAddressFilter,
                inventoryIdFilter,
                statusFilter ? statusFilter.value : undefined,
            );
        }
    }, [
        deviceFilter,
        extensionFilter,
        macAddressFilter,
        inventoryIdFilter,
        statusFilter,
    ]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit?.();
    };

    return (
        <div className={classNames(classes.filters)}>
            <form className={classes.filtersContainer} onSubmit={handleSubmit} autoComplete="off">
                <div className={classes.filtersInputs}>
                    <TextField
                        id="deviceFilter"
                        className={classes.filterSeparation}
                        label={t('screens:devices.deviceName')}
                        value={deviceFilter}
                        onChange={(e) => setDeviceFilter(e.target.value)}
                        dataQa={'filter-by-device-id'}
                    />
                    <TextField
                        id="extensionFilter"
                        className={classes.filterSeparation}
                        label={t('screens:extensions.extension')}
                        value={extensionFilter}
                        onChange={(e) => setExtensionFilter(e.target.value)}
                        dataQa={'filter-by-extension'}
                    />
                    <TextFieldWithMask
                        id={'device-mac-address'}
                        className={classNames(
                            classes.filterSeparation,
                            macAddressFilter == onFocusInitMac
                                ? classes.initMac
                                : '',
                        )}
                        label={t('screens:devices.macAddress')}
                        onFocus={() => {
                            if (macAddressFilter == '') {
                                setMacAddressFilter(onFocusInitMac);
                            }
                            setMackAddressFocusOn(true);
                        }}
                        onBlur={() => {
                            if (macAddressFilter == onFocusInitMac) {
                                setMacAddressFilter('');
                            }
                            setMackAddressFocusOn(false);
                        }}
                        value={macAddressFilter}
                        onChange={(e) => setMacAddressFilter(e.target.value)}
                        mask={
                            macAddressFocusOn
                                ? MacMaskForFilters
                                : MacMaskForFiltersWithoutMask
                        }
                        autoComplete={"off"}
                    />

                    <TextField
                        id="inventoryIdFilter"
                        className={classes.filterSeparation}
                        label={t('screens:devices.inventoryId')}
                        value={inventoryIdFilter}
                        onChange={(e) => setInventoryIdFilter(e.target.value)}
                        dataQa={'filter-by-email'}
                    />

                    <SelectField
                        className={classNames(
                            classes.filterSeparation,
                            classes.status,
                        )}
                        label={t('common:anyStatus')}
                        classes={{ container: classes.unsetMinWidth }}
                        getOptionLabel={(o) => o.label}
                        items={statuses}
                        dataQa={'filter-by-status'}
                        onChange={(e, newValue) => setStatusFilter(newValue)}
                        value={statusFilter}
                        disableClearable
                    />
                </div>
                <CustomButton
                    dataQa="search-button"
                    primary
                    accent
                    type="submit"
                    className={classes.search}
                >
                    {t('common:search')}
                </CustomButton>
            </form>
        </div>
    );
};

export default DevicesFilters;

import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import CustomizedIconButton from '../IconButton/IconButton';
import { Colors } from '../../styles/Colors';
import classNames from 'classnames';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
import { PermissionType } from '../../store/types/Permission';

export type DataItemActionsProps = {
    onDelete?: () => void;
    onEdit?: () => void;
    classes?: {
        deleteButton?: string;
    };
    editPermission?: string[];
    deletePermission?: string[];
    skipEditPermissionReadOnly?: boolean;
    hideDelete?: boolean;
    customizeDeletePermission?: (permissionValue: PermissionType) => PermissionType;
    customizeEditPermission?: (permissionValue: PermissionType) => PermissionType;
};

const useStyles = makeStyles(() => ({
    buttonSeparation: {
        marginLeft: 5,
    },
    container: {
        display: 'flex',
        flex: 1,
    },
}));

const DataItemsActions: React.VFC<DataItemActionsProps> = ({
    onDelete,
    onEdit,
    classes: customClasses,
    editPermission,
    deletePermission,
    skipEditPermissionReadOnly = true,
    hideDelete = false,
    customizeDeletePermission,
    customizeEditPermission
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <PermissionProvider
                permission={editPermission || []}
                skipReadOnly={skipEditPermissionReadOnly}
                customizePermission={customizeEditPermission}
            >
                <CustomizedIconButton
                    onClick={onEdit}
                    dataTestId="edit-list-item-button"
                    dataQa="edit-list-item-button"
                    tooltipText={t('common:edit')}
                >
                    <Edit htmlColor={Colors.ListIconColor} />
                </CustomizedIconButton>
            </PermissionProvider>

            {onDelete && !hideDelete && (
                <PermissionProvider
                    permission={deletePermission || []}
                    customizePermission={customizeDeletePermission}
                >
                    <CustomizedIconButton
                        onClick={onDelete}
                        dataTestId="remove-list-item-button"
                        dataQa="remove-list-item-button"
                        className={classNames(
                            classes.buttonSeparation,
                            customClasses?.deleteButton,
                        )}
                        tooltipText={t('common:delete')}
                    >
                        <Delete htmlColor={Colors.ListIconColor} />
                    </CustomizedIconButton>
                </PermissionProvider>
            )}
        </div>
    );
};

export default DataItemsActions;

import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            minWidth: 365,
            minHeight: 440,
            backgroundColor: Colors.SmokeBackground,
            padding: '80px 86px 20px 86px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    statusIcon: {
        position: 'absolute',
        top: 80,
    },
    receiptRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '3px 0',
        width: '100%',
    },
    leftReceiptText: {
        color: Colors.Gray5,
        fontSize: 13,

        '&:first-letter': {
            textTransform: 'capitalize',
        },
    },
    boldLeftText: {
        fontWeight: 500,
        color: Colors.Text,
    },
    rightReceiptText: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.Text,
    },
    rightSecondaryReceiptText: {
        fontSize: 14,
        fontWeight: 500,
        color: Colors.Gray5,
        marginLeft: 5,
    },
    rightContainer: {
        display: 'flex',
    },
    rightContainerWithTooltip: {
        maxWidth: 140,
    },
    totalHeader: {
        color: Colors.Gray9,
        fontSize: 13,
    },
    totalText: {
        fontSize: 30,
        color: Colors.Gray5,
        marginBottom: 20,
        marginTop: 6,
    },
    totalBoldText: {
        color: Colors.Text,
        fontWeight: 700,
    },
    receiptDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 195,
        width: 275,
    },
    separator: {
        border: `1.2px dashed ${Colors.Border}`,
        margin: '17px 0',
        width: 275,
    },
    downloadReceiptButton: {
        height: 36,
        '& .MuiButton-label': {
            '& svg': {
                marginRight: 10,
            },
        },
    },
    errorHeader: {
        fontSize: 13,
        color: Colors.Gray5,
        fontWeight: 700,
        width: 275,
        marginBottom: 8,
        marginTop: 10,
    },
    errorText: {
        fontSize: 13,
        color: Colors.Gray5,
        width: 275,
        lineHeight: '18px',
    },
    failedPaymentTotal: {
        textDecoration: 'line-through',
    },
}));

export type PaymentStatusDialogProps = {
    isOpen: boolean;
    toggleVisibility?: () => void;
};

import React, { useEffect, useState } from 'react';
import SelectField from '../SelectField/SelectField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import { CustomerDIDNumberType } from '../../store/types/CustomerDIDNumber';
import classNames from 'classnames';

type DidNumberSelectFieldProps = {
    fetchData: boolean;
    value?: string[];
    onChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
    dataQa?: string;
    onlyFree?: boolean;
    tooltipMsg?: string;
    copyTooltip?: boolean;
    tooltipDataQa?: string;
    id?: string;
    classes?: {
        container?: string;
    };
    i_account?: number;
    getOptionDisabled?: (v: any) => boolean;
    renderDidTags?: (v: string[]) => React.ReactNode;
};

const DidNumberSelectField: React.VFC<DidNumberSelectFieldProps> = ({
    fetchData,
    value,
    onChange,
    dataQa,
    onlyFree = true,
    tooltipMsg,
    copyTooltip = true,
    tooltipDataQa,
    id,
    classes: customClasses,
    i_account,
    getOptionDisabled,
    renderDidTags,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const numbers = useSelector<ReduxState, CustomerDIDNumberType[]>(
        (state) => state.didNumbers.numbers || [],
    );

    const [items, setItems] = useState<string[]>();

    useEffect(() => {
        if (fetchData) {
            dispatch(actions.didNumbersForSelect.request());
        }
    }, [fetchData]);

    const filterNumbers = () => {
        return numbers.filter((v) => !v.i_account).map(mapping) || [];
    };

    useEffect(() => {
        if (numbers) {
            if (i_account) {
                setItems(filterAccountWithFree(numbers, i_account));
            } else if (onlyFree) {
                setItems(filterNumbers);
            } else {
                setItems(numbers.map(mapping));
            }
        }
    }, [i_account, numbers]);

    const mapping = (v: CustomerDIDNumberType) => v.did_number;
    return (
        <SelectField
            id={id}
            label={t('screens:didNumbers.editDIDNumberText')}
            onChange={onChange}
            items={items || []}
            value={value}
            multiple
            icon={
                tooltipMsg ? (
                    <IconWithTooltip
                        dataQa={tooltipDataQa}
                        tooltipText={tooltipMsg}
                        copy={copyTooltip}
                    />
                ) : undefined
            }
            dataQa={dataQa}
            classes={{
                container: classNames(customClasses?.container),
            }}
            dataTestId={'test-id-' + id}
            disableClearable
            getOptionDisabled={getOptionDisabled}
            renderTags={renderDidTags}
            disableAutoSettingValue
        />
    );
};

export default DidNumberSelectField;

const mapping = (v: CustomerDIDNumberType) => v.did_number;

const filterAccountWithFree = (
    items: CustomerDIDNumberType[],
    i_account: number,
): string[] => {
    const fromAccount = items
        .filter(
            (o) => o.i_account == i_account || o.i_master_account == i_account,
        )
        .map(mapping);

    const free = items.filter((v) => !v.i_account).map(mapping);

    return fromAccount.concat(free);
};

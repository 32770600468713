import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Colors} from '../../styles/Colors';
import {TFunction} from 'react-i18next';
import {Column} from 'react-table';
import EmptyRowContent from '../../components/DataTable/EmptyRowContent';
import {RoleUserItem} from '../../store/actions/company/payloads';
import {PortalUserItem} from '../../store/actions/company/payloads';
import dayjs from '../../services/customDayJs';
import StatusItem from '../../components/Extensions/StatusItem';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import i18n from '../../services/i18n';
import {convertFormatFromBackendToDayJs} from '../../utils/dateWithTimezoneConversion';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import {Permission} from "../../store/types/Permission";
import DataItemActions from "../../components/DataTable/DataItemActions";
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";
import { ReduxState } from '../../store/types';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: Colors.SmokeBackground,
        '& .MuiButton-label': {
            color: Colors.White,
        },
        '& .icon-actions-wrapper': {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-40px',
            '& button': {
                marginLeft: 10,
            },
        },
    },

    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '48px 96px',
        '& .MuiTableContainer-root': {
            maxWidth: 1040,
            width: 'unset !important'
        },
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        '& .MuiButtonBase-root': {
            width: 'auto',
            height: 40,
            marginLeft: 15,
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 11,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
        },
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 17,
    },
    filtersHidden: {
        width: 50,
        overflow: 'hidden',
        marginBottom: -92,
    },
    pressableSearchIcon: {
        marginTop: 10,
        marginRight: 17,
        marginLeft: 17,
        cursor: 'pointer',
    },
    filtersContainer: {
        marginTop: 20,
        marginBottom: 16,
        backgroundColor: Colors.LightGray,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    headerRow: {
        textTransform: 'uppercase',
        border: 0,
    },
    headerCell: {
        fontWeight: 700,
        fontSize: 12,
        backgroundColor: Colors.LightGray,
        height: '60px',
    },
    row: {
        borderBottom: 0,
    },
    filterSeparation: {
        marginRight: 18,
        width: '390px',
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    tableContainer: {
        width: 1157,
        overflowX: 'hidden',
    },
    searchButton: {
        marginLeft: 6,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        marginTop: 24,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },

        '& > :first-child': {
            marginTop: 0,
        },

        '& .MuiDialogContent-root': {
            padding: '54px 84px 36px 84px',
        },
    },
    actions: {
        display: 'flex',
        paddingLeft: 0,
        marginLeft: '-3.5rem',
    },
    actionWrapper: {
        width: 156,
    },
    callQueue: {
        width: '60px',
        display: 'flex',
        justifyContent: 'center',
    },
    cell: {
        color: Colors.Gray5,
        fontSize: 14,
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        marginTop: 30,
        color: Colors.Gray5,
        '& .MuiTableRow-root': {
            height: 70,
            borderBottom: `1px solid ${Colors.LightGraySecondary4}`,
            '& .MuiTableRow-footer': {
                height: 64,
            },
        },
        '& .MuiTableBody-root:last-child': {
            borderBottom: 0,
        },
        '& .MuiTableRow-head': {
            height: 64,
        },
    },
    roleSelect: {
        maxWidth: 360,
        width: 192,
        marginRight: 16,
    },
    addUserButton: {
        width: 129,
        height: 35,
    },
    buttonSearch: {
        width: 96,
        height: 40,
    },
}));

export type PortalUserListItem = {
    id?: number;
    login: string;
    status: {
        status: string;
        tooltip: string | null;
    };
    role: number;
    email: string;
    //@ts-ignore
    actions?: any;
};
type ShortDatesElement = {
    activation_date: Date | null;
    expiration_date: Date | null;
};
export const generateColumns = (
    t: TFunction<string>,
    onEdit: (row: PortalUserListItem) => void,
    onDelete: (row: PortalUserListItem) => void,
    classes: ReturnType<typeof useStyles>,
    roleList?: RoleUserItem[]
): Column<PortalUserListItem>[] => {
    
    const { login } = useSelector((state: ReduxState) => state.auth);
    
    return [
        {
            accessor: 'login',
            Header: t<string>('screens:portalUsers.login'),
            width: 1,
            Cell: function Login(params) {
                const login = params.row.original.login;
                if (login) {
                    return (
                        <PermissionLinkCell
                            text={login}
                            onClick={() => onEdit(params.row.original)}
                            permissions={
                                Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                            }
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'status',
            width: 1,
            Header: t<string>('screens:portalUsers.status'),
            Cell: function Status(params) {
                const status = params.row.original.status;
                const blocked = status.status !== 'Disabled' ? 'N' : 'Y';
                const sip = status.status === 'Active' ? 1 : 0;
                if (status.status && status.tooltip) {
                    return (
                        <CustomizedTooltip
                            title={status.tooltip}
                            dataQa={'status-tooltip'}
                            dataTestId={'status-tooltip'}
                            copy={false}
                        >
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    flex: 1,
                                }}
                            >
                                <StatusItem
                                    blocked={blocked}
                                    sip_status={sip}
                                    statusOnCustomMessage={t<string>(
                                        'screens:portalUsers.active',
                                    )}
                                    statusOffCustomMessage={t<string>(
                                        'screens:portalUsers.inactive',
                                    )}
                                    fetchData={() => null}
                                    hideRefresh={true}
                                />
                            </div>
                        </CustomizedTooltip>
                    );
                } else if (status.status) {
                    return (
                        <StatusItem
                            blocked={blocked}
                            sip_status={sip}
                            statusOnCustomMessage={t<string>(
                                'screens:portalUsers.active',
                            )}
                            statusOffCustomMessage={t<string>(
                                'screens:portalUsers.inactive',
                            )}
                            fetchData={() => null}
                            hideRefresh={true}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'email',
            width: 1.5,
            Header: t<string>('screens:portalUsers.email'),
            Cell: function Email(params) {
                const email = params.row.original.email;
                if (email) {
                    return (
                        <OverflowTooltip
                            classes={{
                                text: classes.cell,
                            }}
                            tooltip={email}
                            text={email}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'role',
            width: 1,
            Header: t<string>('screens:portalUsers.role'),
            Cell: function Description(params) {
                const role = params.row.original.role;
                const value =
                    roleList && roleList?.find((e) => e.i_role === role)?.name;
                if (value) {
                    return (
                        <OverflowTooltip
                            text={value}
                            tooltip={value}
                            classes={{text: classes.cell}}
                            copy={false}
                        />
                    );
                }
                return <EmptyRowContent/>;
            },
        },
        {
            accessor: 'actions',
            width: 0.75,
            Header: t<string>('common:actions'),
            Cell: function ActionButtons(params) {
                return (
                    <div className="icon-actions-wrapper">
                        <DataItemActions
                            onDelete={() => onDelete(params.row.original)
                            }
                            onEdit={() => onEdit(params.row.original)}
                            editPermission={
                                Permission.MyCompany
                                    .PortalUsers.PortalUsersDetails.value
                            }
                            deletePermission={
                                Permission.MyCompany.PortalUsers.DeletePortalUser.value
                            }
                            hideDelete={params.row.original.login?.toLocaleLowerCase() === login?.toLocaleLowerCase()}
                        />

                    </div>

                );
            },
        },
    ];
};

export const calculateStatus = (
    element: PortalUserItem | ShortDatesElement,
    userDateFormat?: string,
) => {
    const now = dayjs();
    let status = {status: 'Active', tooltip: ''};

    if (element.expiration_date && element.activation_date) {
        status =
            now < dayjs(element.activation_date) &&
            now < dayjs(element.expiration_date)
                ? {
                    status: 'Inactive',
                    tooltip: i18n.t<string>(
                        'tooltips:myCompany.willBeActivedOn',
                        {
                            value: dayjs(element.activation_date).format(
                                convertFormatFromBackendToDayJs(
                                    userDateFormat || 'MMM DD, YYYY',
                                ),
                            ),
                        },
                    ),
                }
                : {
                    status: 'Active',
                    tooltip: i18n.t<string>(
                        'tooltips:myCompany.expirationDate',
                        {
                            value: dayjs(element.expiration_date).format(
                                convertFormatFromBackendToDayJs(
                                    userDateFormat || 'MMM DD, YYYY',
                                ),
                            ),
                        },
                    ),
                };
    } else if (element.expiration_date) {
        status =
            now > dayjs(element.expiration_date)
                ? {
                    status: 'Disabled',
                    tooltip: i18n.t<string>('tooltips:myCompany.expiredOn', {
                        value: dayjs(element.expiration_date).format(
                            convertFormatFromBackendToDayJs(
                                userDateFormat || 'MMM DD, YYYY',
                            ),
                        ),
                    }),
                }
                : {
                    status: 'Active',
                    tooltip: i18n.t<string>(
                        'tooltips:myCompany.expirationDate',
                        {
                            value: dayjs(element.expiration_date).format(
                                convertFormatFromBackendToDayJs(
                                    userDateFormat || 'MMM DD, YYYY',
                                ),
                            ),
                        },
                    ),
                };
    } else if (element.activation_date) {
        status =
            now < dayjs(element.activation_date)
                ? {
                    status: 'Inactive',
                    tooltip: i18n.t<string>(
                        'tooltips:myCompany.willBeActivedOn',
                        {
                            value: dayjs(element.activation_date).format(
                                convertFormatFromBackendToDayJs(
                                    userDateFormat || 'MMM DD, YYYY',
                                ),
                            ),
                        },
                    ),
                }
                : {
                    status: 'Active',
                    tooltip: i18n.t<string>(
                        'tooltips:myCompany.expirationDate',
                        {
                            value: dayjs(element.activation_date).format(
                                convertFormatFromBackendToDayJs(
                                    userDateFormat || 'MMM DD, YYYY',
                                ),
                            ),
                        },
                    ),
                };
    }
    return status;
};

import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { actions } from '../../..';
import { Customer } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import { EditExtensionForm } from '../../../../views/Extensions/ExtensionDetails/utils';
import { api } from '../../../services/axios';
import { RingGroupType } from '../../../types/RingGroup';

export function* getExtensionRingGroups(
    action: ActionType<typeof actions.getExtensionRingGroups.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({ i_c_ext: action.payload.i_c_ext, get_main_office_huntgroups: 1 });
    const res: AxiosResponse<{
        huntgroup_list: RingGroupType[];
        total: number;
    }> = yield api.post(Customer.GetHuntGroupList, body);

    body.delete('params');

    const allRingGroupsResponse: AxiosResponse<{
        huntgroup_list: RingGroupType[];
        total: number;
    }> = yield api.post(Customer.GetHuntGroupList, body);

    yield put(
        actions.getExtensionRingGroups.success({
            extensionRingGroups: res.data.huntgroup_list,
            allRingGroups: allRingGroupsResponse.data.huntgroup_list,
        }),
    );
}

export function* editExtensionRingGroups(
    data: Partial<EditExtensionForm>,
    action: ActionType<typeof actions.editExtension.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);

    const body = new JSONFormData(session_id, csrf_token);
    if (data.extensionRingGroups) {
        const initialRingGroupsIds = action.payload.initialValues.extensionRingGroups.map(
            (v) => v.id,
        );

        const changedRingGroupsIds = data.extensionRingGroups?.map((v) => v.id);

        for (const v of data.extensionRingGroups) {
            body.setParams({
                i_c_group: v.i_c_group,
                [`${
                    initialRingGroupsIds.includes(v.id) ? 'chg' : 'add'
                }_extensions`]: v.assigned_extensions?.map(e => {
                    return {
                        ...e,
                        hunt_delay: e.hunt_delay !== undefined ? e.hunt_delay : '',
                        hunt_expire: e.hunt_expire != undefined ? e.hunt_expire : '',
                    }
                }),
            });
            yield api.post(Customer.UpdateCustomerHuntGroup, body);
        }

        for (const v of action.payload.initialValues.extensionRingGroups) {
            if (!changedRingGroupsIds?.includes(v.id)) {
                body.setParams({
                    i_c_group: v.i_c_group,
                    del_extensions: [
                        v.assigned_extensions?.find(
                            (w) => w.i_c_ext === action.payload.extensionId,
                        )?.i_cg_ext,
                    ],
                });
                yield api.post(Customer.UpdateCustomerHuntGroup, body);
            }
        }
    }
}

import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import CustomizedCheckbox from '../../Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import {
    AddonsValidationResults,
    CustomerAgreementCondition,
} from '../../../store/types/CustomerAgreementCondition';
import { actions } from '../../../store';
import {
    getConflictedAddons,
    getConflictedAddonsWithAddon,
} from '../../../utils/extensions/getConflictedAddons';
import Loader from '../../Loader/Loader';
import MainProductSelectField from '../../Extensions/MainProductSelectFiled/MainProductSelectField';
import { filterProductsByType } from '../../Extensions/MainProductSelectFiled/utils';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import {
    ExtensionPlanFormType, extensionPlanFormValidationReqFiledsName,
    PlanFormProps,
    useStyles,
} from './PlanForm.utils';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import {isAnyKEyFromSearchExistInSource} from "../../../utils/isAnyKEyFromSearchExistInSource";

const PlanForm: React.FC<PlanFormProps> = ({
    i_account,
    productType,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    tabIndex,tabName
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    const products = useSelector<
        ReduxState,
        | {
              mainProducts: CustomerAgreementCondition[];
              addonsProducts: CustomerAgreementCondition[];
          }
        | undefined
    >((state) => state.extensions.products);

    const addonsConflicts = useSelector<
        ReduxState,
        AddonsValidationResults | undefined
    >((state) => state.extensions.products?.addonsConflicts);

    const {
        values,
        setFieldValue,
        errors,
        setFieldError,
        dirty,
        isValid,
        handleSubmit,
        initialValues,
    } = useFormikContext<ExtensionPlanFormType>();

    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKEyFromSearchExistInSource(errorsKeys, extensionPlanFormValidationReqFiledsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors,values]);


    const [conflicts, setConflicts] = useState<number[]>([]);
    const [
        areAddonsConflictsLoading,
        setAreAddonsConflictsLoading,
    ] = useState<boolean>(true);

    const validateAddons = (mainProductId: number) => {
        dispatch(
            actions.validateAddonsProducts.request({
                mainProductId,
            }),
        );
    };

    useEffect(() => {
        if (values.selectedMainProduct !== -1) {
            setAreAddonsConflictsLoading(true);
            validateAddons(values.selectedMainProduct);
        }
    }, [products?.mainProducts, values.selectedMainProduct, i_account]);

    const changeAddon = useCallback(
        (value: number) => {
            let addonsCopy: number[] = [...values.selectedAddonsProducts];

            if (values.selectedAddonsProducts.includes(value)) {
                addonsCopy = values.selectedAddonsProducts.filter(
                    (v) => v !== value,
                );
            } else {
                addonsCopy.push(value);
            }
            setFieldValue(`selectedAddonsProducts`, addonsCopy, false);
            checkConflicts(addonsCopy);
        },
        [values.selectedAddonsProducts, addonsConflicts],
    );

    const checkConflicts = useCallback(
        (addons: number[]) => {
            if (addonsConflicts) {
                const conflictsArray = getConflictedAddons(
                    addons,
                    addonsConflicts,
                );
                setConflicts(conflictsArray);

                const addonsCopy = addons.filter(
                    (v) => !conflictsArray.includes(v),
                );

                setFieldValue(`selectedAddonsProducts`, addonsCopy, false);
                setAreAddonsConflictsLoading(false);
            }
        },
        [addonsConflicts],
    );

    useEffect(() => {
        checkConflicts(values.selectedAddonsProducts);
    }, [addonsConflicts]);

    useEffect(() => {
        handleDirtyChange?.('plan', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetIsValidFunc?.('plan', () => isValid);
    }, [isValid]);

    useEffect(() => {
        handleSetSubmitFunc?.('plan', handleSubmit);
    }, [handleSubmit]);

    const addonsProducts = filterProductsByType(
        productType,
        products?.addonsProducts,
    );

    const isElementDisabled = (item: {
        used_quantity: number;
        max_offered_quantity: number;
        i_product: number;
    }) =>
        (item.max_offered_quantity <= item.used_quantity &&
            !!item.max_offered_quantity &&
            !initialValues.selectedAddonsProducts.includes(item.i_product)) ||
        conflicts.includes(item.i_product);

    return (
        <div className={classes.inputs}>
            <Grid className={classes.itemsContainer}>
                <MainProductSelectField
                    dataQa="main-product-select"
                    fetchData={!!i_account}
                    mainProductId={values.selectedMainProduct}
                    setFieldError={setFieldError}
                    onChange={(_, value) => {
                        setFieldValue('selectedMainProduct', value.i_product);
                        setAreAddonsConflictsLoading(true);
                        validateAddons(value.i_product);
                    }}
                    errorMsg={errors?.selectedMainProduct}
                    i_account={i_account}
                    productType={productType}
                />
            </Grid>

            <Grid className={classes.expanderMargin}>
                <div className={classes.titleContainer}>
                    {t('screens:extensions.addonProducts')}
                </div>
                {!addonsProducts.length ? (
                    <div className={classes.noData}>
                        {t('screens:extensions.noCompatibleAddonProducts')}
                    </div>
                ) : (
                    <Grid
                        container
                        spacing={2}
                        className={classes.itemsElementsContainer}
                    >
                        {addonsProducts.map((item) => (
                            <Grid
                                item
                                xs={12}
                                key={item.i_product}
                                className={classes.itemContainer}
                            >
                                <CustomizedTooltip
                                    disableHoverListener={
                                        !conflicts.includes(item.i_product)
                                    }
                                    title={t(
                                        'screens:extensions.addonIncompatibleWith',
                                        {
                                            value: addonsConflicts
                                                ? getConflictedAddonsWithAddon(
                                                      item.i_product,
                                                      addonsConflicts,
                                                  ).join(', ')
                                                : '',
                                        },
                                    )}
                                    className={
                                        isElementDisabled(item)
                                            ? classes.addonItems
                                            : ''
                                    }
                                    copy={false}
                                >
                                    <div>
                                        <CustomizedCheckbox
                                            dataQa="addon-product"
                                            checked={values.selectedAddonsProducts.includes(
                                                item.i_product,
                                            )}
                                            onChange={() =>
                                                changeAddon(item.i_product)
                                            }
                                            disabled={isElementDisabled(item)}
                                            className={classes.checkboxAddon}
                                        />
                                    </div>
                                </CustomizedTooltip>
                                <OverflowTooltip
                                    classes={{
                                        text: classes.textOverflow,
                                    }}
                                    tooltip={item.product_name}
                                    text={<span>{item.product_name}</span>}
                                    copy={false}
                                />

                                <span className={classes.customOptionSemiValue}>
                                    {item.max_offered_quantity
                                        ? `${item.used_quantity}/${item.max_offered_quantity}`
                                        : t('common:noLimit')}
                                </span>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {areAddonsConflictsLoading && (
                    <div className={classes.loaderWrapper}>
                        <Loader dataQa="addons-loader" absolutePosition />
                    </div>
                )}
            </Grid>
        </div>
    );
};

export default PlanForm;

import { MainProductType } from '../../Extensions/MainProductSelectFiled/utils';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import {ErrorTabInteface} from "../RingGroups/generalFormUtils";

export type ExtensionPlanFormType = {
    selectedMainProduct: number;
    selectedAddonsProducts: number[];
};
export const extensionPlanFormValidationSchema = Yup.object().shape({
    selectedMainProduct: Yup.number().required(),
    selectedAddonsProducts: Yup.array()
        .of(Yup.number().notRequired())
        .notRequired(),
});

export const extensionPlanFormValidationReqFiledsName: string[] = [
    'selectedMainProduct',
    'selectedAddonsProducts'
];
export const extensionPlanFormDefaultValues: ExtensionPlanFormType = {
    selectedMainProduct: -1,
    selectedAddonsProducts: [],
};
export type PlanFormProps = {
    i_account?: number;
    productType?: MainProductType;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
} & ErrorTabInteface;
export const useStyles = makeStyles(() => ({
    itemsContainer: {
        // padding: '22px 25px 17px 6px',
        maxWidth: 512,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                height: '58px !important',
            },
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        maxWidth: 560,
        padding: '22px 16px',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',
        maxWidth: 986,
        alignItems: 'center',
    },
    rowBoxHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.Text,
    },
    boldHeader: {
        fontWeight: 700,
    },
    checkbox: {
        '& .MuiTypography-body1': {
            fontWeight: 'bold',
            color: Colors.Text,
            fontSize: 14,
        },
    },
    itemContainer: {
        padding: '0 !important',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: 24,
        '& .MuiFormControlLabel-root': {
            marginRight: 12,
            '& .MuiButtonBase-root': {
                paddingRight: 0,
                paddingTop: 0,
            },
        },
    },
    itemsElementsContainer: {
        padding: '24px 48px 30px 13px',

        '& .MuiFormControlLabel-label': {
            color: Colors.Text,
            fontSize: 16,
        },

        '& div:nth-child(2n)': {
            paddingRight: '0 !important',
        },
    },
    noData: {
        color: Colors.Gray,
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 20,
    },
    expanderMargin: {
        marginTop: 6,
        position: 'relative',
    },
    option: {
        height: '48px !important',
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    addonItems: {
        '& .MuiTypography-root': {
            color: Colors.Gray,
        },
    },
    textOverflow: {
        paddingTop: 2,
        '-webkit-line-clamp': 3,
        position: 'relative',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        maxHeight: '90px',
        whiteSpace: 'normal',
        lineHeight: '20px',
    },
    titleContainer: {
        margin: '12px 5px 0 5px',
        fontWeight: 'bold',
        fontSize: 16,
    },
    loaderWrapper: {
        '& div': {
            background: 'unset !important',
        },
    },
    checkboxAddon: {
        '& .MuiButtonBase-root': {
            paddingTop: '9px !important',
            paddingRight: '9px !important',
            marginTop: '-9px',
            marginRight: '-9px',
        },
    },
}));

import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { APIErrorInterface } from '../../../types';
import { SipStatus } from '../../../types/SipStatus';

export interface SipReducerState {
    sipInfo?: SipStatus;
    permittedSipProxies?: string[];
    savingSipInfo?: boolean;
    sipInfoErrors?: APIErrorInterface;
}

export const initialState: SipReducerState = {};

export type SipReducerActions = ActionType<typeof actions>;

export const sipReducer = createReducer<SipReducerState, SipReducerActions>(
    initialState,
)
    .handleAction(actions.getPermittedSipProxies.request, (state) =>  ({
        ...state,
        sipInfoErrors: undefined
    }))
    .handleAction(actions.getPermittedSipProxies.success, (state, action) => {
        return {
            ...state,
            permittedSipProxies: ['', ...action.payload.map((v) => v.proxy_name)],
            sipInfoErrors: undefined
        };
    })
    .handleAction(actions.getPermittedSipProxies.failure, (state, action) => ({
        ...state,
        sipInfoErrors: action.payload
    }))
    ;

export default sipReducer;

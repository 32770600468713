import MomentUtils from '@date-io/moment';
import React, {useState, useEffect} from 'react';
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {Colors} from '../../styles/Colors';
import {
    createMuiTheme,
    makeStyles,
    MuiThemeProvider,
} from '@material-ui/core/styles';
import {CalendarToday} from '@material-ui/icons';
import classNames from 'classnames';
import {convertFormatFromBackendToDayJs} from '../../utils/dateWithTimezoneConversion';
import {
    keyboardDatePickerMaskPropsForDMONYYFormat,
    keyboardDateTimePickerMaskPropsForDMONYYFormat,
} from './utils';
import {useTranslation} from 'react-i18next';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";

const theme = createMuiTheme({
    typography: {
        allVariants: { },
        button:{textTransform:'uppercase'}
    },
    palette: {
        primary: {
            main: Colors.Primary,
            contrastText: Colors.White,
            dark: Colors.White,
        },
        error: {
            main: Colors.Error,
        },
    },
});

export const defaultMinTime = new Date('1900/01/01');
export const defaultMaxTime = new Date('2100/01/31');

export const defaultMinTimeWithHours = new Date('1900/01/01 12:00:00');
export const defaultMaxTimeWithHouts = new Date('2100/01/31 12:00:00');

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 209,

        borderRadius: '4px 4px 0px 0px',

        '& .MuiInputLabel-formControl': {
            transform: 'translate(0, 22px) scale(1)',
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 11px) scale(0.75)',
        },
        '& .MuiFormLabel-root': {
            marginLeft: 16,
            zIndex: 1,
        },

        '& .MuiFormLabel-root.Mui-focused': {
            color: Colors.Primary,
        },

        '& .MuiFormHelperText-root': {
            marginLeft: 16,
            marginRight: 16,
            marginBottom: -25,
        },

        '& .MuiFormLabel-root.Mui-error': {
            color: Colors.Error,
        },

        '& .MuiInputLabel-asterisk': {
            color: Colors.Error,
        },

        '& .MuiInputBase-root': {
            height: 56,
            marginTop: 0,
            backgroundColor: Colors.White,
            '& .MuiInputBase-input': {
                paddingRight: '0 !important',
            },
            '&:before': {
                borderBottom: `1px solid ${Colors.Border}`,
            },
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        '& .MuiInputBase-input': {
            padding: '16px 10px 0 16px !important',
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: `${Colors.Primary} !important`,
        },

        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: `${Colors.Error} !important`,
        },

        '& .MuiButtonBase-root': {
            padding: 10,
        },
    },
    disabledContainer: {
        '& .MuiSvgIcon-root': {
            opacity: 0.6,
        },

        '& .Mui-disabled:before': {
            borderBottom: `1px dashed ${Colors.LightGraySecondary}`,
        },
    },

    readOnlyContainer: {
        backgroundColor: 'transparent !important',
        '& .Mui-disabled:hover:before': {
            borderBottomColor: 'inherit',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent !important',
        },

        '& .Mui-disabled:before': {
            borderBottom: `1px solid ${Colors.BorderDark}`,
        },
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: Colors.PlaceHolder,
        },
        '& .Mui-disabled': {
            '& .MuiInputAdornment-root': {
                '& .MuiSvgIcon-root': {
                    fill: Colors.Gray9,
                },
            },
        },
    },

    calendarIcon: {
        fill: `${Colors.Gray5} !important`,
    },
    dateTimePicker: {
        minWidth: 230,
    },
}));

type TimePickerProps = {
    value?: ParsableDate;
    onChange?: (value?: string | null) => void;
    icon?: React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    label?: string;
    required?: boolean;
    dataQa?: string;
    dataTestId?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    withHours?: boolean;
    handleSubmit?: (e: React.ChangeEvent<any>) => void;
    allowEmptyDate?: boolean;
    userDateTimeFormat?: string;
    helperText?: string;
    disablePast?: boolean;
    setFieldError?: (field: string, value: string | undefined) => void;
    id?: string;
    skipPermission?: boolean;
};

const CustomKeyboardDateTimePicker: React.FC<TimePickerProps> = (
    {
        value,
        onChange,
        disabled,
        readOnly,
        className,
        label,
        required,
        dataQa,
        dataTestId,
        minDate,
        maxDate,
        withHours = true,
        handleSubmit,
        allowEmptyDate = false,
        userDateTimeFormat,
        helperText,
        disablePast,
        setFieldError,
        id,
        skipPermission
    }) => {
    const {t} = useTranslation();
    let permission = usePermissionContext();

    permission = skipPermission ? PermissionType.Visible : permission;
    readOnly = readOnly || permission === PermissionType.ReadOnly


    const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate | null | string>(
        allowEmptyDate && !value?.toString()
            ? null
            : value?.toString() || new Date().toString(),
    );

    useEffect(() => {
        if (allowEmptyDate && value == null) {
            handleDateChange(null);
        } else if (value?.toString()) {
            handleDateChange(value?.toString());
        }
    }, [value]);

    const onKeyPress = (ev: React.KeyboardEvent<any>) => {
        if (ev?.key === 'Enter' && handleSubmit) {
            handleSubmit(ev);
            ev.preventDefault();
        }
    };

    const onInputKeyPress = () => {
        !!id && setFieldError?.(id, undefined);
    };

    const classes = useStyles();
    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
                locale={
                    window.localStorage
                        .getItem('user_language')
                        ?.toLowerCase() || 'en'
                }
                utils={MomentUtils}
            >
                {!withHours ? (
                    <KeyboardDatePicker
                        id={id}
                        ampm={false}
                        InputProps={{
                            //@ts-ignore - missing props in ui ts definitions
                            'data-testid': dataTestId,
                            onKeyDown: onInputKeyPress,
                        }}
                        label={label}
                        value={selectedDate}
                        onChange={(v) => {
                            handleDateChange(v);
                            onChange?.(v?.format('YYYY-MM-DD'));
                            !!id && setFieldError?.(id, undefined);
                        }}
                        keyboardIcon={
                            <CalendarToday
                                className={classes.calendarIcon}
                                htmlColor={Colors.Gray5}
                                data-testid="select-date-button"
                                data-qa="select-date-button"
                            />
                        }
                        format={convertFormatFromBackendToDayJs(
                            userDateTimeFormat || 'yyyy-MM-DD',
                        )}
                        className={classNames(
                            classes.root,
                            className,
                            disabled && classes.disabledContainer,
                            readOnly && classes.readOnlyContainer,
                        )}
                        disabled={disabled || readOnly}
                        required={required}
                        data-qa={dataQa}
                        minDate={minDate ?? defaultMinTime}
                        maxDate={maxDate ?? defaultMaxTime}
                        disablePast={disablePast}
                        onKeyPress={onKeyPress}
                        error={!!helperText}
                        helperText={helperText}
                        cancelLabel={t('common:cancel')}
                        okLabel={t('common:ok')}
                        {...(userDateTimeFormat?.includes('D-MON-YY')
                            ? keyboardDatePickerMaskPropsForDMONYYFormat
                            : {})}
                    />
                ) : (
                    <KeyboardDateTimePicker
                        id={id}
                        ampm={false}
                        InputProps={{
                            //@ts-ignore - missing props in ui ts definitions
                            'data-testid': dataTestId,
                            onKeyDown: onInputKeyPress,
                        }}
                        label={label}
                        value={selectedDate}
                        onChange={(v) => {
                            handleDateChange(v);
                            onChange?.(v?.format('YYYY-MM-DD HH:mm:ss'));
                            !!id && setFieldError?.(id, undefined);
                        }}
                        keyboardIcon={
                            <CalendarToday
                                className={classes.calendarIcon}
                                htmlColor={Colors.Gray5}
                                data-testid="select-date-button"
                                data-qa="select-date-button"
                            />
                        }
                        format={convertFormatFromBackendToDayJs(
                            userDateTimeFormat || 'yyyy-MM-DD HH:mm:ss',
                        )}
                        className={classNames(
                            classes.root,
                            classes.dateTimePicker,
                            className,
                            disabled && classes.disabledContainer,
                            readOnly && classes.readOnlyContainer,
                        )}
                        disabled={disabled || readOnly}
                        required={required}
                        data-qa={dataQa}
                        minDate={minDate ?? defaultMinTimeWithHours}
                        maxDate={maxDate ?? defaultMaxTimeWithHouts}
                        onKeyPress={onKeyPress}
                        error={!!helperText}
                        helperText={helperText}
                        disablePast={disablePast}
                        cancelLabel={t('common:cancel')}
                        okLabel={t('common:ok')}
                        //@ts-ignore
                        refuse={
                            userDateTimeFormat?.includes('AM')
                                ? /[^AP0-9]+/gi
                                : undefined
                        }
                        {...(userDateTimeFormat?.includes('D-MON-YY')
                            ? keyboardDateTimePickerMaskPropsForDMONYYFormat
                            : {})}
                    />
                )}
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};

export default CustomKeyboardDateTimePicker;

import dayjs from '../services/customDayJs';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const onlyDateFormat = 'YYYY-MM-DD';
const userDateFormat = 'MMM DD, YYYY HH:mm:ss';

export const convertUtcToUserLocalTime = (
    dateString: string,
    timezoneOffset: number,
    customDateFormat?: string,
) => {
    return dayjs
        .utc(dateString, dateFormat)
        .utcOffset(timezoneOffset / 60)
        .format(
            convertFormatFromBackendToDayJs(customDateFormat || dateFormat),
        );
};

export const convertUserLocalTimeToUtc = (
    dateString: string,
    timezoneOffset: number,
) => {
    return dayjs
        .utc(dateString, dateFormat)
        .utcOffset(-(timezoneOffset / 60))
        .format(dateFormat);
};

export const convertUserLocalDateToUtc = (
    dateString: string,
    timezoneOffset: number,
) => {
    return dayjs
        .utc(dateString, onlyDateFormat)
        .utcOffset(-(timezoneOffset / 60))
        .format(onlyDateFormat);
};

export const convertToUserFormat = (
    dateString: string,
    customFormat?: string,
) => {
    return dayjs(dateString, dateFormat).format(customFormat || userDateFormat);
};

export const convertFormatFromBackendToDayJs = (format: string) => {
    return format
        .replace(/MONTH/g, 'MMMM')
        .replace(/MON/g, 'MMM')
        .replace(/HH12/g, 'hh')
        .replace(/HH24/g, 'HH')
        .replace(/MI/g, 'mm')
        .replace(/SS/g, 'ss')
        .replace(/AM/g, 'A');
};

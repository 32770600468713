import { ActionType } from 'typesafe-actions';
import { Invoice } from '../../../services/endpoints';
import { api } from '../../services/axios';
import {
    GetInvoiceListResponse,
    GetTransactionsTotalInfoResponse,
    PaymentMethodType, ServiceInfo
} from '../../types/Billing';
import { AxiosResponse } from 'axios';
import { takeLatest, select, put, delay } from 'redux-saga/effects';
import { Customer, Payment } from '../../../services/endpoints';
import JSONFormData from '../../../utils/JSONFormData';
import * as actions from '../../actions';
import { ReduxState } from '../../types';
import { CustomerInfo, CustomerInfoDetails } from '../../types/CustomerInfo';
import {
    getCountriesList,
    getGlobalCurrencyData,
    getGlobalCustomerInfo,
    getSessionData,
    getSubdivisionsData,
} from '../generic/saga';
import fileDownload from 'js-file-download';
import {
    AutoPaymentInfo,
    OwnerPaymentMethod,
    PaymentDetails,
    PaymentMethod,
    PaymentMethodInfo,
    TaxesResponse,
} from '../../types/Payment';
import { CallHistory } from '../../types/CallHistory';
import { compareObjectsAndReturnDifferencesInValues } from '../../../utils/compareObjects';
import qs from 'qs';
import toast from 'react-hot-toast';
import i18n from '../../../services/i18n';
import { convertCardValidatorTypeToPaymentType, getCardType } from '../../../components/Forms/Billing/PaymentMethodForm/PaymentMethodForm.utils';
import dayjs from '../../../services/customDayJs';
import PBPaymentAPI, {
    ENTITY_TYPE_CUSTOMER,
} from '../../../utils/payments/pb-payment-api';
import Config from '../../../config.json';
import { roundUp, truncate } from '../../../utils/roundUp';
import {getServiceColor} from "../../../styles/Colors";

export function* getBasicBillingData() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );

        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ effective_values: 1 });

        const response: AxiosResponse<CustomerInfo> = yield api.post(
            Customer.GetCustomerInfo,
            body,
        );

        yield getGlobalCurrencyData();
        yield getPaymentMethodData();
        yield getCountriesList();

        const country: string | undefined = yield select(
            (state: ReduxState) =>
                state.billing.customerPaymentMethod?.iso_3166_1_a2 ||
                response.data.customer_info.country,
        );

        if (country) {
            yield getSubdivisionsData(
                actions.getSubdivisionData.request({
                    iso_3166_1_a2: country,
                }),
            );
        }

        yield put(
            actions.getBasicBillingInfo.success(response.data.customer_info),
        );
    } catch (err: any) {
        yield put(actions.getBasicBillingInfo.failure(err?.response?.data));
    }
}

export function* getInvoiceList(
    action: ActionType<typeof actions.getInvoicesListAction.request>,
) {
    const { session_id, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );
    //@ts-ignore
    const tz_offset = yield select(
        (state: ReduxState) => state.generic.sessionData?.tz_offset,
    );
    //@ts-ignore
    const out_date_format = yield select(
        (state: ReduxState) =>
            state.generic.globalCustomerInfo?.customer_info.out_date_format,
    );
    //@ts-ignore
    const iso_4217 = yield select(
        (state: ReduxState) =>
            state.generic.globalCustomerInfo?.customer_info.iso_4217,
    );

    if (tz_offset == undefined) {
        yield getSessionData();
    }

    if (out_date_format == undefined || iso_4217 == undefined) {
        yield getGlobalCustomerInfo();
    }

    try {
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            complex_ordering: [
                {
                    direction: 'DESC',
                    field: 'i_invoice',
                },
                {
                    direction: 'DESC',
                    field: 'issue_date',
                },
            ],
            limit: action.payload.limit,
            offset: action.payload.offset,
            invoice_number: action.payload.invoiceNumber,
            issued_after: action.payload.issued_after,
            issued_before: action.payload.issued_before,
            covers_date: action.payload.covers_date,
            hide_void: action.payload.hide_void,
            invoice_status_list: action.payload.status?.value
                ? [
                      {
                          i_invoice_status: action.payload.status?.value,
                      },
                  ]
                : undefined,
        });
        const res: AxiosResponse<GetInvoiceListResponse> = yield api.post(
            Invoice.GetInvoiceList,
            body,
        );

        yield put(
            actions.getInvoicesListAction.success({
                items: res.data.invoice_list,
                total: res.data.total,
                summary: res.data.invoices_summary,
            }),
        );
    } catch (e) {
        yield put(actions.getInvoicesListAction.failure());
    }
}

export function* getInvoiceFile(
    action: ActionType<typeof actions.getInvoiceSelectedFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(action.payload);

        const res: AxiosResponse<string> = yield api.post(
            Invoice.GetInvoiceInfo,
            body,
            { responseType: 'blob' },
        );

        fileDownload(res.data, `${action.payload.fileName}.pdf`);
        action.payload.callback && action.payload.callback();
    } catch (err) {
        yield put(actions.getInvoiceSelectedFile.failure());
    }
}

export function* getTransactionList(
    action: ActionType<typeof actions.getTransactionList.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ ...action.payload });

        const res: AxiosResponse<GetTransactionsTotalInfoResponse> = yield api.post(
            Customer.GetTransactionsTotalInfo,
            body,
        );

        res.data.per_service_info.forEach((item: ServiceInfo) => item.color = getServiceColor(item.i_service));

        yield put(
            actions.getTransactionList.success({
                items: res.data.per_service_info,
                iso_4217: res.data.iso_4217,
                total_amount: res.data.total_amount,
                count: res.data.count,
            }),
        );
    } catch (e) {
        yield put(actions.getTransactionList.failure());
    }
}

export function* getTransactionCsvFile(
    action: ActionType<typeof actions.getTransactionCsvFile.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ ...action.payload, format: 'csv' });

        const res: AxiosResponse<string> = yield api.post(
            Customer.GetCustomerXDRS,
            body,
            { responseType: 'blob' },
        );

        fileDownload(res.data, `${action.payload.fileName}.csv`);
        action.payload.callback && action.payload.callback();
    } catch (err) {
        yield put(actions.getTransactionCsvFile.failure());
    }
}

export function* getTransactionDetails(
    action: ActionType<typeof actions.getTransactionDetails.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({ ...action.payload, get_total: 1 });

        const res: AxiosResponse<{
            xdr_list: CallHistory[];
            total: number;
        }> = yield api.post(Customer.GetCustomerXDRS, body);

        yield put(actions.getTransactionDetails.success(res.data));
        action.payload.callback && action.payload.callback();
    } catch (err) {
        yield put(actions.getTransactionDetails.failure());
    }
}

export function* getPaymentMethodData() {
    const { session_id, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );

    const body = new JSONFormData(session_id, csrf_token);

    const ownerPaymentsResponse: AxiosResponse<{
        payment_methods: OwnerPaymentMethod[];
    }> = yield api.post(Payment.GetPaymentMethodsForOwner, body);

    let customerPaymentResponse:
        | AxiosResponse<{
              payment_method_info: PaymentMethodInfo;
          }>
        | undefined;

    try {
        customerPaymentResponse = yield api.post(
            Customer.GetPaymentMethodInfo,
            body,
        );
    } catch (err) {}

    const autoPaymentResponse: AxiosResponse<{
        auto_payment_info: AutoPaymentInfo;
    }> = yield api.post(Customer.GetAutoPaymentInfo, body);

    yield put(
        actions.getPaymentData.success({
            ownerPaymentsMethods: ownerPaymentsResponse.data.payment_methods,
            customerPaymentMethod:
                customerPaymentResponse?.data?.payment_method_info,
            autoPaymentInfo: autoPaymentResponse.data.auto_payment_info,
        }),
    );
}

export function* editAutoPayments(
    action: ActionType<typeof actions.editAutoPayments.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const dataToSave = compareObjectsAndReturnDifferencesInValues(
            action.payload.initialValues,
            action.payload.changedValues,
        );

        const autoPaymentsId: number | undefined = yield select(
            (state: ReduxState) => state.billing.autoPaymentInfo?.i_object,
        );

        const autoPaymentsInfo: AutoPaymentInfo | undefined = yield select(
            (state: ReduxState) => state.billing.autoPaymentInfo,
        );

        if (dataToSave.autoPayInvoice !== undefined) {
            body.setParams({
                customer_info: {
                    bp_charge_cc: dataToSave.autoPayInvoice ? 'Y' : 'N',
                },
            });

            yield api.post(Customer.UpdateCustomer, body);
        }

        if (
            dataToSave.autoPayWithValues !== undefined ||
            dataToSave.amount ||
            dataToSave.balance
        ) {
            const currentAutoPaymentsStatus =
                dataToSave.autoPayWithValues ??
                action.payload.initialValues.autoPayWithValues;

            body.setParams({
                auto_payment_info: {
                    balance_threshold: parseFloat(
                        dataToSave.balance ||
                            action.payload.initialValues.balance ||
                            '0',
                    ),
                    pay_amount: parseFloat(
                        dataToSave.amount ||
                            action.payload.initialValues.amount ||
                            '0',
                    ),
                    frozen: 'N',
                    object: 'customer',
                    i_object: autoPaymentsId,
                },
            });

            if (
                !currentAutoPaymentsStatus &&
                action.payload.initialValues.autoPayWithValues
            ) {
                body.setParams({ i_object: autoPaymentsId });
                yield api.post(Customer.DeleteAutoPayment, body);
            } else if (
                currentAutoPaymentsStatus &&
                !action.payload.initialValues.autoPayWithValues &&
                autoPaymentsInfo?.frozen !== 'Y'
            ) {
                yield api.post(Customer.AddAutoPayment, body);
            } else {
                yield api.post(Customer.UpdateAutoPayment, body);
            }
        }

        yield put(actions.editAutoPayments.success());
        toast(i18n.t<string>('screens:billing.autoPaymentsHasBeenUpdated'));
        yield delay(1000);
        location?.replace(
            `?${qs.stringify({
                tab: action.payload.redirectTab,
            })}`,
        );
    } catch (err: any) {
        yield put(actions.editAutoPayments.failure(err?.response?.data));
    }
}

export function* removeDefaultPaymentMethod() {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        yield api.post(Customer.DeletePaymentMethod, body);

        yield put(actions.removeDefaultPaymentMethod.success());
        toast(i18n.t<string>('screens:billing.defaultPaymentMethodDeleted'));
        yield delay(1000);

        location?.replace(
            `?${qs.stringify({
                tab: 2,
            })}`,
        );
    } catch (err: any) {
        yield put(
            actions.removeDefaultPaymentMethod.failure(err?.response?.data),
        );
    }
}

export function* setDefaultPaymentMethod(
    action: ActionType<typeof actions.setDefaultPaymentMethod.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const expDate = action.payload.expirationDate?.split(' / ');

        const ownerPaymentsMethods: OwnerPaymentMethod[] = yield select(
            (state: ReduxState) => state.billing.ownerPaymentsMethods || [],
        );

        const paymentMethodType =
            action.payload.paymentType === PaymentMethodType.BankAccount
                ? PaymentMethod.BankAccount
                : convertCardValidatorTypeToPaymentType(
                    getCardType(action.payload.cardNumber) || ''
                );

        const paymentMethod = ownerPaymentsMethods.find(
            (v) => v.payment_method === paymentMethodType,
        );

        if (action.payload.email) {
            body.setParams({
                customer_info: {
                    email: action.payload.email,
                },
            });

            yield api.post(Customer.UpdateCustomer, body);
        }

        let amount: number | undefined = undefined;
        if(paymentMethod && paymentMethod.min_allowed_payment) {
            amount = paymentMethod.min_allowed_payment;
        }

        const cardInfo = {
            account_number: action.payload.accountNumber || undefined,
            address: action.payload.address,
            bank_number: action.payload.bankRoutingNumber || undefined,
            city: action.payload.city,
            cvv: action.payload.cvv || undefined,
            exp_date: action.payload.expirationDate
                ? dayjs
                      .utc()
                      .set('day', 1)
                      .set('month', parseInt(expDate[0]) - 1)
                      .set('year', parseInt('20' + expDate[1]))
                      .endOf('month')
                      .format('YYYY-MM-DD')
                : undefined,
            i_country_subdivision: action.payload.state,
            i_payment_method: paymentMethod?.i_payment_method,
            iso_3166_1_a2: action.payload.country,
            name: action.payload.cardholderName || action.payload.accountName,
            number: action.payload.cardNumber.replace(/ /g, ''),
            payment_method: paymentMethod?.payment_method,
            phone_number: action.payload.billingPhone || '',
            zip: action.payload.postalCode,
            issue_no: '',
        };

        if (paymentMethod?.remote_cc_storage === 'Y') {
            const api = new PBPaymentAPI({
                entityType: ENTITY_TYPE_CUSTOMER,
                sessionId: session_id,
                url: Config.CUSTOMER_API_URL,
                token: csrf_token,
            });

            const authorizeResponse:
                | {
                      i_payment_transaction: string;
                      faultdetails?: { i_payment_transaction: string };
                      redirect_url?: string;
                  }
                | undefined = yield api.authorizeCreditCard({
                cardInfo,
                saveCard: true,
                amount: amount,
                returnUrl: location.toString(),
                resultCallback: () => null,
            });

            if (authorizeResponse?.redirect_url) {
                yield put(actions.setPaymentProcessing(true));
                location?.replace(authorizeResponse.redirect_url);
                return;
            }
        } else {
            body.setParams({
                payment_method_info: cardInfo,
            });

            yield api.post(Customer.UpdatePaymentMethod, body);
        }

        yield put(actions.setDefaultPaymentMethod.success());
        toast(i18n.t<string>('screens:billing.defaultPaymentMethodUpdated'));
        yield delay(1000);
        location?.replace(
            `?${qs.stringify({
                tab: 2,
            })}`,
        );
    } catch (err: any) {
        toast(i18n.t<string>('common:undefinedError'));
        yield put(actions.setDefaultPaymentMethod.failure(err?.response?.data));
    }
}

export function* estimateTaxes(
    action: ActionType<typeof actions.estimateTaxes.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        const customerInfo: CustomerInfoDetails = yield select(
            (state: ReduxState) => state.billing.customerInfo,
        );

        body.setParams({
            calc_taxes: '1',
            xdr_list: [
                {
                    charged_amount: action.payload.amount,
                    i_service:
                        customerInfo?.i_balance_control_type === 2 &&
                        customerInfo?.estimate_taxes === 1 &&
                        customerInfo?.inclusive_taxation === 'N'
                            ? '2'
                            : undefined,
                },
            ],
        });

        const response: AxiosResponse<TaxesResponse> = yield api.post(
            Customer.EstimateTaxes,
            body,
        );

        yield put(
            actions.estimateTaxes.success(
                response.data.taxes_list.reduce((v, w) => v + w.amount, 0),
            ),
        );
    } catch (err: any) {
        yield put(actions.estimateTaxes.failure(err?.response?.data));
    }
}

export function* makePayment(
    action: ActionType<typeof actions.makePayment.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );

        const api = new PBPaymentAPI({
            entityType: ENTITY_TYPE_CUSTOMER,
            sessionId: session_id,
            url: Config.CUSTOMER_API_URL,
            token: csrf_token,
        });

        const ownerPaymentsMethods: OwnerPaymentMethod[] = yield select(
            (state: ReduxState) => state.billing.ownerPaymentsMethods || [],
        );

        const paymentMethodType =
            action.payload.paymentType === PaymentMethodType.BankAccount
                ? PaymentMethod.BankAccount
                : convertCardValidatorTypeToPaymentType(
                    getCardType(action.payload.cardNumber) || '',
                );

        const paymentMethod = ownerPaymentsMethods.find(
            (v) => v.payment_method === paymentMethodType,
        );

        const customerInfo: CustomerInfoDetails = yield select(
            (state: ReduxState) => state.billing.customerInfo,
        );
        const estimatedTaxesValue: number = yield select(
            (state: ReduxState) => state.billing.estimatedTaxesValue,
        );

        const expDate = action.payload.expirationDate?.split(' / ');

        const paymentResponse: {
            i_payment_transaction: string;
            faultdetails?: { i_payment_transaction: string };
            redirect_url?: string;
        } = yield api.doPayment({
            amount:
                parseFloat(action.payload.amount.replace(/,/g, '')) +
                (estimatedTaxesValue || 0),
            returnUrl: location.toString(),
            resultCallback: () => {
                null;
            },
            //@ts-ignore
            i_service:
                customerInfo?.i_balance_control_type === 2 &&
                customerInfo?.estimate_taxes === 1 &&
                customerInfo?.inclusive_taxation === 'N'
                    ? 2
                    : undefined,
            cardInfo:
                action.payload.isDefaultPaymentMethod === true
                    ? action.payload.cvv
                        ? { cvv: action.payload.cvv }
                        : undefined
                    : {
                          cvv: action.payload.cvv || undefined,
                          exp_date: action.payload.expirationDate
                              ? dayjs
                                    .utc()
                                    .set('day', 1)
                                    .set('month', parseInt(expDate[0]) - 1)
                                    .set('year', parseInt('20' + expDate[1]))
                                    .endOf('month')
                                    .format('YYYY-MM-DD')
                              : undefined,
                          number:
                              action.payload.cardNumber.replace(/ /g, '') ||
                              undefined,
                          payment_method: paymentMethod?.payment_method || '',
                          account_number:
                              action.payload.accountNumber || undefined,
                          address: action.payload.address,
                          bank_number:
                              action.payload.bankRoutingNumber || undefined,
                          city: action.payload.city,
                          i_country_subdivision:
                              action.payload.state || undefined,
                          iso_3166_1_a2: action.payload.country,
                          name:
                              action.payload.cardholderName ||
                              action.payload.accountName,
                          phone_number: action.payload.billingPhone || '',
                          zip: action.payload.postalCode,
                      },
            saveCard: action.payload.setAsDefaultPaymentMethod,
        });

        if (paymentResponse.redirect_url) {
            yield put(actions.setPaymentProcessing(true));
            location?.replace(paymentResponse.redirect_url);
        } else if (
            paymentResponse.faultdetails?.i_payment_transaction ||
            paymentResponse.i_payment_transaction
        ) {
            yield getPaymentDetailsCall(
                paymentResponse.faultdetails?.i_payment_transaction ||
                    paymentResponse.i_payment_transaction,
            );

            yield put(actions.getBasicBillingInfo.request());
        } else {
            throw 'Error';
        }

        yield put(actions.makePayment.success({paymentSuccess:true}));
    } catch (err: any) {
        yield put(actions.setPaymentError(true));
        yield put(actions.makePayment.failure(err?.response?.data));
    }
}

export function* getPaymentDetailsCall(id: string | number) {
    const { session_id, csrf_token } = yield select(
        (state: ReduxState) => state.auth,
    );
    const { decimal_digits } = yield select(
        (state: ReduxState) => state.generic.globalCurrency?.decimal_digits,
    );
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_payment_transaction: id,
    });

    const response: AxiosResponse<{
        transaction: PaymentDetails;
    }> = yield api.post(Payment.GetPaymentTransactionById, body);

    const customerInfo: CustomerInfoDetails = yield select(
        (state: ReduxState) => state.billing.customerInfo,
    );

    body.setParams({
        calc_taxes: '0',
        xdr_list: [
            {
                charged_amount: response.data.transaction.amount,
                i_service:
                    customerInfo?.i_balance_control_type === 2 &&
                    customerInfo?.estimate_taxes === 1 &&
                    customerInfo?.inclusive_taxation === 'N'
                        ? 2
                        : undefined,
            },
        ],
    });

    const taxResponse: AxiosResponse<TaxesResponse> = yield api.post(
        Customer.EstimateTaxes,
        body,
    );

    const taxSum = taxResponse.data.taxes_list.reduce(
        (v, w) => v + w.amount,
        0,
    );

    const paymentAmount = response.data.transaction.amount / (1 + taxSum);
    const taxAmount = response.data.transaction.amount - paymentAmount;

    response.data.transaction.paymentAmount = truncate(
        paymentAmount,
        decimal_digits || 2,
    ).toString();
    response.data.transaction.taxAmount = roundUp(
        taxAmount,
        decimal_digits || 2,
    ).toString();

    yield put(actions.getPaymentDetails.success(response.data.transaction));
}

export function* getPaymentDetails(
    action: ActionType<typeof actions.getPaymentDetails.request>,
) {
    try {
        const { session_id, csrf_token } = yield select(
            (state: ReduxState) => state.auth,
        );
        const body = new JSONFormData(session_id, csrf_token);

        body.setParams({
            i_payment_transaction: action.payload.payment_id,
        });

        if (action.payload.withFinalization) {
            yield api.post(Payment.FinalizeTransaction, body);
        }

        yield getPaymentDetailsCall(action.payload.payment_id);
    } catch (err: any) {
        yield put(actions.getPaymentDetails.failure(err?.response?.data));
    }
}

export const billingSaga = [
    takeLatest(actions.getInvoiceSelectedFile.request, getInvoiceFile),
    takeLatest(actions.getInvoicesListAction.request, getInvoiceList),
    takeLatest(actions.getBasicBillingInfo.request, getBasicBillingData),
    takeLatest(actions.getTransactionList.request, getTransactionList),
    takeLatest(actions.getTransactionCsvFile.request, getTransactionCsvFile),
    takeLatest(actions.getTransactionDetails.request, getTransactionDetails),
    takeLatest(actions.editAutoPayments.request, editAutoPayments),
    takeLatest(
        actions.removeDefaultPaymentMethod.request,
        removeDefaultPaymentMethod,
    ),
    takeLatest(
        actions.setDefaultPaymentMethod.request,
        setDefaultPaymentMethod,
    ),
    takeLatest(actions.makePayment.request, makePayment),
    takeLatest(actions.estimateTaxes.request, estimateTaxes),
    takeLatest(actions.getPaymentDetails.request, getPaymentDetails),
];

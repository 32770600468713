import { createAsyncAction } from 'typesafe-actions';
import { APIErrorInterface } from '../../types';
import {
    AutoAttendantMenu,
    AutoAttendantMenuTransition,
} from '../../types/AutoAttendant';
import * as payloads from './payloads';
import { AddNewMenuForm } from '../../../components/AutoAttendants/CreateNewMenu/utils';

export const getMenuDetails = createAsyncAction(
    'GET_MENU_DETAILS',
    'GET_MENU_DETAILS_SUCCESSED',
    'GET_MENU_DETAILS_FAILED',
)<payloads.GetMenuDetailsRequestPayload, AutoAttendantMenu, undefined>();

export const getMenuTransitions = createAsyncAction(
    'GET_MENU_TRANSITIONS',
    'GET_MENU_TRANSITIONS_SUCCESSED',
    'GET_MENU_TRANSITIONS_FAILED',
)<
    payloads.GetMenuTransitionsRequestPayload,
    AutoAttendantMenuTransition[],
    undefined
>();

export const getMenuPromptFile = createAsyncAction(
    'GET_MENU_PROMPT_FILE',
    'GET_MENU_PROMPT_FILE_SUCCESSED',
    'GET_MENU_PROMPT_FILE_FAILED',
)<payloads.GetMenuPromptFileRequestPayload, File, undefined>();

export const editMenu = createAsyncAction(
    'EDIT_MENU',
    'EDIT_MENU_SUCCESSED',
    'EDIT_MENU_FILE_FAILED',
)<payloads.EditMenuRequestPayload, undefined, APIErrorInterface>();

export const uploadMenuTransitionPrompt = createAsyncAction(
    'UPLOAD_MENU_TRANSITION_PROMPT',
    'UPLOAD_MENU_TRANSITION_PROMPT_SUCCESSED',
    'UPLOAD_MENU_TRANSITION_PROMPT_FAILED',
)<payloads.UploadMenuTransitionPromptRequestPayload, undefined, undefined>();

export const uploadMenuPrompt = createAsyncAction(
    'UPLOAD_MENU_PROMPT',
    'UPLOAD_MENU_PROMPT_SUCCESSED',
    'UPLOAD_MENU_PROMPT_FAILED',
)<payloads.UploadMenuPromptRequestPayload, undefined, undefined>();

export const removeMenu = createAsyncAction(
    'REMOVE_MENU',
    'REMOVE_MENU_SUCCESSED',
    'REMOVE_MENU_FAILED',
)<payloads.RemoveMenuRequestPayload, undefined, undefined>();

export const createMenu = createAsyncAction(
    'CREATE_MENU',
    'CREATE_MENU_SUCCESS',
    'CREATE_MENU_FAILED',
)<
    {
        login: string;
        domain: string;
        dataToStore: AddNewMenuForm;
        callback?: (id: number) => void;
    },
    undefined,
    APIErrorInterface
>();

import { Colors } from "../../../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";
import { OperationMode } from "../../../../store/actions/extensions/payloads";

export type NewOperationModeDialogProps = {
    isOpen: boolean,
    editObject?: OperationMode,
    clickOnClose?: () => void;
};

const requiredError = i18n.t<string>('errors:common.emptyInput');
export const newOperationModeValidationSchema = Yup.object().shape({
    short_code: Yup.number()
        .required(requiredError),
    name: Yup.string()
        .required(requiredError),
    sticky: Yup.string()
        .optional(),
    default_timeout: Yup.number()
        .optional(),
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 371,
            width: 576,
            padding: 0,
            margin: 0,
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 576,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    itemsContainer: {
        paddingLeft: 56,
        paddingTop: 34,
        paddingRight: 80,
        paddingBottom: 21,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 440,
            height: 56
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },

        paddingBottom: 31
    },

    numberField: {
        width: '100%',

        '& .MuiSvgIcon-root': {
            marginTop: 12
        }
    },

    checkBox: {
        paddingBottom: 0,
    },

    iconTooltip: {
        paddingTop: 8,
    }
}));
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import {
    CallBarringFormProps,
    ExtensionCallBarringFormType,
    useStyles,
} from './CallBarringForm.utils';
import classNames from 'classnames';

const CallBarringForm: React.FC<CallBarringFormProps> = ({
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    customClass,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const {
        setFieldValue,
        values,
        initialValues,
        handleSubmit,
        isValid,
        dirty,
    } = useFormikContext<ExtensionCallBarringFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    useEffect(() => {
        handleDirtyChange?.('callBarring', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callBarring', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callBarring', () => isValid);
    }, [isValid]);

    return (
        <div className={classNames(classes.inputs, customClass?.container)}>
            <Grid item className={classes.itemsContainer}>
                <Box className={classes.rowBox}>
                    <SwitchWithLabel
                        className={classes.switchContainer}
                        label={t('screens:extensions.callBarring')}
                        setValue={setValue}
                        value={values.callBarringStatus}
                        field={'callBarringStatus'}
                        id={'call-barring-status-switch'}
                        disabled={values.callBarringLocked}
                        icon={
                            <ServiceIcon
                                type={Service.CallBarring}
                                dataQa="call-barring-service"
                                dataTestId="call-barring-service"
                            />
                        }
                    />
                </Box>

                {values.callBarringStatus && initialValues.callBarringStatus && (
                    <>
                        {!values.callBarringItems.length ? (
                            <div className={classes.noData}>
                                {t('common:noData')}
                            </div>
                        ) : (
                            <Grid
                                container
                                spacing={2}
                                className={classes.itemsElementsContainer}
                                data-qa="call-barring-rules"
                            >
                                {values.callBarringItems.map((item, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        key={index}
                                        className={classes.itemContainer}
                                    >
                                        <CustomizedTooltip
                                            title={
                                                (values.callBarringLocked && t('tooltips:billing.disabledFeature'))
                                                || (!values.callBarringLocked && (item.rule_description || t('screens:extensions.noNumberPattern')))
                                            }
                                            dataQa="call-barring-item-tooltip"
                                            interactive
                                            copy={false}
                                        >
                                            <div>
                                                <CustomizedCheckbox
                                                    className={classes.checkbox}
                                                    checked={item.state === 'Y'}
                                                    dataQa="call-barring-item-checkbox"
                                                    label={item.name}
                                                    disabled={
                                                        !item.rule_description || values.callBarringLocked
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            `callBarringItems.[${index}].state`,
                                                            e.target.checked
                                                                ? 'Y'
                                                                : 'N',
                                                            false,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </CustomizedTooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default CallBarringForm;

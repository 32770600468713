import MaskedInput from 'react-text-mask';
import React from 'react';

const ExpirationDateMask = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-1]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/]}
            placeholderChar={'_'}
            placeholder={'MM / YY'}
        />
    );
};

export default ExpirationDateMask;

import toast from 'react-hot-toast';
import i18n from '../services/i18n';

export const showErrorToast = (
    message?: string,
    backendErrorName?: string,
    exceptions?: string[],
) => {
    if (
        backendErrorName &&
        exceptions &&
        exceptions.find((a) => backendErrorName.includes(a || ''))
    ) {
        return;
    }

    if (message) {
        toast(message);
    } else {
        toast(i18n.t<string>('common:undefinedError'));
    }
};

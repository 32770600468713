import {CallForwardingRule} from '../../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import {TransportProtocol} from '../../store/types/AccountFollowMeSettings';
import {ForwardModeFlag} from '../../store/types/ServiceFeature';
import {convertRangesStringToNumbersArray} from './RingScheduleSummary';
import {IntervalStatus} from "../../components/IntervalSelect/IntervalSelect.utils";

export const prepareCallForwardingRule = (
    rule: CallForwardingRule,
    callForwardingMethod: ForwardModeFlag,
    accountId: number,
) => {
    return {
        i_follow_me_number: !rule.id?.includes?.('created_')
            ? rule.id
            : undefined,
        timeout: rule.ringFor || undefined,
        i_account: accountId,
        redirect_number: rule.number || null,
        domain:
            callForwardingMethod === ForwardModeFlag.AdvancedForwarding ||
            callForwardingMethod === ForwardModeFlag.ForwardToSIPUri
                ? rule.sipProxy
                : '',
        name: rule.description,
        keep_original_cli: rule.callingPartyDisplay,
        keep_original_cld: rule.keepOriginalCld ? 'Y' : 'N',
        use_tcp:
            callForwardingMethod === ForwardModeFlag.AdvancedForwarding ||
            callForwardingMethod === ForwardModeFlag.ForwardToSIPUri
                ? rule.transportProtocol === TransportProtocol.TCP
                    ? 'Y'
                    : 'N'
                : undefined,
        active: rule.enabled ? 'Y' : 'N',
        weight: rule.weight,
        period_definition:
            rule.intervals?.activity === IntervalStatus.Always
                ? []
                : rule.intervals?.newIntervals?.map((v) => ({
                    start_time: !v.wholeDay ? v.startTime + ':00' : undefined,
                    end_time: !v.wholeDay ? v.endTime + ':00' : undefined,
                    weekday_list: v.days.map((v) => v.apiValue),
                    month_list: v.months.map((v) => v.apiValue),
                    monthday_list: convertRangesStringToNumbersArray(
                        v.daysOfMonth,
                    ),
                    year_list: v.years?.map((v) => v.apiValue)
                })),
    };
};

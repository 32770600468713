import React, { useState, useEffect, useCallback } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Pause, PlayArrow, Stop } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import Loader from '../Loader/Loader';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export type SoundPlayerProps = {
    file?: File | null;
    fileName?: string;
    getFileOnPlay?: () => void;
    disabled?: boolean;
};

const SoundPlayer: React.VFC<SoundPlayerProps> = ({
    fileName,
    file,
    getFileOnPlay,
    disabled,
}) => {
    const classes = useStyles();

    const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined);

    const [status, setStatus] = useState<
        'playing' | 'paused' | 'stopped' | 'loading'
    >('stopped');

    useEffect(() => {
        const newAudio = new Audio();

        if (file) {
            try {
                newAudio.srcObject = file;
            } catch (error) {
                newAudio.src = URL.createObjectURL(file);
            }

            audio?.removeEventListener('ended', onStop);
            setAudio(newAudio);
            newAudio.addEventListener('ended', onStop);
        }
        if (status === 'loading' && file) {
            newAudio?.play();
            setStatus('playing');
        }
    }, [file]);

    useEffect(() => {
        return () => {
            audio?.removeEventListener('ended', onStop);
        };
    }, [audio]);

    const onPlay = useCallback(() => {
        if (file) {
            setStatus('playing');
            audio?.play();
        } else if (fileName) {
            setStatus('loading');
            getFileOnPlay?.();
        }
    }, [status, file, audio]);

    const onPause = useCallback(() => {
        setStatus('paused');
        audio?.pause();
    }, [status]);

    const onStop = useCallback(() => {
        setStatus('stopped');

        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
    }, [status]);

    return (
        <div className={classes.container}>
            {status === 'loading' && (
                <div>
                    <Loader dataQa="sound-player-loader" size={20} />
                </div>
            )}

            {(status === 'stopped' || status === 'paused') && (
                <IconButton
                    size="small"
                    onClick={onPlay}
                    disabled={(!file && !fileName) || disabled}
                    data-qa="play-button"
                    data-testid="play-button"
                >
                    <PlayArrow htmlColor={Colors.Gray5} />
                </IconButton>
            )}

            {status === 'playing' && (
                <IconButton
                    size="small"
                    onClick={onPause}
                    data-qa="pause-button"
                    data-testid="pause-button"
                >
                    <Pause htmlColor={Colors.Gray5} />
                </IconButton>
            )}

            {(status === 'playing' || status === 'paused') && (
                <IconButton
                    size="small"
                    onClick={onStop}
                    data-qa="stop-button"
                    data-testid="stop-button"
                >
                    <Stop htmlColor={Colors.Gray5} />
                </IconButton>
            )}
        </div>
    );
};

export default SoundPlayer;

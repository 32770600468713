import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { UserInput } from '../../../store/types/AutoAttendant';
import { useTranslation } from 'react-i18next';
import DialingButton from './DialingButton';
import { MenuActionFormType } from '../../Forms/AutoAttendantsMenus/ActionsForm.utils';
import { Colors } from '../../../styles/Colors';

const useStyles = makeStyles(() => ({
    rowContainer: {
        display: 'flex',
        marginBottom: 19,
    },
    titleContainer: {
        width: 140,
        paddingTop: 5,
        paddingLeft: 30,
        color: Colors.Gray7,
    },
}));

type DialingPadProps = {
    id: string;
    value: UserInput | null;
    onChange: (value: UserInput) => void;
    helperText?: string;
    setFieldError?: (id: string, value: string | undefined) => void;
    menuActions: MenuActionFormType[];
    isDialExtensionDirectly?: boolean;
};

const DialingPad: React.VFC<DialingPadProps> = ({
    id,
    value,
    onChange,
    menuActions,
    setFieldError,
    isDialExtensionDirectly,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onChangeSelectedValue = useCallback(
        (value: UserInput) => {
            setFieldError?.(id, undefined);
            onChange?.(value);
        },
        [id, onChange],
    );

    const buttonProps = {
        menuActions,
        onChange: onChangeSelectedValue,
        selectedValue: value,
        isDialExtensionDirectly,
    };

    return (
        <div className={classes.rowContainer}>
            <div className={classes.titleContainer}>
                <span>{t('screens:autoAttendants.userInput')}</span>
            </div>
            <div>
                <DialingButton value={UserInput.Digit1} {...buttonProps} />
                <DialingButton value={UserInput.Digit4} {...buttonProps} />
                <DialingButton value={UserInput.Digit7} {...buttonProps} />
                <DialingButton value={UserInput.Star} {...buttonProps} />
            </div>

            <div>
                <DialingButton value={UserInput.Digit2} {...buttonProps} />
                <DialingButton value={UserInput.Digit5} {...buttonProps} />
                <DialingButton value={UserInput.Digit8} {...buttonProps} />
                <DialingButton value={UserInput.Digit0} {...buttonProps} />
            </div>

            <div>
                <DialingButton value={UserInput.Digit3} {...buttonProps} />
                <DialingButton value={UserInput.Digit6} {...buttonProps} />
                <DialingButton value={UserInput.Digit9} {...buttonProps} />
                <DialingButton value={UserInput.Hash} {...buttonProps} />
            </div>

            <div>
                <DialingButton
                    value={UserInput.NoInput}
                    {...buttonProps}
                    wide
                />
                <DialingButton value={UserInput.Fax} {...buttonProps} wide />
            </div>
        </div>
    );
};

export default DialingPad;

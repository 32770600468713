import React from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { ApiChangePasswordPayload } from '../../../store/services/auth-api';

import { ReactComponent as KeyIcon } from '../../../assets/key.svg';
import { ReactComponent as KeyRedIcon } from '../../../assets/key-red.svg';

import { white, errorColor } from '../../../styles/Colors';
import { Grid } from '@material-ui/core';
import TextField from '../../TextField/TextField';
import CustomButton from '../../Button/Button';

type ErrorMessage = {
    password?: string;
    confirmPassword?: string;
    global?: string;
};

const validate = (values: any) => {
    const errors: ErrorMessage = {};

    if (values.password && values.password === values.oldPassword) {
        errors.password = 'sameAsOldPassword';
    } else if (!values.password) {
        errors.password = 'emptyInput';
    } else if (values.password.length < 6) {
        errors.password = 'passwordCriteriaNotMet';
    } else if (!/^[A-Z0-9._`~!@#$%&*';:{}"]*$/i.test(values.password)) {
        errors.password = 'passwordCriteriaNotMet';
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'emptyInput';
    } else if (values.password !== values.confirmPassword) {
        errors.password = 'passwordDontMatch';
    }

    return errors;
};

const useStyles = makeStyles(() => ({
    errors: {
        '& .MuiFormLabel-root .MuiFormLabel-filled': {
            color: `${errorColor} `,
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },

        '& .MuiInputBase-root': {
            height: 56,
        },

        '& .MuiInputBase-input': {
            marginLeft: '-10px',
        },
    },

    action: {
        display: 'flex',

        '& button:first-child': {
            width: 179,

            '& span': {
                color: white,
            },
        },
    },
}));

type PasswordExpirationPros = {
    onSubmitAction: (payload: ApiChangePasswordPayload) => void;
    validateOnChange: boolean;
    login: string;
    oldPassword: string;
};

const PasswordExpirationFrom: React.FC<PasswordExpirationPros> = ({
    onSubmitAction,
    login,
    oldPassword,
    validateOnChange = false,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
            confirmPassword: '',
            global: '',
            oldPassword: oldPassword,
        },
        validate,
        validateOnChange: validateOnChange,
        onSubmit: (values) => {
            const payload = {
                login: login,
                password: oldPassword,
                new_password: values.confirmPassword,
            };

            onSubmitAction(payload);
        },
    });

    const passwordAttr = formik.errors.password
        ? { icon: <KeyRedIcon />, class: classes.errors }
        : { icon: <KeyIcon />, class: '' };
    const ConfirmPasswordAttr = formik.errors.confirmPassword
        ? { icon: <KeyRedIcon />, class: classes.errors }
        : { icon: <KeyIcon />, class: '' };

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            data-testid={'password-expiration-form'}
        >
            <div className={classes.inputs}>
                <Grid item className={passwordAttr.class}>
                    <TextField
                        id="password"
                        dataQa="change-password-input"
                        icon={passwordAttr.icon}
                        label={t('screens:signIn.newPasswordLabel')}
                        onChange={formik.handleChange}
                        value={formik.values.password.trim()}
                        type="password"
                        helperText={
                            formik.errors.password
                                ? t(
                                      `errors:loginForm.${formik.errors.password}`,
                                  )
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                    />
                </Grid>
                <Grid item className={passwordAttr.class}>
                    <TextField
                        id="confirmPassword"
                        dataQa="change-confirmation-password-input"
                        icon={ConfirmPasswordAttr.icon}
                        label={t('screens:signIn.confirmPasswordLabel')}
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword.trim()}
                        type="password"
                        helperText={
                            formik.errors.confirmPassword
                                ? t(
                                      `errors:loginForm.${formik.errors.confirmPassword}`,
                                  )
                                : ''
                        }
                        setFieldError={formik.setFieldError}
                    />
                </Grid>
            </div>
            <div className={classes.action}>
                <CustomButton
                    dataQa="change-password-submit"
                    primary
                    type="submit"
                >
                    {t('screens:signIn.changePasswordButton')}
                </CustomButton>
            </div>
        </form>
    );
};

export default PasswordExpirationFrom;

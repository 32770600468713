import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeoutIcon } from '../../../../../assets/ivr_timeout.svg';
import { ReactComponent as FaxIcon } from '../../../../../assets/faxmailbox.svg';
import { ReactComponent as CalendarIcon } from '../../../../../assets/ivr_calendar.svg';
import { Colors } from '../../../../../styles/Colors';
import { UserInput } from '../../../../../store/types/AutoAttendant';
import { MenuActionFormType } from '../../../AutoAttendantsMenus/ActionsForm.utils';
import classNames from 'classnames';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
    container: {
        height: 40,
        width: 40,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.Secondary,
        marginTop: 5,
    },
    titleText: {
        fontSize: 16,
        color: Colors.White,
        fontWeight: 500,
    },
    greenContainer: {
        backgroundColor: Colors.Support,
    },
    centeredIcon: {
        marginTop: 7,
        pointerEvents: 'all',
    },
}));

type TransactionSubNode = {
    transition: MenuActionFormType;
    className?: string;
};

const StartNode: React.VFC<TransactionSubNode> = ({
    transition,
    className,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div
            className={classNames(
                classes.container,
                transition.userInput === UserInput.NotActive &&
                    classes.greenContainer,
                className,
            )}
            data-qa="transition-sub-node"
            data-testid="transition-sub-node"
        >
            {transition.userInput === UserInput.Fax && (
                <CustomizedTooltip
                    title={t('tooltips:autoAttendants.fax')}
                    copy={false}
                >
                    <div className={classes.centeredIcon} style={{marginTop: 0}}>
                        <FaxIcon data-qa="fax-icon" data-testid="fax-icon" />
                    </div>
                </CustomizedTooltip>
            )}

            {transition.userInput === UserInput.NoInput && (
                <CustomizedTooltip
                    title={t('tooltips:autoAttendants.timeoutWithoutInput')}
                    copy={false}
                >
                    <div className={classes.centeredIcon}>
                        <TimeoutIcon
                            data-qa="timeout-icon"
                            data-testid="timeout-icon"
                        />
                    </div>
                </CustomizedTooltip>
            )}

            {transition.userInput === UserInput.NotActive && (
                <CalendarIcon
                    data-qa="calendar-icon"
                    data-testid="calendar-icon"
                />
            )}

            {transition.userInput !== UserInput.NoInput &&
                transition.userInput !== UserInput.Fax &&
                transition.userInput !== UserInput.NotActive && (
                    <span className={classes.titleText}>
                        {t(`enums:userInput.${transition.userInput}`)}
                    </span>
                )}
        </div>
    );
};

export default memo(StartNode);

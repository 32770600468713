import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
} from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { DataGridProps, PaginationMode } from './types';
import classNames from 'classnames';
import {
    Row,
    useFlexLayout,
    usePagination,
    useRowSelect,
    useTable,
} from 'react-table';
import HeaderRow from './HeaderRow';
import TableRow from './Row';
import Pagination from './Pagination';
import Loader from '../Loader/Loader';
import EmptyGridPlaceholder from './EmptyGridPlaceholder';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import CustomizedCheckbox from '../Checkbox/Checkbox';
import { reorder } from './helper';

const useStyles = makeStyles(() => ({
    tableContainer: {
        background: Colors.White,
        border: `1px solid ${Colors.Border}`,
        borderRadius: 4,
    },
    table: {
        position: 'relative',
        background: Colors.White,
        marginTop: '0 !important',

        '& .MuiTableCell-root': {
            borderBottom: 0,
        },

        '& .MuiTableCell-head': {
            color: Colors.Gray5,
            fontWeight: 700,
        },

        '& .MuiTableRow-root': {
            borderBottom: `1px solid ${Colors.Border}`,
        },

        '& .MuiTableRow-footer': {
            borderBottom: 0,
        },

        '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
                display: 'flex',
                alignItems: 'flex-start',
                fontSize: 16,
            },
        },

        '& .MuiTypography-body2': {
            fontSize: 16,
            color: Colors.Gray9,
        },

        '& .MuiTableCell-footer': {
            justifyContent: 'flex-end',
            padding: '0 !important',
            height: '56px !important',
            alignItems: 'center',
            '& .MuiTablePagination-root': {
                marginTop: 0,
            },
        },
    },

    centeredRows: {
        '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    narrowRows: {
        '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
                padding: '12px 16px',
            },
        },
    },

    pagination: {
        '& .MuiTablePagination-actions': {
            color: Colors.Gray5,
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: Colors.Gray9,
            opacity: 0.6
        },
    },

    hideSeparator: {
        '& .MuiTableRow-root': {
            borderBottom: 0,
        },
    },

    header: {
        backgroundColor: Colors.White,
        '& .MuiTableCell-root': {
            padding: '20px 16px !important',
            fontWeight: 'bold',
            fontSize: 14,
            color: Colors.Gray5,
        },
    },
    rowHeader: {
        border: 'none',
    },
}));

const DataGrid: <T extends Object>(
    p: DataGridProps<T>,
) => React.ReactElement<DataGridProps<T>> = ({
    columns,
    data,
    rowCount,
    loading,
    showMessageWhenEmpty = true,
    hidePagination,
    paginationMode = PaginationMode.Client,
    hideHeader = false,
    centeredRows,
    narrowRows,
    pageSizeOptions,
    initialPageSize,
    dataQa,
    className,
    classes: customClasses,
    dragEnabled,
    multiSelectEnabled,
    hideSeparator,
    hideFooter,
    customRowHeight,
    onPageChange,
    onPageSizeChange,
    onChangeItemPosition,
    onChangeItemsSelection,
    onCellHover,
    getItemId,
    renderRowHeader,
    forceFirstPage,
    dragHeader,
    dragColumnStyle,
    dragHeaderStyle,
    message,
    showNoDataImg,
    showNoDataMsg,
    internalHeader = false
}) => {
    const classes = useStyles();

    const [items, setItems] = useState<typeof data>(data || []);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<
        Row<typeof data[number]>[]
    >([]);

    useEffect(() => {
        setItems(data || []);
    }, [data]);

    useEffect(() => {
        setIsLoading(!!loading);
    }, [loading]);

    const onDragEnd = (result: DropResult) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index,
        );

        onChangeItemPosition?.(result.source.index, result.destination.index);
        setItems(newItems);
    };

    const getRowId = React.useCallback(
        (row, index) => {
            return getItemId ? getItemId(row, index) : row.id;
        },
        [getItemId],
    );

    const {
        getTableProps,
        headers,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state,
        selectedFlatRows,
    } = useTable<typeof data[number]>(
        {
            columns,
            data: items,
            initialState: {
                pageSize: initialPageSize || 50,
                pageIndex: 0,
            },
            manualPagination: paginationMode === PaginationMode.Server,
            pageCount: rowCount,
            autoResetPage: false,
            autoResetSelectedRows: false,
            getRowId,
        },
        useFlexLayout,
        usePagination,
        useRowSelect,
        (hooks) => {
            multiSelectEnabled &&
                hooks.visibleColumns.push((columns) => [
                    {
                        id: 'selection',
                        Header: function Cell({
                            getToggleAllPageRowsSelectedProps,
                        }) {
                            return (
                                <div>
                                    <CustomizedCheckbox
                                        dataQa={'select-all-checkbox'}
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </div>
                            );
                        },
                        Cell: function Cell({
                            row,
                        }: {
                            row: typeof data[number];
                        }) {
                            return (
                                <div>
                                    <CustomizedCheckbox
                                        dataQa={'item-checkbox'}
                                        {...row.getToggleRowSelectedProps()}
                                    />
                                </div>
                            );
                        },
                        maxWidth: 40,
                        width: 1,
                    },
                    ...columns,
                ]);
        },
    );

    useEffect(() => {
        forceFirstPage && gotoPage(0);
    }, [forceFirstPage]);

    useEffect(() => {
        if (selectedItems.length !== selectedFlatRows.length) {
            setSelectedItems(selectedFlatRows);
            onChangeItemsSelection?.(selectedFlatRows.map((v) => v.original));
        }
    }, [selectedFlatRows, selectedItems]);

    return (
        <>
            <TableContainer
                className={classNames(
                    classes.tableContainer,
                    customClasses?.tableContainer,
                )}
                data-qa={dataQa}
                data-testid={dataQa}
            >
                <Table
                    className={classNames(
                        classes.table,
                        centeredRows && classes.centeredRows,
                        narrowRows && classes.narrowRows,
                        hideSeparator && classes.hideSeparator,
                        className,
                    )}
                    {...getTableProps()}
                >
                    {!hideHeader && !internalHeader && (
                        <TableHead
                            className={classNames(
                                classes.tableContainer,
                                classes.rowHeader,
                                customClasses?.header,
                            )}
                        >
                            <HeaderRow
                                columns={headers}
                                dragEnabled={dragEnabled}
                                dragHeader={dragHeader}
                                dragHeaderStyle={dragHeaderStyle}
                            />
                        </TableHead>
                    )}

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <TableBody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {page.map((row, index) => {
                                        prepareRow(row);
                                        return (
                                            <TableRow
                                                key={index}
                                                row={row}
                                                index={index}
                                                dragEnabled={dragEnabled}
                                                onCellHover={onCellHover}
                                                renderRowHeader={
                                                    renderRowHeader
                                                }
                                                data-id={row.id}
                                                data-rowindex={index}
                                                customRowHeight={
                                                    customRowHeight
                                                }
                                                dragColumnStyle={
                                                    dragColumnStyle
                                                }
                                                internalHeader={internalHeader}
                                            />
                                        );
                                    })}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {hideFooter && showNoDataMsg ? (
                        <TableFooter>
                            <MuiTableRow>
                                <MuiTableCell>
                                    <EmptyGridPlaceholder
                                        showNoDataImg={showNoDataImg}
                                        message={message}
                                        isVisible={
                                            showMessageWhenEmpty &&
                                            !page.length &&
                                            !isLoading
                                        }
                                    />
                                </MuiTableCell>
                            </MuiTableRow>
                        </TableFooter>
                    ) : null }
                    {!!hideFooter ? null : (
                        <TableFooter>
                            <MuiTableRow>
                                <MuiTableCell>
                                    <EmptyGridPlaceholder
                                        showNoDataImg={showNoDataImg}
                                        message={message}
                                        isVisible={
                                            showMessageWhenEmpty &&
                                            !page.length &&
                                            !isLoading
                                        }
                                    />

                                    <Pagination
                                        className={classNames(
                                            classes.pagination,
                                            customClasses?.pagination,
                                        )}
                                        data-qa={'page-pagination'}
                                        data-testid={'page-pagination'}
                                        isVisible={
                                            !hidePagination && !!page.length
                                        }
                                        paginationMode={paginationMode}
                                        pageSizeOptions={
                                            pageSizeOptions || [
                                                5,
                                                10,
                                                15,
                                                25,
                                                50,
                                            ]
                                        }
                                        pageSize={state.pageSize}
                                        onPageChange={(_, page) => {
                                            gotoPage(page);
                                            onPageChange?.({
                                                page,
                                                pageCount: rowCount,
                                                pageSize: state.pageSize,
                                                rowCount,
                                                paginationMode:
                                                    paginationMode ||
                                                    PaginationMode.Client,
                                            });
                                        }}
                                        onPageSizeChange={(event) => {
                                            const value = parseInt(
                                                event.target.value,
                                                10,
                                            );
                                            setPageSize(value);
                                            onPageSizeChange?.({
                                                page: 0,
                                                pageCount: rowCount,
                                                pageSize: value,
                                                rowCount,
                                                paginationMode:
                                                    paginationMode ||
                                                    PaginationMode.Client,
                                            });
                                        }}
                                        rowCount={rowCount}
                                        page={state.pageIndex}
                                    />
                                </MuiTableCell>
                            </MuiTableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>

            {isLoading && <Loader dataQa="datagrid-loader" absolutePosition />}
        </>
    );
};

export default DataGrid;

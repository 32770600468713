import React, { useMemo, useEffect, useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import TextField from '../../TextField/TextField';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import * as Yup from 'yup';
import classNames from 'classnames';
import i18n from '../../../services/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../store';
import Loader from '../../Loader/Loader';
import { APIErrorInterface, ReduxState } from '../../../store/types';
import SelectField from '../../SelectField/SelectField';
import { CustomerDIDNumberType } from '../../../store/types/CustomerDIDNumber';
import MainProductSelectField from '../../Extensions/MainProductSelectFiled/MainProductSelectField';
import { MainProductType } from '../../Extensions/MainProductSelectFiled/utils';
import { useStyles } from './utils';
import { CreateExtensionFaultCode } from '../../../store/types/Extension';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';
import { SelectItem } from '../../../store/types/RingGroup';
import CustomizedCheckbox from '../../Checkbox/Checkbox';
import TimeRangePicker from '../../TimeRangePicker/TimeRangePicker';
import {
    convertRangeToStartEndTime,
    prepareDefaultRangeDate,
} from '../../TimeRangePicker/TimeRangePicker.utils';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import { daysSelectItems } from '../../../utils/extensions/RingScheduleSummary';

const requiredFieldError = i18n.t('errors:common.emptyInput');
const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');
const nameMaxLengthError = i18n.t('errors:extensions.max32Length');

const incorrectFormat = i18n.t<string>('errors:extensions.incorrectFormat');

const CreateNewExtensionValidationSchema = Yup.object().shape({
    extensionNumber: Yup.string()
        .max(5, numberMaxLengthError)
        .matches(/^[0-9]*$/, numberDigitsError)
        .required(requiredFieldError),
    extensionName: Yup.string().max(32, nameMaxLengthError).notRequired(),
    mainProductId: Yup.number().required(requiredFieldError),
    startTime: Yup.string().length(5, incorrectFormat),
    endTime: Yup.string().length(5, incorrectFormat),
    days: Yup.array().notRequired(),
});

interface CreateNewAutoAttendantFormData {
    extensionNumber: string;
    extensionName: string;
    mainProductId?: number;
    didNumber: string[];
    enableBusinessHours: boolean;
    enableAfterHoursAndHolidays: boolean;
    startTime: string;
    endTime: string;
    days: SelectItem[];
}

interface CreateNewAutoAttendantDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
}

const CustomTags = (selected: string[]) => {
    //@ts-ignore - material ui lib bug
    const renderTagsValue = selected.map((v) => v.name).join(', ');
    return (
        <div
            style={{
                flex: 1,
                marginLeft: 5,
                width: 53,
            }}
        >
            <OverflowTooltip
                text={renderTagsValue}
                tooltip={renderTagsValue}
                copy={false}
            />
        </div>
    );
};

const CreateNewAutoAttendantDialog: React.VFC<CreateNewAutoAttendantDialogProps> = ({
    isOpen,
    toggleVisibility,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [otherError, setOtherError] = useState<boolean>(false);

    const isDataFetching = useSelector<ReduxState, boolean>(
        (state) => !!state.autoAttendants.createAutoAttendantDataIsLoading,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => !!state.autoAttendants.isCreatingNewAutoAttendant,
    );

    const apiErrors = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state) => state.autoAttendants.createNewAutoAttendantsErrors,
    );

    const didNumbers = useSelector<ReduxState, CustomerDIDNumberType[]>(
        (v) => v.autoAttendants.customerDidNumbers || [],
    );

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const ampm = outTimeFormat?.includes('AM');

    const [fetchData, setFetchData] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setFetchData(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            dispatch(actions.createAutoAttendantDetailsData.request());
        }
    }, [isOpen]);

    useEffect(() => {
        if (
            apiErrors?.faultcode === CreateExtensionFaultCode.ExtHGNumberInUse
        ) {
            setFieldError(
                'extensionNumber',
                t('errors:extensions.numberInUse'),
            );
        } else if (
            apiErrors?.faultcode?.includes(
                CreateExtensionFaultCode.MaxOfferedQuantity,
            )
        ) {
            setFieldError(
                'mainProductId',
                t('errors:extensions.maximumProvider'),
            );
        } else if (
            apiErrors?.faultcode === CreateExtensionFaultCode.DuplicateId
        ) {
            setFieldError(
                'mainProductId',
                t('errors:extensions.extensionNumberExists'),
            );
        } else if (apiErrors !== undefined) {
            setOtherError(true);
        }
    }, [apiErrors]);

    const initialValues = useMemo(
        () => ({
            extensionNumber: '',
            extensionName: '',
            mainProductId: undefined,
            didNumber: [],
            enableBusinessHours: false,
            enableAfterHoursAndHolidays: false,
            startTime: '08:00',
            endTime: '17:00',
            days: [],
        }),
        [],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        setFieldValue,
        setFieldError,
        errors,
    } = useFormik<CreateNewAutoAttendantFormData>({
        initialValues,
        onSubmit: (form) => {
            dispatch(actions.createNewAutoAttendant.request(form));
        },
        validationSchema: CreateNewExtensionValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const isSaveDisabled =
        !values.extensionName ||
        !values.extensionNumber ||
        !values.mainProductId;

    const items = useMemo(() => {
        return (
            didNumbers.filter((v) => !v.i_account).map((v) => v.did_number) ||
            []
        );
    }, [didNumbers]);

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="sip-dialog"
            header={t('screens:autoAttendants.addNewAutoAttendant')}
            headerClass={classes.header}
            className={classNames(
                classes.modalContainer,
                otherError && classes.warningModal,
            )}
            dialogActionsButtons={
                otherError
                    ? [
                          <DialogButton
                              key="close"
                              label={t('common:close')}
                              onClick={() => {
                                  toggleVisibility?.();
                                  resetForm();
                                  setOtherError(false);
                              }}
                              className={classes.primaryActionButton}
                          />,
                      ]
                    : [
                          <DialogButton
                              key="cancel"
                              label={t('common:cancel')}
                              onClick={() => {
                                  toggleVisibility?.();
                                  resetForm();
                              }}
                          />,
                          <DialogButton
                              primary
                              disabled={isSaveDisabled}
                              key="save"
                              label={t('common:save')}
                              onClick={handleSubmit}
                          />,
                      ]
            }
        >
            {isDataFetching ? (
                <Loader
                    dataQa="create-auto-attendant-data-fetching-loader"
                    absolutePosition
                />
            ) : (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="create-extension-form"
                >
                    {otherError ? (
                        <Box
                            className={classes.warningWrapper}
                            display="flex"
                            alignItems="center"
                        >
                            <WarningIcon
                                data-qa={'warning-icon'}
                                data-testid="warning-icon"
                            />
                            <span className={classes.otherErrorLabel}>
                                {t('errors:extensions.otherError')}
                            </span>
                        </Box>
                    ) : (
                        <Grid item className={classes.itemsContainer}>
                            <TextField
                                id="extensionName"
                                label={t('common:name')}
                                onChange={handleChange}
                                value={values.extensionName}
                                setFieldError={setFieldError}
                                icon={
                                    <IconWithTooltip
                                        dataQa="create-extension-name-tooltip"
                                        tooltipText={t(
                                            'tooltips:autoAttendants.extensionName',
                                        )}
                                    />
                                }
                                iconPosition="end"
                                dataQa="create-extension-name-input"
                                helperText={errors?.extensionName}
                                className={classes.fieldMargin}
                                required
                                maxLength={32}
                            />

                            <TextField
                                id="extensionNumber"
                                label={t('screens:extensions.extensionNumber')}
                                onChange={handleChange}
                                value={values.extensionNumber}
                                setFieldError={setFieldError}
                                icon={
                                    <IconWithTooltip
                                        dataQa="create-extension-number-tooltip"
                                        tooltipText={t(
                                            'tooltips:autoAttendants.extensionNumber',
                                        )}
                                    />
                                }
                                iconPosition="end"
                                dataQa="create-extension-number-input"
                                helperText={errors?.extensionNumber}
                                className={classes.fieldMargin}
                                required
                                maxLength={5}
                            />

                            <MainProductSelectField
                                fetchData={fetchData}
                                mainProductId={values.mainProductId}
                                setFieldError={setFieldError}
                                onChange={(_, value) => {
                                    setFieldValue(
                                        'mainProductId',
                                        value.i_product,
                                    );
                                }}
                                errorMsg={errors?.mainProductId}
                                productType={MainProductType.AutoAttendants}
                            />
                            <SelectField
                                label={t('screens:extensions.didNumber')}
                                onChange={(e, value) =>
                                    setFieldValue('didNumber', value)
                                }
                                items={items}
                                value={values.didNumber}
                                multiple
                                icon={
                                    <IconWithTooltip
                                        dataQa="did-numbers-tooltip"
                                        tooltipText={t(
                                            'tooltips:autoAttendants.didNumber',
                                        )}
                                    />
                                }
                                dataQa="extension-didnumbers-input"
                                className={classes.didNumbers}
                                classes={{
                                    container: classes.didNumber,
                                }}
                            />

                            <div
                                className={classNames(
                                    classes.row,
                                    classes.menusSection,
                                )}
                            >
                                <CustomizedCheckbox
                                    checked={values.enableBusinessHours}
                                    label={t(
                                        'screens:autoAttendants.enableBusinessHours',
                                    )}
                                    dataQa="requirePinStatus"
                                    onChange={() => {
                                        setFieldValue(
                                            'enableBusinessHours',
                                            !values.enableBusinessHours,
                                        );
                                        if (
                                            values.enableAfterHoursAndHolidays
                                        ) {
                                            setFieldValue(
                                                'enableAfterHoursAndHolidays',
                                                false,
                                            );
                                        }
                                    }}
                                />
                                <IconWithTooltip
                                    dataQa="auto0-attendants-tooltip"
                                    tooltipText={t(
                                        'tooltips:autoAttendants.enableBusinessHours',
                                    )}
                                    type="small"
                                />
                            </div>

                            {values.enableBusinessHours && (
                                <div
                                    className={classNames(
                                        classes.row,
                                        classes.rangesSection,
                                    )}
                                >
                                    <TimeRangePicker
                                        label={t('common:time')}
                                        ampm={ampm}
                                        defaultValue={prepareDefaultRangeDate(
                                            values.startTime,
                                            values.endTime,
                                            ampm,
                                        )}
                                        onChange={(value) => {
                                            const result = convertRangeToStartEndTime(
                                                value,
                                                ampm,
                                            );
                                            setFieldValue(
                                                `startTime`,
                                                result.startTime,
                                                false,
                                            );
                                            setFieldValue(
                                                `endTime`,
                                                result.endTime,
                                                false,
                                            );
                                        }}
                                        helperText={
                                            errors?.startTime || errors?.endTime
                                        }
                                        className={classes.timeRangeContainer}
                                        required
                                    />
                                    <SelectField
                                        label={t('screens:ringSchedule.days')}
                                        items={daysSelectItems}
                                        value={
                                            daysSelectItems.filter((v) =>
                                                values.days
                                                    .map(
                                                        (v: SelectItem) =>
                                                            v.value,
                                                    )
                                                    .includes(v.value),
                                            ) || null
                                        }
                                        multiple
                                        onChange={(_, value) => {
                                            setFieldValue(`days`, value);
                                        }}
                                        renderTags={CustomTags}
                                        className={classes.daysMonthsSelect}
                                        getOptionLabel={(v: SelectItem) =>
                                            v.name
                                        }
                                        getOptionSelected={(
                                            option: SelectItem,
                                            value: SelectItem,
                                        ) => value.value === option.value}
                                        classes={{
                                            container: classes.selectContainer,
                                        }}
                                    />
                                </div>
                            )}

                            <div
                                className={classNames(
                                    classes.row,
                                    classes.afterHoursSection,
                                )}
                            >
                                <CustomizedCheckbox
                                    checked={values.enableAfterHoursAndHolidays}
                                    label={t(
                                        'screens:autoAttendants.enableAfterHoursAndHolidays',
                                    )}
                                    dataQa="requirePinStatus"
                                    onChange={() =>
                                        setFieldValue(
                                            'enableAfterHoursAndHolidays',
                                            !values.enableAfterHoursAndHolidays,
                                        )
                                    }
                                    disabled={!values.enableBusinessHours}
                                />
                                <IconWithTooltip
                                    dataQa="auto0-attendants-tooltip"
                                    tooltipText={t(
                                        'tooltips:autoAttendants.enableAfterHoursAndHolidays',
                                    )}
                                    type="small"
                                />
                            </div>
                        </Grid>
                    )}
                    {isLoading && (
                        <Loader
                            dataQa="create-new-extension-loader"
                            absolutePosition
                        />
                    )}
                </form>
            )}
        </DialogContainer>
    );
};

export default CreateNewAutoAttendantDialog;

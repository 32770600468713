import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../../..';
import { CallBarringRule } from '../../../types/CallBarring';

export interface CallBarringReducerState {
    callBarringRules?: CallBarringRule[];
}

export const initialState: CallBarringReducerState = {};

export type CallBarringReducerActions = ActionType<typeof actions>;

export const callBarringReducer = createReducer<
    CallBarringReducerState,
    CallBarringReducerActions
>(initialState).handleAction(
    actions.getCallBarringRules.success,
    (state, action) => {
        return {
            ...state,
            callBarringRules: action.payload.rules,
        };
    },
);

export default callBarringReducer;

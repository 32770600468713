import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import {
    EmailOption,
    VoicemailFileFormat,
    VoicemailGreeting,
} from '../../../../store/types/Voicemail';
import { Colors } from '../../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '10px 38px',
        maxWidth: 944,
        display: 'flex',
        flexDirection: 'column',
    },
    rowContainer: {
        flex: 1,
        display: 'flex',
        paddingBottom: 24,
        alignItems: 'flex-start',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        margin: '3px 0',
    },
    separator: {
        height: 1,
        width: 440,
        backgroundColor: Colors.Border,
    },
    greetingContainer: {
        padding: '0px 23px 0px 40px',
    },
    inputs: {
        display: 'flex',
        padding: '0px 16px 0px 16px',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            flex: 1,
        },
    },
    switcherContainer: {
        marginBottom: 0,
    },
    rowStandardHeader: {
        marginRight: 14,
    },
    greetingsRadios: {
        margin: '15px 0',
    },
    radioContainer: {
        marginRight: 32,
    },
    greetingFileContainer: {
        marginTop: '0!important',
        padding: '0!important',
        '& .MuiAutocomplete-root': {
            width: 440,
        },
    },
    greetingDetailsText: {
        fontWeight: 400,
        fontSize: 12,
        color: Colors.Gray5,
        maxWidth: 410,
        marginTop: 5,
        padding: '0 15px',
        lineHeight: '13px',
        marginBottom: 15,
    },
    pinInput: {
        marginBottom: 17,
        maxWidth: 214,
    },
    bottomSeparator: {
        marginTop: 18,
        marginBottom: 16,
    },
    optionSelect: {
        maxWidth: 252,
        minWidth: 252,
        marginLeft: 16,
    },
    formatSelect: {
        maxWidth: 188,
        marginLeft: 16,
    },
    greetingOutsideContainer: {
        padding: 0,
        marginTop: 10,
    },
}));

export type UnifiedMessagingFormType = {
    unifiedMessagingStatus: boolean;
    unifiedMessagingBlocked: boolean;
    requirePinStatus: boolean;
    autoPlayStatus: boolean;
    announceDateStatus: boolean;
    pin: string;
    unifiedMessagingEmailAddress: string;
    emailOption: EmailOption;
    fileFormat: VoicemailFileFormat;
    greetingType: VoicemailGreeting;
    greetingFileName?: string;
    greetingFile?: File | null;
};

const minPinLength = i18n.t('errors:extensions.pin');
const requiredField = i18n.t('errors:common.emptyInput');
const pinOnlyDigits = i18n.t('errors:extensions.pinOnlyDigits');
const invalidEmail = i18n.t('errors:common.invalidEmail');

export const unifiedMessagingValidationSchema = Yup.object().shape({
    pin: Yup.string().when('requirePinStatus', {
        is: true,
        then: Yup.string()
            .matches(/^[0-9]*$/, pinOnlyDigits)
            .min(3, minPinLength)
            .required(requiredField),
        otherwise: Yup.string().notRequired(),
    }),
    unifiedMessagingEmailAddress: Yup.string()
        .email(invalidEmail)
        .notRequired(),
});

export const unifiedMessagingFormDefaultValues: UnifiedMessagingFormType = {
    unifiedMessagingStatus: false,
    unifiedMessagingBlocked: false,
    requirePinStatus: false,
    autoPlayStatus: false,
    announceDateStatus: false,
    pin: '',
    unifiedMessagingEmailAddress: '',
    emailOption: EmailOption.None,
    fileFormat: VoicemailFileFormat.WAV,
    greetingType: VoicemailGreeting.Standard,
};

import React, {useEffect, useMemo} from 'react';
import TextField from '../../../components/TextField/TextField';
import SelectField from '../../../components/SelectField/SelectField';
import CustomButton from '../../../components/Button/Button';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {SipTrunksListFilters, useStyles} from './FiltersComponent.utils';
import {BillStatus} from "../../../store/types/SipTrunk";

interface Filters {
    page: number;
    pageSize: number;
    handleSearch: (
        params?: { pageSize: number; page: number },
        filters?: SipTrunksListFilters,
    ) => void;
    handleForceFirstPage?: () => void;
    triggerSearch?: boolean;
}

const FiltersComponent: React.VFC<Filters> = (
    {
        page,
        pageSize,
        handleSearch,
        handleForceFirstPage,
        triggerSearch,
    }) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik<SipTrunksListFilters>({
        initialValues: {
            trunkIdFilter: '',
            didNumberFilter: '',
            statusFilter: undefined,
        },
        onSubmit: (form) => {
            handleSearch({page: 0, pageSize}, form);
            handleForceFirstPage?.();
        },
    });

    const items = useMemo(() => {
        return [
            {
                value: '',
                title: t('screens:devices.anyStatus'),
                details: {},
            },
            {
                title: t('screens:extensions.sipStatusRegister'),
                details: {sip_status: 1, blocked: 'N'},
            },
            {
                title: t('screens:extensions.sipStatusUnregister'),
                details: {sip_status: 0, blocked: 'N'},
            },
            {
                title: t('screens:extensions.disabled'),
                details: {sip_status: null, blocked: 'Y'},
            },
            {
                title: t('screens:sipTrunks.inactive'),
                details: {
                    blocked: "N", bill_status:BillStatus.Inactive, sip_status: 1
                },
            },
        ];
    }, []);

    useEffect(() => {
        handleSearch({page, pageSize}, values);
    }, [page, pageSize]);

    useEffect(() => {
        triggerSearch && handleSearch({page, pageSize}, values);
    }, [triggerSearch]);

    return (
        <form
            className={classes.filtersContainer}
            onSubmit={handleSubmit}
            data-qa={'sip-trunks-list-filters'}
            data-testid={'sip-trunks-list-filters'}
        >
            <div className={classes.filtersInputs}>
                <TextField
                    id={'trunkIdFilter'}
                    className={classes.filterSeparation}
                    label={t('screens:sipTrunks.trunkId')}
                    value={values.trunkIdFilter}
                    onChange={handleChange}
                    dataQa={'filter-by-id'}
                />

                <TextField
                    id={'didNumberFilter'}
                    className={classes.filterSeparation}
                    label={t('screens:extensions.didNumber')}
                    value={values.didNumberFilter}
                    onChange={handleChange}
                    dataQa={'filter-by-didNumber'}
                />

                <SelectField
                    id={'statusFilter'}
                    className={classes.filterSeparation}
                    label={t('common:status')}
                    getOptionLabel={(o) => o.title}
                    items={items}
                    dataQa={'filter-by-status'}
                    onChange={(e, value) => {
                        setFieldValue('statusFilter', value);
                    }}
                    value={
                        !!values.statusFilter ? values.statusFilter : items[0]
                    }
                    disableClearable
                />
            </div>
            <CustomButton dataQa="search-button" primary accent type="submit">
                {t('common:search')}
            </CustomButton>
        </form>
    );
};

export default FiltersComponent;

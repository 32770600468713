import { Colors } from '../../styles/Colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';

type ExtensionCellProps = {
    mainValue?: string | number | React.ReactNode;
    secondaryValue?: string | number | React.ReactNode;
    emptyText?: string;
};

const ExtensionCell: React.FC<ExtensionCellProps> = ({
    mainValue,
    secondaryValue,
    emptyText,
}) => {
    const { t } = useTranslation();

    const value =
        mainValue ?? (secondaryValue ? t('common:reserved') : undefined);

    return (
        <OverflowTooltip
            tooltip={value ?? emptyText}
            text={
                <span>
                    {
                        <span
                            style={{
                                fontSize: 16,
                                color: !mainValue ? Colors.Gray9 : Colors.Text,
                            }}
                        >
                            {value ?? emptyText}
                        </span>
                    }
                </span>
            }
            copy={false}
        />
    );
};

export default ExtensionCell;

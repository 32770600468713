import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';

export const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 16,
    },
    secondColumn: {
        marginLeft: 50,
    },
    header: {
        fontWeight: 700,
        color: Colors.Text,
        lineHeight: '20px',
        maxWidth: 170,
    },
    title: {
        color: Colors.Text,
        lineHeight: '20px',
    },
    secondaryText: {
        color: Colors.Gray5,
        fontSize: 12,
        lineHeight: '16px',
    },
}));

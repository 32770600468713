import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { Reset2FADialogProps, useStyles } from './Reset2FADialog.utils';
import CustomizedTextField from '../TextField/TextField';
import classNames from 'classnames';

const Reset2FADialog: React.VFC<Reset2FADialogProps> = ({
    onClose,
    isOpen,
    i_object,
    object,
    mfaError
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [oneTimePassword, setOneTimePassword] = useState<string>('');
    const [fieldError, setFieldError] = useState<string | undefined>(undefined);

    const handleSubmit = useCallback((lastVal?: string) => {
        dispatch(actions.resetMfa.request({
            i_object: i_object,
            object: object,
            one_time_password: lastVal || oneTimePassword,
            onSuccess: () => {
                setOneTimePassword('');
                onClose?.(true);
            }
        }));
    }, [oneTimePassword, i_object, object, onClose]);

    useEffect(() => {
        if(mfaError) {
            const leftAttempts = (mfaError?.mfa_attempts || 0);
            
            if(leftAttempts > 0) {
                const faultString = t(leftAttempts === 1
                    ? 'screens:twoFa.incorrectCodeAttemptCount'
                    : 'screens:twoFa.incorrectCodeAttemptsCount', {
                    value: leftAttempts?.toString()
                });
                setFieldError(faultString);
                setOneTimePassword('');
            } else if(leftAttempts === 0) {
                setTimeout(() => {
                    dispatch(actions.removeAuthDataAndReload({reload: true}));
                }, 5000);
            }
        } else {
            setFieldError(undefined);
        }
    }, [mfaError]);

    const isMaxAttemptsReached = useMemo(() => {
        return !!mfaError && (mfaError?.mfa_attempts || 0) === 0;
    }, [mfaError]);

    const actionButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={() => {
                onClose?.(false);
                dispatch(actions.cleanupMfaConfig());
            }}
        />,
        <DialogButton
            key="save"
            label={t('screens:twoFa.resetButton')}
            onClick={handleSubmit}
            disabled={oneTimePassword.length !== 6 || isMaxAttemptsReached}
            className={classNames(classes.primaryButton, (oneTimePassword.length !== 6) ? classes.disabledButton : null)}
        />,
    ];

    return (
        <DialogContainer
            isOpen={isOpen}
            header={t('screens:twoFa.reset2FADialogTitle')}
            headerClass={classes.header}
            dialogActionsButtons={actionButtons}
            className={classes.root}
        >
            <Grid container className={classes.itemsContainer}>
                {isMaxAttemptsReached ? (
                    <div className={classes.maxAttemptsReached}>{t('screens:twoFa.maxAttemptsReached')}</div>)
                : (
                        <Grid>
                            <Grid>
                                <CustomizedTextField
                                    autoFocus
                                    id="one_time_password"
                                    label={t('screens:twoFa.enterCode')}
                                    value={oneTimePassword}
                                    required
                                    helperText={
                                        fieldError && fieldError.length > 0
                                            ? fieldError
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setOneTimePassword(e.target.value);
                                        if(e.target.value.length >= 6) {
                                            setTimeout(() => {
                                                handleSubmit(e.target.value);
                                            }, 100);
                                        }
                                    }}
                                    maxLength={6}
                                    dataQa="one_time_password-field"
                                    skipPermission
                                />

                            </Grid>
                            <Grid className={classes.helperMsg}>
                                {t('screens:twoFa.helpResetMsg')}
                            </Grid>

                        </Grid>
                    )}
            </Grid>
        </DialogContainer>
    );
};
export default Reset2FADialog;

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { Reset2FADialogObjectType } from './Reset2FADialog.utils';

export type TwoFaSettingProps = {
    object: Reset2FADialogObjectType;
    i_object: number;
    isMyOwn: boolean;
    login?: string;
};

export const useStyles = makeStyles(() => ({
    mainContainer: {
        width: '100%',
    },

    containerLine: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        marginBottom: 34
    },

    switchLabel: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '20px',
        color: Colors.Gray7,
        marginTop: 10
    },

    tooltipIconContainer: {
        marginTop: 6,
        marginLeft: 12
    },

    resetButton: {
        minWidth: 160,
        height: 40,
        justifyContent: 'center'
    },
    
    disableAlert: {
        '& .MuiPaper-root': {
            maxWidth: '382px !important',
            width: '382px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: '334px !important',
            paddingBottom: '19px !important',
        },
    },
    
    contentDialog: {
        backgroundColor: Colors.White + ' !important',
        wordBreak: 'break-word'
    },

    resetAlert: {
        '& .MuiPaper-root': {
            maxWidth: '382px !important',
            width: '382px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: '334px !important',
            paddingBottom: '22px !important',
        },
    },
    
    enableAlert: {
        '& .MuiPaper-root': {
            maxWidth: '382px !important',
            width: '382px !important',
        },

        '& .MuiDialogContent-root': {
            minWidth: '334px !important',
            paddingBottom: '22px !important',
        },
    },
}));
import { FormikErrors } from 'formik';
import * as Yup from 'yup';
import i18n from '../../../../services/i18n';
import { ServiceCodesFormType } from './serviceCodesUtils';

const emptyInput = i18n.t<string>('errors:common.emptyInput');
const nonUniqueError = i18n.t<string>('errors:callSettings.uniqueRule');
const ruleRegexError = i18n.t<string>('errors:callSettings.ruleRegex');
const max6LengthError = i18n.t<string>('errors:callSettings.max6Length');

const codeRegex = /^[0-9*#]*$/;

const ruleValidation = Yup.string()
    .matches(codeRegex, ruleRegexError)
    .max(6, max6LengthError);

const serviceCodesValidation = (
    name: string,
    parent: any,
    exceptions: string[],
) => {
    for (const key in parent) {
        if (
            key !== name &&
            parent[key] &&
            !exceptions.includes(key) &&
            parent[key] === parent[name]
        ) {
            return false;
        }
    }

    return true;
};

export const serviceCodesFormValidationSchema = Yup.object().shape({
    rules: Yup.array().of(
        Yup.object().shape({
            dial_codes: Yup.object().shape(
                {
                    barge_in: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'barge_in',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    barge_in_dtmf: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'barge_in_dtmf',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    bypass_dial_plan: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'bypass_dial_plan',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    call_park: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'call_park',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    call_release: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'call_release',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    clir_hide: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'clir_hide',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    clir_show: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'clir_show',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    cs_bulk_management: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'cs_bulk_management',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    cs_individual_management: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'cs_individual_management',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    dial_out: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'dial_out',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    group_pickup: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'group_pickup',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    huntgroup_login: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'huntgroup_login',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    huntgroup_logout: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'huntgroup_logout',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    paging_prefix: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'paging_prefix',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    recording_off: ruleValidation
                        .test(
                            'columns-no-duplicate-names',
                            nonUniqueError,
                            function () {
                                return serviceCodesValidation(
                                    'recording_off',
                                    this.parent,
                                    ['recording_off_dtmf'],
                                );
                            },
                        )
                        .when('recording_on', {
                            is: (value: any) => !!value,
                            then: Yup.string().required(emptyInput),
                        }),
                    recording_off_dtmf: ruleValidation
                        .test(
                            'columns-no-duplicate-names',
                            nonUniqueError,
                            function () {
                                return serviceCodesValidation(
                                    'recording_off_dtmf',
                                    this.parent,
                                    ['recording_off'],
                                );
                            },
                        )
                        .when('recording_on_dtmf', {
                            is: (value: any) => !!value,
                            then: Yup.string().required(emptyInput),
                        }),
                    recording_on: ruleValidation
                        .test(
                            'columns-no-duplicate-names',
                            nonUniqueError,
                            function () {
                                return serviceCodesValidation(
                                    'recording_on',
                                    this.parent,
                                    ['recording_on_dtmf'],
                                );
                            },
                        )
                        .when('recording_off', {
                            is: (value: any) => !!value,
                            then: Yup.string().required(emptyInput),
                        }),
                    recording_on_dtmf: ruleValidation
                        .test(
                            'columns-no-duplicate-names',
                            nonUniqueError,
                            function () {
                                return serviceCodesValidation(
                                    'recording_on_dtmf',
                                    this.parent,
                                    ['recording_on'],
                                );
                            },
                        )
                        .when('recording_off_dtmf', {
                            is: (value: any) => !!value,
                            then: Yup.string().required(emptyInput),
                        }),
                    spy: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'spy',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    spy_dtmf: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'spy_dtmf',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    voicemail: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'voicemail',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    whisper: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'whisper',
                                this.parent,
                                [],
                            );
                        },
                    ),
                    whisper_dtmf: ruleValidation.test(
                        'columns-no-duplicate-names',
                        nonUniqueError,
                        function () {
                            return serviceCodesValidation(
                                'whisper_dtmf',
                                this.parent,
                                [],
                            );
                        },
                    ),
                },
                [
                    ['recording_off_dtmf', 'recording_on_dtmf'],
                    ['recording_off', 'recording_on'],
                ],
            ),
        }),
    ),
});

export const getRuleError = (
    errors: FormikErrors<ServiceCodesFormType>,
    selectedRuleIndex: number,
    name: string,
) => {
    if (typeof errors?.rules?.[selectedRuleIndex] !== 'string') {
        const item = errors?.rules?.[selectedRuleIndex];
        if (item && typeof item !== 'string') {
            //@ts-ignore
            return item.dial_codes[name];
        }
    }
    return undefined;
};

import { put, select, call, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { getServiceFeatureValue } from '../../../../utils/extensions/getServiceValue';
import * as actions from '../../../actions';
import { ReduxState } from '../../../types';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';
import {
    getCountriesList,
    getSubdivisionsData,
    getTimeZonesList,
} from '../../generic/saga';
import { getExtensionRingGroups } from '../assignedRingGroups/saga';
import { getCallBarringRules } from '../callBarring/saga';
import { getExtensionCallForwardingSettings } from '../callForwarding/saga';
import {
    getCallProcessingOperationModeList,
    getCustomerExtensionDetails,
    getExtensionDialingRule,
} from '../extensions/saga';
import { getExtensionMohDetails } from '../mohs/saga';
import { getExtensionProducts } from '../plan/saga';
import { getPermittedSipProxies } from '../sip/saga';
import {
    getExtensionVoicemailSettings,
    getGreetingsFilesNames,
} from '../voicemail/saga';
import {
    getCallProcessingPolicyList,
    getCallScreeningConditionData,
    getCpRuleList,
} from '../callScreening/saga';

export function* getExtensionTabData(
    action: ActionType<typeof actions.getExtensionTabData.request>,
) {
    const { i_c_ext, i_customer, i_account } = action.payload;

    if (i_account) {
        yield all([
            call(
                getCustomerExtensionDetails,
                actions.getCustomerExtensionDetails.request({
                    extensionId: i_c_ext,
                    customerId: i_customer,
                }),
            ),

            call(
                getExtensionRingGroups,
                actions.getExtensionRingGroups.request({
                    i_c_ext,
                }),
            ),
            call(getCountriesList),
            call(getTimeZonesList),
        ]);

        const country: string | undefined = yield select(
            (state: ReduxState) =>
                state.extensions.extensionDetails?.account_info?.country,
        );

        if (country) {
            yield call(
                getSubdivisionsData,
                actions.getSubdivisionData.request({
                    iso_3166_1_a2: country,
                }),
            );
        }
    }

    yield put(actions.getExtensionTabData.success());
}

export function* getCallSettingsTabData(
    action: ActionType<typeof actions.getCallSettingsTabData.request>,
) {
    const serviceFeatures: ServiceFeature[] = yield select(
        (state: ReduxState) => state.extensions.serviceFeatures || [],
    );

    const callBarringStatus = getServiceFeatureValue(
        ServiceFeatureName.CallBarring,
        serviceFeatures,
    );

    if (callBarringStatus && callBarringStatus != 'N') {
        yield call(
            getCallBarringRules,
            actions.getCallBarringRules.request({
                i_account: action.payload.i_account,
            }),
        );
    }

    if (action.payload.domain.length) {
        yield all([
            call(
                getExtensionVoicemailSettings,
                actions.getExtensionVoicemailSettings.request({
                    accountId: action.payload.id,
                    domain: action.payload.domain,
                }),
            ),
            call(
                getGreetingsFilesNames,
                actions.getGreetingsFilesNames.request({
                    accountId: action.payload.id,
                    domain: action.payload.domain,
                }),
            ),
        ]);
    }

    yield call(
        getExtensionMohDetails,
        actions.getExtensionMohDetails.request({
            accountId: action.payload.i_account,
        }),
    );

    const i_dialing_rule = getServiceFeatureValue(
        ServiceFeatureName.VoiceDialing,
        serviceFeatures,
        'i_dial_rule',
    );

    if (i_dialing_rule) {
        yield getExtensionDialingRule(i_dialing_rule);
    }

    yield put(actions.getCallSettingsTabData.success());
}

export function* getPlanTabData() {
    yield call(getExtensionProducts);
    yield put(actions.getPlanTabData.success());
}

export function* getDeviceTabData() {
    yield put(actions.getDeviceTabData.success());
}

export function* getCallForwardingTabData(
    action: ActionType<typeof actions.getCallForwardingTabData.request>,
) {
    yield all([
        call(
            getExtensionCallForwardingSettings,
            actions.getExtensionFollowMeSettings.request({
                accountId: action.payload.i_account,
            }),
        ),
        call(
            getPermittedSipProxies,
            actions.getPermittedSipProxies.request({
                i_account: action.payload.i_account,
            }),
        ),
    ]);

    yield put(actions.getCallForwardingTabData.success());
}

export function* getCallScreeningTabData(
    action: ActionType<typeof actions.getCallScreeningTabData.request>,
) {
    yield all([
        call(
            getCpRuleList,
            actions.getCpRuleList.request({
                i_account: action.payload.i_account,
            }),
        ),
        call(
            getCallScreeningConditionData,
            actions.getCallScreeningConditions.request({
                i_account: action.payload.i_account,
            }),
        ),
        call(
            getCallProcessingPolicyList,
            actions.getCallProcessingPolicyList.request({
                i_customer: action.payload.i_customer,
                with_rules: 1,
            }),
        ),
        call(
            getCallProcessingOperationModeList,
            actions.getCallProcessingOperationModeList.request({
                check_usage: 0
            }),
        ),
    ]);

    yield put(actions.getCallScreeningTabData.success());
}

import React, { useCallback, useState, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import SwitchWithLabel from '../SwitchWithLabel/SwitchWithLabel';
import { useTranslation } from 'react-i18next';
import SelectField from '../SelectField/SelectField';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import Tooltip from '@material-ui/core/Tooltip';
import UploadFile from '../UploadFile/UploadFile';
import { MohInfo } from '../../store/types/AccountMoh';
import { ApiFile } from '../../store/types/ApiFile';

import {
    OnHoldMusicProps,
    useStyles,
    MusicUpdateFormProps,
    OnHoldMusicValidationSchema,
    initialValues,
} from './utils';
import { useFormik } from 'formik';

const OnHoldMusic: React.FC<OnHoldMusicProps> = ({
    value,
    setValue,
    disabled,
    mohItems = [],
    selectedMohFile,
    withTooltip,
    onSave = undefined,
    customTooltip,
    fileSelectId,
    disableSelectHide = false,
    hideSwitcher,
    customLabel,
    customSelectLabel,
    classes: customClasses,
    id,
    helperText,
    withoutUpload,
    customIcon
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const classes = useStyles();

    const generateSwitcherWithProperState = useCallback(
        (switcher: JSX.Element, withTooltip: boolean | undefined) => {
            if (withTooltip) {
                const title = t('tooltips:incomings.locked');
                return (
                    <Tooltip placement="top-start" title={title}>
                        <div>{switcher}</div>
                    </Tooltip>
                );
            }
            return switcher;
        },
        [value],
    );

    const { setFieldValue } = useFormik<MusicUpdateFormProps>({
        initialValues,
        onSubmit: () => {
            null;
        },
        validationSchema: OnHoldMusicValidationSchema,
        validateOnChange: false,
    });

    const items = useMemo(() => {
        return mohItems?.map((v: MohInfo | ApiFile) => ({
            name: v.name,
            value: v.name,
        }));
    }, [mohItems]);

    return (
        <div
            className={classNames(
                classes.inputs,
                customClasses?.outsideContainer,
            )}
        >
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.columnItemsContainer,
                    customClasses?.container,
                )}
            >
                {!hideSwitcher && (
                    <Box
                        className={classNames(
                            classes.rowBox,
                            classes.switchMargin,
                        )}
                    >
                        {generateSwitcherWithProperState(
                            <SwitchWithLabel
                                label={
                                    customLabel ||
                                    t('screens:extensions.onHoldMusic')
                                }
                                setValue={setValue}
                                value={value}
                                field={id || 'onHoldMusicSwitcher'}
                                id={id || 'onHoldMusicSwitcher'}
                                disabled={disabled}
                                className={classNames(
                                    classes.switcher,
                                    customClasses?.switcher,
                                )}
                            />,
                            withTooltip,
                        )}

                        <IconWithTooltip
                            dataQa="on-hold-music"
                            tooltipText={
                                customTooltip
                                    ? customTooltip
                                    : t('tooltips:callSettings.onHoldMusic')
                            }
                            copy={false}
                        />
                    </Box>
                )}
                {(value || disableSelectHide) && (
                    <Box
                        className={classNames(
                            classes.musicRowBox,
                            customClasses?.selectContainer,
                        )}
                    >
                        <SelectField
                            disableClearable={true}
                            label={
                                customSelectLabel ||
                                t('screens:callSettings.musicTitle')
                            }
                            items={[
                                !withoutUpload
                                    ? t('common:uploadNewFile')
                                    : undefined,
                                ...(items?.map((v) => v.name) || []),
                            ].filter((v) => !!v)}
                            value={selectedMohFile || null}
                            dataQa="extension-onmusic-file-input"
                            onChange={(e, v) => {
                                if (v === t('common:uploadNewFile')) {
                                    setIsModalOpen(true);
                                    setFieldValue('fileName', '');
                                    setFieldValue('file', null);
                                    setFieldValue('musicFile', '');
                                } else {
                                    setValue(
                                        fileSelectId || 'selectedMohFile',
                                        v,
                                    );
                                }
                            }}
                            classes={{ option: classes.option }}
                            disableAutoSettingValue
                            helperText={helperText}
                            icon={customIcon}
                        />
                    </Box>
                )}
            </Grid>
            <UploadFile
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onSave={onSave}
                items={items}
            />
        </div>
    );
};

export default OnHoldMusic;

import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';
import { sipDefaultValues } from '../components/Extensions/Sip';
import {ConfigData} from "../store/types/ConfigData";
import { ServiceFeatureName } from '../store/types/ServiceFeature';
import { YesNo } from '../store/types/CallScreening';

export const useExtensionDeviceFormData = () => {
    const { sipInfoErrors, savingSipInfo, serviceFeatures } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const extension = useSelector(
        (state: ReduxState) => state.extensions.extensionDetails,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );
    
    const lineSharing = useMemo(() => serviceFeatures?.find(
        (e) => e.name === ServiceFeatureName.LineSharing
    ), [serviceFeatures]);

    const initFormData = useMemo(() => {
        if (!extension) {
            return sipDefaultValues;
        }
        return {
            iAccount: extension.i_account,
            savingSipInfo: savingSipInfo,
            registrationName: extension.account_id,
            servicePassword: extension.account_info?.h323_password || '',
            userAgent: extension.account_info?.sip_agent || '',
            sipInfoErrors: sipInfoErrors,
            sipContact: extension.account_info?.sip_contact || '',
        };
    }, [extension, sipInfoErrors, savingSipInfo]);

    return {
        initFormData: initFormData,
        data: {
            passwordConfig: config?.Web,
            passwordLength: config?.Accounts.CreditAccMinServicePasswdLength ? Number(config?.Accounts.CreditAccMinServicePasswdLength) : 6,
            extension: extension,
            sipStatus: !!extension?.account_info?.sip_status,
            sharedLine: lineSharing?.effective_flag_value === YesNo.Yes,
            sharedLineLocked: lineSharing?.locked === 1,
        },
    };
};

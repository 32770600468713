import {ApiChangePasswordPayload} from "../../../store/services/auth-api";
import {makeStyles} from "@material-ui/core/styles";
import {Colors, errorColor, white} from "../../../styles/Colors";

export const useStyles = makeStyles(() => ({
    errors: {
        '& .MuiFormLabel-root .MuiFormLabel-filled': {
            color: `${errorColor} `,
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },

    submitButtonContainer: {
        width: '100%'
    },
    submitButton: {
        width: '100%',
        height: 56,
        '& span': {
            color: Colors.White,
            fontSize: 16,
            fontWeight: '700',
        },

        '& .MuiCircularProgress-svg': {
            color: Colors.White + '!important',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },

        '& .MuiInputBase-root': {
            height: 56,
        },

    },

    action: {
        display: 'flex',
        marginTop: 44,
        '& button:first-child': {
            width: '100%',
            '& span': {
                color: white,
            },
        },
    },
}));

export type PasswordExpirationPros = {
    onSubmitAction: (payload: ApiChangePasswordPayload) => void;
    validateOnChange: boolean;
    login: string;
    oldPassword: string;
    apiErrorsExist?: boolean,
    inProgress?:boolean
};
import { AxiosResponse } from 'axios';
import { select, put } from 'redux-saga/effects';
import { actions } from '../../..';
import { Account } from '../../../../services/endpoints';
import JSONFormData from '../../../../utils/JSONFormData';
import { api } from '../../../services/axios';
import { ExtensionType } from '../../../types/Extension';
import {
    ServiceFeature,
    ServiceFeatureName,
} from '../../../types/ServiceFeature';

export function* getAllServiceFeatures(i_account: number | string) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_account,
        get_included_services: 1,
    });

    const accountGetInfoRes: AxiosResponse<{
        account_info: ExtensionType;
    }> = yield api.post(Account.GetAccountInfo, body);

    yield put(
        actions.getServiceFeatures.success(
            accountGetInfoRes.data.account_info.service_features || [],
        ),
    );
}

export function* getServiceFeatures(
    i_account: number | string,
    serviceFeaturesNames: ServiceFeatureName[],
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_account,
        get_service_features: serviceFeaturesNames,
    });

    const accountGetInfoRes: AxiosResponse<{
        account_info: ExtensionType;
    }> = yield api.post(Account.GetAccountInfo, body);

    yield put(
        actions.getServiceFeatures.success(
            accountGetInfoRes.data.account_info.service_features || [],
        ),
    );
}

export function* editServiceFeatures(
    i_account: number | string,
    serviceFeatures: ServiceFeature[],
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({
        i_account,
        service_features: serviceFeatures,
    });

    yield api.post(Account.UpdateServiceFeatures, body);
}

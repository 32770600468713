import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import {
    UnifiedMessagingFormType,
    useStyles,
} from './UnifiedMessagingForm.utils';
import {
    VoicemailFileFormat,
    VoicemailGreeting,
} from '../../../../store/types/Voicemail';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import CustomizedRadio from '../../../Radio/Radio';
import OnHoldMusic from '../../../OnHoldMusic/OnHoldMusic';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import TextField from '../../../TextField/TextField';
import EmailOptionSelect from '../../../EmailOptionSelect/EmailOptionSelect';
import SelectField from '../../../SelectField/SelectField';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store/types';

const UnifiedMessagingForm: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const greetingsFilesNames = useSelector<
        ReduxState,
        { [key in VoicemailGreeting]?: string } | undefined
    >((state) => state.extensions.greetingsFilesNames);

    const {
        values,
        setFieldValue,
        handleChange,
        initialValues,
        errors,
        setFieldError,
    } = useFormikContext<UnifiedMessagingFormType>();

    const greetingSelectList = useMemo(
        () =>
            Object.values(VoicemailGreeting).map((v) => ({
                name: t(`enums:voicemailGreeting.${v}`),
                value: v,
            })),
        [],
    );

    const fileFormatsSelectList = useMemo(
        () =>
            Object.values(VoicemailFileFormat).map((v) => ({
                name: v.toUpperCase(),
                value: v,
            })),
        [],
    );

    const greetingFiles = useMemo(
        () =>
            values.greetingFileName
                ? [{ id: 1, name: values.greetingFileName }]
                : undefined,
        [values.greetingType, values.greetingFileName],
    );

    const onChangeGreeting = useCallback(
        (v: { name: string; value: VoicemailGreeting }) => {
            setFieldValue('greetingType', v.value);
            setFieldValue(
                'greetingFileName',
                greetingsFilesNames?.[v.value] || null,
            );
        },
        [greetingsFilesNames, setFieldValue],
    );

    const onSaveMoh = useCallback(
        (file, name, callback) => {
            setFieldValue('greetingFile', file);
            setFieldValue('greetingFileName', name);
            callback?.();
        },
        [setFieldValue],
    );

    return (
        <div className={classes.inputs}>
            <SwitchWithLabel
                className={classes.switcherContainer}
                id="unifiedMessagingStatus"
                field="unifiedMessagingStatus"
                label={t('screens:extensions.unifiedMessaging')}
                value={values.unifiedMessagingStatus}
                disabled={values.unifiedMessagingBlocked}
                setValue={setFieldValue}
                icon={
                    <ServiceIcon
                        type={Service.UnifiedMessaging}
                        dataQa="unified-messaging-service"
                        dataTestId="unified-messaging-service"
                    />
                }
            />
            {values.unifiedMessagingStatus &&
                initialValues.unifiedMessagingStatus && (
                    <Grid
                        item
                        className={classNames(
                            classes.itemsContainer,
                            classes.greetingContainer,
                        )}
                    >
                        <div className={classes.row}>
                            <span className={classes.rowStandardHeader}>
                                {t('screens:extensions.greetingType')}
                            </span>
                            <IconWithTooltip
                                dataQa="greetingType-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.greetingType',
                                )}
                                type="small"
                            />
                        </div>

                        <div className={classes.row}>
                            {greetingSelectList.map((v) => (
                                <CustomizedRadio
                                    key={v.value}
                                    value={v.value}
                                    className={classes.radioContainer}
                                    checked={values.greetingType === v.value}
                                    label={v.name}
                                    dataQa={`greetingTypeRadio-${v.value}`}
                                    onChange={() => onChangeGreeting(v)}
                                />
                            ))}
                        </div>

                        <OnHoldMusic
                            id="greetingFileName"
                            fileSelectId="greetingFileName"
                            setValue={setFieldValue}
                            value={true}
                            hideSwitcher
                            disableSelectHide
                            withTooltip={false}
                            customSelectLabel={t(
                                'screens:extensions.greetingRecord',
                            )}
                            mohItems={greetingFiles}
                            selectedMohFile={values.greetingFileName || ''}
                            onSave={onSaveMoh}
                            classes={{
                                container: classes.greetingFileContainer,
                                selectContainer: classes.greetingFileContainer,
                                outsideContainer:
                                    classes.greetingOutsideContainer,
                            }}
                            withoutUpload={
                                values.greetingType ===
                                VoicemailGreeting.Standard
                            }
                        />

                        <span className={classes.greetingDetailsText}>
                            {t(
                                `enums:voicemailGreetingDetails.${values.greetingType}`,
                            )}
                        </span>

                        <div className={classes.separator} />

                        <div className={classes.row}>
                            <CustomizedCheckbox
                                checked={values.requirePinStatus}
                                label={t('screens:extensions.requirePin')}
                                dataQa="requirePinStatus"
                                onChange={() =>
                                    setFieldValue(
                                        'requirePinStatus',
                                        !values.requirePinStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.requirePin',
                                )}
                                type="small"
                            />
                        </div>

                        {values.requirePinStatus && (
                            <TextField
                                id="pin"
                                label={t('screens:extensions.pin')}
                                onChange={handleChange}
                                value={values.pin}
                                dataQa="pin-input"
                                className={classes.pinInput}
                                maxLength={5}
                                inputProps={{
                                    pattern: '[0-9]*',
                                }}
                                required
                                setFieldError={setFieldError}
                                helperText={errors?.pin}
                            />
                        )}

                        <div className={classes.row}>
                            <CustomizedCheckbox
                                checked={values.autoPlayStatus}
                                label={t('screens:extensions.autoPlayMessages')}
                                dataQa={`autoPlayStatus`}
                                onChange={() =>
                                    setFieldValue(
                                        'autoPlayStatus',
                                        !values.autoPlayStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t('tooltips:extensions.autoPlay')}
                                type="small"
                            />
                        </div>

                        <div className={classes.row}>
                            <CustomizedCheckbox
                                checked={values.announceDateStatus}
                                label={t('screens:extensions.announceDateTime')}
                                dataQa={`announceDateStatus`}
                                onChange={() =>
                                    setFieldValue(
                                        'announceDateStatus',
                                        !values.announceDateStatus,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="require-pin-tooltip"
                                tooltipText={t(
                                    'tooltips:extensions.announceDateTime',
                                )}
                                type="small"
                            />
                        </div>

                        <div
                            className={classNames(
                                classes.separator,
                                classes.bottomSeparator,
                            )}
                        />

                        <div className={classes.rowContainer}>
                            <TextField
                                id="unifiedMessagingEmailAddress"
                                label={t('screens:extensions.emailAddress')}
                                dataQa="email-address-input"
                                onChange={handleChange}
                                value={values.unifiedMessagingEmailAddress}
                                icon={
                                    <>
                                        <IconWithTooltip
                                            dataQa="email-address-tooltip"
                                            tooltipText={t(
                                                'tooltips:extensions.voicemailEmailAddress',
                                            )}
                                        />
                                    </>
                                }
                                iconPosition="end"
                                maxLength={128}
                                setFieldError={setFieldError}
                                helperText={errors.unifiedMessagingEmailAddress}
                            />

                            <EmailOptionSelect
                                label={t('screens:extensions.emailOption')}
                                onChange={(_, value) => {
                                    setFieldValue('emailOption', value.value);
                                }}
                                dataQa="email-option-input"
                                tooltipDataQa="email-option-tooltip"
                                tooltipMsg={t(
                                    'tooltips:extensions.emailOption',
                                )}
                                copyTooltip={false}
                                value={values.emailOption}
                                classes={{ container: classes.optionSelect }}
                            />
                            <SelectField
                                label={t('screens:extensions.fileFormat')}
                                items={fileFormatsSelectList}
                                value={fileFormatsSelectList.find(
                                    (v) => v.value === values.fileFormat,
                                )}
                                onChange={(_, value) => {
                                    setFieldValue(
                                        'fileFormat',
                                        value.value,
                                        false,
                                    );
                                }}
                                disableClearable
                                dataQa="file-format-input"
                                icon={
                                    <IconWithTooltip
                                        dataQa="file-format-tooltip"
                                        tooltipText={t(
                                            'tooltips:extensions.fileFormat',
                                        )}
                                    />
                                }
                                getOptionLabel={(v: { name: string }) => v.name}
                                getOptionSelected={(v: {
                                    value: VoicemailFileFormat;
                                }) => v.value === values.fileFormat}
                                classes={{ container: classes.formatSelect }}
                            />
                        </div>
                    </Grid>
                )}
        </div>
    );
};

export default UnifiedMessagingForm;

import * as React from 'react';

type HeleprTextProps = {
    text?: string;
    dataQa?: string;
    className?: string;
};

const HelperText: React.FC<HeleprTextProps> = ({ text, dataQa, className }) => {
    return text ? (
        <span
            data-qa={`helper-${dataQa}`}
            data-testid="textfield-helper"
            className={className}
        >
            {text}
        </span>
    ) : (
        <></>
    );
};

export default HelperText;

import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import CustomizedCheckbox from '../../../Checkbox/Checkbox';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import { Service, ServiceIcon } from '../../../Extensions/ServiceIcon';
import Tooltip from '@material-ui/core/Tooltip';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import { useStyles, CallBarringFormProps, CallBarringFormType } from './utils';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import classNames from 'classnames';

const CallBarringForm: React.FC<CallBarringFormProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { values, setFieldValue } = useFormikContext<CallBarringFormType>();

    const setValue = (field: string, value: boolean) => {
        setFieldValue(field, value);
    };

    const generateSwitcherWithProperState = useCallback(
        (switcher: JSX.Element, withTooltip: boolean) => {
            if (withTooltip) {
                const title = t('tooltips:incomings.locked');
                return (
                    <Tooltip placement="top-start" title={title}>
                        <div>{switcher}</div>
                    </Tooltip>
                );
            }
            return switcher;
        },
        [values],
    );
    return (
        <div className={classes.inputs}>
            <Grid item className={classes.itemsContainer}>
                <Box className={classes.rowBox}>
                    {generateSwitcherWithProperState(
                        <SwitchWithLabel
                            label={t('screens:extensions.callBarring')}
                            setValue={setValue}
                            value={values.callBarringStatus}
                            field={'callBarringStatus'}
                            id={'call-barring-status-switch'}
                            disabled={values.callBarringLocked}
                            icon={
                                <ServiceIcon
                                    type={Service.CallBarring}
                                    dataQa="unified-messaging-fax-only-service"
                                    dataTestId="unified-messaging-fax-only-service"
                                />
                            }
                        />,
                        values.callBarringLocked,
                    )}
                </Box>

                {values.callBarringStatus && (
                    <>
                        <Box className={classes.rowBox}>
                            <CustomizedCheckbox
                                className={classNames(
                                    classes.checkbox,
                                    classes.individualRules,
                                )}
                                checked={values.individualRules}
                                dataQa="call-barring-item-checkbox"
                                label={t(
                                    'screens:callSettings.individualRules',
                                )}
                                onChange={(e) =>
                                    setFieldValue(
                                        'individualRules',
                                        e.target.checked,
                                    )
                                }
                            />
                            <IconWithTooltip
                                dataQa="call-barring-individual-rules"
                                tooltipText={t(
                                    'tooltips:callSettings.callBarringStatus',
                                )}
                                copy={false}
                            />
                        </Box>
                        <Box className={classes.headerClass}>
                            <span>
                                {t('screens:callSettings.classOfPhoneNumber')}
                            </span>
                        </Box>
                        {!values.callBarringItems.length ? (
                            <div className={classes.noData}>
                                {t('common:noData')}
                            </div>
                        ) : (
                            <Grid
                                container
                                spacing={1}
                                className={classes.itemsElementsContainer}
                            >
                                {values.callBarringItems.map((item, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        key={index}
                                        className={classes.itemContainer}
                                    >
                                        <CustomizedTooltip
                                            title={
                                                (values.callBarringLocked && t('tooltips:billing.disabledFeature'))
                                                || (!values.callBarringLocked && (item.rule_description || t('screens:extensions.noNumberPattern')))
                                            }
                                            dataQa="call-barring-item-tooltip"
                                            copy={false}
                                        >
                                            <div>
                                                <CustomizedCheckbox
                                                    className={classes.checkbox}
                                                    checked={item.state === 'Y'}
                                                    dataQa="call-barring-item-checkbox"
                                                    label={item.name}
                                                    disabled={
                                                        !item.rule_description || values.callBarringLocked
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            `callBarringItems.[${index}].state`,
                                                            e.target.checked
                                                                ? 'Y'
                                                                : 'N',
                                                            false,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </CustomizedTooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default CallBarringForm;

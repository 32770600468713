import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { CustomerDIDNumberType } from '../../store/types/CustomerDIDNumber';
import { ExtensionType } from '../../store/types/Extension';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '50px',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            minWidth: 240,
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 24,
        },
        '& .MuiBox-root': {
            '& .MuiFormControlLabel-root': {
                marginRight: 36,
            },
        },
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            padding: 0,
            background: Colors.SmokeBackground,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 298,
            width: 604,
            '& form': {
                height: 298,
                width: '100%',
            },
        },

        '& .MuiGrid-root:last-child': {
            marginBottom: 30,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },
    primaryActionButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },

    twoItems: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    assignAccountSelect: {
        '& .MuiFormControl-root': {
            backgroundColor: 'white',
            marginTop: 18,
        },
    },
    select: {},
    marginBottom: {
        marginBottom: 7,
    },
    saveButtonDisabled: {
        backgroundColor: `${Colors.Gray8}!important`,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}!important`,
        },
    },
}));

export type AssignDIDNumberFormProps = {
    reassignMode: boolean;
    item?: CustomerDIDNumberType;
    accounts?: ExtensionType[];
    assignToAccount?: ExtensionType;
};

export type AssignDIDNumberDialogProp = {
    item?: CustomerDIDNumberType;
    isOpen: boolean;
    clickOnClose?: () => void;
};

import React, {useEffect} from 'react';
import {useTabStyles} from '../../views/Extensions/ExtensionDetails/utils';
import Loader from '../Loader/Loader';
import {Formik} from 'formik';
import CallScreeningForm from '../Forms/Extensions/CallScreening/CallScreeningForm';
import {useCallScreeningFormData} from '../../hooks/useCallScreeningFormData';
import {CallScreeningFormType} from '../Forms/Extensions/CallScreening/CallScreeningForm.utils';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import {TabInterface} from './Tabs.utils';
import RestrictedMessageProvider, {ProviderTabName} from "../../views/AutoAttendants/Details/RestrictedMessageProvider";

interface CallScreeningTabInterface extends TabInterface {
    showPBX?: boolean;
    forceTooltip?: string;
    extensionNumber?: string;
}

const CallScreeningTab: React.FC<CallScreeningTabInterface> = (
    {
        handleInitDataChange,
        handleSubmitForm,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        showPBX = true,
        forceTooltip,
        extensionNumber,

    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callScreeningTab
                ?.isLoading || false,
    );

    const {initFormData, data} = useCallScreeningFormData();

    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-screening-loader"/>
        </div>
    ) : (
        <RestrictedMessageProvider tabName={ProviderTabName.CallScreening}>
            <Formik<CallScreeningFormType>
                initialValues={initFormData}
                onSubmit={(form) => handleSubmitForm?.(form)}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {() => {
                    return (
                        <>
                            <CallScreeningForm
                                service={data.serviceValue}
                                mode={data.mode}
                                handleSubmitForm={handleSubmitForm}
                                handleDirtyChange={handleDirtyChange}
                                handleSetSubmitFunc={handleSetSubmitFunc}
                                handleSetIsValidFunc={handleSetIsValidFunc}
                                showPBX={showPBX}
                                forceTooltip={forceTooltip}
                                extensionNumber={extensionNumber}
                            />
                        </>
                    )
                }
                }

            </Formik>
        </RestrictedMessageProvider>

    );
};

export default CallScreeningTab;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';

const useStyles = makeStyles(() => ({
    custom: {
        minHeight: 20,
        maxHeight: 20,
        display: 'flex',
        width: '100%',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: Colors.LightGray,
        borderRadius: 5,
    },

    '@keyframes slideRight': {
        '0%': { left: '-50%' },
        '50%': { left: '100%' },
        '100%': { left: '-50%' },
    },
    customB: {
        left: 0,
        height: 'auto',
        width: '50%',
        animationName: '$slideRight',
        animation: '2s infinite',
        position: 'relative',
        display: 'flex',
    },

    left: {
        height: 'auto',
        width: '25%',
    },

    center: {
        width: '30%',
        backgroundColor: 'white',
        height: 'auto',
    },

    right: {
        backgroundImage: `linear-gradient(to left, ${Colors.LightGray}, white)`,
        height: 'auto',
        width: '25%',
    },
}));

type BarLoaderProps = {
    dataQa?: string;
    dataTestId?: string;
    backgroundColor?: string;
};

const BarLoader: React.VFC<BarLoaderProps> = ({
    dataQa,
    dataTestId = 'bar-loader',
    backgroundColor,
}) => {
    const classes = useStyles();

    return (
        <>
            <div
                className={classes.custom}
                data-testid={dataTestId}
                data-qa={dataQa}
                style={{ backgroundColor }}
            >
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className={classes.customB}>
                        <div
                            className={classes.left}
                            style={{
                                backgroundImage: `linear-gradient(to right, ${
                                    backgroundColor ?? Colors.LightGray
                                }, white)`,
                            }}
                        />
                        <div className={classes.center} />
                        <div
                            className={classes.right}
                            style={{
                                backgroundImage: `linear-gradient(to left, ${
                                    backgroundColor ?? Colors.LightGray
                                }, white)`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BarLoader;

import React from 'react';

export const PeopleIcon: React.VFC = () => {
    return  (
        <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.75 12.9688C13.7165 12.9688 14.5 13.7523 14.5 14.7188L14.4989 15.6808C14.6156 17.8699 12.9879 18.9778 10.0668 18.9778C7.15786 18.9778 5.5 17.888 5.5 15.7188V14.7188C5.5 13.7523 6.2835 12.9688 7.25 12.9688H12.75ZM1.75 7.96875L6.126 7.96883C6.04375 8.28843 6 8.62348 6 8.96875C6 10.0843 6.45665 11.0932 7.1932 11.8186L7.35526 11.9697L7.25 11.9688C6.94865 11.9688 6.65863 12.0172 6.38729 12.1068C5.52255 12.3923 4.84765 13.0952 4.60122 13.9769L4.56679 13.9778C1.65786 13.9778 0 12.888 0 10.7188V9.71875C0 8.75225 0.783502 7.96875 1.75 7.96875ZM18.25 7.96875C19.2165 7.96875 20 8.75225 20 9.71875L19.9989 10.6808C20.1156 12.8699 18.4879 13.9778 15.5668 13.9778L15.3985 13.976C15.1596 13.1221 14.5188 12.4361 13.6929 12.1347C13.4576 12.0488 13.2073 11.9941 12.947 11.9757L12.75 11.9688L12.6447 11.9697C13.4758 11.2367 14 10.164 14 8.96875C14 8.62348 13.9563 8.28843 13.874 7.96883L18.25 7.96875ZM10 5.96875C11.6569 5.96875 13 7.3119 13 8.96875C13 10.6256 11.6569 11.9688 10 11.9688C8.34315 11.9688 7 10.6256 7 8.96875C7 7.3119 8.34315 5.96875 10 5.96875ZM4.5 0.96875C6.15685 0.96875 7.5 2.3119 7.5 3.96875C7.5 5.6256 6.15685 6.96875 4.5 6.96875C2.84315 6.96875 1.5 5.6256 1.5 3.96875C1.5 2.3119 2.84315 0.96875 4.5 0.96875ZM15.5 0.96875C17.1569 0.96875 18.5 2.3119 18.5 3.96875C18.5 5.6256 17.1569 6.96875 15.5 6.96875C13.8431 6.96875 12.5 5.6256 12.5 3.96875C12.5 2.3119 13.8431 0.96875 15.5 0.96875Z"
                fill="#000000"
                opacity={0.38}
            />
        </svg>
    );
};

export default PeopleIcon;

import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Colors } from '../../styles/Colors';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import SidebarItem from './SidebarItem';
import history from '../../history';
import { Link } from 'react-router-dom';
import CustomizedLink from '../Link/CustomizedLink';
import { menuLocalStoreKey } from '../../hooks/useSidebarContext';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import PermissionProvider from '../PermissionProvider/PermissionProvider';

const MoreIcon = <ExpandMore style={{ height: 15, width: 15 }} />;
const LessIcon = <ExpandLess style={{ height: 15, width: 15 }} />;

export type SidebarItemProps = {
    className?: string;
    title: string;
    items: {
        title: string;
        route: string;
        permission: string[];
    }[];
    currentPath: string;
    active: boolean;
    sectionOpened: boolean;
    isSidebarOpen: boolean;
    toggleSection: () => void;
    toggleSidebar?: () => void;
    sectionIcon: JSX.Element;
    dataQa?: string;
    index: number;
};
const useStyles = makeStyles(() => ({
    tab: {
        marginRight: 8,
        '& svg': {
            '& path': {
                fill: Colors.White,
                fillOpacity: 0.6,
            },
        },
    },
    menuItemIcon: {
        minWidth: 40,
        width: 40,
    },
    collapse: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 5,
        paddingBottom: 5,
        '& li': {
            paddingLeft: 40,
            cursor: 'pointer',
        },
    },
}));

const SidebarSection: React.FC<SidebarItemProps> = ({
    title,
    items,
    active,
    sectionOpened,
    toggleSection,
    toggleSidebar,
    sectionIcon,
    isSidebarOpen,
    currentPath,
    className,
    dataQa,
    index,
}) => {
    const permission = usePermissionContext();
    const classes = useStyles();

    if (localStorage.getItem(menuLocalStoreKey)) {
        const newState = JSON.parse(
            localStorage.getItem(menuLocalStoreKey) || '',
        );
        if (newState.length) {
            newState[index] = sectionOpened;
            localStorage.setItem(menuLocalStoreKey, JSON.stringify(newState));
        }
    }

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <>
            <CustomizedLink
                to={items.length === 1 ? items[0].route : ''}
                disabled={items.length !== 1}
            >
                <ListItem
                    button
                    onClick={() => {
                        if (items.length === 1) {
                            history.push(items[0].route);
                        } else {
                            toggleSection?.();
                        }
                    }}
                    className={classNames(classes.tab, active, className)}
                    data-qa={dataQa}
                >
                    <ListItemIcon
                        onClick={toggleSidebar}
                        className={classes.menuItemIcon}
                    >
                        {!isSidebarOpen && active ? (
                            <Tooltip title={title}>{sectionIcon}</Tooltip>
                        ) : (
                            sectionIcon
                        )}
                    </ListItemIcon>
                    <ListItemText primary={title} />
                    {items.length >= 2 && (
                        <>{sectionOpened ? LessIcon : MoreIcon}</>
                    )}
                </ListItem>
            </CustomizedLink>
            {items.length >= 2 && (
                <Collapse
                    in={isSidebarOpen && sectionOpened}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapse}
                >
                    <List component="div" disablePadding>
                        {items.map((v, index) => (
                            <PermissionProvider
                                key={index}
                                permission={v.permission}
                            >
                                <Link to={v.route}>
                                    <SidebarItem
                                        active={currentPath.includes(v.route)}
                                        title={v.title}
                                    />
                                </Link>
                            </PermissionProvider>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default SidebarSection;

import {
    CPConditionTimeWindowInfo,
    TimeWindowIntervalsDetails,
} from '../../store/types/CallScreening';
import {
    WeekDayDescriptor,
    convertArrayOfNumbersToRangesString,
    daysApi,
    daysSelectLongItems,
    getRingScheduleSummary,
    monthApi,
    monthsFullSelectItems,
    years,
    yearsSelectItems,
} from './RingScheduleSummary';
import { Interval, SelectItem } from '../../store/types/RingGroup';
import {IntervalFormProps, IntervalStatus} from "../../components/IntervalSelect/IntervalSelect.utils";

function clean(value: string): string {
    return value.replace(/(\r\n|\n|\r)/gm, '').trimStart();
}

export const periodStringToArray = (
    periods?: CPConditionTimeWindowInfo,
): Array<TimeWindowIntervalsDetails> => {
    if (periods?.description?.slice(0, 4) === ' or ') {
        periods.description = periods.description.slice(4);
    }

    const desc = periods?.description
        ?.split(/(or\s|\r|\n)/)
        ?.map(clean)
        ?.filter(e => e?.length > 0 && e !== 'or ')
        || [];
    const intervals = periods?.period?.split(', ').map(clean) || [];

    return desc.map((o, index) => ({
        description: o,
        intervals: intervals[index],
    }));
};

export const timeWindowFromTimeWindowIntervals = (
    form: IntervalFormProps,
): CPConditionTimeWindowInfo => {
    if (form.activity === IntervalStatus.Always) {
        return {
            period: 'Always',
            description: 'Always',
        };
    }

    const timeWindow: CPConditionTimeWindowInfo = {
        period: '',
        description: '',
    };

    timeWindow.period = timeWindow.period?.slice(
        0,
        timeWindow.period?.length - 2,
    )?.trimEnd() || '';

    timeWindow.description = timeWindow.description?.slice(
        0,
        timeWindow.description?.length - 3,
    )?.trimEnd();

    let newPeriod = '';
    form.intervals.forEach((o) => {
        newPeriod += makePeriod(o) + ', ';
    });

    if (newPeriod.length) {
        if(timeWindow.period.length > 0) {
            timeWindow.period += ', ' + newPeriod.slice(0, newPeriod.length - 2);
        } else {
            timeWindow.period += newPeriod.slice(0, newPeriod.length - 2);
        }
        timeWindow.description += (timeWindow.description?.length ? ' or ' : '') +
            getRingScheduleSummary({
                activity: form.activity,
                intervals: form.intervals
            });
    }

    return timeWindow;
};

const makePeriodDetails = (interval: Interval): string => {
    let result = '';

    if (interval.days.length) {
        result += `wd{${convertArrayOfNumbersToRangesString(
            interval.days.map((day) => day.value),
            daysApi,
        ).replace(/,/g, '')}} `;
    }

    if (interval.daysOfMonth.length) {
        result += `md{${interval.daysOfMonth.replace(/,/g, ' ')}} `;
    }

    if (interval.months.length) {
        result += `mo{${convertArrayOfNumbersToRangesString(
            interval.months.map((month) => month.value),
            monthApi,
        ).replace(/,/g, '')}} `;
    }

    if (interval.years?.length) {
        result += `yr{${convertArrayOfNumbersToRangesString(
            interval.years.map((year) => parseInt(year.name)),
        ).replace(/,/g, '')}} `;
    }

    return result.slice(0, result.length - 1) || '';
};

export const makePeriodTime = (h1: number, m1: number, h2: number, m2: number) => {
    if(h2 < h1) {
        if(m1 === 0 && m2 === 0 && (h1 - h2) > 1) {
            return [
                `hr{${h1}-${h2-1}} `
            ];
        }
        return [
            `hr{${h1}}min{${m1}-59} `,
            `hr{${h1+1}-${h2-1}} `,
            m2 > 0 
                ? `hr{${h2}}min{0-${m2-1}} `
                : `hr{${h2}} `
        ];
    }
    else if (h1 === h2 && m1 === 0 && m2 === 0) {
        return [`hr{${h1}}min{0} `];
    } else if (h1 === h2 && (m1 !== 0 || m2 !== 0)) {
        if(m1 === m2) {
            return [`hr{${h1}}min{${m1}} `];
        }
        return [`hr{${h1}} min{${m1}-${m2 - 1}} `];
    } else if (h1 !== h2 && m1 === 0 && m2 === 0 && h2 - h1 === 1) {
        return [`hr{${h1}} `];
    } else if (h1 !== h2 && m1 === 0 && m2 === 0 && h2 - h1 !== 1) {
        return [`hr{${h1}-${h2 - 1}} `];
    } else if (h1 === h2 - 1 && m1 !== 0 && m2 === 0) {
        return [`hr{${h1}}min{${m1}-59} `];
    } else if (h1 === h2 - 1 && m1 === 0 && m2 !== 0) {
        return [`hr{${h1}}`, `hr{${h2}}min{0-${m2-1}} `];
    } else if (h1 !== h2 - 1 && m1 === 0 && m2 !== 0) {
        return [`hr{${h1}-${h2-1}}`, `hr{${h2}}min{0-${m2-1}} `];
    } else {
        const middleHours =
            h1 + 1 === h2 - 1 
                ? `${h1 + 1}`
                : `${h1 + 1}-${h2 - 1}`;
        return [
            `hr{${h1}}min{${m1}-59} `,
            `hr{${middleHours}} `,
            m2 > 0 ? 
            `hr{${h2}}min{0-${m2 - 1}} `
            : ''
        ];
    }
};

export const makePeriod = (interval: Interval): string => {
    const details = makePeriodDetails(interval);

    if (interval.wholeDay || (interval.startTime === '00:00' && interval.endTime === '23:59')) {
        return `hr{0}min{0-59} ${details},hr{1-22} ${details},hr{23}min{0-58} ${details}`;
    }

    const from = interval.startTime.split(':').map((o) => parseInt(o));
    const to = interval.endTime.split(':').map((o) => parseInt(o));
    const time = makePeriodTime(from[0], from[1], to[0], to[1]);

    return `${time.filter(v => v.length > 0).map((v) => v + details)}`.trimEnd();
};

const groupBy = function(list: string[][], keyGetter: (itm: string[]) => string, objectGetter: (itm: string[]) => string) {
    const map = new Map<string, string[]>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const hasKey = map.has(key);
        if(!hasKey) {
            const collection: string[] = [];
            collection.push(objectGetter(item));
            map.set(key, collection);
        } else {
            const collection = map.get(key);
            if(collection) {
                collection.push(objectGetter(item));
                map.set(key, collection);
            }
        }
    });
    return map;
};

const getPartsSelected = (value: string, partIdentifier: string, partList: WeekDayDescriptor[], 
        apiValues: string[], partIdentifierNotFound?: (value: string) => WeekDayDescriptor | undefined): SelectItem[] => {
    const result: SelectItem[] = [];

    const splitted = value.matchAll(/[^{}\s]+({.+?})?/g);
    if(!splitted)
        return result;

    for(const val of splitted) {
        const parts = val[0].split(/[{}]/);
        if(parts.length > 1 && parts[0] === partIdentifier) {
            const subParts = parts[1].split(' ').map(e => e.trim());
            for(const sp of subParts) {
                if(sp.includes('-')) {
                    const toEnd = sp.split('-').map(e => e.trim());
                    if(toEnd.length !== 2) {
                        console.error('Invalid part format! ' + value);
                        return result;
                    }
                    const indx1 = apiValues.indexOf(toEnd[0]);
                    const indx2 = apiValues.indexOf(toEnd[1]);
                    for(let indx = indx1; indx <= indx2; indx++) {
                        const dayObj = partList.find(e => e.value === indx);
                        if(!dayObj) {
                            console.error('getPartsSelected object not found! ' + value);
                            return result;
                        }
                        result.push(dayObj);
                    }
                } else {
                    const indx = apiValues.indexOf(sp);
                    let dayObj = partList.find(e => e.value === indx);
                    if(!dayObj) {
                        dayObj = partIdentifierNotFound?.(sp);
                        if(!dayObj) {
                            console.error('getPartsSelected object not found! ' + value);
                            return result;
                        }
                    }
                    result.push(dayObj);
                }
            }
        }
    }
    return result;
}

const getDaysOfMonth = (value: string): string => {
    if((value?.length ?? 0) === 0)
        return '';

    const splitted = value.matchAll(/[^{}\s]+({.+?})?/g);
    if(!splitted)
        return '';

    for(const m of splitted) {
        const parts = m[0].split(/[{}]/);
        if(parts.length > 1 && parts[0] === 'md') {
            const subParts = parts[1].split(' ').map(e => e.trim());
            return subParts.join(', ');
        }
    }
    return '';
}

const getTime = (value: string, last: boolean): string => {
    const splitted = value.split(/[{}]/).map(e => e.trim()).filter(e => e.length > 0);

    const getVal = (v: string, customLast?: boolean): number => {
        if(v.includes('-')) {
            const parts = v.split('-').map(e => e.trim());
            const cl = customLast ?? last;
            if(cl) 
                return parseInt(parts[parts.length - 1]);
            else 
                return parseInt(parts[0]);
        } else {
            return parseInt(v.trim());
        }
    };

    if(splitted.length === 2 && splitted[0] === 'hr') {
        let h = getVal(splitted[1]);
        if(last) {
            h++;
        }
        h = h % 24;
        return (h + '').padStart(2, '0') + ':00';
    } else if(splitted.length === 4 && splitted[0] === 'hr' && splitted[2] === 'min') {
        let h = getVal(splitted[1], last);
        let m = getVal(splitted[3], last);
        if(last && getVal(splitted[3], true) !== getVal(splitted[3], false)) {
            m = m + 1;
        }
        else if(last && getVal(splitted[3], false) === 0 && m !== 0) {
            m = m + 1;
        }
        if(m >= 60) {
            m = m % 60;
            h++;
            h = h % 24;
        }
        return (h + '').padStart(2, '0') + ':' + (m + '').padStart(2, '0');
    }

    return '00:00';
}

export const parsePeriodString = (period: string): Interval[] => {
    const result: Interval [] = [];
    if((period?.trim()?.length ?? 0) === 0) 
        return result;

    while(period.indexOf('  ') !== -1) {
        period = period.replace('  ', ' ');
    }
    const splitted = period.trim().split(',').map(e => e.trim()).filter(e => e.length > 0);
    const searchWholeDay = splitted.map(e => {
        let partsPreparation = ' ' + (e ?? '') + ' ';
        partsPreparation = partsPreparation
            .replaceAll('}', '} ')
            .replaceAll('  ', ' ')
            .trim();
        partsPreparation = partsPreparation
            .replace(' hr{', 'hr{')
            .replace(' min{', 'min{')
            .replace(' sec{', 'sec{');
        const parts = partsPreparation.split(' ').filter(c => c.length > 0);
        return [parts[0], parts.slice(1, parts.length).join(' ')];
    });

    const yearsValues = years();
    const yearNotFound = (year: string) => {
        const obj: WeekDayDescriptor = {
            name: year,
            value: parseInt(year),
            apiValue: year
        };
        return obj;
    };

    const groupedByRestOfString = groupBy(searchWholeDay, (itm) => itm[1], (itm) => itm[0]);
    for(const key of groupedByRestOfString.keys()) {
        let isWholeDay = false;
        let isPartialDay = false;
        let isReversedTime = false;
        const arr: string[] = (groupedByRestOfString.get(key) || []);
        //arr.sort();
        const notEmptryRows = arr.filter(e => e.length > 0).length;
        if(arr.length === 3) {
            isWholeDay = arr[0] === 'hr{0}min{0-59}'
                && arr[1] === 'hr{1-22}'
                && arr[2] === 'hr{23}min{0-58}';
            if(!isWholeDay) {
                const endTimeString = arr[2]; //getTime(arr[2], true);
                const startTimeString = arr[0]; //getTime(arr[0], false);
                //arr.sort((a, b) => getTime(a, true) > getTime(b, true) ? 1 : -1);
                const indxOfStartTimeInOrdered = arr.indexOf(startTimeString);
                const indxOfEndTimeInOrdered = arr.indexOf(endTimeString);
                if(indxOfStartTimeInOrdered > indxOfEndTimeInOrdered) {
                    //the time is reversed
                    isReversedTime = true;
                }
            }
            isPartialDay = !isWholeDay && arr.length > 0 && notEmptryRows > 0;
        } else if(arr.length === 2) {
            isWholeDay = (arr[0] === 'hr{0-22}' && arr[1] === 'hr{23}min{0-58}');
            isPartialDay = !isWholeDay && arr.length > 0 && notEmptryRows > 0;
        } else {
            isPartialDay = arr.length > 0 && notEmptryRows > 0;
        }
        if(isWholeDay || isPartialDay) {
            for(const rest of arr) {
                const full = rest + (key.length > 0 ? (' ' + key) : '');
                const indx = splitted.findIndex(e => e === full);
                splitted.splice(indx, 1);
            }
        }
        if(isWholeDay) {
            result.push({
                wholeDay: true,
                endTime: '23:59',
                startTime: '00:00',
                days: getPartsSelected (key, 'wd', daysSelectLongItems, daysApi),
                daysOfMonth: getDaysOfMonth(key),
                months: getPartsSelected (key, 'mo', monthsFullSelectItems, monthApi),
                years: getPartsSelected (key, 'yr', yearsSelectItems, yearsValues, yearNotFound),
            } as Interval);
        } else if(isPartialDay) {
            //arr.sort((a, b) => getTime(a, false) > getTime(b, false) ? 1 : -1);
            const startTimeToParse = arr[0]
                + (key.startsWith('min{')
                    ?  ' ' + key
                    : '');
            const endTimeToParse = arr[arr.length - 1] 
                + (key.startsWith('min{') 
                    ?  ' ' + key
                    : '');
            let startTimeParsed = getTime(startTimeToParse, false);
            let endTimeParsed = getTime(endTimeToParse, true);
            if(isReversedTime) {
                const r = startTimeParsed;
                startTimeParsed = endTimeParsed;
                endTimeParsed = r;
            }
            result.push({
                wholeDay: false,
                endTime: endTimeParsed,
                startTime: startTimeParsed,
                days: getPartsSelected (key, 'wd', daysSelectLongItems, daysApi),
                daysOfMonth: getDaysOfMonth(key),
                months: getPartsSelected (key, 'mo', monthsFullSelectItems, monthApi),
                years: getPartsSelected (key, 'yr', yearsSelectItems, yearsValues, yearNotFound),
            } as Interval);
        }
    }

    for(const key of splitted) {
        result.push({
            wholeDay: false,
            days: getPartsSelected (key, 'wd', daysSelectLongItems, daysApi),
            daysOfMonth: getDaysOfMonth(key),
            months: getPartsSelected (key, 'mo', monthsFullSelectItems, monthApi),
            years: getPartsSelected (key, 'yr', yearsSelectItems, yearsValues, yearNotFound),
        } as Interval);
    }
    
    return result;
};

export const isAlways = (intervals: Interval[]): boolean => {
    if(!intervals || intervals.length === 0)
        return true;

    const results = intervals.map(e => 
        e.wholeDay &&
        (e.daysOfMonth ?? '').length === 0 &&
        (e.days ?? []).length === 0 &&
        (e.months ?? []).length === 0 &&
        (e.years ?? []).length === 0);

    return !results.includes(false);
};
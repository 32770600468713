import {makeStyles} from '@material-ui/core/styles';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../../store/types';
import {Colors} from '../../../../styles/Colors';
import {TwoFaSettingProps} from '../../../TwoFaSetting/TwoFaSetting.utils';

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '0',
        maxWidth: 965,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20
    },

    itemsContainer2Fa: {
        paddingTop: 46,
        paddingLeft: 16,
        borderTop: '1px solid ' + Colors.Border
    },

    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 106,
    },

    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.Gray5,
        fontSize: 16,
        '& ul': {
            padding: '0 0 0 24px',
            margin: 0,
            lineHeight: '30px',
        },
    },

    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        padding: '22px 16px 2px 16px',
        '& .MuiFormControl-root': {
            marginBottom: 32,
        },

        '& .MuiInputBase-root': {
            width: 440,
            marginTop: 0,
        },
    },
    header: {
        fontWeight: 500,
        fontSize: 32,
        marginTop: 58,
        color: Colors.Gray7,
    },
    resetButton: {
        '& .MuiButton-label': {
            color: `${Colors.Primary} !important`,
        },
        marginLeft: 4,
    },
}));

export const props2FA = () => {
    const {customer} = useSelector((state: ReduxState) => state.myProfile);
    const i_customer = useSelector((state: ReduxState) => state.generic.sessionData?.i_customer);

    return useMemo(() => {
        if ((customer?.i_individual || 0) > 0) {
            return {
                object: 'individual',
                i_object: customer?.i_individual || -1,
                isMyOwn: true
            } as TwoFaSettingProps;
        } else {
            return {
                object: 'customer',
                i_object: customer?.i_customer || i_customer,
                isMyOwn: true
            } as TwoFaSettingProps;
        }
    }, [customer, i_customer]);
}

export const changePasswordValidationReqFiledsName: string[] = [
    'oldPassword',
    'newPassword',
    'confirmPassword'
];
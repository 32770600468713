import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import {
    DIDAreaInfo,
    DIDCountryInfo,
    DIDNumberListInfo,
} from '../../store/types/DIDProvider';
import { ExtensionType } from '../../store/types/Extension';

export const useStyles = makeStyles(() => ({
    error: {
        color: Colors.Error,
        fontSize: 12,
        fontWeight: 400,

        '& svg': {
            display: 'none',
        },
    },
    itemsContainer: {
        padding: '42px 60px 8px 60px',
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 440,
            marginBottom: 24,
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 486,
            width: 'unset',
            padding: 0,
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 560,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },
    primaryActionButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: Colors.White,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },

    twoItems: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    assignAccountSelect: {
        '& .MuiFormControl-root': {
            backgroundColor: 'white',
            marginTop: 18,
        },
    },
    checkBox: {
        flexDirection: 'row-reverse',
    },

    boxRow: {
        display: 'flex',
        alignItems: 'flex-start',
        width: 440,
        '& .Mui-error': {
            fontWeight: 400,
        },
    },
    paddingTop: {
        paddingTop: 0,
    },
    border: {
        borderBottom: `1px solid ${Colors.Border}`,
    },
    paddingBottom: {
        paddingBottom: 8,
    },
    padding8Top: {
        paddingTop: 8,
    },
    assignedTo: {
        marginTop: 24,
        width: '100%',
    },
    pricing: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& b': {
            fontWeight: 500,
            textTransform: 'uppercase',
        },
    },
    didNumberToAdd: {
        lineHeight: '18.75px',
        marginBottom: 24,
    },
    numberSelect: {
        marginBottom: 24,
    },
}));

export const didProviderValidationSchema = Yup.object().shape({
    country: Yup.object()
        .nullable()
        .required(i18n.t<string>('screens:didNumbers.didCountryNotSelected')),
    area: Yup.object()
        .nullable()
        .required(i18n.t<string>('screens:didNumbers.didAreaNotSelected')),
    number: Yup.object().when('is_numbers_list_available', {
        is: 1,
        then: Yup.object()
            .nullable()
            .required(
                i18n.t<string>('screens:didNumbers.didNumberNotSelected'),
            ),
        otherwise: Yup.object().nullable().notRequired(),
    }),
});

export type InventoryFormProps = {
    number: DIDNumberListInfo | null;
    country: DIDCountryInfo | null;
    area: DIDAreaInfo | null;
    is_numbers_list_available: number;
    assignedTo?: ExtensionType | null;
};

export type DIDInventoryDialogProps = {
    isOpen: boolean;
    clickOnClose?: () => void;
};

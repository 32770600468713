import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import CustomizedTooltip from '../Tooltip/Tooltip';

interface PolicyComponentInterface {
    name?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
    },

    policy: {
        fontWeight: 400,
        lineHeight: '12px',
        fontSize: 10,
        color: Colors.Secondary1,
        border: `1px solid ${Colors.Secondary1}`,
        padding: '5px 8px 4px 8px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        marginRight: 8,
        display: 'inline-block',
    },
}));

const PolicyComponent: React.VFC<PolicyComponentInterface> = ({ name }) => {
    if (!name) {
        return null;
    }

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <CustomizedTooltip
                copy={false}
                interactive={false}
                title={t('tooltips:callScreening.policy')}
                dataTestId={'policy-tooltip'}
                dataQa={'policy-tooltip'}
            >
                <span className={classes.policy}>
                    {t('screens:extensions.policy')}
                </span>
            </CustomizedTooltip>

            <OverflowTooltip
                classes={{ text: classes.root }}
                dataQa="call-screening-policy-details"
                text={name}
                tooltip={t('tooltips:callScreening.policyDetails', {
                    details: name,
                })}
                copy={false}
            />
        </>
    );
};

export default PolicyComponent;

import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SignInRootBackground,
        height: '100vh',
        maxWidth: '100vw'
    },

    wrapper: {
        height: 515,
        width: 515,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.White,
        border: `1px solid ${Colors.Border}`,
        borderRadius: 4,
        alignItems: 'center',

        '& .MuiGrid-root.MuiGrid-item ': {
            marginBottom: 20,
        },
    },

    credentials: {
        width: 372,
        display: 'flex',
        flexDirection: 'column',

        '& form': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            flexGrow: 1,
        },
    },
}));

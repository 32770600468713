import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as PageConstruction } from '../../assets/page-construction.svg';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center'
    }
}));

const PageUnderConstruction: React.VFC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PageConstruction data-testid={'page-construction'} width="546px" height="474px" />
        </div>
    );
};

export default PageUnderConstruction;
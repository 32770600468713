import MaskedInput from 'react-text-mask';
import React from 'react';

const MacMask = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
                ':',
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
                ':',
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
                ':',
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
                ':',
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
                ':',
                /[A-Fa-f0-9]/,
                /[A-Fa-f0-9]/,
            ]}
            placeholderChar={'x'}
            placeholder={'xx:xx:xx:xx:xx:xx'}
        />
    );
};

export default MacMask;

import SelectField from '../../SelectField/SelectField';
import { CustomerAgreementCondition } from '../../../store/types/CustomerAgreementCondition';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { APIErrorInterface, ReduxState } from '../../../store/types';
import { actions } from '../../../store';
import { filterProductsByType, MainProductType, useStyles } from './utils';

type MainProductSelectFieldProps = {
    fetchData: boolean;
    mainProductId?: number;
    setFieldError: (field: string, value: string | undefined) => void;
    errorMsg?: string;
    onChange?: (
        event: React.ChangeEvent<{}> | undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newValue: any,
    ) => void;
    required?: boolean;
    apiErrors?: APIErrorInterface;
    dataQa?: string;
    i_account?: number;
    productType?: MainProductType;
    customApiErrorMessage?: string;
};

const MainProductSelectField: React.VFC<MainProductSelectFieldProps> = ({
    fetchData,
    mainProductId,
    setFieldError,
    errorMsg,
    onChange,
    apiErrors,
    required = true,
    dataQa = 'create-extension-main-product-select',
    i_account,
    productType,
    customApiErrorMessage,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const products = useSelector<
        ReduxState,
        | {
              mainProducts: CustomerAgreementCondition[];
              addonsProducts: CustomerAgreementCondition[];
          }
        | undefined
    >((state) => state.extensions.products);

    useEffect(() => {
        if (fetchData) {
            dispatch(actions.createExtensionDetailsData.request({ i_account }));
        }
    }, [fetchData]);

    useEffect(() => {
        if (
            apiErrors?.faultcode?.includes(
                'Server.Customer.Maximum offered quantity exceeded',
            )
        ) {
            const product = products?.mainProducts.find(
                (v) => v.i_product === mainProductId,
            );

            setFieldError(
                'mainProductId',
                customApiErrorMessage ??
                    t('errors:extensions.maximumProvider', {
                        count: product?.max_offered_quantity,
                    }),
            );
        }
    }, [apiErrors]);

    useEffect(() => {
        const availableProducts = filterProductsByType(
            productType,
            products?.mainProducts,
        ).filter(
            (v) =>
                !(
                    v.max_offered_quantity <= v.used_quantity &&
                    !!v.max_offered_quantity
                ),
        );

        if (availableProducts?.length === 1 && !mainProductId) {
            onChange?.(undefined, availableProducts[0]);
        }
    }, [productType, products?.mainProducts, mainProductId]);

    const renderOption = useCallback(
        (option: CustomerAgreementCondition) => (
            <div className={classes.customOptionContainer}>
                <span>{option.product_name}</span>
                <span className={classes.customOptionSemiValue}>
                    {option.max_offered_quantity
                        ? `${option.used_quantity}/${option.max_offered_quantity}`
                        : t('common:noLimit')}
                </span>
            </div>
        ),
        [],
    );

    const listProducts = filterProductsByType(
        productType,
        products?.mainProducts,
        mainProductId,
    );

    return (
        <>
            <SelectField
                id="mainProductId"
                label={t('screens:extensions.mainProduct')}
                items={listProducts || []}
                value={
                    listProducts?.find((v) => v.i_product === mainProductId) ||
                    null
                }
                dataQa={dataQa}
                onChange={onChange}
                getOptionLabel={(v: { product_name: string }) => v.product_name}
                getOptionSelected={(v: { i_product: number }) =>
                    v.i_product === mainProductId
                }
                disableClearable
                required={required}
                renderOption={renderOption}
                getOptionDisabled={(option: CustomerAgreementCondition) =>
                    option.max_offered_quantity <= option.used_quantity &&
                    !!option.max_offered_quantity
                }
                helperText={errorMsg}
                setFieldError={setFieldError}
                disableAutoSettingValue
            />
        </>
    );
};

export default MainProductSelectField;

import { ActionType, createReducer } from 'typesafe-actions';
import dayjs from '../../../services/customDayJs';
import * as actions from '../../actions';
import { APIErrorInterface } from '../../types';
import { ExtensionType } from '../../types/Extension';
import { BaseWallboardWidget, RefreshWidgetQueueItem, RefreshWidgetTime, WidgetCallHistory } from '../../types/Wallboard';
import { RingGroupType } from '../../types/RingGroup';
import { SipCall } from '../../actions/ringgroups/payloads';

export interface WallboardStateType {
    widgets?: BaseWallboardWidget[];
    isLoadingData?: boolean;
    updateWidgetsError?: APIErrorInterface;
    refreshTime?: string;

    refreshWidgetsQueue: RefreshWidgetQueueItem[];
    refreshWidgetsTime: RefreshWidgetTime[];

    callHistoryItems: WidgetCallHistory[];
    historyCallsLoading: boolean;
    
    extensionsList: ExtensionType[];
    isLoadingExtensions: boolean;

    ringGroupsList: RingGroupType[];
    isLoadingRingGroups: boolean;

    sipCallsList: SipCall[];
    isLoadingSipCalls: boolean;
}

export const initialState: WallboardStateType = {
    refreshWidgetsQueue: [],
    refreshWidgetsTime: [],
    callHistoryItems: [],
    historyCallsLoading: false,
    extensionsList: [],
    isLoadingExtensions: false,
    ringGroupsList: [],
    isLoadingRingGroups: false,
    sipCallsList: [],
    isLoadingSipCalls: false
};

export type wallboardActionsType = ActionType<typeof actions>;

const wallboardReducer = createReducer<
    WallboardStateType,
    wallboardActionsType
>(initialState)
    .handleAction(actions.getWallboardData.request, (state) => ({
        ...state,
        isLoadingData: true
    }))
    .handleAction(actions.getWallboardData.success, (state, action) => ({
        ...state,
        isLoadingData: false,
        widgets: action.payload,
        refreshTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }))
    .handleAction(actions.getWallboardData.failure, (state) => ({
        ...state,
        isLoadingData: false,
        refreshTime: undefined
    }))
    .handleAction(actions.updateWallboards.request, (state) => ({
        ...state,
        updateWidgetsError: undefined
    }))
    .handleAction(actions.updateWallboards.success, (state, action) => ({
        ...state,
        updateWidgetsError: undefined,
        widgets: action.payload
    }))
    .handleAction(actions.updateWallboards.failure, (state, action) => ({
        ...state,
        updateWidgetsError: action.payload
    }))
    .handleAction(actions.refreshWallboardTrigger.success, (state, action) => ({
        ...state,
        refreshWidgetsQueue: [...action.payload.refreshWidgetsQueue],
    }))
    .handleAction(actions.refreshWallboardTrigger.failure, (state) => ({
        ...state,
    }))
    .handleAction(actions.refreshWidgetCompleted.success, (state, action) => ({
        ...state,
        refreshWidgetsQueue: [...action.payload.refreshWidgetsQueue],
        refreshWidgetsTime: [...(action.payload.refreshWidgetsTime || state.refreshWidgetsTime)],
    }))
    .handleAction(actions.refreshWidgetCompleted.failure, (state) => ({
        ...state,
    }))
    .handleAction(actions.getCallHistoryDataForWidget.request, (state) => ({
        ...state,
        historyCallsLoading: true
    }))
    .handleAction(actions.getCallHistoryDataForWidget.success, (state, action) => ({
        ...state,
        historyCallsLoading: false, 
        callHistoryItems: action.payload,
    }))
    .handleAction(actions.getCallHistoryDataForWidget.failure, (state) => ({
        ...state,
        historyCallsLoading: false, 
    }))
    .handleAction(actions.getExtensionsListForWidgets.request, (state) => ({
        ...state,
        isLoadingExtensions: true
    }))
    .handleAction(actions.getExtensionsListForWidgets.success, (state, action) => ({
        ...state,
        extensionsList: action.payload,
        isLoadingExtensions: false
    }))
    .handleAction(actions.getExtensionsListForWidgets.failure, (state) => ({
        ...state,
        isLoadingExtensions: false
    }))
    .handleAction(actions.getRingGroupsListForWidgets.request, (state) => ({
        ...state,
        isLoadingRingGroups: true
    }))
    .handleAction(actions.getRingGroupsListForWidgets.success, (state, action) => ({
        ...state,
        ringGroupsList: action.payload,
        isLoadingRingGroups: false
    }))
    .handleAction(actions.getRingGroupsListForWidgets.failure, (state) => ({
        ...state,
        isLoadingRingGroups: false
    }))
    .handleAction(actions.getSipCallsListForWidgets.request, (state) => ({
        ...state,
        isLoadingSipCalls: true
    }))
    .handleAction(actions.getSipCallsListForWidgets.success, (state, action) => ({
        ...state,
        sipCallsList: action.payload,
        isLoadingSipCalls: false
    }))
    .handleAction(actions.getSipCallsListForWidgets.failure, (state) => ({
        ...state,
        isLoadingSipCalls: false
    }))
    .handleAction(actions.validatesAndPostDataToQueue.success, (state, action) => ({
        ...state,
        refreshWidgetsQueue: [...action.payload]
    }))
;

export default wallboardReducer;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';
import { Permission, PermissionType } from '../../store/types/Permission';
import { calculateFreeSpace, defaultColumnsAmount, useStyles } from './Wallboard.utils';
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";
import { BaseWallboardWidget, WidgetDataType, WidgetPresantationType } from '../../store/types/Wallboard';
import {useDispatch, useSelector} from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types';
import classNames from 'classnames';
import { ReactComponent as ResizeIcon } from '../../assets/resizer.svg';
import ExternalUrlWidget from './Widgets/ExternalUrlWidget';
import ExtensionPresenseWidget from './Widgets/ExtensionPresenseWidget';
import CustomizedButton from '../../components/Button/Button';
import { Add, Refresh, Delete, MoreHoriz, Settings, Launch, Brightness4, Close } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import { IconButton } from '@material-ui/core';
import AddNewWidgetDialog from './AddNewWidgetDialog';
import { AddNewWidgetDialogProps } from './AddNewWidgetDialog.utils';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {v4 as id} from 'uuid';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { DialogButton } from '../../components/AlertDialog/DialogContainer';
import { ReactComponent as DougnutWidgetType } from '../../assets/dougnut_widget_type.svg';
import { ReactComponent as ListWidgetType } from '../../assets/list_widget_type.svg';
import { ReactComponent as IFrameWidgetType } from '../../assets/iframeicon.svg';
import { usePermissions } from '../../hooks/usePermissions';
import usePageTitle from "../../hooks/usePageTitle";
import CallVolumesWidget from './Widgets/CallVolumesWidget';
import CustomizedTooltip from '../../components/Tooltip/Tooltip';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const AutoRefreshTime = 5;

const Wallboard = () => {
    usePageTitle();
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handle = useFullScreenHandle();
    
    const permission = usePermissions(...Permission.CloudPBX.Wallboard.value);
    
    const {widgets, isLoadingData, refreshWidgetsQueue} = useSelector((state: ReduxState) => state.wallboard);

    const [layouts, setLayouts] = useState<Layouts|undefined>(undefined);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<{
        isOpen: boolean,
        widget?: BaseWallboardWidget
    }>({
        isOpen: false,
        widget: undefined
    });
    
    const [showAddNewDialog, setShowAddNewDialog] = useState<AddNewWidgetDialogProps>({
        isOpen: false
    });

    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState<boolean>(false);

    useEffect(() => {
        dispatch(actions.getWallboardData.request());
        dispatch(actions.getExtensionsListForWidgets.request());
        dispatch(actions.getRingGroupsListForWidgets.request());
    }, []);

    function refresh() {
        dispatch(actions.refreshWallboardTrigger.request({
            refreshImmidiately: false,
            id: undefined
        }));
        setTimeout(refresh, AutoRefreshTime * 1000);
    }

    useMemo(() => {
        setTimeout(() => {
            refresh();
        }, AutoRefreshTime * 1000);
    }, []);

    const isRefreshing = useMemo(() => {
        return isLoadingData || (refreshWidgetsQueue.length > 0);
    }, [isLoadingData, refreshWidgetsQueue]);
    
    const isReadOnly = useMemo(() => {
        return permission === PermissionType.ReadOnly;
    }, [permission]);

    const handleModify = useCallback((currentLayout: Layout[], allLayouts: Layouts) => {
        const isNotInitialLoad = !!layouts;
        setLayouts(allLayouts);

        if(isNotInitialLoad) {
            const tempArray = widgets || [];
            let anyChanges = false;
            let changedIndex = -1;

            if(currentLayout) {
                for(const itm of currentLayout) {

                    const widgetId = itm.i.replace('widget_', '');
                    const index = tempArray.findIndex(e => e.id === widgetId); //parseInt(itm.i);
                    if(index === -1) continue;

                    if(tempArray[index].layout.x !== itm.x) {
                        tempArray[index].layout.x = itm.x;
                        anyChanges = true;
                    }
                    if(tempArray[index].layout.y !== itm.y) {
                        tempArray[index].layout.y = itm.y;
                        anyChanges = true;
                    }
                    if(tempArray[index].layout.w !== itm.w) {
                        tempArray[index].layout.w = itm.w;
                        anyChanges = true;
                    }
                    if(tempArray[index].layout.h !== itm.h) {
                        tempArray[index].layout.h = itm.h;
                        anyChanges = true;
                    }

                    if(anyChanges) {
                        changedIndex = index;
                    }
                }
            }

            if(anyChanges) {
                const id = "widget_" + changedIndex;
                const widgetDiv = document.getElementById(id);
                if(!!widgetDiv) {
                    applyOnHover(widgetDiv, true);
                }

                dispatch(actions.updateWallboards.request({data: tempArray}));
            }
        }
    }, [widgets, layouts]);

    const handleDelete = (widget: BaseWallboardWidget) => {
        const tempArray = (widgets || []);
        const index = tempArray.indexOf(widget);
        tempArray.splice(index, 1);
        dispatch(actions.refreshWidgetCompleted.request(widget.id));
        dispatch(actions.updateWallboards.request({
            data: tempArray
        }));
    };

    const handleEdit = (widget: BaseWallboardWidget) => {
        setShowAddNewDialog({
            isOpen: true,
            editObject: widget,
            onClose: (success, object) => {
                if(success && !!object) {
                    const tempArray = (widgets || []);
                    const indx = tempArray.indexOf(widget);
                    tempArray.splice(indx, 1);
                    tempArray.push(object);
                    dispatch(actions.updateWallboards.request({
                        data: tempArray || [],
                        onSuccess: () => {
                            dispatch(actions.refreshWallboardTrigger.request({
                                id: object.id,
                                refreshImmidiately: true
                            }));
                        }
                    }));
                }               
                setShowAddNewDialog({
                    isOpen: false,
                    editObject: undefined
                });
            },
            onDelete: (widget) => {               
                setShowAddNewDialog({
                    isOpen: false,
                    editObject: undefined
                });
                setOpenDeleteDialog({
                    isOpen: true,
                    widget: widget
                });
            }
        });
    };

    const handleAddNewWidget = useCallback(() => {
        setShowAddNewDialog({
            isOpen: true,
            onClose: (success, object) => {
                if(success && !!object) {
                    const tempArray = (widgets || []);
                    const busySpaces = widgets?.map(e => e.layout) || [];
                    const freeSpace = calculateFreeSpace(object.layout, busySpaces);
                    if(freeSpace) {
                        object.layout = {
                            ...object.layout,
                            ...freeSpace
                        };
                    }
                    object.id = id();
                    tempArray.push(object);
                    dispatch(actions.updateWallboards.request({
                        data: tempArray || [],
                        onSuccess: () => {
                            dispatch(actions.refreshWallboardTrigger.request({
                                id: object.id,
                                refreshImmidiately: true
                            }));
                        }
                    }));
                }               
                setShowAddNewDialog({
                    isOpen: false,
                    editObject: undefined
                });
            }
        });
    }, [widgets]);
    
    const handleRefresh = useCallback(() => {
        dispatch(actions.refreshWallboardTrigger.request({
            refreshImmidiately: true,
            id: undefined
        }));
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const applyOnHover = (div: any, hover: boolean) => {
        
        let rootWidgetElem = div;
        while(!!rootWidgetElem 
                && (!rootWidgetElem.classList || !rootWidgetElem.classList.contains(classes.reactGridItem))
                && rootWidgetElem !== window) {
            rootWidgetElem = rootWidgetElem.parentNode;
        }

        if(!rootWidgetElem || rootWidgetElem === window)
            return;
        
        if(hover && !rootWidgetElem.classList.contains(classes.selectedWidget)) {
            rootWidgetElem.classList.add(classes.selectedWidget);
        }
        else if(!hover && rootWidgetElem.classList.contains(classes.selectedWidget)) {
            rootWidgetElem.classList.remove(classes.selectedWidget);
        }

        const resizers = rootWidgetElem.getElementsByClassName(classes.resizeHandle);
        if(resizers && resizers.length) {
            const resizer = resizers[0];
            if(hover && !resizer.classList.contains(classes.selectedResizer)) {
                resizer.classList.add(classes.selectedResizer);
            }
            else if(!hover && resizer.classList.contains(classes.selectedResizer)) {
                resizer.classList.remove(classes.selectedResizer);
            }
        }
    };

    const handleFullScreen = useCallback(() => {
        if(!fullScreenMode) {
            handle.enter();
        }
        setFullScreenMode(!fullScreenMode);
    }, [fullScreenMode]);

    const onStatusChange = useCallback((state: boolean) => {
        if(!state && fullScreenMode) {
            setFullScreenMode(false);
        }
        handleRefresh();
    }, [fullScreenMode]);

    const handleExitDarkMode = useCallback(() => {
        handle.exit();
    }, [darkMode]);

    const handleDarkMode = useCallback(() => {
        setDarkMode(!darkMode);
    }, [darkMode]); 

    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.Wallboard.value}>
            <FullScreen handle={handle} className={classes.container} onChange={onStatusChange}>
                <div className={classes.scrollableContainer}>
                    <div className={classes.containerPadding}>
                        {fullScreenMode ? (
                            <div className={classes.headerContainerFullScreen}>
                                <div>
                                    {/*<IconButton
                                        data-qa="dark-mode-button"
                                        data-testid="dark-mode-button"
                                        size="small"
                                        onClick={handleDarkMode}
                                    >
                                        <Brightness4 htmlColor={Colors.Secondary1} />
                                    </IconButton>*/}
                                </div>
                                <div className={classes.darkModeHeader}>
                                    {t('screens:wallboard.wallboardHeaderInFullScreenMode')}
                                </div>
                                <div className={classes.closeButtonContainer} onClick={handleExitDarkMode}
                                        data-testid={'exit-full-screen-mode-button'}>
                                    <Close htmlColor={Colors.Secondary1} />
                                    {t('common:close')}
                                </div>
                            </div>
                        ) : (
                            <div className={classes.headerContainer}>
                                <div className={classes.headerContainerLeftPart}>
                                    <span className={classes.mainHeader}>
                                        {t('screens:wallboard.wallboardHeader')}
                                    </span>
                                    <PermissionProvider
                                        permission={
                                            Permission.CloudPBX.Wallboard.AddWidget.value
                                        }
                                    >
                                        <CustomizedButton
                                            dataQa={'add-wallboard-button-id'}
                                            primary
                                            type="submit"
                                            onClick={handleAddNewWidget}
                                            className={classes.addWidgetButton}
                                        >
                                            <Add
                                                className={classes.plusIcon}
                                                htmlColor={Colors.White}
                                                style={{ height: 22, width: 22, marginRight: 6, marginLeft: -3 }}
                                            />
                                            {t('screens:wallboard.addWidgetButton')}
                                        </CustomizedButton>
                                    </PermissionProvider>
                                    <IconButton
                                        data-qa="refresh-button"
                                        data-testid="refresh-button"
                                        size="small"
                                        onClick={handleRefresh}
                                        className={classes.refreshButton}
                                        disabled={isRefreshing}
                                    >
                                        <Refresh htmlColor={Colors.Gray5} />
                                    </IconButton>
                                    <div className={classes.refreshTimeContainer}>
                                        {isRefreshing && (
                                            <span className={classes.noWrap}>{t('screens:wallboard.refreshing')}</span>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fullscreenButtonContainer}>
                                    <CustomizedTooltip
                                        title={t('screens:wallboard.fullScreen')}
                                        copy={false}
                                        disableHoverListener={fullScreenMode}
                                    >
                                        <IconButton
                                            data-qa="full-screen-button"
                                            data-testid="full-screen-button"
                                            size="small"
                                            onClick={handleFullScreen}
                                            className={classes.fullscreenButton}
                                        >
                                            <Launch htmlColor={Colors.Gray5} />
                                        </IconButton>
                                    </CustomizedTooltip>
                                </div>
                            </div>
                        )}
                        <div className={classNames(classes.doughnutsContainer, fullScreenMode && classes.doughnutsContainerInFullScreenMode)}>
                            <ResponsiveReactGridLayout
                                onLayoutChange={handleModify}
                                verticalCompact={true}
                                //@ts-ignore
                                layout={layouts}
                                breakpoints={{
                                    lg: 1200,
                                    md: 996,
                                    sm: 768,
                                    xs: 480,
                                    xxs: 0
                                }}
                                preventCollision={false}
                                cols={{ 
                                    lg: defaultColumnsAmount, 
                                    md: defaultColumnsAmount, 
                                    sm: defaultColumnsAmount, 
                                    xs: defaultColumnsAmount, 
                                    xxs: defaultColumnsAmount
                                }}
                                autoSize={true}
                                rowHeight={143}
                                margin={{
                                    lg: [16, 16],
                                    md: [16, 16],
                                    sm: [16, 16],
                                    xs: [16, 16],
                                    xxs: [16, 16],
                                }}
                                resizeHandle={!isReadOnly && (
                                    <div className={classNames(classes.resizeHandle, 'react-resizable-handle', 'react-resizable-handle-se')}>
                                        <ResizeIcon />
                                    </div>
                                )}
                            >
                                {widgets?.map(widget => {
                                    return (
                                        <div
                                            className={classes.reactGridItem}
                                            key={"widget_" + widget.id}
                                            id={"widget_" + widget.id}
                                            data-grid={{
                                                ...widget?.layout,
                                                title: widget?.title,
                                                minW: 1,
                                                maxW: Infinity,
                                                minH: 1,
                                                maxH: Infinity,
                                                isDraggable: !isReadOnly,
                                                isResizable: !isReadOnly,
                                            }}
                                            onMouseEnter={(event) => {
                                                if(isReadOnly) return;
                                                applyOnHover(event.target, true);
                                            }}
                                            onMouseLeave={(event) => {
                                                if(isReadOnly) return;
                                                applyOnHover(event.target, false);
                                            }}
                                        >
                                            {!isReadOnly && !fullScreenMode && (
                                                <IconButton
                                                    data-qa="delete-widget-button"
                                                    data-testid="delete-widget-button"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenDeleteDialog({
                                                            isOpen: true,
                                                            widget: widget
                                                        });
                                                    }}
                                                    className={classes.deleteButton}
                                                >
                                                    <Delete htmlColor={Colors.Gray5} />
                                                </IconButton>
                                            )}
                                            
                                            {!isReadOnly && !fullScreenMode && (
                                                <IconButton
                                                    data-qa="settings-widget-button"
                                                    data-testid="settings-widget-button"
                                                    size="small"
                                                    onClick={() => handleEdit(widget)}
                                                    className={classes.editButton}
                                                >
                                                    <Settings htmlColor={Colors.Gray5} />
                                                </IconButton>
                                            )}
                                            
                                            {!isReadOnly && (
                                                <IconButton
                                                    data-qa="move-button"
                                                    data-testid="move-button"
                                                    size="small"
                                                    className={classes.moveButton}
                                                >
                                                    <MoreHoriz htmlColor={Colors.Gray5} />
                                                </IconButton>
                                            )}

                                            <div className={classes.widgetTypeIcon}>
                                                {widget.dataType !== WidgetDataType.externalIframe && widget.presentation === WidgetPresantationType.doughnut && (
                                                    <DougnutWidgetType />
                                                )}
                                                {widget.dataType !== WidgetDataType.externalIframe && widget.presentation === WidgetPresantationType.list && (
                                                    <ListWidgetType />
                                                )}
                                                {widget.dataType === WidgetDataType.externalIframe && (
                                                    <IFrameWidgetType />
                                                )}
                                            </div>
                                            <div className={classes.widgetTitle}>{widget.title}</div>
                                            <div className={classNames(classes.widgetFrame, 
                                                widget.dataType === WidgetDataType.extensionPresense
                                                    && widget.presentation === WidgetPresantationType.list
                                                    && classes.fullWidthContainer,
                                                widget.dataType === WidgetDataType.callVolumes 
                                                    && widget.presentation === WidgetPresantationType.list
                                                    && classes.emptyRightMarginContainer
                                                )}>
                                                {widget.dataType === WidgetDataType.externalIframe && (
                                                    <ExternalUrlWidget 
                                                        data={widget.data}
                                                        id={widget.id}
                                                    />
                                                )}
                                                {widget.dataType === WidgetDataType.extensionPresense && (
                                                    <ExtensionPresenseWidget 
                                                        data={widget.data}
                                                        id={widget.id}
                                                        presentation={widget.presentation}
                                                    />
                                                )}
                                                {widget.dataType === WidgetDataType.callVolumes && (
                                                    <CallVolumesWidget 
                                                        data={widget.data}
                                                        id={widget.id}
                                                        presentation={widget.presentation}
                                                        disableCustomRange={fullScreenMode}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </ResponsiveReactGridLayout>
                        </div>
                    </div>
                </div>

                <AddNewWidgetDialog {...showAddNewDialog} />
                
                <AlertDialog
                    isOpen={openDeleteDialog.isOpen}
                    hideHeader={true}
                    description={t('screens:wallboard.deleteConfirmation', {name: openDeleteDialog.widget?.title})}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() => setOpenDeleteDialog({
                                isOpen: false,
                                widget: undefined
                            })}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:remove')}
                            onClick={() => {
                                if(openDeleteDialog.widget) {
                                    handleDelete(openDeleteDialog.widget);
                                }
                                setOpenDeleteDialog({
                                    isOpen: false,
                                    widget: undefined
                                });
                            }}
                        />,
                    ]}
                    className={classes.deleteAlert}
                />
            </FullScreen>
        </PermissionPlaceholder>);
};

export default Wallboard;

import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import Loader from '../../../components/Loader/Loader';
import {Formik} from 'formik';
import {TabInterface, useTabStyles} from '../../../components/Tabs/Tabs.utils';
import {
    generateSipTrunkFormValidationSchema,
    SipTrunkFormType
} from "../../../components/Forms/SipTrunks/SipTrunk/SipTrunkForm.utils";
import SipTrunkForm from "../../../components/Forms/SipTrunks/SipTrunk/SipTrunkForm";
import {useSipTrunkFormTab} from "../../../hooks/useSipTrunkFormTab";

const SipTrunkTab: React.VFC<TabInterface & { prefixEnabled?: boolean }> = (
    {
        handleSubmitForm,
        handleInitDataChange,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
        prefixEnabled,
        callback

    }) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.sipTrunks?.sipTrunkDetails?.sipTrunkTab?.isLoading ||
            false,
    );

    const {data, initialValues} = useSipTrunkFormTab();

    useEffect(() => {
        if (!isDataLoading && initialValues) {
            handleInitDataChange?.(initialValues);
            handleSubmitForm?.(initialValues);
        }
    }, [isDataLoading]);

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="sip-details-loader"/>
        </div>
    ) : (
        <Formik<SipTrunkFormType>
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={(form) => handleSubmitForm?.(form)}
            validationSchema={generateSipTrunkFormValidationSchema(
                data.passwordLength,
            )}
        >

            {() => {
                return (
                    <>
                        <SipTrunkForm
                            didNumbers={data.didNumbers}
                            accountId={data.accountId}
                            timeZones={data.timeZonesList}
                            countries={data.countriesList}
                            webConfig={data.passwordConfig}
                            subdivisionsList={data.subdivisionsList}
                            apiErrors={data.apiErrors}
                            handleSubmitForm={handleSubmitForm}
                            handleDirtyChange={handleDirtyChange}
                            handleSetSubmitFunc={handleSetSubmitFunc}
                            handleSetIsValidFunc={handleSetIsValidFunc}
                            prefixEnabled={prefixEnabled}
                            callback={callback}
                        />
                    </>
                )
            }
            }

        </Formik>
    );
};

export default SipTrunkTab;

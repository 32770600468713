import React from 'react';
import { Route } from 'react-router-dom';

import Sidebar from '../components/Sidebar/Sidebar';

type BaseRouteProps = {
    path?: string | string[];
    component?: any;
    exact?: boolean;
    render?: any;
};

const ProtectedRoute: React.FC<BaseRouteProps> = ({
    component: Component,
    ...props
}) => {
    return (
        <Route
            {...props}
            render={(renderProps) => (
                <>
                    <div style={{ display: 'flex' }}>
                        <Sidebar />
                        <Component {...renderProps} />
                    </div>
                </>
            )}
        />
    );
};

export default ProtectedRoute;

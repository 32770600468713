import React from 'react';
// import parsePhoneNumber from 'libphonenumber-js';
import TwoLineCell from './TwoLineCell';

interface CallerInfoProps {
    caller: string;
    className?: string;
    headerClassName?: string;
}

const CallerInfo: React.VFC<CallerInfoProps> = ({
    caller,
    className,
    headerClassName,
}) => {
    let headerText: string,
        descriptionText = '';

    if (caller.includes('(')) {
        const desc = caller.substr(caller.indexOf('('));
        const splited = caller.split(' ');
        headerText = splited[0];
        descriptionText = desc.substring(1, desc.length - 1);
    }
    // Temporary holded - see ICAP-309 - will be available in the feature
    // else if (caller.includes('+')) {
    //     const parsed = parsePhoneNumber(caller);
    //     if (parsed) {
    //         headerText = parsed?.formatInternational();
    //     }
    // }
    else {
        headerText = caller;
    }

    return (
        <TwoLineCell
            headerText={headerText}
            descriptionText={descriptionText}
            className={className}
            headerClassName={headerClassName}
        />
    );
};

export default CallerInfo;

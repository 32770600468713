import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { useMemo } from 'react';

export const useExtensionDetailsForm = () => {
    const extension = useSelector(
        (state: ReduxState) => state.extensions.extensionDetails,
    );

    return useMemo(() => {
        return {
            i_account: extension?.i_account,
            is_blocked: extension?.account_info?.blocked === 'Y',
            didNumbers: extension?.numbers,
            sip_status: extension?.account_info?.sip_status,
            sip_agent: extension?.account_info?.sip_agent,
            i_c_ext: extension?.i_c_ext,
            extension_id: extension?.id,
            i_customer: extension?.i_customer,
            account_id: extension?.account_info?.id,
            um_domain: extension?.account_info?.um_domain,
            country: extension?.account_info?.country,
            name: extension?.name,
        };
    }, [extension]);
};

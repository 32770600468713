import React from "react";
import i18n from "../../services/i18n";
import {toast} from "react-hot-toast";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import { ReactComponent as Copy } from '../../assets/copy-content.svg';


const useStyle = makeStyles(() => ({

    copyIcon: {
        height: '20px',
        cursor: 'pointer',
        '& path': {
            fill: Colors.PlaceHolder,
            fillOpacity: 1,
        },
    },

}));

type CopyContentIconProps = {
    toastMsg?: string;
    tooltipMsg?: string;
    copyContent?: string;
};

const CopyContentIcon: React.FC<CopyContentIconProps> = (
    {
        toastMsg,
        tooltipMsg,
        copyContent,
    }) => {
    const classes = useStyle();
    const toastString = toastMsg || i18n.t('common:textIsCopied');
    const handleOnClick = () => {
        navigator.clipboard.writeText(copyContent || '');

        toast(toastString || '');
    };
    return (
        <CustomizedTooltip title={tooltipMsg} copy={false}>
            <Copy
                data-testid="copy-button"
                onClick={handleOnClick}
                className={classes.copyIcon}
            />
        </CustomizedTooltip>
    );
};

export default CopyContentIcon;
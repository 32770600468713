import { useSelector } from 'react-redux';
import { ReduxState } from '../store/types';
import { CallHistory } from '../store/types/CallHistory';
import { GlobalCurrencyInfo } from '../store/types/CustomerInfo';
import { useMemo } from 'react';

export const useRecentCalls = () => {
    const { items, total } = useSelector<
        ReduxState,
        { items: CallHistory[]; total: number; allTotal: number }
    >((state) => state.calls.callHistory);

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const customerCurrency = useSelector<ReduxState, string>(
        (state) => state.calls.customerCurrency || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const isLoading = useSelector<ReduxState, boolean>(
        (state) => state.calls.callHistory.isLoading,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );
    return useMemo(() => {
        return {
            items,
            total,
            currencyInfo,
            timezoneOffset,
            isLoading,
            userDateTimeFormat,
            customerCurrency,
            decimalDigits: currencyInfo?.decimal_digits || 2,
        };
    }, [
        items,
        currencyInfo,
        customerCurrency,
        timezoneOffset,
        isLoading,
        userDateTimeFormat,
    ]);
};

import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DialogContainer, { DialogButton } from '../AlertDialog/DialogContainer';
import { Colors } from '../../styles/Colors';
import { CallHistory } from '../../store/types/CallHistory';
import CustomizedTextField from '../TextField/TextField';
import { convertUtcToUserLocalTime } from '../../utils/dateWithTimezoneConversion';
import CallRecordDots from './CallRecordDots';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import { GlobalCurrencyInfo } from '../../store/types/CustomerInfo';
import { roundUp } from '../../utils/roundUp';
import { CloudDownload, Delete } from '@material-ui/icons';
import { prepareCallRecordName } from '../../utils/calls/prepareCallRecordName';
import classNames from 'classnames';
import CustomizedIconButton from '../IconButton/IconButton';
import PermissionProvider from '../PermissionProvider/PermissionProvider';
interface CallRecordInformationDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    callHistory?: CallHistory;
    customerCurrency?: string;
    timezoneOffset: number;
    onDeleteClick?: () => void;
    onDownloadClick?: (i_xdr: number, call_recording_id: string) => void;
    userDateTimeFormat?: string;
    isDownloadingGoingOn?: boolean[];
    downloadPermission?: string[];
    deletePermission?: string[];
}

const useStyles = makeStyles(() => ({
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 900,
            backgroundColor: Colors.SmokeBackground,
        },
    },
    inputsContainer: {
        padding: '0 12px 0 12px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            background: Colors.SmokeBackground,
        },
    },
    inputRow: {
        display: 'flex',
        marginTop: 22,

        '& .MuiFormControl-root': {
            flex: 1,
            margin: '0 6px',
        },
    },
    iconMargin: {
        marginLeft: 10,
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    tooltipContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 8px',
    },
    tooltip: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    tooltipTitle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '16px',
        lineHeight: '28px',
        marginRight: '8px',
        whiteSpace: 'pre-wrap',
    },
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    shorterInput: {
        maxWidth: 330,
    },

    cloud: {
        display: 'flex',
        alignItems: 'center',
        color: Colors.LightGraySecondary3,
        marginLeft: 23,
        marginRight: 12,
    },
}));

const CallRecordInformationDialog: React.VFC<CallRecordInformationDialogProps> = ({
    isOpen,
    toggleVisibility,
    callHistory,
    timezoneOffset,
    onDeleteClick,
    onDownloadClick,
    userDateTimeFormat,
    isDownloadingGoingOn,
    customerCurrency,
    downloadPermission,
    deletePermission,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [record, setRecord] = useState<CallHistory | undefined>(undefined);

    useEffect(() => {
        if (callHistory) {
            setRecord(callHistory);
        }
    }, [callHistory]);

    const currencyInfo = useSelector<
        ReduxState,
        GlobalCurrencyInfo | undefined
    >((state) => state.generic.globalCurrency);

    const decimalDigits = currencyInfo?.decimal_digits || 2;

    const connectTime = record?.connect_time
        ? convertUtcToUserLocalTime(
              record.connect_time,
              timezoneOffset,
              userDateTimeFormat,
          )
        : '';
    const disconnectTime = record?.disconnect_time
        ? convertUtcToUserLocalTime(
              record.disconnect_time,
              timezoneOffset,
              userDateTimeFormat,
          )
        : '';
    const durationTime = new Date((record?.charged_quantity || 0) * 1000)
        .toISOString()
        .substr(11, 8);

    const onDownloadIconClick = () => {
        if (onDownloadClick)
            onDownloadClick(
                record?.i_xdr || 0,
                record?.cr_download_ids?.[0] || '0',
            );
    };
    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-record-dialog"
            header={t('screens:calls.callRecordInformation')}
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:close')}
                    onClick={toggleVisibility}
                />,
            ]}
        >
            <CallRecordDots
                leftText={record?.CLI}
                rightText={record?.CLD}
                type={record?.bit_flags ? record.bit_flags & 12 : undefined}
            />

            <Grid className={classes.inputsContainer}>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="connectTime"
                        label={t('screens:calls.connectTime')}
                        value={connectTime}
                        readOnly
                    />
                    <CustomizedTextField
                        id="disconnectTime"
                        label={t('screens:calls.disconnectTime')}
                        value={disconnectTime}
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="duration"
                        label={t('screens:calls.duration')}
                        value={durationTime}
                        readOnly
                    />
                    <CustomizedTextField
                        id="disconnectReason"
                        label={t('screens:calls.disconnectReason')}
                        value={record?.disconnect_reason}
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="country"
                        label={t('screens:calls.country')}
                        value={record?.country || t<string>('common:notSet')}
                        readOnly
                    />
                    <CustomizedTextField
                        id="area"
                        label={t('screens:calls.area')}
                        value={
                            record?.subdivision || t<string>('common:notSet')
                        }
                        readOnly
                    />
                </Grid>
                <Grid className={classes.inputRow}>
                    <CustomizedTextField
                        id="description"
                        label={t('screens:calls.description')}
                        value={callHistory?.description}
                        readOnly
                    />
                    <CustomizedTextField
                        id="charge"
                        label={`${t('screens:calls.charge')}, ${
                            currencyInfo?.iso_4217 || customerCurrency
                        }`}
                        value={roundUp(
                            record?.charged_amount || 0,
                            decimalDigits,
                        ).toFixed(decimalDigits)}
                        readOnly
                    />
                </Grid>
            </Grid>

            {record?.cr_download_ids?.[0] && (
                <Grid className={classNames(classes.inputsContainer)}>
                    <Grid className={classes.inputRow}>
                        <CustomizedTextField
                            id="callRecord"
                            label={t('screens:calls.callRecording')}
                            value={prepareCallRecordName(
                                record,
                                timezoneOffset,
                            )}
                            readOnly
                            className={classes.shorterInput}
                        />
                        <div className={classes.buttonsContainer}>
                            <PermissionProvider
                                permission={downloadPermission || []} skipReadOnly
                            >
                                <CustomizedIconButton
                                    tooltipText={t(
                                        'tooltips:ringGroups.downloadRecording',
                                    )}
                                    dataQa="details-modal-download-icon"
                                    dataTestId="details-modal-download-icon"
                                    onClick={onDownloadIconClick}
                                    disabled={
                                        !(
                                            (isDownloadingGoingOn &&
                                                !isDownloadingGoingOn[0]) ||
                                            !isDownloadingGoingOn
                                        )
                                    }
                                >
                                    <CloudDownload />
                                </CustomizedIconButton>
                            </PermissionProvider>

                            <PermissionProvider
                                permission={deletePermission || []}
                            >
                                <CustomizedIconButton
                                    tooltipText={t('common:delete')}
                                    dataQa="details-modal-delete"
                                    dataTestId="details-modal-delete"
                                    onClick={onDeleteClick}
                                    className={classes.iconMargin}
                                >
                                    <Delete />
                                </CustomizedIconButton>
                            </PermissionProvider>
                        </div>
                    </Grid>
                </Grid>
            )}
        </DialogContainer>
    );
};

export default CallRecordInformationDialog;

import React from 'react';
import { UAInfo } from '../../store/types/Devices';

type FreeLinesProps = {
    info: UAInfo;
};

export const getFreeLines = (o: UAInfo): { left: number; right: number } => {
    let left = 0;

    o.ports_config.forEach((r) => {
        if (r.account_id == undefined) {
            left++;
        }
    });
    return { left: left, right: o.ports_config.length };
};

export const dataTestId = 'free-lines-cell';

const FreeLines: React.FC<FreeLinesProps> = ({ info }) => {
    const { left, right } = getFreeLines(info);
    return (
        <span data-testid={dataTestId}>
            <span style={{ color: 'black' }}>
                <b>{`${left}`}</b>
            </span>{' '}
            / {`${right}`}
        </span>
    );
};

export default FreeLines;

import { makeStyles } from '@material-ui/core/styles';
import { StatusFilterType } from '../../../store/sagas/extensions/extensions/saga';

export const useStyles = makeStyles(() => ({
    filtersContainer: {
        flex: 1,
        marginTop: 20,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0px',
    },
    filtersInputs: {
        flex: 1,
        display: 'flex',
        '& MuiFormControl-root': {
            flex: 1,
            maxWidth: 400,
        },
    },
    filterSeparation: {
        marginRight: 18,
        flex: 1,
        maxWidth: '390px',
        background: 'white',
    },
}));

export interface ExtensionListFilters {
    extensionFilter: string;
    nameFilter: string;
    didNumberFilter: string;
    emailFilter: string;
    statusFilter: StatusFilterType | undefined;
}

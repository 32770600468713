import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Colors } from '../../styles/Colors';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { usePermissionContext } from '../../hooks/usePermissions';
import { PermissionType } from '../../store/types/Permission';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    container: {
        padding: 10,

        '&:hover': {
            backgroundColor: Colors.Gray14,
        },
    },
    backgroundWithOpacity: {
        padding: 8,
        '&:hover': {
            backgroundColor: Colors.WhiteWithTinyOpacity,
        },
    },
    disabled: {
        '& svg': {
            opacity: 0.5,
        },
    },
}));

type CustomizedIconButtonProps = {
    dataQa?: string;
    dataTestId?: string;
    onClick?: (e: any) => void;
    className?: string;
    tooltipText?: string;
    disabled?: boolean;
    backgroundWithOpacity?: boolean;
    above?: boolean;
    skipPermission?: boolean;
};

const CustomizedIconButton: React.FC<CustomizedIconButtonProps> = ({
    dataQa,
    dataTestId = '',
    onClick,
    children,
    className,
    tooltipText,
    disabled,
    backgroundWithOpacity,
    above,
    skipPermission,
}) => {
    let permission = usePermissionContext();
    const classes = useStyles();
    const { t } = useTranslation();

    permission = skipPermission ? PermissionType.Visible : permission;

    if (permission === PermissionType.Hidden) {
        return null;
    }

    return (
        <CustomizedTooltip
            title={
                permission === PermissionType.Visible
                    ? tooltipText
                    : t('common:noPermissionToResource')
            }
            interactive={false}
            copy={false}
            disableHoverListener={!tooltipText}
            above={above}
        >
            <span>
                <IconButton
                    className={classNames(
                        classes.container,
                        backgroundWithOpacity && classes.backgroundWithOpacity,
                        className,
                        (disabled || permission === PermissionType.ReadOnly) &&
                            classes.disabled,
                    )}
                    onClick={onClick}
                    data-testid={dataTestId}
                    data-qa={dataQa}
                    disabled={
                        disabled || permission === PermissionType.ReadOnly
                    }
                >
                    {children}
                </IconButton>
            </span>
        </CustomizedTooltip>
    );
};

export default CustomizedIconButton;

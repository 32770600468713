import {makeStyles} from "@material-ui/core";
import {Colors} from "../../../styles/Colors";
import i18n from "../../../services/i18n";
import * as Yup from "yup";
import {nameMaxLengthError} from "../../../components/PortalUsers/utils";
import {invalidCharError} from "../../../components/Forms/SipTrunks/SipTrunk/SipTrunkForm.utils";

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '18px 24px 18px 24px',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            marginBottom: 24,
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },

    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 520,
            padding: '28px 60px',
            backgroundColor: Colors.SmokeBackground,
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },
    container: {
        '& > div:nth-child(2)': {
            position: 'relative',
            display: 'block',
            height: 'auto',
            top: '-30px'
        }
    }

}));

const requiredFieldError = i18n.t('errors:common.emptyInput');
export const nameSipTrunkMaxLengthError = i18n.t('errors:sipTrunks.max64Length');
export const maxTrunkIdLength = 64;
export const CreateNewSipTrunkValidationSchema = (
    portalPasswordLength: number,
) => Yup.object().shape({
    trunkId: Yup.string()
        .max(maxTrunkIdLength, nameSipTrunkMaxLengthError)
        .matches(/^[a-zA-Z0-9_.-]*$/, invalidCharError)
        .required(requiredFieldError),
    mainProductId: Yup.number().required(requiredFieldError),
    password: Yup.string()
        .test(
            'passLength',
            i18n.t(`screens:portalUsers.portalPassword`, {
                value: portalPasswordLength,
            }),
            function (value) {
                if (
                    value !== undefined &&
                    portalPasswordLength
                ) {
                    return (
                        value.length >= portalPasswordLength
                    );
                }

                return true;
            },
        ).test(
            'passChars',
            i18n.t(`errors:sipTrunks.whiteSpaceAdded`),
            function (value) {
                const test = value?.indexOf(' ')
                return test == undefined || test == -1
            }
        )
        .max(32, nameMaxLengthError)
        .required(requiredFieldError),

});
import React, { useState } from 'react';
import Config from './../../config.json';
import { makeStyles } from '@material-ui/core/styles';

const LOGO = Config.LOGO_LOCATION;
const DEF_LOGO = '/logo-default.svg';

interface LogoInterface {
    toWhite?: boolean;
}

const useStyles = makeStyles(() => ({
    changeToWhite: {
        filter: 'brightness(0) invert(1)',
        maxHeight: 100,
        width: 'auto',
    },
    logoContainer: {
        display: 'flex',
        width: '100%',
        height: 72,
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxHeight: 72,
            width: 'auto',
        },
    }
}));

enum ImgExt {
    JPG = 'jpg',
}

const Logo: React.VFC<LogoInterface> = ({ toWhite }) => {
    const [logo, setLogo] = useState(LOGO);
    const classes = useStyles();
    const ext = logo.substring(logo.length - 3).toLowerCase();
    let changeToWhite = false;

    if (toWhite && ext != ImgExt.JPG) {
        changeToWhite = true;
    }

    return (
        <div className={classes.logoContainer}>
            <img
                className={changeToWhite ? classes.changeToWhite : undefined}
                src={logo}
                onError={() => {
                    setLogo(DEF_LOGO);
                }}
            />
        </div>
    );
};
export default Logo;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogButton } from '../AlertDialog/DialogContainer';
import { GroupMemberType } from './groupDialogUtils';
import AlertDialog from '../AlertDialog/AlertDialog';
import { Colors } from '../../styles/Colors';

export interface ExcludeGroupMemberDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    type?: GroupMemberType;
    dataObj?: { name?: string; id?: string };
    onClickYesButton?: () => void;
}

const useStyles = makeStyles(() => ({
    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
}));

const ExcludeGroupMemberDialog: React.VFC<ExcludeGroupMemberDialogProps> = ({
    isOpen,
    toggleVisibility,
    type,
    dataObj,
    onClickYesButton,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AlertDialog
            isOpen={isOpen}
            description={t('screens:ringGroups.excludeGroupMemberDescription', {
                type: t(`enums:groupMember.${type}`)?.toLowerCase(),
                name: dataObj?.name || t('common:unnamed'),
                number: dataObj?.id,
            })}
            contentClass="alert-content"
            className={classes.container}
            hideHeader={true}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryModalButton}
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={toggleVisibility}
                />,
                <DialogButton
                    key="exlude"
                    label={t('screens:ringGroups.exclude')}
                    className={classes.primaryModalButton}
                    onClick={onClickYesButton}
                />,
            ]}
            dataQa="remove-ringgroup-modal"
            dataTestId="remove-ringgroup-modal"
        />
    );
};

export default ExcludeGroupMemberDialog;

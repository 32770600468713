import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import i18n from 'i18next';
import * as Yup from 'yup';
import { OperationMode } from '../../store/actions/extensions/payloads';
import dayjs from '../../services/customDayJs';

export const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: Colors.Secondary5,

        '& span.MuiButton-label': {
            color: Colors.Secondary1,
        },

        '& svg > path': {
            fill: `${Colors.Secondary1}`,
        },

        '&:hover': {
            backgroundColor: Colors.Secondary1,

            '& span.MuiButton-label': {
                color: Colors.White,
            },
            '& svg > path': {
                fill: Colors.White,
            },
        }
    },
    disabledButton: {
        background: '#D7D9DA',

        '& span.MuiButton-label': {
            color: Colors.Gray10
        },
        '& svg > path': {

            fill: `${Colors.Gray10}`,
        },
    }
}));

const dateRequired = i18n.t<string>('errors:extensions.dateRequired');

export const switchModeValidation = Yup.object().shape({
    effective_to: Yup.string()
        .nullable()
        .when('mode', {
            is: (value: OperationMode) => !!value.default_timeout,
            then: Yup.string()
                .test(
                    'isDate',
                    i18n.t<string>('errors:common.invalidDate'),
                    (value) => {
                        return dayjs((value as unknown) as Date).isValid();
                    },
                )
                .test(
                    'past',
                    i18n.t<string>('errors:common.dateFeature'),
                    (value) => {
                        if (!value) {
                            return true;
                        }
                        const date = dayjs((value as unknown) as Date);
                        const now = dayjs();
                        return date > now;
                    },
                )
                .required(dateRequired),
        }),
});

import React from 'react';
import {Add} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import CustomizedButton from '../../../components/Button/Button';
import {
    CustomerBillingStatus,
    CustomerBillingType,
} from '../../../store/types/CustomerInfo';
import {useStyles} from './BillingHeader.utils';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import PaymentHOC from '../../../components/Forms/Billing/PaymentHOC/PaymentHOC';
import EmptyRowContent from '../../../components/DataTable/EmptyRowContent';
import {OwnerPaymentMethod} from '../../../store/types/Payment';
import {Permission} from "../../../store/types/Permission";
import PermissionProvider from "../../../components/PermissionProvider/PermissionProvider";

const BillingHeader = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {customerInfo} = useSelector((state: ReduxState) => state.billing);
    const {globalCurrency} = useSelector(
        (state: ReduxState) => state.generic,
    );

    const ownerPaymentsMethods = useSelector<ReduxState,
        OwnerPaymentMethod[] | undefined>((state) => state.billing.ownerPaymentsMethods);

    const balance =
        customerInfo?.i_balance_control_type === CustomerBillingType.Postpaid
            ? customerInfo?.balance
            : customerInfo?.balance
                ? -1 * (customerInfo?.balance || 0)
                : undefined;

    return (

        <PaymentHOC>
            {(setIsPaymentDialogVisible) => (
                <div className={classes.header}>
                    <div className={classes.displayInline}>
                        <label>{t('screens:billing.billing')}</label>
                        <PermissionProvider
                            permission={
                                Permission.MyCompany.Billing.MakeAPayment.value
                            }
                        >
                            <CustomizedButton
                                dataQa="make-payment-button"
                                primary
                                className={classes.button}
                                onClick={() => setIsPaymentDialogVisible(true)}
                                disabled={ownerPaymentsMethods?.length === 0}
                            >
                                <Add className={classes.addIcon}/>
                                {t('screens:billing.makeAPayment')}
                            </CustomizedButton>
                        </PermissionProvider>
                    </div>
                    <div className={classes.rightContainer}>
                        {customerInfo?.status && (
                            <div
                                className={classNames(
                                    classes.statusBox,
                                    customerInfo.status ===
                                    CustomerBillingStatus.Suspended &&
                                    classes.warningStatusBox,
                                )}
                                data-testid="billing-status"
                                data-qa="billing-status"
                            >
                                {t(
                                    `enums:customerBillingStatus.${customerInfo.status}`,
                                )}
                            </div>
                        )}
                        <div className={classes.outsideBalanceContainer}>
                            {balance !== undefined ? (
                                <div className={classes.balanceContainer}>
                                    <span className={classes.semiHeader}>
                                        {t('screens:billing.balance')}
                                    </span>
                                    <span
                                        className={classNames(
                                            classes.value,
                                            classes.boldedValue,
                                        )}
                                    >
                                        {`${balance?.toFixed(2)} ${
                                            globalCurrency?.iso_4217
                                        }`}
                                    </span>
                                </div>
                            ) : undefined}

                            {customerInfo?.i_balance_control_type ===
                                CustomerBillingType.Postpaid && (
                                    <div
                                        className={classNames(
                                            classes.balanceContainer,
                                            classes.secondBalance,
                                        )}
                                    >
                                        <span className={classes.semiHeader}>
                                            {t('screens:billing.creditLimit')}
                                        </span>
                                        <span className={classes.value}>
                                        {customerInfo?.credit_limit !== undefined &&
                                            customerInfo?.credit_limit !== null ? (
                                                `${customerInfo?.credit_limit?.toFixed(
                                                    2,
                                                )} ${globalCurrency?.iso_4217}`
                                        ) : (
                                            <EmptyRowContent/>
                                        )}
                                    </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            )}
        </PaymentHOC>
    );
};

export default BillingHeader;

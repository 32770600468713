import {
    PortalComponentInfo,
    PortalPermission,
} from '../../store/types/Permission';

export const mapPermissionsToObject = (
    component: PortalComponentInfo,
): PortalPermission => {
    const components: { [key in string]: PortalPermission } = {};

    component.component_list.forEach((v) => {
        components[v.name] = mapPermissionsToObject(v);
    });

    return {
        components,
        name: component.name,
        permission: component.permission,
    };
};

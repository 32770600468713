import { makeStyles } from '@material-ui/core';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../../services/i18n';

export const useStyles = makeStyles(() => ({
    separator: {
        maxWidth: 944,
        height: 1,
        backgroundColor: Colors.Border,
        marginTop: 40,
        marginBottom: 34,
    },
}));

export type AutoPaymentsForm = {
    autoPayInvoice: boolean;
    autoPayWithValues: boolean;
    amount: string;
    balance: string;
};

export const AutoPaymentsFormInitialValues: AutoPaymentsForm = {
    autoPayInvoice: false,
    autoPayWithValues: false,
    amount: '',
    balance: '',
};

export const autoPaymentsFormValidationSchemaGenerator = (minValue: number) => {
    return Yup.object().shape({
        autoPayInvoice: Yup.boolean().required(),
        autoPayWithValues: Yup.boolean().required(),
        amount: Yup.string().when('autoPayWithValues', {
            is: (value: boolean) => value,
            then: Yup.string().test('minValue', 'min error', function (value) {
                if (value === '' || value === undefined) {
                    return this.createError({
                        path: this.path,
                        message: i18n.t<string>('errors:common.emptyInput'),
                    });
                }

                const numberValue = parseFloat(value?.replace(',', '') || '0');

                if (numberValue < minValue) {
                    return this.createError({
                        path: this.path,
                        message: i18n.t<string>('errors:billing.amountMin', {
                            value: minValue.toFixed(2),
                        }),
                    });
                }

                return true;
            }),
        }),
        balance: Yup.string().when('autoPayWithValues', {
            is: (value: boolean) => value,
            then: Yup.string().required(
                i18n.t<string>('errors:common.emptyInput'),
            ),
        }),
    });
};

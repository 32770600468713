import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import {
    CallProcessingModeName,
    CallProcessingModeNameMapping,
} from '../../components/Calls/ModeCell';
import { RingGroupType } from '../../store/types/RingGroup';
import { useMemo } from 'react';
import { SwitchModeFormInterface } from '../../components/SwitchMode/SwithModelDialog.utils';
import { SwitchModeApiFile } from '../../store/types/ApiFile';
import i18n from "../../services/i18n";

const defaultMessage: SwitchModeApiFile = {
    name: i18n.t<string>('common:notSet'),
    id: -1,
    status: '',
};

export const defaultGroup: RingGroupType = {
    i_c_group: -1,
    name: i18n.t<string>('screens:extensions.any'),
    id: '-1',
    hunt_sequence: '-1',
};

export const useSwitchModeDialogData = () => {
    const isLoading = useSelector(
        (state: ReduxState) => state.extensions.isSwitchModeDataLoading,
    );
    const isSaving = useSelector(
        (state: ReduxState) => state.extensions.isSwitchModeFormSaving,
    );

    const callProcessingOperationModeList = useSelector(
        (state: ReduxState) => state.extensions.callProcessingOperationModeList,
    );

    const operationList = callProcessingOperationModeList?.map((o) => ({
        ...o,
        name:
            o.name != CallProcessingModeName.Normal
                ? o.name
                : i18n.t<string>(`enums:callScreeningMode.${CallProcessingModeNameMapping.Normal}`),
    }));

    const huntGroupList = useSelector(
        (state: ReduxState) => state.ringgroups.huntGroupList,
    );

    const responseMessageList = useSelector(
        (state: ReduxState) => state.ringgroups.responseMessageList,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const iCustomer = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.i_customer || -1,
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const initData: SwitchModeFormInterface = useMemo(() => {
        if (!callProcessingOperationModeList) {
            return { i_c_group: defaultGroup.i_c_group, i_customer: iCustomer };
        }

        return {
            i_c_group: defaultGroup.i_c_group,
            mode: operationList?.find(
                (o) => o.name ==  i18n.t<string>(`enums:callScreeningMode.${CallProcessingModeNameMapping.Normal}`),
            ),
            i_customer: iCustomer,
        };
    }, [callProcessingOperationModeList, iCustomer]);


    return {
        ...initData,
        data: {
            huntGroupList: huntGroupList
                ? [defaultGroup].concat([...huntGroupList])
                : [defaultGroup],
            isLoading,
            isSaving,
            callProcessingOperationModeList: operationList,
            responseMessageList: responseMessageList
                ? [defaultMessage].concat([...responseMessageList])
                : [defaultMessage],
            userDateTimeFormat,
            timezoneOffset,
        },
    };
};

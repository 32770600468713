import React, { memo, useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../styles/Colors';
import { FlowNode } from '../utils/types';
import { ReactComponent as MusicIcon } from '../../../../../assets/music.svg';
import TransitionSubNode from './TransitionSubNode';
import { UserInput } from '../../../../../store/types/AutoAttendant';
import { Handle, Position } from 'react-flow-renderer';
import { MenuActionFormType } from '../../../AutoAttendantsMenus/ActionsForm.utils';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../store/types';
import { Assignment, Edit } from '@material-ui/icons';
import { getTransitionMenuLabel } from '../../../../../utils/autoAttendants/getTransitionMenuLabel';
import CustomizedTooltip from '../../../../Tooltip/Tooltip';
import OverflowTooltip from '../../../../OverflowTooltip/OverflowTooltip';
import { EditAutoAttendantsMenuForm } from '../../../../../views/AutoAttendants/AutoAttendantsMenuDetails';
import i18n from '../../../../../services/i18n';
import { PermissionType } from '../../../../../store/types/Permission';
import NodeActionButton from './NodeActionButton';
import { usePermissionContext } from '../../../../../hooks/usePermissions';
import { ServiceFeatureName } from '../../../../../store/types/ServiceFeature';

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'flex',
        height: 52,
    },
    container: {
        height: 50,
        width: 175,
        borderRadius: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.White,
        border: `2px solid ${Colors.Primary}`,
        pointerEvents: 'auto',
    },
    titleText: {
        fontSize: 12,
        color: Colors.Gray9,
        fontWeight: 500,
        lineHeight: '16px',
    },
    descriptionText: {
        fontSize: 14,
        color: Colors.Black,
        fontWeight: 500,
        lineHeight: '18px',
        maxWidth: 150,
        height: 18,
        flex: 'unset',
        pointerEvents: 'auto',
    },
    ringtoneContainer: {
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.Secondary11,
        border: `1px solid ${Colors.Primary}`,
        borderRadius: '50%',
        position: 'absolute',
        top: -12,
        right: 147,
        pointerEvents: 'all',
    },
    horizontalPath: {
        width: 40,
        height: 2,
        backgroundColor: Colors.Secondary12,
        marginTop: 25,
    },
    sourceHandler: {
        left: 0,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    editContainer: {
        width: 40,
        height: 40,
        backgroundColor: Colors.White,
        borderRadius: 20,
        boxShadow: `0px 4px 4px ${Colors.Gray8}`,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 67.5,
        pointerEvents: 'auto',
        bottom: -25,
        cursor: 'pointer',

        '& .MuiSvgIcon-root': {
            '&:hover': {
                fill: Colors.Text,
            },
        },
    },
}));

const isTransitionHasPrompt = (transition: MenuActionFormType) => {
    return (
        transition.playBeforeActionStatus && transition.playBeforeActionFileName
    );
};

const transitionPromptsTooltip = (
    transition?: MenuActionFormType,
    parentMenu?: EditAutoAttendantsMenuForm,
) => {
    return (
        <div>
            <span
                style={{
                    color: Colors.Gray3,
                    marginBottom: 5,
                }}
            >{`${parentMenu?.name}\n`}</span>
            {i18n.t('screens:autoAttendants.promptBeforeAction')}
        </div>
    );
};

const ActionNode = (node: FlowNode) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();

    const { data } = node;

    const [focused, setFocused] = useState(false);

    const email = useSelector<ReduxState, string>(
        (state) =>
            state.autoAttendants?.incomingTab?.emailAddressIncoming || '',
    );
    
    const serviceFeatures = useSelector(
        (state: ReduxState) => state.autoAttendants.autoAttendantDetails?.extension?.serviceFeatures
            || state.autoAttendants?.autoAttendantInfo?.account_info?.service_features
            || state.autoAttendants?.autoAttendantInfo?.serviceFeatures
            || state.extensions?.serviceFeatures
    );

    const unifiedMessagingVoicemailEnabled = useMemo(() => serviceFeatures
            ?.find((v) => v.name === ServiceFeatureName.UnifiedMessaging)
            ?.attributes.find((v) => v.name === 'fax_only_mode')?.effective_values[0]
            === "N",
        [serviceFeatures]);

    const { title, description } = getTransitionMenuLabel(
        unifiedMessagingVoicemailEnabled,
        data?.transition,
        email,
    );

    const onClickEdit = useCallback(
        () =>
            data?.transition &&
            data?.parentMenu &&
            data?.onPressTransitionNode?.({
                transition: data.transition,
                menu: data.parentMenu,
            }),
        [data],
    );

    const onMouseEnter = useCallback(() => setFocused(true), []);
    const onMouseLeave = useCallback(() => setFocused(false), []);

    const editButton = permission === PermissionType.Visible ? (
        <NodeActionButton
            tooltip={t('screens:autoAttendants.editAction')}
            onClick={onClickEdit}
            dataTestId="edit-action-button"
            dataQa="edit-action-button"
            className={classes.editContainer} 
            skipPermission
        >
            <Edit htmlColor={Colors.Gray5} />
        </NodeActionButton>
    ) : (
        <NodeActionButton
            tooltip={t('screens:autoAttendants.viewActionDetails')}
            onClick={onClickEdit}
            dataTestId="edit-action-button"
            dataQa="edit-action-button"
            className={classes.editContainer}
            skipPermission
        >
            <Assignment htmlColor={Colors.Gray5} />
        </NodeActionButton>
    );

    return (
        <div
            className={classes.mainContainer}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-testid="action-node"
            data-qa="action-node"
        >
            {data?.transition && (
                <>
                    {data.transition.userInput !== UserInput.NotActive ? (
                        <TransitionSubNode transition={data?.transition} />
                    ) : (
                        <div className={classes.horizontalPath} />
                    )}

                    <div className={classes.horizontalPath} />
                </>
            )}

            <div>
                <div className={classes.container}>
                    <Handle
                        type="target"
                        position={Position.Left}
                        id="a"
                        className={classes.sourceHandler}
                    />

                    {title && (
                        <span className={classes.titleText}>{title}</span>
                    )}

                    {description && (
                        <OverflowTooltip
                            classes={{ text: classes.descriptionText }}
                            tooltip={description}
                            text={description}
                            copy={false}
                        />
                    )}
                </div>

                {data?.transition && isTransitionHasPrompt(data?.transition) && (
                    <CustomizedTooltip
                        title={transitionPromptsTooltip(
                            data?.transition,
                            data?.parentMenu,
                        )}
                        copy={false}
                    >
                        <div className={classes.ringtoneContainer}>
                            <MusicIcon
                                data-qa="ringtone-icon"
                                data-testid="ringtone-icon"
                            />
                        </div>
                    </CustomizedTooltip>
                )}
            </div>

            {focused && data?.transition?.userInput !== UserInput.NotActive && editButton}
        </div>
    );
};

export default memo(ActionNode);

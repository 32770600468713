import {createStyles, IconButton, makeStyles} from '@material-ui/core';
import {Delete, Edit, KeyboardArrowDown, KeyboardArrowRight} from '@material-ui/icons';
import classNames from 'classnames';
import React, {useState} from 'react';
import {Colors} from '../../styles/Colors';
import CustomizedIconButton from "../IconButton/IconButton";
import {useTranslation} from "react-i18next";
import Badge from "../Badge/Badge";
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';

export type RowExpanderProps = {
    className?: string;
    title?: string;
    isOpenedOnInit?: boolean;
    badgeCount?: null | number
    onEdit?: () => void;
    onDelete?: () => void;
    showUnderline?: boolean;
    disabled?: boolean;
    customDisabledTooltipText?: string;
    hideDelete?: boolean;
};

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            backgroundColor: Colors.SmokeBackground,
            padding: '16px 0px 16px 0px',
            display: 'flex',
            flexDirection: 'column',
        },
        borderLined: {
            borderBottom: `1px solid ${Colors.Border}`,
        },
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 24
        },
        headerItem: {
            display: 'flex',
            paddingLeft: 12,
            maxWidth: 'calc(100% - 120px)'
        },
        arrowButton: {
            padding: 0,
            height: 24,
            margin: 0,

            '& .MuiIconButton-label': {
                '& .MuiSvgIcon-root': {
                    fill: Colors.Gray5,
                },
            },
        },
        title: {
            fontSize: 16,
            fontWeight: 400,
            color: Colors.Text,
            height: 24,
            paddingLeft: 12
        },
        badgeContainer: {
            height: 24,
            marginLeft: 18,
            marginTop: 1,

            '& .MuiBadge-badge': {
                fontSize: 14
            },
        },
        emptySpan: {
            marginRight: 44
        }
    })
);

const RowExpander: React.FC<RowExpanderProps> = (
    {
        title,
        isOpenedOnInit,
        children,
        className,
        onDelete,
        onEdit,
        badgeCount,
        showUnderline = false,
        disabled = false,
        customDisabledTooltipText,
        hideDelete = false
    }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(isOpenedOnInit || false);
    const {t} = useTranslation();

    //fix the Tooltip bug when the tooltip not showing on hovering element just after page reload
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 200);

    return (
        <div className={classNames(classes.container, className, showUnderline && classes.borderLined)}>
            <div className={classes.headerContainer}>
                <div className={classes.headerItem}>
                    <IconButton
                        onClick={() => setIsOpen(!isOpen)}
                        size="small"
                        data-testid="expander-icon"
                        data-qa="expander-icon"
                        edge={'start'}
                        className={classes.arrowButton}
                    >
                        {isOpen ? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
                    </IconButton>
                    
                    <OverflowTooltip
                        tooltip={title}
                        text={title}
                        classes={{ text: classes.title }}
                        copy={false}
                    />

                    {!!badgeCount &&
                        <div className={classes.badgeContainer}><Badge count={badgeCount} /></div>
                    }
                </div>
                <div className={classes.headerItem}>
                    <CustomizedIconButton
                        onClick={onEdit}
                        dataTestId="edit-policy-item-button"
                        dataQa="edit-policy-item-button"
                        tooltipText={customDisabledTooltipText || t<string>('common:edit')}
                        disabled={disabled}
                    >
                        <Edit htmlColor={Colors.ListIconColor}/>
                    </CustomizedIconButton>
                    {hideDelete ? (
                        <div className={classes.emptySpan} />
                    ) : (
                        <CustomizedIconButton
                            onClick={onDelete}
                            dataTestId="remove-policy-item-button"
                            dataQa="remove-policy-item-button"
                            tooltipText={customDisabledTooltipText || t<string>('common:delete')}
                            disabled={disabled}
                        >
                            <Delete htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    )}
                </div>

            </div>
            {isOpen && <div>{children}</div>}
        </div>
    );
};

export default RowExpander;

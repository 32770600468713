import React from 'react';
import DefaultCallerForm from './DefaultCallerForm';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CustomerDIDNumberType } from '../../../../store/types/CustomerDIDNumber';
import Config from '../../../../config.json';
import {convertBool} from "../../../../utils/parseBool";

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 10,
        maxWidth: 392,
    },
    itemsContainer: {
        padding: '22px 16px 17px 16px',
        maxWidth: 970,
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface GeneralFormProps {
    numbers: CustomerDIDNumberType[];
}

const GeneralForm: React.VFC<GeneralFormProps> = ({ numbers }) => {
    const classes = useStyles();

    return (
        <div data-testid="general-form">
            <Grid item className={classes.itemsContainer}>
                <DefaultCallerForm
                    numbers={numbers}
                    prefixEnabled={convertBool(Config.PREFIX_DID_ENABLED) || false}
                />
            </Grid>
        </div>
    );
};

export default GeneralForm;

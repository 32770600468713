import React, { useEffect, useState } from 'react';
import { PasswordComplexityRules } from '../../store/types/ConfigData';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/Colors';
import { ReactComponent as Check } from '../../assets/check.svg';
import { ReactComponent as Dot } from '../../assets/dot.svg';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

type PasswordRulesProps = {
    rules?: {
        PasswordComplexityRules: PasswordComplexityRules[];
        PasswordMinLength: number;
    };
    passwordRuleFollowed?: {
        letters: boolean;
        numbers: boolean;
        symbols: boolean;
        length: boolean;
        uppercaseLetters: boolean;
        lowercaseLetters: boolean;
    };
};
const mapper: { [key in PasswordComplexityRules]: string } = {
    [PasswordComplexityRules.LatinLettersCanBeUsed]: 'letters',
    [PasswordComplexityRules.LatinLettersMustBeUsed]: 'letters',
    [PasswordComplexityRules.DigitsCanBeUsed]: 'numbers',
    [PasswordComplexityRules.DigitsMustBeUsed]: 'numbers',
    [PasswordComplexityRules.LowercaseLettersCanBeUsed]: 'lowercaseLetters',
    [PasswordComplexityRules.LowercaseLettersMustBeUsed]: 'lowercaseLetters',
    [PasswordComplexityRules.UppercaseLettersCanBeUsed]: 'uppercaseLetters',
    [PasswordComplexityRules.UppercaseLettersMustBeUsed]: 'uppercaseLetters',
    [PasswordComplexityRules.SpecialCharactersCanBeUsed]: 'symbols',
    [PasswordComplexityRules.SpecialCharactersMustBeUsed]: 'symbols',
};

const useStyles = makeStyles(() => ({
    listElement: {
        color: Colors.Gray5,
        paddingLeft: 4,
    },
    rule: {
        minHeight: 30,

        '& svg': {
            width: 24,
            height: 12,
        },
    },
    fulfiledRule: {
        color: Colors.Support,
    },
}));

const PasswordRulesList: React.VFC<PasswordRulesProps> = ({
    rules,
    passwordRuleFollowed,
}) => {
    const { t } = useTranslation();
    const [elements, setElements] = useState<JSX.Element[] | undefined>(
        undefined,
    );
    const classes = useStyles();

    useEffect(() => {
        let elements: JSX.Element[] | undefined = undefined;
        if (rules) {
            const translations = rules.PasswordComplexityRules.map((rule) => {
                const currentRule = mapper[rule];
                return {
                    name: t(`enums:passwordRules.${rule}`),
                    //@ts-ignore
                    ruleFulfilled: passwordRuleFollowed?.[currentRule],
                };
            });

            translations.push({
                name: t('enums:passwordRules.PasswordMinLength', {
                    charLength: rules.PasswordMinLength,
                }),
                ruleFulfilled: passwordRuleFollowed?.['length'],
            });

            elements = translations.map((val, key) => {
                return (
                    <div
                        key={key}
                        className={classNames(
                            classes.rule,
                            val.ruleFulfilled && classes.fulfiledRule,
                        )}
                    >
                        {val.ruleFulfilled ? <Check /> : <Dot />}
                        <span> {val.name}</span>
                    </div>
                );
            });
            setElements(elements);
        }
    }, [passwordRuleFollowed]);

    return (
        <>
            {elements ? (
                <>
                    <span>
                        {t('screens:myProfile.changePasswordDescriptionTitle')}
                    </span>
                    {elements}
                </>
            ) : (
                <span>{t('screens:myProfile.noRules')}</span>
            )}
        </>
    );
};

export default PasswordRulesList;

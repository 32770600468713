import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../styles/Colors';
import * as Yup from 'yup';

type ErrorMessage = {
    login?: string;
    email?: string;
    global?: string;
};
export const validate = (values: any) => {
    const errors: ErrorMessage = {};

    const schema = Yup.object().shape({
        email: Yup.string().email()
    });

    if(!schema.isValidSync(values)) {
        errors.email = 'wrongEmailFormat';
    }

    return errors;
};
export const useStyles = makeStyles(() => ({
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },

    action: {
        marginTop: 44,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',

        '& div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    },

    recoveryButton: {
        fontSize: 16,
        fontWeight: 700,
        width: '100%',
        height: 56,
        padding: 0,
        marginBottom: 23,
        '& span': {
            color: Colors.White,
        },
    },

    backButton: {
        padding: 0,
        height: 16,
        color: Colors.Secondary1,
        fontWeight: 400,
        fontSize: 16,
        alignSelf: 'center',
        '& span': {
            height: 16,
        },
    },

    disabled: {
        backgroundColor: Colors.Gray11 + ' !important',
        '& span': {
            color: Colors.Gray10 + '!important',
        },
    },
}));
